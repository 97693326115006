import { Injectable } from '@angular/core';
import { InternalCallingLineIDDelivery } from '../../../model/bw-services/internal-calling-line-id-delivery';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';


/**
 * to register internalCallingLineIDDeliveryResponseHandler   
 * 
 * @export
 * @class InternalCallingLineIDDeliveryService
 */
@Injectable()

export class InternalCallingLineIDDeliveryService {
    isActive: boolean = false;
    internalCallingLineIDDelivery: InternalCallingLineIDDelivery;
    internalCallingChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }
    setData(internalCallingLineIDDelivery: InternalCallingLineIDDelivery) { }
    validate(): boolean {
        return true;
    }
    save() { }
    populateData() { }
    cancel() { }
}
