import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import {  Subscription } from 'rxjs';
import { ContextService } from "./context.service";
@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  sub: Subscription = new Subscription();
  isSubscription: boolean = false;
  constructor(public contextService: ContextService) {

    this.contextService.calculateSummaryStats.subscribe(val => {
      if (val == "waittime") {
        this.timerTestSetUp();
      } else {
        this.calculateSummaryValues();
      }
    });
    this.contextService.commonObservable.subscribe(val => {
      if (val == "autoReconnect") {
        this.timerUnsubcribe();
      }
    });
  }

  timerTestSetUp() {
    this.contextService.log("PW::timerTestSetUp");

    let time = timer(0, 1000);
    if (!this.isSubscription) {

      this.isSubscription = true;
      this.sub = time.subscribe(
        t => {
          this.timerForPW();
        }
      );
    }
  }

  timerUnsubcribe() {
    this.contextService.log("PW::timerUnsubcribe");
    if (!this.sub.closed) {
      this.isSubscription = false;
      this.sub.unsubscribe();
    }
  }
  calculateSummaryValues() {
    this.contextService.summaryStatToDisplay[0].clearAllValues();
    let userTotalCalls: number = 0, userAnsweredCalls: number = 0, userMissedCalls: number = 0, userTotalTalkTime: number = 0, userAverageTalkTime: number = 0,
      userTotalStaffedTime: number = 0, userCallbacksAnswered: number = 0, queueTotalCalls: number = 0, queueCallsAnswered: number = 0,
      queueCallsAnsweredPercentage: number = 0.00, queueTotalMissedCalls: number = 0, queueTotalMissedCallsPercentage: number = 0.00,
      queueCallsInQueue: number = 0, queueLongestWaitTime: number = 0, queueAverageWaitTime: number = 0, queueAverageTalkTime: number = 0,
      queueBusyOverflows: number = 0, queueBusyOverflowsPercentage: number = 0.00, queueCallsAbandoned: number = 0, queueCallsAbandonedPercentage: number = 0.00,
      queueCallsTransfered: number = 0, queueCallsTransferedPercentage: number = 0.00, queueCallsTimedOut: number = 0,
      queueCallsTimedOutPercentage: number = 0.00, queueOverflowedTotal: number = 0, queueOverflowedTotalPercentage: number = 0.00, queueAverageAgentsTalking: number = 0, queueAverageAgentsStaffed: number = 0,
      queueAverageAbandonedTime: number = 0, queueTotalTalkTime: number = 0, staffedAgents: number = 0,
      totalAgents: number = 0;

    let count = 0;
    this.contextService.statistics.forEach(element => {
      if (element.isDisplayInPersonalWallboard) {
        count++;

        userTotalCalls += element.getUserTotalCalls();
        userAnsweredCalls += element.getUserAnsweredCalls();
        userMissedCalls += element.getUserMissedCalls();
        userTotalTalkTime += element.getUserTotalTalkTime();
        userAverageTalkTime += element.getUserAverageTalkTime();
        userTotalStaffedTime += element.getUserTotalStaffedTime();
        userCallbacksAnswered += element.getUserCallbacksAnswered();

        queueTotalCalls += element.getQueueTotalCalls();
        queueCallsAnswered += element.getQueueCallsAnswered();
        queueCallsAnsweredPercentage += element.getQueueCallsAnsweredPercentage();
        queueTotalMissedCalls += element.getQueueTotalMissedCalls();
        queueTotalMissedCallsPercentage += element.getQueueTotalMissedCallsPercentage();
        queueCallsInQueue += element.getQueueCallsInQueue();
        if (element.getQueueLongestWaitTime() > queueLongestWaitTime) {
          queueLongestWaitTime = element.getQueueLongestWaitTime();
        }
        queueAverageWaitTime += element.getQueueAverageWaitTime();
        queueAverageTalkTime += element.getQueueAverageTalkTime();
        queueBusyOverflows += element.getQueueBusyOverflows();
        queueBusyOverflowsPercentage += element.getQueueBusyOverflowsPercentage();
        queueCallsAbandoned += element.getQueueCallsAbandoned();
        queueCallsAbandonedPercentage += element.getQueueCallsAbandonedPercentage();
        queueCallsTransfered += element.getQueueCallsTransfered();
        queueCallsTransferedPercentage += element.getQueueCallsTransferedPercentage();
        queueCallsTimedOut += element.getQueueCallsTimedOut();
        queueCallsTimedOutPercentage += element.getQueueCallsTimedOutPercentage();
        queueOverflowedTotal += element.getQueueOverflowedTotal();
        queueOverflowedTotalPercentage += element.getQueueOverflowedTotalPercentage();
        queueAverageAgentsTalking += element.getQueueAverageAgentsTalking();
        queueAverageAgentsStaffed += element.getQueueAverageAgentsStaffed();
        queueAverageAbandonedTime += element.getQueueAverageAbandonedTime();
        queueTotalTalkTime += element.getQueueTotalTalkTime();
        staffedAgents += element.getStaffedAgents();
        totalAgents += element.getTotalAgents();
      }
    });

    this.contextService.summaryStatToDisplay[0].setUserTotalCalls(userTotalCalls);
    this.contextService.summaryStatToDisplay[0].setUserAnsweredCalls(userAnsweredCalls);
    this.contextService.summaryStatToDisplay[0].setUserMissedCalls(userMissedCalls);
    this.contextService.summaryStatToDisplay[0].setUserTotalTalkTime(userTotalTalkTime);
    userAverageTalkTime = userAverageTalkTime / count;
    this.contextService.summaryStatToDisplay[0].setUserAverageTalkTime(userAverageTalkTime);
    this.contextService.summaryStatToDisplay[0].setUserTotalStaffedTime(userTotalStaffedTime);
    this.contextService.summaryStatToDisplay[0].setUserCallbacksAnswered(userCallbacksAnswered);

    this.contextService.summaryStatToDisplay[0].setQueueTotalCalls(queueTotalCalls);
    this.contextService.summaryStatToDisplay[0].setQueueCallsAnswered(queueCallsAnswered);
    queueCallsAnsweredPercentage = queueCallsAnsweredPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueCallsAnsweredPercentage(queueCallsAnsweredPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueTotalMissedCalls(queueTotalMissedCalls);
    queueTotalMissedCallsPercentage = queueTotalMissedCallsPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueTotalMissedCallsPercentage(queueTotalMissedCallsPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueCallsInQueue(queueCallsInQueue);
    this.contextService.summaryStatToDisplay[0].setQueueLongestWaitTime(queueLongestWaitTime);
    queueAverageWaitTime = queueAverageWaitTime / count;
    this.contextService.summaryStatToDisplay[0].setQueueAverageWaitTime(queueAverageWaitTime);
    queueAverageTalkTime = queueAverageTalkTime / count;
    this.contextService.summaryStatToDisplay[0].setQueueAverageTalkTime(queueAverageTalkTime);
    this.contextService.summaryStatToDisplay[0].setQueueBusyOverflows(queueBusyOverflows);
    queueBusyOverflowsPercentage = queueBusyOverflowsPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueBusyOverflowsPercentage(queueBusyOverflowsPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueCallsAbandoned(queueCallsAbandoned);
    queueCallsAbandonedPercentage = queueCallsAbandonedPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueCallsAbandonedPercentage(queueCallsAbandonedPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueCallsTransfered(queueCallsTransfered);
    queueCallsTransferedPercentage = queueCallsTransferedPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueCallsTransferedPercentage(queueCallsTransferedPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueCallsTimedOut(queueCallsTimedOut);
    queueCallsTimedOutPercentage = queueCallsTimedOutPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueCallsTimedOutPercentage(queueCallsTimedOutPercentage);
    this.contextService.summaryStatToDisplay[0].setQueueOverflowedTotal(queueOverflowedTotal);
    queueOverflowedTotalPercentage = queueOverflowedTotalPercentage / count;
    this.contextService.summaryStatToDisplay[0].setQueueOverflowedTotalPercentage(queueOverflowedTotalPercentage);
    queueAverageAgentsTalking = queueAverageAgentsTalking / count;
    this.contextService.summaryStatToDisplay[0].setQueueAverageAgentsTalking(queueAverageAgentsTalking);
    queueAverageAgentsStaffed = queueAverageAgentsStaffed / count;
    this.contextService.summaryStatToDisplay[0].setQueueAverageAgentsStaffed(queueAverageAgentsStaffed);
    queueAverageAbandonedTime = queueAverageAbandonedTime / count;
    this.contextService.summaryStatToDisplay[0].setQueueAverageAbandonedTime(queueAverageAbandonedTime);
    this.contextService.summaryStatToDisplay[0].setQueueTotalTalkTime(queueTotalTalkTime);
    this.contextService.summaryStatToDisplay[0].setStaffedAgents(staffedAgents);
    this.contextService.summaryStatToDisplay[0].setTotalAgents(totalAgents);
    this.contextService.summaryStatToDisplay[0].setQueueStaffedRatio();

    // this.contextService.summaryStatToDisplay[0].setQueueStaffedRatio(queueStaffedRatio);
  }

  timerForPW() {
    let isLongestWaitTimePresent: boolean = false;
    let isAgentStaffedPresent: boolean = false;
    let isCallInQueue: boolean = false;
    let isAgentStaffed: boolean = false;
    this.contextService.cols.forEach(element => {
      if (element.field == "queueLongestWaitTime") {
        isLongestWaitTimePresent = true;
      }
      if (element.field == "userTotalStaffedTime") {
        isAgentStaffedPresent = true;
      }
    });
    if (isLongestWaitTimePresent || isAgentStaffedPresent) {
      this.contextService.statistics.forEach(element => {
        if (isLongestWaitTimePresent) {
          if (element.getQueueCallsInQueue() > 0) {
            isCallInQueue = true;
            if (Object.keys(element.longestWaitTimeCollection).length > 0) {
              let time = this.calculateLongestWaitTime(element.longestWaitTimeCollection);
              element.setQueueLongestWaitTime(time);

            } else {
              element.setQueueLongestWaitTime(element.getQueueLongestWaitTime() + 1);
            }
          } else {
            element.setQueueLongestWaitTime(0);
          }
        }
        if (isAgentStaffedPresent) {
          if (element.queueState == "join") {
            isAgentStaffed = true;
            // let staffedTime = Math.abs((new Date().getTime() - element.lastStaffedDateTime.getTime()) / 1000);
            element.setUserTotalStaffedTime(element.getUserTotalStaffedTime() + 1);
          }
        }
        this.calculateSummaryValues();
      });
      if (!isCallInQueue && !isAgentStaffed) {
        this.timerUnsubcribe();
      }
    }
  }

  calculateLongestWaitTime(longestWaitTimeCollection) {
    
    let Seconds = 20;
 
    return Seconds;
  }
}
