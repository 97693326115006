<p-dialog [(visible)]="repVis" [closable]="false" class="report-dialog" [draggable]="false" [responsive]="true" breakpoint="9000">
    <p-header>
      <div class="container-center">
        <p class="text-center">Generate a report</p>
        <span class="close-modal" (click)="closeReport()">X</span>
      </div>
    </p-header>
    <div class="modal-content">
      <div class="mid-container-center">
        <form (ngSubmit)="onSubmit()" [formGroup]="reportForm" #rfRef="ngForm" id="reportForm">
          <div class="row">
            <label for="report" class="label">Report</label>
            <p-dropdown [options]="reportOpts" formControlName="selectedReport" (onChange)="setReportType($event)" [style]="{'width':'100%'}"></p-dropdown>
          </div>
          <div class="row">
            <div class="cols-2">
              <div class="col">
                <label class="label">Start period</label>
                <div>
                  <p-calendar formControlName="startDate" [showIcon]="true"></p-calendar>
                  <p-dropdown [options]="startHourOpts" formControlName="startHour" [style]="{'width':'23%'}"></p-dropdown>
                  <span>:</span>
                  <p-dropdown [options]="startMinOpts" formControlName="startMin" [style]="{'width':'23%'}"></p-dropdown>
                </div>
              </div>
              <div class="col">
                <label class="label">End period</label>
                <div>
                  <p-calendar formControlName="endDate" [showIcon]="true"></p-calendar>
                  <p-dropdown [options]="endHourOpts" formControlName="endHour" [style]="{'width':'23%'}"></p-dropdown>
                  <span>:</span>
                  <p-dropdown [options]="endMinOpts" formControlName="endMin" [style]="{'width':'23%'}"></p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <!--show selected Reports-->
          <div [ngSwitch]="selectedReportID">
            <div *ngSwitchCase="2">
              <div class="row">
                <p-checkbox formControlName="realtime" name="realtime" label="Real-time report" binary="true"></p-checkbox>
              </div>
              <div class="row">
                <label for="output" class="label">Sampling</label>
                <p-dropdown [options]="sampling" formControlName="sampling" [style]="{'width':'100%'}"></p-dropdown>
              </div>
              <div class="row">
                <label for="output" class="label">Short duration</label>
                <input type="number" name="short-duration" formControlName="shortDuration" placeholder="Measured in seconds. Only one value can be entered." min="0">
              </div>
              <div class="row">
                <label for="output" class="label">Call completion</label>
                <input type="number" name="call-completion" formControlName="callCompletion" placeholder="Measured in seconds. Only one value can be entered." min="0">
              </div>
            </div>
            <div *ngSwitchCase="3">
              <div class="row">
                <p-checkbox formControlName="realtime" name="realtime" label="Real-time report" binary="true"></p-checkbox>
              </div>
              <div class="row">
                <label for="output" class="label">Sampling</label>
                <p-dropdown [options]="sampling" formControlName="sampling" [style]="{'width':'100%'}"></p-dropdown>
              </div>
            </div>
            <div *ngSwitchDefault>
            </div>
          </div>
          <div class="row">
            <label for="output" class="label">Document type</label>
            <div class="doctype">
              <p-radioButton formControlName="doctype" name="doctype" value="PDF" label="PDF" styleClass="big-radio"></p-radioButton>
              <p-radioButton formControlName="doctype" name="doctype" value="XLS" label="XLS" styleClass="big-radio"></p-radioButton>
            </div>
          </div>
        </form>
      </div>
    </div>
    <p-footer>
      <div class="container-center ui-helper-clearfix">
        <div class="save-settings">
          <a (click)="closeReport()">Cancel</a>
          <button type="submit" form="reportForm" class="btn-blue-light">Generate report</button>
        </div>
      </div>
    </p-footer>
</p-dialog>