import { Component, OnInit, Input, Output, ViewEncapsulation, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ReplyEntry } from '../../model/reply-entry.model'

@Component({
  selector: 'app-canned-replies',
  templateUrl: './canned-replies.component.html',
  styleUrls: ['./canned-replies.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CannedRepliesComponent implements OnInit {
  @ViewChild('input', { static: true }) searchInput;
  @Output() onRightClick = new EventEmitter<any>();
  @Output() onDoubleClick = new EventEmitter<any>();
  @Input() repliesEntries: Array<ReplyEntry>;
  @Input() visible: boolean;

  listFiltered: Array<ReplyEntry>;

  noResults: string = "";
  searchString: string = "";
  search: string = "";

  constructor(public contextService: ContextService, private messagesService: MessagesService) { }

  ngOnInit() {
    this.search = this.contextService.getLanguageTagByKey('Misc.Search');
    this.noResults = this.contextService.getLanguageTagByKey('Misc.NoEntries');

    this.searchString = '';
  }

  onSubmit() {

  }

  doubleClick(data) {
    console.log(data)
    this.onDoubleClick.emit(data.text);
  }

  rightClick(data) {
    console.log(data)
    this.onRightClick.emit(data.text);
  }

  filter(s) {
    this.searchString = s;

    if (s.length) {
      var found: Array<ReplyEntry> = [];
      var sl = s.toLowerCase()
      this.repliesEntries.forEach(entry => {
        if (entry.title.toLowerCase().indexOf(sl) >= 0 || entry.description.toLowerCase().indexOf(sl) >= 0 || entry.text.toLowerCase().indexOf(sl) >= 0) {
          found.push(entry)
        }
      });
      this.listFiltered = found;
    } else {
      this.listFiltered = this.repliesEntries;
    }
  }

  focusSearch() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.visible) {
      if (changes.visible.currentValue === true) {
        this.focusSearch();
      }
    }

    if (changes.repliesEntries) {
      this.listFiltered = changes.repliesEntries.currentValue
    }

  }
}
