import { CallCenterMonitoringService } from './../../../../../../../providers/settings/settings-services/call-center-monitoring.service';
import { ContextService } from 'app/providers/context.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-call-center-monitoring',
  templateUrl: './call-center-monitoring.component.html',
  styleUrls: ['./call-center-monitoring.component.scss']
})
export class CallCenterMonitoringComponent implements OnInit {
  description: string;
  playingWarningTone: string
  constructor(public contextService: ContextService, public callCenterMonitoringService: CallCenterMonitoringService) { }

  ngOnInit() {
    this.callCenterMonitoringService.callCenterMonitoringChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.CallCenterMonitoring.Description');
    this.playingWarningTone = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.CallCenterMonitoring.PlayingWarningTone');
  }

}
