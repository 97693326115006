import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../providers/settings/settings-general/settings-general.service';
/**
 * This serves as a interface between AppearanceComponent and SettingsGeneral Service
 * 
 * @export
 * @class AppearanceComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-appearance',
  templateUrl: './appearance.component.html',
  styleUrls: ['./appearance.component.scss']
})

export class AppearanceComponent implements OnInit {
description:string="";
minimised:string="";
SettingsAppearanceAlwaysShowUnityOnTop:string="";
SettingsAppearanceShowMissedCallCount:string="";
SettingsAppearanceSystemTray:string="";
SettingsAppearanceTaskbar:string="";

constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.description = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.Description');
    this.minimised = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.WhenUnityMinimised');
    this.SettingsAppearanceAlwaysShowUnityOnTop = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.AlwaysShowUnityOnTop');
    this.SettingsAppearanceShowMissedCallCount = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.ShowMissedCallCount');
    this.SettingsAppearanceSystemTray = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.SystemTray');
    this.SettingsAppearanceTaskbar = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.Taskbar');
    this.settingsGeneral.settingsChanged=true;
  }

}
