import { CallCenterAuthentication } from 'app/model/agent/callCenterAuthentication';
import { StatToDisplay } from 'app/model/agent/statistics/statDisplayModel';
import { BwDirectoryEntry } from 'app/model/directory/bw-directory-entry';
import { ContextService } from '../../../providers/context.service';
import { ResponseHandler } from '../../../shared/ResponseHandler';
import { ResponseRouter } from '../../../shared/ResponseRouter';




export class OCIResponseRoutingHandler extends ResponseRouter implements ResponseHandler {
    xsi: string = "http://schema.broadsoft.com/xsi";

    type = "OCI";
    i: number = 1;
    // loggedForFirstTime: boolean = true;
    constructor(private contextService: ContextService) {
        super();
        this.contextService.log('###### constructor - OCIResponseRoutingHandler');
    }

    handle(xmlResponse: Document): void {
        this.contextService.log('###### handle - OCIResponseRoutingHandler');
        let sessionid: string = "", cc_Id: string = "", groupid: string = "", type: string = "", request: string = "", summary: string = "";

        try {

            if (xmlResponse.getElementsByTagName("command").length > 0) {
                type = xmlResponse.getElementsByTagName("command")[0].getAttribute("xsi:type");
                request = xmlResponse.getElementsByTagName("command")[0].getAttribute("echo");
            }
            if (xmlResponse.getElementsByTagName("sessionId").length > 0) {
                sessionid = xmlResponse.getElementsByTagName("sessionId")[0].textContent;
            }
            if (xmlResponse.getElementsByTagName("callCenterId").length > 0) {
                cc_Id = xmlResponse.getElementsByTagName("callCenterId")[0].textContent;
            }
            if (xmlResponse.getElementsByTagName("groupId").length > 0) {
                groupid = xmlResponse.getElementsByTagName("groupId")[0].textContent;
            }

            if (xmlResponse.getElementsByTagName("summary").length > 0) {
                summary = xmlResponse.getElementsByTagName("summary")[0].textContent;
            }
            if (this.responseHandlersList[type]) {
                this.responseHandlersList[type].handle(xmlResponse);
            } else if (request == "LoginRequest14sp4") {
                let callCenterId: string = "";
                if (this.contextService.agentDetails && this.contextService.agentDetails.callCenters) {
                    if (!this.contextService.objLoggedUserDetails.getWebClientUseOciHttp()) {
                        for (const key in this.contextService.agentDetails.callCenters) {
                            if (this.contextService.agentDetails.callCenters.hasOwnProperty(key)) {
                                if (sessionid.startsWith(key)) {
                                    callCenterId = key;
                                }

                            }
                        }
                    } else {
                        callCenterId = cc_Id;
                    }
                }

                if (type == 'c:ErrorResponse') {
                        this.contextService.errorVisible.next({ "errorType": "OCI", "errorMessage": summary, "exceptionMessage": "", "serviceName": "" });
                    
                } else if (type == 'LoginResponse14sp4') {

                    if (this.contextService.agentDetails && this.contextService.agentDetails.callCenters) {
                        if (this.contextService.agentDetails.callCenters[callCenterId] && this.contextService.agentDetails.callCenters[callCenterId]) {
                            this.contextService.agentDetails.callCenters[callCenterId].isLoggedIn = true;
                            // }
                            let statExist = false;
                            for (let index = 0; index < this.contextService.statistics.length; index++) {
                                const element = this.contextService.statistics[index];

                                if (element.queueID == callCenterId) {
                                    statExist = true;
                                }
                            }
                            if (!statExist) {
                                this.contextService.log("stat not exist");
                                this.contextService.statistics.push(new StatToDisplay(callCenterId, this.contextService.getNameFromUserID(callCenterId), this.contextService.agentDetails.callCenters[callCenterId].joined ? "join" : "leave", this.i++, this.getNumberFromUserID(callCenterId), this.contextService));

                                this.contextService.statistics.forEach(element => {
                                    if (element.queueID == callCenterId) {
                                        this.contextService.agentStatisticsList.forEach(element1 => {
                                            if (element1.threshold.highLightValues) {
                                                element.isHighlightCollection[element1.statName] = true;
                                            } else {
                                                delete element.isHighlightCollection[element1.statName];
                                            }
                                        });

                                        this.contextService.queueStatisticsList.forEach(element2 => {
                                            let callcenterInStat = element2.callCentersList[element.queueID];
                                            if (callcenterInStat.highLightValues) {
                                                element.isHighlightCollection[element2.statName] = true;
                                            }
                                            let allInStat = element2.callCentersList["all"];
                                            if (allInStat.highLightValues) {
                                                element.isHighlightCollection[element2.statName] = true;
                                            }
                                        });
                                    }
                                });
                                this.contextService.statistics = [...this.contextService.statistics];
                                this.contextService.calculateSummaryStats.next("waittime");
                            }
                            // if (this.loggedForFirstTime) {
                            this.contextService.statistics.forEach(element => {
                                if (this.contextService.objSettings.showInPersonalWBList) {
                                    if (this.contextService.objSettings.showInPersonalWBList[element.queueID]) {
                                        if (!this.contextService.objSettings.showInPersonalWBList[element.queueID].showInWallBoard && this.contextService.objSettings.defaultPasswordExist) {
                                            element.isDisplayInPersonalWallboard = false;
                                        } else {
                                            element.isDisplayInPersonalWallboard = true;
                                        }
                                    }
                                }
                            });
                            // this.contextService.refreshFrequencyTimer.next("login");
                            // this.loggedForFirstTime = false;
                            // }
                        }
                    }
                    // }
                    this.contextService.checkIfShowPersonalWallboard();
                }
            }
        } catch (error) {
            console.error(error);

        }

    }
    getNumberFromUserID(userID) {
        for (let i = 0; i < this.contextService.directoryList.length; i++) {
            if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
                let element = this.contextService.directoryList[i] as BwDirectoryEntry;
                if (element.getUserId() === userID) {
                    return element.getExtension();
                }
            }
        }
    }
}