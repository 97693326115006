import { Component, OnInit, ViewChild } from '@angular/core';
import { Dropdown } from 'primeng';
import { ContextService } from '../../../../../providers/context.service';
import { HotelingGuestService } from '../../../../../providers/settings/settings-services/hoteling-guest.service';
import { BaseComponent } from '../../../../base-component';


@Component({
  selector: 'app-hoteling-guest',
  templateUrl: './hoteling-guest.component.html',
  styleUrls: ['./hoteling-guest.component.css']
})

export class HotelingGuestComponent extends BaseComponent implements OnInit {
  description: string = "";
  hostName: string = "";
  enabled: string = "";
  isActive: boolean = false;
  loginId: string = "";
  hours: string = "";
  maximum: string = "";
  association: string = "";
  toolTip: string = "";
  unlimitedHours:string="";
  @ViewChild('dropdown') dropdown: Dropdown;
  constructor(public hotelingGuestService: HotelingGuestService, public contextService: ContextService) {
    super();
    this.hotelingGuestService.setHostId.subscribe((val) => {
      this.setHostValue(val);
    });
  }

  ngOnInit() {
    this.hotelingGuestService.hotelingGuestChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.Description');
    this.hostName = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.HostName');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.hours = this.contextService.getLanguageTagByKey('Misc.Hours');
    this.maximum = this.contextService.getLanguageTagByKey('Misc.Maximum');
    this.association = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.AssociationLimit');
    this.toolTip = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.ToolTip');
    this.unlimitedHours = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.Hours');
  }

  onChange(event) {
    // this.contextService.hotelingListWithName.forEach(element => {
    //   if(element.value===event.value){
    //     this.hotelingGuestService.hostAssociationLimitHours=element.associationLimit;
    //     if(element.enableAssociationLimit){
    //       this.hotelingGuestService.showUnlimitedAssociationText=false;
    //     } else{
    //       this.hotelingGuestService.showUnlimitedAssociationText=true;
    //     }
    //   }
    // });
}
  setHostValue(val: number) {
    this.dropdown.writeValue(val);
  }
}



