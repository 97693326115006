import { CallCenterAuthentication } from "../agent/callCenterAuthentication";

/**
 * model to store settings of the logged in user
 */
export class Settings {
  //appearence
  public appearance_AlwaysOnTop: boolean;
  public appearance_MissedCallCountVisible: boolean;

  //dialing rule
  public dialingRule_ToBeReplaced: string;
  public dialingRule_ReplacingValue: string;
  public dialingRule_forIncomingCalls: string;

  //call notification
  public callNotification_ShowRemotePartyNumber: boolean;
  public callNotification_OverrideContactName: boolean;
  public callNotification_AutoAnswer: boolean;
  public callNotification_PopUpUnity: boolean;
  public callNotification_AutoHide: boolean;
  public callNotification_PopUpSummary: boolean;
  public callNotification_PopUpSummaryDuration: number;

  //startUp
  STARTUP: number;

  // update
  update_AutoUpdatePermitted: boolean;
  update_UpdateInterval: number;
  update_RestartOrAlertAfterUpdate: string;

  // language
  language_DefaultLanguage: string;
  language_selected: string;

  // network
  network_BWServerAddress: string;
  network_BWServerPort: string;
  network_IMServerAddress: string;
  network_IMServerPort: string;

  // authentication
  broadworks_Login: string;
  broadworks_Password: string;
  broadworks_RememberBroadworksLogin: boolean;
  broadworks_RememberBroadworksPassword: boolean;


  // callcenters
  assignedCallCenters: Array<CallCenterAuthentication> = [];
  CALL_CENTER_LOGIN_FAILS: boolean;

  //Directory settings
  dir_sortOrder: string;
  dir_sortField: string;
  dir_Appearance: string;

  dir_Extension_Hidden: boolean;
  dir_Department_Hidden: boolean;
  dir_ACDStatus_Hidden: boolean;
  dir_CallDuration_Hidden: boolean;
  dir_Title_Hidden: boolean;
  dir_Email_Hidden: boolean;
  dir_Location_Hidden: boolean;
  //callLog settings
  callLog_sortOrder: string;
  callLog_sortField: string;

  //voiceMessage settings
  voiceMessage_sortOrder: string;
  voiceMessage_sortField: string;

  //chat settings
  chat_openStatus: boolean = true;


  //VoiceMail Tab
  voiceMailTab_state: boolean;

  settings_Contacts_ShowBusinessName: any;
  settings_Monitor_refresh_Time_milliSeconds: any;
  settings_HeartBeat_Interval: any;
  settings_Monitor_Loading_Time_milliSeconds: any;
  settings_HeartBeat_Timeout: any;
  settings_Connection_Timeout_milliSeconds: any;

  shutdownTime: Date;


  userLoginForTheFirstTime: boolean;
  //commpilot
  commPilotExpress_ValueOnStartup: string = "";
  commPilotExpress_ValueOnUnityStop: string = "";

  currentProduct: string;

  public settingsCollection = {};

  //agent

  //service callcenter page
  callCenter_JoinAllQueue: boolean;
  callCenter_LeaveAllQueue: boolean;
  //service agent page
  agent_AutomaticCallAnswer: boolean;
  //service acd state page
  acdState_StartupACDState: string;
  acdState_PostCallACDState: string;
  acdState_WrapUpDuration: number;
  acdState_AssignDispositionCode: boolean;
  acdState_DispositionTime: number;
  acdState_ShowCurrentACDStateButton: boolean;
  acdState_ShowDuration: string = "";
  acdState_SignOutACDState: boolean;
  agent_IncludeCallCenterCalls: boolean;
  acdState_PreventACDStateChage: boolean;
  agent_OnlyShowMonitoringSupervisor: boolean;
  agent_ShowQueuedCalls: boolean;
  queueStatistics = [];
  agentStatistics = [];
  showInPersonalWBList = [];

  statistics_DisplaySummaryStatisticsOnly: boolean;
  statistics_ScrollbarCountPersonalWB: string;
  statistics_RefreshFrequencyStartValue: string;
  statistics_RefreshFrequencyEndValue: string;
  statistics_ProcessCallCenterCount: string;
  bwGroupNamesList_Monitor = [];
  removeUserFromUserTreeArray = [];
  sessionId: string = "";
  defaultPasswordExist: boolean = false;
  //statistics sort
  stat_sortOrder: string = "";
  stat_sortField: string = "";

  showCallCenterLoginFailed: boolean;
  personalWallboardPersist: boolean = true;


  browserInt_URL: string;
  browserInt_InboundCallCenter: boolean;
  browserInt_InboundNonCallCenter: boolean;
  browserInt_OutboundCallCenter: boolean;
  browserInt_OutbountNonCallCenter: boolean;
  browserInt_EncodeURL: boolean;
  browserInt_browserInterface:boolean;
  showBusinessNameColumn: boolean;
  survey_Extension: string;
  allow_WarmTransfer_To_Voicemail: boolean;
  allow_WarmTransfer: boolean;
  callPark_Retrieve_ShowButton: boolean;
  contact_includeAllContact: boolean;
  contact_showMonitoredUsersFirst: boolean;
  contact_alwaysSortSearchByName: boolean;
  contact_includeDepartmentInSearch: boolean;
  contact_clearSearchBox: boolean;
  contact_privacyEnabled: boolean;
  contact_activateContactSearch: boolean;
  contact_startsWith: string;
  supervior_addAllAgentsToAgentList: boolean;
  agentTable_sortOrder: string;
  agentTable_sortField: string;
  supervisor_activityImage: string;
  supervisor_showOnlyJoinedAgents: boolean;
  supervisor_supervisedAgentsStaffedRatio: boolean;
  agentActivityStatistics: any;
  callPriority: boolean;
  constructor() {
    this.callPriority = false;
    this.supervior_addAllAgentsToAgentList = false;
    this.supervisor_showOnlyJoinedAgents = false;
    this.supervisor_supervisedAgentsStaffedRatio = true;
    this.showCallCenterLoginFailed = false;
    this.showInPersonalWBList = [];
    this.queueStatistics = [];
    this.agentStatistics = [];
    this.assignedCallCenters = [];
    this.bwGroupNamesList_Monitor = [];
    this.removeUserFromUserTreeArray = [];
    this.sessionId = "";
    this.defaultPasswordExist = false;
    // this.AssignedCallCenters.push({callCenterID:"extn8359@drd.co.uk",callCenterPassword:"1234@1234"});
    // this.AssignedCallCenters.push({callCenterID:"11111@drd.co.uk",callCenterPassword:"1234@1234"});
    // this.AssignedCallCenters.push({callCenterID:"22222@drd.co.uk",callCenterPassword:"1234@1234"});
    // this.AssignedCallCenters.push({callCenterID:"33333@drd.co.uk",callCenterPassword:"1234@1234"});

    this.appearance_AlwaysOnTop = false;
    this.appearance_MissedCallCountVisible = true;

    this.dialingRule_ToBeReplaced = "";
    this.dialingRule_ReplacingValue = "";
    this.dialingRule_forIncomingCalls = "1";
    this.contact_startsWith = "startsWith";
    this.callNotification_ShowRemotePartyNumber = false;
    this.callNotification_OverrideContactName = false;
    this.callNotification_AutoAnswer = false;
    this.callNotification_PopUpUnity = false;
    this.callNotification_AutoHide = false;
    this.callNotification_PopUpSummary = false;
    this.callNotification_PopUpSummaryDuration = 3;

    this.STARTUP = 3;

    this.update_AutoUpdatePermitted = true;
    this.update_UpdateInterval = 3;
    this.update_RestartOrAlertAfterUpdate = "0";

    this.language_DefaultLanguage = "en";
    this.language_selected = "";
    this.network_BWServerAddress = "";
    this.network_BWServerPort = "8011";
    this.network_IMServerAddress = "";
    this.network_IMServerPort = "";

    this.broadworks_Login = "";
    this.broadworks_Password = "";
    this.broadworks_RememberBroadworksLogin = true;
    this.broadworks_RememberBroadworksPassword = true;


    this.shutdownTime = null;
    this.userLoginForTheFirstTime = true;

    this.dir_sortOrder = '1';
    this.dir_sortField = 'name';
    this.agentTable_sortField = "name";
    this.agentTable_sortOrder = '1';
    this.dir_Appearance = 'Details';

    this.dir_Extension_Hidden = true;
    this.dir_Department_Hidden = false;
    this.dir_ACDStatus_Hidden = false;
    this.dir_CallDuration_Hidden = false;
    this.dir_Email_Hidden = true;
    this.dir_Title_Hidden = true;
    this.dir_Location_Hidden = true;
    this.callLog_sortOrder = '-1';
    this.callLog_sortField = 'time';

    this.voiceMessage_sortOrder = '-1';
    this.voiceMessage_sortField = 'date_time';

    this.commPilotExpress_ValueOnStartup = "notSet";
    this.commPilotExpress_ValueOnUnityStop = "notSet";

    this.chat_openStatus = true;

    this.voiceMailTab_state = true;

    this.currentProduct = "Unity Desktop";


    this.browserInt_URL = "";
    this.browserInt_InboundCallCenter = false;
    this.browserInt_InboundNonCallCenter = false;
    this.browserInt_OutboundCallCenter = false;
    this.browserInt_OutbountNonCallCenter = false;
    this.browserInt_EncodeURL = false;
    this.browserInt_browserInterface=false;
    this.showBusinessNameColumn = false;

    this.contact_includeAllContact = false;
    this.contact_showMonitoredUsersFirst = false;
    this.contact_alwaysSortSearchByName = false;
    this.contact_includeDepartmentInSearch = false;
    this.contact_clearSearchBox = false;
    this.contact_privacyEnabled = false;
    this.contact_activateContactSearch = false;
    //agent

    this.callCenter_JoinAllQueue = false;
    this.callCenter_LeaveAllQueue = false;
    this.agent_AutomaticCallAnswer = false;
    this.acdState_StartupACDState = "none";
    this.acdState_PostCallACDState = "none";
    this.acdState_WrapUpDuration = 0;
    this.acdState_AssignDispositionCode = false;
    this.acdState_DispositionTime = 0;
    this.acdState_ShowCurrentACDStateButton = false;
    this.acdState_ShowDuration = "none";
    this.acdState_SignOutACDState = false;
    this.agent_IncludeCallCenterCalls = true;
    this.acdState_PreventACDStateChage = false;
    this.agent_OnlyShowMonitoringSupervisor = false;
    this.agent_ShowQueuedCalls = false;
    this.statistics_DisplaySummaryStatisticsOnly = false;
    this.statistics_RefreshFrequencyEndValue = "300";
    this.statistics_RefreshFrequencyStartValue = "60";
    this.statistics_ScrollbarCountPersonalWB = "7";
    this.statistics_ProcessCallCenterCount = "1";
    this.stat_sortOrder = '1';
    this.stat_sortField = 'queueName';
    //agent
    this.survey_Extension = "";
    this.allow_WarmTransfer_To_Voicemail = false;
    this.allow_WarmTransfer = false;
    this.callPark_Retrieve_ShowButton = false;

    //supervisor
    this.supervior_addAllAgentsToAgentList = false;
    this.supervisor_activityImage = "joined"
  }

  /**
   * to get user settings from localstorage of browser
   * 
   * @param {string} loginID 
   * @memberof Settings
   */
  getSettings(loginID: string) {
    if (this.getLocalStorageItemValueByKey(loginID) != null) {

      this.settingsCollection = JSON.parse(this.getLocalStorageItemValueByKey(loginID));

      this.STARTUP = 3;
      this.sessionId = this.settingsCollection["sessionId"];
      if (this.sessionId == undefined) {
        this.sessionId = "";
      }
      this.language_DefaultLanguage = this.settingsCollection["Language_DefaultLanguage"];
      this.language_selected = this.settingsCollection["language_selected"];
      this.network_BWServerAddress = this.settingsCollection["Network_BWServerAddress"];
      this.network_BWServerPort = this.settingsCollection["Network_BWServerPort"];
      // this.network_IMServerAddress = this.settingsCollection["Network_IMServerAddress"];
      // this.network_IMServerPort = this.settingsCollection["Network_IMServerPort"];

      this.broadworks_Login = this.settingsCollection["Broadworks_Login"];

      // this.Broadworks_Password = this.settingsCollection["Broadworks_Password"];


      this.broadworks_RememberBroadworksLogin = this.settingsCollection["Broadworks_RememberBroadworksLogin"];
      this.broadworks_RememberBroadworksPassword = this.settingsCollection["Broadworks_RememberBroadworksPassword"];

      this.userLoginForTheFirstTime = this.settingsCollection["userLoginForTheFirstTime"];

      this.dir_sortOrder = this.settingsCollection["dir_sortOrder"];
      this.dir_sortField = this.settingsCollection["dir_sortField"];
      // this.Dir_Appearance = this.settingsCollection["dir_appearance"];
      this.agentTable_sortOrder = this.settingsCollection["agentTable_sortOrder"];
      this.agentTable_sortField = this.settingsCollection["agentTable_sortField"];

      this.callLog_sortOrder = this.settingsCollection["callLog_sortOrder"];
      this.callLog_sortField = this.settingsCollection["callLog_sortField"];


      this.voiceMessage_sortOrder = this.settingsCollection["voiceMessage_sortOrder"];
      this.voiceMessage_sortField = this.settingsCollection["voiceMessage_sortField"];


      this.chat_openStatus = true;

      this.stat_sortOrder = this.settingsCollection["stat_sortOrder"];
      this.stat_sortField = this.settingsCollection["stat_sortField"];

      if (this.STARTUP === 1) {
        this.currentProduct = "Unity Desktop";
      } else if (this.STARTUP === 2) {
        this.currentProduct = "Unity Agent";
        this.personalWallboardPersist = this.settingsCollection["personalWallboardPersist"];
      } else if (this.STARTUP === 3) {
        this.currentProduct = "Unity Supervisor";
        this.personalWallboardPersist = this.settingsCollection["personalWallboardPersist"];
      }
    } else {
      this.setSettings(loginID);
    }
  }


  /**
   * to get a local storage item according to key
   * 
   * @param {string} key 
   * @returns {string} 
   * @memberof Settings
   */
  getLocalStorageItemValueByKey(key: string): string {
    return localStorage.getItem(key);
  }
  /**
   * to set a local storage item using key and value
   * 
   * @param {string} key 
   * @param {string} value 
   * @memberof Settings
   */
  setLocalStorageItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  /**
   * to set settings of a user
   * 
   * @param {string} loginID 
   * @memberof Settings
   */
  setSettings(loginID: string) {
    this.settingsCollection = [];
    // localStorage.removeItem(loginID);
    // this.setLocalStorageItem(loginID, JSON.stringify(this));
    //agentp3
    this.setLocalStorageItem(loginID, JSON.stringify({ voiceMessage_sortField: this.voiceMessage_sortField, callLog_sortField: this.callLog_sortField, voiceMessage_sortOrder: this.voiceMessage_sortOrder, callLog_sortOrder: this.callLog_sortOrder, dir_sortField: this.dir_sortField, dir_sortOrder: this.dir_sortOrder, sessionId: this.sessionId, Broadworks_Login: this.broadworks_Login, Broadworks_RememberBroadworksLogin: this.broadworks_RememberBroadworksLogin, Broadworks_RememberBroadworksPassword: this.broadworks_RememberBroadworksPassword, Network_BWServerAddress: this.network_BWServerAddress, Network_BWServerPort: this.network_BWServerPort, chat_openStatus: this.chat_openStatus, language_selected: this.language_selected, stat_sortField: this.stat_sortField, stat_sortOrder: this.stat_sortOrder, userLoginForTheFirstTime: this.userLoginForTheFirstTime, personalWallboardPersist: this.personalWallboardPersist, agentTable_sortField: this.agentTable_sortField, agentTable_sortOrder: this.agentTable_sortOrder }));
    // this.setLocalStorageItem("StartUpApp", this.STARTUP.toString())
  }


}

