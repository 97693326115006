import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { VoiceMailService } from '../../../../../providers/settings/settings-services/voice-mail.service';
import { ContextService } from '../../../../../providers/context.service';


@Component({
  selector: 'app-voice-mail-service',
  templateUrl: './voice-mail.component.html',
  styleUrls: ['./voice-mail.component.scss']
})
export class VoicemailServiceComponent extends BaseComponent implements OnInit {
  description: string = "";
  enabled: string = "";
  useUnifiedMessaging: string = "";
  usePhoneMessageWaitingIndicator: string = "";
 showVisualVoicemailTab: string = "";
  forwardTheVoicemailViaEmail: string = "";
  emailNotificationOfNewVoicemails: string = "";
  emailACopyOfTheVoicemails: string = "";
  transferFromVoicemailOnZero: string = "";
  sendAllCallsToVoicemail: string = "";
  sendBusyCallsToVoicemail: string = "";
  sendUnansweredCallsToVoicemail: string = "";
  showVisualVoiceMailTab:string="";

  constructor(public voiceMailService: VoiceMailService, private contextService: ContextService) {
    super();
  }
  ngOnInit() {
    this.voiceMailService.voicemailChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.useUnifiedMessaging = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.UseUnifiedMessaging');
    this.usePhoneMessageWaitingIndicator = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.UseMwi');
    this.showVisualVoicemailTab = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.ShowVisualVoicemailTab');
    this.forwardTheVoicemailViaEmail = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.ForwardViaEmail');
    this.emailNotificationOfNewVoicemails = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.EmailNotificationOfNewVoicemails');
    this.emailACopyOfTheVoicemails = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.EmailCopyOfVoicemail');
    this.transferFromVoicemailOnZero = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.TransferFromVoicemailOnZero');
    this.sendAllCallsToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.AlwaysRedirectToVoiceMail');
    this.sendBusyCallsToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.BusyRedirectToVoiceMail');
    this.sendUnansweredCallsToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.NoAnswerRedirectToVoiceMail');
    this.showVisualVoiceMailTab = this.contextService.getLanguageTagByKey('Settings.Services.Voicemail.ShowVisualVoicemailTab');
  }

  focus(a) {
    document.getElementById(a).focus();
  }
}
