import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/takeUntil';
import { timer, Subscription, Subject } from "rxjs";
import { ContextService } from './../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { DirectoryService } from './../../providers/directory.service';
import { HelperService } from './../../providers/helper.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { HistoryEntry } from '../../model/history-entry.model'
import { NoteEntry } from '../../model/note.model'
import { Attachment } from '../../model/attachment.model';
import { User } from '../../model/user.model'
//import { ChatConversation } from "app/model/chat-conversation.model";

@Component({
  selector: 'app-call-back',
  templateUrl: './call-back.component.html',
  styleUrls: ['./call-back.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallBackComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

  callBackModalVisible: boolean = false;
  scheduleFormVisible: boolean = false;
  crmFormVisible: boolean = false;
  title: string = "";
  department: string = "";
  titleCallBack: string = "";
  reserveBtn: string = "";
  rejectBtn: string = "";
  transferBtn: string = "";
  scheduleBtn: string = "";
  closeBtn: string = "";
  sendlbl: string = "";
  viewInCRMBtn: string = "";
  addCRMBtn: string = "";
  attachmentBtn: string = "";
  addNotePlaceholder: string = "";
  user: User;

  selectedTab: string = 'notes';

  scheduleDateSelected: Date = null;

  history: Array<HistoryEntry> = [];

  // demo
  startedDateTime: Date = new Date();
  countDown: Subscription;
  tick = 1000;
  reserved: boolean = false;
  transferToList: MenuItem[] = [];
  notes: Array<NoteEntry>;
  //conversations: Array<ChatConversation> = [];

  constructor(
    private helper: HelperService, public directoryService: DirectoryService, public contextService: ContextService,
    public messagesService: MessagesService, private cd: ChangeDetectorRef
  ) {

    this.cd.detach();
  }

  ngOnInit() {


    this.messagesService.uploadFileOnPaste.subscribe(attachments => {
      if (this.callBackModalVisible && this.reserved) { // this is open, act here 
        for (var i = 0; i < attachments.length; i++) {
          this.addMessage('', attachments[i])
        }
      }
    })

    this.messagesService.openCallBackDialog.takeUntil(this.ngUnsubscribe).subscribe((open) => {
      this.cd.reattach();
      this.callBackModalVisible = open
      if (open) {
        // scroll down
        this.scrollToLatest();
      }
      this.titleCallBack = this.contextService.getLanguageTagByKey('CallBack.Title')
      this.sendlbl = this.contextService.getLanguageTagByKey('Misc.Send');
      this.reserveBtn = this.contextService.getLanguageTagByKey('Messages.Reserve');
      this.rejectBtn = this.contextService.getLanguageTagByKey('Messages.Reject');
      this.transferBtn = this.contextService.getLanguageTagByKey('Messages.Transfer');
      this.scheduleBtn = this.contextService.getLanguageTagByKey('CallBack.Schedule');
      this.addNotePlaceholder = this.contextService.getLanguageTagByKey('CallBack.AddNotePlaceholder');
      this.closeBtn = this.contextService.getLanguageTagByKey('Messages.Close');
      this.viewInCRMBtn = this.contextService.getLanguageTagByKey('Messages.ViewInCRM');
      this.addCRMBtn = this.contextService.getLanguageTagByKey('Messages.addCRMLogEntry');
      this.attachmentBtn = this.contextService.getLanguageTagByKey('Messages.AddAttachment');

      // demo
      //this.scheduleDateSelected = ??; // set saved datetime
      this.transferToList = this.messagesService.loadTransferToList(this.transferTo)
      this.loadHistory();
      this.loadMessages();
      this.loadUserInfo();
      this.countDown = timer(0, this.tick).subscribe(() => this.setTitlte());
    });
  }

  setTitlte() { // demo  
    this.department = 'Sales'
    this.title = this.titleCallBack + ': Chris Lab > ' + this.titleCallBack + ' (' + this.department + ') - ';
    this.title += this.messagesService.millisToMinutesAndSeconds(new Date().getTime() - this.startedDateTime.getTime());
  }

  loadHistory() {
    // demo  
    this.history = this.messagesService.loadHistory();
  }


  loadUserInfo() {
    // demo  
    this.user = this.messagesService.getUser(55);
  }

  loadMessages() {
    // demo  
    /*
    this.conversations.push(new ChatConversation({
      "id": "123", "people": [{ "id": "extn8004@kakaposystems.com", "name": "Arjun Harikumar" }],
      "headline": "james", "unread": 1, "offline": false, "message": "Starting new conversation demo"
    }));
    */

    this.notes = [
      new NoteEntry(
        Math.random(),
        "Callback requested by Michal Orlik",
        "Ticket number: 55",
        "Hey...call mme back",
        Date.now(),
        99,
        "note-callback"
      ),
      new NoteEntry(
        Math.random(),
        "Callback reserved by Jenson Franklin",
        "Ticket number: 55",
        "",
        Date.now(),
        99,
        "note-reserved"
      ),
      new NoteEntry(
        Math.random(),
        "Callback scheduled by M.",
        "Ticket number: 55",
        "Hey...call mme back",
        Date.now(),
        99,
        "note-scheduled"
      ),
      new NoteEntry(
        Math.random(),
        "Transfered from X to Y",
        "Ticket number: 55",
        "Hey...call mme back",
        Date.now(),
        99,
        "note-transfered"
      ),
      new NoteEntry(
        Math.random(),
        "Note added by Jenson Franklin - attached something",
        "Ticket number: 55",
        "",
        Date.now(),
        99,
        "note-note",
        new Attachment(0, "demo image.jpg", Date.now(), 99, 'http://angular.io', 255555)
      ),

    ];

  }


  onMessageSubmit = (message: string, attachment: Attachment | null): void => {
    this.addMessage(message, attachment);
  }

  addMessage = (message: string, attachment: Attachment | null) => {
    this.notes.push(
      new NoteEntry(
        Math.random(),
        "Note added by Jenson Franklin",
        "Ticket number: 55",
        this.messagesService.parseMessage(message),
        Date.now(),
        99,
        "note-note",
        attachment
      )
    );

    this.scrollToLatest();
  }

  scrollToLatest() {
    this.messagesService.scrollToBottom('callback-scroll-pane');
  }

  openTransferToCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  transferTo(person) {

  }


  reserve(event) {
    event.preventDefault();
    event.stopPropagation();

    this.reserved = true;
    this.contextService.log("reserved callback");

    return false;
  }

  reject(event) {
    event.preventDefault();
    event.stopPropagation();

    this.callBackModalVisible = false;
    this.contextService.log("rejected callback");


    return false;
  }

  transfer(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("transfer callback");

    return false;
  }

  schedule(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("schedule callback");
    this.scheduleFormVisible = true;


    return false;
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();

    this.callBackModalVisible = false;
    this.contextService.log("close callback");

    return false;
  }

  selectTab(e, tab) {
    if (e) {
      e.preventDefault();
    }

    this.selectedTab = tab;
  }

  /* CRM */
  viewInCRM(event) {
    event.preventDefault();
    event.stopPropagation();

    alert(this.viewInCRMBtn)

    return false;
  }

  addCRMLogEntry(event) {
    event.preventDefault();
    event.stopPropagation();
    this.crmFormVisible = true;

    // scroll down
    this.scrollToLatest();
    return false;
  }

  onCrmSubmit(data) {
    alert('submit')
    this.crmFormVisible = false;
    // scroll down
    this.scrollToLatest();
  }

  onCrmClose(data) {
    alert('cancel')
    this.crmFormVisible = false;
    // scroll down
    this.scrollToLatest();
  }

  /* schedule */

  hideScheduleForm() {
    this.scheduleFormVisible = false;
  }

  onScheduleSubmit(date) {
    this.scheduleDateSelected = date;
    this.addMessage('Callback scheduled by M. for ' + date.toLocaleString(), null);

    this.hideScheduleForm();
  }

  onScheduleClose(ev) {
    this.hideScheduleForm();
  }

  onScheduleClear(ev) {
    this.scheduleDateSelected = null;
    this.hideScheduleForm();
  }

  closeModal() {
    this.callBackModalVisible = false;
    setTimeout(() => { this.cd.detach(); });
  }

  ngOnDestroy() {
    this.countDown = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
