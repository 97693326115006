import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { InternalCallingLineIDDeliveryService } from '../../../../../providers/settings/settings-services/internal-calling-line-id-delivery.service';
import { ContextService } from '../../../../../providers/context.service';


/**
 * This serves as a interface between component and internalCallingLineIdDelivery service
 * 
 * @export
 * @class InternalCallingLineIDDeliveryComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-internal-calling-line-id-delivery',
  templateUrl: './internal-calling-line-id-delivery.component.html',
  styleUrls: ['./internal-calling-line-id-delivery.component.scss']
})
export class InternalCallingLineIDDeliveryComponent extends BaseComponent implements OnInit {
  description: string = '';
  enabled: string = '';
  constructor(public internalCallingLineIDDeliveryService: InternalCallingLineIDDeliveryService, private contextService: ContextService) {
    super();
  }
  ngOnInit() {
    this.internalCallingLineIDDeliveryService.internalCallingChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.InternalCallLineIdDelivery.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
  }
}

