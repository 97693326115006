import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/takeUntil';
import { timer, Subscription, Subject } from "rxjs";
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { DirectoryService } from '../../providers/directory.service';
import { HelperService } from '../../providers/helper.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { HistoryEntry } from '../../model/history-entry.model'
import { ResourceEntry } from '../../model/resource-entry.model'
import { ReplyEntry } from '../../model/reply-entry.model'
import { SmsMessage } from '../../model/sms-message.model'
import { Attachment } from 'app/model/attachment.model';
import { User } from '../../model/user.model'
//import { ChatConversation } from "app/model/chat-conversation.model";

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SmsComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;


  // c8 MMS enabled?!!
  mmsEnabled: boolean = true;

  smsModalVisible: boolean = false;
  crmFormVisible: boolean = false;
  title: string = "";
  titleWebChat: string = "";
  department: string = "";
  reserveBtn: string = "";
  rejectBtn: string = "";
  transferBtn: string = "";
  scheduleBtn: string = "";
  closeBtn: string = "";
  viewInCRMBtn: string = "";
  addCRMBtn: string = "";
  attachmentBtn: string = "";

  conversation: string = "";
  cannedReplies: string = "";
  resources: string = "";

  conversationBtn: string = "";
  respliesBtn: string = "";
  resourcesBtn: string = "";

  predefinedMessage: string = "";

  history: Array<HistoryEntry> = [];

  selectedTab: string = 'conversation';

  reserved: boolean = false;
  transferToList: MenuItem[] = [];
  messagesArray: Array<any>;
  resourcesList: Array<ResourceEntry> = [];
  respliesList: Array<ReplyEntry> = [];
  //conversations: Array<ChatConversation> = [];

  user: User;

  //demo
  startedDateTime: Date = new Date();
  countDown: Subscription;
  tick = 1000;

  constructor(
    private helper: HelperService, public directoryService: DirectoryService, public contextService: ContextService,
    public messagesService: MessagesService, private cd: ChangeDetectorRef
  ) {
    this.cd.detach();
  }

  ngOnInit() {

    this.messagesService.uploadFileOnPaste.subscribe(attachments => {
      if (this.smsModalVisible && this.reserved && this.mmsEnabled) { // this is open, act here 
        for (var i = 0; i < attachments.length; i++) {
          this.addMessage('', attachments[i])
        }
      }
    })

    this.messagesService.openSmsDialog.takeUntil(this.ngUnsubscribe).subscribe((open) => {
      this.cd.reattach();
      this.smsModalVisible = open
      if (open) {
        // scroll down
        this.scrollToLatest();
      }
      this.titleWebChat = this.contextService.getLanguageTagByKey('Sms.Title');
      this.reserveBtn = this.contextService.getLanguageTagByKey('Messages.Reserve');
      this.rejectBtn = this.contextService.getLanguageTagByKey('Messages.Reject');
      this.transferBtn = this.contextService.getLanguageTagByKey('Messages.Transfer');
      this.closeBtn = this.contextService.getLanguageTagByKey('Messages.Close');
      this.viewInCRMBtn = this.contextService.getLanguageTagByKey('Messages.ViewInCRM');
      this.addCRMBtn = this.contextService.getLanguageTagByKey('Messages.addCRMLogEntry');
      this.attachmentBtn = this.contextService.getLanguageTagByKey('Messages.AddAttachment');

      this.conversationBtn = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.respliesBtn = this.contextService.getLanguageTagByKey('Messages.Replies');
      this.resourcesBtn = this.contextService.getLanguageTagByKey('Messages.Resources');

      this.conversation = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.cannedReplies = this.contextService.getLanguageTagByKey('Messages.CannedReplies');
      this.resources = this.contextService.getLanguageTagByKey('Messages.Resources');

      // demo
      this.loadHistory();
      this.loadMessages();
      this.loadResources();
      this.loadReplies();
      this.loadUserInfo();

      this.transferToList = this.messagesService.loadTransferToList(this.transferTo)

      this.countDown = timer(0, this.tick).subscribe(() => this.setTitlte());
    })
  }

  setTitlte() { // demo  
    this.department = 'Kakapo SMS Sales Team'
    this.title = this.conversation + ': Chris Lab > ' + ' (' + this.department + ') - ';
    this.title += this.messagesService.millisToMinutesAndSeconds(new Date().getTime() - this.startedDateTime.getTime());
  }


  loadUserInfo() {
    // demo  
    this.user = this.messagesService.getUserLessInfo(55);
  }

  loadHistory() {
    // demo 
    this.history = this.messagesService.loadHistory();
  }

  loadMessages() {

    this.messagesArray = [
      new SmsMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage("Hey...!!!"),
        Date.now(),
        99,
        'Mike'
      ),
      new SmsMessage(
        Math.random(),
        false,
        this.messagesService.parseMessage("Hello everyone...!!!"),
        Date.now(),
        99,
        'Steve Tutt'
      ),
      new SmsMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage("Hey...!!!"),
        Date.now(),
        99,
        'Mike'
      ),
      new SmsMessage(
        Math.random(),
        false,
        this.messagesService.parseMessage("Hello everyone...!!!"),
        Date.now(),
        99,
        'Steve Tutt'
      ),
    ];

  }

  loadResources() {
    // demo 
    this.resourcesList = this.messagesService.loadResources();
  }

  loadReplies() {
    // demo 
    this.respliesList = this.messagesService.loadReplies();
  }

  repliesRightClick(text: string) {
    this.addMessage(text, null);
    this.showConversation()
  };

  repliesDoubleClick(text: string) {
    // add to chat box and make editable
    this.predefinedMessage = text;
    this.showConversation()
  };

  resourcesDoubleClick(att: Attachment) {
    this.addMessage(att.url + ' ', null);
    this.showConversation()
  };

  resourcesRightClick(att: Attachment) {
    this.resourcesDoubleClick(att)
    this.showConversation()
  };


  onMessageSubmit = (message: string, attachment: Attachment | null): void => {
    this.addMessage(message, attachment);
    this.showConversation()
  }

  addMessage = (message: string, attachment: Attachment | null) => {
    this.messagesArray.push(
      new SmsMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage(message),
        Date.now(),
        99,
        'Mike',
        attachment
      )
    );
    this.scrollToLatest();
  }

  scrollToLatest() {
    this.messagesService.scrollToBottom('sms-scroll-pane');
  }

  showConversation() {
    this.selectTab(null, 'conversation')
    this.scrollToLatest();
  }

  openTransferToCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  transferTo(person) {

  }

  reserve(event) {
    event.preventDefault();
    event.stopPropagation();

    this.reserved = true;
    this.contextService.log("reserved callback");

    return false;
  }

  reject(event) {
    event.preventDefault();
    event.stopPropagation();

    this.smsModalVisible = false;
    this.contextService.log("rejected callback");


    return false;
  }

  transfer(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("transfer callback");

    return false;
  }

  selectTab(e, tab) {
    if (e) {
      e.preventDefault();
    }

    this.selectedTab = tab;
  }

  /* CRM */
  viewInCRM(event) {
    event.preventDefault();
    event.stopPropagation();

    alert(this.viewInCRMBtn)

    return false;
  }

  addCRMLogEntry(event) {
    event.preventDefault();
    event.stopPropagation();
    this.crmFormVisible = true;
    this.scrollToLatest();

    return false;
  }


  onCrmSubmit(data) {
    alert('submit')
    this.crmFormVisible = false;
    this.scrollToLatest();
  }

  onCrmClose(data) {
    alert('cancel')
    this.crmFormVisible = false;
    this.scrollToLatest();
  }

  closeModal() {
    this.smsModalVisible = false;
    setTimeout(() => { this.cd.detach(); });
  }


  ngOnDestroy() {
    this.countDown = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
