import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { RemoteOfficeService } from '../../../../../providers/settings/settings-services/remote-office.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-remote-office',
  templateUrl: './remote-office.component.html',
  styleUrls: ['./remote-office.component.css']
})
export class RemoteOfficeComponent extends BaseComponent implements OnInit {
  frwdNumber: String = "";
  description: String = "";
  enabled: String = "";
  constructor(private contextService: ContextService, public remoteOfficeService: RemoteOfficeService) {
    super();
  }
  ngOnInit() {
    this.remoteOfficeService.remoteOfficeChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.RemoteOffice.Description');
    this.frwdNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
  }
  focus() {
    document.getElementById("textarea").focus();
  }
}
