import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RemoteOffice } from '../../../model/bw-services/remote-office';
import { ContextService } from '../../../providers/context.service';
import { HelperService } from '../../../providers/helper.service';
import { BwServices } from './bw-services.service';
/**
 * service for remoteoffice
 * 
 * @export
 * @class RemoteOfficeService
 */
@Injectable()
export class RemoteOfficeService {
    isActive: boolean = false;
    frwdNumber: string = "";
    numberValidation: boolean = false;
    remoteOffice: RemoteOffice;
    remoteOfficeChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService, private router: Router, private helper: HelperService) {

    }
    setData(remoteOffice: RemoteOffice) {
    }

    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    save() { }
    populateData() { }
    cancel() { }
    activateRemoteOffice() { }
    deactivateRemoteOffice() { }
}