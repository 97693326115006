<div class="row">
  <p>
    {{this.callcenterDescription}}
  </p>
</div>

<div class="row">
  <label for="" class="label">{{defaultPassword}}</label>
  <input class="input-text" type="password" name="password" [disabled]="this.settingsGeneral.defaultCCPasswordExistInLicense" [(ngModel)]="this.settingsGeneral.defaultPassword" autocomplete=off  style="width:95%">

  <div class="call-log-btns" style=float:right;margin-top:7px *ngIf="this.settingsGeneral.defaultPasswordDeleteButton===true && this.settingsGeneral.defaultCCPasswordExistInLicense=== false">
    <span class="del" (click)="removePassword()" title="{{toolTip}}"></span>
  </div>
</div>

<div class="row">
  <p>
    <b>
      {{this.callCenterLoginDetails}}
    </b>
  </p>

  <div class="box">
    <!-- <p-dataTable [value]="this.callCenterList" selectionMode="single" dataKey="id" [(selection)]="selectedCallCenter"
      (dblclick)="doubleClickCallCenterTable($event)">
    
      <p-column styleClass="ico-cell" [style]="{'width':'2%'}">
        <ng-template let-contact="rowData"  pTemplate="body">
          <span class="{{contact.icon}}"></span>

        </ng-template>
    
      </p-column>
      
      <p-column field="number"></p-column>
    </p-dataTable> -->

    <p-table [value]="this.callCenterList" selectionMode="single" [(selection)]="selectedCallCenter" dataKey="id">
        <ng-template pTemplate="header" >
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
           <tr (dblclick)="doubleClickCallCenterTable(rowData)" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData">
            <td class="ico-cell" style="width:2%">
                <span class="{{rowData.icon}}"></span>
            </td>
            <td>
                {{rowData.number}}
            </td>
          </tr>
        </ng-template>
      </p-table>
  </div>

</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="settingsGeneral.showCallCenterLoginFailedAlert" label={{loginFailsAlert}} binary="true"></p-checkbox>
</div>

<p-dialog [resizable]="false" [(visible)]="this.settingsGeneral.removePasswordVis" [closable]="false" [style]="{'width':'600px'}" [ngClass]="{visible: this.settingsGeneral.removePasswordVis}"
  class="dialog-window buttons-2" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{this.confirmDefaultPasswordDelete}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.deletePassword()">{{this.dialogYes}}</button>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.removePasswordVis = false">{{this.dialogNo}}</button>
    </div>
  </div>
</p-dialog>
