<p-dialog [(visible)]="this.loginPageVisibility" [showHeader]="false" [responsive]="true" class="login-panel" breakpoint="9000" [closable]="false"
  positionLeft="0" positionTop="0" [draggable]="false" [closeOnEscape]="false" [resizable]="false">
  <div class="modal-content">
    <div class="container-center">
      <div class="partner">
        <img src="assets/img/unity_logo.svg" alt="partner logo">
      </div>
      <form (ngSubmit)="login()" [formGroup]="loginForm" #rfRef="ngForm" id="loginForm">
        <div class="row">
          <label for="id" class="label">{{lblLoginId}}</label>
          <input type="text" id="loginID" name="id" formControlName="id" [(ngModel)]="this.loginId">
        </div>
        <div class="row">
          <label for="password" class="label">{{lblPasswd}}</label>
          <input type="password" name="password" formControlName="password" [(ngModel)]="this.password">
        </div>
        <div class="row">
          <p-checkbox formControlName="remember" name="remember" label="{{rememberLogin}}" binary="true"></p-checkbox>
        </div>
        <div class="row">
          <p-checkbox formControlName="autologin" name="autologin" label="{{rememberpswd}}" binary="true"></p-checkbox>
        </div>
        <div class="row">
          
          <p-accordion [activeIndex]=this.index (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)">
            <p-accordionTab header="Advanced Settings" styleClass="ui-state-default" >
              <div class="row">
                <label for="server" class="label">{{serverAddress}}</label>
                <input type="text" id="serverField" name="server" formControlName="server" [(ngModel)]="this.bwServerAddress">
              </div>
              <!-- <div class="row">
                <label for="port" class="label">{{ctiPort}}</label>
                <input type="text" name="port" formControlName="port" [(ngModel)]="this.serverPort">
              </div> -->
            </p-accordionTab>
          </p-accordion>

        </div>
        <div class="row">
          <div class="error" *ngIf="error.length">{{error}}</div>
          <button type="submit" form="loginForm" class="btn-blue-light" (click)="settings()">{{loginDetails}}</button>
        </div>
      </form>

    </div>
  </div>
  </p-dialog>
