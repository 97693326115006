<app-main-menu></app-main-menu>
<header id="headerTest">
  <app-buttons-menu #buttonsMenu></app-buttons-menu>
 
  <div *ngIf="urlPresent" class="partner">
    <a target="_blank" href="{{partnerLogoUrl}}" >
    <img src="{{partnerLogoPath}}"  alt="partner logo">
    </a>
  </div>
  <div *ngIf="!urlPresent" class="partner">
    <img src="{{partnerLogoPath}}" alt="partner logo" (click)="logo_Click()">
  </div>
</header>
