import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { BWAnywhereService } from '../../../../../providers/settings/settings-services/bw-anywhere.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-bw-anywhere-location-details',
  templateUrl: './bw-anywhere-location-details.component.html',
  styleUrls: ['./bw-anywhere.component.css']
})
export class BwAnywhereLocationDetailsComponent extends BaseComponent implements OnInit {
  description: string = "";
  enabled: string = "";
  phoneNumber: string = "";
  phDescription: string = "";
  outBound: string = "";
  divInhibitor: string = "";
  answer: string = "";
  callControl: String = "";
  criteria: string = "";


  constructor(public bWAnywhereService: BWAnywhereService, private contextService: ContextService) {
    super();
  }

  ngOnInit() {
    this.bWAnywhereService.bwAnyWhereChanges=true;
    this.contextService.checkBWAnywhere=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Enabled');
    this.phoneNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.phDescription = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Description');
    this.outBound = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.OutboundAlternateNumber');
    this.divInhibitor = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.EnableDiversionInhibitor');
    this.answer = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.RequireAnswerConfirmation');
    this.callControl = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.UseCallControlServices');
    this.criteria = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.ShowSelectionCriteria');

  }
  ngOnDestroy(){
   this.contextService.checkBWAnywhere=false;
   this.bWAnywhereService.validateFlag=false;
  }
  showSelectionCriteria() {
    this.bWAnywhereService.showCriteria();
  }
}