<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <p>
    <b>
      {{joinCallCenters}}
    </b>
  </p>
  <p-checkbox name="checkboxGroup1" value="checkbox" [disabled]="this.showItems===false" label={{unityStaring}} [(ngModel)]="settingsGeneral.selectJoinQueue" binary="true"></p-checkbox>
</div>

<div class="row">
  <p>
    <b>
      {{leaveCallCenters}}
    </b>
  </p>
  <p-checkbox name="checkboxGroup1" value="checkbox 1" [disabled]="this.showItems===false" label={{unityClosing}} [(ngModel)]="settingsGeneral.leaveAllQueue" binary="true"></p-checkbox>
</div>
