import { DirectoryEntry } from './directory-entry';

/**
 * This is the base model class for BwDirectoryEntry.
 * 
 * @export
 * @class BwDirectoryEntry
 */
export class BwDirectoryEntry extends DirectoryEntry {

    private userId: string;
    private firstName: string;
    private lastName: string;
    private hiranganaLastName: string;
    private hiranganaFirstName: string;
    private groupId: string;
    private extension: string;
    private department: string;
    private emailAddress: string;
    private mobile: string;
    private isEnterprise: boolean;
    public isOnline: boolean;
    imStatus: String = "";
    imStatusStartDate: Date;
    imStatusEndDate: Date;
    private isMonitored: boolean;
    private location: string;
    private title: string;
    public gettitle(): string {
        return this.title;
    }


    /**
     * Creates an instance of BwDirectoryEntry.
     * 
     * @param {string} userId
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} hiranganaLastName
     * @param {string} hiranganaFirstName
     * @param {string} groupId
     * @param {string} extension
     * @param {string} department
     * @param {string} emailAddress
     * @param {string} mobile
     * @param {boolean} isEnterprise
     * 
     * @memberOf BwDirectoryEntry
     */
    constructor(userId: string, firstName: string, lastName: string, hiranganaLastName: string, hiranganaFirstName: string, groupId: string, number: string, extension: string, department: string, emailAddress: string, mobile: string, title: string, isEnterprise: boolean,location:string) {
        super(firstName + " " + lastName, number);
        this.location = location;
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.hiranganaLastName = hiranganaLastName;
        this.hiranganaFirstName = hiranganaFirstName;
        this.groupId = groupId;
        this.extension = extension;
        this.department = department;
        this.emailAddress = emailAddress;
        this.mobile = mobile;
        this.isEnterprise = isEnterprise;
        this.isOnline = false;
        this.isMonitored = false;
        this.imStatusStartDate = null;
        this.imStatusEndDate = null;
        this.title = title;

    }
    getLocation(): string {
        return this.location;
    }
    getUserId(): string {
        return this.userId;
    }
    getFirstName(): string {
        return this.firstName;
    }
    getLastName(): string {
        return this.lastName;
    }
    getExtension(): string {
        return this.extension;
    }
    getGroupId(): string {
        return this.groupId;
    }
    getDepartment(): string {
        return this.department;
    }
    getEmailAddress(): string {
        return this.emailAddress;
    }
    getMobile(): string {
        return this.mobile;
    }
    getIsEnterprise(): boolean {
        return this.isEnterprise;
    }
    getOnline(): boolean {
        return this.isOnline;
    }
    getisMonitored() {
        return this.isMonitored;
    }
    getFullName() {
        return this.firstName + ' ' + this.lastName;
    }

}

