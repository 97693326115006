import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../providers/context.service';
import { CommPilotExpressService } from '../../../../../../providers/settings/settings-services/comm-pilot-express.service';


@Component({
  selector: 'app-available-out-of-office',
  templateUrl: './available-out-of-office.component.html',
  styleUrls: ['./available-out-of-office.component.scss']
})
export class AvailableOutOfOfficeComponent implements OnInit {
  description: string="";
  whenCallArrives: string="";
  sendCalltoVM: string="";
  frwdCall: string="";
  notifyMe: string="";

  constructor(private contextService: ContextService, public commPilotExpressService: CommPilotExpressService) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.OutOfOffice.Description');
    this.whenCallArrives = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.OutOfOffice.WhenCallArrives');
    this.sendCalltoVM = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.SendCallToVoicemail');
    this.frwdCall = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.ForwardCall');
    this.notifyMe = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Busy.NotifyMeOfNewVoicemails');
    this.commPilotExpressService.commpilotServiceChanges = true;
  }

}
