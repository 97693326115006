import { Injectable } from '@angular/core';
import { DoNotDisturb } from '../../../model/bw-services/do-not-disturb';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';


/**
 * service for do not disturb
 * 
 * @export
 * @class DoNotDisturbService
 */
@Injectable()
export class DoNotDisturbService {
    doNotDisturb: DoNotDisturb;
    isActive: boolean = false;
    isRingSplash: boolean = false;
    dndChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - DoNotDisturbService');
    }
    setData(doNotDisturb: DoNotDisturb) { }
    populateData() { }
    validate(): boolean {
        return true;
    }
    save() { }
    cancel() { }
    activateDND() { }
    deActivateDND() { }
}