import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { BwModel } from '../../../model/bw-services/bw-anywhere/bw-model';
import { BwaCriteriaDetails } from '../../../model/bw-services/bw-anywhere/bwa-location-criteria-details';
import { BwaLocationDetails } from '../../../model/bw-services/bw-anywhere/bwa-location-details';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
/**
 * to register BwAnywhereResponseHandler,BwAnywhereLocationHandler,BwAnywhereCriteriaHandler
 * 
 * @export
 * @class BWAnywhereService
 */
@Injectable()
export class BWAnywhereService {
    numberValidation: boolean = false;

    broadcast: Subject<any> = new Subject();
    public count: number = 0;
    public location: string = "";
    public criteriaName: string = "";
    public newNumber: string = "";
    currentBWPage: string = "0";
    bWAnywhereCriteriaDetails: BwaCriteriaDetails[];
    bwaLocationDetails: BwaLocationDetails[];
    bwModel: BwModel[];
    public timeSchedule = [];
    public locationArray = [];
    public criteriaArray: any[];
    isAlertAllLocation: boolean = false;
    fromDnCriteriaSelectionBool: boolean = false;
    objCurrentLocation: BwaLocationDetails;
    objCurrentCriteria: BwaCriteriaDetails;
    checkSaveCriteria: boolean = false;
    checkSaveLocationDetails: boolean = false;
    bwAnyWhereChanges: boolean = false;
    validateFlag: boolean = false;
    updateCriteriaNumber: boolean = false;
    constructor(private router: Router, private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - BWAnywhereService');
        this.bwModel = [];
        this.locationArray = [];
        this.timeSchedule = [];
        this.criteriaArray = [];
        this.bwaLocationDetails = [];
        this.bWAnywhereCriteriaDetails = [];
        this.isAlertAllLocation = false;
        this.fromDnCriteriaSelectionBool = false;


        this.contextService.broadworksCriteriaAdd.subscribe(val => {
            if (this.updateCriteriaNumber) {
                this.saveCriteria();
            }
            // this.updateCriteriaNumber=false;    
        });
    }
    setData(isAlertAllLocation: boolean, bwModel) {
    }
    populateData() {
    }
    save() {
    }
    cancel() {
    }
    addNewLocation() {
    }
    displayLocation(location: string) {
    }
    deleteLocation(selectedNumber: string) {
    }
    validateTextField(a): boolean {
        return true;
    }
    validate(input): boolean {
        return true;
    }
    validateOutboundNumber(input): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) {
    }
    saveNewLocation() {
    }
    updateLocation() {
    }
    showCriteria() {
    }
    addNewCriteria() {
    }
    displayCriteria(selecterCriteria: string) {
    }
    saveCriteria() {
    }
    updateCriteria() {
    }
    deleteCriteria(selectedCriteria) {
    }
    addPhoneNumber() {
    }
    deletePhoneNumber(selectedNumber) {
    }
}