import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { SimultaneousRingService } from '../../../../../providers/settings/settings-services/simultaneous-ring.service';
import { ContextService } from '../../../../../providers/context.service';

/**
 * Used to store Simultaneous Ring properties and this serves as a interface between component and SimultaneousRing service
 * 
 * @export
 * @class SimultaneousRingComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-simultaneous-ring',
  templateUrl: './simultaneous-ring.component.html',
  styleUrls: ['./simultaneous-ring.component.scss']
})
export class SimultaneousRingComponent extends BaseComponent implements OnInit {
  description: string = "";
  phoneNumber: string = "";
  doNotRingWhenOnACall: String = "";
  enabled: String = "";
  selectedNumber: any = "";
  delete: String = "";
  plus: String = "";

  constructor(public simultaneousRingService: SimultaneousRingService, private contextService: ContextService) {
    super();

  }

  ngOnInit() {
    this.simultaneousRingService.simultaneousChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.SimRing.Description');
    this.phoneNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.doNotRingWhenOnACall = this.contextService.getLanguageTagByKey('Settings.Services.SimRing.DoNotRingWhenOnACall');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
  }

  deleteNumber() {
    if (this.simultaneousRingService.isActive) {
      this.simultaneousRingService.deleteNumber(this.selectedNumber.number);
    }
  }
  
  focus() {
    document.getElementById("textarea").focus();
  }
}
