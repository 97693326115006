import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataService } from "app/providers/dataservice.service";
import { HelperService } from 'app/providers/helper.service';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/debounceTime';
import { CallLogEntry } from '../../model/call-logs/call-log-entry';
import { BwDirectoryEntry } from '../../model/directory/bw-directory-entry';
import { CallLogsService } from "../../providers/call-logs.service";
import { ContextService } from "../../providers/context.service";
import { DirectoryService } from './../../providers/directory.service';


@Component({
  selector: 'app-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CallLogsComponent implements OnInit {
  logsLater: Array<any> = [];
  logsRecent: Array<any> = [];
  contextMenuItems: MenuItem[];
  @ViewChild('callLogs') logsTable;
  @ViewChild('afterdropmenuCallLog') afterdropmenuCallLog;
  selectedRadio: string;
  contactTableMenuHeight;
  logTableHeight;
  logTableMenuHeight;
  scrolling: boolean = false;
  selectedContact: CallLogEntry;
  sortO: string = '';
  sortF: string = '';

  missed: string = "";
  received: string = "";
  dialed: string = "";
  miscName: string = "";
  miscPhone: string = "";
  callDate: string = "";

  //dragula bags
  bag;

  public objCallLogs: Array<CallLogEntry>;
  isOnCall: boolean = false;

  afterDropCM: MenuItem[];
  afterdropmenuCallLogLeft: string;
  afterdropmenuCallLogTop: string;
  cols: any[];
  rowGroupMetadata: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      if (this.helper.selectedTableTab === 'calllogs' && this.logsTable) {
        this.helper.calcCellsWidth(this.logsTable);
        this.calcLogsHeight();
      }
    }, 200);
  }

  constructor(private dataService: DataService,
    private helper: HelperService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    private directoryService: DirectoryService, public callLogsService: CallLogsService, private contextService: ContextService) {
    this.cd.detach();
    this.objCallLogs = [];
  }

  ngOnInit() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'phone', header: 'Phone' },
      { field: 'time', header: 'Call Date' }
    ];
    this.selectCallType(0);
    let grouped = this.groupBy(this.callLogsService.ObjCallLogs, 'period');
    this.logsLater = grouped.Later;
    this.logsRecent = grouped.Recent;

    this.missed = this.contextService.getLanguageTagByKey('TabShowing.CallLog.Missed');
    this.received = this.contextService.getLanguageTagByKey('TabShowing.CallLog.Received');
    this.dialed = this.contextService.getLanguageTagByKey('TabShowing.CallLog.Dialled');
    this.miscName = this.contextService.getLanguageTagByKey('Misc.Name');
    this.miscPhone = this.contextService.getLanguageTagByKey('Misc.Phone');
    this.callDate = this.contextService.getLanguageTagByKey('List.CallLogs.Column.CallDate');



    this.helper.tableTabChanged.subscribe((val) => {
      this.cd.reattach();
      setTimeout(() => {
        if (this.callLogsService.ObjCallLogs != null) {
          this.setSort();
          this.helper.calcCellsWidth(this.logsTable);
        }
      });
    });

    this.helper.recalcCallLogsHeight.debounceTime(250).subscribe((val) => {
      if (this.callLogsService.ObjCallLogs) {
        this.calcLogsHeight();
      }
    });

    this.helper.resized.debounceTime(600).subscribe((val) => {
      this.helper.calcCellsWidth(this.logsTable);
    });

    this.selectedRadio = 'missed';

    // unselect on drag start
    this.helper.dragStart.subscribe((val) => {
      this.selectedContact = null;
    });
    this.helper.openedMenuOnDropCallLogs.subscribe((value) => {
      const left = (value.x).toString() + 'px';
      const top = (value.y).toString() + 'px';
      this.afterdropmenuCallLogLeft = left;
      this.afterdropmenuCallLogTop = top;

      this.afterDropCM = [
        { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToNumber'), command: (event) => this.transferOnDrop(), visible: false },
      ];
      this.contextService.log("dropped to::" + this.contextService.droppedToNumber);
      setTimeout(() => {
        if ((this.contextService.droppedcall !== undefined) && (this.contextService.droppedcall !== null)) {
          if (this.contextService.droppedToNumber !== "") {
            this.afterDropCM[0].visible = true;
          } else {
            this.contextService.log("--Active Call dropped but Dropped to call Log is undefined.");
          }
        }
        this.afterdropmenuCallLog.show();

      }, 250);

    });

    if (this.contextService.objSettings.callLog_sortField && this.contextService.objSettings.callLog_sortOrder) {

      this.sortF = this.contextService.objSettings.callLog_sortField;
      this.sortO = this.contextService.objSettings.callLog_sortOrder;
    }

  }

  setSort() {

    this.logsTable.sortField = this.contextService.objSettings.callLog_sortField;

    this.logsTable.sortOrder = this.contextService.objSettings.callLog_sortOrder == "1" ? 1 : -1;

    this.logsTable.sortSingle();

  }

  loadContextMenu() {
    this.contextMenuItems = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallNumber'), command: (event) => this.callLogsService.call() },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToNumber'), command: (event) => this.callLogsService.transferCallTo(), visible: false },
      { separator: true, visible: true },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.AddTo'), command: (event) => this.addToPersonalDirectory() },
      { separator: true, visible: true },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.ViewUserDetails'), command: (event) => this.callLogsService.viewUserDetails(), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.callLogsService.instantMessage(), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CopyNumber'), command: (event) => this.callLogsService.copy() }
    ];
  }

  rowClick(e) {
    this.helper.disableRows(this.logsTable);
  }

  contextMenuSelected(event, cm) {
    this.helper.hideAllCMenus();
    this.helper.saveAllCMenus(cm);
    cm.show();
    this.isOnCall = false;
    this.helper.disableRows(this.logsTable);

    for (let index = 0; index < this.contextService.callStatusList.length; index++) {
      if (this.contextService.callStatusList[index].callStatus == 'Active') {
        this.isOnCall = true;
        break;
      } else {
        this.isOnCall = false;
      }
    }

    this.loadContextMenu();
    let objectFound;
    if (this.isOnCall) {
      objectFound = this.contextMenuItems[1];
      objectFound.visible = true;
    }
    if (event.data.loginId !== "") {
      this.contextService.log("call log set context");

      this.contextMenuItems[5].visible = true;
      objectFound = this.contextMenuItems[6];
      objectFound.visible = true;
      objectFound.separator = true;
      if (this.checkIfOnline(event.data.loginId)) {
        this.contextMenuItems[7].visible = true;
        objectFound = this.contextMenuItems[8];
        objectFound.visible = true;
        objectFound.separator = true;
      }

    }
    this.callLogsService.setSelectedCallLog(event.data);
  }
  checkIfOnline(id: string): boolean {
    for (let i = 0; i < this.contextService.directoryList.length; i++) {
      if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
        let element = this.contextService.directoryList[i] as BwDirectoryEntry;
        if ((element instanceof BwDirectoryEntry) && (element.getUserId() === id)) {
          if (this.contextService.isImConnected) {
            return true;
          }
        }
      }
    }
  }
  sortColumn(e) {
    // sorted
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  sortGroupedLogs(logs, event) {
    if (logs) {
      return [...logs.sort((a, b) => {
        if (a[event.field] < b[event.field]) {
          return -event.order;
        }
        if (a[event.field] > b[event.field]) {
          return event.order;
        }
        return 0;
      })];
    } else {
      return [];
    }

  }

  groupedSort(event) {
    let grouped = this.groupBy(this.callLogsService.ObjCallLogs, 'period');
    this.logsLater = grouped.Later;
    this.logsRecent = grouped.Recent;
    this.callLogsService.ObjCallLogs = [].concat(this.sortGroupedLogs(this.logsRecent, event)).concat(this.sortGroupedLogs(this.logsLater, event));
  }

  calcLogsHeight() {
    const heights = this.helper.calcTableHeight(this.el, this.callLogsService.ObjCallLogs, 81);
    this.logTableHeight = heights.th;
    this.logTableMenuHeight = heights.mh;
    this.scrolling = heights.scrolling;
  }

  clearLogs() {
    // clear logs
  }
  download() {
    // download
    this.contextService.log('download');
  }


  selectCallType(selectedLog: number) {
    this.callLogsService.selectedCallType(selectedLog);
  }
  //dial action when double click on calllog
  onRowDblclick(event) {
    this.contextService.websocketService.dialAction(event);

  }

  addToPersonalDirectory() {
    this.directoryService.setCallLogValues(this.callLogsService.selectedCallLog.name, this.callLogsService.selectedCallLog.phone);
    this.helper.addPersonalEntryModalVis.next(true)
  }
  transferOnDrop() {
    this.contextService.websocketService.blindTransferAction(this.contextService.droppedcall.callId, this.contextService.droppedToNumber);
  }

  changeSortCallLog(event) {
    this.updateRowGroupMetaData();
    this.contextService.objSettings.callLog_sortOrder = event.order;
    this.contextService.objSettings.callLog_sortField = event.field;
    this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());

  }
  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.callLogsService.ObjCallLogs) {
      for (let i = 0; i < this.callLogsService.ObjCallLogs.length; i++) {
        let rowData = this.callLogsService.ObjCallLogs[i];
        let period = rowData.period;
        if (i == 0) {
          this.rowGroupMetadata[period] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.callLogsService.ObjCallLogs[i - 1];
          let previousRowGroup = previousRowData.period;
          if (period === previousRowGroup)
            this.rowGroupMetadata[period].size++;
          else
            this.rowGroupMetadata[period] = { index: i, size: 1 };
        }
      }
    }
  }
}