export class Threshold {
    public highLightValues: boolean;
    public isIncreasing: boolean;
    public green: string;
    public yellow: string;
    public red: string;
    public black: string;

    constructor(highLightValues: boolean, isIncreasing: boolean, green: string, yellow: string, red: string, black: string) {

        this.highLightValues = highLightValues;
        this.isIncreasing=isIncreasing;
        this.green=green;
        this.yellow=yellow;
        this.red=red;
        this.black=black;
    }



}