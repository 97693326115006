<div class="row">
  <p>
  {{description}}
  <p>
</div>
 <div class="row">
        <label for="" class="label">{{criteriaDescription}}</label>
        <textarea name="textarea" [(ngModel)]="bWAnywhereService.objCurrentCriteria.criteriaName" rows="3"></textarea>
      </div>
        <div class="row">
        <label for="" class="label">{{schedule}}</label>
        <p-dropdown [options]="bWAnywhereService.timeSchedule" name="dropdown" [(ngModel)]="bWAnywhereService.objCurrentCriteria.name" [style]="{'width':'100%'}"></p-dropdown>
      </div>
      <div class="row">
  <label for="" class="label-block">{{callsFrom}}</label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{extPhNumber}} [(ngModel)]="bWAnywhereService.fromDnCriteriaSelectionBool"binary="true"></p-checkbox>
</div>
<div class="row">
 
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{pvtNumber}} [(ngModel)]="bWAnywhereService.objCurrentCriteria.includeAnonymousCallers"binary="true" [disabled]="bWAnywhereService.fromDnCriteriaSelectionBool"></p-checkbox>
</div>
<div class="row">
  
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{unNumber}} [(ngModel)]="bWAnywhereService.objCurrentCriteria.includeUnavailableCallers"binary="true" [disabled]="bWAnywhereService.fromDnCriteriaSelectionBool"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{theseNumbers}}</label>
  <input type="text" name="input-text" [(ngModel)]="bWAnywhereService.newNumber" [disabled]="bWAnywhereService.fromDnCriteriaSelectionBool" autocomplete=off>
</div>
  <div class="row">
  <div class="box">

    <p-table [value]="bWAnywhereService.objCurrentCriteria.phoneNumberList" selectionMode="single" [(selection)]="selectedPhoneNumber" dataKey="id" (onRowSelect)="onRowSelectNumber($event)">
        <ng-template pTemplate="header" >
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td >
                    {{rowData.location}}
                </td>
            </tr>
        </ng-template>
    </p-table>

    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="bWAnywhereService.addPhoneNumber()">{{plus}}</a>
        <a href="javascript:void(0);" (click)="deletePhoneNumber()">{{delete}}</a>
      </div>
    </div>
  </div>
</div>