<div class="attach-file">
  <input
    type="file"
    id="cb_att_{{ componentName }}"
    (change)="uploadFile($event)"
    multiple
    name="att"
  />
  <label
    for="cb_att_{{ componentName }}"
    title="{{ title }}"
    class="ico-upload"
  ></label>
</div>
