import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ContextService } from './../../providers/context.service';
import { HelperService } from './../../providers/helper.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutComponent implements OnInit {
  aboutModalVisible: boolean = false;
  browserName: string = "";
  browserVersion: string = "";
  licence: string = '';
  osBit: string = "";
  osName: string = "";
  workingFolder: string = '';
  productName: string = "";

  version: string = "";
  licenseType: string = "";
  workingFolderPath: string = "";
  operatingSystem: string = "";
  browserdetails: string = "";
  importantNotice: string = "";
  noticeBody: string = "";



  constructor(private helper: HelperService, public contextService: ContextService) {
    this.contextService.log('###### constructor - AboutComponent');
  }

  ngOnInit() {
    // modal observer 
    this.helper.aboutModalVis.subscribe(val => {
      this.aboutModalVisible = val;
      this.licence = this.getLicense();
    });

    if (this.contextService.objSettings.STARTUP === 1) {
      this.productName = "Unity Desktop Web";
    } else if (this.contextService.objSettings.STARTUP === 2) {
      this.productName = "Unity Agent Web";
    } else if (this.contextService.objSettings.STARTUP === 3) {
      this.productName = "Unity Supervisor Web"
    }
    if (navigator.userAgent.indexOf("WOW64") != -1 ||
      navigator.userAgent.indexOf("Win64") != -1) {
      this.osBit = "64-bit";
    } else {
      this.osBit = "32-bit";
    }

    this.osName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) {
      this.osName = "Windows";
    }
    if (navigator.appVersion.indexOf("Mac") != -1) {
      this.osName = "MacOS";
    }
    if (navigator.appVersion.indexOf("X11") != -1) {
      this.osName = "UNIX";
    }
    if (navigator.appVersion.indexOf("Linux") != -1) {
      this.osName = "Linux";
    }


    let browser = this.get_browser_info();
    this.browserName = browser.name;
    this.browserVersion = browser.version;
    if (this.contextService.isElectron === true) {
      this.workingFolder = this.contextService.electronService.ipcRenderer.sendSync('path', 'ping') // prints "pong"    
    }

    this.version = this.contextService.getLanguageTagByKey('About.Version');
    this.licenseType = this.contextService.getLanguageTagByKey('About.LicenseType');
    this.workingFolderPath = this.contextService.getLanguageTagByKey('About.WorkingFolder');
    this.operatingSystem = this.contextService.getLanguageTagByKey('About.OperatingSystem');
    this.browserdetails = this.contextService.getLanguageTagByKey('About.BrowserDetails');
    this.importantNotice = this.contextService.getLanguageTagByKey('About.ImportantNotice');
    this.noticeBody = this.contextService.getLanguageTagByKey('About.ImportantNotice.Body');





  }
  goToFolder() {
    this.contextService.log("going to working folder " + this.workingFolder);
    if (this.contextService.isElectron === true) {
      this.contextService.electronService.ipcRenderer.send('openpath', this.workingFolder);
    }
  }
  get_browser_info() {
    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };
  }


  getLicense() {
    let type = this.contextService.objLoggedUserDetails.getlicenseType();

    if (type == 'Trial') {
      let month = '';
      if (this.contextService.objLoggedUserDetails.getlicenseExpiryMonth() != '10' && this.contextService.objLoggedUserDetails.getlicenseExpiryMonth() != '11' && this.contextService.objLoggedUserDetails.getlicenseExpiryMonth() != '12') {
        month = '0' + this.contextService.objLoggedUserDetails.getlicenseExpiryMonth();
      }
      else {
        month = this.contextService.objLoggedUserDetails.getlicenseExpiryMonth();
      }
      return (type + " (" + this.contextService.getLanguageTagByKey('About.LicenseType.Expires') + ": " + this.contextService.objLoggedUserDetails.getlicenseExpiryDay() + "/" + month + "/" + this.contextService.objLoggedUserDetails.getlicenseExpiryYear() + ")");
    }
    else if (type == 'None') {
      return (type);
    }
    else {
      return (type + " (" + this.contextService.getLanguageTagByKey('About.LicenseType.NoExpiry') + ")");
    }
  }
  closeModal() {
    this.aboutModalVisible = false;
  }


}
