import { BWDepartment } from './bw-department';
import { BWUser } from './bw-user';
/**
 * to store users according to group
 * 
 * @export
 * @class BWGroup
 */
export class BWGroup {
    public groupName: string;
    public type: string;

    public bwDepartmentList: Array<BWDepartment>;

    public bwUserList: Array<BWUser>;

    constructor() {
        this.bwDepartmentList = [];
        this.bwUserList = [];
    }


}
