<div class="row">
  <div class="srch-field">
    <input type="text" placeholder="{{tooltip}}" #contactFilter pInputText [(ngModel)]="searchTerm" class="form-control"
      (ngModelChange)=" this.filterDirectoryDisplayArray(searchTerm)"
      (input)="contactsTable.filterGlobal($event.target.value, this.searchMode)" (focusin)="clearSearchOnActivation()">
    <span *ngIf="searchTerm.length===0" class="srch-ico">{{tooltip}}</span>
    <span *ngIf="searchTerm.length!==0" class="srch-ico-x" (click)="clearSearch()">X</span>
    <div class="srch-select" *ngIf="searchMenu" appDropdown (click)="searchmenu.toggle($event) ; contactsfilter()">
      <p-tieredMenu #searchmenu [model]="searchMenu" [popup]="true" styleClass="ui-menu-dynamic ui-shadow"
        appendTo="body"></p-tieredMenu>
    </div>
  </div>
  <div class="loc-labels" [hidden]="contactFilterType === 'location'">
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">Denver</span>
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">Engineering team</span>
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">London</span>
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">Miami</span>
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">New York</span>
    <span [primeDragula]="locBag" [dragulaOptions]="{ copy: true }" class="loc-label">San Francisco</span>
  </div>
  <!-- <ul class="alphabet" [hidden]="contactFilterType === 'alphabet'">
    <li *ngFor="let letter of alphabet;let i = index">
      <a href="#{{letter}}" (click)="letterClick(letter)" [ngClass]="{active: letter===clickedLetter}">{{letter}}</a>
    </li>
  </ul> -->
  <!-- <a (click)="switchFilter(contactFilterType)" class="btn-white">{{contactFilterType === 'alphabet' ? 'alphabet' : 'location'}}</a> -->
</div>

<p-table [value]="directoryService.directoryDisplayArray" selectionMode="single" [(selection)]="selectedContact"
  [contextMenu]="cm" [(contextMenuSelection)]="selectedContact" [sortField]="sortF" [sortOrder]="sortO"
  [resizableColumns]="false" columnResizeMode="fit" [responsive]="true" [globalFilterFields]="this.globalFilterArray"
  [scrollable]="true" scrollHeight="{{contactTableHeight}}" (onColResize)="colResized($event)"
  [ngClass]="{'scrolling':scrolling === true, 'one-col': acdHidden, 'ext-col': !extHidden, 'dep-col': !depHidden, 'contact-table-detail':layoutType === 'Detail','contact-table-small-icons':layoutType === 'Small icons','contact-table-2-cols':layoutType === '2 columns'}"
  contextMenuSelectionMode="joint" #datatable dataKey="id" id="contact-table"
  (onContextMenuSelect)="contextMenuSelected($event,cm)" (onSort)="changeSort($event)" [primeDragula]="bag"
  [dragulaModel]="directoryService.directoryDisplayArray"
  [dragulaOptions]="{ childContainerSelector: '#contact-table .ui-table-scrollable-body-table .ui-table-tbody', initAfterView: true, copy: true, childContainerSelectorID: 'contact-body'}">

  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th [pSortableColumn]="'name'">{{miscName}}
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <!-- athul -->
      <th [pSortableColumn]="'title'" *ngIf="!titleHidden">{{title}}
        <p-sortIcon [field]="'title'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'location'" *ngIf="!locationHidden">{{location}}
        <p-sortIcon [field]="'location'"></p-sortIcon>
      </th>
      <!-- athul -->
      <th [pSortableColumn]="'phone'">{{miscPhone}}
        <p-sortIcon [field]="'phone'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'businessname'" *ngIf="!this.directoryService.businessHidden">{{businessName}}
        <p-sortIcon [field]="'businessName'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'extension'" *ngIf="!extHidden">{{extension}}
        <p-sortIcon [field]="'extension'"></p-sortIcon>
      </th>
      <!-- athul -->
      <th [pSortableColumn]="'email'" *ngIf="!emailHidden">{{email}}
        <p-sortIcon [field]="'email'"></p-sortIcon>
      </th>
      <!-- athul -->
      <th [pSortableColumn]="'mobile'" *ngIf="!mobileHidden">{{mobile}}
        <p-sortIcon [field]="'mobile'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'department'" *ngIf="!depHidden">{{department}}
        <p-sortIcon [field]="'department'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'ACDState'" *ngIf="!acdHidden&&(this.contextService.objSettings.STARTUP===2 || this.contextService.objSettings.STARTUP === 3)">{{acdState}}
        <p-sortIcon [field]="'ACDState'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'status'" *ngIf="!statusHidden">{{miscStatus}}
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
      <!-- The below column is added to view personal directory contacts in proper manner. It should be an invisible column-->
      <th [pSortableColumn]="'hidden'" *ngIf="hiddenColumn">
        <p-sortIcon [field]="'hidden'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr (dblclick)="onRowDblclick(rowData)" (click)="rowClick(rowData)" [pContextMenuRow]="rowData"
      [pSelectableRow]="rowData"  (mouseenter)="mouseenterOnContact(rowData)" (mouseleave)="mouseleaveOnContact()" title="{{rowToolTip}}">
      <td class="ico-cell">
        <!-- <span class="ui-column-title" style="float:left">a</span> -->
        <span class="online-{{rowData.isOnline}}" [attr.data-id]="rowData.id"></span>
        
   <!-- //if adding new class add it in primedragula also -->
        <span class="ico-status ico-{{rowData.icon}}"></span>
      </td>
      <td class="name-cell">
        <span class="ui-column-title">{{miscName}}</span>
        {{rowData.name}}</td>
      <!-- athul -->
      <td class="title-cell" *ngIf="!titleHidden">
        <span class="ui-column-title">{{title}}</span>
        {{rowData.title}}</td>
      <td class="title-cell" *ngIf="!locationHidden">
        <span class="ui-column-title">{{location}}</span>
        {{rowData.location}}</td>
      <!-- athul -->
      <td class="phone-cell">
        <span class="ui-column-title">{{miscPhone}}</span>
        {{rowData.phone}}</td>

      <td class="business-cell" *ngIf="!this.directoryService.businessHidden">
        <span class="ui-column-title">{{businessName}}</span>
        {{rowData.companyName}}</td>

      <td class="ext-cell" *ngIf="!extHidden">
        <span class="ui-column-title">{{extension}}</span>
        {{rowData.extension}}</td>
      <!-- athul -->
      <td class="email-cell" *ngIf="!emailHidden">
        <span class="ui-column-title">{{email}}</span>
        {{rowData.email}}</td>
      <!-- athul -->
      <td class="phone-cell" *ngIf="!mobileHidden">
        <span class="ui-column-title">{{mobile}}</span>
        {{rowData.mobile}}</td>
      <td class="dep-cell" *ngIf="!depHidden">
        <span class="ui-column-title">{{department}}</span>
        {{rowData.department}}</td>
      <td class="acd-cell" *ngIf="!acdHidden&&(this.contextService.objSettings.STARTUP===2||this.contextService.objSettings.STARTUP === 3)">
        <span class="ui-column-title">{{acdState}}</span>
        {{rowData.ACDState}}</td>
      <td class="status-cell" *ngIf="!statusHidden">
        <span class="ui-column-title">{{miscStatus}}</span>
        {{rowData.status}}</td>
      <td class="status-cell" *ngIf="hiddenColumn">
        <span class="ui-column-title"></span>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="cmbox" (contextmenu)="contextMenuSelected(undefined,cmoutside)" #contactsTableMenu
  [ngStyle]="{'height': contactTableMenuHeight}"></div>

<p-contextMenu [model]="contextMenuItems" appendTo="body" #cm></p-contextMenu>
<p-contextMenu [model]="contextMenuOutside" appendTo="body" #cmoutside [target]="contactsTableMenu"></p-contextMenu>
<p-contextMenu [model]="afterDropCM" appendTo="body" #afterdropmenu
  [style]="{'left':afterdropmenuLeft,'top':afterdropmenuTop}"></p-contextMenu>