import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../../providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-call-notification',
  templateUrl: './call-notification.component.html',
  styleUrls: ['./call-notification.component.scss']
})
export class CallNotificationComponent implements OnInit {

  SettingsCtiNotificationDescription: string = "";
  SettingsAppearanceShowRemotePartyNumber: string = "";
  SettingsCtiNotificationOnCall: string = "";
  SettingsCtiNotificationAlwaysPopFromRemoteNumber: string = "";
  SettingsCtiNotificationPerformAutoAnswer: string = "";
  SettingsCtiNotificationPopUnity: string = "";
  SettingsCtiNotificationPopUnityAutoHide: string = "";
  SettingsCtiNotificationPopSummary: string = "";
  SettingsCtiNotificationFor: string = "";
  SettingsCtiNotificationSeconds: string = "";
  SettingsCtiNotificationWhenCallDetailsClicked: string = "";
  SettingsCtiNotificationWhenCallDetailsClickedPopOutlook: string = "";
  SettingsCtiNotificationPopCRM: string = "";
  SettingsCtiNotificationWhenCallDetailsClickedBrowseToUrl: string = "";

  constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {

    this.SettingsCtiNotificationDescription = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.Description');
    this.SettingsAppearanceShowRemotePartyNumber = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Appearance.ShowRemotePartyNumber');
    this.SettingsCtiNotificationOnCall = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.OnCall');
    this.SettingsCtiNotificationAlwaysPopFromRemoteNumber = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.AlwaysPopFromRemoteNumber');
    this.SettingsCtiNotificationPerformAutoAnswer = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PerformAutoAnswer');
    this.SettingsCtiNotificationPopUnity = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PopUnity');
    this.SettingsCtiNotificationPopUnityAutoHide = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PopUnity.AutoHide');
    this.SettingsCtiNotificationPopSummary = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PopSummary');
    this.SettingsCtiNotificationFor = this.settingsGeneral.contextService.getLanguageTagByKey('Misc.For');
    this.SettingsCtiNotificationSeconds = this.settingsGeneral.contextService.getLanguageTagByKey('Misc.Seconds');
    this.SettingsCtiNotificationWhenCallDetailsClicked = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.WhenCallDetailsClicked');
    this.SettingsCtiNotificationPopUnity = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PopUnity');
    this.SettingsCtiNotificationWhenCallDetailsClickedPopOutlook = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.WhenCallDetailsClicked.PopOutlook');
    this.SettingsCtiNotificationPopCRM = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.PopCRM');
    this.SettingsCtiNotificationWhenCallDetailsClickedBrowseToUrl = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Notification.WhenCallDetailsClicked.BrowseToUrl');
    this.settingsGeneral.settingsChanged=true;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
    }
}
