import { Injectable } from '@angular/core';
import { BwServiceResponseHandler } from '../../../components/settings/settings-services/settings-services-response-handler';
import { ContextService } from '../../../providers/context.service';



/**
 * to register BwServiceResponseHandler,BroadResponseHandler,BroadworksResponseHandler,AddBWAnywhereLocationCriteriaResponseHandler
 * 
 * @export
 * @class BwServices 
 */
@Injectable()
export class BwServices {
    bwServiceResponseHandler: BwServiceResponseHandler;
    constructor(public contextService: ContextService) {
        this.contextService.log('###### constructor - BwServices');

       
    }
}