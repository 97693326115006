import { Injectable } from '@angular/core';

import { ipcRenderer } from 'electron';
// import * as childProcess from 'child_process';

@Injectable()
export class ElectronService {

  ipcRenderer: typeof ipcRenderer;
  childProcess//: typeof childProcess;

  constructor() {
    //Conditional imports
    console.log('###### constructor - ElectronService');

    if (this.isElectron()) {
      // this.ipcRenderer = newwindow.require('electron').ipcRenderer;
      // this.childProcess = newwindow.require('child_process');
    }
  }

  isElectron = () => {
    // return newwindow && newwindow.process && newwindow.process.type;
    return false;
  }

}