import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { timer, Subscription } from "rxjs";
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { DirectoryService } from '../../providers/directory.service';
import { HelperService } from '../../providers/helper.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { ResourceEntry } from '../../model/resource-entry.model'
import { ReplyEntry } from '../../model/reply-entry.model'
import { Attachment } from '../../model/attachment.model';

declare const tinymce: any;

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

  emailModalVisible: boolean = false;
  tinyInit: boolean = false;
  title: string = "";
  titleEmail: string = "";
  emailsInput: string = "";
  emailsOptionalInput: string = "";
  chanel: string = "";
  reserveBtn: string = "";
  rejectBtn: string = "";
  transferBtn: string = "";
  scheduleBtn: string = "";
  closeBtn: string = "";
  sendBtn: string = "";
  attachmentBtn: string = "";
  viewInCRMBtn: string = "";
  addCRMBtn: string = "";

  conversationBtn: string = "";
  conversation: string = "";
  respliesBtn: string = "";
  resourcesBtn: string = "";

  selectedTab: string = 'conversation';

  reserved: boolean = false;
  transferToList: MenuItem[] = [];
  messagesArray: Array<any>;
  resourcesList: Array<ResourceEntry> = [];
  respliesList: Array<ReplyEntry> = [];
  detailsExpanded: boolean = false;
  //conversations: Array<ChatConversation> = [];

  //demo
  emailHtml: string = "";
  emailSubject: string = "";
  emailTo: string[] = [];
  emailCC: string[] = [];
  emailBCC: string[] = [];

  startedDateTime: Date = new Date();
  countDown: Subscription;
  tick = 1000;
  attachments: Array<Attachment> = [];

  constructor(
    private helper: HelperService, public directoryService: DirectoryService, public contextService: ContextService,
    public messagesService: MessagesService, private cd: ChangeDetectorRef
  ) {

    this.cd.detach();
  }

  ngOnInit() {

    this.messagesService.prependEmailHtml.subscribe(html => {
      this.prependHtml(html);
      this.selectTab(null, 'conversation')
    });

    this.messagesService.addEmailAttachment.subscribe(att => {
      this.addAttachment(att)
      this.selectTab(null, 'conversation')
    });

    this.messagesService.uploadFileOnPaste.subscribe(attachments => {
      if (this.emailModalVisible && this.reserved) { // this is open, act here 
        for (var i = 0; i < attachments.length; i++) {
          this.addAttachment(attachments[i])
        }
      }
    })

    this.messagesService.openEmailDialog.takeUntil(this.ngUnsubscribe).subscribe((open) => {
      this.cd.reattach();
      this.emailModalVisible = open
      setTimeout(() => {
        this.tinyInit = true;
      }, 333); // needs to be inited after visible 


      this.titleEmail = this.contextService.getLanguageTagByKey('Email.Title');
      this.reserveBtn = this.contextService.getLanguageTagByKey('Messages.Reserve');
      this.rejectBtn = this.contextService.getLanguageTagByKey('Messages.Reject');
      this.transferBtn = this.contextService.getLanguageTagByKey('Messages.Transfer');
      this.closeBtn = this.contextService.getLanguageTagByKey('Messages.Close');

      this.viewInCRMBtn = this.contextService.getLanguageTagByKey('Messages.ViewInCRM');
      this.addCRMBtn = this.contextService.getLanguageTagByKey('Messages.addCRMLogEntry');
      this.attachmentBtn = this.contextService.getLanguageTagByKey('Messages.AddAttachment');
      this.sendBtn = this.contextService.getLanguageTagByKey('Email.Send');

      this.conversationBtn = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.conversation = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.respliesBtn = this.contextService.getLanguageTagByKey('Messages.Replies');
      this.resourcesBtn = this.contextService.getLanguageTagByKey('Messages.Resources');

      this.emailsInput = this.contextService.getLanguageTagByKey('Email.Emails');
      this.emailsOptionalInput = this.contextService.getLanguageTagByKey('Email.EmailsOptional');


      // demo 
      this.loadMessages();
      this.loadResources();
      this.loadReplies();
      this.transferToList = this.messagesService.loadTransferToList(this.transferTo)

      this.emailSubject = 'The subject ...';
      this.emailTo.push('michal@code8.cz');
      this.emailCC.push('cc@code8.cz');
      this.emailBCC.push('bcc@code8.cz');

      this.countDown = timer(0, this.tick).subscribe(() => this.setTitlte());
      // tinymce.get('ID') 
      this.setContent();
    });
  }

  handlePaste(e) {

    const items = (e.event.clipboardData || e.event.originalEvent.clipboardData).items;
    let blob = null;
    var attachments: Array<Attachment> = [];

    for (const item of items) {
      if (item.kind === 'file' && item.type.indexOf('image') !== 0) {
        blob = item.getAsFile();
        attachments.push(new Attachment(0, blob.name, Date.now(), 99, '#', blob.size));
        this.messagesService.uploadFileOnPaste.next(attachments);
      }
    }

  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === ";" || event.key === "," || event.key === " ") {
      event.preventDefault();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }

  validateEmailTo(event: any): void {
    console.log(this.emailTo)
    if (!this.validateEmail(event.value)) {
      this.emailTo.pop();
    }
  }
  validateEmailCC(event: any): void {
    if (!this.validateEmail(event.value)) {
      this.emailCC.pop();
    }
  }
  validateEmailBCC(event: any): void {
    if (!this.validateEmail(event.value)) {
      this.emailBCC.pop();
    }
  }

  validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  toggleDetails(event) {
    event.preventDefault();
    event.stopPropagation();
    this.detailsExpanded = !this.detailsExpanded;
  }

  closeDetails() {
    this.detailsExpanded = false;
  }

  showReplies(e) {
    this.selectTab(e, 'replies')
    this.closeDetails();
  }

  showResources(e) {
    this.selectTab(e, 'resources')
    this.closeDetails();
  }

  repliesRightClick(text: string) {
    this.prependHtml(text);
    this.selectTab(null, 'conversation')
  };

  repliesDoubleClick(text: string) {
    this.repliesRightClick(text)
  };

  resourcesDoubleClick(att: Attachment) {
    this.addAttachment(att)
    this.selectTab(null, 'conversation')
  };

  resourcesRightClick(att: Attachment) {
    this.resourcesDoubleClick(att)
  };

  addAttachment(att) {
    console.log(att);
    this.attachments.push(att);
  }

  prependHtml(html) {
    this.emailHtml = html + this.emailHtml;
  }

  setTitlte() { // demo  
    this.chanel = 'Unity Connect Email'
    this.title = this.conversation + ': Chris Lab > ' + this.chanel + ' - ';
    this.title += this.messagesService.millisToMinutesAndSeconds(new Date().getTime() - this.startedDateTime.getTime());

  }

  setContent() {
    this.emailHtml = ` 
    <p><span style="color: #000000; font-family: Calibri, sans-serif;"><br /><strong>From:</strong> Michal Orl&iacute;k [orlik.michal@gmail.com]<br /><strong>Sent:</strong> ponděl&iacute; 7. března 2022 11:52<br /><strong>To:</strong> Unity Connect Email [unityconnect@outlook.com]<br /><strong>Subject:</strong> hello again<br /></span></p>
    <div dir="ltr">whats apppp
    <div><br clear="all" />
    <div>
    <div class="gmail_signature" dir="ltr" data-smartmail="gmail_signature">S pozdravem <br />Michal Orl&iacute;k</div>
    </div>
    </div>
    </div>
    <div id="DAB4FAD8-2DD7-40BB-A1B8-4E2AA1F9FDF2"><br />
    <table style="border: #000000 1px dashed;" data-zero_border_table="__Zero_Border_12bd15f3a20048d3a7d29bf4e6a4a9c4" data-originalstyle="BORDER-TOP: #d3d4de 1px solid">
    <tbody>
    <tr>
    <td><a href="http://www.avg.com/email-signature?utm_medium=email&amp;utm_source=link&amp;utm_campaign=sig-email&amp;utm_content=webmail" target="_blank" rel="noopener"><img alt="" width="46" height="29" /></a></td>
    <td style="font-size: 13px; font-family: Arial,Helvetica,sans-serif; width: 470px; color: #41424e; padding-top: 17px; line-height: 18px;">Bez virů. <a href="http://www.avg.com/email-signature?utm_medium=email&amp;utm_source=link&amp;utm_campaign=sig-email&amp;utm_content=webmail" target="_blank" rel="noopener">www.avg.com</a></td>
    </tr>
    </tbody>
    </table>`
  }

  loadMessages() {
    // demo  
    /*
    this.conversations.push(new ChatConversation({
      "id": "123", "people": [{ "id": "extn8004@kakaposystems.com", "name": "Arjun Harikumar" }],
      "headline": "james", "unread": 1, "offline": false, "message": "Starting new conversation demo"
    }));
    */

    this.messagesArray = [
      {
        dateTime: Date.now(),
        headline: "Mike",
        id: Math.random(),
        itsMe: true,
        person: [99],
        text: "Hey...!!!"
      },
      {
        dateTime: Date.now(),
        headline: "Steve Tutt",
        id: Math.random(),
        itsMe: false,
        person: [99],
        text: "Hello everyone...!!!"
      },
    ];

  }

  loadResources() {
    // demo 
    this.resourcesList = this.messagesService.loadResources();
  }

  loadReplies() {
    // demo 
    this.respliesList = this.messagesService.loadRepliesEmail();
  }


  openTransferToCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  transferTo(person) {

  }


  viewInCRM(event) {
    event.preventDefault();
    event.stopPropagation();

    alert(this.viewInCRMBtn)

    return false;
  }

  addCRMLogEntry(event) {
    event.preventDefault();
    event.stopPropagation();

    alert(this.addCRMBtn)

    return false;
  }

  onCrmSubmit(data) {
    this.selectTab(null, 'conversation')
  }

  onCrmClose(data) {
    this.selectTab(null, 'conversation')
  }

  uploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList && fileList.length) {
      for (var i = 0; i < fileList.length; i++) {
        var f = fileList[i];
        this.attachments.push(new Attachment(0, f.name, Date.now(), 99, '#', f.size));
      }
    }
    this.contextService.log("upload file");

    return false;
  }

  sendEmail(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("sending");

    return false;
  }

  reserve(event) {
    event.preventDefault();
    event.stopPropagation();

    this.reserved = true;
    // demo, append automatic reply footer
    this.prependHtml(`
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <hr />
    <p><span style="font-family: Calibri,sans-serif;"><strong><span style="font-size: 14pt;">Support Specialist</span></strong></span><br /><a href="http://www.kakaposystems.com"><span style="color: #0d0d0d;">www.kakaposystems.com</span></a><span style="font-size: 11pt; font-family: Calibri,sans-serif;">&nbsp; &nbsp; &nbsp;</span></p>
    <p>&nbsp;</p>
    <p><span style="font-family: Calibri,sans-serif;"><a href="https://twitter.com/KakapoSystems"><span style="color: #171717;"><img src="https://uatt.ch/repository/637371411568726051.png" /></span></a>&nbsp;&nbsp;<a href="https://www.linkedin.com/company/kakapo-systems-limited"><span style="font-size: 7pt;"><img style="height: 29px; width: 29px;" src="https://uatt.ch/repository/637371411568926070.png" alt="linkedin" /></span></a>&nbsp;&nbsp;<a href="https://www.youtube.com/channel/UCPoT0K1NHvyetKliR8qX-mA"><strong><span style="font-size: 14pt;"><img src="https://uatt.ch/repository/637371411569006110.png" alt="youtube" /></span></strong></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></p>
    <p><span style="font-family: Calibri,sans-serif;"><span style="color: #171717;"><img src="https://uatt.ch/repository/637371411569446060.png" /></span></span></p>
    <p><span style="font-family: Calibri,sans-serif;"><a href="https://www.kakaposystems.com/sms-connector"><span style="font-size: 8pt;"><img src="https://uatt.ch/repository/637371411569486121.png" /></span></a></span></p>
    <p><span style="font-family: Calibri,sans-serif;"><a href="https://www.youtube.com/watch?v=bSdiEHnuvYw&amp;feature=youtu.be"><span style="font-size: 8pt;"><img src="https://uatt.ch/repository/637371411569566098.png" /></span></a>&nbsp; <a href="https://www.kakaposystems.com/sms-connector"><span style="font-size: 8pt;"><img src="https://uatt.ch/repository/637371411569646111.png" /></span></a><span style="font-size: 8pt;">&shy;</span><br /><br /><a href="https://www.prnewswire.com/news-releases/kakapo-systems-twitter-queues-for-broadsoft-wins-tmc-2019-communications-solutions-product-of-the-year-300946561.html"><span style="font-size: 8pt;"><img src="https://uatt.ch/repository/637371411569686069.png" /></span></a></span></p>
    <p><br /><span style="font-family: Calibri,sans-serif;"><span style="background-color: white;">This email and any files transmitted with it are confidential and intended solely for the use of the individual or entity to whom they are addressed. If you have received this email in error please notify the system manager. This message contains confidential information and is intended only for the individual named. If you are not the named addressee you should not disseminate, distribute or copy this e-mail. Please notify the sender immediately by e-mail if you have received this e-mail by mistake and delete this e-mail from your system. If you are not the intended recipient you are notified that disclosing, copying, distributing or taking any action in reliance on the contents of this information is strictly prohibited.</span></span></p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>   
    <hr /> 
    `);


    this.contextService.log("reserved tw");

    return false;
  }

  reject(event) {
    event.preventDefault();
    event.stopPropagation();

    this.emailModalVisible = false;
    this.contextService.log("rejected tw");


    return false;
  }

  transfer(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("transfer tw");

    return false;
  }

  showDialogMaximized(dialog) {
    dialog.maximized = true;
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();

    this.emailModalVisible = false;
    this.tinyInit = false;
    setTimeout(() => { this.cd.detach(); });
    return false;
  }


  selectTab(e, tab) {
    if (e) {
      e.preventDefault();
    }

    this.selectedTab = tab;
  }

  ngOnDestroy() {
    this.countDown = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
