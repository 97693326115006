import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../providers/context.service';
import { CommPilotExpressService } from '../../../../../../providers/settings/settings-services/comm-pilot-express.service';

@Component({
  selector: 'app-unavailable',
  templateUrl: './unavailable.component.html',
  styleUrls: ['./unavailable.component.scss']
})
export class UnavailableComponent implements OnInit {

  description: string = "";
  divertAllCallsToVoicemail: string = "";
  butForwardCallsFromTheseNumbers: string = "";
  to: string = "";
  WhenCallSentToVoicemail: string = "";
  UseUnavailableGreeting: string = "";
  UseNoAnswerGreeting: string = "";
  selectedNumber;
  delete: string = "";
  plus: string = "";

  constructor(private contextService: ContextService, public commPilotExpressService: CommPilotExpressService) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable.Description');
    this.divertAllCallsToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.DivertAllCallsToVoicemail');
    this.butForwardCallsFromTheseNumbers = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.ButForwardCallsFromTheseNumbers');
    this.to = this.contextService.getLanguageTagByKey('Misc.To');
    this.WhenCallSentToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail');
    this.UseUnavailableGreeting = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail.UseUnavailableGreeting');
    this.UseNoAnswerGreeting = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail.UseNoAnswerGreeting');
    this.commPilotExpressService.commpilotServiceChanges = true;

    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
   this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
  }
  deleteNumberUnavilable() {
    if (this.commPilotExpressService.unavailablesendCallsToVoiceMailExceptExcludedAddresses) {
      this.commPilotExpressService.deleteUnavailable(this.selectedNumber.number);
    }
  }

}


