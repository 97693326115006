import { Component, OnInit, Input, OnChanges, ViewEncapsulation, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { User } from '../../model/user.model'
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit {

  @Input() user: User;
  menu: MenuItem[];
  emailList: MenuItem[] = [];
  phoneList: MenuItem[] = [];

  constructor(public contextService: ContextService) {

  }

  ngOnInit() {
    this.emailList = [
      { label: "Copy", icon: null, command: () => this.copyEmail(), visible: true }
    ]

    this.phoneList = [
      { label: "Copy", icon: null, command: () => this.copyPhone(), visible: true }
    ]
  }

  openCopyCm(event, cm, cm2) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    cm2.hide(event); // hide if open
    return false;
  }

  copyEmail() {
    this.contextService.copy(this.user.email)
  }

  copyPhone() {
    this.contextService.copy(this.user.phone)
  }

  call() {
    alert('call')
  }

  mailTo() {
    alert('open email')
  }
}
