<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div>
  <div class="row">
    <label for="" class="label">{{whenCallArrives}}</label>
  </div>
  <div class="row">
    <label for="" class="label-block"></label>

    <p-radioButton id="rdb_sendCalltoVM" name="groupname" value="Transfer To Voice Mail" label={{sendCalltoVM}} [(ngModel)]="commPilotExpressService.outOfficeincomingCalls" styleClass="big-radio"></p-radioButton>
  </div>
  <div class="row">
    <label for="" class="label-block"></label>
    <p-radioButton id="rdb_frwdCall" name="groupname" value="Forward" label={{frwdCall}} [(ngModel)]="commPilotExpressService.outOfficeincomingCalls" styleClass="big-radio"></p-radioButton>

  </div>
  <div class="row">
    <label for="" class="label"></label>
    <input type="text" id="txt_outOfficeforwardingAddress" name="input-text" [(ngModel)]="commPilotExpressService.outOfficeforwardingAddress" [disabled]="commPilotExpressService.outOfficeincomingCalls === 'Transfer To Voice Mail'">
  </div>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{notifyMe}} [(ngModel)]="commPilotExpressService.outOfficesendEmail"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label"></label>
  <input type="text" id="txt_outOfficeemailAddress" name="input-text" [(ngModel)]="commPilotExpressService.outOfficeemailAddress" [disabled]="!commPilotExpressService.outOfficesendEmail">
</div>