<div class="row">
  <p>
    {{SettingsLanguageDescription}}
    <p>
</div>
<div class="row">
  <label for="" class="label">{{SettingsLanguageCurrentLanguage}}</label>
  <p-dropdown [options]="languagArray" name="dropdown" [(ngModel)]="settingsGeneral.selectedLanguage" [style]="{'width':'95%'}"></p-dropdown>
  <div class="call-log-btns" style=float:right;margin-top:1px>
    <span  class="ref" (click)="languageReset();" title="Reset">reset</span>
</div>
</div>