/** 
 * C8 - model to store a webchat request
 */

import { CallStatusEntry } from './call-status/call-status-entry';

export class WebChatEntry extends CallStatusEntry {


}
