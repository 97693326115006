<div class="row">
  <p>
    {{description}}
    <p>
</div>

<p-tabView [activeIndex]="0">

  <p-tabPanel header="OCI" *ngIf="!this.contextService.objLoggedUserDetails.getWebclientUseXSI()">

    <div class="row">
      <label for="" class="label">{{serverAddressText}}</label>
      <input type="text" name="ociserverAddress" [(ngModel)]="settingsGeneral.bwServerAddress">
    </div>

    <div class="row">
      <label for="" class="label">{{tlsCertificateServerHostText}}</label>
      <input type="text" name="tlsCertificateServerHost" [(ngModel)]="this.ocioverTlsHostName" [disabled]='true'>
    </div>

    <div class="row">
      <label for="" class="label-block"></label>
      <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.webClientsUseOciOverSoap"
        label={{webClientsUseOciOverSoapText}} binary="true" [disabled]='true'></p-checkbox>
    </div>

  </p-tabPanel>

  <p-tabPanel header="XSI">

    <div class="row">
      <label for="" class="label">{{serverAddressText}}</label>
      <input type="text" name="xsiserverAddress" [disabled]="!this.contextService.objLoggedUserDetails.getWebclientUseXSI()" [(ngModel)]="settingsGeneral.xsiServerAddress">
    </div>

    <div class="row">
      <label for="" class="label-block"></label>
      <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.xsiIsSecure"
        label={{secureConnectionText}} binary="true" [disabled]='true'></p-checkbox>
    </div>
  </p-tabPanel>

  <p-tabPanel header="CTI" *ngIf="this.contextService.objLoggedUserDetails.getWebclientUseXSI()=== false">
    <div class="row">
      <p>
        <b>
          {{broadworksServerConnectionDetails}}
        </b>
      </p>
    </div>

    <div class="row">
      <label for="" class="label-block"></label>
      <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="clientUseCTI" label={{clientUseCTIText}}
        binary="true" [disabled]='true'></p-checkbox>
    </div>

    <div class="row">
      <label for="" class="label">{{serverAddressText}}</label>
      <input type="text" [disabled]='true' name="bwserverAddress" [(ngModel)]="this.ctiServerAddress">
    </div>

    <div class="row">
      <label for="" class="label">{{CTIPort}}</label>
      <input type="text" [disabled]='true' name="bwserverPort" [(ngModel)]="settingsGeneral.bwServerPort">
    </div>

    <div class="row">
      <label for="" class="label-block"></label>
      <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="ctiSecureConnection"
        label={{secureConnectionText}} binary="true" [disabled]='true'></p-checkbox>
    </div>

    <div class="row">
      <label for="" class="label">{{tlsServerPortText}}</label>
      <input type="text" name="tlsServerPort" [(ngModel)]="tlsServerPort" [disabled]='true'>
    </div>

    <div class="row">
      <label for="" class="label">{{tlsCertificateServerHostText}}</label>
      <input type="text" name="tlsCertificateServerHost" [(ngModel)]="tlsCertificateServerHost" [disabled]='true'>
    </div>

    <div class="row">
      <label for="" class="label">{{tlsSecurityProtocolText}}</label>
      <input type="text" name="tlsSecurityProtocol" [(ngModel)]="tlsSecurityProtocol" [disabled]='true'>
    </div>

  </p-tabPanel>
  <p-tabPanel header="IM&P">
    <div class="row">
      <p>
        <b>
          {{unityServerConnectionDetails}}
        </b>
      </p>
    </div>
    <div class="row">
      <label for="" class="label">{{serverAddressText}}</label>
      <input type="text" name="imserverAddress" [(ngModel)]="settingsGeneral.imServerAddress">
    </div>

    <div class="row">
      <label for="" class="label">{{serverPort}}</label>
      <input type="text" name="imserverPort" [(ngModel)]="settingsGeneral.imServerPort">
    </div>
  </p-tabPanel>
</p-tabView>