import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '../../../../base-component';
import { CommPilotExpressService } from '../../../../../providers/settings/settings-services/comm-pilot-express.service';
import { ContextService } from '../../../../../providers/context.service';
import { SettingsGeneral } from '../../../../../providers/settings/settings-general/settings-general.service';



/**
 * Used to store CommPilotExpressComponent properties and this serves as a interface between component and CommPilotExpress service
 * 
 * @export
 * @class CommPilotExpressComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-comm-pilot-express',
  templateUrl: './comm-pilot-express.component.html',
  styleUrls: ['./comm-pilot-express.component.scss']
})
export class CommPilotExpressComponent extends BaseComponent implements OnInit {
  description: string = "";
  startProfile: string = "";
  closeProfile: string = "";
  selected: string = "";

  constructor(public commPilotExpressService: CommPilotExpressService, public contextService: ContextService, public settingsGeneral:SettingsGeneral) {
    super();
  }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Description');
    this.startProfile = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.WhenUnityStarting');
    this.closeProfile = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.WhenUnityClosing');
    this.commPilotExpressService.commpilotProfileChanges = true;
    this.settingsGeneral.settingsChanged=true;
  }

}
