import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/operator/takeUntil';
import { BwDirectoryEntry } from '../../model/directory/bw-directory-entry';
import { DirectoryView } from '../../model/directory/directory-view';
import { ContextService } from "../../providers/context.service";

/**
 * component to view selected user details
 * 
 * @export
 * @class ViewUserDetailsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-view-user-details',
  templateUrl: './view-user-details.component.html',
  styleUrls: ['./view-user-details.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ViewUserDetailsComponent implements OnInit {
  name: string = '';
  title: string = '';
  email: string = '';
  mobile: string = '';
  phone: string = '';
  department: string = '';
  location: string = '';

  viewUserDetailsModalVisible: boolean = false;
  objDirectoryView: DirectoryView;

  userDetailsTitle: string = "";
  userDetailsEmail: string = "";
  userDetailsPhone: string = "";
  userDetailsMobile: string = "";
  userDetailsDepartment: string = "";
  userDetailsLocation: string = "";

  callButtonShowingOk: string = "";


  constructor(public contextService: ContextService) {

  }
  /**
   * to initiallize ViewUserDetailsComponent
   * 
   * @memberof ViewUserDetailsComponent
   */
  ngOnInit() {
    this.userDetailsTitle = this.contextService.getLanguageTagByKey('UserDetails.Title');
    this.userDetailsEmail = this.contextService.getLanguageTagByKey('UserDetails.Email');
    this.userDetailsPhone = this.contextService.getLanguageTagByKey('Misc.Phone');
    this.userDetailsMobile = this.contextService.getLanguageTagByKey('UserDetails.Mobile');
    this.callButtonShowingOk = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.userDetailsDepartment = this.contextService.getLanguageTagByKey('List.UserStatus.Column.Department');
    this.userDetailsLocation = this.contextService.getLanguageTagByKey('Settings.Update.Runtime.DownloadPath');

    this.contextService.viewUserDetailsModalVis.subscribe(val => {
      this.contextService.directoryList.forEach(element => {
        if (element instanceof BwDirectoryEntry) {
          if (element.getMobile().length > 0) {
            if (DirectoryView.getBwDirectoryView(element, true).userId === val) {
              this.objDirectoryView = DirectoryView.getBwDirectoryView(element, true);
            }
          }
          if (DirectoryView.getBwDirectoryView(element, false).userId === val) {
            this.objDirectoryView = DirectoryView.getBwDirectoryView(element, false);
          }
        }
      });
      this.title = this.objDirectoryView.title;
      this.email = this.objDirectoryView.email;
      this.mobile = this.objDirectoryView.mobile;
      this.phone = this.objDirectoryView.phone;
      this.viewUserDetailsModalVisible = true;
      this.name = this.objDirectoryView.name;
      this.department=this.objDirectoryView.department;
      this.location=this.objDirectoryView.location;
    });
  }
  /**
   * to close the modal
   * 
   * @memberof ViewUserDetailsComponent
   */
  closeModal() {
    this.viewUserDetailsModalVisible = false;
  }
}