import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SupervisorComponent } from "../app/components/settings/settings-services/incoming-calls/call-center/supervisor/supervisor.component";
import { SettingsGeneralComponent } from './components/settings/settings-general/settings-general.component';
import { AppearanceComponent } from './components/settings/settings-general/unity/appearance/appearance.component';
import { ContactSearchComponent } from './components/settings/settings-general/unity/appearance/contact-search/contact-search.component';
import { BrowserIntegrationComponent } from './components/settings/settings-general/unity/computer-phone-integration/browser-integration/browser-integration.component';
import { CallNotificationComponent } from './components/settings/settings-general/unity/computer-phone-integration/call-notification/call-notification.component';
import { DialingRuleComponent } from './components/settings/settings-general/unity/computer-phone-integration/dialing-rule/dialing-rule.component';
//agentP3
import { OutlookIntegrationComponent } from './components/settings/settings-general/unity/computer-phone-integration/outlook-integration/outlook-integration.component';
import { AuthenticationComponent } from './components/settings/settings-general/unity/connection/authentication/authentication.component';
import { CallCenterAuthenticationDetailsComponent } from "./components/settings/settings-general/unity/connection/authentication/call-centers-authentication/call-center-authentication-details/call-center-authentication-details.component";
import { CallCentersAuthenticationComponent } from "./components/settings/settings-general/unity/connection/authentication/call-centers-authentication/call-centers-authentication.component";
import { NetworkComponent } from './components/settings/settings-general/unity/connection/network/network.component';
import { LanguageComponent } from './components/settings/settings-general/unity/language/language.component';
import { StartupComponent } from './components/settings/settings-general/unity/startup/startup.component';
import { UpdateComponent } from './components/settings/settings-general/unity/update/update.component';
import { BwAnywhereCriteriaDetailsComponent } from './components/settings/settings-services/call-control/bw-anywhere/bw-anywhere-criteria-details.component';
import { BwAnywhereCriteriaComponent } from './components/settings/settings-services/call-control/bw-anywhere/bw-anywhere-criteria.component';
import { BwAnywhereLocationDetailsComponent } from './components/settings/settings-services/call-control/bw-anywhere/bw-anywhere-location-details.component';
import { BwAnywhereComponent } from './components/settings/settings-services/call-control/bw-anywhere/bw-anywhere.component';
import { CallParkRetrieveComponent } from './components/settings/settings-services/call-control/call-park-retrieve/call-park-retrieve.component';
import { CallRecordingComponent } from './components/settings/settings-services/call-control/call-recording/call-recording.component';
import { CallTransferComponent } from './components/settings/settings-services/call-control/call-transfer/call-transfer.component';
import { CallWaitingComponent } from './components/settings/settings-services/call-control/call-waiting/call-waiting.component';
import { DirectedCallPickupWithBargeInComponent } from './components/settings/settings-services/call-control/directed-call-pickup-with-barge-in/directed-call-pickup-with-barge-in.component';
import { FlexibleSeatingGuestComponent } from './components/settings/settings-services/call-control/flexible-seating-guest/flexible-seating-guest.component';
import { HotelingGuestComponent } from './components/settings/settings-services/call-control/hoteling-guest/hoteling-guest.component';
import { RemoteOfficeComponent } from './components/settings/settings-services/call-control/remote-office/remote-office.component';
import { AnonymousCallRejectionComponent } from './components/settings/settings-services/incoming-calls/anonymous-call-rejection/anonymous-call-rejection.component';
import { AcdStateComponent } from "./components/settings/settings-services/incoming-calls/call-center/agent/acd-state/acd-state.component";
import { AgentComponent } from "./components/settings/settings-services/incoming-calls/call-center/agent/agent.component";
//agent
import { CallCenterComponent } from "./components/settings/settings-services/incoming-calls/call-center/call-center.component";
import { AgentActivityThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/agent-activity-threshold/agent-activity-threshold.component';
import { AgentThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/agent-threshold/agent-threshold.component';
import { CallcenterThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/callcenter-threshold/callcenter-threshold.component';
//agent
//agentP3
import { ColumnsComponent } from "./components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/columns.component";
import { StatisticsServiceComponent } from "./components/settings/settings-services/incoming-calls/call-center/statistics-service/statistics-service.component";
import { CallCenterMonitoringComponent } from './components/settings/settings-services/incoming-calls/call-center/supervisor/call-center-monitoring/call-center-monitoring.component';
import { CallForwardNoAnswerComponent } from './components/settings/settings-services/incoming-calls/call-forward-no-answer/call-forward-no-answer.component';
import { CallForwardingAlways } from './components/settings/settings-services/incoming-calls/call-forwarding-always/call-forwarding-always.component';
import { CallForwardingBusyComponent } from './components/settings/settings-services/incoming-calls/call-forwarding-busy/call-forwarding-busy.component';
import { AvailableInOfficeComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/available-in-office/available-in-office.component';
import { AvailableOutOfOfficeComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/available-out-of-office/available-out-of-office.component';
import { BusyComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/busy/busy.component';
import { CommPilotExpressComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/comm-pilot-express.component';
import { UnavailableComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/unavailable/unavailable.component';
import { DoNotDisturbComponent } from './components/settings/settings-services/incoming-calls/do-not-disturb/do-not-disturb.component';
import { ExternalCallingLineIDDeliveryComponent } from './components/settings/settings-services/incoming-calls/external-calling-line-id-delivery/external-calling-line-id-delivery.component';
import { InternalCallingLineIDDeliveryComponent } from './components/settings/settings-services/incoming-calls/internal-calling-line-id-delivery/internal-calling-line-id-delivery.component';
import { SimultaneousRingComponent } from './components/settings/settings-services/incoming-calls/simultaneous-ring/simultaneous-ring.component';
import { VoicemailServiceComponent } from './components/settings/settings-services/messaging/voice-mail/voice-mail.component';
import { CallingLineIDDeliveryBlockingComponent } from './components/settings/settings-services/outgoing-calls/calling-line-id-delivery-blocking/calling-line-id-delivery-blocking.component';
import { ServiceComponent } from './components/settings/settings-services/service/service.component';
import { SettingsServicesComponent } from './components/settings/settings-services/settings-services.component';






const  appRoutes: Routes = [
    {
        path: 'settings-service', component: SettingsServicesComponent, children: [
            { path: 'service', component: ServiceComponent },
            { path: 'anonymous-call-rejection', component: AnonymousCallRejectionComponent },
            { path: 'call-forwarding-always', component: CallForwardingAlways },
            { path: 'call-forwarding-busy', component: CallForwardingBusyComponent },
            { path: 'call-forward-no-answer', component: CallForwardNoAnswerComponent },
            { path: 'comm-pilot-express', component: CommPilotExpressComponent },
            { path: 'available-in-office', component: AvailableInOfficeComponent },
            { path: 'available-out-of-office', component: AvailableOutOfOfficeComponent },
            { path: 'busy', component: BusyComponent },
            { path: 'do-not-disturb', component: DoNotDisturbComponent },
            { path: 'external-calling-line-id-delivery', component: ExternalCallingLineIDDeliveryComponent },
            { path: 'internal-calling-line-id-delivery', component: InternalCallingLineIDDeliveryComponent },
            { path: 'simultaneous-ring', component: SimultaneousRingComponent },
            { path: 'unavailable', component: UnavailableComponent },
            { path: 'call-recording', component: CallRecordingComponent },
            { path: 'bw-anywhere', component: BwAnywhereComponent },

            { path: 'bw-anywhere-location-details', component: BwAnywhereLocationDetailsComponent },
            { path: 'bw-anywhere-criteria', component: BwAnywhereCriteriaComponent },
            { path: 'bw-anywhere-criteria-details', component: BwAnywhereCriteriaDetailsComponent },

            { path: 'hoteling-guest', component: HotelingGuestComponent },
            { path: 'remote-office', component: RemoteOfficeComponent },
            { path: 'call-waiting', component: CallWaitingComponent },
            { path: 'flexible-seating-guest', component: FlexibleSeatingGuestComponent },
            { path: 'calling-line-id-delivery-blocking', component: CallingLineIDDeliveryBlockingComponent},
            { path: 'voice-mail-service', component: VoicemailServiceComponent},
            { path: 'call-center', component: CallCenterComponent },
            { path: 'agent', component: AgentComponent },
            { path: 'acd-state', component: AcdStateComponent },
            { path: 'statistics', component: StatisticsServiceComponent },
            { path: 'agent-threshold', component: AgentThresholdComponent },
            { path: 'callcenter-threshold', component: CallcenterThresholdComponent },
            { path: 'columns', component: ColumnsComponent },
            {path:'call-transfer', component:CallTransferComponent},
            {path:'directed-call-pickup-with-barge-in',component:DirectedCallPickupWithBargeInComponent},
            {path:'call-park-retrieve',component:CallParkRetrieveComponent},
            {path:'supervisor',component:SupervisorComponent},
            { path: 'agent-activity-threshold', component: AgentActivityThresholdComponent },
            {path:'call-center-monitoring', component:CallCenterMonitoringComponent}
        ]
    },
    {
        path: 'settings-general', component: SettingsGeneralComponent, children: [
            { path: 'appearance', component: AppearanceComponent },
            { path: 'contact-search', component: ContactSearchComponent },
             { path: 'dialing-rule', component: DialingRuleComponent },
             { path: 'outlook-integration', component: OutlookIntegrationComponent },
             {path:'browser-integration', component:BrowserIntegrationComponent},
             { path: 'call-notification', component: CallNotificationComponent },
              { path: 'startup', component: StartupComponent },
              { path: 'update', component: UpdateComponent },
              { path: 'language', component: LanguageComponent },
              { path:'authentication',component:AuthenticationComponent},
              {path:'network',component:NetworkComponent},
              { path: 'call-centers-authentication', component: CallCentersAuthenticationComponent },
              { path: 'center-authentication-details', component: CallCenterAuthenticationDetailsComponent }
     
        ]
    },
]

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {

}