import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../../providers/settings/settings-general/settings-general.service';
import { ContextService } from "./../../../../../../providers/context.service";
@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  description: string = "";
  broadworksServerConnectionDetails: string = "";
  serverAddressText: string = "";
  serverPort: string = "";
  unityServerConnectionDetails: string = "";
  logConnections: string = "";
  logTrace: string = "";
  CTIPort: string = "";
  secureConnectionText: string = "";
  tlsServerPortText: string = "";
  tlsCertificateServerHostText: string = "";
  tlsSecurityProtocolText: string = "";
  clientUseCTIText: string = "";

  ctiSecureConnection: boolean = false;
  tlsServerPort: string = "";
  tlsCertificateServerHost: string = "";
  tlsSecurityProtocol: string = "";
  clientUseCTI: boolean = false;
  serverAddress: string = "";
  ctiServerAddress: string = "";
  ocioverTlsHostName: string = "";
  xsiIsSecure: boolean = false;
  webClientsUseOciOverSoap: boolean = false;
  webClientsUseOciOverSoapText: string = "";
  constructor(public settingsGeneral: SettingsGeneral, public contextService: ContextService) { }

  ngOnInit() {
    this.CTIPort = this.contextService.getLanguageTagByKey('Settings.Connection.Network.CTIPort');
    this.description = this.contextService.getLanguageTagByKey('Settings.Connection.Network.Description');
    this.broadworksServerConnectionDetails = this.contextService.getLanguageTagByKey('Settings.Connection.Network.BroadworksServerConnectionDetails');
    this.serverAddressText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.ServerAddress');
    this.serverPort = this.contextService.getLanguageTagByKey('Settings.Connection.Network.ServerPort');
    this.unityServerConnectionDetails = this.contextService.getLanguageTagByKey('Settings.Connection.Network.UnityServerConnectionDetails');
    this.logConnections = this.contextService.getLanguageTagByKey('Settings.Connection.Network.LogConnections');
    this.logTrace = this.contextService.getLanguageTagByKey('Settings.Connection.Network.LogTrace');
    this.settingsGeneral.settingsChanged = true;
    this.secureConnectionText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.ConnectionIsSecure');
    this.tlsServerPortText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.TlsServerPort');
    this.tlsCertificateServerHostText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.TlsCertificateServerHost');
    this.tlsSecurityProtocolText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.TlsSecurityProtocol');
    this.clientUseCTIText = this.contextService.getLanguageTagByKey('Settings.Connection.Network.ClientsUseCti');
    this.webClientsUseOciOverSoapText = "Use OCI-P over SOAP";
    if (this.contextService.objLoggedUserDetails) {
      this.webClientsUseOciOverSoap=this.contextService.objLoggedUserDetails.getWebClientUseOciHttp();
      this.ctiSecureConnection = this.contextService.objLoggedUserDetails.getctiIsSecure();
      this.tlsCertificateServerHost = this.contextService.objLoggedUserDetails.getctiOverTlsServerHostName();
      this.tlsServerPort = this.contextService.objLoggedUserDetails.getctiOverTlsServerPort();
      this.clientUseCTI = this.contextService.objLoggedUserDetails.getclientsUseCti();
      if (this.contextService.objLoggedUserDetails.getctiOverTlsSecurityProtocol() == "tls" || this.contextService.objLoggedUserDetails.getctiOverTlsSecurityProtocol() == "2") {
        this.tlsSecurityProtocol = "TLS 1";
      }
      else if (this.contextService.objLoggedUserDetails.getctiOverTlsSecurityProtocol() == "tls11" || this.contextService.objLoggedUserDetails.getctiOverTlsSecurityProtocol() == "3") {
        this.tlsSecurityProtocol = "TLS 1.1";
      }
      else {
        this.tlsSecurityProtocol = "TLS 1.2";
      }
    }

    if (this.contextService.objLoggedUserDetails.getctiServerHost()) {
      this.ctiServerAddress = this.contextService.objLoggedUserDetails.getctiServerHost();
    } else {
      this.ctiServerAddress = this.settingsGeneral.bwServerAddress;
    }
    this.ocioverTlsHostName = this.contextService.objLoggedUserDetails.getociOverTlsServerHostName();

    if (this.contextService.objLoggedUserDetails.getxsiSecurityProtocol() === "0") {
      this.xsiIsSecure = false;
    } else {
      this.xsiIsSecure = true;
    }
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}



