import { loggedUserCallCenterList } from "../../../model/agent/loggedUserCallCenterList";
import { ContextService } from '../../../providers/context.service';
import { ResponseHandler } from '../../../shared/ResponseHandler';
import { GetAgentCallCenterConfigurationResponseHandler } from "./get-agent-call-center-configuration-response-handler";
import { OCIResponseRoutingHandler } from "./oci-responses-routing-handler";
export class AgentCallCenterListResponseHandler implements ResponseHandler {


    xsp: string = "http://schema.broadsoft.com/XspXMLInterface";
    xsi: string = "http://schema.broadsoft.com/xsi";
    xsi1: string = "http://www.w3.org/2001/XMLSchema-instance";
    type = "CallCenter";

    sendCallCenterLoginRequest: boolean = false;
    constructor(private contextService: ContextService) {
        this.contextService.log('###### constructor - AgentCallCenterListResponseHandler');

    }

    handle(xmlResponse: Document): void {
        this.contextService.log("AgentCallCenterListResponseHandler------handle()");
        let CallCenter, callCenterList = [];
        let useDefaultGuardTimer: boolean = false, agentACDState: string = "", enableGuardTimer: boolean = false, guardTimerSeconds: string = "", useSystemDefaultUnavailableSettings: boolean = false,
            forceAgentUnavailableOnDNDActivation: boolean = false, forceUnavailableOnPersonalCalls: boolean = false, forceAgentUnavailableOnBouncedCallLimit: boolean = false,
            numberConsecutiveBouncedCallsToForceAgentUnavailable: string = "", makeOutgoingCallsAsCallCenter: boolean = false, DNISserviceUserId: string = "", DNISname: string = "";
        if (xmlResponse.getElementsByTagName("CallCenter").length > 0) {
            CallCenter = xmlResponse.getElementsByTagName("CallCenter")[0];
        }
        if (CallCenter.getElementsByTagName("agentACDState").length > 0) {
            agentACDState = CallCenter.getElementsByTagName("agentACDState")[0].textContent;
        }
        if (CallCenter.getElementsByTagName("useDefaultGuardTimer").length > 0) {
            useDefaultGuardTimer = ((CallCenter.getElementsByTagName("useDefaultGuardTimer")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("enableGuardTimer").length > 0) {
            enableGuardTimer = ((CallCenter.getElementsByTagName("enableGuardTimer")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("guardTimerSeconds").length > 0) {
            guardTimerSeconds = CallCenter.getElementsByTagName("guardTimerSeconds")[0].textContent;
        }
        if (CallCenter.getElementsByTagName("useSystemDefaultUnavailableSettings").length > 0) {
            useSystemDefaultUnavailableSettings = ((CallCenter.getElementsByTagName("useSystemDefaultUnavailableSettings")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("forceAgentUnavailableOnDNDActivation").length > 0) {
            forceAgentUnavailableOnDNDActivation = ((CallCenter.getElementsByTagName("forceAgentUnavailableOnDNDActivation")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("forceUnavailableOnPersonalCalls").length > 0) {
            forceUnavailableOnPersonalCalls = ((CallCenter.getElementsByTagName("forceUnavailableOnPersonalCalls")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("forceAgentUnavailableOnBouncedCallLimit").length > 0) {
            forceAgentUnavailableOnBouncedCallLimit = ((CallCenter.getElementsByTagName("forceAgentUnavailableOnBouncedCallLimit")[0].textContent) == 'true');
        }
        if (CallCenter.getElementsByTagName("numberConsecutiveBouncedCallsToForceAgentUnavailable").length > 0) {
            numberConsecutiveBouncedCallsToForceAgentUnavailable = CallCenter.getElementsByTagName("numberConsecutiveBouncedCallsToForceAgentUnavailable")[0].textContent;
        }
        if (CallCenter.getElementsByTagName("makeOutgoingCallsAsCallCenter").length > 0) {
            makeOutgoingCallsAsCallCenter = ((CallCenter.getElementsByTagName("makeOutgoingCallsAsCallCenter")[0].textContent) == 'true');
        }

        if (makeOutgoingCallsAsCallCenter) {
            let outgoingCallDNIS = CallCenter.getElementsByTagName("outgoingCallDNIS")[0];
            if (outgoingCallDNIS) {
                if (outgoingCallDNIS.getElementsByTagName("serviceUserId").length > 0) {
                    DNISserviceUserId = outgoingCallDNIS.getElementsByTagName("serviceUserId")[0].textContent;
                }
                if (outgoingCallDNIS.getElementsByTagName("name").length > 0) {
                    DNISname = outgoingCallDNIS.getElementsByTagName("name")[0].textContent;
                }
            }
        }

        this.contextService.agentDetails.useDefaultGuardTimer = useDefaultGuardTimer;
        this.contextService.agentDetails.enableGuardTimer = enableGuardTimer;
        this.contextService.agentDetails.guardTimerSeconds = guardTimerSeconds;
        this.contextService.agentDetails.useSystemDefaultUnavailableSettings = useSystemDefaultUnavailableSettings;
        this.contextService.agentDetails.forceAgentUnavailableOnDNDActivation = forceAgentUnavailableOnDNDActivation;
        this.contextService.agentDetails.forceUnavailableOnPersonalCalls = forceUnavailableOnPersonalCalls;
        this.contextService.agentDetails.forceAgentUnavailableOnBouncedCallLimit = forceAgentUnavailableOnBouncedCallLimit;
        this.contextService.agentDetails.numberConsecutiveBouncedCallsToForceAgentUnavailable = numberConsecutiveBouncedCallsToForceAgentUnavailable;
        this.contextService.agentDetails.makeOutgoingCallsAsCallCenter = makeOutgoingCallsAsCallCenter;
        this.contextService.agentDetails.dnisServiceUserId = DNISserviceUserId;
        this.contextService.agentDetails.dnisName = DNISname;



        let serviceUserId: string = "", available: boolean = false, extension: string = "", isLogOffAllowed: boolean = false;
        let callCenterDetails = xmlResponse.getElementsByTagName("callCenterDetails");
        if (!this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
            this.contextService.websocketService.register(new OCIResponseRoutingHandler(this.contextService));
        }
        for (let i = 0; i < callCenterDetails.length; i++) {
            if (callCenterDetails[i].getElementsByTagName("serviceUserId").length > 0) {
                serviceUserId = this.contextService.appendDomain(callCenterDetails[i].getElementsByTagName("serviceUserId")[0].textContent);

            }
            if (callCenterDetails[i].getElementsByTagName("available").length > 0) {
                available = ((callCenterDetails[i].getElementsByTagName("available")[0].textContent) == 'true');
            }
            if (callCenterDetails[i].getElementsByTagName("extension").length > 0) {
                extension = callCenterDetails[i].getElementsByTagName("extension")[0].textContent;
            }
            if (callCenterDetails[i].getElementsByTagName("isLogOffAllowed").length > 0) {
                isLogOffAllowed = ((callCenterDetails[i].getElementsByTagName("isLogOffAllowed")[0].textContent) == 'true');
            }
            this.contextService.agentDetails.callCenters[serviceUserId] = new loggedUserCallCenterList(this.contextService.getLastNameFromUserID(serviceUserId), serviceUserId, available, extension, isLogOffAllowed);
            this.contextService.agentDetails.callCenters[serviceUserId].isAgentCallcenter = true;
            callCenterList.push(serviceUserId);
     

        }


        this.contextService.joinLeaveQueue.next(true);
        this.contextService.websocketService.register(new GetAgentCallCenterConfigurationResponseHandler(this.contextService));

        

     
 
        //supervisor
        this.contextService.callCenterListAlert.next();
        if (!this.sendCallCenterLoginRequest) {
            this.sendCallCenterLoginRequest = true;
            let assignedCC = [];

            for (let key in this.contextService.agentDetails.callCenters) {
                assignedCC.push(key);
            }
            if (assignedCC.length > 0) {
                if (this.contextService.showAgentColumnsList.length == 0 && this.contextService.showQueueColumnsList.length == 0) {
                    //my statistics default columns
                    this.contextService.showAgentColumnsList.push({ id: 1, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'userTotalCalls' });
                    this.contextService.showAgentColumnsList.push({ id: 2, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AnsweredCalls'), value: 'userAnsweredCalls' });
                    this.contextService.showAgentColumnsList.push({ id: 3, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalMissedCalls'), value: 'userMissedCalls' });
                    this.contextService.showAgentColumnsList.push({ id: 4, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AverageTalkTime'), value: 'userAverageTalkTime' });
                    this.contextService.showAgentColumnsList.push({ id: 5, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalTalkTimeNew'), value: 'userTotalTalkTime' });

                    //queue statistics default colums
                    this.contextService.showQueueColumnsList.push({ id: 1, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'queueTotalCalls' });
                    this.contextService.showQueueColumnsList.push({ id: 6, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsAnswered'), value: 'queueCallsAnswered' });
                    this.contextService.showQueueColumnsList.push({ id: 2, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalMissedCallsNew'), value: 'queueTotalMissedCalls' });
                    this.contextService.showQueueColumnsList.push({ id: 22, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsInQueue'), value: 'queueCallsInQueue' });
                    this.contextService.showQueueColumnsList.push({ id: 21, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.LongestWaitTime'), value: 'queueLongestWaitTime' });
                    this.contextService.showQueueColumnsList.push({ id: 16, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AverageWaitTime'), value: 'queueAverageWaitTime' });
                    this.contextService.showQueueColumnsList.push({ id: 18, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AverageTalkTime'), value: 'queueAverageTalkTime' });

                    this.contextService.showAgentColumnsList.forEach(element => {
                        this.contextService.cols.push({ field: element.value, header: element.label, color: element.value + "Color" });
                    });
                    this.contextService.showQueueColumnsList.forEach(element => {
                        this.contextService.cols.push({ field: element.value, header: element.label, color: element.value + "Color" });
                    });
                }
            
            }

        }

        let parser = new DOMParser();
        // let xmlDoc = null;
    
        let xmlDoc = parser.parseFromString('<webClientAction type="OCI"><ociPayload><BroadsoftDocument xmlns="C" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" protocol="OCI"><sessionId xmlns="">kakkapoindia@kakaposystems.comeb43ad54-477a-46a2-8c05-608216601c6f</sessionId><command xmlns="" echo="LoginRequest14sp4" xsi:type="LoginResponse14sp4"><loginType>User</loginType><locale>en_US</locale><encoding>UTF-8</encoding><groupId>kakaposystems</groupId><serviceProviderId>Qudo</serviceProviderId><isEnterprise>false</isEnterprise><passwordExpiresDays>2147483647</passwordExpiresDays><userDomain>kakaposystems.com</userDomain></command></BroadsoftDocument></ociPayload></webClientAction>', "text/xml");
     
        this.contextService.websocketService.responseHandlersList['OCI'].handle(xmlDoc);

        let xmlDoc1 = parser.parseFromString('<webClientAction type="OCI"><ociPayload><BroadsoftDocument xmlns="C" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" protocol="OCI"><sessionId xmlns="">support@kakaposystems.com41d3e1c6-4caf-4942-9059-f707b841ce65</sessionId><command xmlns="" echo="LoginRequest14sp4" xsi:type="LoginResponse14sp4"><loginType>User</loginType><locale>en_US</locale><encoding>UTF-8</encoding><groupId>kakaposystems</groupId><serviceProviderId>Qudo</serviceProviderId><isEnterprise>false</isEnterprise><passwordExpiresDays>2147483647</passwordExpiresDays><userDomain>kakaposystems.com</userDomain></command></BroadsoftDocument></ociPayload></webClientAction>', "text/xml");
     
        this.contextService.websocketService.responseHandlersList['OCI'].handle(xmlDoc1);
    }
}
