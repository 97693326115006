/**
 * model to store each chat conversation
 */
export class ChatConversation {
    id: number;
    people: Array<any>;
    headline: string;
    unread: number;
    offline: boolean;
    message: string;

    constructor(converationObject: object) {
        Object.assign(this, converationObject);
    }

    update(converationObject: object) {
        Object.assign(this, converationObject);
    }

    addPerson(userid, name) {
        this.people.push({ "id": userid, "name": name });
        this.headline += ', ' + name;
    }

    getPeopleCount() {
        return this.people.length;
    }

    getCallToPeple() {
        return this.people;
    }

}
