import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../../../providers/context.service';
import { SettingsGeneral } from "../../../../../../../../providers/settings/settings-general/settings-general.service";
@Component({
  selector: 'app-agent-threshold',
  templateUrl: './agent-threshold.component.html',
  styleUrls: ['./agent-threshold.component.scss']
})
export class AgentThresholdComponent implements OnInit {
  setThreshold: string = "";
  increasingThreshold: string = "";
  highlights: string = "";
  description: string = "";
  statistic: string = "";
  heading: string = "";
  green:string="";
  black:string="";
  red:string="";
  yellow:string="";
  callsNameList=[];
  secondsNameList=[];
  constructor(public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.setThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.SetThresholdValue');
    this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds');
    this.highlights = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.HighlightNonZeroValues');

    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.Columns.Description');
    this.statistic = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Statistic');
    this.heading = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Heading');

    this.green = this.contextService.getLanguageTagByKey('Color.Green');
    this.black = this.contextService.getLanguageTagByKey('Color.Black');
    this.red = this.contextService.getLanguageTagByKey('Color.Red');
    this.yellow = this.contextService.getLanguageTagByKey('Color.Yellow');

    this.callsNameList.push("userTotalCalls");
    this.callsNameList.push("userAnsweredCalls");
    this.callsNameList.push("userMissedCalls");
    this.callsNameList.push("userAverageTalkTime");
    this.secondsNameList.push("userTotalTalkTime");
    this.secondsNameList.push("userTotalStaffedTime");
    this.changeText();
  }
  changeText(){
    if (this.callsNameList.includes(this.settingsGeneral.selectedAgentColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Menu.Context.Call')).toLocaleLowerCase() + ")";
    } else if (this.secondsNameList.includes(this.settingsGeneral.selectedAgentColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";
    }
  }

}
