<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label">{{alsoRing}}</label>
  <input type="text" id="txt_inOfficeadditionalPhoneNumberToRing" name="input-text" [(ngModel)]="commPilotExpressService.inOfficeadditionalPhoneNumberToRing" placeholder="">
</div>

<div class="row">

  <label for="" class="label">{{ifBusy}}</label>

  <div class="row">
    <label for="" class="label-block"></label>
    <p-radioButton id="rdb_sendCallToVM" name="groupname" value="Transfer To Voice Mail" label={{sendCallToVM}} [(ngModel)]="commPilotExpressService.inOfficebusySetting" styleClass="big-radio"></p-radioButton>
  </div>

  <div class="row">
    <label for="" class="label-block"></label>
    <p-radioButton id="rdb_frwdCall" name="groupname" value="Forward" label={{frwdCall}} [(ngModel)]="commPilotExpressService.inOfficebusySetting" styleClass="big-radio"></p-radioButton>

  </div>

  <div class="row">
    <label for="" class="label">{{to}}</label>
    <input id="txt_inOfficeforwardingAddressIfBusy" type="text" name="input-text" [(ngModel)]="commPilotExpressService.inOfficeforwardingAddressIfBusy" [disabled]="commPilotExpressService.inOfficebusySetting==='Transfer To Voice Mail'">
  </div>

</div>

<div class="row">
  <label for="" class="label">{{ifNotAnswered}}</label>
  <div class="row">
    <label for="" class="label-block"></label>
    <p-radioButton id="rdb_frwdCall_sendCallToVM" name="groupname1" value="Transfer To Voice Mail" label={{sendCallToVM}} [(ngModel)]="commPilotExpressService.inOfficenoAnswerSetting" styleClass="big-radio"></p-radioButton>
  </div>
  <div class="row">
    <label for="" class="label-block"></label>
    <p-radioButton id="rdb_frwdCall_ifNotAnswered" name="groupname1" value="Forward" label={{frwdCall}} [(ngModel)]="commPilotExpressService.inOfficenoAnswerSetting" styleClass="big-radio"></p-radioButton>
  </div>
  <div class="row">
    <label for="" class="label">{{toNotAnswered}}</label>
    <input type="text" id="txt_inOfficeforwardingAddressIfNotAnswered" name="input-text" [(ngModel)]="commPilotExpressService.inOfficeforwardingAddressIfNotAnswered" [disabled]="commPilotExpressService.inOfficenoAnswerSetting==='Transfer To Voice Mail'">
  </div>
</div>