import { Injectable } from '@angular/core';
import { CallCenterMonitoring } from 'app/model/bw-services/call-center-monitoring';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';

@Injectable()
export class CallCenterMonitoringService {
    isActive: boolean = false;


    callCenterMonitoringChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - CallWaitingService');
    }

    setData(callCenterMonitoring: CallCenterMonitoring) {
    }

    validate(): boolean {
        return true;
    }

    save() {

    }

    populateData() {
    }
    cancel() {
        this.populateData();
    }

}