<p-dialog
  [(visible)]="twitterModalVisible"
  [ngClass]="{ visible: twitterModalVisible, 'is-reserved': reserved }"
  [closable]="false"
  class="small-dialog messages-dialog twitter-dialog"
  dismissableMask="true"
  showHeader="true"
  [resizable]="false"
  #dialTwitter
>
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{ title }}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
      <span
        class="maximize-modal"
        (click)="messagesService.toggleDialogMaximized(dialTwitter)"
      ></span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="messages-modal-content">
      <ul class="buttons-menu in-modal">
        <!-- reject -->
        <li *ngIf="!reserved">
          <div class="tooltip">
            <span>{{ rejectBtn }}</span>
          </div>
          <a (click)="reject($event)" class="btn-reject new-i">{{
            rejectBtn
          }}</a>
        </li>
        <!-- reserve -->
        <li *ngIf="!reserved">
          <div class="tooltip" style="white-space: nowrap">
            <span>{{ reserveBtn }}</span>
          </div>
          <a (click)="reserve($event)" class="btn-reserve new-i">{{
            reserveBtn
          }}</a>
        </li>

        <!-- transfer -->
        <li *ngIf="reserved" #transferToChat>
          <div class="tooltip">
            <span>{{ transferBtn }}</span>
          </div>
          <a
            (click)="openTransferToCm($event, transfertocmChat)"
            class="btn-transfer-cb new-i"
            >{{ transferBtn }}</a
          >

          <p-contextMenu
            [model]="transferToList"
            appendTo="body"
            [target]="transferToChat"
            #transfertocmChat
          >
          </p-contextMenu>
        </li>

        <!-- close -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ closeBtn }}</span>
          </div>
          <a (click)="close($event)" class="btn-close new-i">{{ closeBtn }}</a>
        </li>

        <!-- att -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ attachmentBtn }}</span>
          </div>
          <label for="cb_att_twitter" class="btn-attachment new-i">
            {{ attachmentBtn }}
          </label>
        </li>

        <li *ngIf="reserved" class="separator"></li>

        <!-- send -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ sendBtn }}</span>
          </div>
          <a
            (click)="sendDirectMessageRequest($event)"
            class="btn-email-send new-i"
            >{{ sendBtn }}</a
          >
        </li>

        <li *ngIf="reserved" class="separator"></li>

        <!-- conversation -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ conversationBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'conversation' }"
            (click)="showConversation($event)"
            class="btn-email-conversation new-i"
            >{{ conversationBtn }}</a
          >
        </li>

        <!-- replies -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ respliesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'replies' }"
            (click)="selectTab($event, 'replies')"
            class="btn-email-replies new-i"
            >{{ respliesBtn }}</a
          >
        </li>

        <!-- resources -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ resourcesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'resources' }"
            (click)="selectTab($event, 'resources')"
            class="btn-email-resources new-i"
            >{{ resourcesBtn }}</a
          >
        </li>
      </ul>

      <div class="messages-window">
        <div class="mw-right fw">
          <div class="chat-wrapper">
            <div class="container" [perfectScrollbar] id="twitter-scroll-pane">
              <div class="content">
                <ul
                  class="twitter-messages"
                  [hidden]="selectedTab !== 'conversation'"
                >
                  <li
                    *ngFor="let message of messages; let i = index"
                    class="twitter-tweet"
                    [ngClass]="{
                      'date-time': !message.id,
                      you: !message.itsMe && message.id,
                      me: message.itsMe && message.id
                    }"
                  >
                    <blockquote>
                      <a
                        href="https://twitter.com/{{ message.twitterName }}"
                        target="_blank"
                        class="tw-author"
                      >
                        <span class="tw-author-photo">
                          <img
                            src="{{ message.photo }}"
                            alt="{{ message.name }}"
                          />
                        </span>
                        <strong class="tw-author-name">{{
                          message.name
                        }}</strong>
                        <span class="tw-author-username">{{
                          message.twitterName
                        }}</span>
                      </a>

                      <p class="tw-text" [innerHTML]="message.text"></p>

                      <span class="timestamp">
                        {{ message.dateTime | datex: "hh:mm A - MM/DD/YYYY " }}
                      </span>
                    </blockquote>
                  </li>
                </ul>
                <app-canned-replies
                  [hidden]="selectedTab !== 'replies'"
                  [visible]="selectedTab === 'replies'"
                  (onRightClick)="repliesRightClick($event)"
                  (onDoubleClick)="repliesDoubleClick($event)"
                  [repliesEntries]="respliesList"
                ></app-canned-replies>

                <app-resources
                  [hidden]="selectedTab !== 'resources'"
                  [visible]="selectedTab === 'resources'"
                  (onRightClick)="resourcesRightClick($event)"
                  (onDoubleClick)="resourcesDoubleClick($event)"
                  [resourceEntries]="resourcesList"
                ></app-resources>
              </div>
            </div>
          </div>
          <div class="replies-wrapper">
            <div class="table-content">
              <app-web-chat-conversation
                [componentName]="'twitter'"
                [predefinedMessage]="predefinedMessage"
                (onMessageSubmit)="onMessageSubmit($event[0], $event[1])"
              ></app-web-chat-conversation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
