<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{alertAllLocation}} [(ngModel)]="bWAnywhereService.isAlertAllLocation"
    binary="true"></p-checkbox>
</div>
<div class="row">

  <div class="box">

    <p-table [value]="bWAnywhereService.locationArray" selectionMode="single" [(selection)]="selectedNumber" dataKey="id">
      <ng-template pTemplate="header">
        <th>{{phoneNumber}}
        </th>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr (dblclick)="doubleClick(rowData)" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData">
          <td>
            {{rowData.number}}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="deleteLocation()">{{delete}}</a>
        <a href="javascript:void(0);" (click)="bWAnywhereService.addNewLocation()">{{plus}}</a>
      </div>
    </div>
  </div>
</div>
<p-dialog [resizable]="false" [(visible)]="deleteLocationWindow" [closable]="false" [style]="{'width':'370px'}" class="close-dialog"
  dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{confirm}}</p>
    <div>
      <button type="button" class="btn-white" (click)="deleteLocationWindow = false">{{miscNo}}</button>
      <button type="button" class="btn-blue-light" (click)="deleteLocationWindowLocation()">{{miscYes}}</button>
    </div>
  </div>
</p-dialog>