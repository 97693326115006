import { Injectable } from '@angular/core';

import { CallLogEntry } from '../model/call-logs/call-log-entry';
import { CallType } from '../model/call-logs/call-type';
import { ContextService } from '../providers/context.service';
import { HelperService } from "./helper.service";
import { ChatConversation } from '../model/chat-conversation.model';
import { ChatService } from "./chat.service";
@Injectable()
export class CallLogsService {
    public ObjCallLogs: Array<any>;
    selectedCallLog: CallLogEntry;
    selectedValue: any = CallType.MISSED;
    userID: string = "";

    constructor(private chatService: ChatService, public contextService: ContextService, private helperService: HelperService) {
        this.contextService.log('###### constructor - CallLogsService');

        this.selectedValue = CallType.MISSED;
        this.contextService.updatecalllogs.subscribe(() => {
            this.selectedCallType(this.selectedValue);
        });

        this.contextService.signout.subscribe(val => {
            this.ObjCallLogs = [];
        });
        this.contextService.commonObservable.subscribe(val => {
            if (val === "autoReconnect") {
                this.ObjCallLogs = [];
            }
        });
    }
    setSelectedCallLog(objCallLogEntry: CallLogEntry) {
        this.selectedCallLog = objCallLogEntry;
        this.contextService.log(this.selectedCallLog);

    }
    selectedCallType(selectedValue: number) {
        this.ObjCallLogs = [];
        this.ObjCallLogs = this.contextService.callLogsList;
        this.ObjCallLogs.forEach(element => {
            element.period = this.contextService.getLanguageTagByKey('Misc.Later');;
        });
        this.ObjCallLogs = this.ObjCallLogs.filter(CallLogEntry => (CallLogEntry.callType == selectedValue));

        if ((selectedValue == 0)) {
            this.ObjCallLogs.forEach(element => {
                if (element.time > this.contextService.missedCallReferenceDate) {
                    element.period = this.contextService.getLanguageTagByKey('Misc.Recent');

                } else {
                    element.period = this.contextService.getLanguageTagByKey('Misc.Later');
                }

            });
        }

    }
    call() {
      
        this.contextService.log("dialAction performed call Id: " + this.selectedCallLog.phone);
    }
    transferCallTo() {
        this.contextService.log("start method: transferCallTo");

      

    }
    copy() {
        this.contextService.log("start method: copy");

        let textTocopy = this.selectedCallLog.phone;
        let aux = document.createElement("input");

        // Get the text from the element passed into the input
        aux.setAttribute("value", textTocopy);
        // Append the aux input to the body
        document.body.appendChild(aux);

        // Highlight the content
        aux.select();

        // Execute the copy command
        document.execCommand("copy");
        document.body.removeChild(aux);
        this.contextService.log("End method: copy");

    }
    viewUserDetails() {

        this.contextService.viewUserDetailsModalVis.next(this.selectedCallLog.loginId);
    }

    instantMessage() {
        this.contextService.instantMessage(this.selectedCallLog.loginId);

    }

}