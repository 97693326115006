
import { CallType } from './call-type';
/**
 * model class to store call logs of the logged in user
 * 
 * @export
 * @class CallLogEntry
 */
export class CallLogEntry {

    id:number
    name: string;

    countryCode: string;

    phone: string;

    time: Date;

    callLogId: string;

    loginId: String;

    callType: CallType;
    period:string;

    constructor(name: string, countryCode: string, phone: string, time: Date, callLogId: string, loginId: String, callType: CallType) {
        this.name = name;
        this.countryCode = countryCode;
        this.phone = phone;
        this.time = time;
        this.callLogId = callLogId;
        this.loginId = loginId;
        this.callType = callType;
        this.id=parseInt(callLogId.replace(/[^0-9]/g,''));
        this.period='Later';//Recent
    }
   
}

