<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <p-checkbox name="autoupdate" value="checkbox" label={{autoUpdate}} [(ngModel)]="settingsGeneral.update_autoUpdateUnity"
    binary="true"></p-checkbox>
</div>

<div class="row">
  <p>
    {{checkForUpdateEvery}}
  </p>
  <p>
    <input type="number" [(ngModel)]="settingsGeneral.update_updateCheckIntervall" (keypress)="onlyNumberKey($event)" name="mini-input" class="input-mini" [min]="1" [max]="24" onPaste="return false" [disabled]="!settingsGeneral.update_autoUpdateUnity"> {{hours}}
  </p>
</div>

<div class="row">
  <p-radioButton name="alertorrestart" value="0" label={{alertMe}} binary="true" styleClass="big-radio" [(ngModel)]="settingsGeneral.update_restartOrAlertAfterUpdate"
    [disabled]="!settingsGeneral.update_autoUpdateUnity"></p-radioButton>
</div>
<div class="row">
  <p-radioButton name="alertorrestart" value="1" label={{autoRestart}} binary="true" styleClass="big-radio" [(ngModel)]="settingsGeneral.update_restartOrAlertAfterUpdate"
    [disabled]="!settingsGeneral.update_autoUpdateUnity"></p-radioButton>
</div>
<div class="row">
  <button type="button" (click)="this.settingsGeneral.manualAlertVisible()" class="btn-blue-light">{{performManualUpdateNow}}</button>
</div>

<!-- <div class="row">
  <div>
    <p>
      {{this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.Rollback')}}
    </p>
  </div>

  <div>
    <p style="font-size:110%">
        {{this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.Rollback.VersionsAvailable')}}
    </p>
    
  </div>

  <p-dropdown name="dropdown" [style]="{'width':'45%'}" [options]="settingsGeneral.versionArray" [(ngModel)]="settingsGeneral.selectedVersion" placeholder="Select an version"></p-dropdown>
  <button type="button"  class="btn-blue-light" (click)="this.settingsGeneral.callBackAlertVisible()">{{this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.Rollback')}}</button>
</div> -->

