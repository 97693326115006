<div class="history-wrap">
  <div class="history-search">
    <h4 class="blue-headline">{{ title }}</h4>

    <form (ngSubmit)="filterHistory(input.value)">
      <fieldset class="srch-field srch-history srch-smaller">
        <input
          type="text"
          placeholder="{{ search }}"
          name="text"
          #input
          (input)="filterHistory(input.value)"
          autocomplete="off"
        />
        <button type="submit"></button>
      </fieldset>
    </form>
  </div>
  <div
    class="history-entries"
    #historyEentries
    [ngStyle]="{ height: scrollHeight }"
  >
    <div class="container" [perfectScrollbar]>
      <div class="content">
        <ul *ngIf="inited && historyEntriesFiltered.length > 0">
          <li
            *ngFor="let historyEntry of historyEntriesFiltered; let i = index"
            (click)="onClick(historyEntry)"
          >
            <strong class="action action-{{ historyEntry.ico }}">
              {{ historyEntry.title }}
              <app-stars-rating
                *ngIf="historyEntry.rating"
                [rating]="historyEntry.rating"
              ></app-stars-rating>
            </strong>
            <ul class="action-info">
              <li *ngFor="let info of historyEntry.info; let z = index">
                {{ info }}
              </li>
            </ul>
            <span class="date-time"
              >{{ historyEntry.dateTime | datex: "MM/DD/YYYY hh:mm:ss A" }}
            </span>
          </li>
        </ul>
        <p class="not-found" *ngIf="historyEntriesFiltered.length === 0">
          {{ noHistory }}
        </p>
      </div>
    </div>
  </div>
</div>
