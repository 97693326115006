import { ContextService } from '../../../providers/context.service';
import { ResponseHandler } from '../../../shared/ResponseHandler';
import { ResponseRouter } from '../../../shared/ResponseRouter';


export class GetAgentCallCenterConfigurationResponseHandler extends ResponseRouter implements ResponseHandler {

    type = "GetAgentCallCenterConfigurationResponse";

    constructor(private contextService: ContextService) {
        super();
    }

    handle(xmlResponse: Document): void {


    }
}
