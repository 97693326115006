<div class="resources">
  <div class="resources-search">
    <form (ngSubmit)="filter(input.value)">
      <fieldset class="srch-field srch-resources srch-smaller">
        <input
          type="text"
          placeholder="{{ search }}"
          name="text"
          #input
          (input)="filter(input.value)"
          autocomplete="off"
        />
        <button type="submit" tabindex="-1"></button>
      </fieldset>
    </form>
  </div>
  <div class="search-entries resources-entries">
    <div class="container" [perfectScrollbar]>
      <div class="content">
        <div *ngIf="listFiltered.length > 0">
          <p-table
            [value]="listFiltered"
            [resizableColumns]="false"
            columnResizeMode="fit"
            [responsive]="true"
            selectionMode="single"
          >
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr
                (dblclick)="doubleClick(rowData)"
                (contextmenu)="rightClick(rowData)"
                (keyup.enter)="rightClick(rowData)"
                [pSelectableRow]="rowData"
                [pSelectableRowDblClick]="rowData"
                tabindex="0"
              >
                <td>
                  {{ rowData.title || rowData.url }}
                </td>
                <td style="text-align: center">
                  {{ rowData.description }}
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <p class="not-found" *ngIf="listFiltered.length === 0">
          {{ noResults }}
        </p>
      </div>
    </div>
  </div>
</div>
