import { Threshold } from "./threshold";
export class AgentActivityStatistics {
    public statToDisplay: string;
    public statName: string;
    public columnHeader: string;
    public setThreshold: boolean;
    public threshold: Threshold;
    unavailableCodeList:Array<Threshold>=[];


    constructor(statToDisplay: string,statName: string, statHeader: string, isThresholdSet: boolean) {
        this.statToDisplay = statToDisplay;
        this.statName=statName;
        this.columnHeader = statHeader;
        this.setThreshold = isThresholdSet;

    }



}