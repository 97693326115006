import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChatConversation } from 'app/model/chat-conversation.model';
import { BwDirectoryEntry } from "app/model/directory/bw-directory-entry";
import { ChatService } from 'app/providers/chat.service';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/debounceTime';
import { ContextService } from '../../providers/context.service';
import { SettingsGeneral } from "../../providers/settings/settings-general/settings-general.service";
import { StatisticsService } from "../../providers/statistics.service";
import { DataService } from './../../providers/dataservice.service';
import { HelperService } from './../../providers/helper.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  statistics: Array<any>[] = [];
  overallStatistics: Array<any>[] = [];
  tablesOpen: boolean = true;
  scrollBarWidth: string = "";
  scrollHeight: string = "";
  statsCellDimension: Array<any> = [];
  statsMenuItems: MenuItem[];
  afterdropmenuLeft: string = "";
  afterdropmenuTop: string = "";
  afterDropCM: MenuItem[];
  tablesDeviderLeftPos;
  tablesDeviderVis: boolean = false;
  selected;
  myStatistics: string = "";
  allStatistics: string = "";
  summary: string = "";

  dividerDelay: number = 300;

  showAgentStatHeader: boolean = false;
  showQueueStatHeader: boolean = false;

  messageUserObject = [];

  tempId: string = "";
  id: string = "";
  //sort
  sortO: string = '1';
  sortF: string = 'queueName';

  //dragula bags
  bag;
  selectedCallcenter;

  @ViewChild('afterdropmenustats') afterdropmenustats;
  @ViewChild('mystats') mystats;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.tablesDeviderVis = false;
    this.calcCellWidths();
    this.setTableDeviderPos();
  }

  constructor(private dataService: DataService, private chatService: ChatService, private settingsGeneral: SettingsGeneral, private statisticsService: StatisticsService,
    private helper: HelperService,
    public contextService: ContextService) { }

  ngOnInit() {

    this.myStatistics = this.contextService.getLanguageTagByKey('List.Statistics.MyStatistics');
    this.allStatistics = this.contextService.getLanguageTagByKey('List.Statistics.OverallStatistics');
    this.summary = this.contextService.getLanguageTagByKey('List.Statistics.Summary');

    this.contextService.showOnlySummaryStatInPW.subscribe(() => {
      setTimeout(() => {
        if (this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly) {
          this.tablesOpen = true;
          this.collapseTables(this.mystats);
        } else {
          this.tablesOpen = !this.contextService.objSettings.personalWallboardPersist;
          this.collapseTables(this.mystats);
        }
      }, 500);

    });
    /**bifin */
    this.contextService.calcStatWidth.subscribe((val) => {
      if (val == "Im") {
        setTimeout(() => {
          setTimeout(() => {
            this.collapseTables(this.mystats);
          }, 100);
          this.collapseTables(this.mystats);
        }, 100);

      }
      // calc table height based on row numbers (should be set somewhere in settings)
      this.calcSrollHeight(parseInt(this.settingsGeneral.scrollbarCountOfPW));
      setTimeout(() => {
        if (this.contextService.statistics != null && this.contextService.showPersonalWallboard) {
          this.helper.calcCellsWidth(this.mystats).then(data => { this.statsCellDimension = data });
          this.setTableDeviderPos();
        }
      });
    });
    this.contextService.calcStatWidth.next();
    this.helper.openedMenuOnDropStats.subscribe((value) => {
      if (value[0].displayState == "Active") {
        let phone;
        for (let i = 0; i < this.contextService.directoryList.length; i++) {
          if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
            let element = this.contextService.directoryList[i] as BwDirectoryEntry;
            if (element.getLastName() === value[1]) {
              phone = element.getExtension();
            }
          }
        }
        this.contextService.log("active call transfered to call center");
        this.contextService.log("callId:" + value[0].callId + ", phone:" + phone);
        this.contextService.websocketService.blindTransferAction(value[0].callId, phone);
      }
    });

    this.helper.resized.debounceTime(500).subscribe((val) => {
      this.tablesDeviderVis = false;
      this.calcCellWidths();
      this.setTableDeviderPos();
    });

    this.afterDropCM = [
      { label: 'Option 1 Stats' },
      { label: 'Option 2 Stats' }
    ];



    // unselect on drag start
    this.helper.dragStart.subscribe((val) => {
      this.selected = [];
    });

    this.setSort();
    this.contextService.showOnlySummaryStatInPW.next();
  }

  setTableDeviderPos() {
    // this.contextService.log("setTableDeviderPos() entered");
    if (this.contextService.showAgentColumnsList.length > 0) {
      this.showAgentStatHeader = true;
    } else {
      this.showAgentStatHeader = false;
    }
    if (this.contextService.showQueueColumnsList.length > 0) {
      this.showQueueStatHeader = true;
    } else {
      this.showQueueStatHeader = false;
    }

    setTimeout(() => {
      if (this.showAgentStatHeader && this.showQueueStatHeader) {
        const rowNum = this.contextService.showAgentColumnsList.length + 2;
        let dividerPosition = 0;
        for (let index = 0; index < rowNum; index++) {
          dividerPosition += this.statsCellDimension[index];
        }
        this.tablesDeviderLeftPos = (dividerPosition + 10).toString() + 'px';


        this.tablesDeviderVis = true;
      } else {
        this.tablesDeviderVis = false;
      }
      this.dividerDelay = 0;

      var EmptySpan = document.getElementById('EmptySpan');
      if (EmptySpan) {
        EmptySpan.style.width = (this.statsCellDimension[0] + this.statsCellDimension[1]).toString() + 'px';
      }

      var MyStatSpan = document.getElementById('MyStatSpan');
      var QStatSpan = document.getElementById('QStatSpan');
      if (MyStatSpan && QStatSpan) {
        // MyStatSpan.style.width = (this.statsCellDimension[2] + this.statsCellDimension[3]).toString() + 'px';
        // QStatSpan.style.width = ((this.statsCellDimension.length - 4) * this.statsCellDimension[2]).toString() + 'px';

        MyStatSpan.style.width = this.getWidth(0, this.contextService.showAgentColumnsList.length) + 'px';
        QStatSpan.style.width = (MyStatSpan.parentElement.scrollWidth - (this.statsCellDimension[0] + this.statsCellDimension[1] + this.getWidth(0, this.contextService.showAgentColumnsList.length) + 25)) + 'px';
      } else {
        if (QStatSpan) {
          // QStatSpan.style.width = (this.contextService.showQueueColumnsList.length * this.statsCellDimension[2]).toString() + 'px';
          QStatSpan.style.width = this.getWidth(0, this.contextService.showQueueColumnsList.length) + 'px';
        } else if (MyStatSpan) {
          // MyStatSpan.style.width = (this.contextService.showAgentColumnsList.length * this.statsCellDimension[2]).toString() + 'px';
          MyStatSpan.style.width = this.getWidth(0, this.contextService.showAgentColumnsList.length) + 'px';
        }
      }
    }, this.dividerDelay);

  }

  getWidth(start, end) {
    let totalWidth = 0;
    for (let index = start + 2; index < start + 2 + end; index++) {
      totalWidth += this.statsCellDimension[index];
    }
    return totalWidth;
  }
  calcCellWidths() {
    if (this.contextService.showPersonalWallboard) {
      if (this.mystats) {
        if (!this.tablesOpen) {
          // calc width of header cells becasue tbody is hidden
          this.helper.calcCellsWidth(this.mystats, true).then(data => { this.statsCellDimension = data });
        } else {
          // calc width of tbody cells
          this.helper.calcCellsWidth(this.mystats, false).then(data => { this.statsCellDimension = data });
        }
      }
    }
  }

  calcSrollHeight(rows: number) {
    let tableHeight = 'auto';
    let scrollBarW = this.helper.getScrollBarWidth();
    const row = 20; // height of 1 row in px
    if (rows < this.contextService.statistics.length) {
      tableHeight = (rows * row + 11).toString() + 'px'; // magic number 11 adds some space after a row so the last summary row doesn't collide with other rows
      this.scrollHeight = tableHeight;
      this.scrollBarWidth = scrollBarW;
    }
  }

  collapseTables(mystats) {
    this.contextService.log("collapseTables() entered")
    const mystatsBodyRows = mystats.el.nativeElement.querySelectorAll('.ui-table-tbody');
    this.tablesOpen = !this.tablesOpen;
    if (this.tablesOpen) {
      this.calcSrollHeight(parseInt(this.settingsGeneral.scrollbarCountOfPW));
      this.helper.forEach(mystatsBodyRows, (i, row) => { row.removeAttribute('style'); });
      if (this.contextService.showPersonalWallboard) {
        this.helper.calcCellsWidth(this.mystats, false).then(data => { this.statsCellDimension = data });
      }
    } else {
      // this.scrollHeight = '30px';
      // this.scrollBarWidth = '0px';
      this.helper.forEach(mystatsBodyRows, (i, row) => { row.style.display = 'none'; });
      if (this.contextService.showPersonalWallboard) {
        this.helper.calcCellsWidth(this.mystats, true).then(data => { this.statsCellDimension = data });
      }
    }
    this.contextService.objSettings.personalWallboardPersist = this.tablesOpen;
    if (this.contextService.objLoggedUserDetails) {
      this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
    }
    this.setTableDeviderPos();
    // calculate height of components
    if (this.helper.selectedTableTab === 'contacts') {
      this.helper.recalcContactsHeight.next(true);
    } else if (this.helper.selectedTableTab === 'calllogs') {
      this.helper.recalcCallLogsHeight.next(true);
    } else if (this.helper.selectedTableTab === 'voicemail') {
      this.helper.recalcVoicemailHeight.next(true);
    } else if (this.helper.selectedTableTab === 'agentactivity') {
      this.helper.recalcagentactivityHeight.next(true);
    }

  }

  rowClick(e) {
    this.helper.disableRows(this.mystats);
  }

  contextMenuSelected(e) {
    this.helper.disableRows(this.mystats);
  }


  setCellColor(val, bgcolor, col, rowData) {
    if (val != undefined || val != null) {
      if (val.toString().length >= 0 && bgcolor != "") {
        return '#ffffff';
      } else {
        let value;
        if (val.toString().search(':') != -1) {
          value = this.processTimeToSeconds(val);
        } else {
          value = parseFloat(val);
        }
        if (value > 0) {
          for (let index = 0; index < this.contextService.statistics.length; index++) {
            if (this.contextService.statistics[index].queueID == rowData.queueID) {
              if (this.contextService.statistics[index].isHighlightCollection[col.field]) {
                return '#ff0000';
              }
            }
          }
        }
      }
    }
  }

  processTimeToSeconds(time) {
    let a = time.toString().split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
  }

  insertMenuItemsForSelected(sm) {

    this.contextService.log("insertMenuItemsForSelected");
    this.helper.hideAllCMenus();
    this.helper.saveAllCMenus(sm);
    sm.show();
    this.statsMenuItems = [];
    if (this.selectedCallcenter) {
      this.contextService.log("selected callcenter :" + this.selectedCallcenter.queueID);
      this.contextService.log(this.selectedCallcenter);

      let showJoinOption = false;
      let showLeaveOption = false;

      if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isAgentCallcenter) {
        if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].joined) {
          showLeaveOption = true;
          showJoinOption = false;
        } else {
          showLeaveOption = false;
          showJoinOption = true;
        }

      } else {
        showLeaveOption = false;
        showJoinOption = false;
      }


      if (this.contextService.callStatusList.length < 1) {
        this.contextService.log("no call exist");
        this.statsMenuItems = [
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Call'), visible: true, command: (event) => { this.contextService.websocketService.dialAction(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].extension) } },

          { separator: true, visible: this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isAgentCallcenter && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'leave') }, disabled: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isLogOffAllowed, visible: showLeaveOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'join') }, visible: showJoinOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },

        ];
      } else {
        this.contextService.log("call exist");
        this.statsMenuItems = [
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Call'), visible: true, command: (event) => { this.contextService.websocketService.dialAction(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].extension) } },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Transfer'), visible: true, command: (event) => { this.callActionsInQueue('transfer', this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].extension) } },
          { separator: true, visible: this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isAgentCallcenter && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'leave') }, disabled: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isLogOffAllowed, visible: showLeaveOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'join') }, visible: showJoinOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },

        ];
      }
      if (this.contextService.objSettings.STARTUP === 3) {
        if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isSupervisorCallcenter && Object.keys(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList).length > 0) {
          this.statsMenuItems.push({ separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ShowActivity'), command: (event) => { this.showAgentActivity(this.selectedCallcenter.queueID) }, visible: true }
          );
        }
      }
      this.supervisorsMenu();


      if (this.contextService.objSettings.STARTUP === 3) {
        this.agentMenu();
        let supervisedAgentExist = false;
        let supervisedAgentsCount = 0;
        for (const key in this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList) {
          if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList.hasOwnProperty(key)) {
            if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[key].isSupervised) {
              supervisedAgentsCount++;
              supervisedAgentExist = true;
            }
            if (supervisedAgentsCount === 1) {
              if (key === this.contextService.objLoggedUserDetails.getUserId()) {
                supervisedAgentExist = false;
              }
            }
          }
        }


        if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isSupervisorCallcenter && Object.keys(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList).length > 0) {
          if (this.contextService.isImConnected && supervisedAgentExist) {
            this.statsMenuItems.push(
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.InstantMessageSupervisedAgents'), command: (event) => { this.instantMessage(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID]) }, visible: true }
            );
          }
          if (!this.contextService.objSettings.supervior_addAllAgentsToAgentList) {
            this.statsMenuItems.push(
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ModifySupervisedAgentList'), command: (event) => { this.modifyAgents(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID]) }, visible: true }
            );
          }
        }

        if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].type === "Premium" && this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isSupervisorCallcenter) {
          if (this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].activeForcedForwarding) {
            this.statsMenuItems.push({ separator: true, visible: true },
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.DeactivateForcedForwarding') + " (" + this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].forcedForwardingNumber + ")", command: (event) => { }, visible: true }
            );
          } else {
            this.statsMenuItems.push({ separator: true, visible: true },
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ActivateForcedForwarding'), command: (event) => { this.activateForcedForwarding(this.selectedCallcenter.queueID) }, visible: true }
            );
          }
        }

      }


      if (this.selectedCallcenter.isDisplayInPersonalWallboard && this.contextService.objSettings.defaultPasswordExist) {
        this.contextService.log("no call exist && DisplayInPersonalWallboard ");
        this.statsMenuItems.push({ separator: true, visible: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.RemoveFromPersonalWallboard'), visible: true, command: (event) => { this.removeCallCenter(this.selectedCallcenter.queueID) } });
      }

    }

  }
  showAgentActivity(queueID: any) {
    this.contextService.updateTabSelection.next('agentactivity');
    this.contextService.showAgentActivity.next({ 'command': 'ShowAgentActivity', 'queueID': queueID });
  }
  modifyAgents(data) {
    this.contextService.modifySupervisedAgentsVisible.next(data);
  }
  agentMenu() {
    //add supervisor menu in tools 
    //adding agents menu
    let agentMenu: MenuItem[];
    agentMenu = [
      {
        label: this.contextService.getLanguageTagByKey('Misc.Agents')
      }
    ];



    agentMenu[0].items = [];

    let agentsList = this.sortAgents(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList);//sort agent list based on name
    let s = 0;
    for (let j = 0; j < agentsList.length; j++) {
      let agent = agentsList[j];

      if (agent[1].isSupervised) {
        (<MenuItem[]>agentMenu[0].items).push(
          {
            label: agent[1].firstName + " " + agent[1].lastName, items: []
          });
        let unavailableMenuTemp = [];
        let positionIndex: number = 2;
        let defaultValue = "";



        for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
          defaultValue = "";
          if (this.contextService.unAvailableCodesArray[i].isDefault) {
            defaultValue = " (" + this.contextService.getLanguageTagByKey('Misc.Default') + ")";
          }
          let icon = "";
          if (this.contextService.agentSubscriptionList[agent[1].id].unavailableCode === this.contextService.unAvailableCodesArray[i].code) {
            icon = 'tick';
          }
          unavailableMenuTemp.push({ label: this.contextService.unAvailableCodesArray[i].Description + defaultValue, target: this.contextService.unAvailableCodesArray[i].code, title: this.contextService.unAvailableCodesArray[i].Description, badge: positionIndex, command: (event) => this.unAvailableMenuClick(event, agent[1].id), icon: icon, styleClass: 'state' });
          positionIndex = positionIndex + 1;
        }

        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'join', agent[1].id) }, visible: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState }
          , { separator: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState, visible: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState }
        );

        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'leave', agent[1].id) }, visible: this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState, disabled: !this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].isLogOffAllowed }
          , { separator: this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState, visible: this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].agentList[agent[1].id].joinedState }
        );
        //acd state
        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignIn'), command: (event) => { } }

        );
        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable'), command: (event) => { } }

        );

        // if (this.contextService.agentSubscriptionList[agent[1].id].ACDState.startsWith("Unavailable")) {

        //   let name = "";
        //   if (this.contextService.agentSubscriptionList[agent[1].id].unavailableCode != "") {

        //     for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
        //       if (this.contextService.agentSubscriptionList[agent[1].id].unavailableCode === this.contextService.unAvailableCodesArray[i].code) {
        //         name = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable') + " (" + this.contextService.unAvailableCodesArray[i].Description + ")";
        //         break;
        //       }
        //     }
        //   } else {
        //     name = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');
        //   }
        //   (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

        //     { label: name, command: (event) => { this.joinLeaveQueue(this.selectedCallcenter.queueID, 'leave') } }

        //   );

        // } else {
        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable'), command: (event) => { }, items: unavailableMenuTemp }

        );
        // }

        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp'), command: (event) => { } }

        );
        (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

          { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignOut'), command: (event) => { } }
        );

        if (agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
          (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push({ separator: true, visible: true }
          );
        }
        if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Sign-In") {
          (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[4]).icon = 'tick';
        } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Available") {
          (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[5]).icon = 'tick';
        } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState.startsWith("Unavailable")) {
          (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[6]).icon = 'tick';
        } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Wrap-Up") {
          (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[7]).icon = 'tick';
        } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Sign-Out") {
          (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[8]).icon = 'tick';
        }
        //acd state
        if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in') && this.contextService.assignedServices.includes('CallCenterMonitoring') && this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].type === "Premium" && agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
          let silentMonitorItems = [];
          if (Object.keys(this.contextService.silentMonitorArray).length > 0) {
            for (const key in this.contextService.silentMonitorArray) {
              if (this.contextService.silentMonitorArray.hasOwnProperty(key)) {
                if (key === agent[1].id) {
                  silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Misc.Stop'), command: (event) => { }, visible: true });
                } else {
                  if (Object.keys(this.contextService.agentSubscriptionList[agent[1].id].callDetails).length > 0) {
                    silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => { }, visible: true },
                      { separator: true, visible: true },
                      { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => { } },
                      { separator: true, visible: true },
                      { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => { } });
                  } else {
                    silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => { }, visible: true },
                      { separator: true, visible: true },
                      { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => { } },
                      { separator: true, visible: true },
                      { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => { } });


                  }

                }

              }
            }
          } else {
            if (Object.keys(this.contextService.agentSubscriptionList[agent[1].id].callDetails).length > 0) {
              silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => { }, visible: true },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => { } },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => { } });
            } else {
              silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => { }, visible: true },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => { } },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => { } });


            }
          }

          (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
            { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor'), items: silentMonitorItems, visible: true }
            , { separator: true, visible: true }
          );
        }
        if (agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
          (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
            { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => { }, visible: true }
          );
          if (this.contextService.isImConnected) {
            (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
              { separator: this.contextService.isImConnected, visible: true },
              { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => { }, visible: true }
            );
          }
        }
        s++;
      }
    }
    if (agentMenu[0].items.length > 0) {
      this.statsMenuItems.push({ separator: true, visible: true },
        agentMenu[0]);
    }

  }


  unAvailableMenuClick(event, targetId) {
    if (event.item.icon !== "tick") {
      this.contextService.websocketService.setAgentStateAction('Unavailable', event.item.target, targetId);
    }
  }
  activateForcedForwarding(acdUserId: string) {
    this.contextService.forcedForwardingCCId = acdUserId;
    this.contextService.forcedForwardingVisible = true;
  }
  sortAgents(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }
  joinLeaveQueue(id, type, targetId?) {
    if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
      if (targetId === this.contextService.objLoggedUserDetails.getUserId()) {
        targetId = "";
      }
      if (type === "join") {//check whether the product is agent web
        this.contextService.websocketService.joinLeaveQueue(id, true, targetId);//join a call center
      } else if (type === "leave") {//check whether the product is agent web
        this.contextService.websocketService.joinLeaveQueue(id, false, targetId);//leave a call center
      }
    }
  }

  sortSupervisor(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key) && this.contextService.objLoggedUserDetails.getUserId() !== key)
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }
  supervisorsMenu() {
    //add supervisor menu in tools 
    let supervisorMenu: MenuItem[];
    supervisorMenu = [
      {
        label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Supervisors')
      }
    ];
    let isSupervisorForMe: boolean = false;
    supervisorMenu[0].items = [];
    let supervisorList;
    supervisorList = this.sortSupervisor(this.contextService.agentDetails.callCenters[this.selectedCallcenter.queueID].supervisorList);//sort supervisor list based on name


    let isOnCall = false;
    //check whether is there any active call
    let ringingCallNumber = 0;
    let holdCallCount = 0;
    let activeCallExist = false;
    let ringingCallExist = false;
    let holdCallExist = false;
    let activeCallId = "";
    for (let index = 0; index < this.contextService.callStatusList.length; index++) {
      if (this.contextService.callStatusList.length == 1 && !this.contextService.callStatusList[index].isQueuedCall) {
        isOnCall = true;
        if (this.contextService.callStatusList[index].callcenterId) {
          activeCallId = this.contextService.callStatusList[index].callcenterId;
        }
      } else {
        if ((this.contextService.callStatusList[index].displayState == 'Active') && (!activeCallExist && !holdCallExist)) {
          isOnCall = true;
          activeCallExist = true;
          if (this.contextService.callStatusList[index].callcenterId) {
            activeCallId = this.contextService.callStatusList[index].callcenterId;
          }
        } else if ((this.contextService.callStatusList[index].displayState == 'Active') && (activeCallExist || holdCallExist)) {
          isOnCall = false;
          activeCallId = "";
        }
        if ((this.contextService.callStatusList[index].displayState == 'Ringing' && !this.contextService.callStatusList[index].isQueuedCall) && (activeCallExist || holdCallExist)) {
          isOnCall = false;
          activeCallId = "";
        } else if (this.contextService.callStatusList[index].displayState == 'Ringing' && !this.contextService.callStatusList[index].isQueuedCall && !activeCallExist) {
          isOnCall = true;
          ringingCallNumber++;
          ringingCallExist = true;
          if (this.contextService.callStatusList[index].callcenterId) {
            activeCallId = this.contextService.callStatusList[index].callcenterId;
          }
        }

        if ((this.contextService.callStatusList[index].displayState == 'On hold' && !this.contextService.callStatusList[index].isQueuedCall) && (activeCallExist || ringingCallExist)) {
          isOnCall = false;
          activeCallId = "";
        } else if (this.contextService.callStatusList[index].displayState == 'On hold' && !this.contextService.callStatusList[index].isQueuedCall) {
          isOnCall = true;
          holdCallExist = true;
          holdCallCount++;
          if (this.contextService.callStatusList[index].callcenterId) {
            activeCallId = this.contextService.callStatusList[index].callcenterId;
          }
        }
      }
    }
    if (ringingCallNumber > 1 || holdCallCount > 1) {
      isOnCall = false;
      activeCallId = "";
    }
    if (this.contextService.selectedRows[0]) {
      if (this.contextService.selectedRows[0].isQueuedCall) {
        isOnCall = false;
        activeCallId = "";
      } else {
        isOnCall = true;
        activeCallId = this.contextService.selectedRows[0].callcenterId;
      }
    }

    let k = 0;
    let callCenterCall = false;
    for (let j = 0; j < supervisorList.length; j++) {
      let supervisor = supervisorList[j];
      if (activeCallId === this.selectedCallcenter.queueID) {
        callCenterCall = true;
      } else if ((this.contextService.selectedRows[0] && this.contextService.selectedRows[0].callcenterId === this.selectedCallcenter.queueID) && !this.contextService.selectedRows[0].isQueuedCall) {
        this.contextService.log(this.contextService.selectedRows[0]);
        callCenterCall = true;
      } else {
        callCenterCall = false;
      }
      if (supervisor[1].isMonitoringMe === true) {
        /* make true only if the call is a call center call and for 
      the monitoring supervisor so that escalate/transfer could be performed only for that supervisor*/
        if (callCenterCall === true) {
          isSupervisorForMe = true;
        }
      } else {
        if (this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor === true) {
          continue; // don't add other supervisors if the flag is true; skipping loop to the next supervisor
        }
        isSupervisorForMe = false;
      }
      //adding supervisors to the supervisor menu
      (<MenuItem[]>supervisorMenu[0].items).push(
        {
          label: supervisor[1].firstName + " " + supervisor[1].lastName, items: []
        });

      if (isSupervisorForMe) {
        (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
          { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall'), command: (event) => { this.callActionsInQueue('escalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true },
          { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall'), command: (event) => { this.callActionsInQueue('emergencyEscalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
        );
      } else {
        (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
          { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => { this.contextService.websocketService.dialAction(this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
        );
      }
      if (isOnCall) {
        (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
          { separator: isOnCall, visible: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => { this.callActionsInQueue('transfer', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
        );
      }
      if (this.contextService.isImConnected) {
        (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
          { separator: this.contextService.isImConnected, visible: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => { this.contextService.instantMessage(supervisor[1].id) }, visible: true }
        );
      }
      k++;
    }
    //insert supervisor menu only if supervisors are returned from the server
    if (this.contextService.ongoingCallCenterCallDetails && supervisorMenu[0].items.length > 0) {
      if (callCenterCall) {
        this.statsMenuItems.push({ separator: true, visible: true },
          { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall.First'), command: (event) => { this.callActionsInQueue('escalateToFirst') }, visible: true },
          { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall.First'), command: (event) => { this.callActionsInQueue('emergencyEscalateToFirst') }, visible: true }, { separator: true, visible: true },
          supervisorMenu[0]);
      } else {
        this.statsMenuItems.push({ separator: true, visible: true },
          supervisorMenu[0]);
      }
    } else if (supervisorMenu[0].items.length > 0 && this.contextService.callStatusList.length > 0 && callCenterCall) {
      // for (let index = 0; index < this.contextService.callStatusList.length; index++) {
      // const element = this.contextService.callStatusList[index];
      // if (element.isCallCenterCall && element.callcenterId == this.selectedCallcenter.queueID) {
      this.statsMenuItems.push({ separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall.First'), command: (event) => { this.callActionsInQueue('escalateToFirst') }, visible: true },
        { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall.First'), command: (event) => { this.callActionsInQueue('emergencyEscalateToFirst') }, visible: true }, { separator: true, visible: true },
        supervisorMenu[0]);
      // } else {
      //   this.statsMenuItems.push({ separator: true, visible: true },
      //     supervisorMenu[0]);
      // }

      // }
    } else if (supervisorMenu[0].items.length > 0) {
      this.statsMenuItems.push({ separator: true, visible: true },
        supervisorMenu[0]);
    }


  }
  callActionsInQueue(action: string, transferTo?: string) {
  }
  changeSort(event) {
    this.contextService.log("changeSort() entered");
    this.contextService.calculateIndexOfPW();
    this.contextService.log(event);
    this.contextService.objSettings.stat_sortOrder = event.order;
    this.contextService.objSettings.stat_sortField = event.field;
    if (this.contextService.objLoggedUserDetails) {
      this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
    }
  }
  onRowDblclick(event) {
    this.contextService.log("statistics onRowDblclick() entered");
    this.contextService.log(event);
    this.contextService.websocketService.dialAction(event.phone);

  }

  /**
  * queueName field is set as default sort
  */
  setDefaultSort() {
    this.contextService.log("setDefaultSort() entered");
    this.sortF = 'queueName';
    this.sortO = '1';
  }

  setSort() {
    this.contextService.log("setSort() entered");
    if (this.contextService.objSettings.stat_sortField && this.contextService.objSettings.stat_sortOrder) {
      this.sortF = this.contextService.objSettings.stat_sortField;
      this.sortO = this.contextService.objSettings.stat_sortOrder;
    }
    else {
      this.contextService.log("going to enter setDefaultSort()");
      this.setDefaultSort();
    }
    setTimeout(() => {
      this.mystats.sortField = this.sortF;
      this.mystats.sortOrder = this.sortO == "1" ? 1 : -1;
      //this.datatable.sortOrder = 1;
      this.mystats.sortSingle();

    }, 50);
  }
  removeCallCenter(id) {
    this.contextService.log("removeCallCenter() entered");
    for (let index = 0; index < this.contextService.statistics.length; index++) {
      const element = this.contextService.statistics[index];
      if (element.queueID == id) {
        element.isDisplayInPersonalWallboard = false;
        this.contextService.showAgentActivity.next({ 'command': 'RemoveFromPW', 'queueID': id });
        if (this.contextService.objSettings.showInPersonalWBList[id]) {
          this.contextService.log("ID exist in showInPersonalWBList");
          this.contextService.objSettings.showInPersonalWBList[id].showInWallBoard = false;
        } else {
          this.contextService.log("ID not exist in showInPersonalWBList");
          this.contextService.objSettings.showInPersonalWBList[id] = { showInWallBoard: id };
        }
        this.contextService.log(this.contextService.objSettings);
        this.contextService.websocketService.settings(this.contextService.objSettings);
      }

    }
    this.contextService.checkIfShowPersonalWallboard();
  }

  instantMessage(data) {
    this.messageUserObject = [];
    for (const key in data.agentList) {
      if (data.agentList.hasOwnProperty(key)) {
        if (data.agentList[key].isSupervised && this.contextService.objLoggedUserDetails.getUserId() !== key) {
          this.messageUserObject.push(this.getDirectortObjFromUserID(key));
        }
      }
    }
    if (this.messageUserObject.length > 0) {
      if (this.contextService.chatWindowStatus === false) {
        this.chatService.toggleChat.next(true);
      }
    }
    let temp = false;
    let chatUsers = [];
    this.messageUserObject.forEach(element => {
      chatUsers.push(element.userId);
    });
    let chatArrayLength = chatUsers.length;
    let noNewChat = false;
    let singleChatID;

    if (chatArrayLength === 1) {
      chatUsers.forEach(element => {
        this.id = element + "_" + Math.floor(Date.now() / 1000);
        singleChatID = element;
      });
      let result = this.id.substring(0, this.id.lastIndexOf("_"));
      this.contextService.conversations.forEach(conversationsElement => {

        if (conversationsElement.people.length === 1) {
          conversationsElement.people.forEach(element => {

            if (element.id === result) {
              noNewChat = true;

              this.id = conversationsElement.id.toString();
            }

          });
        }
      });
      this.contextService.deletedConversations.forEach(element => {
        if (element.people.length === 1) {
          let deletedMessageID = element.id.toString().substring(0, element.id.toString().lastIndexOf("_"));

          if (deletedMessageID === result) {
            this.contextService.conversations.push(element);
            noNewChat = true;
            this.id = element.id.toString();
            // this.contextService.active = "";
            let index: number = this.getConversationKeyById(element.id);
            if (index > -1) {
              this.contextService.deletedConversations.splice(index, 1);
            }

          } else if (element.people.includes(result)) {
            this.contextService.conversations.push(element);
            this.id = element.id.toString();
            noNewChat = true;
            let index: number = this.getConversationKeyById(element.id);
            if (index > -1) {
              this.contextService.deletedConversations.splice(index, 1);
            }
          }
        }

      });

      if (!noNewChat) {
        let people = [];
        let obj = { "id": singleChatID, "name": this.contextService.getNameFromUserID(singleChatID) };
        people.push(obj);

        let c = new ChatConversation({
          "id": this.id, "people": people, "headline": this.contextService.getNameFromUserID(singleChatID), "unread": 0, "offline": false, "message": ""
        });
        this.contextService.conversations.push(c);
        this.contextService.messageArray.push({
          dateTime: "",
          headline: "",
          id: this.id,
          itsMe: false,
          person: [99],
          text: ""
        });

      }

    }
    else {
      let exist = false;
      this.contextService.conversations.forEach(conversationsElement => {
        if (conversationsElement.people.length === chatArrayLength) {

          let i = 0;
          conversationsElement.people.forEach(element => {


            if (chatUsers.includes(element.id)) {
              i++;
            }
          });

          if (conversationsElement.people.length === chatArrayLength && conversationsElement.people.length === i) {
            this.id = conversationsElement.id.toString();
            exist = true;
          }

        }
      });

      this.contextService.deletedConversations.forEach(conversationsElement => {
        if (conversationsElement.people.length === chatArrayLength) {

          let i = 0;
          conversationsElement.people.forEach(element => {
            if (chatUsers.includes(element.id)) {
              i++;
            }
          });

          if (conversationsElement.people.length === chatArrayLength && conversationsElement.people.length === i) {
            this.contextService.conversations.push(conversationsElement);
            this.id = conversationsElement.id.toString();
            exist = true;

            let index: number = this.getConversationKeyById(conversationsElement.id);
            if (index > -1) {
              this.contextService.deletedConversations.splice(index, 1);
            }
          }

        }
      });

      if (!exist) {
        temp = false;

        this.messageUserObject.forEach(messageUserObjectElement => {
          if (temp === false) {
            temp = true;
            this.id = messageUserObjectElement.userId + "_" + Math.floor(Date.now() / 1000);
            let people1 = [];
            let obj = { "id": messageUserObjectElement.userId, "name": messageUserObjectElement.name };
            people1.push(obj);
            let c = new ChatConversation({
              "id": this.id, "people": people1, "headline": messageUserObjectElement.name, "unread": 0, "offline": false, "message": ""
            });
            this.contextService.conversations.push(c);
            this.contextService.messageArray.push({
              dateTime: "",
              headline: "",
              id: this.id,
              itsMe: false,
              person: [99],
              text: ""
            });
          }
          else {
            let tempName = false;
            this.contextService.conversations.forEach(elementConversations => {
              if (elementConversations.id.toString() === this.id) {
                elementConversations.people.forEach(elementPeople => {

                  if (elementPeople.id === messageUserObjectElement.userId) {
                    tempName = true
                  }
                });
              }
              if (!tempName && elementConversations.id.toString() === this.id) {

                let obj = { "id": messageUserObjectElement.userId, "name": messageUserObjectElement.name };
                elementConversations.people.push(obj);
                let headline = [];
                elementConversations.people.forEach(elementPeople => {
                  headline.push(elementPeople.name)
                });
                elementConversations.headline = headline.toString();
              }
            });
          }
        });
      }
    }

    this.contextService.messages = [];
    this.contextService.messageArray.forEach(elementMessageArray => {
      if (elementMessageArray.id == this.id && elementMessageArray.text.length > 0) {
        this.contextService.messages.push(elementMessageArray);
      }
    });
    this.contextService.conversations.forEach(element => {
      if (this.id == element.id.toString()) {
        if (element.unread > 0) {
          element.unread = 0;
        }
      }
    });
    if (this.contextService.active != this.id) {
      this.activeId(this.id);

    }


  }

  activeId(id: string) {
    this.contextService.activateId.next(id);
  }

  getDirectortObjFromUserID(loginID: string): BwDirectoryEntry {
    for (let i = 0; i < this.contextService.directoryList.length; i++) {
      if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
        let element = this.contextService.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === loginID) {
          return element;
        }
      }
    }
  }
  getConversationKeyById(id: number) {
    let ret = -1;
    let i = 0;
    for (let conv of this.contextService.deletedConversations) {
      if (conv.id == id) {
        ret = i;
      }
      i++;
    }
    return ret;
  }
}
