<p-dialog
  [(visible)]="callBackModalVisible"
  [ngClass]="{ visible: callBackModalVisible, 'is-reserved': reserved }"
  [closable]="false"
  class="small-dialog messages-dialog notes-dialog"
  dismissableMask="true"
  [resizable]="false"
  [draggable]="false"
  showHeader="true"
  #dialCallBack
>
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">
        {{ title }}
      </h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
      <span
        class="maximize-modal"
        (click)="messagesService.toggleDialogMaximized(dialCallBack)"
      ></span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="notes-modal-content">
      <ul class="buttons-menu in-modal">
        <!-- reject -->
        <li *ngIf="!reserved">
          <div class="tooltip">
            <span>{{ rejectBtn }}</span>
          </div>
          <a (click)="reject($event)" class="btn-reject new-i">{{
            rejectBtn
          }}</a>
        </li>
        <!-- reserve -->
        <li *ngIf="!reserved">
          <div class="tooltip" style="white-space: nowrap">
            <span>{{ reserveBtn }}</span>
          </div>
          <a (click)="reserve($event)" class="btn-reserve new-i">{{
            reserveBtn
          }}</a>
        </li>

        <!-- transfer -->
        <li *ngIf="reserved" #transferToCb>
          <div class="tooltip">
            <span>{{ transferBtn }}</span>
          </div>
          <a
            (click)="openTransferToCm($event, transferTocmCb)"
            class="btn-transfer-cb new-i"
            >{{ transferBtn }}</a
          >

          <p-contextMenu
            [model]="transferToList"
            appendTo="body"
            [target]="transferToCb"
            #transferTocmCb
          >
          </p-contextMenu>
        </li>
        <!-- schedule -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ scheduleBtn }}</span>
          </div>
          <a
            (click)="schedule($event)"
            class="btn-schedule new-i"
            [ngClass]="{ active: scheduleFormVisible }"
            >{{ scheduleBtn }}</a
          >
        </li>
        <!-- close -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ closeBtn }}</span>
          </div>
          <a (click)="close($event)" class="btn-close new-i">{{ closeBtn }}</a>
        </li>
        <!-- view in CRM -->
        <li>
          <div class="tooltip">
            <span>{{ viewInCRMBtn }}</span>
          </div>
          <a (click)="viewInCRM($event)" class="btn-view-in-crm new-i">{{
            viewInCRMBtn
          }}</a>
        </li>
        <!-- add entry to CRM -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ addCRMBtn }}</span>
          </div>
          <a
            (click)="addCRMLogEntry($event)"
            [ngClass]="{ active: crmFormVisible }"
            class="btn-add-crm-entry new-i"
            >{{ addCRMBtn }}</a
          >
        </li>
        <!-- att -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ attachmentBtn }}</span>
          </div>
          <label for="cb_att_call_back" class="btn-attachment new-i">
            {{ attachmentBtn }}
          </label>
        </li>
      </ul>

      <div class="messages-window">
        <div class="mw-left">
          <div class="info-schedule">
            <app-schedule
              [hidden]="!scheduleFormVisible"
              (onSubmit)="onScheduleSubmit($event)"
              (onClose)="onScheduleClose($event)"
              (onClear)="onScheduleClear($event)"
              [scheduleDateSelected]="scheduleDateSelected"
            ></app-schedule>

            <div class="info-wrap">
              <app-user-details [user]="user"></app-user-details>
            </div>
          </div>
          <app-history
            [historyEntries]="history"
            [visible]="callBackModalVisible"
          ></app-history>
        </div>
        <div class="mw-right" [ngClass]="{ 'crm-visible': crmFormVisible }">
          <div class="notes-wrapper">
            <app-add-crm-entry
              [hidden]="!crmFormVisible"
              (onSubmit)="onCrmSubmit($event)"
              (onClose)="onCrmClose($event)"
            ></app-add-crm-entry>

            <div class="container" [perfectScrollbar] id="callback-scroll-pane">
              <div class="content">
                <ul class="notes" [hidden]="selectedTab !== 'notes'">
                  <li
                    *ngFor="let note of notes; let i = index"
                    ngClass="{{ note.ico }}"
                  >
                    <strong ngClass="{{ note.ico }}">{{
                      note.headline
                    }}</strong>
                    <span class="timestamp">{{
                      note.dateTime | datex: "MM/DD/YYYY hh:mm:ss A"
                    }}</span>
                    <span *ngIf="note.info" [innerHTML]="note.info"></span>
                    <span
                      class="note-text"
                      *ngIf="note.text"
                      [innerHTML]="note.text"
                    ></span>
                    <div class="note-attachment" *ngIf="note.attachment?.url">
                      <app-attachment
                        [attachment]="note.attachment"
                      ></app-attachment>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="replies-wrapper">
            <div class="replies-wrapper">
              <div class="add-note">
                <app-web-chat-conversation
                  [componentName]="'call_back'"
                  [placeholder]="addNotePlaceholder"
                  (onMessageSubmit)="onMessageSubmit($event[0], $event[1])"
                ></app-web-chat-conversation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
