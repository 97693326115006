import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { CallForwardningAlwaysService } from '../../../../../providers/settings/settings-services/call-forwarding-always.service';

/**
 * Used to store call log properties and this serves as a interface between component and calllogs service
 * 
 * @export
 * @class CallLogsComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-call-forwarding-always',
  templateUrl: './call-forwarding-always.component.html',
  styleUrls: ['./call-forwarding-always.component.scss']

})

export class CallForwardingAlways implements OnInit {

  ringSplash: string="";
  description: String="";
  forwardNumber: String="";
  enabled: String="";

  constructor(private contextService: ContextService, public callForwardningAlwaysService: CallForwardningAlwaysService) {

  }
  ngOnInit() {
    this.callForwardningAlwaysService.cfaChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallForwardAlways.Description');
    this.forwardNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.ringSplash = this.contextService.getLanguageTagByKey('Settings.Services.RingSplash');

  }

  focus() {
    document.getElementById("textarea").focus();
  }

}
