import { Injectable } from '@angular/core';
import { CallTransfer } from '../../../model/bw-services/call-transfer';
import { ContextService } from '../../../providers/context.service';
import { SettingsGeneral } from './../settings-general/settings-general.service';
import { BwServices } from './bw-services.service';
/**
 * service for call transfer
 * 
 * @export
 * @class CallTransferService
 */
@Injectable()
export class CallTransferService {

    callTransfer: CallTransfer;
    allowCallTransferRecall: boolean = false;
    numberOfRingsBeforeRecall: string = "";
    allowBusyCampOn: boolean = false;
    campCallsFor: string = "";
    blindTransferInhibitor: boolean = false;
    consultativeCallsInhibitor: boolean = false;
    calltransferChanges: boolean = false;
    numberValidation: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService, private settingsGeneral: SettingsGeneral) {
    }
    setData(callTransfer: CallTransfer) { }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    save() { }
    populateData() { }
    cancel() { }

}