"use strict";
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataService } from 'app/providers/dataservice.service';
import { HelperService } from 'app/providers/helper.service';
import { MenuItem } from 'primeng/api/menuitem';
import { VoiceMailEntry } from '../../model/voice-mail/voice-mail-entry';
import { ContextService } from '../../providers/context.service';



@Component({
  selector: 'app-voicemail',
  templateUrl: './voicemail.component.html',
  styleUrls: ['./voicemail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VoicemailComponent implements OnInit {

  logsLater: Array<any> = [];
  logsRecent: Array<any> = [];

  logs: Array<any>[] = [];
  contextMenuItems: MenuItem[];
  voiceSelectedRadio: string = "";
  voiceTableHeight;
  voiceTableMenuHeight;
  scrolling: boolean = false;

  selectedVoiceMessageEntry: VoiceMailEntry;
  deleteVoiceMessageConfirm: boolean = false;
  searchTerm: string = "";
  sortO: string = '';
  sortF: string = '';


  voicemailSearch: string = "";
  name: string = "";
  phoneNumber: string = "";
  duration: string = "";
  callDate: string = "";
  deleteVoicemail: string = "";
  miscNo: string = "";
  miscYes: string = "";

  selectedContact;
  rowGroupMetadata: any;
  myUrl;


  //dragula bags
  voicebag;

  @ViewChild('voicemail') voicemailTable;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      if (this.helper.selectedTableTab === 'voicemail' && this.voicemailTable) {
        this.helper.calcCellsWidth(this.voicemailTable);
        this.calcVoiceHeight();
      }
    }, 200);
  }

  constructor(private dataService: DataService,
    private helper: HelperService,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
    public contextService: ContextService) {
    this.cd.detach();



    this.contextService.updateMediaPlayerSource.subscribe(() => {


      // let source = document.getElementById('mediaSource') as HTMLImageElement;
      //   if (source != null) {
      // source.src = 'data:audio/mp3;base64,' + this.contextService.urlVoiceMessage;
      //   }
      // let source1 = document.getElementById('mediaSource1') as HTMLImageElement;
      //   if (source1) {
      // source1.src = 'data:audio/mp3;base64,' + this.contextService.urlVoiceMessage;
      // }
      let sounds1 = document.getElementsByTagName('audio');
      for (let i = 0; i < sounds1.length; i++) {
        sounds1[i].currentTime = 0;
        sounds1[i].load();
      }

      if (!this.contextService.downloadFlag) {
        // let source = document.getElementById('mediaSource') as HTMLImageElement;
        // if (source != null) {
        //   source.src = 'data:audio/mp3;base64,' + this.contextService.urlVoiceMessage;
        // }
        // let source1 = document.getElementById('mediaSource1') as HTMLImageElement;
        // if (source1) {
        //   source1.src = 'data:audio/mp3;base64,' + this.contextService.urlVoiceMessage;
        // }
        // let sounds1 = document.getElementsByTagName('audio');
        // for (let i = 0; i < sounds1.length; i++) {
        //   sounds1[i].currentTime = 0;
        //   sounds1[i].load();
        // }
        for (let i = 0; i < sounds1.length; i++) {
          sounds1[i].play();
        }
      } else {

        let a = document.getElementById('forhref') as HTMLImageElement;
        a.setAttribute("href", this.contextService.urlVoiceMessage);
        document.getElementById('forhref').click();
        this.contextService.downloadFlag = false;
      }

    });

    this.contextService.updateVoiceMailTableUpdate.subscribe(() => {
      this.contextService.voiceMailList.forEach((element, index) => {
        if (element.messageId === this.contextService.deletedVoiceMessageId) {
          let index = this.findSelectedVoiceMessageIndex();
          this.contextService.voiceMailList = this.contextService.voiceMailList.filter((val, i) => i != index);
        }
      });
      this.contextService.mediaPlayerState = false;
    });
  }

  ngOnInit() {
    this.voicemailSearch = this.contextService.getLanguageTagByKey('TabShowing.Voicemail.Search');
    this.name = this.contextService.getLanguageTagByKey('Misc.Name');
    this.phoneNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.duration = this.contextService.getLanguageTagByKey('List.CurrentCalls.Column.Duration');
    this.callDate = this.contextService.getLanguageTagByKey('List.CallLogs.Column.CallDate');
    this.deleteVoicemail = this.contextService.getLanguageTagByKey('Prompt.DeleteVoicemail');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');

    let grouped = this.groupBy(this.contextService.voiceMailList, 'period');
    this.logsLater = grouped.Later;
    this.logsRecent = grouped.Recent;

    // this.dataService.getLogs().subscribe(
    //   (response: Response) => {
    //     const data = response.json().data;
    //     this.logs = data;
    //   }
    // );

    this.helper.tableTabChanged.subscribe((val) => {
      this.cd.reattach();
      setTimeout(() => {
        if (this.contextService.voiceMailList != null) {
          this.helper.calcCellsWidth(this.voicemailTable);
        }
      });
    });

    this.helper.recalcVoicemailHeight.debounceTime(250).subscribe((val) => {
      if (this.contextService.voiceMailList) {
        this.calcVoiceHeight();
      }
    });

    this.helper.resized.debounceTime(600).subscribe((val) => {
      this.helper.calcCellsWidth(this.voicemailTable);
    });

    // unselect on drag start
    this.helper.dragStart.subscribe((val) => {
      this.selectedContact = [];
    });

    if (this.contextService.objSettings.voiceMessage_sortField && this.contextService.objSettings.voiceMessage_sortOrder) {

      this.sortF = this.contextService.objSettings.voiceMessage_sortField;
      this.sortO = this.contextService.objSettings.voiceMessage_sortOrder;
    }

  }
  findSelectedVoiceMessageIndex(): number {
    return this.contextService.voiceMailList.indexOf(this.selectedVoiceMessageEntry);
  }
  rowClick(e) {
    this.helper.disableRows(this.voicemailTable);
  }

  contextMenuSelected(e, cm) {
    this.helper.hideAllCMenus();
    this.helper.saveAllCMenus(cm);
    cm.show();
    this.contextMenuItems = [];
    this.contextMenuItems = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.Play'), command: (event) => this.onRowDblclick_play(this.selectedVoiceMessageEntry) },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.Save'), command: (event) => this.saveVoiceMessage(this.selectedVoiceMessageEntry) },
      { separator: true, visible: true },
      { label: this.contextService.getLanguageTagByKey('Misc.Delete'), command: (event) => this.deleteVoiceMessageConfirmation(this.selectedVoiceMessageEntry) },
      { separator: true, visible: true },
      { label: this.contextService.getLanguageTagByKey('ContactCenter.Type.Call'), command: (event) => this.call(this.selectedVoiceMessageEntry) },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CopyNumber'), command: (event) => this.CopyToClipboard(this.selectedVoiceMessageEntry.address) }

    ];

    this.helper.disableRows(this.voicemailTable);
    if (this.contextService.isImConnected) {
      this.contextMenuItems.push({ separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.startInstanceMessage(this.selectedVoiceMessageEntry) })
    }
  }

  calcVoiceHeight() {
    const heights = this.helper.calcTableHeight(this.el, this.contextService.voiceMailList, 80);
    this.voiceTableHeight = heights.th;
    this.voiceTableMenuHeight = heights.mh;
    this.scrolling = heights.scrolling;
  }

  clearLogs() {
    // clear logs
  }

  /**
   * Make call to extension
   * 
   * @param event 
   */
  call(event) {
    this.contextService.websocketService.dialAction(event.address);
    this.contextService.log("Dial action to:" + event.address);
  }
  CopyToClipboard(phoneNumber) {
    this.contextService.copy(phoneNumber);
  }

  /**
   * Double click voice message entry
   * @param event 
   */
  onRowDblclick_play(event) {
    this.contextService.log("start Method: onRowDblclick_play()");

    this.contextService.log("End Method: onRowDblclick_play()");
  }

  deleteVoiceMessageConfirmation(event) {
    this.contextService.log("start Method: deleteVoiceMessageConfirmation()");
    this.contextService.deletedVoiceMessageId = "";
    if (event.messageId) {
      this.deleteVoiceMessageConfirm = true;
      this.contextService.deletedVoiceMessageId = event.messageId;
    }
    this.contextService.log("End Method: deleteVoiceMessageConfirmation()");
  }
  /**
   * Delete voice mail entry Action
   */
  deleteVoiceMailEntry() {
    this.contextService.log("start Method: deleteVoiceMailEntry()");
    this.contextService.log("End Method: deleteVoiceMailEntry()");
  }

  refreshVoiceMessage() {

  }
  startInstanceMessage(event) {
    this.contextService.instantMessage(event.userId);
  }
  /**
   * Save voice message
   * @param event 
   */
  saveVoiceMessage(event) {

  }
  /**
   * Down load voice mail mp3 file
   * @param dataurl 
   * @param filename 
   */
  download(dataurl, filename) {

    let a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    let b = document.createEvent("MouseEvents");
    b.initEvent("click", false, true);
    a.dispatchEvent(b);
    return false;
  }

  /**
   * close voice message player
   */
  closePlayer() {
    this.contextService.mediaPlayerState = false;
    this.contextService.urlVoiceMessage = "";
    let sounds1 = document.getElementsByTagName('audio');
    for (let i = 0; i < sounds1.length; i++) {
      sounds1[i].pause();
      sounds1[i].currentTime = 0;
    }
    // for (let i = 0; i < sounds1.length; i++) {
    //   sounds1[i].currentTime = 0;
    // }

  }

  clearSearch() {
    this.voicemailTable.reset();
    this.searchTerm = '';

  }
  changeSortVoiceMessage(event) {
    this.updateRowGroupMetaData();
    this.contextService.objSettings.voiceMessage_sortOrder = event.order;
    this.contextService.objSettings.voiceMessage_sortField = event.field;
    this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());

  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  groupedSort(event) {
    this.contextService.voiceMailList = [].concat(this.sortGroupedLogs(this.logsRecent, event)).concat(this.sortGroupedLogs(this.logsLater, event));
  }

  sortGroupedLogs(logs, event) {
    return [...logs.sort((a, b) => {
      if (a[event.field] < b[event.field]) {
        return -event.order;
      }
      if (a[event.field] > b[event.field]) {
        return event.order;
      }
      return 0;
    })];
  }
  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.contextService.voiceMailList) {
      for (let i = 0; i < this.contextService.voiceMailList.length; i++) {
        let rowData = this.contextService.voiceMailList[i];
        let period = rowData.period;
        if (i == 0) {
          this.rowGroupMetadata[period] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.contextService.voiceMailList[i - 1];
          let previousRowGroup = previousRowData.period;
          if (period === previousRowGroup)
            this.rowGroupMetadata[period].size++;
          else
            this.rowGroupMetadata[period] = { index: i, size: 1 };
        }
      }
    }
  }
}
