/** 
 * C8 - model to store a sms request
 */

import { CallStatusEntry } from './call-status/call-status-entry';

export class SmsEntry extends CallStatusEntry {


}
