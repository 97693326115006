<div class="row">
  <div class="agent-activity-controls">
    <!-- <div class="call-log-filter"> -->
    <p-dropdown placeholder={{langTag_select}} [options]="agentActivityOptions"
      [(ngModel)]="this.agentactivityService.agentActivityTabDropDownValue" [style]="{'width':'20%'}"
      (ngModelChange)=" this.dropdownValueChanged()" appendTo="body" styleClass="specificdropdown"
      (click)="loadDropdownOptions()">
    </p-dropdown>
    <p-radioButton name="AgentActivity" label={{langTag_queues}} value="Queues"
      [(ngModel)]="this.agentactivityService.agentActivityTabRadioButonValue"
      (click)="agentActivityRadioButtoChanged('Queues')">
    </p-radioButton>
    <p-radioButton name="AgentActivity" label={{langTag_Agents}} value="Agents"
      [(ngModel)]="this.agentactivityService.agentActivityTabRadioButonValue"
      (click)="agentActivityRadioButtoChanged('Agents')">
    </p-radioButton>
    <!-- <p-radioButton name="calllog" label="{{dialed}}" value="1" [(ngModel)]="callLogsService.selectedValue" (click)="selectCallType(1)"></p-radioButton> -->
    <!-- </div> -->
    <div class="call-log-btns">
      <span class="queue-join" (click)="join()" title={{langTag_join}}></span>
      <span class="queue-leave" (click)="leave()" title={{langTag_leave}}></span>
      <!-- <span class="ref" (click)="reset();" title="Reset">reset</span>
      <span class="dl" (click)="download()" title="Download">download</span> -->
    </div>
  </div>
</div>

<p-table [value]="this.agentactivityService.statToDisplay" selectionMode="single" [(selection)]="selectedAgent"
  [sortField]="sortF" [sortOrder]="sortO" (onSort)="changeSort($event)" (sortFunction)="customSort($event)"
  [customSort]="true" [contextMenu]="cm" [(contextMenuSelection)]="selectedAgent"
  (onContextMenuSelect)="contextMenuSelected($event,cm)" [resizableColumns]="false" columnResizeMode="fit"
  [scrollable]="true" scrollHeight="{{scrollHeight}}" dataKey="id" [ngClass]="{'scrolling':scrolling === true}"
  contextMenuSelectionMode="joint" #agentActivitytable id="agentActivity-table" [primeDragula]="bag"
  [dragulaModel]="this.agentactivityService.statToDisplay"
  [dragulaOptions]="{  childContainerSelector: '#agentActivity-table .ui-table-scrollable-body-table .ui-table-tbody', initAfterView: true, copy: false, childContainerSelectorID: 'agentActivity-body'}">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th [pSortableColumn]="'name'">Name
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <!-- supervisor -->
      <!-- remove  [style]="{'width':this.contextService.statColWidth}" unused -->
      <th *ngFor="let col of this.contextService.agentActivityCols;let i=index"
        [style]="{'width':this.contextService.statColWidth}" pTooltip="{{col.header}}" showDelay="400" hideDelay="300"
        tooltipPosition="top" [pSortableColumn]="col.field">
        {{col.header}}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr (click)="rowClick(rowData)" (dblclick)="onRowDblclick(rowData)" [pSelectableRowDblClick]="rowData"
      [pContextMenuRow]="rowData" [pSelectableRow]="rowData">
      <td class="ico-cell">
        <!-- <span class="ui-column-title" style="float:left">a</span> -->
        <span class="online-{{rowData.isOnline}}" [attr.data-id]="rowData.id"></span>
        <span class="ico-status  ico-{{rowData.icon}}"></span>
      </td>
      <td class="name-cell">
        {{rowData.name}}</td>
      <td #cellColor *ngFor="let col of this.contextService.agentActivityCols;let i=index"
        [style.background-color]="rowData[col.color]">
        <span
          [style.color]="setCellColor(rowData[col.field], cellColor.style.backgroundColor,col,rowData)">{{rowData[col.field]}}</span>

      </td>
    </tr>
  </ng-template>
</p-table>
<div class="cmbox" [ngStyle]="{'height': blankSpaceHeight,'background-color':'white'}"></div>
<p-table [value]="this.agentactivityService.summaryStat" id="my-stats-summary" resizableColumns="false"
  columnResizeMode="fit" #statSummary>
  <ng-template pTemplate="header" let-rowData let-columns="columns">
    <tr>
      <td [style.width.px]="statsCellDimension[0]" [style.maxWidth.px]="statsCellDimension[0]"></td>
      <td [style.width.px]="statsCellDimension[1]" [style.maxWidth.px]="statsCellDimension[1]">{{this.langTag_summary}}
      </td>
      <td *ngFor="let col of this.contextService.agentActivityCols;let i=index"
        [style.width.px]="statsCellDimension[i+2]" [style.maxWidth.px]="statsCellDimension[i+2]">
        {{this.agentactivityService.summaryStat[0][col.field]}}
      </td>
      <td *ngIf="this.blankSpaceHeight == '0px'" [style.width.px]="'17px'" [style.maxWidth.px]="'17px'"></td>
    </tr>
  </ng-template>
</p-table>

<p-contextMenu autoZIndex=true [model]="contextMenuItems" appendTo="body" #cm></p-contextMenu>
<p-contextMenu [model]="afterDropCM" appendTo="body" #afterdropmenuAgentActivity
  [style]="{'left':afterdropmenuAgentActivityLeft,'top':afterdropmenuAgentActivityTop}"></p-contextMenu>

<p-dialog [(visible)]="joinLeaveVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: joinLeaveVis}"
  class="dialog-window buttons-2" dismissableMask="true" showHeader="false" [resizable]="false">
  <div class="modal-content">
    <p>{{this.alertContent}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="submit()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="close()">{{miscNo}}</button>

    </div>
  </div>
</p-dialog>