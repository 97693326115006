import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import 'rxjs/add/operator/takeUntil';
import { ContextService } from './../../providers/context.service';
import { DirectoryService } from './../../providers/directory.service';
import { HelperService } from './../../providers/helper.service';

@Component({
  selector: 'app-add-personal-entry',
  templateUrl: './add-personal-entry.component.html',
  styleUrls: ['./add-personal-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddPersonalEntryComponent implements OnInit {
  addPersonalEntryModalVisible: boolean = false;
  invalidNumber: boolean = false;
  form: FormGroup;
  personalDirectoryAddto:string ="";
  miscName:string ="";
  miscPhone:string ="";
  btnOk: string ="";
  btnCancel:string ="";
  removeConfirm:string = "";
  yesLabel:string ="";
  noLabel:string = "";
  invalidPhNo:string ="";


  constructor(private helper: HelperService,
    private fb: FormBuilder, public directoryService: DirectoryService, private contextService: ContextService) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required]
    });

    this.helper.addPersonalEntryModalVis.subscribe(val => {
      this.addPersonalEntryModalVisible = val;
    });

    this.personalDirectoryAddto= this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.AddTo');
    this.miscName =this.contextService.getLanguageTagByKey('Misc.Name');
    this.miscPhone = this.contextService.getLanguageTagByKey('Misc.Phone');
    this.btnOk = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.btnCancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
    this.removeConfirm = this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Remove.Confirm');
    this.yesLabel = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.noLabel = this.contextService.getLanguageTagByKey('Misc.No');
    this.invalidPhNo = this.contextService.getLanguageTagByKey('Misc.InvalidPhoneNumber');

  }

  numValidate(event) {
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 40 || event.charCode == 41 || event.charCode == 46) {
      return true;
    }
    return false;
  }

  closeModal() {
    this.directoryService.resetUserDetails();
    this.addPersonalEntryModalVisible = false;
    this.directoryService.editFlag = false;
  }

  onSubmit() {
    var regex = /[\\/]/g
    if (this.form.value.phone && this.form.value.name) {
      if (this.form.value.phone.length == 1) {
        this.invalidPhNo = this.contextService.getLanguageTagByKey('Misc.InvalidPhoneNumber');
        this.invalidNumber = true;
      } else if(regex.test(this.form.value.name)){
        let found = this.form.value.name.match(regex);
        this.invalidPhNo = this.contextService.getLanguageTagByKey('PersonalDirectory.Prompt.NameNotValid') + " ("+found[0]+")";
        this.invalidNumber = true;
      }
      else {
        if (this.directoryService.editFlag) {
          this.directoryService.editUserFromPD();
        } else {
          this.directoryService.addUsertoPD();
        }
        this.addPersonalEntryModalVisible = this.directoryService.visibilityFlag;
      }
    }
  }

  onDelete() {
    this.directoryService.deleteUserFromPD();
    this.directoryService.deleteEntryVisible = false
  }
}
