import { Component, OnInit } from '@angular/core';
import { ContextService } from 'app/providers/context.service';
import { DirectedCallPickUpWithBargeInService } from 'app/providers/settings/settings-services/directed-call-pick-up-within-barge-in.service';

@Component({
  selector: 'app-directed-call-pickup-with-barge-in',
  templateUrl: './directed-call-pickup-with-barge-in.component.html',
  styleUrls: ['./directed-call-pickup-with-barge-in.component.scss']
})
export class DirectedCallPickupWithBargeInComponent implements OnInit {
  description:string="";
  playWarningToneText:string="";
  constructor(public contextService:ContextService,public directedCallPickUpWithBargeInService:DirectedCallPickUpWithBargeInService) { }

  ngOnInit() {
    this.directedCallPickUpWithBargeInService.directedCallPickUpWithBargeInChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.DirectedCallPickupWithBargeIn.Description');
    this.playWarningToneText=this.contextService.getLanguageTagByKey('Settings.Services.DirectedCallPickupWithBargeIn.PlayWarningTone');
  }

}
