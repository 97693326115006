<ul class="person">
  <li>{{ user.name }}</li>
  <li #copyPhoneTarget>
    <!-- tel:{{ user.phone }} -->
    <a
      href="#"
      (click)="call()"
      (contextmenu)="openCopyCm($event, copyCmPhone, copyCmEmail)"
      >{{ user.phone }}</a
    >
    <p-contextMenu
      [model]="phoneList"
      appendTo="body"
      [target]="copyPhoneTarget"
      #copyCmPhone
    ></p-contextMenu>
  </li>
  <li #copyEmailTarget>
    <!-- mailto:{{ user.email }} -->
    <a
      href="#"
      (click)="mailTo()"
      (contextmenu)="openCopyCm($event, copyCmEmail, copyCmPhone)"
      >{{ user.email }}</a
    >
    <p-contextMenu
      [model]="emailList"
      appendTo="body"
      [target]="copyEmailTarget"
      #copyCmEmail
    >
    </p-contextMenu>
  </li>
</ul>
<ul
  class="details"
  *ngIf="user.IP || user.department || user.userAgent || user.IP || user.url"
>
  <li *ngIf="user.department">{{ user.department }}</li>
  <li *ngIf="user.userAgent">{{ user.userAgent }}</li>
  <li *ngIf="user.IP">IP: {{ user.IP }}</li>

  <li *ngIf="user.url">
    <a href="{{ user.url }}" target="_blank"> {{ user.url }} </a>
  </li>
</ul>
