import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Attachment } from '../../model/attachment.model';

@Component({
  selector: 'app-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttachFileComponent implements OnInit {

  @Output() onFileSelect = new EventEmitter<any>();
  @Input() componentName: string;

  title: string = "";

  constructor(public contextService: ContextService, private messagesService: MessagesService) {
  }


  uploadFile(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    var attachments: Array<Attachment> = [];

    if (fileList && fileList.length) {
      for (var i = 0; i < fileList.length; i++) {
        var f = fileList[i];
        // await 
        var att = this.messagesService.uploadFile(f)
        attachments.push(att);
      }
      this.onFileSelect.emit(attachments);
    }

    return false;
  }

  ngOnInit() {
    this.title = this.contextService.getLanguageTagByKey('AttachFile.Title');
  }

}
