<p-dialog [(visible)]="calendarModalVisible" [closable]="false" [style]="{'width':width}"  [ngClass]="{visible: calendarModalVisible}" class="small-dialog calendar-dialog" dismissableMask="true" showHeader="true">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">Chris Tutt</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
    </div>
  </p-header>  
  <div class="modal-content">     
    <table id="calendar-view"> 
      <tr> 
        <td class="hour spacer"></td>
        <td class="day" *ngFor="let day of days; ">
          {{day}}
        </td>
      </tr>
      <tr *ngFor="let hour of hours; ">
        <td class="hour">{{hour}}</td>
        <td class="cell" *ngFor="let day of days; "> 
        </td>
      </tr>  
    </table> 
    <div  *ngFor="let event of events; " class="cal-event" [ngStyle]="{'top': event.top + 'px','height': event.height + 'px', 'left': event.left + 'px'}">{{event.title}}</div>
    
  </div>  
</p-dialog>