import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ProgressBarModule, AccordionModule, CalendarModule, CheckboxModule, ContextMenuModule, DialogModule, DropdownModule, InputSwitchModule, MenuModule, PanelMenuModule, ProgressSpinnerModule, RadioButtonModule, SharedModule, SpinnerModule, TableModule, TabViewModule, TieredMenuModule, TooltipModule, TreeModule, SplitButtonModule, ChipsModule } from 'primeng';
import { SupervisorComponent } from "../app/components/settings/settings-services/incoming-calls/call-center/supervisor/supervisor.component";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { AddPersonalEntryComponent } from './components/add-personal-entry/add-personal-entry.component';
import { AgentActivityTableComponent } from './components/agent-activity-table/agent-activity-table.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CallLogsComponent } from './components/call-logs/call-logs.component';
import { ChatComponent } from './components/chat/chat.component';
import { ConversationComponent } from './components/chat/conversation/conversation.component';
import { AutosizeChat } from "./components/chat/input.directive";
import { ContactTableComponent } from './components/contact-table/contact-table.component';
import { DialComponent } from './components/dial/dial.component';
import { DropCallAreaComponent } from './components/drop-call-area/drop-call-area.component';
import { ButtonsMenuComponent } from './components/header/buttons-menu/buttons-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { MainMenuComponent } from './components/header/main-menu/main-menu.component';
import { LoginComponent } from './components/login/login.component';
import { ManageUsersComponent } from './components/manage-users/manage-users.component';
import { ModifySupervisedAgentListComponent } from './components/modify-supervised-agent-list/modify-supervised-agent-list.component';
import { OutOfOfficeComponent } from './components/out-of-office/out-of-office.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SettingsGeneralComponent } from './components/settings/settings-general/settings-general.component';
import { AppearanceComponent } from './components/settings/settings-general/unity/appearance/appearance.component';
import { ContactSearchComponent } from './components/settings/settings-general/unity/appearance/contact-search/contact-search.component';
import { BrowserIntegrationComponent } from './components/settings/settings-general/unity/computer-phone-integration/browser-integration/browser-integration.component';
import { CallNotificationComponent } from './components/settings/settings-general/unity/computer-phone-integration/call-notification/call-notification.component';
import { DialingRuleComponent } from './components/settings/settings-general/unity/computer-phone-integration/dialing-rule/dialing-rule.component';
//agent
import { OutlookIntegrationComponent } from './components/settings/settings-general/unity/computer-phone-integration/outlook-integration/outlook-integration.component';
import { AuthenticationComponent } from './components/settings/settings-general/unity/connection/authentication/authentication.component';
import { CallCenterAuthenticationDetailsComponent } from './components/settings/settings-general/unity/connection/authentication/call-centers-authentication/call-center-authentication-details/call-center-authentication-details.component';
import { CallCentersAuthenticationComponent } from './components/settings/settings-general/unity/connection/authentication/call-centers-authentication/call-centers-authentication.component';
import { NetworkComponent } from './components/settings/settings-general/unity/connection/network/network.component';
import { LanguageComponent } from './components/settings/settings-general/unity/language/language.component';
import { StartupComponent } from './components/settings/settings-general/unity/startup/startup.component';
import { UpdateComponent } from './components/settings/settings-general/unity/update/update.component';
import { BwAnywhereCriteriaDetailsComponent } from './components/settings/settings-services//call-control/bw-anywhere/bw-anywhere-criteria-details.component';
import { BwAnywhereCriteriaComponent } from './components/settings/settings-services//call-control/bw-anywhere/bw-anywhere-criteria.component';
import { BwAnywhereLocationDetailsComponent } from './components/settings/settings-services//call-control/bw-anywhere/bw-anywhere-location-details.component';
import { BwAnywhereComponent } from './components/settings/settings-services//call-control/bw-anywhere/bw-anywhere.component';
import { CallParkRetrieveComponent } from './components/settings/settings-services/call-control/call-park-retrieve/call-park-retrieve.component';
import { CallRecordingComponent } from './components/settings/settings-services/call-control/call-recording/call-recording.component';
import { CallTransferComponent } from './components/settings/settings-services/call-control/call-transfer/call-transfer.component';
import { CallWaitingComponent } from './components/settings/settings-services/call-control/call-waiting/call-waiting.component';
import { DirectedCallPickupWithBargeInComponent } from './components/settings/settings-services/call-control/directed-call-pickup-with-barge-in/directed-call-pickup-with-barge-in.component';
import { FlexibleSeatingGuestComponent } from "./components/settings/settings-services/call-control/flexible-seating-guest/flexible-seating-guest.component";
import { HotelingGuestComponent } from './components/settings/settings-services/call-control/hoteling-guest/hoteling-guest.component';
import { RemoteOfficeComponent } from './components/settings/settings-services/call-control/remote-office/remote-office.component';
import { AnonymousCallRejectionComponent } from './components/settings/settings-services/incoming-calls/anonymous-call-rejection/anonymous-call-rejection.component';
import { AcdStateComponent } from "./components/settings/settings-services/incoming-calls/call-center/agent/acd-state/acd-state.component";
import { AgentComponent } from "./components/settings/settings-services/incoming-calls/call-center/agent/agent.component";
//agent
import { CallCenterComponent } from "./components/settings/settings-services/incoming-calls/call-center/call-center.component";
import { AgentActivityThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/agent-activity-threshold/agent-activity-threshold.component';
import { AgentThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/agent-threshold/agent-threshold.component';
import { CallcenterThresholdComponent } from './components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/callcenter-threshold/callcenter-threshold.component';
import { ColumnsComponent } from "./components/settings/settings-services/incoming-calls/call-center/statistics-service/columns/columns.component";
// phase3
import { StatisticsServiceComponent } from "./components/settings/settings-services/incoming-calls/call-center/statistics-service/statistics-service.component";
import { CallCenterMonitoringComponent } from './components/settings/settings-services/incoming-calls/call-center/supervisor/call-center-monitoring/call-center-monitoring.component';
import { CallForwardNoAnswerComponent } from './components/settings/settings-services/incoming-calls/call-forward-no-answer/call-forward-no-answer.component';
import { CallForwardingAlways } from './components/settings/settings-services/incoming-calls/call-forwarding-always/call-forwarding-always.component';
import { CallForwardingBusyComponent } from './components/settings/settings-services/incoming-calls/call-forwarding-busy/call-forwarding-busy.component';
import { AvailableInOfficeComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/available-in-office/available-in-office.component';
import { AvailableOutOfOfficeComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/available-out-of-office/available-out-of-office.component';
import { BusyComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/busy/busy.component';
import { CommPilotExpressComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/comm-pilot-express.component';
import { UnavailableComponent } from './components/settings/settings-services/incoming-calls/comm-pilot-express/unavailable/unavailable.component';
import { DoNotDisturbComponent } from './components/settings/settings-services/incoming-calls/do-not-disturb/do-not-disturb.component';
import { ExternalCallingLineIDDeliveryComponent } from './components/settings/settings-services/incoming-calls/external-calling-line-id-delivery/external-calling-line-id-delivery.component';
import { InternalCallingLineIDDeliveryComponent } from './components/settings/settings-services/incoming-calls/internal-calling-line-id-delivery/internal-calling-line-id-delivery.component';
import { SimultaneousRingComponent } from './components/settings/settings-services/incoming-calls/simultaneous-ring/simultaneous-ring.component';
import { VoicemailServiceComponent } from './components/settings/settings-services/messaging/voice-mail/voice-mail.component';
import { CallingLineIDDeliveryBlockingComponent } from './components/settings/settings-services/outgoing-calls/calling-line-id-delivery-blocking/calling-line-id-delivery-blocking.component';
import { ServiceComponent } from './components/settings/settings-services/service/service.component';
import { BwServiceResponseHandler } from './components/settings/settings-services/settings-services-response-handler';
import { SettingsServicesComponent } from './components/settings/settings-services/settings-services.component';
import { SettingsComponent } from './components/settings/settings.component';
import { StartConversationComponent } from './components/start-conversation/start-conversation.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ViewUserDetailsComponent } from './components/view-user-details/view-user-details.component';
import { VoicemailComponent } from './components/voicemail/voicemail.component';
import { DatePipe } from './pipes/date.pipe';
import { HeadlinePipe } from './pipes/headline.pipe';
import { CallLogsService } from './providers/call-logs.service';
import { ChatService } from './providers/chat.service';
import { ContextService } from './providers/context.service';
import { DataService } from './providers/dataservice.service';
import { DirectoryService } from './providers/directory.service';
import { ElectronService } from "./providers/electron.service";
import { HelperService } from './providers/helper.service';
/***/
import { ImService } from './providers/im.service';
import { SettingService } from './providers/settings/settings-general/setting.service';
import { SettingsGeneral } from './providers/settings/settings-general/settings-general.service';
import { AnonymousCallRejectionService } from './providers/settings/settings-services/anonymous-call-rejection.service';
import { BWAnywhereService } from './providers/settings/settings-services/bw-anywhere.service';
import { BwServices } from './providers/settings/settings-services/bw-services.service';
import { CallCenterMonitoringService } from './providers/settings/settings-services/call-center-monitoring.service';
import { CallForwardNoAnswerService } from './providers/settings/settings-services/call-forward-no-answer.service';
import { CallForwardningAlwaysService } from './providers/settings/settings-services/call-forwarding-always.service';
import { CallForwardingBusyService } from './providers/settings/settings-services/call-forwarding-busy.service';
import { CallRecordingService } from './providers/settings/settings-services/call-recording.service';
import { CallTransferService } from './providers/settings/settings-services/call-transfer.service';
import { CallWaitingService } from './providers/settings/settings-services/call-waiting.service';
import { CallingLineIDDeliveryBlockingService } from './providers/settings/settings-services/calling-line-id-delivery-blocking.service';
import { CommPilotExpressService } from './providers/settings/settings-services/comm-pilot-express.service';
import { DirectedCallPickUpWithBargeInService } from './providers/settings/settings-services/directed-call-pick-up-within-barge-in.service';
import { DoNotDisturbService } from './providers/settings/settings-services/do-not-disturb.service';
import { ExternalCallingLineIDDeliveryService } from './providers/settings/settings-services/external-calling-line-id-delivery.service';
import { FlexibleSeatingGuestService } from './providers/settings/settings-services/flexible-seating-guest.service';
import { HotelingGuestService } from './providers/settings/settings-services/hoteling-guest.service';
import { InternalCallingLineIDDeliveryService } from './providers/settings/settings-services/internal-calling-line-id-delivery.service';
import { RemoteOfficeService } from './providers/settings/settings-services/remote-office.service';
import { SimultaneousRingService } from './providers/settings/settings-services/simultaneous-ring.service';
import { VoiceMailService } from './providers/settings/settings-services/voice-mail.service';
import { DropdownDirective } from './shared/dropdown.directive';
import { PrimeDragulaDirective } from './shared/primeDragula.directive';
import { StartUpComponent } from './startup/startup.component';
import { ConnectionServiceModule, ConnectionServiceOptions, ConnectionServiceOptionsToken } from 'ngx-connection-service';

// c8 
import { CallBackComponent } from './components/call-back/call-back.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { HistoryComponent } from './components/history/history.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { AttachFileComponent } from './components/attach-file/attach-file.component';
import { UploadProgressComponent } from './components/upload-progress/upload-progress.component';
import { WebChatComponent } from './components/web-chat/web-chat.component';
import { SmsComponent } from './components/sms/sms.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { WebChatConversationComponent } from './components/web-chat-conversation/web-chat-conversation.component';
import { CannedRepliesComponent } from './components/canned-replies/canned-replies.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { TwitterComponent } from './components/twitter/twitter.component';
//import { MessagesAreaComponent } from './components/messages-area-deprecated/messages-area.component';
import { MessagesService } from './providers/messages.service';
import { EmojisComponent } from './components/emojis/emojis.component';
import { AddCrmEntryComponent } from './components/add-crm-entry/add-crm-entry.component';
import { StarsRatingComponent } from './components/stars-rating/stars-rating.component';

// c8 email 
import { EmailComponent } from './components/email/email.component';
import { EditorModule/*, TINYMCE_SCRIPT_SRC */ } from '@tinymce/tinymce-angular';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.5
};


@NgModule({
  declarations: [
    AppComponent,
    StartUpComponent,
    HeaderComponent,
    PrimeDragulaDirective,
    DropCallAreaComponent,
    MainMenuComponent,
    ButtonsMenuComponent,
    ContactTableComponent,
    CallLogsComponent,
    VoicemailComponent,
    DropdownDirective,
    StatisticsComponent,
    ChatComponent,
    ConversationComponent,
    AutosizeChat,
    HeadlinePipe,
    DatePipe,
    SettingsComponent,
    SettingsServicesComponent,
    SettingsGeneralComponent,
    AppearanceComponent,
    ReportsComponent,
    DialComponent,
    ManageUsersComponent,
    StartConversationComponent,
    AboutComponent,
    OutOfOfficeComponent,
    AddPersonalEntryComponent,
    CalendarComponent,
    ViewUserDetailsComponent,
    AvailableInOfficeComponent,
    AvailableOutOfOfficeComponent,
    BusyComponent,
    UnavailableComponent,
    CallForwardingAlways,
    CallForwardingBusyComponent,
    CallForwardNoAnswerComponent,
    AnonymousCallRejectionComponent,
    DoNotDisturbComponent,
    SimultaneousRingComponent,
    CommPilotExpressComponent,
    ExternalCallingLineIDDeliveryComponent,
    InternalCallingLineIDDeliveryComponent,
    CallingLineIDDeliveryBlockingComponent,
    CallWaitingComponent,
    RemoteOfficeComponent,
    HotelingGuestComponent,
    CallRecordingComponent,
    VoicemailServiceComponent,
    BwAnywhereComponent,
    BwAnywhereLocationDetailsComponent,
    BwAnywhereCriteriaComponent,
    BwAnywhereCriteriaDetailsComponent,
    DialingRuleComponent,
    CallNotificationComponent,
    StartupComponent,
    UpdateComponent,
    LanguageComponent,
    NetworkComponent,
    AuthenticationComponent,
    ServiceComponent,
    LoginComponent,
    FlexibleSeatingGuestComponent,
    CallCenterComponent,
    AgentComponent,
    AcdStateComponent,
    StatisticsServiceComponent,
    ColumnsComponent,
    AgentThresholdComponent,
    CallcenterThresholdComponent,
    CallCentersAuthenticationComponent,
    CallCenterAuthenticationDetailsComponent,
    OutlookIntegrationComponent,
    BrowserIntegrationComponent,
    CallTransferComponent,
    DirectedCallPickupWithBargeInComponent,
    CallParkRetrieveComponent,
    ContactSearchComponent,
    AgentActivityTableComponent,
    SupervisorComponent,
    AgentActivityThresholdComponent,
    ModifySupervisedAgentListComponent,
    CallCenterMonitoringComponent,
    //c8
    CallBackComponent,
    HistoryComponent,
    ScheduleComponent,
    AttachFileComponent,
    AttachmentComponent,
    UploadProgressComponent,
    WebChatComponent,
    SmsComponent,
    WebChatConversationComponent,
    CannedRepliesComponent,
    ResourcesComponent,
    TwitterComponent,
    EmailComponent,
    EmojisComponent,
    AddCrmEntryComponent,
    StarsRatingComponent,
    UserDetailsComponent,

  ],
  imports: [
    BrowserModule,
    // BrowserAnimationsModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    ContextMenuModule,
    CheckboxModule,
    RadioButtonModule,
    MenuModule,
    ProgressSpinnerModule,
    DialogModule,
    PanelMenuModule,
    TieredMenuModule,
    DropdownModule,
    CalendarModule,
    TabViewModule,
    TreeModule,
    InputSwitchModule,
    SplitButtonModule,
    ChipsModule,
    DragulaModule,
    PerfectScrollbarModule,
    AppRoutingModule,
    TooltipModule,
    SpinnerModule,
    AccordionModule,
    TableModule,
    ConnectionServiceModule,
    EditorModule, //c8
    ProgressBarModule
  ],
  providers: [
    /*{ provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' },*/
    {
      provide: ConnectionServiceOptionsToken,
      useValue: <ConnectionServiceOptions>{
        enableHeartbeat: true,
        heartbeatUrl: '/assets/img/unity1.ico',
        requestMethod: 'get',
        heartbeatInterval: 15000,
        heartbeatRetryInterval: 15000
      }
    }, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, DataService, DragulaService, HelperService, SettingService, ChatService, DirectoryService, ElectronService, CallLogsService,

    BwServices, CallForwardningAlwaysService, DirectedCallPickUpWithBargeInService, BwServiceResponseHandler, CallForwardingBusyService, CallForwardNoAnswerService, AnonymousCallRejectionService,
    DoNotDisturbService, SimultaneousRingService, ExternalCallingLineIDDeliveryService, InternalCallingLineIDDeliveryService, InternalCallingLineIDDeliveryService,
    CallingLineIDDeliveryBlockingService, CallWaitingService, RemoteOfficeService, HotelingGuestService, CallRecordingService, VoiceMailService, CommPilotExpressService,
    BWAnywhereService, SettingsGeneral, CallCenterMonitoringService, ContextService, ImService, FlexibleSeatingGuestService, CallTransferService,
    // C8
    MessagesService
  ],
  bootstrap: [StartUpComponent]
})
export class AppModule { }
