<div class="chat ui-widget" id="chat-panel" [ngStyle]="{'height': chatHeight+'px'}">
  <div class="container" id="scrollChat" [perfectScrollbar]>
    <ul class="{{(contextService.conversations.length > 7) && 'conversations-count-more' || ''}} conversations conversations-count-{{contextService.conversations.length}}"
      id="chat-conversations" [primeDragula]="chatBag" [dragulaModel]="contextService.conversations" [dragulaOptions]="{ copy: false, copySortSource: true, bag: 'chatBag', initAfterView: true }">
      <li *ngFor="let conversation of contextService.conversations;let i = index;" class="conversation-container" [ngClass]="{'active': isActive(conversation.id)}">
        <app-conversation class="app-conversation " [conversationObject]="conversation" [primeDragula]="conversationBag" [dragulaModel]="conversation.people"
          [dragulaOptions]="{ copy: true, bag: 'conversationBag', initAfterView: true }"></app-conversation>
      </li>
    </ul>
  </div>
  <div id="new-chat" [primeDragula]="newChatBag" [dragulaOptions]="{ copy: true, bag: 'newChatBag', initAfterView: true }">
    <span class="on">{{newUser}}</span>
    <span class="off">{{offlineUser}}</span>
  </div>
  <a class="disabled-chat" [primeDragula]="externalBag">
    <span>You can't start a chat
      <br>with an external contact</span>
  </a>
</div>