<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label">{{statistic}}</label>
  <p-dropdown [options]="this.contextService.agentActivityColumnList"
    [disabled]="this.settingsGeneral.disableAgentActivityColumnList" name="dropdown" (click)="showThreshold()"
    [(ngModel)]="this.contextService.agentActivityStatisticsToDisplay" [style]="{'width':'100%'}"></p-dropdown>
</div>
<div class="row">
  <label for="" class="label">{{heading}}</label>
  <input type="text" name="input-text" [(ngModel)]="this.contextService.columnHeading">
</div>
<div class="row" *ngIf="this.settingsGeneral.isThresholdShows===true">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.contextService.setThreshold"
    label={{setThreshold}} binary="true" (click)="showThresholdTable()"></p-checkbox>
</div>
<div class="row"
  *ngIf="this.contextService.setThreshold===true && this.settingsGeneral.showElements=== false && this.settingsGeneral.showACDStateTable===true">
  <div class="box">

    <p-table [value]="this.contextService.agentActivityThresholdList" [resizableColumns]="false" selectionMode="single"
      [(selection)]="this.settingsGeneral.selectedCallCenterColumn" dataKey="id" columnResizeMode="fit"
      [responsive]="true" id="cc-threshold-table">
      <ng-template pTemplate="header">
        <th>{{this.acdStateText}}</th>
        <th> <span class="th-green"></span></th>
        <th><span class="th-yellow"></span></th>
        <th><span class="th-red"></span></th>
        <th><span class="th-grey"></span></th>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr (dblclick)="dbACDStateTable(rowData)" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData">
          <td>
            {{rowData.name}}
          </td>
          <td style="text-align:center">
            {{rowData.green}}
          </td>
          <td style="text-align:center">
            {{rowData.yellow}}
          </td>
          <td style="text-align:center">
            {{rowData.red}}
          </td>
          <td style="text-align:center">
            {{rowData.black}}
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>

<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.settingsGeneral.highlightNonZeroValues"
    label={{highlights}} binary="true"></p-checkbox>
</div>
<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.settingsGeneral.increasingThresholdValues"
    label={{increasingThreshold}} binary="true"></p-checkbox>
</div>
<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.green}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.green}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.greenThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.yellow}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.yellow}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.yellowThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.red}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.red}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.redThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.settingsGeneral.showElements=== true && this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.black}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.black}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.blackThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>