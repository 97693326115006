import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent implements OnInit {

  SettingsStartupDescription: string = "";
  SettingsStartupStartupApplication: string = "";
  SettingsCtiStartupSelectinstances: string = "";
  SettingsCtiStartupSelectminimizedstate: string = "";
  SettingsStartupAutoStartOnOsBoot: string = "";

  unityProducts: any[];
  constructor(public settingsGeneral: SettingsGeneral) {
    this.unityProducts = [];
    this.unityProducts.push({ label: 'Unity Desktop', value:1 });
    this.unityProducts.push({ label: 'Unity Call Center Agent', value: 2 });

  }

  ngOnInit() {

    this.SettingsStartupDescription = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Startup.Description');
    this.SettingsStartupStartupApplication = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Startup.StartupApplication');
    this.SettingsCtiStartupSelectinstances = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Startup.Select.instances');
    this.SettingsCtiStartupSelectminimizedstate = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.Startup.Select.minimized.state');
    this.SettingsStartupAutoStartOnOsBoot = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Startup.AutoStartOnOsBoot');
    this.settingsGeneral.settingsChanged=true;
  }

}
