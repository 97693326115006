import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsGeneral } from '../../providers/settings/settings-general/settings-general.service';
import { ContextService } from './../../providers/context.service';
import { HelperService } from './../../providers/helper.service';
import { SettingService } from './../../providers/settings/settings-general/setting.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsComponent implements OnInit {
  settingsVis: boolean = false;
  restartVis: boolean = false;
  broadworksError: boolean = false;
  error: string = "";

  services: string = "";
  tabSetting: string = "";
  cancel: string = "";
  save: string = "";
  restartRequired: string = "";
  miscYes: string = "";
  miscNo: string = "";
  callButtonShowingOK: string = "";
  confirm: string = "";
  deleteAlert: string = "";


  constructor(public cd: ChangeDetectorRef, private settings: SettingService, private router: Router,
    private helper: HelperService, public settingsGeneral: SettingsGeneral, private contextService: ContextService) {
    this.cd.detach();
  }

  ngOnInit() {

    this.confirm = this.contextService.getLanguageTagByKey('Settings.Update.PerformManualUpdateNow.Confirm');
    this.services = this.contextService.getLanguageTagByKey('TabShowing.Services');
    this.tabSetting = this.contextService.getLanguageTagByKey('TabShowing.Settings');
    this.cancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
    this.save = this.contextService.getLanguageTagByKey('Menu.Context.Save');
    this.restartRequired = this.contextService.getLanguageTagByKey('Prompt.RestartRequired');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.deleteAlert = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Confirm');

    // Settings observer (open Settings modal)
    this.helper.setVisible.subscribe(val => {
      this.cd.reattach();
      this.settingsVis = val;
      this.contextService.route = "";
      this.settings.populateService();
      this.settingsGeneral.loadStatsToTable();
    });
    this.contextService.loadSettingsToUI.next();
    // this.contextService.broadworksErrorModalVis.subscribe(val => {
    //   let url = window.location.href;
    //   let url1 = "https://desktop.unityclient.com/";
    //   let url2 = "https://desktop.unityclient.com";
    //   let url3 = "https://agent.unityclient.com/";
    //   let url4 = "https://agent.unityclient.com";
    //   if (url !== url1 || url !== url2) {
    //     this.contextService.log('not in homepage'+' '+url);
    //     this.broadworksError = true;
    //     this.error = val;
    //   }
    // });
    if (this.contextService.objSettings.defaultPasswordExist) {
      this.settingsGeneral.defaultPasswordToCheck = "********";
      this.settingsGeneral.defaultPassword = "********";
    }
  }

  onSubmit(event, form: HTMLFormElement) {

    if (this.settings.saveBWA()) {
      if (this.settings.validate() && this.settingsGeneral.validate()) {
        if (this.settings.save()) {
          if (this.settingsGeneral.save()) {
            if (this.settingsGeneral.restartIM) {
              if ((this.settingsGeneral.imServerAddress !== "") && (this.settingsGeneral.imServerPort !== "")) {
                this.settingsGeneral.contextService.websocketService.iMRegisterAction(this.settingsGeneral.contextService.objLoggedUserDetails.getgroupId(), this.settingsGeneral.imServerAddress, this.settingsGeneral.imServerPort);
              }
            }
            if (this.settingsGeneral.restart) {
              this.restartVis = true;
            }
            else {
              this.settingsVis = false;
            }
            this.helper.closeModal();
          }
        }
      }
    }
  }

  closeSettings() {
    //agentp3
    // this.settingsGeneral.cancel('closecancel');
    this.settings.changeBWPage();
    if (!this.contextService.checkBWAnywhere) {
      if (this.settings.cancel() && this.settingsGeneral.cancel('closecancel')) {
        this.settingsVis = false;
        this.helper.closeModal();
        setTimeout(() => { this.cd.detach(); });
      }
    } else {
      this.settings.navigatePage();
    }


  }
  restartCancel() {
    this.restartVis = false
    this.settingsGeneral.cancel('restartcancel');
    this.router.navigate(['/settings-general']);
  }
}
