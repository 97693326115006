  <div class="loader" *ngIf="contextService.loadingFlag">
    <div class="line-scale-pulse-out"><div></div><div></div><div></div><div></div><div></div></div>
    <div class="loadtext">{{contextService.loadingState}}</div>
  
  </div>

  <app-root *ngIf="contextService.loadingFlag===false"></app-root>

  <p-dialog [(visible)]="confirmVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: confirmVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{loginFailed}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="authenticationFailed()">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="errorVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: errorVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{errorMessage}}</p>
      <p>{{exceptionMessage}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="errorVis=false">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="this.contextService.ctiErrorVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.contextService.ctiErrorVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <!-- <p>Unity could not get any response from the cti server. Please contact your service provider for assistance.</p> -->
      <p *ngIf="this.contextService.objLoggedUserDetails != null && this.contextService.objLoggedUserDetails.getWebclientUseXSI() === false">Unity could not get any CTI response from {{this.contextService.addChannelServerAddress}} on {{this.contextService.addChannelServerPort}}. Please contact your service provider for assistance.</p>
      <p *ngIf="this.contextService.objLoggedUserDetails != null && this.contextService.objLoggedUserDetails.getWebclientUseXSI() === true">Unity could not get any XSI response from {{this.contextService.objLoggedUserDetails.getreportingUrl()}}. Please contact your service provider for assistance.</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="ctiErrorButtonClick()">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="this.contextService.noRegisterResponseVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.contextService.noRegisterResponseVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <!-- <p>Unity could not get any response from the cti server. Please contact your service provider for assistance.</p> -->
      <p>Unity could not get any response from the server. Please contact your service provider for assistance.</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="noRegisterResponseButtonClick()">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="this.contextService.cticonnectiondroppedvis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.contextService.cticonnectiondroppedvis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{this.connectionDroppedAlert}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="restart()">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="closeVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: closeVis}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{promptShutdown}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="closeUnity()">{{miscYes}}</button>
        <button type="button" class="btn-white" (click)="closeVis = false">{{miscNo}}</button>
        
      </div>
    </div>
  </p-dialog>

  <p-dialog [(visible)]="serverAddressError" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: serverAddressError}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{promptHostName}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="serverError()">{{callButtonShowing}}</button>
      </div>
    </div>
  </p-dialog>
  <p-dialog [(visible)]="hostDeviceVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: hostDeviceVis}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false" [resizable]="false">
    <div class="modal-content">
      <p>{{this.hostDeviceAlert}}</p>
      <div>
        <button type="button" class="btn-blue-light" (click)="deactivateHostDevice()">{{miscYes}}</button>
        <button type="button" class="btn-white" (click)="close()">{{miscNo}}</button>
        
      </div>
    </div>
  </p-dialog>

  <p-dialog [resizable]="false" [(visible)]="this.signOut" [closable]="false" [style]="{'width':'330px'}" [ngClass]="{visible: this.signOut}"
  class="dialog-window-signout buttons-1" dismissableMask="true" showHeader="false">
  <div class="modal-content" style="text-align: center;">
    <h4>{{this.signOutAlert}}</h4>
    <button type="button" class="btn-green" (click)="reload()">{{restartText}}</button>
  </div>
</p-dialog>

<p-dialog [(visible)]="this.ociErrorVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.ociErrorVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" [resizable]="false">
  <div class="modal-content">
    <p>Unity could not get any OCI response from {{this.contextService.objSettings.network_BWServerAddress}} on {{this.voipServerPort}}. Please contact your service provider for assistance.</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.ociErrorVis=false">{{callButtonShowing}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="this.noNetworkVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.noNetworkVis}" class="dialog-window1" dismissableMask="true" showHeader="false" [resizable]="false">
  <div class="modal-content">
      <p>{{noInternetAlert}}</p>
      <div style="padding-left: 40%">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="5" fill="#ffffff" animationDuration=".8s"></p-progressSpinner>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="this.contextService.forcedForwardingVisible" [ngClass]="{visible: this.contextService.forcedForwardingVisible}" [closable]="false" [style]="{'width':'380px'}" class="small-dialog out-of-office-dialog" dismissableMask="true" showHeader="true" [resizable]="false">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{forcedForwardingLabel}}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
    </div> 
  </p-header>  
  <div class="modal-content">   
    <form (ngSubmit)="onSubmit()" [formGroup]="form" #rfRef="ngForm" id="apdForm">
    <div class="row"> 

      <div class="row">
        <label for="" class="label">{{miscPhone}}</label>
        <input type="text" name="phone" [(ngModel)]="this.forcedForwardingNum" formControlName="phone" placeholder="" min="2" (keypress)=numValidate($event)>
      </div> 
    </div>
    </form>
  </div>
  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div class="save-settings">
        <button type="button" form="apdForm" (click)="onSubmit()" class="btn-blue-light" >{{btnOk}}</button>
        <a (click)="closeModal()">{{btnCancel}}</a>
      </div>
    </div>
  </p-footer>
  
</p-dialog>

  <app-login *ngIf="this.contextService.loadLoginPage===true"></app-login>


  