import { ContextService } from "../../../providers/context.service";
import { Threshold } from "./threshold";
export class StatToDisplay {
    public id: number;
    public queueName: string;
    public queueState: string;
    public queueID: string;
    public phone: string;
    private ACDState: string;
    public icon: string
    //agents statistics
    private userTotalCalls: number;
    private userAnsweredCalls: number;
    private userMissedCalls: number;
    private userTotalTalkTime: string;
    private userAverageTalkTime: string;
    private userTotalStaffedTime: string;
    private userCallbacksAnswered: number;

    public userTotalCallsColor: string;
    public userAnsweredCallsColor: string;
    public userMissedCallsColor: string;
    public userTotalTalkTimeColor: string;
    public userAverageTalkTimeColor: string;
    public userTotalStaffedTimeColor: string;
    public userCallbacksAnsweredColor: string;

    //queue statistics
    private queueTotalCalls: number;
    private queueCallsAnswered: number;
    private queueCallsAnsweredPercentage: string;
    private queueTotalMissedCalls: number;
    private queueTotalMissedCallsPercentage: string;
    private queueCallsInQueue: number;
    private queueLongestWaitTime: string;
    private queueAverageWaitTime: string;
    private queueAverageTalkTime: string;
    private queueBusyOverflows: number;
    private queueBusyOverflowsPercentage: string;
    private queueCallsAbandoned: number;
    private queueCallsAbandonedPercentage: string;
    private queueCallsTransfered: number;
    private queueCallsTransferedPercentage: string;
    private queueCallsTimedOut: number;
    private queueCallsTimedOutPercentage: string;
    private queueOverflowedTotal: number;
    private queueOverflowedTotalPercentage: string;
    private queueAverageAgentsTalking: string;
    private queueAverageAgentsStaffed: string;
    private queueAverageAbandonedTime: string;
    private queueTotalTalkTime: string;
    //supervisor
    private queueStaffedRatio: string;
    private queueTotalReceivedCalls: number;

    private staffedAgents: number;
    private totalAgents: number;

    contextService: ContextService;

    public queueTotalCallsColor: string;
    public queueCallsAnsweredColor: string;
    public queueCallsAnsweredPercentageColor: string;
    public queueTotalMissedCallsColor: string;
    public queueTotalMissedCallsPercentageColor: string;
    public queueCallsInQueueColor: string;
    public queueLongestWaitTimeColor: string;
    public queueAverageWaitTimeColor: string;
    public queueAverageTalkTimeColor: string;
    public queueBusyOverflowsColor: string;
    public queueBusyOverflowsPercentageColor: string;
    public queueCallsAbandonedColor: string;
    public queueCallsAbandonedPercentageColor: string;
    public queueCallsTransferedColor: string;
    public queueCallsTransferedPercentageColor: string;
    public queueCallsTimedOutColor: string;
    public queueCallsTimedOutPercentageColor: string;
    public queueOverflowedTotalColor: string;
    public queueOverflowedTotalPercentageColor: string;
    public queueAverageAgentsTalkingColor: string;
    public queueAverageAgentsStaffedColor: string;
    public queueAverageAbandonedTimeColor: string
    public queueTotalTalkTimeColor: string;
    public queueStaffedRatioColor: string;
    public queueTotalReceivedCallsColor: string;


    public longestWaitTimeCollection = [];
    public isHighlightCollection = [];

    public isDisplayInPersonalWallboard: boolean;
    public lastStaffedDateTime: Date = null;
    initialUserTotalStaffedTime: number = 0;

    constructor(queueID: string, queueName: string, queueState: string, id: number, phone: string, contextService: ContextService) {
        this.contextService = contextService;
        this.queueID = queueID;
        this.queueName = queueName;
        this.queueState = queueState;
        // if (this.queueState == "join") {
        //     this.lastStaffedDateTime = new Date();
        // }
        this.phone = phone;
        this.id = id;
        this.userTotalCalls = 0;
        this.userAnsweredCalls = 0;
        this.userMissedCalls = 0;
        this.userTotalTalkTime = "00:00:00";
        this.userAverageTalkTime = "00:00:00";
        this.userTotalStaffedTime = "00:00:00";
        this.userCallbacksAnswered = 0;

        this.queueTotalCalls = 0;
        this.queueCallsAnswered = 0;
        this.queueCallsAnsweredPercentage = "0.00";
        this.queueTotalMissedCalls = 0;
        this.queueTotalMissedCallsPercentage = "0.00";
        this.queueCallsInQueue = 0;
        this.queueLongestWaitTime = "00:00:00";
        this.queueAverageWaitTime = "00:00:00";
        this.queueAverageTalkTime = "00:00:00";
        this.queueBusyOverflows = 0;
        this.queueBusyOverflowsPercentage = "0.00";
        this.queueCallsAbandoned = 0;
        this.queueCallsAbandonedPercentage = "0.00";
        this.queueCallsTransfered = 0;
        this.queueCallsTransferedPercentage = "0.00";
        this.queueCallsTimedOut = 0;
        this.queueCallsTimedOutPercentage = "0.00";
        this.queueOverflowedTotal = 0;
        this.queueOverflowedTotalPercentage = "0.00";
        this.queueAverageAgentsTalking = "0";
        this.queueAverageAgentsStaffed = "0";
        this.queueAverageAbandonedTime = "00:00:00";
        this.queueTotalTalkTime = "00:00:00";
        this.queueStaffedRatio = "";
        this.queueTotalReceivedCalls = 0;
        this.staffedAgents = 0;
        this.totalAgents = 0;
        this.isDisplayInPersonalWallboard = true;
        this.icon = "leave";
    }

    public setqueueState(queueState: boolean) {
        if (queueState) {
            this.queueState = "join";
            this.icon = "join";
            if (this.ACDState != "Sign-Out") {
                if (this.lastStaffedDateTime == null) {
                    this.lastStaffedDateTime = new Date();
                }
            }
        } else {
            this.queueState = "leave";
            this.icon = "leave";
            if (this.lastStaffedDateTime != null) {
                let staffedTime = Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);
                this.initialUserTotalStaffedTime += staffedTime;
                this.lastStaffedDateTime = null;
            }
        }
    }
    public setACDState(ACDState: string) {
        this.ACDState = ACDState;
        if (this.ACDState != "Sign-Out") {
            if (this.queueState == "join") {
                if (this.lastStaffedDateTime == null) {
                    this.lastStaffedDateTime = new Date();
                }
            }
        } else {
            if (this.lastStaffedDateTime != null) {
                let staffedTime = Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);
                this.initialUserTotalStaffedTime += staffedTime;
                this.lastStaffedDateTime = null;
            }
        }
    }
    public setInitialUserTotalStaffedTime(initialUserTotalStaffedTime) {
        if (this.lastStaffedDateTime != null) {
            this.lastStaffedDateTime = new Date();
        }
        this.initialUserTotalStaffedTime = initialUserTotalStaffedTime;
    }

    /* #region get and set for agent statistics */
    public setUserTotalCalls(userTotalCalls) {
        this.userTotalCalls = this.setValues(this.userTotalCalls, userTotalCalls);
        this.userTotalCallsColor = this.calculateThresholdColor(this.getUserThreshold("userTotalCalls"), this.userTotalCalls);
    }
    public getUserTotalCalls(): number {
        return this.userTotalCalls;
    }
    public setUserAnsweredCalls(userAnsweredCalls) {
        this.userAnsweredCalls = this.setValues(this.userAnsweredCalls, userAnsweredCalls);
        this.userAnsweredCallsColor = this.calculateThresholdColor(this.getUserThreshold("userAnsweredCalls"), this.userAnsweredCalls);
    }
    public getUserAnsweredCalls(): number {
        return this.userAnsweredCalls;
    }
    public setUserMissedCalls(userMissedCalls) {
        this.userMissedCalls = this.setValues(this.userMissedCalls, userMissedCalls);
        this.userMissedCallsColor = this.calculateThresholdColor(this.getUserThreshold("userMissedCalls"), this.userMissedCalls);
    }
    public getUserMissedCalls(): number {
        return this.userMissedCalls;
    }
    public setUserTotalTalkTime(userTotalTalkTime) {
        this.userTotalTalkTime = this.processSecondsToTime(userTotalTalkTime);
        this.userTotalTalkTimeColor = this.calculateThresholdColor(this.getUserThreshold("userTotalTalkTime"), this.processTimeToSeconds(this.userTotalTalkTime));
    }
    public getUserTotalTalkTime(): number {
        return this.processTimeToSeconds(this.userTotalTalkTime);
    }

    public setUserAverageTalkTime(userAverageTalkTime) {
        this.userAverageTalkTime = this.processSecondsToTime(userAverageTalkTime);
        this.userAverageTalkTimeColor = this.calculateThresholdColor(this.getUserThreshold("userAverageTalkTime"), this.processTimeToSeconds(this.userAverageTalkTime));
    }
    public getUserAverageTalkTime(): number {
        return this.processTimeToSeconds(this.userAverageTalkTime);
    }
    public setUserTotalStaffedTime(userTotalStaffedTime) {
        this.userTotalStaffedTime = this.processSecondsToTime(userTotalStaffedTime);
        this.userTotalStaffedTimeColor = this.calculateThresholdColor(this.getUserThreshold("userTotalStaffedTime"), this.processTimeToSeconds(this.userTotalStaffedTime));
    }
    public getUserTotalStaffedTime(): number {
        if (this.lastStaffedDateTime != null) {
            return this.initialUserTotalStaffedTime + Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);

        } else {
            return this.initialUserTotalStaffedTime;
        }
        // return this.processTimeToSeconds(this.userTotalStaffedTime);
    }
    public setUserCallbacksAnswered(userCallbacksAnswered) {
        this.userCallbacksAnswered = userCallbacksAnswered;
        this.userCallbacksAnsweredColor = this.calculateThresholdColor(this.getUserThreshold("userCallbacksAnswered"), this.userCallbacksAnswered);
    }
    public getUserCallbacksAnswered(): number {
        return this.userCallbacksAnswered;
    }
    /* #endregion */

    /* #region get and set for queue statistics */
    public setQueueTotalCalls(queueTotalCalls) {
        this.queueTotalCalls = this.setValues(this.queueTotalCalls, queueTotalCalls);
        this.queueTotalCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalCalls"), this.queueTotalCalls);
        this.calculatePercentageValues();
    }
    public getQueueTotalCalls(): number {
        return this.queueTotalCalls;
    }
    public setQueueCallsAnswered(queueCallsAnswered) {
        this.queueCallsAnswered = this.setValues(this.queueCallsAnswered, queueCallsAnswered);
        this.queueCallsAnsweredColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAnswered"), this.queueCallsAnswered);
        this.setqueueTotalReceivedCalls();
    }
    public getQueueCallsAnswered(): number {
        return this.queueCallsAnswered;
    }
    public setQueueCallsAnsweredPercentage(queueCallsAnsweredPercentage) {
        // this.queueCallsAnsweredPercentage = parseFloat(queueCallsAnsweredPercentage.toFixed(2));
        if (!isNaN(queueCallsAnsweredPercentage)) {
            this.queueCallsAnsweredPercentage = queueCallsAnsweredPercentage.toFixed(2);
            this.queueCallsAnsweredPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAnsweredPercentage"), this.queueCallsAnsweredPercentage);
        }
    }
    public getQueueCallsAnsweredPercentage(): number {
        return parseFloat(this.queueCallsAnsweredPercentage);
    }
    public setQueueTotalMissedCalls(queueTotalMissedCalls) {
        // if (queueTotalMissedCalls) {
        this.queueTotalMissedCalls = this.setValues(this.queueTotalMissedCalls, queueTotalMissedCalls);
        this.queueTotalMissedCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalMissedCalls"), this.queueTotalMissedCalls);
        // }
    }
    public getQueueTotalMissedCalls(): number {
        return this.queueTotalMissedCalls;
    }
    public setQueueTotalMissedCallsPercentage(queueTotalMissedCallsPercentage) {
        if (!isNaN(queueTotalMissedCallsPercentage)) {
            this.queueTotalMissedCallsPercentage = queueTotalMissedCallsPercentage.toFixed(2);
            this.queueTotalMissedCallsPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalMissedCallsPercentage"), this.queueTotalMissedCallsPercentage);
        }
    }
    public getQueueTotalMissedCallsPercentage(): number {
        return parseFloat(this.queueTotalMissedCallsPercentage);
    }
    public setQueueCallsInQueue(queueCallsInQueue) {
        this.queueCallsInQueue = this.setValues(this.queueCallsInQueue, queueCallsInQueue);
        this.queueCallsInQueueColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsInQueue"), this.queueCallsInQueue);
    }
    public getQueueCallsInQueue(): number {
        return this.queueCallsInQueue;
    }
    public setQueueLongestWaitTime(queueLongestWaitTime) {
        this.queueLongestWaitTime = this.processSecondsToTime(queueLongestWaitTime);
        this.queueLongestWaitTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueLongestWaitTime"), this.processTimeToSeconds(this.queueLongestWaitTime));
    }
    public getQueueLongestWaitTime(): number {
        return this.processTimeToSeconds(this.queueLongestWaitTime);
    }
    public setQueueAverageWaitTime(queueAverageWaitTime) {
        this.queueAverageWaitTime = this.processSecondsToTime(queueAverageWaitTime);
        this.queueAverageWaitTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageWaitTime"), this.processTimeToSeconds(this.queueAverageWaitTime));
    }
    public getQueueAverageWaitTime(): number {
        return this.processTimeToSeconds(this.queueAverageWaitTime);
    }
    public setQueueAverageTalkTime(queueAverageTalkTime) {
        this.queueAverageTalkTime = this.processSecondsToTime(queueAverageTalkTime);
        this.queueAverageTalkTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageTalkTime"), this.processTimeToSeconds(this.queueAverageTalkTime));
    }
    public getQueueAverageTalkTime(): number {
        return this.processTimeToSeconds(this.queueAverageTalkTime);
    }
    public setQueueBusyOverflows(queueBusyOverflows) {
        this.queueBusyOverflows = this.setValues(this.queueBusyOverflows, queueBusyOverflows);
        this.queueBusyOverflowsColor = this.calculateThresholdColor(this.getQueueThreshold("queueBusyOverflows"), this.queueBusyOverflows);
    }
    public getQueueBusyOverflows(): number {
        return this.queueBusyOverflows;
    }
    public setQueueBusyOverflowsPercentage(queueBusyOverflowsPercentage) {
        if (!isNaN(queueBusyOverflowsPercentage)) {
            this.queueBusyOverflowsPercentage = queueBusyOverflowsPercentage.toFixed(2);
            this.queueBusyOverflowsPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueBusyOverflowsPercentage"), this.queueBusyOverflowsPercentage);
        }
    }
    public getQueueBusyOverflowsPercentage(): number {
        return parseFloat(this.queueBusyOverflowsPercentage);
    }

    public setQueueCallsAbandoned(queueCallsAbandoned) {
        this.queueCallsAbandoned = this.setValues(this.queueCallsAbandoned, queueCallsAbandoned);
        this.queueCallsAbandonedColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAbandoned"), this.queueCallsAbandoned);
        this.setqueueTotalReceivedCalls();
    }
    public getQueueCallsAbandoned(): number {
        return this.queueCallsAbandoned;
    }
    public setQueueCallsAbandonedPercentage(queueCallsAbandonedPercentage) {
        if (!isNaN(queueCallsAbandonedPercentage)) {
            this.queueCallsAbandonedPercentage = queueCallsAbandonedPercentage.toFixed(2);
            this.queueCallsAbandonedPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAbandonedPercentage"), this.queueCallsAbandonedPercentage);
        }
    }
    public getQueueCallsAbandonedPercentage(): number {
        return parseFloat(this.queueCallsAbandonedPercentage);
    }
    public setQueueCallsTransfered(queueCallsTransfered) {
        this.queueCallsTransfered = this.setValues(this.queueCallsTransfered, queueCallsTransfered);
        this.queueCallsTransferedColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTransfered"), this.queueCallsTransfered);
    }
    public getQueueCallsTransfered(): number {
        return this.queueCallsTransfered;
    }
    public setQueueCallsTransferedPercentage(queueCallsTransferedPercentage) {
        if (!isNaN(queueCallsTransferedPercentage)) {
            this.queueCallsTransferedPercentage = queueCallsTransferedPercentage.toFixed(2);
            this.queueCallsTransferedPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTransferedPercentage"), this.queueCallsTransferedPercentage);
        }
    }
    public getQueueCallsTransferedPercentage(): number {
        return parseFloat(this.queueCallsTransferedPercentage);
    }

    public setQueueCallsTimedOut(queueCallsTimedOut) {
        this.queueCallsTimedOut = this.setValues(this.queueCallsTimedOut, queueCallsTimedOut);
        this.queueCallsTimedOutColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTimedOut"), this.queueCallsTimedOut);
        this.setqueueTotalReceivedCalls();
    }
    public getQueueCallsTimedOut(): number {
        return this.queueCallsTimedOut;
    }
    public setQueueCallsTimedOutPercentage(queueCallsTimedOutPercentage) {
        if (!isNaN(queueCallsTimedOutPercentage)) {
            this.queueCallsTimedOutPercentage = queueCallsTimedOutPercentage.toFixed(2);
            this.queueCallsTimedOutPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTimedOutPercentage"), this.queueCallsTimedOutPercentage);
        }
    }
    public getQueueCallsTimedOutPercentage(): number {
        return parseFloat(this.queueCallsTimedOutPercentage);
    }
    public setQueueOverflowedTotal(queueOverflowedTotal) {
        this.queueOverflowedTotal = this.setValues(this.queueOverflowedTotal, queueOverflowedTotal);
        this.queueOverflowedTotalColor = this.calculateThresholdColor(this.getQueueThreshold("queueOverflowedTotal"), this.queueOverflowedTotal);
    }
    public getQueueOverflowedTotal(): number {
        return this.queueOverflowedTotal;
    }
    public setQueueOverflowedTotalPercentage(queueOverflowedTotalPercentage) {
        if (!isNaN(queueOverflowedTotalPercentage)) {
            this.queueOverflowedTotalPercentage = queueOverflowedTotalPercentage.toFixed(2);
            this.queueOverflowedTotalPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueOverflowedTotalPercentage"), this.queueOverflowedTotalPercentage);
        }
    }
    public getQueueOverflowedTotalPercentage(): number {
        return parseFloat(this.queueOverflowedTotalPercentage);
    }
    public setQueueAverageAgentsTalking(queueAverageAgentsTalking) {
        this.queueAverageAgentsTalking = queueAverageAgentsTalking.toFixed(2);
        this.queueAverageAgentsTalkingColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAgentsTalking"), this.queueAverageAgentsTalking);
    }
    public getQueueAverageAgentsTalking(): number {
        return parseFloat(this.queueAverageAgentsTalking);
    }
    public setQueueAverageAgentsStaffed(queueAverageAgentsStaffed) {
        this.queueAverageAgentsStaffed = queueAverageAgentsStaffed.toFixed(2);
        this.queueAverageAgentsStaffedColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAgentsStaffed"), this.queueAverageAgentsStaffed);
    }
    public getQueueAverageAgentsStaffed(): number {
        return parseFloat(this.queueAverageAgentsStaffed);
    }
    public setQueueAverageAbandonedTime(queueAverageAbandonedTime) {
        this.queueAverageAbandonedTime = this.processSecondsToTime(queueAverageAbandonedTime);
        this.queueAverageAbandonedTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAbandonedTime"), this.processTimeToSeconds(this.queueAverageAbandonedTime));
    }
    public getQueueAverageAbandonedTime(): number {
        return this.processTimeToSeconds(this.queueAverageAbandonedTime);
    }
    public setQueueTotalTalkTime(queueTotalTalkTime) {
        this.queueTotalTalkTime = this.processSecondsToTime(queueTotalTalkTime);
        this.queueTotalTalkTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalTalkTime"), this.processTimeToSeconds(this.queueTotalTalkTime));
    }
    public getQueueTotalTalkTime(): number {
        return this.processTimeToSeconds(this.queueTotalTalkTime);
    }
    public setQueueStaffedRatio() {
        if (this.totalAgents == 0) {
            this.queueStaffedRatio = "-";
            this.queueStaffedRatioColor = "";
        } else {
            this.queueStaffedRatio = this.staffedAgents.toString() + "/" + this.totalAgents.toString();
            this.queueStaffedRatioColor = this.calculateThresholdColor(this.getQueueThreshold("queueStaffedRatio"), this.staffedAgents);
        }
    }
    public getQueueStaffedRatio(): string {
        return this.queueStaffedRatio;
    }
    public setqueueTotalReceivedCalls() {
        this.queueTotalReceivedCalls = this.getQueueCallsAnswered() + this.getQueueCallsTimedOut() + this.getQueueCallsAbandoned();
        this.queueTotalReceivedCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalReceivedCalls"), this.queueTotalReceivedCalls);
    }
    public getqueueTotalReceivedCalls(): number {
        return this.queueTotalReceivedCalls;
    }
    public setStaffedAgents(staffedAgents) {
        this.staffedAgents = staffedAgents;
    }
    public getStaffedAgents(): number {
        return this.staffedAgents;
    }
    public setTotalAgents(totalAgents) {
        this.totalAgents = totalAgents;
    }
    public getTotalAgents(): number {
        return this.totalAgents;
    }

    /* #endregion */

    setValues(currentvalue, value) {
        if (value === "+") {
            return parseInt(currentvalue) + 1;
        } else if (value === "-") {
            return parseInt(currentvalue) - 1;
        } else {
            return parseInt(value);
        }
    }

    calculateThresholdColor(statThreshold: Threshold, value): string {
        if (statThreshold) {
            let values = [{ color: "#91d987", value: statThreshold.green }, { color: "#ffba55", value: statThreshold.yellow }, { color: "#f96855", value: statThreshold.red }, { color: "#9da4a7", value: statThreshold.black }];
            values.forEach((element, index) => {
                if (element.value === "" || element.value === null || element.value === "null") {
                    values[index].color = ""
                    values[index].value = "0";
                }
            });
            // let values = [{ color: "green", value: statThreshold.green }, { color: "yellow", value: statThreshold.yellow }, { color: "red", value: statThreshold.red }, { color: "black", value: statThreshold.black }];
            let val = "";
            if (statThreshold.isIncreasing) {
                values.sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1));
                for (let index = 0; index < values.length; index++) {
                    if ((values[index].value !== "") && (values[index].color !== "") && (Number(value) >= Number(values[index].value))) {
                        val = values[index].color;
                    }
                }
            } else {
                values.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? -1 : 1));
                for (let index = 0; index < values.length; index++) {
                    if ((values[index].value !== "") && (values[index].color !== "") && (Number(value) <= Number(values[index].value))) {
                        val = values[index].color;
                    }
                }
            }
            return val;
        }
    }
    getUserThreshold(stat) {
        if (this.queueName != "Summary") {
            for (let index = 0; index < this.contextService.agentStatisticsList.length; index++) {
                if (this.contextService.agentStatisticsList[index].statName == stat) {
                    return this.contextService.agentStatisticsList[index].threshold;
                }
            }
        }

    }
    getQueueThreshold(stat) {
        if (this.queueName != "Summary") {
            for (let index = 0; index < this.contextService.queueStatisticsList.length; index++) {
                if (this.contextService.queueStatisticsList[index].statName == stat) {
                    let element = this.contextService.queueStatisticsList[index];
                    if (element.callCentersList[this.queueID].green !== "" || element.callCentersList[this.queueID].black !== "" || element.callCentersList[this.queueID].red !== "" || element.callCentersList[this.queueID].yellow !== "") {
                        return this.contextService.queueStatisticsList[index].callCentersList[this.queueID];
                    } else {
                        return this.contextService.queueStatisticsList[index].callCentersList["all"];
                    }
                }
            }
        }
    }
    processTimeToSeconds(time) {
        let a = time.toString().split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
    processSecondsToTime(totalSeconds) {
        if (totalSeconds) {
            let seconds, minutes, hours;
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            minutes = Math.floor(totalSeconds / 60);
            seconds = Math.floor(totalSeconds % 60);
            // if (hours == 0) {
            // return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            // } else {
            return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            // }
        } else {
            return "00:00:00";
        }

    }
    calculatePercentageValues() {
        if (this.getQueueTotalCalls()) {
            this.setQueueCallsAnsweredPercentage(((this.getQueueCallsAnswered() / this.getQueueTotalCalls()) * 100));
            this.setQueueTotalMissedCallsPercentage((this.getQueueTotalMissedCalls() / this.getQueueTotalCalls()) * 100);
            this.setQueueBusyOverflowsPercentage((this.getQueueBusyOverflows() / this.getQueueTotalCalls()) * 100);
            this.setQueueCallsAbandonedPercentage((this.getQueueCallsAbandoned() / this.getQueueTotalCalls()) * 100);
            this.setQueueCallsTransferedPercentage((this.getQueueCallsTransfered() / this.getQueueTotalCalls()) * 100);
            this.setQueueCallsTimedOutPercentage((this.getQueueCallsTimedOut() / this.getQueueTotalCalls()) * 100);
            this.setQueueOverflowedTotalPercentage((this.getQueueOverflowedTotal() / this.getQueueTotalCalls()) * 100);
        }
    }
    clearAllValues() {
        this.userTotalCalls = 0;
        this.userAnsweredCalls = 0;
        this.userMissedCalls = 0;
        this.userTotalTalkTime = "00:00:00";
        this.userAverageTalkTime = "00:00:00";
        this.userTotalStaffedTime = "00:00:00";
        this.userCallbacksAnswered = 0;

        this.queueTotalCalls = 0;
        this.queueCallsAnswered = 0;
        this.queueCallsAnsweredPercentage = "0.00";
        this.queueTotalMissedCalls = 0;
        this.queueTotalMissedCallsPercentage = "0.00";
        this.queueCallsInQueue = 0;
        this.queueLongestWaitTime = "00:00:00";
        this.queueAverageWaitTime = "00:00:00";
        this.queueAverageTalkTime = "00:00:00";
        this.queueBusyOverflows = 0;
        this.queueBusyOverflowsPercentage = "0.00";
        this.queueCallsAbandoned = 0;
        this.queueCallsAbandonedPercentage = "0.00";
        this.queueCallsTransfered = 0;
        this.queueCallsTransferedPercentage = "0.00";
        this.queueCallsTimedOut = 0;
        this.queueCallsTimedOutPercentage = "0.00";
        this.queueOverflowedTotal = 0;
        this.queueOverflowedTotalPercentage = "0.00";
        this.queueAverageAgentsTalking = "0";
        this.queueAverageAgentsStaffed = "0";
        this.queueAverageAbandonedTime = "00:00:00";
        this.queueTotalTalkTime = "00:00:00";
        this.queueStaffedRatio = "";
        this.queueTotalReceivedCalls = 0;
        this.staffedAgents = 0;
        this.totalAgents = 0;
    }
    recalculateThresholdColor() {
        this.userTotalCallsColor = this.calculateThresholdColor(this.getUserThreshold("userTotalCalls"), this.userTotalCalls);
        this.userAnsweredCallsColor = this.calculateThresholdColor(this.getUserThreshold("userAnsweredCalls"), this.userAnsweredCalls);
        this.userMissedCallsColor = this.calculateThresholdColor(this.getUserThreshold("userMissedCalls"), this.userMissedCalls);
        this.userTotalTalkTimeColor = this.calculateThresholdColor(this.getUserThreshold("userTotalTalkTime"), this.processTimeToSeconds(this.userTotalTalkTime));
        this.userAverageTalkTimeColor = this.calculateThresholdColor(this.getUserThreshold("userAverageTalkTime"), this.processTimeToSeconds(this.userAverageTalkTime));
        this.userTotalStaffedTimeColor = this.calculateThresholdColor(this.getUserThreshold("userTotalStaffedTime"), this.processTimeToSeconds(this.userTotalStaffedTime));
        this.userCallbacksAnsweredColor = this.calculateThresholdColor(this.getUserThreshold("userCallbacksAnswered"), this.userCallbacksAnswered);

        this.queueTotalCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalCalls"), this.queueTotalCalls);
        this.queueCallsAnsweredColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAnswered"), this.queueCallsAnswered);
        this.queueCallsAnsweredPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAnsweredPercentage"), this.queueCallsAnsweredPercentage);
        this.queueTotalMissedCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalMissedCalls"), this.queueTotalMissedCalls);
        this.queueTotalMissedCallsPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalMissedCallsPercentage"), this.queueTotalMissedCallsPercentage);
        this.queueCallsInQueueColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsInQueue"), this.queueCallsInQueue);
        this.queueLongestWaitTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueLongestWaitTime"), this.processTimeToSeconds(this.queueLongestWaitTime));
        this.queueAverageWaitTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageWaitTime"), this.processTimeToSeconds(this.queueAverageWaitTime));
        this.queueAverageTalkTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageTalkTime"), this.processTimeToSeconds(this.queueAverageTalkTime));
        this.queueBusyOverflowsColor = this.calculateThresholdColor(this.getQueueThreshold("queueBusyOverflows"), this.queueBusyOverflows);
        this.queueBusyOverflowsPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueBusyOverflowsPercentage"), this.queueBusyOverflowsPercentage);
        this.queueCallsAbandonedColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAbandoned"), this.queueCallsAbandoned);
        this.queueCallsAbandonedPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsAbandonedPercentage"), this.queueCallsAbandonedPercentage);
        this.queueCallsTransferedColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTransfered"), this.queueCallsTransfered);
        this.queueCallsTransferedPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTransferedPercentage"), this.queueCallsTransferedPercentage);
        this.queueCallsTimedOutColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTimedOut"), this.queueCallsTimedOut);
        this.queueCallsTimedOutPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueCallsTimedOutPercentage"), this.queueCallsTimedOutPercentage);
        this.queueAverageAgentsTalkingColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAgentsTalking"), this.queueAverageAgentsTalking);
        this.queueAverageAgentsStaffedColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAgentsStaffed"), this.queueAverageAgentsStaffed);
        this.queueAverageAbandonedTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueAverageAbandonedTime"), this.processTimeToSeconds(this.queueAverageAbandonedTime));
        this.queueTotalTalkTimeColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalTalkTime"), this.processTimeToSeconds(this.queueTotalTalkTime));
        this.queueOverflowedTotalColor = this.calculateThresholdColor(this.getQueueThreshold("queueOverflowedTotal"), this.queueOverflowedTotal);
        this.queueOverflowedTotalPercentageColor = this.calculateThresholdColor(this.getQueueThreshold("queueOverflowedTotalPercentage"), this.queueOverflowedTotalPercentage);
        this.queueTotalReceivedCallsColor = this.calculateThresholdColor(this.getQueueThreshold("queueTotalReceivedCalls"), this.queueTotalReceivedCalls);
        if (this.queueStaffedRatio != "-") {
            this.queueStaffedRatioColor = this.calculateThresholdColor(this.getQueueThreshold("queueStaffedRatio"), this.staffedAgents);

        }
    }
}