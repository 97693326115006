import { Injectable } from '@angular/core';
import { CallWaiting } from '../../../model/bw-services/call-waiting';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
/**
 * service for call waiting
 * 
 * @export
 * @class CallWaitingService
 */
@Injectable()
export class CallWaitingService {
    isActive: boolean = false;

    callWaiting: CallWaiting;
    callWaitingChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - CallWaitingService');
    }
    setData(callWaiting: CallWaiting) {
    }
    validate(): boolean {
        return true;
    }
    save() {
    }
    populateData() {
    }
    cancel() {
    }

}