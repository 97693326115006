import { Component, OnInit } from '@angular/core';
import { ContextService } from "../../../../../../providers/context.service";
import { SettingsGeneral } from "../../../../../../providers/settings/settings-general/settings-general.service";

@Component({
  selector: 'app-outlook-integration',
  templateUrl: './outlook-integration.component.html',
  styleUrls: ['./outlook-integration.component.scss']
})
export class OutlookIntegrationComponent implements OnInit {

  description: string = "";
  showBusinessNameColumn: string = "";
  authorise: string = "";
  authoriseStatus: string = "";
  authoriseCancel: string = "";
  authoriseComplete: string = "";
  authoriseButtonClicked: boolean = false;
  authorisationFailedAlert: boolean = false;
  authorisationFailedError:string="";
  callButtonShowingOK:string="";
  cssClass:string="";
  constructor(public contextService: ContextService, public settingsGeneral:SettingsGeneral) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Cti.Outlook.Description');
    this.showBusinessNameColumn = this.contextService.getLanguageTagByKey('Settings.Cti.Outlook.ShowBusinessNameColumn');
    this.authorise = this.contextService.getLanguageTagByKey('Misc.Authorise');
    this.authoriseStatus = this.contextService.getLanguageTagByKey('Misc.Authorise.TokenNotSaved');
    this.authoriseCancel = this.contextService.getLanguageTagByKey('Misc.Authorise.Cancel');
    this.authoriseComplete = this.contextService.getLanguageTagByKey('Misc.Authorise.Complete');
    this.authorisationFailedError = this.contextService.getLanguageTagByKey('Misc.Authorise.TokenNotReturnedError');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    if(this.contextService.objLoggedUserDetails.getcalendarTokenExists()){
      this.authoriseStatus =this.contextService.getLanguageTagByKey('Misc.Authorise.TokenSaved');
      this.cssClass="loggedin";
    } else{
      this.authoriseStatus = this.contextService.getLanguageTagByKey('Misc.Authorise.TokenNotSaved');
      this.cssClass="loggedoff";
    }
  }
  showBusinessColumn(){
    this.settingsGeneral.settingsChanged=true;
  }
  authoriseClick() {
    // this.authoriseButtonClicked = true;
   let temp = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=7de0cbb3-2cae-4b96-98a3-d68cc8bc6545&response_type=code&redirect_uri=https%3A%2F%2Fportal.unityclient.com%2FOffice365ContactIntegration.aspx&response_mode=query&state="+this.contextService.objLoggedUserDetails.getUserGuid()+"&scope=Contacts.Read%20Contacts.Read.Shared%20offline_access";
  // let temp = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=5790b2c0-b9e6-41d8-b0ae-11dda34ff7b4&response_type=code&redirect_uri=https%3A%2F%2Fportal.unityclient.com%2FOffice365CalendarIntegration.aspx&response_mode=query&state=u"+this.contextService.objLoggedUserDetails.getUserGuid()+"&scope=Calendars.Read%20Calendars.Read.Shared%20Contacts.Read%20Contacts.Read.Shared%20offline_access"
    if (this.contextService.isElectron === true) {
      this.contextService.electronService.ipcRenderer.send('load-url', temp);
    } else {
      window.open(temp, "_blank");
    }
  }
  authoriseCompleteClick() {
    this.authorisationFailedAlert = true;
  }

  authorisationFailedAlertClick(){
    this.authorisationFailedAlert = false;
  }
  authoriseCancelClick(){
    this.authoriseButtonClicked = false;
  }
}
