<p-dialog
  [(visible)]="emailModalVisible"
  [ngClass]="{ visible: emailModalVisible }"
  [closable]="false"
  [style]="{ width: '90%' }"
  class="small-dialog messages-dialog email-dialog"
  dismissableMask="true"
  showHeader="true"
  [resizable]="false"
  [maximizable]="true"
  (onShow)="showDialogMaximized(dialEmail)"
  #dialEmail
>
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{ title }}</h4>
      <span class="close-modal small" (click)="close($event)">X</span>
      <span
        class="maximize-modal"
        (click)="messagesService.toggleDialogMaximized(dialEmail)"
      ></span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="messages-modal-content">
      <ul class="buttons-menu in-modal">
        <!-- reject -->
        <li *ngIf="!reserved">
          <div class="tooltip">
            <span>{{ rejectBtn }}</span>
          </div>
          <a (click)="reject($event)" class="btn-reject new-i">{{
            rejectBtn
          }}</a>
        </li>
        <!-- reserve -->
        <li *ngIf="!reserved">
          <div class="tooltip" style="white-space: nowrap">
            <span>{{ reserveBtn }}</span>
          </div>
          <a (click)="reserve($event)" class="btn-reserve new-i">{{
            reserveBtn
          }}</a>
        </li>

        <!-- transfer -->
        <li *ngIf="reserved" #transferToChat>
          <div class="tooltip">
            <span>{{ transferBtn }}</span>
          </div>
          <a
            (click)="openTransferToCm($event, transfertocmChat)"
            class="btn-transfer-cb new-i"
            >{{ transferBtn }}</a
          >

          <p-contextMenu
            [model]="transferToList"
            appendTo="body"
            [target]="transferToChat"
            #transfertocmChat
          >
          </p-contextMenu>
        </li>

        <!-- close -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ closeBtn }}</span>
          </div>
          <a (click)="close($event)" class="btn-close new-i">{{ closeBtn }}</a>
        </li>

        <!-- send -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ sendBtn }}</span>
          </div>
          <a (click)="sendEmail($event)" class="btn-email-send new-i">{{
            sendBtn
          }}</a>
        </li>

        <!-- view in CRM -->
        <li>
          <div class="tooltip">
            <span>{{ viewInCRMBtn }}</span>
          </div>
          <a (click)="viewInCRM($event)" class="btn-view-in-crm new-i">{{
            viewInCRMBtn
          }}</a>
        </li>
        <!-- add entry to CRM -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ addCRMBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'crm' }"
            (click)="selectTab($event, 'crm')"
            class="btn-add-crm-entry new-i"
            >{{ addCRMBtn }}</a
          >
        </li>

        <!-- att -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ attachmentBtn }}</span>
          </div>
          <label for="email_att" class="btn-attachment new-i">
            {{ attachmentBtn }}
            <input
              type="file"
              id="email_att"
              name="att"
              multiple
              (change)="uploadFile($event)"
            />
          </label>
        </li>

        <li *ngIf="reserved" class="separator"></li>

        <!-- conversation -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ conversationBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'conversation' }"
            (click)="selectTab($event, 'conversation')"
            class="btn-email-conversation new-i"
            >{{ conversationBtn }}</a
          >
        </li>

        <!-- replies -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ respliesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'replies' }"
            (click)="showReplies($event)"
            class="btn-email-replies new-i"
            >{{ respliesBtn }}</a
          >
        </li>

        <!-- resources -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ resourcesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'resources' }"
            (click)="showResources($event)"
            class="btn-email-resources new-i"
            >{{ resourcesBtn }}</a
          >
        </li>
      </ul>

      <div class="email-window">
        <div class="mw-right fw">
          <div class="email-details" [ngClass]="{ expanded: detailsExpanded }">
            <a
              href="#"
              class="details-toggle"
              (click)="toggleDetails($event)"
            ></a>
            <dl>
              <dt class="details-to">To</dt>
              <dd class="details-to">
                <p-chips
                  [(ngModel)]="emailTo"
                  [disabled]="!reserved"
                  placeholder="{{ emailsInput }}"
                  (onAdd)="validateEmailTo($event)"
                  [addOnTab]="true"
                  [addOnBlur]="true"
                  (keydown)="onKeyDown($event)"
                  [allowDuplicate]="false"
                >
                  <ng-template let-item pTemplate="item">
                    <span class="email_addr">{{ item }}</span>
                  </ng-template>
                </p-chips>
              </dd>
              <dt class="details-cc">CC</dt>
              <dd class="details-cc">
                <p-chips
                  [(ngModel)]="emailCC"
                  [disabled]="!reserved"
                  placeholder="{{ emailsOptionalInput }}"
                  (onAdd)="validateEmailCC($event)"
                  [addOnTab]="true"
                  [addOnBlur]="true"
                  (keydown)="onKeyDown($event)"
                  [allowDuplicate]="false"
                >
                  <ng-template let-item pTemplate="item">
                    <span class="email_addr">{{ item }}</span>
                  </ng-template>
                </p-chips>
              </dd>
              <dt class="details-bcc">BCC</dt>
              <dd class="details-bcc">
                <p-chips
                  [(ngModel)]="emailBCC"
                  [disabled]="!reserved"
                  placeholder="{{ emailsOptionalInput }}"
                  (onAdd)="validateEmailBCC($event)"
                  [addOnTab]="true"
                  [addOnBlur]="true"
                  (keydown)="onKeyDown($event)"
                  [allowDuplicate]="false"
                >
                  <ng-template let-item pTemplate="item">
                    <span class="email_addr">{{ item }}</span>
                  </ng-template>
                </p-chips>
              </dd>
              <dt>Subject</dt>
              <dd>
                <input
                  type="text"
                  required
                  class="email-subject"
                  [disabled]="!reserved"
                  [(ngModel)]="emailSubject"
                />
              </dd>
            </dl>
          </div>
          <div class="email-attachments" *ngIf="attachments.length">
            <ul class="email-attachments-list">
              <li *ngFor="let attachment of attachments; let i = index">
                <app-attachment [attachment]="attachment"></app-attachment>
              </li>
            </ul>
          </div>
          <div
            class="email-body-wrapper"
            [hidden]="selectedTab !== 'conversation'"
            [ngClass]="{ accessible: reserved }"
          >
            <editor
              *ngIf="tinyInit"
              [(ngModel)]="emailHtml"
              apiKey="vzctnhg5kx8vuwq3o38z9x3739ydbwu7xvo1cjii3ctucr1k"
              id="email-client"
              (onPaste)="handlePaste($event)"
              spellcheck="true"
              [init]="{
                cloudChannel: 6,
                quickbars_image_toolbar: false,
                quickbars_quicktable_toolbar: false,
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar:
                  'bold italic strikethrough underline forecolor backcolor | \ | quicklink h2 h3 blockquote',
                height: '100%',
                menubar: false,
                resize: false,
                branding: false,
                toolbar_sticky: true,
                statusbar: false,
                paste_data_images: true,
                spellchecker_dialog: false,
                spellchecker_language: 'en',
                plugins:
                  'tinymcespellchecker export print preview paste searchreplace autolink fullscreen image link media table charmap hr pagebreak nonbreaking anchor advlist lists wordcount noneditable help charmap quickbars emoticons',
                toolbar:
                  'bold italic strikethrough underline forecolor backcolor |  
                    alignleft aligncenter alignright alignjustify | \ undo redo | \
                    bullist numlist outdent indent | table quickimage | export | \
                    spellchecker spellcheckdialog | help '
              }"
            >
            </editor>
          </div>
          <app-canned-replies
            [hidden]="selectedTab !== 'replies'"
            [visible]="selectedTab === 'replies'"
            (onRightClick)="repliesRightClick($event)"
            (onDoubleClick)="repliesDoubleClick($event)"
            [repliesEntries]="respliesList"
          ></app-canned-replies>
          <app-resources
            [hidden]="selectedTab !== 'resources'"
            [visible]="selectedTab === 'resources'"
            (onRightClick)="resourcesRightClick($event)"
            (onDoubleClick)="resourcesDoubleClick($event)"
            [resourceEntries]="resourcesList"
          ></app-resources>
          <app-add-crm-entry
            [hidden]="selectedTab !== 'crm'"
            (onSubmit)="onCrmSubmit($event)"
            (onClose)="onCrmClose($event)"
          ></app-add-crm-entry>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
