<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <p>
    <b>
      {{divertAllCallsToVoicemail}}
    </b>
  </p>
</div>
<div class="row">
  <p-checkbox name="checkboxGroup2" value="checkbox 1" label={{butForwardCallsFromTheseNumbers}} [(ngModel)]="commPilotExpressService.unavailablesendCallsToVoiceMailExceptExcludedAddresses"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <input type="text" id="txt_addNumberUnavailable" name="input-text" [(ngModel)]="commPilotExpressService.addNumberUnavailable"
    [disabled]="!commPilotExpressService.unavailablesendCallsToVoiceMailExceptExcludedAddresses">
</div>
<div class="row">
  <div class="box">

    <p-table [value]="commPilotExpressService.unavailableexcludedAddress" selectionMode="single" [(selection)]="selectedNumber">
      <ng-template pTemplate="header" >
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr  [pSelectableRow]="rowData">
          <td>
            {{rowData.number}}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="deleteNumberUnavilable()">{{delete}}</a>
        <a href="javascript:void(0);" (click)="commPilotExpressService.addUnavailable()">{{plus}}</a>
      </div>
    </div>
  </div>
</div>


<div class="row">

  {{to}}
  <input type="text" id="txt_unavailableforwardToAddress" name="input-disabled" [(ngModel)]="commPilotExpressService.unavailableforwardToAddress"
    [disabled]="!commPilotExpressService.unavailablesendCallsToVoiceMailExceptExcludedAddresses">
</div>
<div>
  <div class="row">
    <label for="" class="label-block"> {{WhenCallSentToVoicemail}}</label>
  </div>
  <div class="row">
    <p-radioButton id="rdb_UnavailableGreeting" name="groupname" value="Unavailable" label={{UseUnavailableGreeting}}
      [(ngModel)]="commPilotExpressService.unavailablevoiceMailGreeting" binary="true" styleClass="big-radio"></p-radioButton>
  </div>
  <div class="row">
    <p-radioButton id="rdb_NoAnswerGreeting" name="groupname" value="No Answer" label={{UseNoAnswerGreeting}}
      [(ngModel)]="commPilotExpressService.unavailablevoiceMailGreeting" binary="true" styleClass="big-radio"></p-radioButton>
  </div>
</div>