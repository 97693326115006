import { Component, OnInit } from '@angular/core';
import { ContextService } from "../../../../../providers/context.service";
import { FlexibleSeatingGuestService } from "../../../../../providers/settings/settings-services/flexible-seating-guest.service";
import { BaseComponent } from '../../../../base-component';

@Component({
  selector: 'app-flexible-seating-guest',
  templateUrl: './flexible-seating-guest.component.html',
  styleUrls: ['./flexible-seating-guest.component.scss']
})
export class FlexibleSeatingGuestComponent extends BaseComponent implements OnInit {
  hours: string = "";
  maximum: string = "";
  flexibleSeatingGuestDescription: string = "";
  deviceEnable: string = "";
  flexibleSeatingGuestDeviceName: string = "";
  linePort: string = "";
  flexibleSeatingGuestHostAssociation: string = "";
  hotelingGuestHostName: string = "";
  unlimitedHours:string="";
  constructor(public flexibleSeatingGuestService: FlexibleSeatingGuestService, public contextService: ContextService) {
    super();
  }

  ngOnInit() {
    this.flexibleSeatingGuestService.flexibleSeatingGuestChanged = true;
    this.hours = this.contextService.getLanguageTagByKey('Misc.Hours');
    this.maximum = this.contextService.getLanguageTagByKey('Misc.Maximum');

    this.flexibleSeatingGuestDescription = this.contextService.getLanguageTagByKey('Settings.Services.FlexibleSeatingGuest.Description');
    this.deviceEnable = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.flexibleSeatingGuestDeviceName = this.contextService.getLanguageTagByKey('Settings.Services.FlexibleSeatingGuest.DeviceName');
    this.linePort = this.contextService.getLanguageTagByKey('Settings.Services.Sca.LinePort');
    this.flexibleSeatingGuestHostAssociation = this.contextService.getLanguageTagByKey('Settings.Services.FlexibleSeatingGuest.HostAssociation');
    this.hotelingGuestHostName = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.HostName');
    this.unlimitedHours = this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest.Hours');
  }
  onChange(event) {
    this.contextService.flexibleHostListWithName.forEach(element => {
      if (element.value === event.value) {
        this.flexibleSeatingGuestService.hostAssociationLimitHours = element.associationLimit;
        if (element.enableAssociationLimit) {
          this.flexibleSeatingGuestService.showUnlimitedAssociationText = false;
        } else {
          this.flexibleSeatingGuestService.showUnlimitedAssociationText = true;
        }
      }
    });
  }
}
