import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../providers/context.service';
import { CommPilotExpressService } from '../../../../../../providers/settings/settings-services/comm-pilot-express.service';

@Component({
  selector: 'app-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss']
})
export class BusyComponent implements OnInit {
  description: string = "";
  divertAllCallsToVoicemail: string = "";
  butForwardCallsFromTheseNumbers: string = "";
  to: string = "";
  notifyMeOfNewVoicemails: string = "";
  selectedNumber:any="";

  delete: string = "";
  plus: string = "";

  constructor(private contextService: ContextService, public commPilotExpressService: CommPilotExpressService) { }

  ngOnInit() {

    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');

    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Busy.Description');
    this.divertAllCallsToVoicemail = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.DivertAllCallsToVoicemail');
    this.butForwardCallsFromTheseNumbers = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.ButForwardCallsFromTheseNumbers');
    this.to = this.contextService.getLanguageTagByKey('Misc.To');
    this.notifyMeOfNewVoicemails = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Busy.NotifyMeOfNewVoicemails');
    this.commPilotExpressService.commpilotServiceChanges = true;
  }
  deleteNumber() {
    if (this.commPilotExpressService.busysendCallsToVoiceMailExceptExcludedAddresses) {
      this.commPilotExpressService.deleteBusy(this.selectedNumber.number);
    }

  }

}






