<ul class="chat-conversation"  [ngClass]="{'offline': conversation.offline, 'unread': conversation.unread > 0}" (@close.done)="animationDone($event)" [@close]='state'>
  <li class="conversation-header" (click)="activate(conversation.id, $event)">
    <div>
      <strong class="chat-person" (mouseenter)="showMore($event)"  (mouseleave) ="hideMore($event)" [innerHTML]="conversation.headline | headline"></strong>
      <ul class="actions">
        <li *ngIf="conversation.people.length == 1" class="call"><a href="#" (click)="call($event, conversation)"></a></li>
        <li *ngIf="conversation.people.length > 1" class="call" (mouseenter) ="showMore($event) "  (mouseleave) ="hideMore($event)">
          <a href="#" (click)="callListTrigger($event)"></a>
          <div class="call-list">
            <ul>
              <li *ngFor="let person of conversation.getCallToPeple();let i = index;">
        
                <a href="#" (click)="call($event, conversation, person)">{{person.name}}</a>
              </li> 
            </ul>
            <span class="corner"></span>
          </div>
          
        </li>
        <li class="close" title="{{closeChat}}"><a href="#" (click)="close($event)"></a></li>
      </ul>
      <p><span *ngIf="conversation.unread > 0" class="unread">{{conversation.unread}}</span>{{conversation.message}}</p>
      <a class="adding-person"><span>{{addToChat}}</span></a>
      <a class="dropping-person"><span>Drop for options</span></a>       
      <a class="offline-person"><span>{{offlineNotAdded}}</span></a> 
    </div>
  </li>
  <li class="conversation-text">
        <div class="container" id="scrollPS{{conversation.id}}" [perfectScrollbar]>
            <div class="content"><ul class="conversation-messages">
                <li *ngFor="let message of contextService.messages;let i = index;" [ngClass]="{'date-time': !message.id, 'you': !message.itsMe&&message.id, 'me': message.itsMe&&message.id}">
                  <p *ngIf="!message.id">{{message.dateTime}}</p>
                  <p *ngIf="message.id">
                    <strong class="chat-person" *ngIf="message.headline">{{message.headline}}</strong>
                    <span class="timestamp">{{message.dateTime | date:'shortTime'}}</span>
                    <span [innerHTML]="message.text"></span>
                  </p>
                </li>    
              </ul>
          </div>
        </div>
  </li>
  <li class="conversation-input">
    <div>
      <form  (ngSubmit)="onSubmit(conversation.id)" name="imMessageForm{{conversation.id}}">
        <div class="conversation-notify" *ngIf="typing">{{typing}} is typing</div>
        <fieldset [ngClass]="{'disabled': message.text.length == 0}"> 
           <div class="container" id="chat{{conversation.id}}" [perfectScrollbar]>
            <div class="text-wrap clearfix">
              <textarea name="message" id="{{conversation.id}}" (keyup)="adjustSize($event,conversation.id)" (click)="focus($event,conversation.id)" required [(ngModel)]="message.text" placeholder="{{writeMessageHere}}" rows="1" [maxLength]="500" [disabled]=this.disableTextfield></textarea>
            </div>
          </div>
          <button type="submit" [disabled]="message.text.length == 0"(click)="send($event,conversation.id)">{{sendlbl}}</button>
        </fieldset>
      </form>
    </div>
  </li>   
</ul>
<p-contextMenu [model]="contextMenuItems" appendTo="body" #afterdropmenu styleClass="chat-context-menu" [style]="{'left':afterdropmenuLeft,'top':afterdropmenuTop}"></p-contextMenu>