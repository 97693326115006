<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox1" (click)="focus()" label={{enabled}} [(ngModel)]="simultaneousRingService.isActive" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{phoneNumber}}</label>
  <input type="text" id="textarea" name="input-text" [(ngModel)]="simultaneousRingService.newNumber" [disabled]="!simultaneousRingService.isActive" autocomplete=off>
</div>
<div class="row">
  <div class="box">

    <p-table [value]="simultaneousRingService.numberArray" selectionMode="single" [(selection)]="selectedNumber" dataKey="id">
        <ng-template pTemplate="header" >
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr  [pSelectableRow]="rowData">
            <td>
              {{rowData.number}}
            </td>
          </tr>
        </ng-template>
      </p-table>
    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="deleteNumber()">{{delete}}</a>
        <a href="javascript:void(0);"  (click)="simultaneousRingService.addNewNumber()">{{plus}}</a>
        
      </div>
    </div>
  </div>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup2" value="checkbox2" label={{doNotRingWhenOnACall}} [(ngModel)]="simultaneousRingService.incomingCalls"
    binary="true" [disabled]="!simultaneousRingService.isActive"></p-checkbox>
</div>