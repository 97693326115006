/**
 * model base class to store directories 
 * 
 * @export
 * @class DirectoryEntry
 */
export class DirectoryEntry {
    private name: string;
    private id: number;
    private number: string;

    constructor(name: string, number: string) {
        this.name = name;
        this.number = number;
    }
    public getId() {
        return this.id;
    }
    public setId(id: number) {
        this.id=id;
    }
    public getNumber() {
        return this.number;
    }
    public getName() {
        return this.name;
    }
}