
/**
 * This is the model class of BW user.
 * 
 * @export
 * @class BWUser
 */ 
export class BWUser {
    
        public loginId: string;
    
        public name: string;
    
        public title: string;
    
        public email: string;
    
        public mobile: string;
    
        public isOnline: boolean;
    
        public departmentId: string;
    
        public groupId: string;
        
        public type: string;
    
      

        /**
           * Creates an instance of BWUser.
           * 
           * 
           * @memberOf BWUser
           */
        constructor() {
        }
    
    }
   
    
    
    
    