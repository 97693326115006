import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from 'app/providers/settings/settings-general/settings-general.service';
@Component({
  selector: 'app-browser-integration',
  templateUrl: './browser-integration.component.html',
  styleUrls: ['./browser-integration.component.scss']
})
export class BrowserIntegrationComponent implements OnInit {

  description: string = "";
  browseToUrl: string = "";
  onInboundCall: string = "";
  throughCallCenter: string = "";
  notThroughCallCenter: string = "";
  onOutboundCall: string = "";

  encodeDelimiter: string = "";
  doNotUseBrowserInterface:string="";
  constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.description = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.Description');
    this.browseToUrl = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.BrowseToUrl');
    this.onInboundCall = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.OnInboundCall');

    this.throughCallCenter = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.ThroughCallCenter');
    this.notThroughCallCenter = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.NotThroughCallCenter');
    this.onOutboundCall = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.OnOutboundCall');
    this.encodeDelimiter = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.EncodeDelimiter');

    this.settingsGeneral.settingsChanged=true;

    this.doNotUseBrowserInterface=this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.BrowseToUrlWithoutBrowser');
  }

}
