import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { ExternalCallingLineIDDeliveryService } from '../../../../../providers/settings/settings-services/external-calling-line-id-delivery.service';
import { ContextService } from '../../../../../providers/context.service';

/**
 * This serves as a interface between component and externalCallingLineIdDelivery service
 * 
 * @export
 * @class ExternalCallingLineIDDeliveryComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-external-calling-line-id-delivery',
  templateUrl: './external-calling-line-id-delivery.component.html',
  styleUrls: ['./external-calling-line-id-delivery.component.scss']
})
export class ExternalCallingLineIDDeliveryComponent extends BaseComponent implements OnInit {
  description: string="";
  enabled: string="";
  constructor(public externalCallingLineIDDeliveryService: ExternalCallingLineIDDeliveryService, private contextService: ContextService) {
    super();
  }
  ngOnInit() {
    this.externalCallingLineIDDeliveryService.externalLineChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.ExternalCallLineIdDelivery.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');

  }
}
