import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-add-crm-entry',
  templateUrl: './add-crm-entry.component.html',
  styleUrls: ['./add-crm-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCrmEntryComponent implements OnInit {

  @Output() onSubmit = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  crmForm: FormGroup;
  note: string;
  closeImeediately: boolean;

  title: string = "";
  check: string = "";
  btnShow: string = "";
  btnOk: string = "";
  btnCancel: string = "";

  constructor(
    public contextService: ContextService, private messagesService: MessagesService,
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {

    this.title = this.contextService.getLanguageTagByKey('AddCrmEntry.Title');
    this.check = this.contextService.getLanguageTagByKey('AddCrmEntry.Check');
    this.btnShow = this.contextService.getLanguageTagByKey('AddCrmEntry.ShowInCRMContact');
    this.btnOk = this.contextService.getLanguageTagByKey('AddCrmEntry.btnOk');
    this.btnCancel = this.contextService.getLanguageTagByKey('AddCrmEntry.btnCancel');

    this.crmForm = this.fb.group({
      note: ['', Validators.required],
      closeImeediately: [false],
    });

  }

  onFormSubmit() {
    console.log(this.crmForm.value);
    this.onSubmit.emit(this.crmForm.value);
  }

  showContact() {
    alert(this.btnShow)
  }

  close() {
    this.onClose.emit(true);
  }
}
