import { Injectable } from '@angular/core';
import { CallingLineIDDeliveryBlocking } from '../../../model/bw-services/calling-line-id-delivery-blocking';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';


/**
 * to register externalCallingLineIDDeliveryResponseHandler   
 * 
 * @export
 * @class ExternalCallingLineIDDeliveryService
 */
@Injectable()

export class CallingLineIDDeliveryBlockingService {
    isActive: boolean = false;
    callingLineChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - CallingLineIDDeliveryBlockingService');
      }
    setData(callingLineIDDeliveryBlocking: CallingLineIDDeliveryBlocking) {
    }
    validate(): boolean {
        return true;
    }
     save() {
     }
    populateData() {
    }
    cancel() {
    }

}