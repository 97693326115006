import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
// c8 - new types?
import { CallBackEntry } from '../model/call-back.model';
import { ResourceEntry } from '../model/resource-entry.model'
import { ReplyEntry } from '../model/reply-entry.model'
import { HistoryEntry } from '../model/history-entry.model'
import { User } from '../model/user.model'
import { Attachment } from '../model/attachment.model';

type Emojis = { [key: string]: any };

@Injectable()
export class MessagesService {

  openCallBackDialog = new Subject<any>();
  openWebChatDialog = new Subject<any>();
  openTwitterDialog = new Subject<any>();
  openSmsDialog = new Subject<any>();


  emojis: Emojis = {
    'smile': [':)', ':-)'],
    'sad': [':(', ':-('],
    'winking': [';)', ';-)'],
    'laughing': [':D', ':-D'],
    'big-grin': ['=D'],
    'rofl': [':->', ':>'],
    'cool': ['B-)'],
    'worried': ['S-)'],
    'thinking': [':-?'],
    'straightface': [':-|'],
    'shocked': [':-o'],
    'angry': [':-X'],
    // 'furious' : [':-@'], 
  };

  public messagesList: Array<CallBackEntry>;

  constructor(private http: HttpClient) {
    this.messagesList = [];
  }


  scrollToBottom(id, timeout = 100) {
    if (!timeout) {
      timeout = 100
    }
    setTimeout(() => {
      var element = document.getElementById(id);
      if (element) {
        if (element.scrollHeight != null) {
          element.scrollTop = element.scrollHeight;
        }
      }
    }, timeout);
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return ("00" + minutes).slice(-2) + ":" + ("00" + seconds).slice(-2);
  }

  loadTransferToList(command) {
    return [
      { label: "Yup I'm here Tom - away?", icon: 'ico-available', command: (event) => command({}), visible: true },
      { label: "Yup I'm here Tom", icon: 'ico-available is-online', command: (event) => command({}), visible: true },
      { label: "Yup I'm here Tom", icon: 'ico-away', command: (event) => command({}), visible: true },
      { label: "Yup I'm here Tom - away but online?", icon: 'ico-away is-online', command: (event) => command({}), visible: true },
      { label: "Lazy Joe", icon: 'ico-unavailable', command: (event) => command({}), visible: true },
      { label: "Bussy Anna", icon: 'ico-onHook-DND', command: (event) => command({}), visible: true },
    ]
  }

  loadHistory() {
    // demo  
    return [
      new HistoryEntry('WebChat (Closed)', ['Chris Lab', 'WebChat'], new Date(1645113239650), 'webchat', 2),
      new HistoryEntry('Twitter? (Closed)', ['Chris Lab', 'Twitter'], new Date(1645113237650), 'twitter'),
      new HistoryEntry('SMS? (Closed)', ['Chris Lab', 'SMS'], new Date(1645113237650), 'sms'),
      new HistoryEntry('WebChat (unstaffed)', ['Chris Lab', 'WebChat'], new Date(1645113238650), 'webchat', 5),
      new HistoryEntry('CallBack (Closed)', ['Chris Lab', 'CallBack'], new Date(1645113237650), 'callback'),
      new HistoryEntry('Email (Closed)', ['Chris Lab', 'CallBack'], new Date(1645113237650), 'email'),
      new HistoryEntry('CallBack (Closed)', ['Chris Lab', 'CallBack'], new Date(1645113237650), 'callback'),
      new HistoryEntry('Email (Closed)', ['Chris Lab', 'Email'], new Date(1645113237650), 'email'),
      new HistoryEntry('WebChat (unstaffed)', ['Chris Lab', 'WebChat'], new Date(1645113238650), 'webchat', 1),
      new HistoryEntry('WebChat (unstaffed)', ['Chris Lab', 'WebChat'], new Date(1645113238650), 'webchat', 3),
      new HistoryEntry('WebChat (unstaffed)', ['Chris Lab', 'WebChat'], new Date(1645113238650), 'webchat', 3.5)
    ]
  }

  getUser(id) {
    return new User(
      id,
      'Michal Orlik',
      'michal@code8.cz',
      '+420776756141',
      'Sales',
      '1555.55.55.55',
      'Google chrome',
      'https://portal.unityclient.com/QueueMediaStreamTest.aspx?streamid=426'
    );
  }
  // demo
  getUserLessInfo(id) {
    return new User(
      id,
      'Michal Orlik',
      'michal@code8.cz',
      '+420776756141',
    );
  }

  toggleDialogMaximized(dialog) {
    dialog.maximized = !dialog.maximized;
  }

  loadResources() {
    var resourcesList: Array<ResourceEntry> = [];
    // demo 
    resourcesList.push(new ResourceEntry(
      1,
      'This is the main name of the resource',
      'This is optional description 1',
      'https://www.kakaposystems.com/files/Unity-Product-Description-Comparison-Table.pdf'
    ));
    resourcesList.push(new ResourceEntry(
      2,
      'This is the main name',
      '',
      'https://www.kakaposystems.com/files/Unity-Product-Description-Comparison-Table.pdf'
    ));
    resourcesList.push(new ResourceEntry(
      3,
      'This is ...',
      'This is optional description 2',
      'https://www.kakaposystems.com/files/Unity-Product-Description-Comparison-Table.pdf'
    ));
    resourcesList.push(new ResourceEntry(
      4,
      'This is the main name of the resource',
      '',
      'https://www.kakaposystems.com/files/Unity-Product-Description-Comparison-Table.pdf'
    ));

    return resourcesList;
  }

  loadReplies() {
    // demo 
    var respliesList: Array<ReplyEntry> = [];
    respliesList.push(new ReplyEntry(
      1,
      'Data protection',
      'This is optional description 1',
      'Text of the reply ..'
    ));
    respliesList.push(new ReplyEntry(
      2,
      'Data protection 2',
      'This is optional description 2',
      'Text of the reply 2..'
    ));
    return respliesList
  }


  loadRepliesEmail() { // c8  includes html in text and description, description can be different from insterted text
    // demo 
    var respliesList: Array<ReplyEntry> = [];
    respliesList.push(new ReplyEntry(
      1,
      'Data protection',
      '<p>This is optional description 1</p>',
      '<p>This is optional description 1</p>'
    ));
    respliesList.push(new ReplyEntry(
      2,
      'Data protection 2',
      `
      <p>For data protection purposes, could you please confirm your name, date of birth and post code?</p>
      <h3>News</h3>
      <p><a href="https://www.kakaposystems.com/kakapo-systems-launch-sms-queues-for-unity-contact-center"><img style="height: 225px; width: 400px;" src="https://www.kakaposystems.com/files/Primary-Image-for-SMS-PR-400x225.jpg" alt="SMS Queues for BroadSoft" /></a></p>
      <h3><a href="https://www.kakaposystems.com/kakapo-systems-launch-sms-queues-for-unity-contact-center">Kakapo Systems Launch SMS Queues for Unity Contact Center</a></h3>
      <p>On 28 Sep, 2020</p>
      
      <p>Post by: Team Kakapo</p>
      
      <p>LONDON, UNITED KINGDOM, September 28, 2020 /EINPresswire.com/ — Kakapo Systems,</p>
      
      <p>&nbsp;</p>
      
      <p><a href="https://www.kakaposystems.com/new-portal-dashboards-for-contact-center">NEW: Portal Dashboards for Contact Center</a></p>
      
      <p>On 22 Jul, 2020</p>
      
      <p>Post by: Team Kakapo</p>
      
      <p>Configure, report and visualise everything that’s happening in Unity Contact Center with the new Portal Dashboards</p>
      
      <p><a href="https://www.kakaposystems.com/new-portal-dashboards-for-contact-center">Read More</a></p>
      
      <p><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard"><img style="height: 249px; width: 400px;" src="https://www.kakaposystems.com/files/1-400x249.jpg" alt="dashboard tiles" /></a></p>
      
      <h3><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard">New Licensing for Unity Dashboard</a></h3>
      <p>On 11 Mar, 2020</p>
      
      <p>Post by: Team Kakapo</p>
      
      <p>&nbsp;</p>
      
      <p>Unity Dashboard can now be licensed against the User ID</p>
      
      <p>&nbsp;</p>
      
      <p><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard">Read More</a></p>
        
      `,
      `
      <p>For data protection purposes, could you please confirm your name, date of birth and post code?</p>
      <h3>News</h3>
      <p><a href="https://www.kakaposystems.com/kakapo-systems-launch-sms-queues-for-unity-contact-center"><img style="height: 225px; width: 400px;" src="https://www.kakaposystems.com/files/Primary-Image-for-SMS-PR-400x225.jpg" alt="SMS Queues for BroadSoft" /></a></p>
      <h3><a href="https://www.kakaposystems.com/kakapo-systems-launch-sms-queues-for-unity-contact-center">Kakapo Systems Launch SMS Queues for Unity Contact Center</a></h3>
      <p>On 28 Sep, 2020</p>

      <p>Post by: Team Kakapo</p>

      <p>LONDON, UNITED KINGDOM, September 28, 2020 /EINPresswire.com/ — Kakapo Systems,</p>

      <p>&nbsp;</p>

      <p><a href="https://www.kakaposystems.com/new-portal-dashboards-for-contact-center">NEW: Portal Dashboards for Contact Center</a></p>

      <p>On 22 Jul, 2020</p>

      <p>Post by: Team Kakapo</p>

      <p>Configure, report and visualise everything that’s happening in Unity Contact Center with the new Portal Dashboards</p>

      <p><a href="https://www.kakaposystems.com/new-portal-dashboards-for-contact-center">Read More</a></p>

      <p><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard"><img style="height: 249px; width: 400px;" src="https://www.kakaposystems.com/files/1-400x249.jpg" alt="dashboard tiles" /></a></p>

      <h3><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard">New Licensing for Unity Dashboard</a></h3>
      <p>On 11 Mar, 2020</p>

      <p>Post by: Team Kakapo</p>

      <p>&nbsp;</p>

      <p>Unity Dashboard can now be licensed against the User ID</p>

      <p>&nbsp;</p>

      <p><a href="https://www.kakaposystems.com/new-licensing-for-unity-dashboard">Read More</a></p>
      `
    ));
    respliesList.push(new ReplyEntry(
      3,
      'Data protection 3',
      '<p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p>',
      '<p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p><p>this is HTML file, Text of the reply 2..</p>'
    ));
    return respliesList
  }

  urlsToLinks(inputText) {

    var isImgLink = /https?:\/\/.*\.(?:png|jpg|gif|jpeg)/i;
    var isVideoLink = /https?:\/\/.*\.(?:mp4|ogg|WebM)/i;

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var replacedText = inputText.replace(replacePattern2, '$1 http://$2');

    //URLs starting with http://, https://, or ftp://
    var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = replacedText.replace(replacePattern1, function (url) {

      if (url.match(isImgLink)) {
        return '<a target="_blank" href="' + url + '"><img src="' + url + '" /></a>';
      }

      if (url.match(isVideoLink)) {
        var type = url.split('.').pop().toLowerCase();
        return '<video width="100%" controls><source src="' + url + '" type="video/' + type + '"></video>';
      }

      return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });



    //Change email addresses to mailto:: links
    var replacePattern3 = /(([a-zA-Z0-9_\-\.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText
  }

  parseMessage(message) {
    // emails
    //var re = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/);
    //var aTag = "<a href=mailto:$1>$1</a>";
    //message = message.replace(re, aTag);

    // images to be shown

    // video to be shown



    // links in general C8
    message = this.urlsToLinks(message)


    for (var className in this.emojis) {
      var codes = this.emojis[className];
      for (var e in codes) {
        message = message.replaceAll('' + codes[e] + '', ' <span class="emoji ' + className + '">&nbsp;</span> ');
        //message = message.replace(new RegExp(codes[e], 'g'), ' <span class="emoji ' + className + '">&nbsp;</span> ');
      }
    }

    return message;
  }

  escapeSpecialChars(regex) {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1');
  }

  // email
  openEmailDialog = new Subject<any>();
  prependEmailHtml = new Subject<any>();
  addEmailAttachment = new Subject<any>();

  // upload files on paste
  uploadFileOnPaste = new Subject<any>();

  // upload progress
  fileUploadProgress: number = 0;

  uploadFile(file) {
    console.log(file);

    // demo
    var count = 0;
    var counting = setInterval((function () {
      if (count < 101) {
        this.fileUploadProgress = count;
        count += 10
      } else {
        this.fileUploadProgress = 0;
        clearInterval(counting)
      }
    }).bind(this), 300);


    var url = 'https://image.shutterstock.com/image-photo/word-demo-appearing-behind-torn-260nw-1782295403.jpg?v=' + Math.random();

    return new Attachment(0, file.name, Date.now(), 99, url, file.size);
  }

}