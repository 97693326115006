import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../providers/settings/settings-general/settings-general.service';


@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class UpdateComponent implements OnInit {
description:string="";
autoUpdate:string="";
checkForUpdateEvery:string="";
hours:string="";
alertMe:string="";
autoRestart:string="";
performManualUpdateNow:string="";
  constructor(public settingsGeneral:SettingsGeneral) { }

  ngOnInit() {
       this.description= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.Description');
       this.autoUpdate= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.AutoUpdate');
       this.checkForUpdateEvery= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.AutoUpdate.CheckForUpdateEvery');
       this.hours= this.settingsGeneral.contextService.getLanguageTagByKey('Misc.Hours');
       this.alertMe= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.AlertMe');
       this.autoRestart= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.AutoRestart');
       this.performManualUpdateNow= this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Update.PerformManualUpdateNow');
       this.settingsGeneral.settingsChanged=true;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
