import { Threshold } from "./threshold";
export class AgentStatistics {
    public statToDisplay: string;
    public statName:string;
    public columnHeader: string;
    public setThreshold: boolean;
    public threshold: Threshold;
    constructor(statToDisplay: string,statName:string, columnHeader: string, setThreshold: boolean) {
        this.statName=statName;
        this.statToDisplay = statToDisplay;
        this.columnHeader = columnHeader;
        this.setThreshold = setThreshold;
        // if(this.isAgentStat){
            // this.thresholdList=[];
        // } else{
            // this.callCentersList={};
        // }

    }



}