import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { ContextService } from '../../../../../providers/context.service';
import { CallForwardingBusyService } from '../../../../../providers/settings/settings-services/call-forwarding-busy.service';

/**
 * Used to store CallForwardingBusyComponent properties and this serves as a interface between component and calllogs service
 * 
 * @export
 * @class CallLogsComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
    selector: 'app-call-forwarding-busy',
    templateUrl: './call-forwarding-busy.component.html',
    styleUrls: ['./call-forwarding-busy.component.scss']
})

export class CallForwardingBusyComponent extends BaseComponent implements OnInit {
    description: String = "";
    enabled: String = "";
    forwardNumber: String = "";
    constructor(private contextService: ContextService, public callForwardingBusyService: CallForwardingBusyService) {
        super();
    }
    ngOnInit() {
        this.callForwardingBusyService.busyChanges = true;
        this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallForwardBusy.Description');
        this.forwardNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
        this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    }

    focus() {
        document.getElementById("textarea").focus();
    }
}