import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/takeUntil';
import { timer, Subscription, Subject } from "rxjs";
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { DirectoryService } from '../../providers/directory.service';
import { HelperService } from '../../providers/helper.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

import { ResourceEntry } from '../../model/resource-entry.model'
import { ReplyEntry } from '../../model/reply-entry.model'
import { TwitterMessage } from '../../model/twitter-message.model'
import { Attachment } from '../../model/attachment.model';
//import { ChatConversation } from "app/model/chat-conversation.model";

@Component({
  selector: 'app-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TwitterComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

  twitterModalVisible: boolean = false;
  title: string = "";
  titleTwitter: string = "";
  chanel: string = "";
  reserveBtn: string = "";
  rejectBtn: string = "";
  transferBtn: string = "";
  scheduleBtn: string = "";
  closeBtn: string = "";
  sendBtn: string = "";

  conversation: string = "";
  cannedReplies: string = "";
  resources: string = "";

  conversationBtn: string = "";
  respliesBtn: string = "";
  resourcesBtn: string = ""
  attachmentBtn: string = "";

  selectedTab: string = 'conversation';

  reserved: boolean = false;
  transferToList: MenuItem[] = [];

  messages: Array<TwitterMessage>;

  resourcesList: Array<ResourceEntry> = [];
  respliesList: Array<ReplyEntry> = [];
  //conversations: Array<ChatConversation> = [];

  predefinedMessage: string = "";

  //demo  
  startedDateTime: Date = new Date();
  countDown: Subscription;
  tick = 1000;

  constructor(
    private helper: HelperService, public directoryService: DirectoryService, public contextService: ContextService,
    public messagesService: MessagesService, private cd: ChangeDetectorRef
  ) {
    this.cd.detach();
  }

  ngOnInit() {

    this.messagesService.uploadFileOnPaste.subscribe(attachments => {
      if (this.twitterModalVisible && this.reserved) { // this is open, act here 
        var append = '';
        for (var i = 0; i < attachments.length; i++) {
          //this.addMessage('', attachments[i])  
          append += attachments[i].url + ' ';
        }
        this.predefinedMessage = append;
      }
    })

    this.messagesService.openTwitterDialog.takeUntil(this.ngUnsubscribe).subscribe((open) => {
      this.cd.reattach();
      this.twitterModalVisible = open
      if (open) {
        // scroll down
        this.scrollToLatest();
      }

      this.titleTwitter = this.contextService.getLanguageTagByKey('Twitter.Title');
      this.reserveBtn = this.contextService.getLanguageTagByKey('Messages.Reserve');
      this.rejectBtn = this.contextService.getLanguageTagByKey('Messages.Reject');
      this.transferBtn = this.contextService.getLanguageTagByKey('Messages.Transfer');
      this.closeBtn = this.contextService.getLanguageTagByKey('Messages.Close');
      this.sendBtn = this.contextService.getLanguageTagByKey('Twitter.SendDirect');

      this.conversationBtn = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.respliesBtn = this.contextService.getLanguageTagByKey('Messages.Replies');
      this.resourcesBtn = this.contextService.getLanguageTagByKey('Messages.Resources');
      this.attachmentBtn = this.contextService.getLanguageTagByKey('Messages.AddAttachment');

      this.conversation = this.contextService.getLanguageTagByKey('Messages.Conversation');
      this.cannedReplies = this.contextService.getLanguageTagByKey('Messages.CannedReplies');
      this.resources = this.contextService.getLanguageTagByKey('Messages.Resources');

      // demo 
      this.loadMessages();
      this.loadResources();
      this.loadReplies();
      this.transferToList = this.messagesService.loadTransferToList(this.transferTo)

      this.countDown = timer(0, this.tick).subscribe(() => this.setTitlte());
    })
  }

  setTitlte() { // demo  
    this.chanel = 'Ross and Zara'
    this.title = this.conversation + ': Ross and Zara Fashion > ' + this.chanel + ' - ';
    this.title += this.messagesService.millisToMinutesAndSeconds(new Date().getTime() - this.startedDateTime.getTime());
  }

  loadMessages() {
    // demo   
    this.messages = [
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!! <a href="https://twitter.com/Ross_and_Zara">@Ross &amp; Zara</a>'),
        Date.now(),
        99,
        'Elon Musk',
        'elonmusk',
        'https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!!'),
        Date.now(),
        99,
        'Ross & Zara',
        'Ross_and_Zara',
        'https://pbs.twimg.com/profile_images/1253624788381315072/XewKqq67_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!! <a href="https://twitter.com/Ross_and_Zara">@Ross &amp; Zara</a>'),
        Date.now(),
        99,
        'Elon Musk',
        'elonmusk',
        'https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!!'),
        Date.now(),
        99,
        'Ross & Zara',
        'Ross_and_Zara',
        'https://pbs.twimg.com/profile_images/1253624788381315072/XewKqq67_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!! <a href="https://twitter.com/Ross_and_Zara">@Ross &amp; Zara</a>'),
        Date.now(),
        99,
        'Elon Musk',
        'elonmusk',
        'https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!!'),
        Date.now(),
        99,
        'Ross & Zara',
        'Ross_and_Zara',
        'https://pbs.twimg.com/profile_images/1253624788381315072/XewKqq67_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!! <a href="https://twitter.com/Ross_and_Zara">@Ross &amp; Zara</a>'),
        Date.now(),
        99,
        'Elon Musk',
        'elonmusk',
        'https://pbs.twimg.com/profile_images/1503591435324563456/foUrqiEw_200x200.jpg'
      ),
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage('Hey...!!!'),
        Date.now(),
        99,
        'Ross & Zara',
        'Ross_and_Zara',
        'https://pbs.twimg.com/profile_images/1253624788381315072/XewKqq67_200x200.jpg'
      ),
    ];

  }

  loadResources() {
    // demo 
    this.resourcesList = this.messagesService.loadResources();
  }

  loadReplies() {
    // demo 
    this.respliesList = this.messagesService.loadReplies();
  }

  repliesRightClick(text: string) {
    this.predefinedMessage = text;
    this.showConversation()
  };

  repliesDoubleClick(text: string) {
    this.predefinedMessage = text;
    this.showConversation()
  };

  resourcesDoubleClick(att: Attachment) {
    //this.addMessage('<a href="' + att.url + '" target="_blank">' + att.url + '</a>', null);
    //this.predefinedMessage = '<a href="' + att.url + '" target="_blank">' + att.url + '</a>';
    this.predefinedMessage = att.url + ' ';
    this.showConversation()
  };

  resourcesRightClick(att: Attachment) {
    this.resourcesDoubleClick(att)
  };

  onMessageSubmit = (message: string, attachment: Attachment | null): void => {
    this.addMessage(message, attachment);
    this.showConversation()
  }

  addAttachmentUrl(att: Attachment) {
    return ' <a href="' + att.url + '" target="_blank">' + att.name + '</a>'
  }


  sendDirectMessageRequest(event) {
    event.preventDefault();
    event.stopPropagation();

    this.addMessage('<a href="#" target="_blank">Direct message invitation sent</a>', null);
  }

  addMessage = (message: string, attachment: Attachment | null) => {

    // attachments as urls ?
    if (attachment && attachment.url) {
      message += this.addAttachmentUrl(attachment)
    }

    this.messages.push(
      new TwitterMessage(
        Math.random(),
        true,
        this.messagesService.parseMessage(message),
        Date.now(),
        99,
        'Ross & Zara',
        'Ross_and_Zara',
        'https://pbs.twimg.com/profile_images/1253624788381315072/XewKqq67_200x200.jpg',
        attachment
      )
    );

    this.scrollToLatest();
  }

  scrollToLatest(timeout = null) {
    this.messagesService.scrollToBottom('twitter-scroll-pane', timeout);
  }

  showConversation(event = null) {
    this.selectTab(event, 'conversation')
    this.scrollToLatest();
  }

  openTransferToCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    cm.show(event);
    return false;
  }

  transferTo(person) {

  }

  reserve(event) {
    event.preventDefault();
    event.stopPropagation();

    this.reserved = true;
    this.contextService.log("reserved tw");

    return false;
  }

  reject(event) {
    event.preventDefault();
    event.stopPropagation();

    this.twitterModalVisible = false;
    this.contextService.log("rejected tw");


    return false;
  }

  transfer(event) {
    event.preventDefault();
    event.stopPropagation();

    this.contextService.log("transfer tw");

    return false;
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();

    this.twitterModalVisible = false;
    this.contextService.log("close tw");

    return false;
  }


  selectTab(e, tab) {
    if (e) {
      e.preventDefault();
    }
    this.selectedTab = tab;
  }

  closeModal() {
    this.twitterModalVisible = false;
    setTimeout(() => { this.cd.detach(); });
  }

  ngOnDestroy() {
    this.countDown = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
