import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CallForwardingAlways } from '../../../model/bw-services/call-forwarding-always';
import { ContextService } from '../../../providers/context.service';
import { HelperService } from '../../../providers/helper.service';
import { BwServices } from './bw-services.service';

/**
 * service for CFA
 * 
 * @export
 * @class CallForwardningAlwaysService
 */
@Injectable()
export class CallForwardningAlwaysService {

    isActive: boolean = false;
    isRingSplash: boolean = false;
    phNumber: string = "";
    numberValidation: boolean = false;
    callForwardingAlways: CallForwardingAlways;
    cfaChanges: boolean = false;
    constructor(private helper: HelperService, private bwServices: BwServices, public contextService: ContextService, private router: Router) {
        this.contextService.log('###### constructor - CallForwardningAlwaysService');
    }
    setData(callForwardingAlways: CallForwardingAlways) {
    }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) {
    }
    save() {      
    }
    populateData() { 
    }
    cancel() {     
    }
    activateCFA() {   
    }
    deactivateCFA() {
    }
}