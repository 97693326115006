<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{enabled}} [(ngModel)]="voiceMailService.isActive" binary="true"></p-checkbox>
</div>
<div>
  <div class="row">
    <p-radioButton name="groupname" value="Unified Voice and Email Messaging" label={{useUnifiedMessaging}} [(ngModel)]="voiceMailService.processing" 
      styleClass="big-radio" [disabled]="!voiceMailService.isActive"></p-radioButton>
  </div>
  <div class="row">
    <div class="row">
      <p-checkbox name="checkboxGroup1" value="checkbox" label={{usePhoneMessageWaitingIndicator}} [(ngModel)]="voiceMailService.usePhoneMessageWaiting"
        binary="true" [disabled]="!voiceMailService.isActive||voiceMailService.processing ==='Deliver To Email Address Only'"></p-checkbox>
    </div>
  </div>
  <div class="row">
    <div class="row">
      <p-checkbox name="checkboxGroup1" value="checkbox" label={{showVisualVoiceMailTab}} [(ngModel)]="voiceMailService.showingVoicemailTab" [disabled]="!voiceMailService.isActive || voiceMailService.processing ==='Deliver To Email Address Only'"
        binary="true"></p-checkbox>
    </div> 
  </div>
  <div class="row">
    <p-radioButton name="groupname" (click)="this.focus('messageDelivery')" value="Deliver To Email Address Only" label={{forwardTheVoicemailViaEmail}} [(ngModel)]="voiceMailService.processing"
      styleClass="big-radio" [disabled]="!voiceMailService.isActive"></p-radioButton>
  </div>

</div>
<div class="row">
  <label for="" class="label"></label>
  <input type="text" id="messageDelivery" name="input-text" [(ngModel)]="voiceMailService.voiceMessageDeliveryEmailAddress" [disabled]="!voiceMailService.isActive||voiceMailService.processing ==='Unified Voice and Email Messaging'">
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)="this.focus('voiceEmailAddress')" label={{emailNotificationOfNewVoicemails}} [(ngModel)]="voiceMailService.sendVoiceMessageNotifyEmail"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label"></label>
  <input type="text" id="voiceEmailAddress" name="input-text" [(ngModel)]="voiceMailService.voiceMessageNotifyEmailAddress" [disabled]="!voiceMailService.sendVoiceMessageNotifyEmail||!voiceMailService.isActive">
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)="this.focus('carbonCopy')" label={{emailACopyOfTheVoicemails}} [(ngModel)]="voiceMailService.sendCarbonCopyVoiceMessage"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label"></label>
  <input type="text" id="carbonCopy" name="input-text" [(ngModel)]="voiceMailService.voiceMessageCarbonCopyEmailAddress" [disabled]="!voiceMailService.sendCarbonCopyVoiceMessage||!voiceMailService.isActive">
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)="this.focus('fromVoicemail')" label={{transferFromVoicemailOnZero}} [(ngModel)]="voiceMailService.transferOnZeroToPhoneNumber"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label"></label>
  <input type="text" id="fromVoicemail" name="input-text" [(ngModel)]="voiceMailService.transferPhoneNumber" [disabled]="!voiceMailService.transferOnZeroToPhoneNumber||!voiceMailService.isActive" autocomplete=off>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{sendAllCallsToVoicemail}} [(ngModel)]="voiceMailService.alwaysRedirectToVoiceMail"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{sendBusyCallsToVoicemail}} [(ngModel)]="voiceMailService.busyRedirectToVoiceMail"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{sendUnansweredCallsToVoicemail}} [(ngModel)]="voiceMailService.noAnswerRedirectToVoiceMail"
    binary="true" [disabled]="!voiceMailService.isActive"></p-checkbox>
</div>