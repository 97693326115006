<!-- <p-dialog [(visible)]="dialVis" [closable]="false" width="320" class="dial-dialog"  [ngClass]="{visible: dialVis}" dismissableMask="true" showHeader="false" (onShow)="focusInput()"> -->
  <p-dialog [(visible)]="dialVis" [closable]="false" class="dial-dialog" [resizable]="false" [style]="{'width':'320px'}" [ngClass]="{visible: dialVis}" dismissableMask="true" showHeader="false" (onShow)="focusInput()">
  <div class="modal-content">
      <form (ngSubmit)="onSubmitCall()" [formGroup]="dialForm" #dfRef="ngForm" id="dialForm">
        <span class="close-modal small" (click)="closeKeyboard()">X</span>
        <p>{{phoneNumber}}</p>
        <div class="num-display">
          <input type="tel" id="txtDial" name="num-display" autocomplete=off formControlName="dialDisplayValue" #dialDisplay pattern="^(?=.*\d).{1,30}$" onKeyPress="if(this.value.length==30) return false;">
          <span class="del" (click)="deleteChar()"></span>
        </div>
        <div class="error-msg" id="dialValidation" *ngIf="!dialForm.get('dialDisplayValue').valid && dialForm.get('dialDisplayValue').touched && dialForm.get('dialDisplayValue').dirty">Number is not valid</div>
        <div class="keyboard">
          <a *ngFor="let k of keyboardBtns"><span (click)="keyClick($event)" attr.data-key="{{k}}">{{k}}</span></a>
        </div>
        <div class="dial">
          <button type="submit" id="btnCall" class="button-call" [disabled]="!dialForm.valid"><span>{{call}}</span></button>
        </div>
      </form>
    </div>
</p-dialog>