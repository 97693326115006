<div class="conversation">
  <fieldset [ngClass]="{ disabled: !isValidMessage() }">
    <textarea
      name="message"
      required
      [(ngModel)]="message"
      placeholder="{{ writeMessageHere }}"
      rows="7"
      [maxLength]="500"
      (keyup)="onKeyUp($event)"
      #textInput
    ></textarea>
    <div class="send-upload">
      <button type="button" (click)="onSubmit()" class="btn-blue-light">
        {{ sendlbl }}
      </button>
      <app-attach-file
        *ngIf="disableAttachments !== true"
        (onFileSelect)="onFileSelect($event)"
        [componentName]="componentName"
      ></app-attach-file>
    </div>
  </fieldset>

  <app-emojis
    (onRightClick)="emojiRightClick($event)"
    (onDoubleClick)="emojiDoubleClick($event)"
    (onSingleClick)="emojiSingleClick($event)"
  ></app-emojis>

  <div class="message-attachments" *ngIf="attachments.length > 0">
    <ul class="message-attachments-list">
      <li *ngFor="let attachment of attachments; let i = index">
        <app-attachment [attachment]="attachment"></app-attachment>
      </li>
    </ul>
  </div>
</div>
