<div class="row">
  <p>
    {{description}}
    <p>
</div>

<div class="row">
  <div class="box">

    <p-table [value]="bWAnywhereService.criteriaArray" selectionMode="single" [(selection)]="selectedNumber" dataKey="id" >
      <ng-template pTemplate="header">
        <th>{{criteriaDescription}}</th>
        <th>{{schedule}}</th>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr (dblclick)="doubleClick(rowData)" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData">
          <td>
            {{rowData.number}}
          </td>
          <td>
              {{rowData.schedule}}
            </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="deleteCriteria()">{{delete}}</a>
        <a href="javascript:void(0);" (click)="bWAnywhereService.addNewCriteria()">{{plus}}</a>
      </div>
    </div>
  </div>
</div>
  <p-dialog [resizable]="false" [(visible)]="deleteCriteriaWindow" [closable]="false" [style]="{'width':'370px'}" class="close-dialog" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{confirm}}</p>
    <div>
      <button type="button" class="btn-white" (click)="deleteCriteriaWindow = false">{{miscNo}}</button>
      <button type="button" class="btn-blue-light" (click)="deleteCriteriaLocation()">{{miscYes}}</button>
    </div>
  </div>
</p-dialog>
