import { CallLogEntry } from '../../model/call-logs/call-log-entry';
import { CallType } from '../../model/call-logs/call-type';
import { ContextService } from '../../providers/context.service';
import { ResponseHandler } from '../../shared/ResponseHandler';


export class CallLogsResponseHandler implements ResponseHandler {

    type = "GetCallLogsResponse";
    public callLogsListDial: Array<CallLogEntry>;
    firstTime: boolean = true;
    constructor(public contextService: ContextService) {
        this.contextService.log('###### constructor - CallLogsResponseHandler');
    }

    handle(xmlResponse: Document): void {
        this.contextService.log("****** handle - GetCallLogsResponse");
        let tempDate = new Date(null);
        let countryCode: string = "", phoneNumber: string = "", name = "", time: string = "", callLogId: string = "";
        let callLogsEntry;
        let i = 0;
        let placedCalls = xmlResponse.getElementsByTagName("placed")[0];
        if (placedCalls) {
            for (i = 0; i < placedCalls.getElementsByTagName("callLogsEntry").length; i++) {
                callLogsEntry = placedCalls.getElementsByTagName("callLogsEntry")[i];
                if (callLogsEntry.getElementsByTagName("countryCode").length > 0) {
                    countryCode = callLogsEntry.getElementsByTagName("countryCode")[0].textContent;
                }

                if (callLogsEntry.getElementsByTagName("phoneNumber").length > 0) {
                    phoneNumber = callLogsEntry.getElementsByTagName("phoneNumber")[0].textContent;
                }

                if (callLogsEntry.getElementsByTagName("name").length > 0) {
                    name = callLogsEntry.getElementsByTagName("name")[0].textContent;
                }

                if (callLogsEntry.getElementsByTagName("time").length > 0) {
                    time = callLogsEntry.getElementsByTagName("time")[0].textContent;
                }

                if (callLogsEntry.getElementsByTagName("callLogId").length > 0) {
                    callLogId = callLogsEntry.getElementsByTagName("callLogId")[0].textContent;
                }

                if (name === 'Unavailable') {
                    let tempName = this.contextService.contactLookUp(phoneNumber);
                    // if (tempName !== phoneNumber) {
                    name = tempName;
                    // }
                }
                this.contextService.callLogsList.push(new CallLogEntry(name, countryCode, phoneNumber, new Date(time), callLogId, "", CallType.DIALED));
            }
        }

        let recevedCalls = xmlResponse.getElementsByTagName("received")[0];
        countryCode = "", phoneNumber = "", name = "", time = "", callLogId = "";
        if (recevedCalls) {
            for (i = 0; i < recevedCalls.getElementsByTagName("callLogsEntry").length; i++) {
                callLogsEntry = recevedCalls.getElementsByTagName("callLogsEntry")[i];
                if (callLogsEntry.getElementsByTagName("countryCode").length > 0) {
                    countryCode = callLogsEntry.getElementsByTagName("countryCode")[0].textContent;
                }
                if (callLogsEntry.getElementsByTagName("phoneNumber").length > 0) {
                    phoneNumber = callLogsEntry.getElementsByTagName("phoneNumber")[0].textContent;
                }
                if (callLogsEntry.getElementsByTagName("name").length > 0) {
                    name = callLogsEntry.getElementsByTagName("name")[0].textContent;
                }
                let d = new Date();
                if (callLogsEntry.getElementsByTagName("time").length > 0) {
                    time = callLogsEntry.getElementsByTagName("time")[0].textContent;
                    d = new Date(time);
                }
                if (callLogsEntry.getElementsByTagName("callLogId").length > 0) {
                    callLogId = callLogsEntry.getElementsByTagName("callLogId")[0].textContent;
                }
                if (name === 'Unavailable') {
                    let tempName = this.contextService.contactLookUp(phoneNumber);
                    // if (tempName !== phoneNumber) {
                    name = tempName;
                    // }
                }
                this.contextService.callLogsList.push(new CallLogEntry(name, countryCode, phoneNumber, d, callLogId, "", CallType.RECEIVED));
            }
        }

        let missedcalls = xmlResponse.getElementsByTagName("missed")[0];
        countryCode = "", phoneNumber = "", name = "", time = "", callLogId = "";
        if (missedcalls) {
            for (i = 0; i < missedcalls.getElementsByTagName("callLogsEntry").length; i++) {
                callLogsEntry = missedcalls.getElementsByTagName("callLogsEntry")[i];
                if (callLogsEntry.getElementsByTagName("countryCode").length > 0) {
                    countryCode = callLogsEntry.getElementsByTagName("countryCode")[0].textContent;
                }
                if (callLogsEntry.getElementsByTagName("phoneNumber").length > 0) {
                    phoneNumber = callLogsEntry.getElementsByTagName("phoneNumber")[0].textContent;
                }
                if (callLogsEntry.getElementsByTagName("name").length > 0) {
                    name = callLogsEntry.getElementsByTagName("name")[0].textContent;
                }
                let d = new Date();
                if (callLogsEntry.getElementsByTagName("time").length > 0) {
                    time = callLogsEntry.getElementsByTagName("time")[0].textContent;
                    d = new Date(time);
                }
                if (callLogsEntry.getElementsByTagName("callLogId").length > 0) {
                    callLogId = callLogsEntry.getElementsByTagName("callLogId")[0].textContent;
                }
                if (this.contextService.isElectron) {
                    if (this.contextService.objSettings.shutdownTime !== null) {
                        if ((this.contextService.objSettings.shutdownTime < d) && (this.contextService.objSettings.shutdownTime.toString() != tempDate.toString())) {
                            this.contextService.missedCallCount++;
                        }
                    }
                }
                if (name === 'Unavailable') {
                    let tempName = this.contextService.contactLookUp(phoneNumber);
                    // if (tempName !== phoneNumber) {
                    name = tempName;
                    // }
                }
                this.contextService.callLogsList.push(new CallLogEntry(name, countryCode, phoneNumber, d, callLogId, "", CallType.MISSED));
            }
        }
        this.contextService.updatecalllogs.next();
    }

}




