import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import {  Subscription } from 'rxjs';
import { AgentActivityCCWise, AgentActivitystats } from 'app/model/supervisor/agentActivitystats';
import { ContextService } from './context.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class AgentactivityService {
  agentActivityTabRadioButonValue: any = 'Queues';
  agentActivityTabDropDownValue: string = "";
  a: Array<AgentActivityCCWise> = [];
  statToDisplay: Array<AgentActivitystats> = [];
  summaryStat: Array<AgentActivitystats> = []

  sub: Subscription = new Subscription();
  isSubscription: boolean = false;
  constructor(private helper: HelperService, public contextService: ContextService) {
    this.summaryStat.push(new AgentActivitystats("Summary", "", "", this.contextService));
    this.summaryStat[0].clearAllValues();

    this.contextService.signout.subscribe(val => {
      this.timerUnsubcribe();
      this.statToDisplay = [];
      this.summaryStat[0].clearAllValues();
      this.contextService.agentStatisticsRaw = [];
    });

    this.contextService.agentAactivityTableUpdation.subscribe((userid) => {
      // todo
      // logic need to be changed????

      if (this.agentActivityTabDropDownValue != "") {
        // if (userid == "JoinUpdate") {
        //   if (this.agentActivityTabRadioButonValue == 'Queues') {
        //     this.filterAccordingToCC(this.agentActivityTabDropDownValue);
        //   } else if (this.agentActivityTabRadioButonValue == 'Agents') {
        //     this.filterAccordingToAgent(this.agentActivityTabDropDownValue);
        //   }
        // } else
        if (userid == "sizeReCalc") {
          this.helper.recalcagentactivityHeight.next();
        } else if (userid == "totalUpdate") {
          // this.setThreshold();
          if (this.agentActivityTabRadioButonValue == 'Queues') {
            // this.setStatisticsAccordingToCC();
            this.filterAccordingToCC(this.agentActivityTabDropDownValue);
          } else if (this.agentActivityTabRadioButonValue == 'Agents') {
            // this.setStatisticsAccordingToAgent(this.agentActivityTabDropDownValue);
            this.filterAccordingToAgent(this.agentActivityTabDropDownValue);
          }
        } else {
          if (this.agentActivityTabRadioButonValue == 'Queues') {
            for (let index = 0; index < this.statToDisplay.length; index++) {
              if (this.statToDisplay[index].userID == userid) {
                this.setStatisticsAccordingToCC(userid);
                break
              }
            }
          } else if (this.agentActivityTabRadioButonValue == 'Agents') {
            if (this.agentActivityTabDropDownValue == userid) {
              this.setStatisticsAccordingToAgent(userid);
            }
          }
        }
      }

    });

    this.contextService.commonObservable.subscribe(val => {
      if (val == "autoReconnect") {
        this.timerUnsubcribe();
      }
    });
  }


  filterAccordingToCC(ccID: string) {
   
  }
  filterAccordingToAgent(userID: string) {
   
  }
  setThreshold() {
   
  }
  setStatisticsAccordingToCC(userID?: string) {
    //todo when the radiobutton is "queues"
    // set values of agents in a call center (status,online,unavailability reason)
    //if userid present then only for that particular agent else for all agents
    

  }
  setStatisticsAccordingToAgent(userID: string) {
    //todo when the radiobutton is "agent"
    // set values of selected agent (status,online,unavailability reason)
    // this.calculateStatisticsOfAnAgent();
   
  }
  calculateStatisticsOfAnAgent(userID: string, callcenterId: string) {
   
  }
  calculateSummaryStatistics() {
    
  }
  timerForAgentActivity() {
   
  }

  timerTestSetUp() {
    this.contextService.log("AgentActivity::timerTestSetUp");

    let time = timer(0, 1000);
    if (!this.isSubscription) {

      this.isSubscription = true;
      this.sub = time.subscribe(
        () => {
          this.timerForAgentActivity();
        }
      );
    }
  }

  timerUnsubcribe() {
    this.contextService.log("PW::timerUnsubcribe");
    if (!this.sub.closed) {
      this.isSubscription = false;
      this.sub.unsubscribe();
    }
  }
  setId() {
    let id_value: number = 1;
    this.statToDisplay.forEach(element => {
      element.id = id_value;
      id_value++;

    });
  }
  /**
   *https://javascript.plainenglish.io/deep-clone-an-object-and-preserve-its-type-with-typescript-d488c35e5574
   *
   * @template T
   * @param {T} source
   * @returns {T}
   * @memberof AgentactivityService
   */
  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
            o[prop] = this.deepCopy(source[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }
}

