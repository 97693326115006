import { ContextService } from '../../../providers/context.service';
import { ResponseHandler } from '../../../shared/ResponseHandler';

export class ACDAgentUnavailableCodesResponseHandler implements ResponseHandler {


    xsp: string = "http://schema.broadsoft.com/XspXMLInterface";
    xsi: string = "http://schema.broadsoft.com/xsi";
    xsi1: string = "http://www.w3.org/2001/XMLSchema-instance";
    type = "ACDAgentUnavailableCodes";


    constructor(private contextService: ContextService) {
    }

    handle(xmlResponse: Document): void {

    }
    sortUnAvailableCodes() {   }
}
