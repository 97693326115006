import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SelectItem } from 'primeng/primeng';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { HelperService } from './../../providers/helper.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  repVis: boolean = false;
  reportForm: FormGroup;
  startDate: Date;
  endDate: Date;
  hours: Array<string> = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'];
  minutes: Array<string> = ['00','15','30','45'];
  startHourOpts: SelectItem[] = [];
  endHourOpts: SelectItem[] = [];
  startMinOpts: SelectItem[] = [];
  endMinOpts: SelectItem[] = [];
  reportOpts: SelectItem[] = [
    {label:'Agent Activity Detail Report', value:{id:1, name: 'Agent Activity Detail Report'}},
    {label:'Agent Activity Report', value:{id:2, name: 'Agent Activity Report'}},
    {label:'Agent Call By Skill Report', value:{id:3, name: 'Agent Call By Skill Report'}},
    {label:'Agent Call Detail Report', value:{id:4, name: 'Agent Call Detail Report'}},
    {label:'Agent Call Report', value:{id:5, name: 'Agent Call Report'}},
    {label:'Agent Duration By Skill Report', value:{id:6, name: 'Agent Duration By Skill Report'}},
    {label:'Agent Duration Report', value:{id:7, name: 'Agent Duration Report'}},
    {label:'Agent Sign In Sign Out Report', value:{id:8, name: 'Agent Sign In Sign Out Report'}},
  ];
  sampling: SelectItem[] = [
    {label:'15 Minutes', value:{id:1, name: '15 Minutes'}},
    {label:'30 Minutes', value:{id:2, name: '30 Minutes'}},
    {label:'Hourly', value:{id:3, name: 'Hourly'}},
  ];
  doctype: string;
  selectedReport: string;
  startHour: string;
  endHour: string;
  startMin: string;
  endMin: string;
  selectedReportID: number;

  constructor(private helper: HelperService,
              private fb: FormBuilder,
              private cd: ChangeDetectorRef) {this.cd.detach();}

  ngOnInit() {
    this.startDate = new Date();
    this.endDate = new Date();
    // populate hour dropdowns
    this.hours.forEach(item => {
      this.startHourOpts.push({label:item, value: item});
      this.endHourOpts.push({label:item, value: item});
    });
    // populate minutes dropdowns
    this.minutes.forEach(item => {
      this.startMinOpts.push({label:item, value: item});
      this.endMinOpts.push({label:item, value: item});
    });
    this.reportForm = this.fb.group({
      selectedReport: [null, Validators.required],
      startDate: [this.startDate, Validators.required],
      endDate: [this.endDate, Validators.required],
      startHour: [null, Validators.required],
      startMin: [null, Validators.required],
      endHour: [null, Validators.required],
      endMin: [null, Validators.required],
      sampling: [null],
      realtime: [null],
      shortDuration: [null],
      callCompletion: [null],
      doctype: ['PDF'],
    });

    this.helper.repVisible.takeUntil(this.ngUnsubscribe).subscribe((val)=>{
      this.cd.reattach();
      this.repVis = val;
    });
  }

  setReportType(e) {
    this.selectedReportID = e.value.id;
  }

  onSubmit() {
    const reportType = this.reportForm.value.selectedReport;
    const doctype = this.reportForm.value.doctype;
    console.log('reportForm: '+doctype);
    // this.reportForm.reset();
  }

  closeReport() {
    this.repVis = false;
    this.helper.closeModal();
    setTimeout(() => {this.cd.detach();});
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}