import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HotelingGuest } from '../../../model/bw-services/hoteling-guest';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
/**
 * service for hoteling guest
 * 
 * @export
 * @class HotelingGuestService
 */
@Injectable()
export class HotelingGuestService {
    isActive: boolean = false;
    enableAssociationLimit: boolean = false;
    associationLimitHours: string = "";
    hostUserId: string = "none";
    hotelingList: any[];
    hotelingLists = [];
    hotelingGuest: HotelingGuest;
    setHostId = new Subject<any>();
    hostList: boolean = false;
    hotelingGuestChanges: boolean = false;
    numberValidation: boolean = false;
    showUnlimitedAssociationText: boolean = false;
    hostAssociationLimitHours: string = "";
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - HotelingGuestService');
    }
    setData(hotelingGuest: HotelingGuest) { }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    save() { }
    populateData() { }
    cancel() { }
    changeHostID() { }
    requestHostList() { }
}