import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../../providers/settings/settings-general/settings-general.service';


@Component({
  selector: 'app-dialing-rule',
  templateUrl: './dialing-rule.component.html',
  styleUrls: ['./dialing-rule.component.scss']
})
export class DialingRuleComponent implements OnInit {

  SettingsComputerPhoneIntegrationdiallingnumbercharacter: string = "";
  SettingsComputerPhoneIgnoreAnyNumbers: string = "";
  SettingsComputerPhoneReplaceNumbersWithinBrackets: string = "";
  SettingsComputerPhoneDialNumbersWithinBrackets: string = "";

  dialRuleDescription: string = "";
  dialRuleBeforeDialing: string = "";
  WithTheseCharacters: string = "";
  Ignore: string = "";
  use: string = "";
  reverse: string = "";


  constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.dialRuleDescription = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.Description');
    this.dialRuleBeforeDialing = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.BeforeDialing');
    this.WithTheseCharacters = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.WithTheseCharacters');
    this.Ignore = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.Ignore');
    this.use = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.Use');
    this.reverse = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Cti.DialRule.Reverse')

    this.SettingsComputerPhoneIntegrationdiallingnumbercharacter = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Computer.Phone.Integration.dialling.number.character');
    this.SettingsComputerPhoneIgnoreAnyNumbers = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Computer.Phone.Ignore.Any.Numbers');
    this.SettingsComputerPhoneReplaceNumbersWithinBrackets = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Computer.Phone.Replace.Numbers.Within.Brackets');
    this.SettingsComputerPhoneDialNumbersWithinBrackets = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Computer.Phone.Dial.Numbers.Within.Brackets');
    this.settingsGeneral.settingsChanged=true;
  }
  /**
   * This method used for text field validation
   * 
   * @param {any} event 
   * @returns 
   * @memberof DialingRuleComponent
   */
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

}
