<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <p><b>
  {{divertAllCallsToVoicemail}}
  </b></p>
</div>
<div class="row">
  <p-checkbox id="chk_frwdNumbers" name="checkboxGroup2" value="checkbox 1" label={{butForwardCallsFromTheseNumbers}} [(ngModel)]="commPilotExpressService.busysendCallsToVoiceMailExceptExcludedAddresses"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <input type="text" id="txt_addNumber" name="input-text" [(ngModel)]="commPilotExpressService.addNumber" [disabled]="!commPilotExpressService.busysendCallsToVoiceMailExceptExcludedAddresses">
</div>
<div class="row">

  <div class="box">
    <p-table [value]="commPilotExpressService.busyexcludedAddress" selectionMode="single" [(selection)]="selectedNumber">
      <ng-template pTemplate="header" >
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr  [pSelectableRow]="rowData">
              <td >
                  {{rowData.number}}
              </td>
          </tr>
      </ng-template>
  </p-table>
    <div class="controls">
      <div class="right">
        <a href="javascript:void(0);" (click)="deleteNumber()">{{delete}}</a>
        <a href="javascript:void(0);" (click)="commPilotExpressService.addBusy()">{{plus}}</a>
      </div>
    </div>
  </div>
</div>

<div class="row">

  {{to}}
  <input type="text" id="txt_busyforwardToAddress" name="input-disabled" [(ngModel)]="commPilotExpressService.busyforwardToAddress" [disabled]="!commPilotExpressService.busysendCallsToVoiceMailExceptExcludedAddresses">
</div>

<div class="row">
  <p-checkbox id="chk_newVoicemails" name="checkboxGroup2" value="checkbox 2" label={{notifyMeOfNewVoicemails}} [(ngModel)]="commPilotExpressService.busysendEmail"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <input type="text" id="txt_busyemailAddress" name="input-text" [(ngModel)]="commPilotExpressService.busyemailAddress" [disabled]="!commPilotExpressService.busysendEmail">
</div>