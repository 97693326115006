<div class="row table-header" style="max-height:30px">
  <span id="EmptySpan" style="width: 15%"></span>

  <span *ngIf="this.showAgentStatHeader" id="MyStatSpan" pTooltip="{{myStatistics}}" showDelay="400" hideDelay="300" tooltipPosition="top">{{myStatistics}}</span>
  <span *ngIf="this.showQueueStatHeader" id="QStatSpan" pTooltip="{{allStatistics}}" showDelay="400" hideDelay="300" tooltipPosition="top">{{allStatistics}}</span>
  <div *ngIf="!this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly" class="toggle-arrow" appDropdown (click)="collapseTables(mystats)">Collapse tables</div>
</div>
<!-- *ngIf="!this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly" -->
<div class="table-devider" #tablesdiv [ngStyle]="{'left':tablesDeviderLeftPos}" [hidden]="!tablesDeviderVis"></div>






<p-table [value]="this.contextService.statistics" selectionMode="single" [(selection)]="selectedCallcenter"
  [contextMenu]="sm" (contextmenu)="insertMenuItemsForSelected(sm)" [(contextMenuSelection)]="selected"
  [resizableColumns]="false" columnResizeMode="fit" [sortField]="sortF" [sortOrder]="sortO" [scrollable]="true"
  scrollHeight="{{scrollHeight}}" [ngClass]="{'no-scroll':scrollBarWidth === '0px','margin-top':'30px'}"
  contextMenuSelectionMode="joint" #mystats id="my-stats-table" (onContextMenuSelect)="contextMenuSelected($event)"
  (onSort)="changeSort($event)" [primeDragula]="bag" [dragulaModel]="this.contextService.statistics"
  [dragulaOptions]="{  childContainerSelector: '#my-stats-table .ui-table-scrollable-body-table .ui-table-tbody', initAfterView: true, copy: false, childContainerSelectorID: 'stats-body'}">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th [pSortableColumn]="'queueName'">Name
        <p-sortIcon [field]="'queueName'"></p-sortIcon>
      </th>
      <th *ngFor="let col of this.contextService.cols;let i=index" [style]="{'width':this.contextService.statColWidth}" pTooltip="{{col.header}}" showDelay="400" hideDelay="300"
        tooltipPosition="top" [pSortableColumn]="col.field">{{col.header}}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr *ngIf="rowData.isDisplayInPersonalWallboard" (click)="rowClick(rowData)" (dblclick)="onRowDblclick(rowData)"
      [pSelectableRowDblClick]="rowData" [pContextMenuRow]="rowData" [pSelectableRow]="rowData">
      <td>
        <span class="queue-{{rowData.icon}}" [attr.data-id]="rowData.id"></span>
      </td>
      <td class="name-cell">
        {{rowData.queueName}}</td>
      <!-- setCellBg(rowData[col.field],col.field,rowData.queueName) -->
      <td #cellColor *ngFor="let col of this.contextService.cols;let i=index"
        [style]="{'width':this.contextService.statColWidth}" [style.background-color]="rowData[col.color]">
        <span
          [style.color]="setCellColor(rowData[col.field], cellColor.style.backgroundColor,col,rowData)">{{rowData[col.field]}}</span>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-table [value]="this.contextService.summaryStatToDisplay" id="my-stats-summary" resizableColumns="false"
  columnResizeMode="fit" [style]="{'margin-right':scrollBarWidth}" [ngClass]="{'no-scroll':scrollBarWidth === '0px'}"
  #statSummary>
  <ng-template pTemplate="header" let-rowData let-columns="columns">
    <tr>
      <td [style.width.px]="statsCellDimension[0]" [style.maxWidth.px]="statsCellDimension[0]"></td>
      <td [style.width.px]="statsCellDimension[1]" [style.maxWidth.px]="statsCellDimension[1]">{{this.summary}}</td>
      <td *ngFor="let col of this.contextService.cols;let i=index" [style.width.px]="statsCellDimension[i+2]"
        [style.maxWidth.px]="statsCellDimension[i+2]">
        {{this.contextService.summaryStatToDisplay[0][col.field]}}
      </td>
    </tr>
  </ng-template>
</p-table>


<p-contextMenu [model]="statsMenuItems" appendTo="body" #sm></p-contextMenu>
<p-contextMenu [model]="afterDropCM" appendTo="body" #afterdropmenustats
  [style]="{'left':afterdropmenuLeft,'top':afterdropmenuTop}"></p-contextMenu>