
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BWDepartment } from "app/model/directory/bw-department";
import { BwDirectoryEntry } from "app/model/directory/bw-directory-entry";
import { BWGroup } from "app/model/directory/bw-group";
import { BWUser } from "app/model/directory/bw-user";
import { ContextService } from "app/providers/context.service";
import { DataService } from "app/providers/dataservice.service";
import { TreeNode } from "primeng";
import { MonitoredUser } from '../../model/directory/monitored-user';
import { HelperService } from './../../providers/helper.service';








// const JSON = require('circular-json');
@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManageUsersComponent implements OnInit {
  enterpriseName: string = "";
  manageUsersVisible: boolean = false;
  data: any[];
  selectedUsers: TreeNode[] = [];
  selectedUsers_temp: TreeNode[] = [];

  usersPicked: TreeNode[] = [];
  deselectedUsers: TreeNode[] = [];


  hierarchy: boolean = true;
  searchString: any = {};

  maxSelected: number = 0;
  saveButtonState: boolean;
  monitorUserVis: boolean = false;

  maxSelectedDisplay: string = "30 remaining";
  remaining_className: string = "pl-remaining ok";

  manageUserTreeData: TreeNode[] = [];

  userArray: any[] = [];
  deptArray: any[] = [];
  groupArray: any[] = [];
  TreeDataTemp: any[] = [];
  TreeDataTemp_Monitor: any[] = [];

  public bwGroupNamesList: Array<BWGroup> = [];
  public bwGroupNamesList_heirachy: Array<BWGroup> = [];

  public bwGroupNamesList_Monitor: Array<BWGroup> = [];
  public bwGroupNamesList_Monitor_heirachy: Array<BWGroup> = [];
  public bwGroupNamesList_Monitor_Temp: Array<BWGroup> = [];

  monitor_userArray: any[] = [];
  monitor_deptArray: any[] = [];
  monitor_groupArray: any[] = [];
  MonitorTreeTemp: any[] = [];

  removeUserFromUserTreeArray: any[] = [];
  removeUserFromMonitorTreeArray: any[] = [];

  //   isEnterpriseRoot: boolean = false;
  isEnterpriseRootRemove_FromUserTree: boolean = false;
  isEnterpriseRootRemove_FromMonitorTree: boolean = false;
  parent_deselected_heirchicalView: boolean = false;

  selectedUsersStatus: boolean = false;

  isHeirachy: boolean = true;
  isSearch: boolean = false;

  lblManageUsers: string = "";
  btnCancel: string = "";
  monitoredListmin: string = "";
  contextSave: string = "";
  btnOK: string = "";


  public bwGroupNamesList_Monitor_For_Heirachy_Users: Array<BWUser> = [];
  public bwGroupNamesList_For_Heirachy_Users: Array<BWUser> = [];

  constructor(
    private helper: HelperService, private dataService: DataService, private cd: ChangeDetectorRef, private contextService: ContextService) {

    let selectedTree: any[] = [];
    let countUser: number = 0;
    this.cd.detach();

  }

  ngOnInit() {
    this.monitorUserVis = false;
    this.saveButtonState = false;
    this.lblManageUsers = this.contextService.getLanguageTagByKey('ManageUsers.Text.ManageUsers');
    this.btnCancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
    this.monitoredListmin = this.contextService.getLanguageTagByKey('ManageUsers.Prompt.MonitoredListMinimum');
    this.contextSave = this.contextService.getLanguageTagByKey('Menu.Context.Save');
    this.btnOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');

    this.helper.manageUsersVisible.subscribe(val => {
      //this.isHeirachy=true;
      if (this.contextService.objSettings.bwGroupNamesList_Monitor.length > 0) {
        this.bwGroupNamesList_Monitor = JSON.parse(JSON.stringify(this.contextService.objSettings.bwGroupNamesList_Monitor));
        this.insert_into_removeArray();

        this.populateUserPickedTree();
      }
      else {

        this.selectedUsersStatus = true;

      }

      this.contextService.commonObservable.subscribe(val => {
        this.data = [];
        this.selectedUsers = [];
        this.selectedUsers_temp = [];

        this.usersPicked = [];
        this.deselectedUsers = [];



        this.manageUserTreeData = [];

        this.userArray = [];
        this.deptArray = [];
        this.groupArray = [];
        this.TreeDataTemp = [];
        this.TreeDataTemp_Monitor = [];

        this.bwGroupNamesList_Monitor_For_Heirachy_Users = [];
        this.bwGroupNamesList_For_Heirachy_Users = [];

        this.bwGroupNamesList = [];
        this.bwGroupNamesList_heirachy = [];

        this.bwGroupNamesList_Monitor = [];
        this.bwGroupNamesList_Monitor_heirachy = [];
        this.bwGroupNamesList_Monitor_Temp = [];

        this.monitor_userArray = [];
        this.monitor_deptArray = [];
        this.monitor_groupArray = [];
        this.MonitorTreeTemp = [];

        this.removeUserFromUserTreeArray = [];
        this.removeUserFromMonitorTreeArray = [];
      });
      this.populateDirToManageUserTree(); // Fetch all bw-directory to  objects
      this.removeUserFromUserTree(); // Remove monitored users from object
      this.loadTreeInGui(); // make object data tree object and populate tree
      //this.hierarchyChange(false);
      this.manageUsersVisible = val;
      this.cd.reattach();

    });

  }

  /**
   * This method is used to manage heiarchical tree view
   * @param checked uncheck show heirachy checkbox it become false otherwise true
   * 
   */
  hierarchyChange(checked: boolean) {
    this.contextService.log("start method: hierarchyChange()");

    if (!checked) {
      this.isHeirachy = false;

      this.insertData_to_HeirchyArray(); // Insert all direcorty to heirachical structure

      this.Load_MonitorTree(); // Load monitored tree in non-heirachical structure
      this.Load_UserTree() // load bw-directory tree in non-heirachical structure

    } else {

      this.isHeirachy = true;
      this.populateUserPickedTree(); // Load monitored tree in heirachical structure
      this.loadTreeInGui(); // load bw-directory tree in heirachical structure
    }
    this.selectedUsers = [];
    this.deselectedUsers = [];
    this.searchString.text = '';
    this.contextService.log("End method: hierarchyChange()");

  }

  /**
   * This method is used for insert all direcorty to heirachical structure
   * 
   */
  insertData_to_HeirchyArray() {
    this.contextService.log("start method: insertData_to_HeirchyArray()");

    this.bwGroupNamesList_Monitor_For_Heirachy_Users = [];
    this.bwGroupNamesList_For_Heirachy_Users = [];
    //Monitor Users
    this.bwGroupNamesList_Monitor.forEach(elementG => {

      elementG.bwDepartmentList.forEach(elementD => {

        elementD.bwUserList.forEach(elementU => {

          let objBWUser = new BWUser();
          objBWUser.departmentId = elementU.departmentId;
          objBWUser.loginId = elementU.loginId;
          objBWUser.name = elementU.name;
          objBWUser.type = "user";
          objBWUser.groupId = elementG.groupName;
          this.bwGroupNamesList_Monitor_For_Heirachy_Users.push(objBWUser);


        });
      });

      elementG.bwUserList.forEach(elementGU => {
        let objBWUser = new BWUser();
        objBWUser.departmentId = elementGU.departmentId;
        objBWUser.loginId = elementGU.loginId;
        objBWUser.name = elementGU.name;
        objBWUser.type = "user";
        objBWUser.groupId = elementG.groupName;
        this.bwGroupNamesList_Monitor_For_Heirachy_Users.push(objBWUser);
      });

    });

    //Users
    this.bwGroupNamesList.forEach(elementG => {

      elementG.bwDepartmentList.forEach(elementD => {

        elementD.bwUserList.forEach(elementU => {

          let objBWUser = new BWUser();
          objBWUser.departmentId = elementU.departmentId;
          objBWUser.loginId = elementU.loginId;
          objBWUser.name = elementU.name;
          objBWUser.type = "user";
          objBWUser.groupId = elementG.groupName;
          this.bwGroupNamesList_For_Heirachy_Users.push(objBWUser);


        });
      });

      elementG.bwUserList.forEach(elementGU => {
        let objBWUser = new BWUser();
        objBWUser.departmentId = elementGU.departmentId;
        objBWUser.loginId = elementGU.loginId;
        objBWUser.name = elementGU.name;
        objBWUser.type = "user";
        objBWUser.groupId = elementG.groupName;
        this.bwGroupNamesList_For_Heirachy_Users.push(objBWUser);
      });

    });

    this.contextService.log("End method: insertData_to_HeirchyArray()");

  }


  /**
   * This method is used for converting data object to tree structure and populate tree
   */
  Load_MonitorTree() {
    this.contextService.log("start method: Load_MonitorTree()");

    this.monitor_deptArray = [];


    if (this.parent_deselected_heirchicalView) {

      this.usersPicked = [];

    } else {
      if (this.contextService.objLoggedUserDetails.getisEnterprise()) {
        this.enterpriseName = this.contextService.objLoggedUserDetails.getserviceProvider();

        this.bwGroupNamesList_Monitor_For_Heirachy_Users.forEach(elementUser_User => {

          this.monitor_deptArray.push({ "label": '' + elementUser_User.name + '', "isFolder": true, "data": '' + elementUser_User.loginId + '', "icon": '' + elementUser_User.loginId + '', "expanded": true, "parent": '' + this.enterpriseName + '', "styleClass": "folder", "type": '' + elementUser_User.type + '', "children": '' });

        });

        this.TreeDataTemp_Monitor = [{ "label": '' + this.enterpriseName + '', "isFolder": true, "data": '' + this.enterpriseName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.monitor_deptArray) }];

      } else {

        this.bwGroupNamesList_Monitor.forEach(elementG => {

          this.bwGroupNamesList_Monitor_For_Heirachy_Users.forEach(elementUser_User => {

            this.monitor_deptArray.push({ "label": '' + elementUser_User.name + '', "isFolder": true, "data": '' + elementUser_User.loginId + '', "icon": '' + elementUser_User.loginId + '', "expanded": true, "parent": '' + this.enterpriseName + '', "styleClass": "folder", "type": '' + elementUser_User.type + '', "children": '' });

          });

          this.TreeDataTemp_Monitor = [{ "label": '' + elementG.groupName + '', "isFolder": true, "data": '' + elementG.groupName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.monitor_deptArray) }];

        });

      }
      this.usersPicked = [];
      this.usersPicked = this.TreeDataTemp_Monitor;
    }
    this.parent_deselected_heirchicalView = false;
    this.contextService.log("End method: Load_MonitorTree()");

  }
  /**
   * This method is used for populate bw-user tree
   */
  Load_UserTree() {
    this.contextService.log("start method: Load_UserTree()");

    this.groupArray = [];

    if (this.bwGroupNamesList_For_Heirachy_Users.length <= 0) {
      this.manageUserTreeData = [];
    }
    else {
      if (this.contextService.objLoggedUserDetails.getisEnterprise()) {
        this.enterpriseName = this.contextService.objLoggedUserDetails.getserviceProvider();


        this.bwGroupNamesList_For_Heirachy_Users.forEach(elementUser_User => {
          this.groupArray.push({ "label": '' + elementUser_User.name + '', "isFolder": true, "data": '' + elementUser_User.loginId + '', "icon": '' + elementUser_User.loginId + '', "expanded": true, "parent": '' + this.enterpriseName + '', "styleClass": "folder", "type": '' + elementUser_User.type + '', "children": '' });
        });

        this.TreeDataTemp = [{ "label": '' + this.enterpriseName + '', "isFolder": true, "data": '' + this.enterpriseName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.groupArray) }];
      } else {

        this.bwGroupNamesList.forEach(elementG => {

          this.bwGroupNamesList_For_Heirachy_Users.forEach(elementUser_User => {
            this.groupArray.push({ "label": '' + elementUser_User.name + '', "isFolder": true, "data": '' + elementUser_User.loginId + '', "icon": '' + elementUser_User.loginId + '', "expanded": true, "parent": '' + this.enterpriseName + '', "styleClass": "folder", "type": '' + elementUser_User.type + '', "children": '' });
          });

          this.TreeDataTemp = [{ "label": '' + elementG.groupName + '', "isFolder": true, "data": '' + elementG.groupName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.groupArray) }];

        });
      }
      this.manageUserTreeData = [];
      this.manageUserTreeData = this.TreeDataTemp;

      if (!this.isSearch) {
        this.data = this.manageUserTreeData;

      }
    }
    this.contextService.log("End method: Load_UserTree()");

  }
  /**
   * This method is used for repopulate all data
   * 
   * @memberof ManageUsersComponent
   */
  reloadComponent() {
    this.contextService.log("starts method: reloadComponent()");
    this.selectedUsers = [];
    this.deselectedUsers = [];
    this.bwGroupNamesList = [];
    this.bwGroupNamesList_Monitor = [];
    this.removeUserFromUserTreeArray = [];
    this.removeUserFromMonitorTreeArray = [];

    if (this.contextService.objSettings.bwGroupNamesList_Monitor) {
      this.bwGroupNamesList_Monitor = this.contextService.objSettings.bwGroupNamesList_Monitor;
      this.insert_into_removeArray();

      this.populateUserPickedTree();
    }
    else {

      this.selectedUsersStatus = true;

    }

    this.populateDirToManageUserTree();
    this.removeUserFromUserTree();
    this.loadTreeInGui();
    this.contextService.log("End method: reloadComponent()");

  }
  /**
   * sort Tree
   * 
   * @param {any} prop 
   * @param {any} arr 
   * @returns 
   * @memberof ManageUsersComponent
   */
  sort(prop, arr) {

    prop = prop.split('.');
    let len = prop.length;

    arr.sort(function (a, b) {
      let i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  };

  /**
   * Cancel Button Click
   * 
   * @memberof ManageUsersComponent
   */
  closeSettings() {
    this.contextService.log("start method: closeSettings()");

    this.reloadComponent();
    this.searchString.text = '';
    this.manageUsersVisible = false;
    this.monitorUserVis = false;
    setTimeout(() => { this.cd.detach(); });
    this.contextService.log("End method: closeSettings()");

  }


  repopulateUserTree() {
    this.manageUserTreeData = [];
  }

  /**
   * Remove users from Monitor Tree
   * 
   * @memberof ManageUsersComponent
   */
  removeUsersFromMonitor() {

    this.contextService.log("start method: removeUsersFromMonitor()");

    this.contextService.log("De-Selected Users:");
    this.contextService.log(this.deselectedUsers);

    let index = 0;
    this.deselectedUsers.forEach(element => {


      if (element.type === 'parent') {

        this.isEnterpriseRootRemove_FromMonitorTree = true;
        this.parent_deselected_heirchicalView = true;
      }

      if (element.type === "user") {


        this.removeUserFromMonitorTreeArray.push(element.data);

        index = this.removeUserFromUserTreeArray.length - 1;
        for (index; index >= 0; index--) {

          if (this.removeUserFromUserTreeArray[index] === element.data) {
            this.removeUserFromUserTreeArray.splice(index, 1);
          }
        }
      }

    });

    this.removeUserFromMonitorTree(); //remove monitored users 
    this.populateUserPickedTree(); //Populate monitored tree

    this.populateDirToManageUserTree(); //Fetch all bw-directory to objects
    this.removeUserFromUserTree(); //remove monitored users from users list object
    this.loadTreeInGui(); // populate bw-users tree

    let index5: number = 0;
    this.deselectedUsers.forEach(element1 => {

      index5 = this.selectedUsers.length - 1;

      for (index5; index5 >= 0; index5--) {

        if (this.selectedUsers[index5]) {
          if (this.selectedUsers[index5].type === 'parent') {

            this.selectedUsers = [];

          }
          else if (this.selectedUsers[index5].type === 'user') {
            if (this.selectedUsers[index5].data === element1.data) {
              this.selectedUsers.splice(index5, 1);
            }
          }
          else if (this.selectedUsers[index5].type === 'department') {
            {

              if (this.selectedUsers[index5].label === element1.label && this.selectedUsers[index5].parent.label === element1.parent.label) {

                this.selectedUsers.splice(index5, 1);

              }
            }

          }
          else if (this.selectedUsers[index5].type === 'group') {
            if (this.selectedUsers[index5].data === element1.data) {
              this.selectedUsers.splice(index5, 1);
            }
          }
        }

      }

    });

    this.deselectedUsers = [];
    this.isEnterpriseRootRemove_FromUserTree = false;

    this.setUsers_remaining();

    this.selectedUsers = [];
    this.searchString.text = '';

    if (!this.isHeirachy) {
      this.insertData_to_HeirchyArray();
      this.Load_MonitorTree();

      this.Load_UserTree()
    }

    this.contextService.log("start method: removeUsersFromMonitor()");
  }


  /**
   * This method is used from remove bw-users from monitored tree
   */
  removeUserFromMonitorTree() {
    this.contextService.log("start method: removeUserFromMonitorTree()");

    let index = 0, index1 = 0, index2 = 0, index3 = 0, index4 = 0;
    let userFlag: boolean = false;


    this.removeUserFromMonitorTreeArray.forEach(element => {

      index2 = this.bwGroupNamesList_Monitor.length - 1;
      for (index2; index2 >= 0; index2--) {

        index1 = this.bwGroupNamesList_Monitor[index2].bwDepartmentList.length - 1;

        for (index1; index1 >= 0; index1--) {

          index = this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length - 1;

          for (index; index >= 0; index--) {
            if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList[index].loginId === element) {

              this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.splice(index, 1);

            }
          }
          if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length === 0) {

            this.bwGroupNamesList_Monitor[index2].bwDepartmentList.splice(index1, 1);

          }
        }



        index3 = this.bwGroupNamesList_Monitor[index2].bwUserList.length - 1;
        for (index3; index3 >= 0; index3--) {

          index = this.bwGroupNamesList_Monitor[index2].bwUserList.length - 1;

          for (index; index >= 0; index--) {



            if (this.bwGroupNamesList_Monitor[index2].bwUserList[index].loginId === element) {
              this.bwGroupNamesList_Monitor[index2].bwUserList.splice(index, 1);

            }
          }



        }

        if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList.length === 0 && this.bwGroupNamesList_Monitor[index2].bwUserList.length === 0) {

          this.bwGroupNamesList_Monitor.splice(index2, 1);

        }

      }

    });
    this.contextService.log("End method: removeUserFromMonitorTree()");
  }

  /**
   * This method used to convert object to tree type
   * 
   * @memberof ManageUsersComponent
   */
  makeToTreeType() {

    this.contextService.log("start method: makeToTreeType()");


    let GroupArray: any[] = [];
    let DeptArray: any[] = [];
    let userArray: any[] = [];
    let isFound: boolean = false;
    let G_Name = "";
    let D_Name = "";
    let U_Name = "";
    let Login_Id = "";

    let bwGroupNamesList_TempararyArray: Array<BWGroup> = [];

    bwGroupNamesList_TempararyArray = JSON.parse(JSON.stringify(this.bwGroupNamesList));



    this.selectedUsers.forEach(elementGroup => {

      G_Name = "";
      D_Name = "";
      U_Name = "";
      Login_Id = "";

      if (elementGroup.type === 'parent') {

        bwGroupNamesList_TempararyArray.forEach(elementGrp => {

          elementGrp.bwDepartmentList.forEach(elementDept => {

            elementDept.bwUserList.forEach(elementUser => {
              this.removeUserFromUserTreeArray.push(elementUser.name);

            });
          });
          elementGrp.bwUserList.forEach(elementUD => {

            this.removeUserFromUserTreeArray.push(elementUD.name);

          });
        });
      }

      if (elementGroup.type === "user") {

        G_Name = "";
        D_Name = "";
        U_Name = "";
        Login_Id = "";
        isFound = false;

        let index = 0, index1 = 0, index2 = 0, index3 = 0;


        index2 = bwGroupNamesList_TempararyArray.length - 1;
        for (index2; index2 >= 0; index2--) {

          index1 = bwGroupNamesList_TempararyArray[index2].bwDepartmentList.length - 1;

          for (index1; index1 >= 0; index1--) {
            index = bwGroupNamesList_TempararyArray[index2].bwDepartmentList[index1].bwUserList.length - 1;

            for (index; index >= 0; index--) {


              if (this.bwGroupNamesList[index2].bwDepartmentList[index1].bwUserList[index].loginId === elementGroup.data) {

                G_Name = bwGroupNamesList_TempararyArray[index2].groupName;
                D_Name = bwGroupNamesList_TempararyArray[index2].bwDepartmentList[index1].departmentId;
                U_Name = bwGroupNamesList_TempararyArray[index2].bwDepartmentList[index1].bwUserList[index].name;
                Login_Id = bwGroupNamesList_TempararyArray[index2].bwDepartmentList[index1].bwUserList[index].loginId;
                isFound = true;
              }
            }
          }

          //Direct user
          index3 = bwGroupNamesList_TempararyArray[index2].bwUserList.length - 1;
          for (index3; index3 >= 0; index3--) {
            if (bwGroupNamesList_TempararyArray[index2].bwUserList[index3].loginId === elementGroup.data) {

              G_Name = bwGroupNamesList_TempararyArray[index2].groupName;
              D_Name = "";
              U_Name = bwGroupNamesList_TempararyArray[index2].bwUserList[index3].name;
              Login_Id = bwGroupNamesList_TempararyArray[index2].bwUserList[index3].loginId;
              isFound = true;

            }
          }
        }



        if (isFound) {

          if (!this.eliminateDuplicates_Group_Monitor(G_Name)) {

            let objBWGroup_Monitor: BWGroup = new BWGroup();
            objBWGroup_Monitor.groupName = G_Name;
            objBWGroup_Monitor.type = 'group';
            this.bwGroupNamesList_Monitor.push(objBWGroup_Monitor);

            this.bwGroupNamesList_Monitor.forEach(elementG => {

              if (elementG.groupName === G_Name) {


                if (D_Name !== '') {

                  if (!this.eliminateDuplicates_Department_Monitor(G_Name, D_Name)) {


                    let objBWDepartment_Monitor: BWDepartment = new BWDepartment();
                    objBWDepartment_Monitor.groupId = elementG.groupName;
                    objBWDepartment_Monitor.departmentId = D_Name;
                    objBWDepartment_Monitor.type = 'department';
                    objBWDepartment_Monitor.loginId = '';
                    elementG.bwDepartmentList.push(objBWDepartment_Monitor);

                    elementG.bwDepartmentList.forEach(elementD => {
                      if (elementD.departmentId === D_Name) {

                        let userFlag: boolean = false;
                        elementD.bwUserList.forEach(elementUsr => {
                          if (elementUsr.loginId === Login_Id) {
                            userFlag = true;
                          }
                        });
                        if (!userFlag) {
                          let objBWUser_Monitor: BWUser = new BWUser();
                          objBWUser_Monitor.name = U_Name;
                          objBWUser_Monitor.loginId = Login_Id;
                          objBWUser_Monitor.type = 'user';
                          objBWUser_Monitor.departmentId = elementD.departmentId;
                          objBWUser_Monitor.groupId = elementG.groupName;
                          elementD.bwUserList.push(objBWUser_Monitor);
                        }
                      }
                    });

                    //Insert loginId to remove list
                    if (!this.checkUserId_RemoveUserFromUserTreeArray(Login_Id)) {
                      this.removeUserFromUserTreeArray.push(Login_Id);
                    }

                  } else { //Department Found                  

                  }

                } //Deptartment Null
                else {

                  let userFlag: boolean = false;
                  elementG.bwUserList.forEach(elementUsr => {
                    if (elementUsr.loginId === Login_Id) {
                      userFlag = true;
                    }
                  });
                  if (!userFlag) {

                    let objBWUser_Monitor: BWUser = new BWUser();
                    objBWUser_Monitor.name = U_Name;
                    objBWUser_Monitor.loginId = Login_Id;
                    objBWUser_Monitor.type = 'user';
                    objBWUser_Monitor.departmentId = elementG.groupName;
                    objBWUser_Monitor.groupId = elementG.groupName;
                    elementG.bwUserList.push(objBWUser_Monitor);
                  }
                  //Insert loginId to remove list
                  if (!this.checkUserId_RemoveUserFromUserTreeArray(Login_Id)) {
                    this.removeUserFromUserTreeArray.push(Login_Id);
                  }

                }

              }

            });

          }
          else { //Grop Name Already Found           

            this.bwGroupNamesList_Monitor.forEach(elementG => {
              if (elementG.groupName === G_Name) {

                if (D_Name !== '') {

                  if (!this.eliminateDuplicates_Department_Monitor(G_Name, D_Name)) {


                    let objBWDepartment_Monitor: BWDepartment = new BWDepartment();
                    objBWDepartment_Monitor.groupId = elementG.groupName;
                    objBWDepartment_Monitor.departmentId = D_Name;
                    objBWDepartment_Monitor.type = 'department';
                    objBWDepartment_Monitor.loginId = '';
                    elementG.bwDepartmentList.push(objBWDepartment_Monitor);

                    elementG.bwDepartmentList.forEach(elementD => {
                      if (elementD.departmentId === D_Name) {

                        let userFlag: boolean = false;
                        elementD.bwUserList.forEach(elementUsr => {
                          if (elementUsr.loginId === Login_Id) {
                            userFlag = true;
                          }
                        });
                        if (!userFlag) {
                          let objBWUser_Monitor: BWUser = new BWUser();
                          objBWUser_Monitor.name = U_Name;
                          objBWUser_Monitor.loginId = Login_Id;
                          objBWUser_Monitor.type = 'user';
                          objBWUser_Monitor.departmentId = elementD.departmentId;
                          objBWUser_Monitor.groupId = elementG.groupName;
                          elementD.bwUserList.push(objBWUser_Monitor);
                        }
                      }
                    });

                    //Insert loginId to remove list
                    if (!this.checkUserId_RemoveUserFromUserTreeArray(Login_Id)) {
                      this.removeUserFromUserTreeArray.push(Login_Id);
                    }

                  }
                  else { //Department Found


                    elementG.bwDepartmentList.forEach(elementD => {
                      if (elementD.departmentId === D_Name) {
                        let userFlag: boolean = false;
                        elementD.bwUserList.forEach(elementUsr => {
                          if (elementUsr.loginId === Login_Id) {
                            userFlag = true;
                          }
                        });
                        if (!userFlag) {
                          let objBWUser_Monitor: BWUser = new BWUser();
                          objBWUser_Monitor.name = U_Name;
                          objBWUser_Monitor.loginId = Login_Id;
                          objBWUser_Monitor.type = 'user';
                          objBWUser_Monitor.departmentId = elementD.departmentId;
                          objBWUser_Monitor.groupId = elementG.groupName;
                          elementD.bwUserList.push(objBWUser_Monitor);
                        }
                      }
                    });
                    //Insert loginId to remove list
                    if (!this.checkUserId_RemoveUserFromUserTreeArray(Login_Id)) {
                      this.removeUserFromUserTreeArray.push(Login_Id);
                    }
                  }

                }//Dept Null
                else {

                  let userFlag: boolean = false;
                  elementG.bwUserList.forEach(elementUsr => {
                    if (elementUsr.loginId === Login_Id) {
                      userFlag = true;
                    }
                  });
                  if (!userFlag) {

                    let objBWUser_Monitor: BWUser = new BWUser();
                    objBWUser_Monitor.name = U_Name;
                    objBWUser_Monitor.loginId = Login_Id;
                    objBWUser_Monitor.type = 'user';
                    objBWUser_Monitor.departmentId = elementG.groupName;
                    objBWUser_Monitor.groupId = elementG.groupName;
                    elementG.bwUserList.push(objBWUser_Monitor);
                  }
                  //Insert loginId to remove list
                  if (!this.checkUserId_RemoveUserFromUserTreeArray(Login_Id)) {
                    this.removeUserFromUserTreeArray.push(Login_Id);
                  }

                }

              }
            });

          }
        }

      } //



    });

    this.removeUserFromMonitorTreeArray = [];

    this.populateDirToManageUserTree();
    this.removeUserFromUserTree();
    this.loadTreeInGui();

    this.populateUserPickedTree();

    this.contextService.log("End method: makeToTreeType()");

  }
  /**
   * Add user to Monitor tree
   * 
   * @memberof ManageUsersComponent
   */
  adduserForMonitor() {

    this.contextService.log("start method: adduserForMonitor()");

    this.enterpriseName = this.contextService.objLoggedUserDetails.getserviceProvider();
    this.selectedUsers_temp = [];

    if (!this.isHeirachy) {
      this.makeToTreeType();

    }
    else {

      this.contextService.log("Selected Users:");

      this.selectedUsers.forEach(elementGroup => {

        if (elementGroup.type === 'parent') {

          this.bwGroupNamesList.forEach(elementGrp => {

            elementGrp.bwDepartmentList.forEach(elementDept => {

              elementDept.bwUserList.forEach(elementUser => {
                this.removeUserFromUserTreeArray.push(elementUser.name);
              });
            });
            elementGrp.bwUserList.forEach(elementUD => {
              this.removeUserFromUserTreeArray.push(elementUD.name);

            });
          });


          // this.isEnterpriseRootRemove_FromUserTree = true;
        }


        //*****************************group**************************** */
        if (elementGroup.type === "group") {

          if (!this.eliminateDuplicates_Group_Monitor(elementGroup.label)) {

            let objBWGroup_Monitor: BWGroup = new BWGroup();
            objBWGroup_Monitor.groupName = elementGroup.label;
            objBWGroup_Monitor.type = elementGroup.type;
            this.bwGroupNamesList_Monitor.push(objBWGroup_Monitor);

          }
        }
        //*****************************department**************************** */
        if (elementGroup.type === "department") {

          if (!this.eliminateDuplicates_Group_Monitor(elementGroup.parent.label)) {

            let objBWGroup_Monitor: BWGroup = new BWGroup();
            objBWGroup_Monitor.groupName = elementGroup.parent.label;
            objBWGroup_Monitor.type = elementGroup.parent.type;
            this.bwGroupNamesList_Monitor.push(objBWGroup_Monitor);

          }

          this.bwGroupNamesList_Monitor.forEach(element => {
            if (element.groupName === elementGroup.parent.label) {

              if (!this.eliminateDuplicates_Department_Monitor(elementGroup.parent.label, elementGroup.label)) {


                let objBWDepartment_Monitor: BWDepartment = new BWDepartment();
                objBWDepartment_Monitor.groupId = elementGroup.parent.label;
                objBWDepartment_Monitor.departmentId = elementGroup.label;
                objBWDepartment_Monitor.type = elementGroup.type;
                objBWDepartment_Monitor.loginId = elementGroup.icon;
                element.bwDepartmentList.push(objBWDepartment_Monitor);
              }
            }
          });
        }

        //*****************************user**************************** */
        if (elementGroup.type === "user") {

          if (elementGroup.parent.parent && elementGroup.parent.parent.data !== this.enterpriseName) {

            // if (elementGroup.parent.parent.data !== this.enterpriseName) {

            if (!this.eliminateDuplicates_Group_Monitor(elementGroup.parent.parent.data)) {

              let objBWGroup_Monitor: BWGroup = new BWGroup();
              objBWGroup_Monitor.groupName = elementGroup.parent.parent.data;
              objBWGroup_Monitor.type = elementGroup.parent.parent.type;
              this.bwGroupNamesList_Monitor.push(objBWGroup_Monitor);

            }


            this.bwGroupNamesList_Monitor.forEach(element => {

              if (element.groupName === elementGroup.parent.parent.label) {

                if (!this.eliminateDuplicates_Department_Monitor(elementGroup.parent.parent.label, elementGroup.parent.label)) {

                  let objBWDepartment_Monitor: BWDepartment = new BWDepartment();
                  objBWDepartment_Monitor.groupId = elementGroup.parent.parent.data;
                  objBWDepartment_Monitor.departmentId = elementGroup.parent.label;
                  objBWDepartment_Monitor.type = elementGroup.parent.type;
                  objBWDepartment_Monitor.loginId = elementGroup.parent.icon;
                  element.bwDepartmentList.push(objBWDepartment_Monitor);
                }
              }


            });

            this.bwGroupNamesList_Monitor.forEach(elementG => {

              elementG.bwDepartmentList.forEach(elementD => {
                if (elementD.departmentId === elementGroup.parent.label && elementG.groupName === elementGroup.parent.parent.label) {

                  let userFlag: boolean = false;
                  elementD.bwUserList.forEach(element => {
                    if (element.loginId === elementGroup.data) {
                      userFlag = true;
                    }
                  });
                  if (!userFlag) {
                    let objBWUser_Monitor: BWUser = new BWUser();
                    objBWUser_Monitor.name = elementGroup.label;
                    objBWUser_Monitor.loginId = elementGroup.data;
                    objBWUser_Monitor.type = elementGroup.type;
                    objBWUser_Monitor.departmentId = elementGroup.parent.label;
                    objBWUser_Monitor.groupId = elementGroup.parent.parent.label;

                    elementD.bwUserList.push(objBWUser_Monitor);
                  }


                  //Insert loginId to remove list
                  if (!this.checkUserId_RemoveUserFromUserTreeArray(elementGroup.data)) {
                    this.removeUserFromUserTreeArray.push(elementGroup.data);
                  }
                }
              });
            });

          }
          else if (elementGroup.parent || elementGroup.parent.parent.data === this.enterpriseName) {
            if (!this.eliminateDuplicates_Group_Monitor(elementGroup.parent.data)) {

              let objBWGroup_Monitor: BWGroup = new BWGroup();
              objBWGroup_Monitor.groupName = elementGroup.parent.data;
              objBWGroup_Monitor.type = elementGroup.parent.type;
              this.bwGroupNamesList_Monitor.push(objBWGroup_Monitor);

            }

            this.bwGroupNamesList_Monitor.forEach(element => {
              if (element.groupName === elementGroup.parent.data) {

                let userFlag: boolean = false;
                element.bwUserList.forEach(element => {
                  if (element.loginId === elementGroup.data) {
                    userFlag = true;
                  }
                });

                if (!userFlag) {

                  let objBWUser_Monitor: BWUser = new BWUser();
                  objBWUser_Monitor.name = elementGroup.label;
                  objBWUser_Monitor.loginId = elementGroup.data;
                  objBWUser_Monitor.type = elementGroup.type;
                  objBWUser_Monitor.departmentId = elementGroup.parent.label;
                  objBWUser_Monitor.groupId = elementGroup.parent.label
                  element.bwUserList.push(objBWUser_Monitor);
                }
                //Insert loginId to remove list
                if (!this.checkUserId_RemoveUserFromUserTreeArray(elementGroup.data)) {
                  this.removeUserFromUserTreeArray.push(elementGroup.data);
                }


              }
            });

          }

        }

      });


      this.removeUserFromMonitorTreeArray = [];
      //this.selectedUsers=[];
      this.populateDirToManageUserTree(); //Fetch bw-directory to object
      this.removeUserFromUserTree(); // remove monitored users from bw-user object
      this.loadTreeInGui(); //populate bw-user tree
      this.populateUserPickedTree(); //populate monitored tree

    }
    this.isEnterpriseRootRemove_FromUserTree = false;
    this.setUsers_remaining(); //Manage user remaining count


    this.deselectedUsers = [];
    this.selectedUsers = [];
    this.searchString.text = '';

    if (!this.isHeirachy) {

      this.insertData_to_HeirchyArray();
      this.Load_MonitorTree();

      this.Load_UserTree()
    }
    this.contextService.log("End end: adduserForMonitor()");


  }
  /**
   * Populate monitored tree
   */
  populateUserPickedTree() {

    this.contextService.log("start method: populateUserPickedTree()");

    this.monitor_groupArray = [];
    this.usersPicked = [];
    this.monitor_deptArray = [];
    this.TreeDataTemp_Monitor = [];

    if (this.isEnterpriseRootRemove_FromMonitorTree) {

      this.usersPicked = [];

    } else {


      this.bwGroupNamesList_Monitor.forEach(elementGroup => {

        elementGroup.bwDepartmentList.forEach(elementDepartment => {

          elementDepartment.bwUserList.forEach(elementUser => {
            this.monitor_userArray.push({ "label": '' + elementUser.name + '', "isFolder": true, "data": '' + elementUser.loginId + '', "icon": '' + elementUser.loginId + '', "expanded": true, "parent": '' + elementDepartment.departmentId + '', "styleClass": "folder", "type": '' + elementUser.type + '', "children": '' });

          });

          this.monitor_deptArray.push({ "label": '' + elementDepartment.departmentId + '', "isFolder": true, "data": '' + elementDepartment.departmentId + '', "icon": '' + elementDepartment.loginId + '', "expanded": true, "parent": '' + elementGroup.groupName + '', "styleClass": "folder", "type": '' + elementDepartment.type + '', "children": this.sort('label', this.monitor_userArray) });
          this.monitor_userArray = [];
        });


        elementGroup.bwUserList.forEach(elementUser_Dept => {

          this.monitor_deptArray.push({ "label": '' + elementUser_Dept.name + '', "isFolder": true, "data": '' + elementUser_Dept.loginId + '', "icon": '' + elementUser_Dept.loginId + '', "expanded": true, "parent": '' + elementGroup.groupName + '', "styleClass": "folder", "type": '' + elementUser_Dept.type + '', "children": '' });

        });

        if (this.contextService.objLoggedUserDetails.getisEnterprise()) {
          this.monitor_groupArray.push({ "label": '' + elementGroup.groupName + '', "isFolder": true, "data": '' + elementGroup.groupName + '', "icon": '' + elementGroup.groupName + '', "expanded": true, "styleClass": "folder", "type": '' + elementGroup.type + '', "children": this.sort('label', this.monitor_deptArray) });
        }
        else {
          this.monitor_groupArray.push({ "label": '' + elementGroup.groupName + '', "isFolder": true, "data": '' + elementGroup.groupName + '', "icon": '' + elementGroup.groupName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.monitor_deptArray) });

        }
        this.monitor_deptArray = [];

        this.enterpriseName = this.contextService.objLoggedUserDetails.getserviceProvider();
        if (this.contextService.objLoggedUserDetails.getisEnterprise()) {

          this.TreeDataTemp_Monitor = [{ "label": '' + this.enterpriseName + '', "isFolder": true, "data": '' + this.enterpriseName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.monitor_groupArray) }];
        }
      });

      if (!this.contextService.objLoggedUserDetails.getisEnterprise()) {
        this.usersPicked = this.monitor_groupArray;
      }
      else {

        this.usersPicked = this.usersPicked.concat(this.TreeDataTemp_Monitor);

      }

    }
    this.contextService.log("usersPicked Tree:");
    this.contextService.log(this.usersPicked);

    this.isEnterpriseRootRemove_FromMonitorTree = false;

    this.contextService.log("End method: populateUserPickedTree()");

  }

  /**
   * This method is used for fetch all Bw-directory to objects
   * 
   * @memberof ManageUsersComponent
   */
  populateDirToManageUserTree() {
    this.contextService.log("start method: populateDirToManageUserTree()");


    this.bwGroupNamesList = [];

    this.contextService.directoryList.forEach(element => {
      if (element instanceof BwDirectoryEntry) {
        let elementTemp = element as BwDirectoryEntry;
        // let canMonitor: boolean = (elementTemp.getUserId() !== this.contextService.objLoggedUserDetails.getUserId() && !elementTemp.getFullName().includes("Flexible Seating Guest") &&  !elementTemp.getFullName().includes("Call Center") && !elementTemp.getFullName().includes("Hunt Group") && !elementTemp.getFullName().includes("Trunk Group")
        //   && !elementTemp.getFullName().includes("Meet-Me Conference") && !elementTemp.getFullName().includes("Auto Attendant") && !elementTemp.getFullName().includes("Meet-Me Conferencing")
        //   && !elementTemp.getFullName().includes("BroadWorks Anywhere") && !elementTemp.getFullName().includes("Voice Messaging Group") && !elementTemp.getFullName().includes("Group Paging")
        //   && !elementTemp.getFullName().includes("Instant Group") && !elementTemp.getFullName().includes("Voice Messaging Group") && !elementTemp.getFullName().includes("Group Paging"));
        let canMonitor: boolean = (elementTemp.getUserId() !== this.contextService.objLoggedUserDetails.getUserId()
          && this.contextService.checkIfContactCanBeMonitored(elementTemp.getFirstName(), false));

        if (canMonitor) {
          let objBWGroup: BWGroup = new BWGroup();

          objBWGroup.groupName = elementTemp.getGroupId();
          objBWGroup.type = "group";
          if (!this.eliminateDuplicates_Group(objBWGroup.groupName)) {

            this.bwGroupNamesList.push(objBWGroup);
          }
          if (elementTemp.getDepartment()) {

            let objBWUser = new BWUser();
            objBWUser.departmentId = elementTemp.getDepartment();
            objBWUser.loginId = elementTemp.getUserId();
            objBWUser.name = elementTemp.getFullName();
            objBWUser.type = "user";
            objBWUser.groupId = elementTemp.getGroupId();

            this.bwGroupNamesList.forEach(elementGrp => {
              if (elementGrp.groupName === elementTemp.getGroupId()) {
                if (!this.eliminateDuplicates_Department(elementTemp.getDepartment(), elementTemp.getGroupId())) {


                  let objBWDepartment: BWDepartment = new BWDepartment();

                  objBWDepartment.groupId = elementTemp.getGroupId();

                  objBWDepartment.departmentId = elementTemp.getDepartment();
                  objBWDepartment.type = 'department';

                  elementGrp.bwDepartmentList.push(objBWDepartment);

                  objBWDepartment.bwUserList.push(objBWUser);
                }
                else {
                  //Insert user to new Departments
                  this.bwGroupNamesList.forEach(element => {
                    element.bwDepartmentList.forEach(element1 => {
                      if (element1.departmentId === elementTemp.getDepartment() && element1.groupId === elementTemp.getGroupId()) {

                        element1.bwUserList.push(objBWUser);

                      }
                    });

                  });
                }
              }
            });


          } else {
            this.bwGroupNamesList.forEach(elementGrp_Dept => {
              if (elementGrp_Dept.groupName === elementTemp.getGroupId()) {
                let objBWUser = new BWUser();
                objBWUser.departmentId = elementTemp.getDepartment();
                objBWUser.loginId = elementTemp.getUserId();
                objBWUser.name = elementTemp.getFullName();
                objBWUser.type = "user";
                objBWUser.groupId = elementTemp.getGroupId();
                elementGrp_Dept.bwUserList.push(objBWUser);
              }
            });



          }
        }
      }
    });

    //---------------Fetch monitor tree------------------------//
    if (this.selectedUsersStatus) {

      if (this.contextService.objLoggedUserDetails.getisEnterprise()) {

        this.Add_to_MonitorList_By_Status_Enterprise();

      }
      else {

        this.Add_to_MonitorList_By_Status_NoEnterprise();
      }
      this.removeUserFromUserTree();
      this.selectedUsersStatus = false;
    }
    this.setUsers_remaining();

    this.contextService.log("End method: populateDirToManageUserTree() ");

  }

  /**
   * Populate bw-user tree
   */
  loadTreeInGui() {

    this.contextService.log("start start: loadTreeInGui()");

    this.manageUserTreeData = [];
    this.userArray = [];
    this.deptArray = [];
    this.groupArray = [];

    if (this.bwGroupNamesList.length <= 0) {

      this.manageUserTreeData = [];

    } else {

      this.bwGroupNamesList.forEach(elementGroup => {

        elementGroup.bwDepartmentList.forEach(elementDepartment => {

          elementDepartment.bwUserList.forEach(elementUser => {

            this.userArray.push({ "label": '' + elementUser.name + '', "isFolder": true, "data": '' + elementUser.loginId + '', "icon": '' + elementUser.loginId + '', "expanded": true, "parent": '' + elementDepartment.departmentId + '', "styleClass": "folder", "type": '' + elementUser.type + '', "children": '' });

          });


          this.deptArray.push({ "label": '' + elementDepartment.departmentId + '', "isFolder": true, "data": '' + elementDepartment.departmentId + '', "icon": '' + elementDepartment.loginId + '', "expanded": true, "parent": '' + elementGroup.groupName + '', "styleClass": "folder", "type": '' + elementDepartment.type + '', "children": this.sort('label', this.userArray) });
          this.userArray = [];

        });
        elementGroup.bwUserList.forEach(elementUser_Dept => {
          this.deptArray.push({ "label": '' + elementUser_Dept.name + '', "isFolder": true, "data": '' + elementUser_Dept.loginId + '', "icon": '' + elementUser_Dept.loginId + '', "expanded": true, "parent": '' + elementGroup.groupName + '', "styleClass": "folder", "type": '' + elementUser_Dept.type + '', "children": '' });

        });

        if (this.contextService.objLoggedUserDetails.getisEnterprise()) {
          this.groupArray.push({ "label": '' + elementGroup.groupName + '', "isFolder": true, "data": '' + elementGroup.groupName + '', "icon": '' + elementGroup.groupName + '', "expanded": true, "styleClass": "folder", "type": '' + elementGroup.type + '', "children": this.sort('label', this.deptArray) });
        } else {
          this.groupArray.push({ "label": '' + elementGroup.groupName + '', "isFolder": true, "data": '' + elementGroup.groupName + '', "icon": '' + elementGroup.groupName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.deptArray) });

        }

        this.deptArray = [];
        this.enterpriseName = this.contextService.objLoggedUserDetails.getserviceProvider();
        if (this.contextService.objLoggedUserDetails.getisEnterprise()) {

          this.TreeDataTemp = [{ "label": '' + this.enterpriseName + '', "isFolder": true, "data": '' + this.enterpriseName + '', "expanded": true, "styleClass": "folder", "type": 'parent', "children": this.sort('label', this.groupArray) }];

        }

      });

      if (!this.contextService.objLoggedUserDetails.getisEnterprise()) {
        this.manageUserTreeData = this.groupArray;
      }
      else {



        this.manageUserTreeData = this.manageUserTreeData.concat(this.TreeDataTemp);

      }


    }
    this.data = this.manageUserTreeData;
    this.isEnterpriseRootRemove_FromUserTree = false;

    this.contextService.log("User Tree:");
    this.contextService.log(this.manageUserTreeData);

    this.contextService.log("End method: loadTreeInGui() ");

  }

  /**
   * Remove Selected users from user tree
   * 
   * @memberof ManageUsersComponent
   */

  removeUserFromUserTree() {

    this.contextService.log("start method:removeUserFromUserTree()");


    let index = 0, index1 = 0, index2 = 0, index3 = 0;

    this.removeUserFromUserTreeArray.forEach(element => {

      index2 = this.bwGroupNamesList.length - 1;
      for (index2; index2 >= 0; index2--) {

        index1 = this.bwGroupNamesList[index2].bwDepartmentList.length - 1;

        for (index1; index1 >= 0; index1--) {
          index = this.bwGroupNamesList[index2].bwDepartmentList[index1].bwUserList.length - 1;

          for (index; index >= 0; index--) {
            if (this.bwGroupNamesList[index2].bwDepartmentList[index1].bwUserList[index].loginId === element) {
              this.bwGroupNamesList[index2].bwDepartmentList[index1].bwUserList.splice(index, 1);

            }
          }

          if (this.bwGroupNamesList[index2].bwDepartmentList[index1].bwUserList.length === 0) {

            this.bwGroupNamesList[index2].bwDepartmentList.splice(index1, 1);

          }
        }


        index3 = this.bwGroupNamesList[index2].bwUserList.length - 1;
        for (index3; index3 >= 0; index3--) {

          index = this.bwGroupNamesList[index2].bwUserList.length - 1;

          for (index; index >= 0; index--) {
            if (this.bwGroupNamesList[index2].bwUserList[index].loginId === element) {
              this.bwGroupNamesList[index2].bwUserList.splice(index, 1);

            }
          }
          //this.bwGroupNamesList[index2].bwUserList.splice(index3, 1);
        }

        if (this.bwGroupNamesList[index2].bwDepartmentList.length === 0 && this.bwGroupNamesList[index2].bwUserList.length === 0) {

          this.bwGroupNamesList.splice(index2, 1);

        }

      }
    });

    this.contextService.log("End method: removeUserFromUserTree() ");

  }


  checkUserId_RemoveUserFromUserTreeArray(userId: string) {
    let flag: boolean = false;
    this.removeUserFromUserTreeArray.forEach(element => {
      if (element === userId) {
        flag = true;
      }
    });
    return flag;
  }

  eliminateDuplicates_Group(groupName: string): boolean {
    let flag: boolean = false;
    this.bwGroupNamesList.forEach(element => {
      if (element.groupName === groupName) {
        flag = true;
      }
    });
    return flag;
  }
  eliminateDuplicates_Department(DeptName: string, groupName: string): boolean {

    let flag: boolean = false;
    this.bwGroupNamesList.forEach(element => {
      element.bwDepartmentList.forEach(element1 => {
        if (element1.departmentId === DeptName && element1.groupId === groupName) {
          flag = true;
        }
      });

    });
    return flag;
  }
  eliminateDuplicates_Group_Monitor(groupName: string): boolean {
    let flag: boolean = false;
    this.bwGroupNamesList_Monitor.forEach(element => {
      if (element.groupName === groupName) {
        flag = true;
      }
    });
    return flag;
  }
  eliminateDuplicates_Department_Monitor(groupName: string, department: string) {
    let flag: boolean = false;
    this.bwGroupNamesList_Monitor.forEach(element => {
      element.bwDepartmentList.forEach(element1 => {
        if (element1.departmentId === department && element1.groupId === groupName) {
          flag = true;
        }
      });

    });
    return flag;
  }
  eliminateDuplicates_User_Monitor(loginId: string, department: string) {
    let flag: boolean = false;
    this.bwGroupNamesList_Monitor.forEach(element => {
      element.bwDepartmentList.forEach(element1 => {
        element1.bwUserList.forEach(element2 => {
          if (element2.departmentId === department && element2.loginId === loginId) {
            flag = true;
          }
        });


      });

    });
    return flag;
  }
  /**
   * Manage search user functionality
   * @param event 
   */
  searchUsersAction() {

    this.contextService.log("start method: searchUsersAction() ");

    let SearchTemp_Data: TreeNode[] = [];

    SearchTemp_Data = this.data;

    if (this.searchString.text.length) {

      this.insertData_to_HeirchyArray();
      this.isSearch = true;
      this.Load_UserTree();

      this.manageUserTreeData = this.helper.searchNodesTree(this.searchString.text, this.helper.clone(this.manageUserTreeData, true), 'children');


    } else {
      this.isSearch = false;
      this.manageUserTreeData = SearchTemp_Data;
    }

    this.contextService.log("End method: searchUsersAction()");

  }
  /**
   * Save users to monitor list 
   * 
   * @memberof ManageUsersComponent
   */
  saveUserList() {
    this.contextService.log("start method: saveUserList() ");

    this.monitorUserVis = false;
    this.removeUserFromUserTreeArray = [];
    let isFound: boolean = false;

    for (const key in this.contextService.privacyEnabledUsersList) {
      if (this.contextService.privacyEnabledUsersList.hasOwnProperty(key)) {
        let exist = false;
        this.usersPicked.forEach(element => {
          const element1 = element.children;
          element1.forEach(element2 => {
            if (element2.data === key) {
              exist = true;
            }
          });
        });

        if (!exist) {
          delete this.contextService.privacyEnabledUsersList[key];
        }


      }
    }

    this.bwGroupNamesList_Monitor.forEach(element_G => {
      element_G.bwDepartmentList.forEach(element_D => {
        element_D.bwUserList.forEach(element_U => {
          this.removeUserFromUserTreeArray.push(element_U.loginId);
          isFound = true;
        });
      });
      element_G.bwUserList.forEach(elementU_D => {
        this.removeUserFromUserTreeArray.push(elementU_D.loginId);
        isFound = true;
      });
    });


    if (isFound) {
      this.contextService.objSettings.bwGroupNamesList_Monitor = this.bwGroupNamesList_Monitor
      //new 

      for (let monitoredUser in this.contextService.monitoredUserList) {
        if (this.contextService.monitoredUserList.hasOwnProperty(monitoredUser)) {
          if (!this.removeUserFromUserTreeArray.includes(monitoredUser)) {
            for (let subscriptionId in this.contextService.monitoredUserList[monitoredUser].subscriptionList) {

              // if (this.contextService.agentSubscriptionList[monitoredUser]) {
              //   this.contextService.agentSubscriptionList[monitoredUser].subscriptionCount--;

              // } else {
              if (!this.contextService.agentSubscriptionList[monitoredUser]) {
                if (this.contextService.monitoredUserList[monitoredUser].subscriptionList[subscriptionId] === "AgentSubscriptionEvent") {
                  if (!this.contextService.staffedRatioSubscriptionList[monitoredUser]) {
                    // this.contextService.websocketService.deleteEventAction(monitoredUser, subscriptionId, true);
                  } else {
                    this.contextService.staffedRatioSubscriptionList[monitoredUser] = subscriptionId;
                  }
                } else {
                  // this.contextService.websocketService.deleteEventAction(monitoredUser, subscriptionId, true);
                }
              }
              // }
            }
            delete this.contextService.monitoredUserList[monitoredUser];
          }
        }
      }

      let contactsToBeSubscribed = [];
      let contactsSubscriptionPackage = [];



      this.removeUserFromUserTreeArray.forEach(element => {
        let userExist = false;
        for (let monitoredUser in this.contextService.monitoredUserList) {
          if (element === monitoredUser) {
            userExist = true;
          }
        }

        if (!userExist) {
          if (this.contextService.agentSubscriptionList[element]) {
            //user present in supervised agent list, only 'need to subscribe 'Voice Mail Message Summary' as extra 
            let obj = this.contextService.agentSubscriptionList[element];
            this.contextService.monitoredUserList[element] = new MonitoredUser("", obj.isDnd, obj.cfa, obj.imStatus, obj.commpilot, obj.displayStatus);
            this.contextService.monitoredUserList[element].ACDState = obj.ACDState;
            this.contextService.monitoredUserList[element].stateTimestamp = obj.stateTimestamp;
            this.contextService.monitoredUserList[element].unavailableCode = obj.unavailableCode;
            for (const callId in obj.callDetails) {
              if (obj.callDetails.hasOwnProperty(callId)) {
                this.contextService.monitoredUserList[element].callDetails[callId] = this.contextService.deepCopy(obj.callDetails[callId]);
              }
            }
            this.contextService.monitoredUserList[element].subscriptionList = obj.subscriptionList;
            this.contextService.monitorStatusUpdate.next();
            this.contextService.acdStateUpdate.next(element);
            
            //to check voicemail exist for monitored user && voicemail subscription is only used for checking voicemail service
           } else {
            this.contextService.monitoredUserList[element] = new MonitoredUser("", false, '', '', '', '');
            contactsToBeSubscribed.push(element);
            if ((this.contextService.objSettings.STARTUP === 2) || this.contextService.objSettings.STARTUP === 3) {
              if (this.contextService.staffedRatioSubscriptionList[element]) {
                // this.contextService.websocketService.deleteEventAction(element, this.contextService.staffedRatioSubscriptionList[element], false);
              }
            }

            //phase 2



          }



        }

      });


   
      contactsToBeSubscribed=[];

      this.contextService.objSettings.removeUserFromUserTreeArray = [];
      this.contextService.objSettings.removeUserFromUserTreeArray = this.removeUserFromUserTreeArray;

      this.contextService.directoryList.forEach(element => {
        if (element instanceof BwDirectoryEntry) {
          if (element.getUserId() in this.contextService.monitoredUserList) {
            this.contextService.monitoredUserList[element.getUserId()].isOnline = element.isOnline;
            let today = new Date();
            let monthNames = [this.contextService.getLanguageTagByKey('Month.1'), this.contextService.getLanguageTagByKey('Month.2'), this.contextService.getLanguageTagByKey('Month.3'), this.contextService.getLanguageTagByKey('Month.4'), this.contextService.getLanguageTagByKey('Month.5'), this.contextService.getLanguageTagByKey('Month.6'),
            this.contextService.getLanguageTagByKey('Month.7'), this.contextService.getLanguageTagByKey('Month.8'), this.contextService.getLanguageTagByKey('Month.9'), this.contextService.getLanguageTagByKey('Month.10'), this.contextService.getLanguageTagByKey('Month.11'), this.contextService.getLanguageTagByKey('Month.12')
            ];
            let toLowerCase = element.imStatus;
            if (element.imStatus !== "") {
              let tempstartMonth = parseInt(element.imStatusStartDate.getMonth().toString());
              let startDay = parseInt(element.imStatusStartDate.getDate().toString());
              let startYear = parseInt(element.imStatusStartDate.getFullYear().toString());
              let startHour = parseInt(element.imStatusStartDate.getHours().toString());
              let startMinute = parseInt(element.imStatusStartDate.getMinutes().toString());



              let IMstatus = "";

              if (element.imStatusEndDate.getHours().toString() == "NaN") {
                if (startDay === today.getDate() && tempstartMonth === today.getMonth() && startYear === today.getFullYear()) {
                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Today') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.FurtherNotice') + ".";

                }
                else {
                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startDay + " " + monthNames[tempstartMonth] + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.FurtherNotice') + ".";
                }
              }
              else {

                let tempendMonth = parseInt(element.imStatusEndDate.getMonth().toString());
                let endDay = parseInt(element.imStatusEndDate.getDate().toString());
                // var startYear = parseInt(element.imStatusStartDate.getFullYear().toString());
                let endHour = parseInt(element.imStatusEndDate.getHours().toString());
                let endMinute = parseInt(element.imStatusEndDate.getMinutes().toString());

                if (startDay === today.getDate() && tempstartMonth === today.getMonth() && startYear === today.getFullYear()) {
                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Today') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + endDay + " " + monthNames[tempendMonth] + " " + this.contextService.getLanguageTagByKey('Misc.At') + " " + endHour + ":" + endMinute + ".";
                }
                else if (endDay === today.getDate() && tempstartMonth === today.getMonth() && startYear === today.getFullYear()) {
                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startDay + " " + monthNames[tempstartMonth] + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + endHour + ":" + endMinute + ".";

                }

                else if (endDay === (today.getDate() + 1) && tempstartMonth === today.getMonth() && startYear === today.getFullYear()) {
                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startDay + " " + monthNames[tempstartMonth] + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + endHour + ":" + endMinute + " " + this.contextService.getLanguageTagByKey('Misc.Tomorrow') + " " + ".";
                }
                else {

                  IMstatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start').toLowerCase() + " " + startDay + " " + monthNames[tempstartMonth] + " " + startHour + ":" + this.zeroPad(startMinute) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End').toLowerCase() + " " + endDay + " " + monthNames[tempendMonth] + " " + this.contextService.getLanguageTagByKey('Misc.At') + " " + endHour + ":" + endMinute + ".";
                }

              }

              this.contextService.monitoredUserList[element.getUserId()].imStatus = IMstatus;
            } else {
              this.contextService.monitoredUserList[element.getUserId()].imStatus = "";

            }
          }
        }
      });



      this.manageUsersVisible = false;
      this.hierarchy = true;
      setTimeout(() => { this.cd.detach(); });
      this.contextService.MonitoredUsersupdate.next();
      this.helper.recalcContactsHeight.next();
    } else {
      this.monitorUserVis = true;

    }
    this.searchString.text = '';
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.contextService.log("End method: saveUserList() ");

  }

  zeroPad(val: number) {
    if (val < 10) {
      return '0' + val;
    } else {
      return val;
    }
  }


  Add_to_MonitorList_By_Status_NoEnterprise() {

    this.contextService.log("start method: Add_to_MonitorList_By_Status_NoEnterprise()");

    for (let k in this.contextService.monitoredUserList) {

      this.removeUserFromUserTreeArray.push(k);

    }


    this.bwGroupNamesList_Monitor = JSON.parse(JSON.stringify(this.bwGroupNamesList));

    let index = 0, index1 = 0, index2 = 0, index3 = 0, index4 = 0, indexR = 0, indexR1 = 0;
    let isUserFound1: boolean = false;
    let isUserFound2: boolean = false;
    let indexRemove = 0;

    index2 = this.bwGroupNamesList_Monitor.length - 1;

    for (index2; index2 >= 0; index2--) {  //Group

      index1 = this.bwGroupNamesList_Monitor[index2].bwDepartmentList.length - 1;
      for (index1; index1 >= 0; index1--) { //Dept

        index = this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length - 1;

        for (index; index >= 0; index--) {

          indexR = this.removeUserFromUserTreeArray.length - 1;
          for (indexR; indexR >= 0; indexR--) {
            isUserFound1 = false;

            if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList[index].loginId === this.removeUserFromUserTreeArray[indexR]) {

              isUserFound1 = true;
              break;
            }
          }
          if (!isUserFound1) {

            this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.splice(index, 1);

          }
        }

        indexRemove = this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length - 1;

        if (indexRemove <= -1) {
          this.bwGroupNamesList_Monitor[index2].bwDepartmentList.splice(index1, 1);
        }


      }
      //Direct user
      index3 = this.bwGroupNamesList_Monitor[index2].bwUserList.length - 1;
      for (index3; index3 >= 0; index3--) {


        indexR1 = this.removeUserFromUserTreeArray.length - 1;

        for (indexR1; indexR1 >= 0; indexR1--) { //remove

          if (this.bwGroupNamesList_Monitor[index2].bwUserList[index3].loginId === this.removeUserFromUserTreeArray[indexR1]) {

            isUserFound2 = true;
            break;
          }


        }
        if (!isUserFound2) {
          this.bwGroupNamesList_Monitor[index2].bwUserList.splice(index3, 1);
        }

      }

    }

    this.populateUserPickedTree();

    this.contextService.log("End method: Add_to_MonitorList_By_Status_NoEnterprise()");

  }

  Add_to_MonitorList_By_Status_Enterprise() {

    this.contextService.log("start method: Add_to_MonitorList_By_Status_Enterprise() ");

    let isFound: boolean = false;

    for (let k in this.contextService.monitoredUserList) {

      this.removeUserFromUserTreeArray.push(k);

    }

    this.bwGroupNamesList_Monitor = JSON.parse(JSON.stringify(this.bwGroupNamesList));  // clone  from bwGroupNamesList to  bwGroupNamesList_Monitor


    let index = 0, index1 = 0, index2 = 0, index3 = 0, index4 = 0, indexR = 0, indexR1 = 0;
    let isUserFound: boolean = false;


    for (index2; index2 >= 0; index2--) {  //Group

      index1 = this.bwGroupNamesList_Monitor[index2].bwDepartmentList.length - 1;
      for (index1; index1 >= 0; index1--) { //Dept

        index = this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length - 1;

        for (index; index >= 0; index--) { //user

          indexR = this.removeUserFromUserTreeArray.length - 1;


          for (indexR; indexR >= 0; indexR--) { //remove

            if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList[index].loginId === this.removeUserFromUserTreeArray[indexR]) {
              isUserFound = true;

              break;
            }

          }
          if (!isUserFound) {
            this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.splice(index, 1);
          }

        }
        //Remove Department if no user Found
        if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList[index1].bwUserList.length <= -1) {

          this.bwGroupNamesList_Monitor[index2].bwDepartmentList.splice(index1, 1);

        }


      }

      index3 = this.bwGroupNamesList_Monitor[index2].bwUserList.length - 1;
      for (index3; index3 >= 0; index3--) {

        index = this.bwGroupNamesList_Monitor[index2].bwUserList.length - 1;

        for (index; index >= 0; index--) {

          indexR1 = this.removeUserFromUserTreeArray.length - 1;

          for (indexR; indexR >= 0; indexR--) { //remove

            if (this.bwGroupNamesList_Monitor[index2].bwUserList[index].loginId === this.removeUserFromUserTreeArray[indexR1]) {
              isUserFound = true;

              break;
            }
          }
          if (!isUserFound) {
            this.bwGroupNamesList_Monitor[index2].bwUserList.splice(index, 1);
          }


        }
      }

      //Remove Group if no department Found
      if (this.bwGroupNamesList_Monitor[index2].bwDepartmentList.length <= -1) {

        this.bwGroupNamesList_Monitor.splice(index2, 1);
      }


    }

    this.populateUserPickedTree();
    this.contextService.log("End method: Add_to_MonitorList_By_Status_Enterprise()");

  }

  insert_into_removeArray() {
    this.contextService.log("start method: insert_into_removeArray()");

    this.bwGroupNamesList_Monitor.forEach(element1 => {
      element1.bwDepartmentList.forEach(element2 => {
        element2.bwUserList.forEach(element3 => {
          this.removeUserFromUserTreeArray.push(element3.loginId);
        });
      });
      element1.bwUserList.forEach(element4 => {
        this.removeUserFromUserTreeArray.push(element4.loginId);
      });
    });
    this.contextService.log("End method: insert_into_removeArray()");

  }

  /**
   * Manage user remaining count
   */
  setUsers_remaining() {
    this.contextService.log("start method: setUsers_remaining()");

    let user_Count = 0;
    this.bwGroupNamesList_Monitor.forEach(element_Group => {
      element_Group.bwDepartmentList.forEach(element_Dept => {
        element_Dept.bwUserList.forEach(element_User => {
          user_Count = user_Count + 1;
        });
      });
      element_Group.bwUserList.forEach(element_U_D => {
        user_Count = user_Count + 1;
      });
    });

    if (user_Count > 30) {
      this.maxSelected = user_Count - 30;
      this.maxSelectedDisplay = Math.abs(this.maxSelected) + " too many";
      this.remaining_className = "pl-remaining";
      this.saveButtonState = true;
    } else {
      this.maxSelected = 30 - user_Count;
      this.maxSelectedDisplay = Math.abs(this.maxSelected) + " remaining";
      this.remaining_className = "pl-remaining ok";
      this.saveButtonState = false;
    }

    this.contextService.log("End method: setUsers_remaining()");

  }
  onRowDblclick(event) {
    this.monitorUserVis = false;
  }


}