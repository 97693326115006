import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../base-component';
import { ContextService } from '../../../../../../providers/context.service';
import { SettingsGeneral } from "../../../../../../providers/settings/settings-general/settings-general.service";
@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent extends BaseComponent implements OnInit {
  description: string="";
  calls: string="";
  autoAnswerCalls: string="";
  includeInCallLogs: string="";
  queuedCallsInActivityList: string="";
  listingSupervisor: string="";
  allSupervisors: string="";
  onlySupervising: string="";
  callPriority:string="";
  constructor(public contextService: ContextService, public settingsGeneral: SettingsGeneral) {
    super()
   }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.Description');
    let tempCalls = this.contextService.getLanguageTagByKey('Misc.Calls');
    this.calls = tempCalls.substr(0, 1).toUpperCase() + tempCalls.substr(1).toLowerCase();
    this.autoAnswerCalls = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.AutomaticallyAnswerCallCenterCalls');
    this.includeInCallLogs = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.IncludeCallsInCallLogs');
    this.queuedCallsInActivityList = this.contextService.getLanguageTagByKey('Settings.Reception.CurrentCalls.ShowQueuedCallsInCallList');  
    this.listingSupervisor = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.WhenListingSupervisors');
    this.allSupervisors = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.WhenListingSupervisors.ShowAll');
    this.onlySupervising = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.WhenListingSupervisors.ShowMine');  
    this.settingsGeneral.settingsChanged=true;
    this.callPriority=this.contextService.getLanguageTagByKey('Settings.Reception.CurrentCalls.ShowQueuedCallPriority');
  }
  queuedCallClick() {
    if (!this.settingsGeneral.showQueuedCalls) {
      this.settingsGeneral.callPriority = false;
    }

  }

}
