import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { AssignedDispositionCodes } from '../../model/agent/assignedDispositionCodes';
import { CallStatusEntry } from '../../model/call-status/call-status-entry';
import { ChatService } from './../../providers/chat.service';
import { ContextService } from './../../providers/context.service';
import { DataService } from './../../providers/dataservice.service';
import { DirectoryService } from './../../providers/directory.service';
import { HelperService } from './../../providers/helper.service';

// c8 new models 
import { MessagesService } from './../../providers/messages.service';
import { CallBackEntry } from '../../model/call-back.model';
import { WebChatEntry } from '../../model/web-chat.model';
import { TwitterEntry } from '../../model/twitter.model';
import { EmailEntry } from '../../model/email.model';
import { SmsEntry } from '../../model/sms.model';

@Component({
  selector: 'app-drop-call-area',
  templateUrl: './drop-call-area.component.html',
  styleUrls: ['./drop-call-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DropCallAreaComponent implements OnInit, OnDestroy {
  // dropMsgState: boolean = false;
  callMenuItems: MenuItem[];
  userID: string = "";
  selection;
  callStatusEntry: CallStatusEntry;
  selectedCallLogUsingContextMenu: CallStatusEntry;
  activeCallWindowMenu: MenuItem[];
  ongoingCallDispositionArray: MenuItem[] = [];
  previousCallDispositionArray: MenuItem[] = [];
  dispositionMenuItems: MenuItem[] = [];

  miscForm: string = "";
  miscTo: string = "";
  duration: string = "";
  miscStatus: string = "";
  selectedCall;

  //dragula bags
  bagCallDropWrapper;

  @ViewChild('activeTable') activeTable;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      if (this.activeTable) {
        this.helper.calcCellsWidth(this.activeTable);
      }
    }, 200);
  }

  constructor(
    private dataService: DataService, private helper: HelperService, public contextService: ContextService,
    private directoryService: DirectoryService, private chatService: ChatService, private messagesService: MessagesService
  ) {

  }

  ngOnInit() {
    this.callMenuItems = [];

    this.miscForm = this.contextService.getLanguageTagByKey('Misc.From');
    this.miscTo = this.contextService.getLanguageTagByKey('Misc.To');
    this.duration = this.contextService.getLanguageTagByKey('List.CurrentCalls.Column.Duration');
    this.miscStatus = this.contextService.getLanguageTagByKey('Misc.Status');

    this.dataService.droppedToCall.subscribe(rowIdModel => {
      this.contextService.log(rowIdModel);
      let number = "";
      if (rowIdModel.phone) {
        number = rowIdModel.phone;
        console.log("dropped(phone):" + number);

      } else if (rowIdModel.userID) {
        number = this.contextService.getExtensionFromUserID(rowIdModel.userID);
        console.log("dropped(userID):" + number);

      }
      if (this.contextService.callStatusList.length) {
        const notInQueue = this.contextService.callStatusList.every(item => {
          return item.id !== rowIdModel.id;
        });
        if (notInQueue) {
          this.contextService.log("rowIdModel not in queue");

          this.contextService.websocketService.dialAction(number);

        }
      } else {
        this.contextService.websocketService.dialAction(number);

      }
      // if (this.contextService.callStatusList.length) {
      //   this.dropMsgState = true;
      // }
      // resize table heights
      if (this.contextService.callStatusList.length) {
        switch (this.helper.selectedTableTab) {
          case 'contacts':
            this.helper.recalcContactsHeight.next(true);
            break;
          case 'calllogs':
            this.helper.recalcCallLogsHeight.next(true);
            break;
          case 'voicemail':
            this.helper.recalcVoicemailHeight.next(true);
            break;
          case 'agentactivity':
            this.helper.recalcagentactivityHeight.next(true);
            break;
          default:
            break;
        }

        setTimeout(() => {
          this.helper.calcCellsWidth(this.activeTable);
        }, 500);
      }
    });

    this.helper.resized.debounceTime(600).subscribe((val) => {
      this.helper.calcCellsWidth(this.activeTable);
    });

    // unselect on drag start
    this.helper.dragStart.subscribe((val) => {
      this.selection = [];
    });

  }

  /**
   * display dialog fpr adding personal directory
   * 
   * @memberof DropCallAreaComponent
   */
  addToPersonalDirectory() {

    this.directoryService.setDropAreaValues(this.selectedCallLogUsingContextMenu);
    this.helper.addPersonalEntryModalVis.next(true);
  }

  instantMessage(event) {
    this.contextService.instantMessage(this.selectedCallLogUsingContextMenu.remoteUserId);
  }


  addNote(e) {
    this.contextService.log(e);
  }

  contextMenuSelected(e) {
    this.selectedCallLogUsingContextMenu = e.data;
    this.contextService.log(this.contextService.selectedRows);
  }

  /* C8 demo */
  transferToDemo(m) {
    this.contextService.log(m);
    alert('transfer')
  }
  rejectDemo(m) {
    this.contextService.log(m);
    alert('reject')
  }
  reserveDemo(m) {
    this.contextService.log(m);
    alert('reserve')
  }
  closeDemo(m) {
    this.contextService.log(m);
    alert('close')
  }

  /**
   * perform this method while click on active call window row
   * 
   * @param {*} event 
   * @memberof DropCallAreaComponent
   */
  onRowClick(event: any, data) {
    const target = event.target;
    const id = data.id;
    const rows = this.activeTable.el.nativeElement.querySelectorAll('tbody tr');
    let targetParent = this.getTargetParent(target);

    if (!event.ctrlKey && !event.shiftKey) {
      this.contextService.selectedRows = [];
      this.contextService.selectedRows.push(data);
      this.helper.forEach(rows, (i, row) => {
        if (this.helper.hasClass(row, 'ui-state-highlight')) {
          this.helper.removeClass(row, 'ui-state-highlight');
        }
      });
      this.highlightRow(target);
    } else {
      if (this.contextService.selectedRows.length) {
        if (this.helper.hasClass(targetParent, 'ui-state-highlight')) {
          this.contextService.selectedRows = this.contextService.selectedRows.filter((row) => {
            return row.id !== id;
          });
          this.helper.removeClass(targetParent, 'ui-state-highlight');
        } else {
          this.contextService.selectedRows.push(data);
          this.highlightRow(target);
        }
      } else {
        this.contextService.selectedRows.push(data);
        this.highlightRow(target);
      }
    }
    this.helper.disableRows(this.activeTable);

    this.contextService.log(this.contextService.selectedRows.length);
    this.contextService.log(this.contextService.selectedRows);
    if (this.contextService.selectedRows.length > 0) {

      this.contextService.log(this.contextService.selectedRows[0].callStatus);
      let callState: string = this.contextService.selectedRows[0].callStatus;
      let personalityState: string = this.contextService.selectedRows[0].personality;

      let callId: string = this.contextService.selectedRows[0].callId;
      this.buttonStates(callState, personalityState, callId);

      let recordState: string = this.contextService.selectedRows[0].recordState;
      this.contextService.recordingButtonState(callState, recordState);
    }

  }

  /**
   * This method is used to manage all button states
   * 
   * @param {string} callState current call status
   * @param {string} personalityState 1. Originator 2. Terminator
   * @memberof DropCallAreaComponent
   */
  buttonStates(callState: string, personalityState: string, callId: string) {
    this.contextService.log("start method: buttonStates()");

    if (callId === this.contextService.warmTransferCallId || callId === this.contextService.activeCallIdWhenWarmTransfer) {

      if (callState === "Alerting") {
        this.contextService.showWarnTransferButton = false;
      } else {
        this.contextService.showWarnTransferButton = true;
      }
    } else {
      this.contextService.showWarnTransferButton = false;
    }
    //Incoming call
    if (personalityState === 'Terminator' && callState === 'Alerting') {


      this.contextService.releaseAnswerState = "answer";

      this.contextService.transferState = "disabled";
      this.contextService.holdRetrieveState = "holdDisabled";
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = "conferenceDisable";
      }
    }
    //Outgoing Call
    if (personalityState === 'Originator' && callState === 'Alerting') {
      this.contextService.releaseAnswerState = "release";

      this.contextService.transferState = "disabled";
      this.contextService.holdRetrieveState = "holdDisabled";
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = "conferenceDisable";
      }
    }
    //Release, Transfer
    if (callState === "Released" || callState === 'Detached') {

      this.contextService.releaseAnswerState = "release";

    }
    //Active call
    if (callState === "Active") {
      this.contextService.releaseAnswerState = "release";
      this.contextService.holdRetrieveState = "hold";
      this.contextService.transferState = "enabled";

      //athul
      this.enableDisableConference();
      //athul
    }
    // call on hold
    if (callState === "Held") {
      this.contextService.holdRetrieveState = "retrieve";
      this.contextService.transferState = "enabled";
      this.contextService.releaseAnswerState = "release";

      //athul
      this.enableDisableConference();
      //athul
    }
    this.contextService.log("End method: buttonStates()");

  }
  enableDisableConference() {
    let callCount: number = 0;
    this.contextService.callStatusList.forEach(element => {
      if (element.callStatus !== 'Alerting') {
        callCount = callCount + 1;
      }
    });
    // If call count >=2, enable conference button else disable conference button
    if (callCount >= 2) {
      if ((this.contextService.assignedServices.includes('Three-WayCall')) || (this.contextService.assignedServices.includes('N-WayCall'))) {

        this.contextService.conferenceState = 'conferenceActive';
      }
    } else {
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = 'conferenceDisable';
      }
    }
  }

  getTargetParent(tar) {
    let parent;
    if (tar.parentNode.tagName === 'TR') {
      parent = tar.parentNode;
    } else if (tar.parentNode.parentNode.tagName === 'TR') {
      parent = tar.parentNode.parentNode;
    } else if (tar.parentNode.parentNode.parentNode.tagName === 'TR') {
      parent = tar.parentNode.parentNode.parentNode;
    }
    return parent;
  }

  highlightRow(target) {
    let targetParent = this.getTargetParent(target);
    if (targetParent) {
      if (this.helper.hasClass(targetParent, 'ui-state-highlight')) {
        return;
      } else {
        this.helper.addClass(targetParent, 'ui-state-highlight');
      }
    }
  }

  setRowClass(rowData: any, rowIndex: number) {
    if (rowIndex === 0) {
      return 'calling act';
    } else {
      return 'act';
    }
  }

  ngOnDestroy() {

  }
  onRowSelect(event) {


  }

  /**
   * This method manage double on calls inside active call window.when doble click on each row, 
   * send answer request according to the call state
   * 
   * @param {any} event 
   * @memberof DropCallAreaComponent
   */
  onRowDblclick(event) {
    this.contextService.log("start method: onRowDblclick()");

    this.callStatusEntry = event;
    this.contextService.log(this.callStatusEntry);

    this.contextService.log(this.callStatusEntry.callId);

    if (this.callStatusEntry.callStatus === "Alerting") {
      this.contextService.log("call state is alerting and performed answerAction to call Id: " + this.callStatusEntry.callId);
    }

    // c8 demo for new types
    if (event instanceof CallBackEntry) {

      this.messagesService.openCallBackDialog.next(true);

    } else if (event instanceof WebChatEntry) {

      this.messagesService.openWebChatDialog.next(true);

    } else if (event instanceof TwitterEntry) {

      this.messagesService.openTwitterDialog.next(true);

    } else if (event instanceof EmailEntry) {

      this.messagesService.openEmailDialog.next(true);

    } else if (event instanceof SmsEntry) {

      this.messagesService.openSmsDialog.next(true);

    } else {

      if (this.callStatusEntry.callStatus === "Active") {
        if (this.callStatusEntry.isConference) {
          this.contextService.log("call state is isConference")
        } else {
          this.contextService.websocketService.holdAction(this.callStatusEntry.callId);
          this.contextService.log("call state is Active and performed holdAction to call Id: " + this.callStatusEntry.callId);
        }
      }
      if (this.callStatusEntry.callStatus === "Held") {
        if (this.callStatusEntry.isConference) {
          this.contextService.log("call state is isConference answer")

        } else {
          this.contextService.log("call state is Held and performed answerAction to call Id: " + this.callStatusEntry.callId);
        }
      }

    }
    this.contextService.log("End method: onRowDblclick()");

  }

  /**
   * populate active call window context menu, This menu shows all options(instanse message, add to personal directory,escalate to supervisor,add disposition code.. etc)
   * @param event 
   */
  showCallWindowMenu(cm) {
    this.contextService.log("start method: showCallWindowMenu");
    this.helper.hideAllCMenus();
    this.helper.saveAllCMenus(cm);

    this.activeCallWindowMenu = [];  //clear context menu
    // let dispositionMenuItems: MenuItem[] = [];
    this.dispositionMenuItems = [];
    this.ongoingCallDispositionArray = [];
    this.previousCallDispositionArray = [];

    // c8 this is demo for new types (email, tw, sms ....)
    if (
      this.contextService.selectedRows &&
      this.contextService.selectedRows.length &&
      (
        this.contextService.selectedRows[0] instanceof CallBackEntry ||
        this.contextService.selectedRows[0] instanceof WebChatEntry ||
        this.contextService.selectedRows[0] instanceof TwitterEntry ||
        this.contextService.selectedRows[0] instanceof TwitterEntry ||
        this.contextService.selectedRows[0] instanceof EmailEntry ||
        this.contextService.selectedRows[0] instanceof SmsEntry
      )
    ) {
      var m = this.contextService.selectedRows[0];

      this.activeCallWindowMenu = [
        {
          label: this.contextService.getLanguageTagByKey('Menu.Context.Transfer'),
          visible: true,
          items: this.messagesService.loadTransferToList(this.transferToDemo)
        },
        { separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Messages.Reserve'), icon: 'tick', command: (event) => this.reserveDemo(m), visible: true },
        { label: this.contextService.getLanguageTagByKey('Messages.Reject'), icon: 'ico-reject', command: (event) => this.rejectDemo(m), visible: true },
        { label: this.contextService.getLanguageTagByKey('Messages.Close'), icon: 'ico-close', command: (event) => this.closeDemo(m), visible: true },
      ];
      this.activeCallWindowMenu.push(
        { separator: true, visible: true }
      );
    } else {

      if (this.contextService.callStatusList.length === 1) { //One call in active call window
        //for queued calls
        if (this.contextService.callStatusList[0].isQueuedCall) {
          this.insertMenuItemsForQueuedCalls(this.contextService.callStatusList[0]);
        } else {
          this.insertMenuItems(); //insert menu items(1. Add to personal directory 2. instance message)
          this.selectedCallLogUsingContextMenu = this.contextService.callStatusList[0];
        }
        //for queued calls
        if (this.contextService.ongoingCallCenterCallDetails) { //check ongoing  call center details
          this.insertMenuItemsForSelected();
          this.insertDispositionCodesForOnGoingCalls(this.contextService.callStatusList[0].callId); //create disposition code menu for on-going call 
          if (this.ongoingCallDispositionArray.length > 0) {
            this.dispositionMenuItems.push({
              label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode.SelectedCall'),
              items: this.ongoingCallDispositionArray
            });
          }
        }
        if (this.contextService.previousCallCenterCallDetails) {  //check for previous call center details    

          this.insertDispositionCodesForPreviousCalls(); //create disposition menu for previous call center call
          if (this.previousCallDispositionArray.length > 0) {

            this.dispositionMenuItems.push({ //create disposition menu with from and To
              label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode.LastCall') + " " + this.contextService.getLastNameFromUserID(this.contextService.previousCallCenterCallDetails.callcenterId) + " " + this.contextService.getNameFromUserID(this.contextService.previousCallCenterCallDetails.remoteUserId),
              items: this.previousCallDispositionArray
            });
          }
        }
        if (this.contextService.ongoingCallCenterCallDetails || this.contextService.previousCallCenterCallDetails) { // check any on-going call center call OR previous call center call, if found enable disposition menu
          if (this.dispositionMenuItems.length > 0) {
            this.activeCallWindowMenu.push(
              { separator: true, visible: true }
            );
          }
          this.enableDispositionMenu(); //enable disposition menu
        }

      } else if (this.contextService.callStatusList.length > 1) { // More than 1 calls in active call window

        if (this.contextService.selectedRows.length == 1) { //check any row or call selected in active call window

          //for queued calls

          if (this.contextService.selectedRows[0].isQueuedCall) {
            this.insertMenuItemsForQueuedCalls(this.contextService.selectedRows[0]);
          } else {
            this.insertMenuItems(); //insert menu items(1. Add to personal directory 2. instance message)
          }
          //for queued calls

          if (this.contextService.selectedRows[0].isCallCenterCall && this.contextService.ongoingCallCenterCallDetails) { //check selected call is call center call and any data for ongoing call center call
            this.insertDispositionCodesForOnGoingCalls(this.contextService.selectedRows[0].callId); //Create disposition codes menu for on-going call
            if (this.ongoingCallDispositionArray.length > 0) {
              this.dispositionMenuItems.push({ //create disposition option menu
                label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode.SelectedCall'),
                items: this.ongoingCallDispositionArray
              });
            }
            this.insertMenuItemsForSelected();
          }
          if (this.contextService.selectedRows[0].isCallCenterCall && this.contextService.previousCallCenterCallDetails) { //Check selected call is call center call and any data for previous call center call

            this.insertDispositionCodesForPreviousCalls(); //Create disposition code menu for previous call center call
            if (this.previousCallDispositionArray) {

              this.dispositionMenuItems.push({
                label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode.LastCall') + " " + this.contextService.getLastNameFromUserID(this.contextService.previousCallCenterCallDetails.callcenterId) + " " + this.contextService.getNameFromUserID(this.contextService.previousCallCenterCallDetails.remoteUserId),
                items: this.previousCallDispositionArray
              });

            }
          }
          if (this.contextService.selectedRows[0].isCallCenterCall) {
            if (this.dispositionMenuItems.length > 0) {
              this.activeCallWindowMenu.push(
                { separator: true, visible: true }
              );
            }
            this.enableDispositionMenu();
          }
          this.selectedCallLogUsingContextMenu = this.contextService.selectedRows[0];
        }
      } else if (this.contextService.callStatusList.length === 0) { //No calls : If there is no calls in active call window, then only shows disposition menu for previous call

        if (this.contextService.previousCallCenterCallDetails) {  //check previous call center details    

          this.insertDispositionCodesForPreviousCalls(); //Create disposition code menu for previous call center call
          if (this.previousCallDispositionArray.length > 0) {
            this.dispositionMenuItems.push({
              label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode.LastCall') + " " + this.contextService.getLastNameFromUserID(this.contextService.previousCallCenterCallDetails.callcenterId) + " " + this.contextService.getNameFromUserID(this.contextService.previousCallCenterCallDetails.remoteUserId),
              items: this.previousCallDispositionArray
            });
          }
          this.enableDispositionMenu();
        }
      }
    }


    this.contextService.log("End method: showCallWindowMenu");
    cm.show();

  }
  /**
   *  Create disposition code for on-going call
   * @param callId  call-half id
   */
  insertDispositionCodesForOnGoingCalls(callId) {
    this.contextService.log("start method: insertDispositionCodesForOnGoingCalls(" + callId + ")");

    if (this.contextService.ongoingCallCenterCallDetails) {
      this.contextService.agentDetails.callCenters[this.contextService.ongoingCallCenterCallDetails.callcenterId].dispositionCodeList.forEach(element => {

        let disabledstateOngoingCal: boolean = false;
        this.contextService.onGoingDispositionSetList.forEach(elementL => {
          if (elementL.callId == callId) {
            elementL.dispositionCodesArray.forEach(elementI => {
              if (elementI === element.code) {
                disabledstateOngoingCal = true;
              }
            });
          }
        });
        this.ongoingCallDispositionArray.push(
          { label: element.description, target: element.code, command: (event) => this.setDispositionCode(element.code, callId), icon: '', disabled: disabledstateOngoingCal }
        )
      });
    }
    this.contextService.log("start method: insertDispositionCodesForOnGoingCalls()");

  }
  /**
   * Create disposition code menu for previous call center call
   */
  insertDispositionCodesForPreviousCalls() {
    this.contextService.log("start method: insertDispositionCodesForPreviousCalls()");

    if (this.contextService.previousCallCenterCallDetails) {
      this.contextService.agentDetails.callCenters[this.contextService.previousCallCenterCallDetails.callcenterId].dispositionCodeList.forEach(element => {
        let codeStateForPreviousCall: boolean = false;
        this.contextService.previousDispositionSetList.forEach(elementPreviousArray => {
          if (elementPreviousArray === element.code) {
            codeStateForPreviousCall = true;
          }
        });
        this.previousCallDispositionArray.push(
          { label: element.description, target: element.code, command: (event) => this.setDispositionCode(element.code, ''), icon: '', visible: true, disabled: codeStateForPreviousCall }
        )
      });
    }
    this.contextService.log("End method: insertDispositionCodesForPreviousCalls()");

  }
  /**
   * Enable disposition code only for call center calls
   */
  enableDispositionMenu() {
    this.contextService.log("start method: enableDispositionMenu()");
    if (this.dispositionMenuItems.length > 0) {
      this.activeCallWindowMenu.push(
        {
          label: this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode'),
          items: this.dispositionMenuItems
        });
    }
    this.contextService.log("End method: enableDispositionMenu()");

  }
  /**
   * For creating menu item(Add to personal directoty, Instance message)
   */
  insertMenuItems() {
    this.contextService.log("start method: insertMenuItems()");

    this.activeCallWindowMenu = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.AddTo'), command: (event) => this.addToPersonalDirectory(), visible: true },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.instantMessage(event), visible: false },
    ];
    if (this.contextService.isImConnected) {
      this.directoryService.directoryDisplayArrayView.forEach(element => {

        if (this.contextService.callStatusList[0].remoteUserId == element.userId) {
          if (element.type === 'Broadworks Directory') {

            let objectFound = this.activeCallWindowMenu[1];
            objectFound.visible = true;
            objectFound.separator = true;

            let objectFound1 = this.activeCallWindowMenu[2];
            objectFound1.visible = true;
          }
        }
      });
    }
    this.contextService.log("End method: insertMenuItems()");
  }
  /**
   * For set disposition code while selecting description from context menu
   * @param code  disposition code
   * @param callId call-half id 
   */
  setDispositionCode(code, callId) {
  }
  sortSupervisor(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key) && this.contextService.objLoggedUserDetails.getUserId() !== key)
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }
  insertMenuItemsForSelected() {
    //add supervisor menu in tools 
    let supervisorMenu: MenuItem[];
    supervisorMenu = [
      {
        label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Supervisors')
      }
    ];
    let isSupervisorForMe: boolean = false;
    supervisorMenu[0].items = [];
    let supervisorList;
    if (this.contextService.ongoingCallCenterCallDetails) {
      supervisorList = this.sortSupervisor(this.contextService.agentDetails.callCenters[this.contextService.ongoingCallCenterCallDetails.callcenterId].supervisorList);//sort supervisor list based on name
      let k = 0;
      for (let j = 0; j < supervisorList.length; j++) {
        let supervisor = supervisorList[j];
        if (supervisor[1].isMonitoringMe === true) {
          /* make true only if the call is a call center call and for 
        the monitoring supervisor so that escalate/transfer could be performed only for that supervisor*/
          isSupervisorForMe = true;
        } else {
          if (this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor === true) {
            continue; // don't add other supervisors if the flag is true; skipping loop to the next supervisor
          }
          isSupervisorForMe = false;
        }
        //adding supervisors to the supervisor menu
        (<MenuItem[]>supervisorMenu[0].items).push(
          {
            label: supervisor[1].firstName + " " + supervisor[1].lastName, items: []
          });

        if (isSupervisorForMe) {
          (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
            { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall'), command: (event) => { this.callActionsInQueue('escalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true },
            { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall'), command: (event) => { this.callActionsInQueue('emergencyEscalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
          );
        } else {
          (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
            { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => { this.contextService.websocketService.dialAction(this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
          );
        }
        (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
          { separator: true, visible: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => { this.callActionsInQueue('transfer', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
        );
        if (this.contextService.isImConnected) {
          (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
            { separator: this.contextService.isImConnected, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => { this.contextService.instantMessage(supervisor[1].id) }, visible: true }
          );
        }
        k++;
      }
    }
    this.activeCallWindowMenu = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.AddTo'), command: (event) => this.addToPersonalDirectory(), visible: true },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.instantMessage(event), visible: false },
      { separator: true, visible: true },
      { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall.First'), command: (event) => { this.callActionsInQueue('escalateToFirst') }, visible: true },
      { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall.First'), command: (event) => { this.callActionsInQueue('emergencyEscalateToFirst') }, visible: true }
    ];
    //insert supervisor menu only if supervisors are returned from the server
    if (supervisorMenu[0].items.length > 0) {
      this.activeCallWindowMenu.push({ separator: true, visible: true },
        supervisorMenu[0])
    }
    if (this.contextService.isImConnected) {
      this.directoryService.directoryDisplayArrayView.forEach(element => {
        if (this.contextService.callStatusList[0].remoteUserId == element.userId) {
          if (element.type === 'Broadworks Directory') {

            let objectFound = this.activeCallWindowMenu[1];
            objectFound.visible = true;
            objectFound.separator = true;

            objectFound = this.activeCallWindowMenu[2];
            objectFound.visible = true;
          }
        }
      });
    }
  }
  callActionsInQueue(action: string, transferTo?: string) {

  }
  sortAgents(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }

  showAgentActivity(queueID: any) {
    this.contextService.updateTabSelection.next('agentactivity');
    this.contextService.showAgentActivity.next({ 'command': 'ShowAgentActivity', 'queueID': queueID });
  }
  //for queued calls
  insertMenuItemsForQueuedCalls(queuedCall: CallStatusEntry) {
    this.activeCallWindowMenu = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.AnswerCall'), command: (event) => { this.contextService.websocketService.answerQueuedCallAction(this.contextService.objLoggedUserDetails.getextension(), queuedCall.callId, queuedCall.callcenterId) }, visible: true }
    ];
    if (this.contextService.objSettings.STARTUP === 3) {
      let transferMenu = [];
      let agentsList = [];
      let callCenterList = [];
      for (const key in this.contextService.agentDetails.callCenters) {
        if (this.contextService.agentDetails.callCenters.hasOwnProperty(key) && key !== queuedCall.callcenterId) {
          callCenterList.push({ label: this.contextService.agentDetails.callCenters[key].callCenterName, command: (event) => { this.contextService.websocketService.answerQueuedCallAction(this.contextService.agentDetails.callCenters[key].extension, queuedCall.callId, queuedCall.callcenterId) }, visible: true });

        }
        if (key === queuedCall.callcenterId) {
          let sortedAgents = this.sortAgents(this.contextService.agentDetails.callCenters[key].agentList);
          for (let j = 0; j < sortedAgents.length; j++) {
            let agent = sortedAgents[j];
            if (agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
              agentsList.push({ label: agent[1].firstName + " " + agent[1].lastName, command: (event) => { this.contextService.websocketService.answerQueuedCallAction(agent[1].extension, queuedCall.callId, queuedCall.callcenterId) }, visible: true });
            }
          }
        }

      }
      transferMenu = [
        { label: this.contextService.getLanguageTagByKey('List.Statistics.CallCenter'), items: callCenterList, command: (event) => { }, visible: true },
        { separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent'), items: agentsList, command: (event) => { }, visible: true }

      ];

      if (queuedCall.acdPriority === "0-Highest") {
        if (queuedCall.position !== 1) {
          this.activeCallWindowMenu.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.PromoteCall'), command: (event) => { }, visible: true });
        }

      } else {
        this.activeCallWindowMenu.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.PromoteCall'), command: (event) => { }, visible: true });


      }
      this.activeCallWindowMenu.push(
        { separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Menu.Context.Transfer'), items: transferMenu, command: (event) => { }, visible: true },
        { separator: true, visible: true },
        { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ShowActivity'), command: (event) => { }, visible: true }
      );
    }
  }
  promoteCall(queuedCall: CallStatusEntry) {

  }
}
