import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { sprintf } from "sprintf-js";
import { ContextService } from '../providers/context.service';
import { HelperService } from '../providers/helper.service';
@Component({
    selector: 'app-startup',
    templateUrl: './startup.component.html',
    styleUrls: ['./startup.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class StartUpComponent {
    confirmVis: boolean = false;
    errorVis: boolean = false;
    initial: boolean = false;
    closeVis: boolean = false;
    serverAddressError: boolean = false;
    hostDeviceVis: boolean = false;
    hostDeviceAlert: string = "";

    loginFailed: string = "";
    callButtonShowing: string = "";
    promptShutdown: string = "";
    miscYes: string = "";
    miscNo: string = "";
    promptHostName: string = "";
    connectionDroppedAlert: string = "";
    errorMessage: string = "";
    ctiErrorVis: boolean = false;

    signOutAlert: string = "";
    logoutSuccess: string = "";
    signOut: boolean = false;
    restartText: string = "";
    ociErrorVis: boolean = false;
    voipServerPort: string = "";
    exceptionMessage: string = "";

    noNetworkVis: boolean = false;
    noInternetAlert: string = "";
    forcedForwardingCCId: string;
    btnOk: string;
    miscPhone: string;
    btnCancel: string;
    forcedForwardingLabel: string = "";
    form: FormGroup;
    forcedForwardingNum: string;
    constructor(private fb: FormBuilder, public contextService: ContextService, private router: Router, private helper: HelperService) {
        window.onkeydown = (ev: KeyboardEvent): any => {
            if (ev.keyCode == 9) {
                if (!this.contextService.loadLoginPage) {
                    ev.preventDefault();
                }
            }
        }

        this.contextService.noNetworkVis.subscribe(val => {
            this.noNetworkVis = val;
        });

        this.helper.closeModalVis.subscribe(val => {
            if (this.contextService.isElectron === true) {
                this.contextService.electronService.ipcRenderer.send('closeUnity');
            } else {
                this.closeVis = true;
            }
        });
        this.contextService.confirmModalVis.subscribe(val => {
            if (val.errorType === "authentication") {
                this.loginFailed = this.contextService.getLanguageTagByKey('Prompt.Login.Failed');
                this.confirmVis = true;
            } else if (val.errorType === "network") {
                if (!this.contextService.loadLoginPage) {
                    this.serverAddressError = true;
                }
            } else if (val.errorType === "Branding") {
                this.loginFailed = this.contextService.getLanguageTagByKey('Prompt.BrandingResponseUnsuccessful');
                this.confirmVis = true;
            } else if (val.errorType === "License") {
                this.loginFailed = this.contextService.getLanguageTagByKey('Prompt.License.LicenseRequestFailed');
                this.confirmVis = true;
            } else if (val.errorType === "Register_AES") {
                this.loginFailed = this.contextService.getLanguageTagByKey('Prompt.Login.Failed');
                this.contextService.objSettings.sessionId = "";
                this.contextService.objSettings.broadworks_Password = "";
                this.confirmVis = true;
            }
        });
        this.contextService.errorVisible.subscribe(val => {
            this.exceptionMessage = "";
            if (val.errorType === "GetServiceConfiguration") {
                this.errorMessage = this.contextService.getLanguageTagByKey('Prompt.RequestFailed') + " " + val.errorType + " (" + val.serviceName + ")";
                this.errorVis = true;
            } else if (val.errorType === "SetServiceConfiguration") {
                this.errorMessage = this.contextService.getLanguageTagByKey('Prompt.RequestFailed') + " " + val.errorType + " (" + val.serviceName + ")";
                this.errorVis = true;
            } else if (val.errorType === "OCI") {
                this.errorMessage = "OCI : " + val.errorMessage;
                this.errorVis = true;
            } else if (val.errorMessage === "Redis Exception") {
                this.errorMessage = this.contextService.getLanguageTagByKey('Prompt.SettingsNotSaved');
                this.errorVis = true;
            } else if (val.errorType === "AddOCIChannel") {
                this.voipServerPort = this.contextService.objLoggedUserDetails.getvoipServerPort();
                this.ociErrorVis = true;
            }
            else if (val.errorType === "pageNotFound") {
                this.errorMessage = this.contextService.getLanguageTagByKey('Prompt.OciOverSoap');
                this.errorVis = true;
            }
            else if (val.errorType === "GetOffice365Contacts") {
                if (val.exceptionMessage.contains('MailboxNotEnabledForRESTAPI')) {
                    this.errorMessage = "Office365Contacts: REST API is not yet supported for this mailbox";
                    this.contextService.office365APISupported = false;
                    this.errorVis = true;
                }
            }
            else {
                this.errorMessage = this.contextService.getLanguageTagByKey('Prompt.RequestFailed') + " " + val.errorType;
                if(val.exceptionMessage){
                this.exceptionMessage = "Exception: " + val.exceptionMessage;
                }
                this.errorVis = true;
            }
        });
        this.contextService.websocketService.loadAuthentication.subscribe(val => {
            if (val === true) {
                if (this.contextService.objSettings.userLoginForTheFirstTime || this.contextService.isFirstTimeLogin) {
                    this.contextService.loadLoginPage = true;
                    this.contextService.loginVisible.next(true);
                }
            }
            else {
                this.contextService.openServerDetails = false;
                this.contextService.loadingFlag = true;
                this.contextService.loadLoginPage = true;
                this.contextService.loginVisible.next(true);
            }
        });

        this.contextService.signout.subscribe(val => {
            if (val === "signOut") {
                this.signOutAlert = this.logoutSuccess;
            } else {
                this.signOutAlert = "You have been signed out due to signing in from another Unity Webclient";
            }
            this.signOut = true;
            this.contextService.noStatus = false;
        });

        this.contextService.websocketService.connectionDroped.subscribe((val) => {
            if (!this.confirmVis && !this.serverAddressError && !this.contextService.isSignedOut && !this.contextService.reConnect) {
                if (val === "internalError") {
                    this.connectionDroppedAlert = this.contextService.getLanguageTagByKey('Prompt.InternalError');
                }
                else {
                    this.connectionDroppedAlert = this.contextService.getLanguageTagByKey('Prompt.Connection.Dropped');
                }
                this.contextService.cticonnectiondroppedvis = true;
            }
        });
        if (this.contextService.isElectron === true) {
            this.contextService.electronService.ipcRenderer.on('confirmQuit', (event, message) => {
                if (this.contextService.isFirstTimeLogin === true) {
                    this.contextService.electronService.ipcRenderer.send('quitUnity');
                } else {
                    this.closeVis = true;
                }
            });
            this.contextService.electronService.ipcRenderer.on('log', (event, message) => {
                this.contextService.log("from electron: " + message.msg);
                // console.log(event);
                // console.log(message);
            });
        }
    }

    ngOnInit() {

        this.form = this.fb.group({
            phone: ['', Validators.required]
        });

        this.loginFailed = this.contextService.getLanguageTagByKey('Prompt.Login.Failed');
        this.callButtonShowing = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
        this.promptShutdown = this.contextService.getLanguageTagByKey('Prompt.Shutdown');
        this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
        this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
        this.promptHostName = this.contextService.getLanguageTagByKey('Prompt.HostName');
        this.connectionDroppedAlert = this.contextService.getLanguageTagByKey('Prompt.Connection.Dropped');
        this.logoutSuccess = this.contextService.getLanguageTagByKey('Misc.Logout.Success');
        this.restartText = this.contextService.getLanguageTagByKey('Menu.File.Restart');
        this.noInternetAlert = this.contextService.getLanguageTagByKey('Prompt.ConnectionDropped');
        this.miscPhone = this.contextService.getLanguageTagByKey('Misc.Number');
        this.btnOk = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
        this.btnCancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
        this.forcedForwardingLabel = this.contextService.getLanguageTagByKey('Misc.ForcedForward');
    }

    closeModal() {
        this.contextService.forcedForwardingVisible = false;
        this.forcedForwardingNum = "";
    }
    onSubmit() {
             this.contextService.forcedForwardingVisible = false;
        this.forcedForwardingNum = "";
    }
    serverError() {
        this.contextService.openServerDetails = false;
        this.contextService.reConnect = true;
        this.contextService.loadLoginPage = true;
        this.contextService.loadingFlag = false;
        this.contextService.loginVisible.next(true);
        this.serverAddressError = false;
    }
    restart() {
        this.contextService.cticonnectiondroppedvis = false;
        if (this.contextService.isElectron === true) {
            this.contextService.electronService.ipcRenderer.send('restart');
        } else if (this.contextService.isElectron === false) {
            location.reload();
        }
    }
    reload() {
        this.signOut = false;
        location.reload();
    }
    numValidate(event) {
        if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 40 || event.charCode == 41 || event.charCode == 46) {
            return true;
        }
        return false;
    }
    closeUnity() {
        if (this.contextService.loadingFlag === false) {
            this.contextService.objSettings.shutdownTime = new Date();
            this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
        }
        if (this.contextService.isElectron === true) {
            if (this.contextService.assignedServices.includes("CommPilotExpress")) {
                if (this.contextService.objSettings.commPilotExpress_ValueOnUnityStop != "" && this.contextService.objSettings.commPilotExpress_ValueOnUnityStop != undefined) {
                    if (this.contextService.objSettings.commPilotExpress_ValueOnUnityStop == "none") {
                        this.contextService.objSettings.commPilotExpress_ValueOnUnityStop = "";
                    }
                    if (this.contextService.objSettings.commPilotExpress_ValueOnUnityStop != "notSet") {
                        this.contextService.websocketService.commPilotExpress(this.contextService.objSettings.commPilotExpress_ValueOnUnityStop);
                    }
                }
            }

            if (this.contextService.hostID != "" || this.contextService.hostIDFlexibleSeating != "") {
                this.closeVis = false;
                if (this.contextService.hostID != "") {
                    this.hostDeviceAlert = sprintf(this.contextService.getLanguageTagByKey('Prompt.DeactivateHotelingHost'), this.contextService.hostID);
                }
                else {
                    this.hostDeviceAlert = sprintf(this.contextService.getLanguageTagByKey('Prompt.DeactivateHotelingHost'), this.contextService.hostIDFlexibleSeating);
                }
                this.hostDeviceVis = true;

            }
            else {
                this.contextService.electronService.ipcRenderer.send('quitUnity');
            }

        } else {
            var objWindow = window.open(location.href, "_self");
            objWindow.close();
        }
    }

    authenticationFailed() {
        this.contextService.loadLoginPage = true;
        this.contextService.loadingFlag = false;
        this.contextService.reConnect = true;
        this.contextService.loginVisible.next(true);
        this.confirmVis = false;
    }
    deactivateHostDevice() {
 
    }
    close() {
        this.hostDeviceVis = false;
        this.contextService.electronService.ipcRenderer.send('quitUnity');
    }
    ctiErrorButtonClick() {
        this.contextService.ctiErrorVis = false;
        this.contextService.websocketService.loadAuthentication.next(false);
    }
    noRegisterResponseButtonClick() {
        this.contextService.noRegisterResponseVis = false;
        this.contextService.websocketService.loadAuthentication.next(false);
    }
}
