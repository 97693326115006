import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from "app/providers/context.service";
import { SettingService } from "app/providers/settings/settings-general/setting.service";
import { MenuItem } from 'primeng/api/menuitem';
import { HelperService } from './../../../providers/helper.service';

@Component({
  selector: 'app-settings-services',
  templateUrl: './settings-services.component.html',
  styleUrls: ['./settings-services.component.scss']
})
export class SettingsServicesComponent implements OnInit {
  serviceSettMenu: MenuItem[];
  serviceVis: boolean = false;
  alertValue: string = "";
  searchString: string;
  originalServiceSettMenu: MenuItem[];

  search: string = "";
  callButtonShowingOK: string = "";

  constructor(private router: Router, private settings: SettingService, private contextService: ContextService, private helper: HelperService) { }

  ngOnInit() {
    this.search = this.contextService.getLanguageTagByKey('CallButtonShowing.Search');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');

    this.originalServiceSettMenu = this.settings.getServiceSettMenu();
    this.searchString = '';
    this.serviceSettMenu = [...this.originalServiceSettMenu];
    this.contextService.serviceModalVis.subscribe(val => {
      this.serviceVis = true;
      this.alertValue = val;

    });

  }
  navigate() {
    this.serviceVis = false;
    //Route to corresponding pages when an validation error occured in service and settings pages
    this.contextService.log("Routing page: " + this.contextService.route);
    switch (this.contextService.route) {
      case 'cfa':
        this.router.navigate(['/settings-service', 'call-forwarding-always']);
        break;
      case 'cfb':
        this.router.navigate(['/settings-service', 'call-forwarding-busy']);
        break;
      case 'noAnswer':
        this.router.navigate(['/settings-service', 'call-forward-no-answer']);
        break;
      case 'acdState':
        this.router.navigate(['/settings-service', 'acd-state']);
        break;
      case 'inOffice':
        this.router.navigate(['/settings-service', 'available-in-office']);
        break;
      case 'outOffice':
        this.router.navigate(['/settings-service', 'available-out-of-office']);
        break;
      case 'busy':
        this.router.navigate(['/settings-service', 'busy']);
        break;
      case 'unavailable':
        this.router.navigate(['/settings-service', 'unavailable']);
        break;
      case 'simultaneous':
        this.router.navigate(['/settings-service', 'simultaneous-ring']);
        break;
      case 'callTransfer':
        this.router.navigate(['/settings-service', 'call-transfer']);
        break;
      case 'FlexibleSeatingGuest':
        this.router.navigate(['/settings-service', 'flexible-seating-guest']);
        break;
      case 'hotelingGuest':
        this.router.navigate(['/settings-service', 'hoteling-guest']);
        break;
      case 'remoteOffice':
        this.router.navigate(['/settings-service', 'remote-office']);
        break;
      case 'voicemail':
        this.router.navigate(['/settings-service', 'voice-mail-service']);
        break;
      case 'dialing':
        this.router.navigate(['/settings-general', 'dialing-rule']);
        break;
      case 'network':
        this.router.navigate(['/settings-general', 'network']);
        break;
      case 'noAuthentication':
        this.router.navigate(['/settings-general', 'authentication']);
        break;
      case 'noDomain':
        this.router.navigate(['/settings-general', 'authentication']);
        break;
      case 'statistics':
        this.router.navigate(['/settings-service', 'statistics']);
        break;
      case 'browserIntegration':
        this.router.navigate(['/settings-general', 'browser-integration']);
        break;
      case 'supervisor':
        this.router.navigate(['/settings-general','suprevisor']);
        break;
      default: {
        this.contextService.log("Routing page not in switch case: " + this.contextService.route)
      }
    }
  }
  filterMenu(s) {
    let exist = false;
    this.searchString = s;
    if (s.length) {
      this.settings.serviceTreeArray.forEach(element => {
        if (element.includes(this.searchString)) {
          exist = true;
        }

      });

      if (exist) {
        this.serviceSettMenu = this.helper.searchNodes(this.searchString, this.helper.clone(this.originalServiceSettMenu, true), 'items');
      } else {
        this.serviceSettMenu = this.helper.searchNodes(this.searchString, this.helper.clone(this.originalServiceSettMenu, true), 'children');
      }

    } else {
      this.serviceSettMenu = this.originalServiceSettMenu;
    }
  }
}
