<p-dialog [resizable]="false" [(visible)]="viewUserDetailsModalVisible" [ngClass]="{visible: viewUserDetailsModalVisible}" [closable]="false" [style]="{'width':'380px'}" class="small-dialog out-of-office-dialog"
  dismissableMask="true" showHeader="true">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{name}}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
    </div>
  </p-header>


  <div class="modal-content">
    
      <div class="row">
        <div class="row">
          <label for="" class="label">{{userDetailsTitle}} {{title}}</label>
        </div>
        <div class="row">
          <label for="" class="label">{{userDetailsEmail}} {{email}}</label>
        </div>
        <div class="row">
          <label for="" class="label">{{userDetailsPhone}}: {{phone}}</label>
        </div>
        <div class="row">
          <label for="" class="label">{{userDetailsMobile}} {{mobile}}</label>
        </div>
        <div class="row">
          <label for="" class="label">{{userDetailsDepartment}}: {{department}}</label>
        </div>
        <div class="row">
          <label for="" class="label">{{userDetailsLocation}}: {{location}}</label>
        </div>
      </div>
    
  </div>
  

  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div>
        <button type="button" class="btn-blue-light" (click)="closeModal()">{{callButtonShowingOk}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>




