import { Component, OnInit } from '@angular/core';
import { ContextService } from "../../../../../../providers/context.service";
import { SettingsGeneral } from "../../../../../../providers/settings/settings-general/settings-general.service";

@Component({
  selector: 'app-statistics-service',
  templateUrl: './statistics-service.component.html',
  styleUrls: ['./statistics-service.component.scss']
})
export class StatisticsServiceComponent implements OnInit {

  description: string = "";
  summaryStatistics: string = "";
  scrollbarDescription: string = "";
  processCallCenterDescription: string = "";
  refreshFreqDescription: string = "";
  constructor(public contextService: ContextService,public settingsGeneral:SettingsGeneral) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.Description');
    this.summaryStatistics = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.OnlyShowSummaryStatistics');
    this.scrollbarDescription = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.UseScrollbars');
    this.processCallCenterDescription = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.BatchLimit');
    this.refreshFreqDescription = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.RefreshFrequency');
    this.settingsGeneral.settingsChanged=true;
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
