import { Injectable } from '@angular/core';
import { CallForwardingBusy } from '../../../model/bw-services/call-forwarding-busy';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';

/**
 * to register callForwardingBusyResponseHandler   
 * 
 * @export
 * @class CallForwardingBusyService
 */

@Injectable()

export class CallForwardingBusyService {
    phNumber: string = "";
    isActive: boolean = false;
    numberValidation: boolean = false;
    callForwardingBusy: CallForwardingBusy;
    busyChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - CallForwardingBusyService');
    }
    setData(callForwardingBusy: CallForwardingBusy) {
    }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) {
    }
    save() {
    }
    populateData() {
    }
    cancel() {
    }

}