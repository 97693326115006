<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)="focus()" label={{enabled}} [(ngModel)]="callForwardningAlwaysService.isActive"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{forwardNumber}}</label>
  <input type="text" name="input-text" id="textarea"  [(ngModel)]="callForwardningAlwaysService.phNumber" [disabled]="!callForwardningAlwaysService.isActive" autocomplete=off>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup2" value="checkbox" label={{ringSplash}} [(ngModel)]="callForwardningAlwaysService.isRingSplash"
    binary="true" [disabled]="!callForwardningAlwaysService.isActive"></p-checkbox>
</div>