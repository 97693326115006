import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Attachment } from '../../model/attachment.model';

@Component({
  selector: 'app-web-chat-conversation',
  templateUrl: './web-chat-conversation.component.html',
  styleUrls: ['./web-chat-conversation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WebChatConversationComponent implements OnInit, OnChanges {

  @Output() onMessageSubmit = new EventEmitter<any>();
  @Input() predefinedMessage: string;
  @Input() componentName: string;
  @Input() placeholder: string;
  @Input() disableAttachments: boolean = false;
  @ViewChild('textInput', { static: true }) textInput;

  sendlbl: string = "";
  message: string = "";
  writeMessageHere: string = "";
  attachments: Array<Attachment> = [];
  isSingleClick: Boolean = true;

  constructor(public contextService: ContextService, private messagesService: MessagesService) { }

  ngOnInit() {
    this.sendlbl = this.contextService.getLanguageTagByKey('Misc.Send');
    this.writeMessageHere = this.placeholder ? this.placeholder : this.contextService.getLanguageTagByKey('Conversation.WriteMessageHere');
  }

  isValidMessage(): boolean {
    return this.message !== "\n" && this.message.trim().length > 0 && this.message.replace(/\s\n/g, '').length > 0
  }

  onKeyUp(event) {
    if (event.keyCode == 13) {
      if (this.isValidMessage()) {
        console.log(this.message, this.message.replace(/\s\n/g, ''))
        event.preventDefault();
        this.onSubmit();
      }
    }
  }

  onFileSelect(atts: Array<Attachment>) {
    atts.forEach(att => {
      //this.attachments.push(att)
      if (this.componentName !== 'twitter') { // do not send directly for TW
        this.onMessageSubmit.emit(['', att])
      } else {
        this.message += att.url + ' ';
      }
      //
    });
  }

  emojiRightClick(shortCode: string) {
    if (this.componentName !== 'twitter') { // do not send directly for TW
      this.onMessageSubmit.emit([shortCode, null])
    } else {
      this.appendEmoji(shortCode)
    }
  };

  emojiSingleClick(shortCode: string) {

    this.isSingleClick = true;

    setTimeout(() => {
      if (this.isSingleClick) {
        this.appendEmoji(shortCode)
      }
    }, 300)
  };

  emojiDoubleClick(shortCode: string) {
    this.isSingleClick = false;
    if (this.componentName !== 'twitter') { // do not send directly for TW
      this.onMessageSubmit.emit([shortCode, null])
    } else {
      this.appendEmoji(shortCode)
    }
  };


  appendEmoji(shortCode: string) {
    if (this.message.length > 0) {
      this.message += ' ';
    }
    this.message += shortCode + ' ';
    this.focusInput();
  }

  focusInput() {
    setTimeout(() => {
      this.textInput.nativeElement.focus();
    }, 0);
  }

  onSubmit() {
    //this.onMessageSubmit.emit(new Attachment(0, data.title, Date.now(), 99, data.url, 255555));
    this.onMessageSubmit.emit([this.message, this.attachments])
    this.message = '';
    this.attachments = [];
    this.predefinedMessage = ''
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.predefinedMessage && changes.predefinedMessage.currentValue.length) {
      if (this.isValidMessage()) {
        this.message += ' ' + changes.predefinedMessage.currentValue
      } else {
        this.message = changes.predefinedMessage.currentValue
      }

      this.focusInput();
      // document.getElementById(id).focus();
    }
  }

}
