<p-dialog [(visible)]="addPersonalEntryModalVisible" [ngClass]="{visible: addPersonalEntryModalVisible}" [closable]="false" [style]="{'width':'380px'}" class="small-dialog out-of-office-dialog" dismissableMask="true" showHeader="true" [resizable]="false">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{personalDirectoryAddto}}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
    </div> 
  </p-header>  
  <div class="modal-content">   
    <form (ngSubmit)="onSubmit()" [formGroup]="form" #rfRef="ngForm" id="apdForm">
    <div class="row"> 
      <div class="row">
        <label for="" class="label">{{miscName}}</label>
        <input type="text" name="name" [(ngModel)]="directoryService.selectedName" formControlName="name" placeholder="" min="2">
      </div> 
      <div class="row">
        <label for="" class="label">{{miscPhone}}</label>
        <input type="text" name="phone" [(ngModel)]="directoryService.selectedPhone" formControlName="phone" placeholder="" min="2" (keypress)=numValidate($event)>
      </div> 
    </div>
    </form>
  </div>
  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div class="save-settings">
        <button type="button" form="apdForm" (click)="onSubmit()" class="btn-blue-light" >{{btnOk}}</button>
        <a (click)="closeModal()">{{btnCancel}}</a>
      </div>
    </div>
  </p-footer>
</p-dialog><p-dialog [(visible)]="this.directoryService.duplicateAlertVisible" [closable]="false" [style]="{'width':'520px'}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{this.directoryService.duplicateMessage}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.directoryService.duplicateAlertVisible = false">{{btnOk}}</button>
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="this.directoryService.deleteEntryVisible" [closable]="false" [style]="{'width':'450px'}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{removeConfirm}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="onDelete()">{{yesLabel}}</button>
      <button type="button" class="btn-white" (click)="this.directoryService.deleteEntryVisible = false">{{noLabel}}</button>
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="invalidNumber" [closable]="false" [style]="{'width':'450px'}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{invalidPhNo}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="invalidNumber = false">{{btnOk}}</button>
    </div>
  </div>
</p-dialog>