/**
 * Model to store details of the loged in user 
 * contains profile,branding and liscence
 */
export class LoggedUserDetails {
	//profile
	private userId: string;
	private firstName: string;
	private lastName: string;
	private hiranganaLastName: string;
	private hiranganaFirstName: string;
	private groupId: string;
	private serviceProvider: string;
	private isEnterprise: boolean;
	private number: string;
	private extension: string;
	private mobile: string;
	private emailAddress: string;
	private passwordExpiresDays: string;
	private fac: string;
	private registrations: string;
	private scheduleList: string;
	private portalPasswordChange: string;
	private countryCode: string;



	//GetBrandForUserResponse

	private brandSuccess: boolean;
	private userFound: boolean;
	private voipServerPort: string;
	private messagingServerHost: string;
	private messagingServerPort: string;
	private keepAliveCycle: string;
	private ociIsSecure: boolean;
	private ctiOverTlsServerPort: string;
	private ociOverTlsServerHostName: string;
	private ociOverTlsServerPort: string;
	private ociOverTlsSecurityProtocol: string;
	private clientsUseCti: boolean;
	private ctiServerPort: string;
	private ctiIsSecure: boolean;
	private ctiOverTlsServerHostName: string;
	private ctiOverTlsSecurityProtocol: string;
	private logoPath: string;
	private logoUrl: string;
	private iconPath: string;
	private useReplacementIconInShortcut: boolean;
	private resellerNameForClientDirectory: string;
	private groupNamesForClientDirectory: string[];
	private enterpriseViewAvailable: boolean;
	private monitoredUsersDepartmentName: string;
	private monitoredUsersDepartmentLimitAll: boolean;
	private promptUserToSwitchStartupApplication: boolean;
	private instantMessageDomain: string;
	private reportingUrl: string;
	private reportingIsSecure: boolean;
	private xsiSecurityProtocol: string;
	private callRecordingUrl: string;
	private callCenterBatchLimit: string;
	private xsiActionsVersion: string;
	private xsiEventsVersion: string;
	private xsiReportsVersion: string;
	private xsiApplicationId: string;
	private xsiEventsApplicationId: string;
	private reportingAlternateUrl: string;
	private reportingAlternateIsSecure: string;
	private reportingAlternateApplicationID: string;
	private userLoginFailedPrompt: string;
	private callCenterLoginFailedPrompt: string;
	private userCanChangeCallRecordingOption: string;
	private replacementVoipServer: string;
	private replacementBroadworksPhrase: string;
	private replacementClientName: string;


	//GetUserLicenseResult
	private licenseSuccess: boolean;
	private licenseuserId: string;
	private licenseapplicationName: string;
	private licenseType: string;
	private licenseTrialLicenseFunctionalityBasedOn: string;
	private licenseExpiryPrompt: string;
	private licensepurchasePageUrl: string;
	private licenseExpiryYear: string;
	private licenseExpiryMonth: string;
	private licenseExpiryDay: string;
	private licenseUsersThatCannotBeMonitored: string;
	private licenseCallDetailsAvailable: boolean;
	private licenseEnterpriseViewAvailable: boolean;
	private licenseRollbackAvailable: boolean;
	private licenseInstantMessageAvailable: boolean;
	private licenseGetReasonCodesUrl: string;
	private licenseSetReasonCodesUrl: string;
	private licenseMonitoredUsersDepartmentName: string;
	private licenseMonitoredUsersDepartmentLimitAll: boolean;
	private licenseSupportUrl: string;
	private licensePreventAgentFromCallCenterLogin: boolean;
	private licenseAllowUserToChangePassword: boolean;
	private licensePromptUserToChangeStartupApplication: boolean;
	private licenseCustomServiceMenuItem: string;
	private licenseCustomServiceMenuDescription: string;
	private licenseDdeIntegrationAvailable: boolean;
	private licenseInstantMessageDomain: string;
	private licenseStatisticsRefreshFrequencyStart: string;
	private licenseStatisticsRefreshFrequency: string;
	private licenseRecordCallCenterEvents: boolean;
	private licenseRecordAbandonedCalls: boolean;
	private licenseDisplayAbandonedCalls: boolean;
	private licenseReportViewerAvailable: boolean;
	private licensebrowseToUrlAddress: string;
	private licensePromptUserForBrowserClickToDial: boolean;
	private licenseContentsMenuVisible: boolean;
	private licenseOnlyAdminsCanEditSettings: boolean;
	private licenseOnlyAdminsCanEditCallCenter: boolean;
	private licenseOnlyAdminsCanEditNetwork: boolean;
	private licenseOnlyAdminsCanEditAuthentication: boolean;
	private licenseCustomHelpFileUrl: string;
	private licenseUserListMode: string;
	private licenseUserCanChooseToSeeQueuedCalls: boolean;
	private licenseUserTemplateName: string;
	private licenseUserTemplateId: string;
	private licenseUserTemplateVersionNumber: string;
	private licenseUserTemplateIsSingleUse: boolean;
	private licenseXmppIsAvailable: boolean;
	private licenseXmppAutoGeneratePassword: boolean;
	private licenseSendImToOfflineUsers: boolean;
	private licenseUserTemplateCanBeSetInClient: boolean;
	private licenseContactCenterAgentAssigned: boolean;
	private licenseContactCenterSupervisorAssigned: boolean;
	private licenseCrmIntegrationAssigned: boolean;
	private licenseUnityMobileAssigned: boolean;
	private licenseRejectionReason: string;
	private licenseErrorMessage: string;
	private commPilotProfile: string = "";
	private commonPhoneListAvailable: boolean;
	private defaultPasswordExistInLicense: boolean;
	private ctiServerHost: string = "";
	private userGuid: string = "";
	private calendarTokenExists: boolean = false;
	private sharedCalenderTokenExists: boolean = false;
	private webClientsUseXsi: boolean = false;
	private webClientsXsiTimeoutInSeconds: string = "";
	private webClientUseOciHttp: boolean;
	private dnd: boolean = false;
	private cfa: string = "";
	private supervisorCallCenterExist: boolean = false;
	private preventAgentFromCallCenterLogin:boolean = false;
	constructor(userId: string, firstName: string, lastName: string, hiranganaLastName: string, hiranganaFirstName: string, groupId: string, serviceProvider: string, isEnterprise: boolean, number: string,
		extension: string, mobile: string, emailAddress: string, passwordExpiresDays: string, fac: string, registrations: string, scheduleList: string, portalPasswordChange: string, countryCode: string,



		// brandSuccess: boolean, errorMessage: string,
		// voipServerPort: string, messagingServerHost: string, messagingServerPort: string, keepAliveCycle: string, callDetailsAvailable: string, enterpriseViewAvailable: string, rollbackAvailable: string, instantMessageAvailable: string,
		// monitoredUsersDepartmentName: string, preventAgentFromCallCenterLogin: string, getReasonCodesUrl: string, setReasonCodesUrl: string, promptUserToSwitchStartupApplication: string,
		// promptSystemProviderName: string, promptResellerName: string, prompLicenseExpiryPrompt: string, promptPurchasePageUrl: string, skinSystemProviderName: string,
		// skinResellerName: string, skingroupName: string, skinName: string, skinLogoPath: string, skinLogoUrl: string, skinFontName: string, skinPrimaryColor: string, skinSecondaryColor: string, skinTertiaryColor: string, 

		brandSuccess: boolean, userFound: boolean, voipServerPort: string, messagingServerHost: string,
		messagingServerPort: string, keepAliveCycle: string, ociIsSecure: boolean,
		ociOverTlsServerHostName: string, ociOverTlsServerPort: string,
		ociOverTlsSecurityProtocol: string, clientsUseCti: boolean, ctiServerPort: string, ctiServerHost: string,
		ctiIsSecure: boolean, ctiOverTlsServerHostName: string, ctiOverTlsServerPort: string,
		ctiOverTlsSecurityProtocol: string, logoPath: string, logoUrl: string,
		iconPath: string, useReplacementIconInShortcut: boolean,
		resellerNameForClientDirectory: string, groupNamesForClientDirectory: string[], enterpriseViewAvailable: boolean,
		monitoredUsersDepartmentName: string,
		monitoredUsersDepartmentLimitAll: boolean, promptUserToSwitchStartupApplication: boolean,
		instantMessageDomain: string,
		reportingUrl: string, reportingIsSecure: boolean, xsiSecurityProtocol: string,
		callRecordingUrl: string, callCenterBatchLimit: string,
		xsiActionsVersion: string, xsiEventsVersion: string, xsiReportsVersion: string,
		xsiApplicationId: string, xsiEventsApplicationId: string,
		reportingAlternateUrl: string, reportingAlternateIsSecure: string,
		reportingAlternateApplicationID: string, userLoginFailedPrompt: string,
		callCenterLoginFailedPrompt: string, userCanChangeCallRecordingOption: string,
		replacementVoipServer: string, replacementBroadworksPhrase: string,
		replacementClientName: string,


		licenseSuccess: boolean,
		licenseuserId: string, licenseapplicationName: string, licenseType: string, licenseTrialLicenseFunctionalityBasedOn: string, licenseExpiryPrompt: string, licenseErrorMessage: string, licensepurchasePageUrl: string, licenseExpiryYear: string, licenseExpiryMonth: string, licenseExpiryDay: string, licenseUsersThatCannotBeMonitored: string,
		licenseCallDetailsAvailable: boolean, licenseEnterpriseViewAvailable: boolean, licenseRollbackAvailable: boolean, licenseInstantMessageAvailable: boolean, licenseGetReasonCodesUrl: string, licenseSetReasonCodesUrl: string, licenseMonitoredUsersDepartmentName: string,
		licenseMonitoredUsersDepartmentLimitAll: boolean, licenseSupportUrl: string, licensePreventAgentFromCallCenterLogin: boolean, licenseAllowUserToChangePassword: boolean, licensePromptUserToChangeStartupApplication: boolean,
		licenseCustomServiceMenuItem: string, licenseCustomServiceMenuDescription: string, licenseDdeIntegrationAvailable: boolean, licenseInstantMessageDomain: string, licenseStatisticsRefreshFrequencyStart: string,
		licenseStatisticsRefreshFrequency: string, licenseRecordCallCenterEvents: boolean, licenseRecordAbandonedCalls: boolean, licenseDisplayAbandonedCalls: boolean, licenseReportViewerAvailable: boolean, licensebrowseToUrlAddress: string,
		licensePromptUserForBrowserClickToDial: boolean, licenseContentsMenuVisible: boolean, licenseOnlyAdminsCanEditSettings: boolean, licenseOnlyAdminsCanEditCallCenter: boolean, licenseOnlyAdminsCanEditNetwork: boolean,
		licenseOnlyAdminsCanEditAuthentication: boolean, licenseCustomHelpFileUrl: string, licenseUserListMode: string, licenseUserCanChooseToSeeQueuedCalls: boolean, licenseUserTemplateName: string, licenseUserTemplateId: string,
		licenseUserTemplateVersionNumber: string, licenseUserTemplateIsSingleUse: boolean, licenseXmppIsAvailable: boolean, licenseXmppAutoGeneratePassword: boolean, licenseSendImToOfflineUsers: boolean, licenseUserTemplateCanBeSetInClient: boolean,
		licenseContactCenterAgentAssigned: boolean, licenseContactCenterSupervisorAssigned: boolean, licenseCrmIntegrationAssigned: boolean, licenseUnityMobileAssigned: boolean, licenseRejectionReason: string, commPilotProfile: string, commonPhoneListAvailable: boolean, defaultPasswordExistInLicense: boolean, userGuid: string, calendarTokenExists: boolean, sharedCalenderTokenExists: boolean,
		webClientsUseXsi: boolean, webClientsXsiTimeoutInSeconds: string, webClientUseOciHttp: boolean,preventAgentFromCallCenterLogin:boolean) {



		this.userId = userId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.hiranganaLastName = hiranganaLastName;
		this.hiranganaFirstName = hiranganaFirstName;
		this.groupId = groupId;
		this.serviceProvider = serviceProvider;
		this.isEnterprise = isEnterprise;
		this.number = number;
		this.extension = extension;
		this.mobile = mobile;
		this.emailAddress = emailAddress;
		this.passwordExpiresDays = passwordExpiresDays;
		this.fac = fac;
		this.registrations = registrations;
		this.scheduleList = scheduleList;
		this.portalPasswordChange = portalPasswordChange;
		this.countryCode = countryCode;


		this.brandSuccess = brandSuccess;
		this.userFound = userFound;
		this.voipServerPort = voipServerPort;
		this.messagingServerHost = messagingServerHost;
		this.messagingServerPort = messagingServerPort;
		this.keepAliveCycle = keepAliveCycle;
		this.ociIsSecure = ociIsSecure;
		this.ociOverTlsServerHostName = ociOverTlsServerHostName;
		this.ociOverTlsServerPort = ociOverTlsServerPort;
		this.ociOverTlsSecurityProtocol = ociOverTlsSecurityProtocol;
		this.clientsUseCti = clientsUseCti;
		this.ctiServerPort = ctiServerPort;
		this.ctiServerHost = ctiServerHost;
		this.ctiIsSecure = ctiIsSecure;
		this.ctiOverTlsServerHostName = ctiOverTlsServerHostName;
		this.ctiOverTlsServerPort = ctiOverTlsServerPort;
		this.ctiOverTlsSecurityProtocol = ctiOverTlsSecurityProtocol;
		this.logoPath = logoPath;
		this.logoUrl = logoUrl;
		this.iconPath = iconPath;
		this.useReplacementIconInShortcut = useReplacementIconInShortcut;
		this.resellerNameForClientDirectory = resellerNameForClientDirectory;
		this.groupNamesForClientDirectory = groupNamesForClientDirectory;
		this.enterpriseViewAvailable = enterpriseViewAvailable;
		this.monitoredUsersDepartmentName = monitoredUsersDepartmentName;
		this.monitoredUsersDepartmentLimitAll = monitoredUsersDepartmentLimitAll;
		this.promptUserToSwitchStartupApplication = promptUserToSwitchStartupApplication;
		this.instantMessageDomain = instantMessageDomain;
		this.reportingUrl = reportingUrl;
		this.reportingIsSecure = reportingIsSecure;
		this.xsiSecurityProtocol = xsiSecurityProtocol;
		this.callRecordingUrl = callRecordingUrl;
		this.callCenterBatchLimit = callCenterBatchLimit;
		this.xsiActionsVersion = xsiActionsVersion;
		this.xsiEventsVersion = xsiEventsVersion;
		this.xsiReportsVersion = xsiReportsVersion;
		this.xsiApplicationId = xsiApplicationId;
		this.xsiEventsApplicationId = xsiEventsApplicationId;
		this.reportingAlternateUrl = reportingAlternateUrl;
		this.reportingAlternateIsSecure = reportingAlternateIsSecure;
		this.reportingAlternateApplicationID = reportingAlternateApplicationID;
		this.userLoginFailedPrompt = userLoginFailedPrompt;
		this.callCenterLoginFailedPrompt = callCenterLoginFailedPrompt;
		this.userCanChangeCallRecordingOption = userCanChangeCallRecordingOption;
		this.replacementVoipServer = replacementVoipServer;
		this.replacementBroadworksPhrase = replacementBroadworksPhrase;
		this.replacementClientName = replacementClientName;



		this.licenseSuccess = licenseSuccess;
		this.licenseuserId = licenseuserId;
		this.licenseapplicationName = licenseapplicationName;
		this.licenseType = licenseType;
		this.licenseTrialLicenseFunctionalityBasedOn = licenseTrialLicenseFunctionalityBasedOn;
		this.licenseExpiryPrompt = licenseExpiryPrompt;
		this.licenseErrorMessage = licenseErrorMessage;
		this.licensepurchasePageUrl = licensepurchasePageUrl;
		this.licenseExpiryYear = licenseExpiryYear;
		this.licenseExpiryMonth = licenseExpiryMonth;
		this.licenseExpiryDay = licenseExpiryDay;
		this.licenseUsersThatCannotBeMonitored = licenseUsersThatCannotBeMonitored;
		this.licenseCallDetailsAvailable = licenseCallDetailsAvailable;
		this.licenseEnterpriseViewAvailable = licenseEnterpriseViewAvailable;
		this.licenseRollbackAvailable = licenseRollbackAvailable;
		this.licenseInstantMessageAvailable = licenseInstantMessageAvailable;
		this.licenseGetReasonCodesUrl = licenseGetReasonCodesUrl;
		this.licenseSetReasonCodesUrl = licenseSetReasonCodesUrl;
		this.licenseMonitoredUsersDepartmentName = licenseMonitoredUsersDepartmentName;
		this.licenseMonitoredUsersDepartmentLimitAll = licenseMonitoredUsersDepartmentLimitAll;
		this.licenseSupportUrl = licenseSupportUrl;
		this.licensePreventAgentFromCallCenterLogin = licensePreventAgentFromCallCenterLogin;
		this.licenseAllowUserToChangePassword = licenseAllowUserToChangePassword;
		this.licensePromptUserToChangeStartupApplication = licensePromptUserToChangeStartupApplication;
		this.licenseCustomServiceMenuItem = licenseCustomServiceMenuItem;
		this.licenseCustomServiceMenuDescription = licenseCustomServiceMenuDescription;
		this.licenseDdeIntegrationAvailable = licenseDdeIntegrationAvailable;
		this.licenseInstantMessageDomain = licenseInstantMessageDomain;
		this.licenseStatisticsRefreshFrequencyStart = licenseStatisticsRefreshFrequencyStart;
		this.licenseStatisticsRefreshFrequency = licenseStatisticsRefreshFrequency;
		this.licenseRecordCallCenterEvents = licenseRecordCallCenterEvents;
		this.licenseRecordAbandonedCalls = licenseRecordAbandonedCalls;
		this.licenseDisplayAbandonedCalls = licenseDisplayAbandonedCalls;
		this.licenseReportViewerAvailable = licenseReportViewerAvailable;
		this.licensebrowseToUrlAddress = licensebrowseToUrlAddress;
		this.licensePromptUserForBrowserClickToDial = licensePromptUserForBrowserClickToDial;
		this.licenseContentsMenuVisible = licenseContentsMenuVisible;
		this.licenseOnlyAdminsCanEditSettings = licenseOnlyAdminsCanEditSettings;
		this.licenseOnlyAdminsCanEditCallCenter = licenseOnlyAdminsCanEditCallCenter;
		this.licenseOnlyAdminsCanEditNetwork = licenseOnlyAdminsCanEditNetwork;
		this.licenseOnlyAdminsCanEditAuthentication = licenseOnlyAdminsCanEditAuthentication;
		this.licenseCustomHelpFileUrl = licenseCustomHelpFileUrl;
		this.licenseUserListMode = licenseUserListMode;
		this.licenseUserCanChooseToSeeQueuedCalls = licenseUserCanChooseToSeeQueuedCalls;
		this.licenseUserTemplateName = licenseUserTemplateName;
		this.licenseUserTemplateId = licenseUserTemplateId;
		this.licenseUserTemplateVersionNumber = licenseUserTemplateVersionNumber;
		this.licenseUserTemplateIsSingleUse = licenseUserTemplateIsSingleUse;
		this.licenseXmppIsAvailable = licenseXmppIsAvailable;
		this.licenseXmppAutoGeneratePassword = licenseXmppAutoGeneratePassword;
		this.licenseXmppAutoGeneratePassword = licenseXmppAutoGeneratePassword;
		this.licenseUserTemplateCanBeSetInClient = licenseUserTemplateCanBeSetInClient;
		this.licenseContactCenterAgentAssigned = licenseContactCenterAgentAssigned;
		this.licenseContactCenterSupervisorAssigned = licenseContactCenterSupervisorAssigned;
		this.licenseCrmIntegrationAssigned = licenseCrmIntegrationAssigned;
		this.licenseUnityMobileAssigned = licenseUnityMobileAssigned;
		this.licenseRejectionReason = licenseRejectionReason;
		this.commPilotProfile = commPilotProfile;
		this.commonPhoneListAvailable = commonPhoneListAvailable;
		this.defaultPasswordExistInLicense = defaultPasswordExistInLicense;
		this.userGuid = userGuid;
		this.calendarTokenExists = calendarTokenExists;
		this.sharedCalenderTokenExists = sharedCalenderTokenExists;
		this.webClientsUseXsi = webClientsUseXsi;
		this.webClientsXsiTimeoutInSeconds = webClientsXsiTimeoutInSeconds;
		this.webClientUseOciHttp = webClientUseOciHttp;
		this.dnd = false;
		this.cfa = "";
		this.supervisorCallCenterExist = false;
		this.preventAgentFromCallCenterLogin=preventAgentFromCallCenterLogin;
	}


	public getPreventAgentFromCallCenterLogin(): boolean{
		return this.preventAgentFromCallCenterLogin;
	}
	public isSupervisorCallCenterExist(): boolean {
		return this.supervisorCallCenterExist;
	}
	public setSupervisorCallCenterExist(active: boolean) {
		this.supervisorCallCenterExist = active;
	}
	public setDND(active: boolean) {
		this.dnd = active;
	}
	public getDND(): boolean {
		return this.dnd;
	}
	public setCFA(number: string) {
		this.cfa = number;
	}
	public getCFA(): string {
		return this.cfa;
	}
	public getWebClientUseOciHttp(): boolean {
		return this.webClientUseOciHttp;
	}
	public getWebclientUseXSI(): boolean {
		return this.webClientsUseXsi;
	}

	public getWebClientsXsiTimeoutInSeconds(): string {
		return this.webClientsXsiTimeoutInSeconds;
	}
	public getSharedCalenderTokenExists(): boolean {
		return this.sharedCalenderTokenExists;
	}

	setContactTokenExists(a: boolean) {
		this.calendarTokenExists = a;

	}

	public getcalendarTokenExists(): boolean {
		return this.calendarTokenExists;
	}
	public getUserGuid(): string {
		return this.userGuid;
	}
	public getCommPilotProfile(): string {
		return this.commPilotProfile;
	}

	setCommPilotProfile(a: string) {
		this.commPilotProfile = a;

	}

	public getcommonPhoneListAvailable(): boolean {
		return this.commonPhoneListAvailable;
	}

	public getfirstName(): string {
		return this.firstName;
	}


	public getlastName(): string {
		return this.lastName;
	}

	public gethiranganaLastName(): string {
		return this.hiranganaLastName;
	}

	public gethiranganaFirstName(): string {
		return this.hiranganaFirstName;
	}

	public getgroupId(): string {
		return this.groupId;
	}

	public getserviceProvider(): string {
		return this.serviceProvider;
	}


	public getnumber(): string {
		return this.number;
	}

	public getextension(): string {
		return this.extension;
	}

	public getmobile(): string {
		return this.mobile;
	}
	public getemailAddress(): string {
		return this.emailAddress;
	}
	public getpasswordExpiresDays(): string {
		return this.passwordExpiresDays;
	}

	public getfac(): string {
		return this.fac;
	}

	public getregistrations(): string {
		return this.registrations;
	}

	public getscheduleList(): string {
		return this.scheduleList;
	}

	public getportalPasswordChange(): string {
		return this.portalPasswordChange;
	}

	public getcountryCode(): string {
		return this.countryCode;
	}


	public getbrandSuccess(): boolean {
		return this.brandSuccess;
	}
	public getuserFound(): boolean {
		return this.userFound;
	}
	public getvoipServerPort(): string {
		return this.voipServerPort;
	}
	public getmessagingServerHost(): string {
		return this.messagingServerHost;
	}
	public getmessagingServerPort(): string {
		return this.messagingServerPort;
	}
	public getkeepAliveCycle(): string {
		return this.keepAliveCycle;
	}
	public getociIsSecure(): boolean {
		return this.ociIsSecure;
	}
	public getctiOverTlsServerPort(): string {
		return this.ctiOverTlsServerPort;
	}
	public getociOverTlsServerHostName(): string {
		return this.ociOverTlsServerHostName;
	}
	public getociOverTlsServerPort(): string {
		return this.ociOverTlsServerPort;
	}
	public getociOverTlsSecurityProtocol(): string {
		return this.ociOverTlsSecurityProtocol;
	}
	public getclientsUseCti(): boolean {
		return this.clientsUseCti;
	}
	public getctiServerPort(): string {
		return this.ctiServerPort;
	}
	public getctiIsSecure(): boolean {
		return this.ctiIsSecure;
	}
	public getctiServerHost(): string {
		return this.ctiServerHost;
	}
	public getctiOverTlsServerHostName(): string {
		return this.ctiOverTlsServerHostName;
	}
	public getctiOverTlsSecurityProtocol(): string {
		return this.ctiOverTlsSecurityProtocol;
	}
	public getlogoPath(): string {
		return this.logoPath;
	}
	public getlogoUrl(): string {
		return this.logoUrl;
	}
	public geticonPath(): string {
		return this.iconPath;
	}
	public getuseReplacementIconInShortcut(): boolean {
		return this.useReplacementIconInShortcut;
	}
	public getresellerNameForClientDirectory(): string {
		return this.resellerNameForClientDirectory;
	}
	public getgroupNamesForClientDirectory(id: string): string {
		if (this.groupNamesForClientDirectory[id]) {
			return this.groupNamesForClientDirectory[id];
		} else {
			return id;
		}
	}
	public getenterpriseViewAvailable(): boolean {
		return this.enterpriseViewAvailable;
	}
	public getmonitoredUsersDepartmentName(): string {
		return this.monitoredUsersDepartmentName;
	}
	public getmonitoredUsersDepartmentLimitAll(): boolean {
		return this.monitoredUsersDepartmentLimitAll;
	}
	public getpromptUserToSwitchStartupApplication(): boolean {
		return this.promptUserToSwitchStartupApplication;
	}
	public getinstantMessageDomain(): string {
		return this.instantMessageDomain;
	}
	public getreportingUrl(): string {
		return this.reportingUrl;
	}
	public getreportingIsSecure(): boolean {
		return this.reportingIsSecure;
	}
	public getxsiSecurityProtocol(): string {
		return this.xsiSecurityProtocol;
	}
	public getcallRecordingUrl(): string {
		return this.callRecordingUrl;
	}
	public getcallCenterBatchLimit(): string {
		return this.callCenterBatchLimit;
	}
	public getxsiActionsVersion(): string {
		return this.xsiActionsVersion;
	}
	public getxsiEventsVersion(): string {
		return this.xsiEventsVersion;
	}
	public getxsiReportsVersion(): string {
		return this.xsiReportsVersion;
	}
	public getxsiApplicationId(): string {
		return this.xsiApplicationId;
	}
	public getxsiEventsApplicationId(): string {
		return this.xsiEventsApplicationId;
	}
	public getreportingAlternateUrl(): string {
		return this.reportingAlternateUrl;
	}
	public getreportingAlternateIsSecure(): string {
		return this.reportingAlternateIsSecure;
	}
	public getreportingAlternateApplicationID(): string {
		return this.reportingAlternateApplicationID;
	}
	public getuserLoginFailedPrompt(): string {
		return this.userLoginFailedPrompt;
	}
	public getcallCenterLoginFailedPrompt(): string {
		return this.callCenterLoginFailedPrompt;
	}
	public getuserCanChangeCallRecordingOption(): string {
		return this.userCanChangeCallRecordingOption;
	}
	public getreplacementVoipServer(): string {
		return this.replacementVoipServer;
	}
	public getreplacementBroadworksPhrase(): string {
		return this.replacementBroadworksPhrase;
	}
	public getreplacementClientName(): string {
		return this.replacementClientName;
	}

	/**** */

	public getlicenseSuccess(): boolean {
		return this.licenseSuccess;
	}

	public getlicenseuserId(): string {
		return this.licenseuserId;
	}


	public getlicenseapplicationName(): string {
		return this.licenseapplicationName;
	}

	public getlicenseType(): string {
		return this.licenseType;
	}

	public getlicenseTrialLicenseFunctionalityBasedOn(): string {
		return this.licenseTrialLicenseFunctionalityBasedOn;
	}

	public getlicenseExpiryPrompt(): string {
		return this.licenseExpiryPrompt;
	}
	public getlicenseErrorMessage(): string {
		return this.licenseErrorMessage;
	}

	public getlicensepurchasePageUrl(): string {
		return this.licensepurchasePageUrl;
	}

	public getlicenseExpiryYear(): string {
		return this.licenseExpiryYear;
	}
	public getlicenseExpiryMonth(): string {
		return this.licenseExpiryMonth;
	}
	public getlicenseExpiryDay(): string {
		return this.licenseExpiryDay;
	}

	public getlicenseCallDetailsAvailable(): boolean {
		return this.licenseCallDetailsAvailable;
	}

	public getlicenseEnterpriseViewAvailable(): boolean {
		return this.licenseEnterpriseViewAvailable;
	}


	public getlicenseUsersThatCannotBeMonitored(): string {
		return this.licenseUsersThatCannotBeMonitored;
	}

	public getlicenseRollbackAvailable(): boolean {
		return this.licenseRollbackAvailable;
	}

	public getlicenseInstantMessageAvailable(): boolean {
		return this.licenseInstantMessageAvailable;
	}

	public getlicenseGetReasonCodesUrl(): string {
		return this.licenseGetReasonCodesUrl;
	}

	public getlicenseSetReasonCodesUrl(): string {
		return this.licenseSetReasonCodesUrl;
	}

	public getlicenseMonitoredUsersDepartmentName(): string {
		return this.licenseMonitoredUsersDepartmentName;
	}

	public getlicenseMonitoredUsersDepartmentLimitAll(): boolean {
		return this.licenseMonitoredUsersDepartmentLimitAll;
	}

	public getlicenseSupportUrl(): string {
		return this.licenseSupportUrl;
	}

	public getlicensePreventAgentFromCallCenterLogin(): boolean {
		return this.licensePreventAgentFromCallCenterLogin;
	}

	public getlicenseAllowUserToChangePassword(): boolean {
		return this.licenseAllowUserToChangePassword;
	}

	public getlicensePromptUserToChangeStartupApplication(): boolean {
		return this.licensePromptUserToChangeStartupApplication;
	}

	public getlicenseCustomServiceMenuItem(): string {
		return this.licenseCustomServiceMenuItem;
	}

	public getlicenseCustomServiceMenuDescription(): string {
		return this.licenseCustomServiceMenuDescription;
	}

	public getlicenseDdeIntegrationAvailable(): boolean {
		return this.licenseDdeIntegrationAvailable;
	}

	public getlicenseInstantMessageDomain(): string {
		return this.licenseInstantMessageDomain;
	}

	public getlicenseStatisticsRefreshFrequencyStart(): string {
		return this.licenseStatisticsRefreshFrequencyStart;
	}

	public getlicenseStatisticsRefreshFrequency(): string {
		return this.licenseStatisticsRefreshFrequency;
	}

	public getlicenseRecordCallCenterEvents(): boolean {
		return this.licenseRecordCallCenterEvents;
	}

	public getlicenseRecordAbandonedCalls(): boolean {
		return this.licenseRecordAbandonedCalls;
	}

	public getlicenseDisplayAbandonedCalls(): boolean {
		return this.licenseDisplayAbandonedCalls;
	}

	public getlicenseReportViewerAvailable(): boolean {
		return this.licenseReportViewerAvailable;
	}

	public getlicensebrowseToUrlAddress(): string {
		return this.licensebrowseToUrlAddress;
	}
	public getlicensePromptUserForBrowserClickToDial(): boolean {
		return this.licensePromptUserForBrowserClickToDial;
	}

	public getlicenseContentsMenuVisible(): boolean {
		return this.licenseContentsMenuVisible;
	}

	public getlicenseOnlyAdminsCanEditSettings(): boolean {
		return this.licenseOnlyAdminsCanEditSettings;
	}

	public getlicenseOnlyAdminsCanEditCallCenter(): boolean {
		return this.licenseOnlyAdminsCanEditCallCenter;
	}

	public getlicenseOnlyAdminsCanEditNetwork(): boolean {
		return this.licenseOnlyAdminsCanEditNetwork;
	}

	public getlicenseOnlyAdminsCanEditAuthentication(): boolean {
		return this.licenseOnlyAdminsCanEditAuthentication;
	}

	public getlicenseCustomHelpFileUrl(): string {
		return this.licenseCustomHelpFileUrl;
	}

	public getlicenseUserListMode(): string {
		return this.licenseUserListMode;
	}

	public getlicenseUserCanChooseToSeeQueuedCalls(): boolean {
		return this.licenseUserCanChooseToSeeQueuedCalls;
	}

	public getlicenseUserTemplateName(): string {
		return this.licenseUserTemplateName;
	}

	public getlicenseUserTemplateId(): string {
		return this.licenseUserTemplateId;
	}

	public getlicenseUserTemplateVersionNumber(): string {
		return this.licenseUserTemplateVersionNumber;
	}
	public getlicenseUserTemplateIsSingleUse(): boolean {
		return this.licenseUserTemplateIsSingleUse;
	}

	public getlicenseXmppIsAvailable(): boolean {
		return this.licenseXmppIsAvailable;
	}

	public getlicenseXmppAutoGeneratePassword(): boolean {
		return this.licenseXmppAutoGeneratePassword;
	}

	public getlicenseSendImToOfflineUsers(): boolean {
		return this.licenseSendImToOfflineUsers;
	}

	public getlicenseUserTemplateCanBeSetInClient(): boolean {
		return this.licenseUserTemplateCanBeSetInClient;
	}

	public getlicenseContactCenterAgentAssigned(): boolean {
		return this.licenseContactCenterAgentAssigned;
	}

	public getlicenseContactCenterSupervisorAssigned(): boolean {
		return this.licenseContactCenterSupervisorAssigned;
	}

	public getlicenseCrmIntegrationAssigned(): boolean {
		return this.licenseCrmIntegrationAssigned;
	}

	public getlicenseUnityMobileAssigned(): boolean {
		return this.licenseUnityMobileAssigned;
	}

	public getlicenseRejectionReason(): string {
		return this.licenseRejectionReason;
	}

	getUserId() {
		return this.userId;
	}

	getFullName() {
		return this.firstName + ' ' + this.lastName;
	}

	public getisEnterprise(): boolean {
		return this.isEnterprise;
	}

	public getDefaultPasswordExistInLicense(): boolean {
		return this.defaultPasswordExistInLicense;
	}

}