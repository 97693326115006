import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../../../providers/context.service';
import { SettingsGeneral } from '../../../../../../../../providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-callcenter-threshold',
  templateUrl: './callcenter-threshold.component.html',
  styleUrls: ['./callcenter-threshold.component.scss']
})
export class CallcenterThresholdComponent implements OnInit {
  setThreshold: string = "";
  increasingThreshold: string = "";
  highlights: string = "";
  description: string = "";
  statistic: string = "";
  heading: string = "";

  green: string = "";
  black: string = "";
  red: string = "";
  yellow: string = "";
  callsNameList = [];
  secondsNameList = [];
  percentageNameList = [];
  agentNameList = [];
  callcenterText:string="";
  constructor(public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.callcenterText=this.contextService.getLanguageTagByKey('List.Statistics.CallCenter');
    this.setThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.SetThresholdValue');
    this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds');
    this.highlights = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.HighlightNonZeroValues');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.Columns.Description');
    this.statistic = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Statistic');
    this.heading = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Heading');
    this.settingsGeneral.selectQueueTab = 1;

    this.green = this.contextService.getLanguageTagByKey('Color.Green');
    this.black = this.contextService.getLanguageTagByKey('Color.Black');
    this.red = this.contextService.getLanguageTagByKey('Color.Red');
    this.yellow = this.contextService.getLanguageTagByKey('Color.Yellow');
    this.callsNameList.push("queueTotalCalls");
    this.callsNameList.push("queueTotalMissedCalls");
    this.callsNameList.push("queueBusyOverflows");
    this.callsNameList.push("queueCallsAnswered");
    this.callsNameList.push("queueCallsAbandoned");
    this.callsNameList.push("queueCallsTransfered");
    this.callsNameList.push("queueCallsTimedOut");
    this.callsNameList.push("queueCallsInQueue");
    this.callsNameList.push("queueTotalReceivedCalls");

    this.secondsNameList.push("queueAverageWaitTime");
    this.secondsNameList.push("queueAverageAbandonedTime");
    this.secondsNameList.push("queueAverageTalkTime");
    this.secondsNameList.push("queueTotalTalkTime");
    this.secondsNameList.push("queueLongestWaitTime");

    this.percentageNameList.push("queueTotalMissedCallsPercentage");
    this.percentageNameList.push("queueBusyOverflowsPercentage");
    this.percentageNameList.push("queueCallsAnsweredPercentage");
    this.percentageNameList.push("queueCallsAbandonedPercentage");
    this.percentageNameList.push("queueCallsTransferedPercentage");
    this.percentageNameList.push("queueCallsTimedOutPercentage");

    this.agentNameList.push("queueAverageAgentsTalking");
    this.agentNameList.push("queueAverageAgentsStaffed");
    this.agentNameList.push("queueStaffedRatio");

  }


  dbCallCenterTable(event) {
    this.contextService.log("dbCallCenterTable clicked");
    this.contextService.log(event);

    //change set threshold text
    if (this.callsNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Menu.Context.Call')).toLocaleLowerCase() + ")";
    } else if (this.secondsNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";
    } else if (this.percentageNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Percent')).toLocaleLowerCase() + ")";
    } else if (this.agentNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent')).toLocaleLowerCase() + ")";
    }


    this.settingsGeneral.selectedCallCenterColumn = event;
    if (this.settingsGeneral.selectedCallCenterColumn) {
      this.settingsGeneral.showElements = true;
      this.contextService.log(this.settingsGeneral.selectedQueueColumn);
      if (this.callsNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
        this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Menu.Context.Call')).toLocaleLowerCase() + ")";
      } else if (this.secondsNameList.includes(this.settingsGeneral.selectedQueueColumn.value)) {
        this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";
      }
      this.settingsGeneral.loadQueueThresholdValues(this.settingsGeneral.selectedCallCenterColumn.value);
      this.settingsGeneral.currentCallCenterQueuePage = "2";
    }
  }
  showThresholdTable() {
    this.contextService.log("showThresholdTable clicked");
    this.settingsGeneral.showElements = false;
    this.settingsGeneral.currentCallCenterQueuePage = "1";
    this.contextService.queueThresholdList = [];
    this.settingsGeneral.contextService.queueStatisticsList.forEach(element => {
      if (this.contextService.queueStatisticsToDisplay == element.statName) {
        if (!element.setThreshold) {
          element.callCentersList["all"].green = "";
          element.callCentersList["all"].yellow = "";
          element.callCentersList["all"].red = "";
          element.callCentersList["all"].black = "";
        }
        this.contextService.queueThresholdList.push({ id: 1, name: "All", value: "all", green: element.callCentersList["all"].green, yellow: element.callCentersList["all"].yellow, red: element.callCentersList["all"].red, black: element.callCentersList["all"].black });

        for (let i = 0; i < this.contextService.callCenterList.length; i++) {
          let element1 = this.contextService.callCenterList[i];
          if (!element.setThreshold) {
            element.callCentersList[element1.value].green = "";
            element.callCentersList[element1.value].yellow = "";
            element.callCentersList[element1.value].red = "";
            element.callCentersList[element1.value].black = "";

          }
          this.contextService.queueThresholdList.push({ id: 1, name: this.contextService.getLastNameFromUserID(element1.value), value: element1.value, green: element.callCentersList[element1.value].green, yellow: element.callCentersList[element1.value].yellow, red: element.callCentersList[element1.value].red, black: element.callCentersList[element1.value].black });
        }
      }

    });
    let temp = [...this.contextService.queueThresholdList];
    this.contextService.queueThresholdList = temp;

    let id_value: number = 1;
    this.contextService.queueThresholdList.forEach(element => {
      element.id = id_value;
      id_value++;

    });




  }
  setValuesToDefault(event) {

    this.contextService.log("setValuesToDefault clicked");
    this.contextService.setThreshold = false;
    this.settingsGeneral.contextService.queueStatisticsList.forEach(element => {

      for (let index = 0; index < this.contextService.queueColumnList.length; index++) {

        const element1 = this.contextService.queueColumnList[index];
        if (element.statName == element1.value) {

          for (let i = 0; i < this.contextService.callCenterList.length; i++) {

            let element2 = this.contextService.callCenterList[i];
            element.callCentersList[element2.value].green = "";
            element.callCentersList[element2.value].yellow = "";
            element.callCentersList[element2.value].red = "";
            element.callCentersList[element2.value].black = "";
          }
          element.callCentersList["all"].black = "";
          element.callCentersList["all"].red = "";
          element.callCentersList["all"].yellow = "";
          element.callCentersList["all"].green = "";
          break;
        }
      }

    });
    this.contextService.queueThresholdList = [];
    this.settingsGeneral.contextService.queueStatisticsList.forEach(element => {
      if (event == element.statName) {

        this.contextService.queueThresholdList.push({ id: 1, name: "All", value: "all", green: element.callCentersList["all"].green, yellow: element.callCentersList["all"].yellow, red: element.callCentersList["all"].red, black: element.callCentersList["all"].black });

        for (let i = 0; i < this.contextService.callCenterList.length; i++) {
          let element1 = this.contextService.callCenterList[i];
          this.contextService.queueThresholdList.push({ id: 1, name: this.contextService.getLastNameFromUserID(element1.value), value: element1.value, green: element.callCentersList[element1.value].green, yellow: element.callCentersList[element1.value].yellow, red: element.callCentersList[element1.value].red, black: element.callCentersList[element1.value].black });
        }
        this.contextService.setThreshold = element.setThreshold;
      }
    });
    let temp = [...this.contextService.queueThresholdList];
    this.contextService.queueThresholdList = temp;

    let id_value: number = 1;
    this.contextService.queueThresholdList.forEach(element => {
      element.id = id_value;
      id_value++;

    });

  }
}
