<div class="row">
  <div class="call-logs-controls">
    <div class="call-log-filter">
      <p-radioButton name="calllog" label="{{missed}}" value="0" [(ngModel)]="callLogsService.selectedValue" (click)="selectCallType(0)"></p-radioButton>
      <p-radioButton name="calllog" label="{{received}}" value="2" [(ngModel)]="callLogsService.selectedValue" (click)="selectCallType(2)"></p-radioButton>
      <p-radioButton name="calllog" label="{{dialed}}" value="1" [(ngModel)]="callLogsService.selectedValue" (click)="selectCallType(1)"></p-radioButton>
    </div>
    <!-- <div class="call-log-btns">
            <span class="del" (click)="clearLogs()" title="Clear">delete</span>
            <span class="ref" (click)="callLogs.reset();" title="Reset">reset</span>
            <span class="exp" (click)="callLogs.exportCSV()" title="Export">export</span>
            <span class="dl" (click)="download()" title="Download">download</span>
        </div> -->
  </div>
</div>

<p-table [value]="callLogsService.ObjCallLogs" selectionMode="single" [(selection)]="selectedContact" [responsive]="true"
  [sortField]="sortF" [sortOrder]="sortO" rowGroupMode="subheader" [(contextMenuSelection)]="selectedContact"
  [contextMenu]="cm" contextMenuSelectionMode="joint" id="call-logs" #callLogs [scrollable]="true" scrollHeight="{{logTableHeight}}"
  [ngClass]="{'scrolling':scrolling === true}" (onContextMenuSelect)="contextMenuSelected($event,cm)" (onSort)="changeSortCallLog($event)"
  dataKey="id" [primeDragula]="bag" [dragulaModel]="callLogsService.ObjCallLogs" [dragulaOptions]="{ childContainerSelector: '#call-logs .ui-table-scrollable-body-table .ui-table-tbody', initAfterView: true, copy: false, childContainerSelectorID: 'calllogs-body' }">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th [pSortableColumn]="'name'">{{miscName}}
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'phone'">{{miscPhone}}
        <p-sortIcon [field]="'phone'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'time'">{{callDate}}
        <p-sortIcon [field]="'time'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-calllog let-rowIndex="rowIndex">
    <tr class="ui-widget-header" *ngIf="rowGroupMetadata[calllog.period].index === rowIndex">
      <td colspan="4">
        <span class="ui-rowgroup-header-name">{{calllog.period}}</span>
      </td>
    </tr>
    <tr (dblclick)="onRowDblclick(calllog.phone)" (click)="rowClick(calllog)" [pContextMenuRow]="calllog"
      [pSelectableRow]="calllog">
      <td></td>
      <td [style]="{'width':'34%'}" styleClass="name-cell">
        <span class="ui-column-title" [attr.data-id]="calllog.id">{{miscName}}</span>
        {{calllog.name}}</td>
      <td [style]="{'width':'32%'}">
        <span class="ui-column-title">{{miscPhone}}</span>
        {{calllog.phone}}</td>
      <td [style]="{'width':'32%'}">
        <span class="ui-column-title">{{callDate}}</span>
        {{calllog.time|datex:'MM/DD/YYYY hh:mm:ss A'}}</td>
    </tr>
  </ng-template>
</p-table>

<div #logsTableMenu [ngStyle]="{'height': logTableMenuHeight}"></div>

<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
<p-contextMenu [model]="afterDropCM" appendTo="body" #afterdropmenuCallLog [style]="{'left':afterdropmenuCallLogLeft,'top':afterdropmenuCallLogTop}"></p-contextMenu>
<!-- <p-contextMenu [model]="contextMenuOutside" appendTo="body" [target]="logsTableMenu"></p-contextMenu> -->
