import { Injectable } from '@angular/core';
import { AnonymousCallRejection } from '../../../model/bw-services/anonymous-call-rejection';
import { ContextService } from '../../context.service';
import { BwServices } from './bw-services.service';
/**
 * service for Anonymous Call Rejection
 * 
 * @export
 * @class AnonymousCallRejectionService
 */
@Injectable()
export class AnonymousCallRejectionService {
    changesOccured: boolean = false;
    isActive: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - AnonymousCallRejectionService');

        }

    setData(anonymousCallRejection: AnonymousCallRejection) {
    
    }

    validate(): boolean {
        return true;
    }

    save() {
       
    }

    populateData() {
      
    }
    cancel() {
      
    }

}