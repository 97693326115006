import { Injectable } from '@angular/core';
import { CommPilotExpressAvailableInOffice } from '../../../model/bw-services/comm-pilot-express-available-in-office';
import { CommPilotExpressAvailableOutOfOffice } from '../../../model/bw-services/comm-pilot-express-available-out-of-office';
import { CommpilotExpressBusy } from '../../../model/bw-services/comm-pilot-express-busy';
import { CommPilotExpressUnavailable } from '../../../model/bw-services/comm-pilot-express-unavailable';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';






/**
 * to register CommPilotExpressResponseHandler   
 * 
 * @export
 * @class CommPilotExpressService
 */
@Injectable()

export class CommPilotExpressService {
    commpilot = [];
    ifNotAnswered: string = '';
    ifNot: string = 'val2';

    ifBusy: string = '';
    inOfficebusySetting: string = "";
    inOfficebusySettingVM: boolean = false;
    inOfficenoAnswerSetting: string = "";
    inOfficenoAnswerSettingVM: boolean = false;
    inOfficeadditionalPhoneNumberToRing: string = "";
    inOfficeforwardingAddressIfBusy: string = "";
    inOfficeforwardingAddressIfNotAnswered: string = "";
    outOfficeincomingCalls: string = "";
    outOfficeincomingCallsVM: boolean = false;
    outOfficesendEmail: boolean = false;
    outOfficeforwardingAddress: string = "";
    outOfficeemailAddress: string = "";

    //Busy
    busysendCallsToVoiceMailExceptExcludedAddresses: boolean = false;
    busysendEmail: boolean = false;
    busyexcludedAddress = [];
    busyforwardToAddress: string = "";
    busyemailAddress: string = "";
    addNumber: string = "";

    //Unavailable
    unavailablesendCallsToVoiceMailExceptExcludedAddresses: boolean = false;
    unavailableexcludedAddress = [];
    unavailableforwardToAddress: string = "";
    unavailablevoiceMailGreeting: string = "";
    //Unavailable
    addNumberUnavailable: string = "";
    //commPilot
    commPilotValueOnStartup: string = "";
    commPilotValueOnUnityClose: string = "";
    commpilotServiceChanges: boolean = false;
    commpilotProfileChanges: boolean = false;

    forwardingAddressIfNotAnsweredValidation: boolean = true;
    forwardingAddressIfBusyValidation: boolean = true;
    additionalPhoneNumberToRingValidation: boolean = true;



    outOfOfficeForwardingAddressValidation: boolean = false;

    busyforwardToAddressValidation: boolean = false;
    busyNewNumberValidation: boolean = false;
    unavailableforwardToAddressValidation: boolean = false;
    unAvailableNewNumberValidation: boolean = false;

    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }
    setInOffice(commPilotExpressAvailableInOffice: CommPilotExpressAvailableInOffice) { }
    setOutOffice(commPilotExpressAvailableOutOfOffice: CommPilotExpressAvailableOutOfOffice) { }
    setBusy(commpilotExpressBusy: CommpilotExpressBusy) { }
    setUnavailable(commPilotExpressUnavailable: CommPilotExpressUnavailable) { }
    allnumericplusminus(inputtxt, profile) { }
    validateInOff(num, value, page): boolean {
        return true;
    }
    validateInOffice(): boolean {
        return true;
    }
    validateOutOffice(): boolean {
        return true;
    }
    validateBusy(): boolean {
        return true;
    }
    validateUnavailable(): boolean {
        return true;
    }
    validateNewNumber(num, booleanVal): boolean {
        return true;
    }
    addBusy() { }
    deleteBusy(selectedNumber) { }
    addUnavailable() { }
    deleteUnavailable(selectedNumber) { }
    populateCommpilot() { }
    populateInOffice() { }
    populateOutOffice() { }
    populateBusy() { }
    populateUnavailable() { }
    saveCommPilot() { }
    saveCommPilotService() { }
    cancelInOffice() { }
    cancelOutOffice() { }
    cancelBusy() { }
    cancelUnavailable() { }
}