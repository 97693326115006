import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { BWAnywhereService } from '../../../../../providers/settings/settings-services/bw-anywhere.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-bw-anywhere',
  templateUrl: './bw-anywhere.component.html',
  styleUrls: ['./bw-anywhere.component.css']
})
export class BwAnywhereComponent extends BaseComponent implements OnInit {
  description: string = "";
  alertAllLocation: string = "";
  selectedNumber;
  phoneNumber: string = "";
  confirm: string = "";
  delete: string = "";
  plus: string = "";
  miscNo: string = "";
  miscYes: string = "";

  deleteLocationWindow: boolean = false;
  constructor(public bWAnywhereService: BWAnywhereService, private contextService: ContextService) {
    super();
  }

  ngOnInit() {
    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.contextService.checkBWAnywhere = false;
    this.bWAnywhereService.bwAnyWhereChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Description');
    this.alertAllLocation = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.AlertAllLocations');
    this.phoneNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.confirm = this.contextService.getLanguageTagByKey("Settings.Services.BroadworksAnywhere.Number.Confirm");
  }
  ngOnDestroy() {
    this.contextService.checkBWAnywhere = false;
  }
  deleteLocationWindowLocation() {
    this.bWAnywhereService.deleteLocation(this.selectedNumber.number);
    this.selectedNumber.number = "";
    this.deleteLocationWindow = false;
  }
  deleteLocation() {
    if (this.selectedNumber) {
      if (!(this.selectedNumber.number === "") && !(this.selectedNumber.number == null)) {
        this.deleteLocationWindow = true;
      }
    }
  }
  doubleClick(event) {
    this.selectedNumber = event;
    if (this.selectedNumber) {
      this.bWAnywhereService.displayLocation(this.selectedNumber.number);
    }

  }


}
