import { Injectable } from '@angular/core';
import { DirectedCallPickUpWithBargeIn } from '../../../model/bw-services/directed-call-pick-up-with-barge-in';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
/**
 * service for directedCallPickUpWithBargeIn
 * 
 * @export
 * @class DirectedCallPickUpWithBargeInService
 */
@Injectable()
export class DirectedCallPickUpWithBargeInService {
    enableBargeInWarningTone: boolean = false;
    enableAutomaticTargetSelection: boolean = false;
    directedCallPickUpWithBargeInChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }
    setData(directedCallPickUpWithBargeIn: DirectedCallPickUpWithBargeIn) { }
    validate(): boolean {
        return true;
    }
    save() { }
    populateData() { }
    cancel() { }

}