
import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { BWAnywhereService } from '../../../../../providers/settings/settings-services/bw-anywhere.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-bw-anywhere-criteria-details',
  templateUrl: './bw-anywhere-criteria-details.component.html',
  styleUrls: ['./bw-anywhere.component.css']
})
export class BwAnywhereCriteriaDetailsComponent extends BaseComponent implements OnInit {
  description: string = "";
  criteriaDescription: string = "";
  selectedPhoneNumber;
  schedule: string = "";
  callsFrom: string = "";
  extPhNumber: string = "";
  pvtNumber: string = "";
  unNumber: string = "";
  theseNumbers: string = "";
  plus: string = "";
  delete: string = "";

  constructor(public bWAnywhereService: BWAnywhereService, public contextService: ContextService) {
    super();
  }
  onRowSelectNumber(event) {
  }
  ngOnInit() {

    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.bWAnywhereService.bwAnyWhereChanges=true;
    this.contextService.checkBWAnywhere=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Description');
    this.criteriaDescription = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.Description');
    this.schedule = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.TimeSchedule');
    this.callsFrom = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.AcceptCallsFrom');
    this.extPhNumber = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.AnyExternalNumber');
    this.pvtNumber = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.AnyPrivateNumber');
    this.unNumber = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.AnyUnavailableNumber');
    this.theseNumbers = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.TheseNumbers');
    if (this.bWAnywhereService.timeSchedule.length<1) {
      this.bWAnywhereService.timeSchedule.push({ label: "Every Day All Day", value: "Every Day All Day" });
    }
  }
  ngOnDestroy(){
    this.bWAnywhereService.validateFlag=false;
    this.contextService.checkBWAnywhere=false;
   }
  deletePhoneNumber() {
    if(!this.bWAnywhereService.fromDnCriteriaSelectionBool){
      this.bWAnywhereService.deletePhoneNumber(this.selectedPhoneNumber);
    }
    
  }

}
