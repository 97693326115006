<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{allowCallTransferRecallText}} [(ngModel)]="this.callTransferService.allowCallTransferRecall" (click)="this.disableCampCallsField()" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{numberOfRingsText}}</label>
  <input type="number"  name="mini-input" class="input-mini" [(ngModel)]="this.callTransferService.numberOfRingsBeforeRecall" [disabled]="!this.callTransferService.allowCallTransferRecall" id="textarea" name="input-text" autocomplete=off>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.callTransferService.allowBusyCampOn" label={{allowBusyCampText}} (click)="this.disableCampCallsField()" [disabled]="!this.callTransferService.allowCallTransferRecall" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{campCallsText}}</label>
  <input type="number"  name="mini-input" class="input-mini" id="textarea" name="input-text" [(ngModel)]="this.callTransferService.campCallsFor" [disabled]="this.disbleCampCalls" autocomplete=off>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{diversionInhibitorBlindTransferText}} [(ngModel)]="this.callTransferService.blindTransferInhibitor" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{diversionInhibitorConsultativeCalls}} [(ngModel)]="this.callTransferService.consultativeCallsInhibitor" binary="true"></p-checkbox>
</div>
<!-- <div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{allowWarmTransferText}} [(ngModel)]="this.settingsGeneral.allowWarmTransfer" binary="true"></p-checkbox>
</div> -->
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{allowWarmTransferToVoicemailText}} [(ngModel)]="this.settingsGeneral.allowWarmTransferToVoicemail" binary="true"></p-checkbox>
</div>
<div class="row">
    <label for="" class="label">{{surveyExtensionText}}</label>
    <input type="number" id="textarea"  name="mini-input" class="input-mini" [(ngModel)]="this.settingsGeneral.surveyExtension" autocomplete=off>
  </div>