import { CallCenterMonitoringService } from './../settings-services/call-center-monitoring.service';
import { MenuItem } from 'primeng/primeng';
import { Injectable } from '@angular/core';
import { DoNotDisturbService } from '../settings-services/do-not-disturb.service';
import { CallForwardingBusyService } from '../settings-services/call-forwarding-busy.service';
import { AnonymousCallRejectionService } from '../settings-services/anonymous-call-rejection.service';
import { CallForwardningAlwaysService } from '../settings-services/call-forwarding-always.service';
import { CallForwardNoAnswerService } from '../settings-services/call-forward-no-answer.service';
import { CallWaitingService } from '../settings-services/call-waiting.service';
import { ExternalCallingLineIDDeliveryService } from '../settings-services/external-calling-line-id-delivery.service';
import { InternalCallingLineIDDeliveryService } from '../settings-services/internal-calling-line-id-delivery.service';
import { SimultaneousRingService } from '../settings-services/simultaneous-ring.service';
import { VoiceMailService } from '../settings-services/voice-mail.service';
import { RemoteOfficeService } from '../settings-services/remote-office.service';
import { FlexibleSeatingGuestService } from '../settings-services/flexible-seating-guest.service';
import { HotelingGuestService } from '../settings-services/hoteling-guest.service';
import { CommPilotExpressService } from '../settings-services/comm-pilot-express.service';
import { CallingLineIDDeliveryBlockingService } from '../settings-services/calling-line-id-delivery-blocking.service';
import { CallRecordingService } from '../settings-services/call-recording.service';
import { ContextService } from '../../context.service';
import { CallTransferService } from "../settings-services/call-transfer.service";
import { BWAnywhereService } from '../settings-services/bw-anywhere.service';
import { DirectedCallPickUpWithBargeInService } from "../settings-services/directed-call-pick-up-within-barge-in.service";

import { Router } from '@angular/router';

/**
 *  Common service for BW services and settings
 * 
 * @export
 * @class SettingService
 */
@Injectable()

export class SettingService {
  serviceSettMenu: MenuItem[] = [];
  generalSettMenu: MenuItem[] = [];
  serviceTreeArray: string[] = [];
  settingsTreeArray: string[] = [];
  computerPhoneIntegration: any[] = [];

  constructor(private router: Router, public callTransferService: CallTransferService, public callWaitingService: CallWaitingService,
    public anonymousCallRejectionService: AnonymousCallRejectionService,
    public callForwardningAlwaysService: CallForwardningAlwaysService,
    public callForwardNoAnswerService: CallForwardNoAnswerService,
    public doNotDisturbService: DoNotDisturbService,
    public callForwardingBusyService: CallForwardingBusyService,
    public externalCallingLineIDDeliveryService: ExternalCallingLineIDDeliveryService,
    public internalCallingLineIDDeliveryService: InternalCallingLineIDDeliveryService,
    public simultaneousRingService: SimultaneousRingService,
    public voiceMailService: VoiceMailService,
    public remoteOfficeService: RemoteOfficeService,
    public hotelingGuestService: HotelingGuestService,
    public flexibleSeatingGuestService: FlexibleSeatingGuestService,
    public commPilotExpressService: CommPilotExpressService,
    public callingLineIDDeliveryBlockingService: CallingLineIDDeliveryBlockingService,
    public callRecordingService: CallRecordingService, public directedCallPickUpWithBargeInService: DirectedCallPickUpWithBargeInService,
    public bWAnywhereService: BWAnywhereService, public callCenterMonitoringService:CallCenterMonitoringService,
    private contextService: ContextService

  ) {
    this.contextService.log('###### constructor - SettingService');

  }
  /**
   * Used to populate service tree
   * 
   * @memberof SettingService
   */
  populateService() {

    if (this.contextService.assignedServices.includes('AnonymousCallRejection')) {
      this.anonymousCallRejectionService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallForwardingAlways')) {
      this.callForwardningAlwaysService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallForwardingBusy')) {
      this.callForwardingBusyService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallForwardingNoAnswer')) {
      this.callForwardNoAnswerService.populateData();
    }

    if (this.contextService.assignedServices.includes('DoNotDisturb')) {
      this.doNotDisturbService.populateData();
    }
    if (this.contextService.assignedServices.includes('ExternalCallingLineIDDelivery')) {
      this.externalCallingLineIDDeliveryService.populateData();
    }
    if (this.contextService.assignedServices.includes('InternalCallingLineIDDelivery')) {
      this.internalCallingLineIDDeliveryService.populateData();
    }
    if (this.contextService.assignedServices.includes('SimultaneousRingPersonal')) {
      this.simultaneousRingService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallingLineIDDeliveryBlocking')) {
      this.callingLineIDDeliveryBlockingService.populateData();
    }
    if (this.contextService.assignedServices.includes('BroadWorksAnywhere')) {
      this.bWAnywhereService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallWaiting')) {
      this.callWaitingService.populateData();
    }
    if (this.contextService.assignedServices.includes('RemoteOffice')) {
      this.remoteOfficeService.populateData();
    }
    if (this.contextService.assignedServices.includes('VoiceMessaging')) {
      this.voiceMailService.populateData();
    }
    if (this.contextService.assignedServices.includes('HotelingGuest')) {
      this.hotelingGuestService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallRecording')) {
      this.callRecordingService.populateData();
    }
    if (this.contextService.assignedServices.includes('FlexibleSeatingGuest')) {
      this.flexibleSeatingGuestService.populateData();
    }
    if (this.contextService.assignedServices.includes('CommPilotExpress')) {
      this.commPilotExpressService.populateCommpilot();
      this.commPilotExpressService.populateInOffice();
      this.commPilotExpressService.populateOutOffice();
      this.commPilotExpressService.populateBusy();
      this.commPilotExpressService.populateUnavailable();
    }
    if (this.contextService.assignedServices.includes('CallTransfer')) {
      this.callTransferService.populateData();
    }
    if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in')) {
      this.directedCallPickUpWithBargeInService.populateData();
    }
    if (this.contextService.assignedServices.includes('CallCenterMonitoring')) {
      this.callCenterMonitoringService.populateData();
    }
  }
  isServiceListLoaded: boolean = false;
  serviceTree(s: string) {
    this.serviceTreeArray.push(s.toLowerCase());
  }
  settingsTree(s: string) {
    this.settingsTreeArray.push(s.toLowerCase());
  }
  /**
   * Used to populate settings tree
   * 
   * @returns 
   * @memberof SettingService
   */
  getServiceSettMenu() {

    if (!this.isServiceListLoaded) {
      this.isServiceListLoaded = true;
      let settingsMenu: MenuItem[] = [];
      let incomingCalls = [];
      let outgoingCalls = [];
      let callControl = [];
      let voiceMail = [];
      let commPilotExpress = [];
      let callCenter = [];
      let agent = [];
      let statistics = [];
      let supervisor = [];
      if (this.contextService.assignedServices.includes('AnonymousCallRejection')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.AnonymousCallRejection'));
        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.AnonymousCallRejection'), routerLink: ['anonymous-call-rejection'] });
      }

      if (this.contextService.assignedServices.includes('CallForwardingAlways')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallForwardAlways'));
        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallForwardAlways'), routerLink: ['call-forwarding-always'] });
      }
      if (this.contextService.assignedServices.includes('CallForwardingBusy')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallForwardBusy'));
        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallForwardBusy'), routerLink: ['call-forwarding-busy'] });
      }
      if (this.contextService.assignedServices.includes('CallForwardingNoAnswer')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallForwardNoAnswer'));

        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallForwardNoAnswer'), routerLink: ['call-forward-no-answer'] });
      }
      if (this.contextService.assignedServices.includes('CommPilotExpress')) {
        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress'), routerLink: ['comm-pilot-express'], items: commPilotExpress, expanded: true }
        );
      }

      //agentstart
      if ((this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium') && this.contextService.objSettings.STARTUP === 2) || (this.contextService.objSettings.STARTUP === 3 && this.contextService.callCenterList.length > 0)) {
        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter'), routerLink: ['call-center'], items: callCenter, expanded: true });
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter'));
        if (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium')) {
          callCenter.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent'), routerLink: ['agent'], items: agent, expanded: true });
          this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent'));
          agent.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.AcdState'), routerLink: ['acd-state'] });
          this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.AcdState'));
        }
        //supervisor
        if (this.contextService.objSettings.STARTUP === 3 && this.contextService.callCenterList.length > 0) {
         
          if (this.contextService.assignedServices.includes('CallCenterMonitoring')) {
            callCenter.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor'), routerLink: ['supervisor'], items: supervisor, expanded: true });
            this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor'));
            supervisor.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.CallCenterMonitoring'), routerLink: ['call-center-monitoring'] });
            this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.CallCenterMonitoring'));
          } else{
            callCenter.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor'), routerLink: ['supervisor'], expanded: true });
            this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor'));
          }
        }
        //supervisor
        //agentP3
        if (!this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
          callCenter.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics'), routerLink: ['statistics'], items: statistics, expanded: true });
          this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics'));
          statistics.push({ label: this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns'), routerLink: ['columns'] });
          this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns'));
        }
        //agentP3


      }
      //agentend

      if (this.contextService.assignedServices.includes('DoNotDisturb')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.DoNotDisturb'));

        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.DoNotDisturb'), routerLink: ['do-not-disturb'] });
      }
      if (this.contextService.assignedServices.includes('ExternalCallingLineIDDelivery')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.ExternalCallingLineIdDelivery'));

        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.ExternalCallingLineIdDelivery'), routerLink: ['external-calling-line-id-delivery'] });
      }
      if (this.contextService.assignedServices.includes('InternalCallingLineIDDelivery')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.InternalCallingLineIdDelivery'));

        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.InternalCallingLineIdDelivery'), routerLink: ['internal-calling-line-id-delivery'] });
      }
      if (this.contextService.assignedServices.includes('SimultaneousRingPersonal')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.SimRing'));

        incomingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.SimRing'), routerLink: ['simultaneous-ring'] }
        );
      }

      if (this.contextService.assignedServices.includes('CallingLineIDDeliveryBlocking')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallingLineIdDeliveryBlocking'));

        outgoingCalls.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallingLineIdDeliveryBlocking'), routerLink: ['calling-line-id-delivery-blocking'] });
      }
      if (this.contextService.assignedServices.includes('BroadWorksAnywhere')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere'), routerLink: ['bw-anywhere'] }
        );
      }
      if (this.contextService.assignedServices.includes('CallTransfer')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer'), routerLink: ['call-transfer'] }
        );
      }
      if (this.contextService.isCallParkAssigned) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallParkRetrieve'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallParkRetrieve'), routerLink: ['call-park-retrieve'] }
        );
      }
      if (this.contextService.assignedServices.includes('CallWaiting')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallWaiting'));
        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallWaiting'), routerLink: ['call-waiting'] }
        );
      }
      if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.DirectedCallPickupWithBargeIn'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.DirectedCallPickupWithBargeIn'), routerLink: ['directed-call-pickup-with-barge-in'] }
        );
      }
      if (this.contextService.assignedServices.includes('FlexibleSeatingGuest')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.FlexibleSeatingGuest'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.FlexibleSeatingGuest'), routerLink: ['flexible-seating-guest'] });
      }
      if (this.contextService.assignedServices.includes('RemoteOffice')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.RemoteOffice'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.RemoteOffice'), routerLink: ['remote-office'] }
        );
      }
      if (this.contextService.assignedServices.includes('VoiceMessaging')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.Voicemail'));

        voiceMail.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.Voicemail'), routerLink: ['voice-mail-service'] }
        );
      }
      if (this.contextService.assignedServices.includes('CommPilotExpress')) {
        commPilotExpress.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice'), routerLink: ['available-in-office'] }
        );
      }
      if (this.contextService.assignedServices.includes('CommPilotExpress')) {
        commPilotExpress.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.OutOfOffice'), routerLink: ['available-out-of-office'] }
        );
      }
      if (this.contextService.assignedServices.includes('CommPilotExpress')) {
        commPilotExpress.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Busy'), routerLink: ['busy'] }
        );
      }
      if (this.contextService.assignedServices.includes('CommPilotExpress')) {
        commPilotExpress.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable'), routerLink: ['unavailable'] }
        );
      }
      if (this.contextService.assignedServices.includes('CallRecording') && this.contextService.objLoggedUserDetails.getuserCanChangeCallRecordingOption() === '1') {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.CallRecording'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallRecording'), routerLink: ['call-recording'] }
        );
      }
      if (this.contextService.assignedServices.includes('HotelingGuest')) {
        this.serviceTree(this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest'));

        callControl.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.HotelingGuest'), routerLink: ['hoteling-guest'] }
        );
      }

      if (incomingCalls.length > 0) {

        this.serviceSettMenu.push(
          {
            label: this.contextService.getLanguageTagByKey('Settings.Services.IncomingCalls'),
            expanded: true,
            items: incomingCalls
          }
        );
      }

      if (outgoingCalls.length > 0) {
        this.serviceSettMenu.push(
          {
            label: this.contextService.getLanguageTagByKey('Settings.Services.OutgoingCalls'),
            expanded: true,
            items: outgoingCalls
          }
        );
      }
      if (callControl.length > 0) {
        this.serviceSettMenu.push(
          {
            label: this.contextService.getLanguageTagByKey('Settings.Services.CallControl'),
            expanded: true,
            items: callControl
          }

        );

      }
      if (voiceMail.length > 0) {
        this.serviceSettMenu.push(
          {
            label: this.contextService.getLanguageTagByKey('Settings.Services.Messaging'),
            expanded: true,
            items: voiceMail
          }

        );

      }


    }

    return this.serviceSettMenu;

  }
  isSettingsListLoaded = false;
  /**
   * Returns settings menu 
   * 
   * @returns 
   * @memberof SettingService
   */
  getGeneralSettMenu() {

    if (!this.isSettingsListLoaded) {
      this.isSettingsListLoaded = true;
      let unitySetting = [];
      let connection = [];
      let computerPhoneIntergration = [];
      let appearance = [];
      //agentp3
      let authentication = [];

      computerPhoneIntergration.push({ label: this.contextService.getLanguageTagByKey('Settings.Cti.DialRule'), routerLink: ['dialing-rule'] },
        { label: this.contextService.getLanguageTagByKey('Settings.Cti.Outlook'), routerLink: ['outlook-integration'] },
        { label: this.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration'), routerLink: ['browser-integration'] },
        { label: this.contextService.getLanguageTagByKey('Settings.Cti.Notification'), routerLink: ['call-notification'] });

      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule'));
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Cti.Outlook'));
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration'));
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Cti.Notification'));

      this.computerPhoneIntegration.push({ label: this.contextService.getLanguageTagByKey('Settings.Cti'), expanded: true, items: computerPhoneIntergration });

      appearance.push({ label: this.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch'), routerLink: ['contact-search'] });
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch'));

      unitySetting.push({ label: this.contextService.getLanguageTagByKey('Misc.Appearance'), expanded: true, items: appearance, routerLink: ['appearance'] });

      this.settingsTree(this.contextService.getLanguageTagByKey('Misc.Appearance'));

      unitySetting.push({ label: this.contextService.getLanguageTagByKey('Settings.Cti'), expanded: true, items: computerPhoneIntergration });
      connection.push({ label: this.contextService.getLanguageTagByKey('Settings.Connection.Network'), routerLink: ['network'] });
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Connection.Network'));

      //agentP3
      if ((this.contextService.objSettings.STARTUP === 2 && (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium')) || this.contextService.objSettings.STARTUP === 3) && this.contextService.callCenterList.length > 0 && !this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
        connection.push({ label: this.contextService.getLanguageTagByKey('Settings.Connection.Authentication'), routerLink: ['authentication'], expanded: true, items: authentication });
        this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Connection.Authentication'));
        authentication.push({ label: this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.CallCenters'), routerLink: ['call-centers-authentication'] });
        this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.CallCenters'));
      } else {
        connection.push({ label: this.contextService.getLanguageTagByKey('Settings.Connection.Authentication'), routerLink: ['authentication'] });
        this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Connection.Authentication'));
      }
      //agentP3
      // unitySetting.push({ label: this.contextService.getLanguageTagByKey('Settings.Startup'), routerLink: ['startup'] });
      // this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Startup'));

      if (this.contextService.isElectron === true) {
        unitySetting.push({ label: this.contextService.getLanguageTagByKey('Settings.Update'), routerLink: ['update'] });
        this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Update'));

      }
      unitySetting.push({ label: this.contextService.getLanguageTagByKey('Settings.Language'), routerLink: ['language'] });
      this.settingsTree(this.contextService.getLanguageTagByKey('Settings.Language'));

      this.computerPhoneIntegration.push({
        label: this.contextService.getLanguageTagByKey('Settings.Connection'),
        expanded: true,
        items: connection
      });

      if (!(this.contextService.objLoggedUserDetails.getlicenseType() === 'None')) {
        this.generalSettMenu.push(
          {
            label: this.contextService.getLanguageTagByKey('Settings.UnitySettings'),
            expanded: true,
            items: unitySetting
          });
      }
      this.generalSettMenu.push(
        {
          label: this.contextService.getLanguageTagByKey('Settings.Connection'),
          expanded: true,
          items: connection
        },
      );

    }


    return this.generalSettMenu;
  }
  /**
   * Validate sevices
   * 
   * @returns {boolean} 
   * @memberof SettingService
   */
  validate(): boolean {

    if (this.contextService.assignedServices.includes('CallForwardingAlways')) {
      if (!this.callForwardningAlwaysService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('CallForwardingBusy')) {
      if (!this.callForwardingBusyService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('CallForwardingNoAnswer')) {

      if (!this.callForwardNoAnswerService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('SimultaneousRingPersonal')) {
      if (!this.simultaneousRingService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('FlexibleSeatingGuest')) {
      if (!this.flexibleSeatingGuestService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('HotelingGuest')) {
      if (!this.hotelingGuestService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('RemoteOffice')) {
      if (!this.remoteOfficeService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('VoiceMessaging')) {
      if (!this.voiceMailService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('CallTransfer')) {
      if (!this.callTransferService.validate()) {
        return false;
      }
    }
    if (this.contextService.assignedServices.includes('CommPilotExpress')) {
      if (!this.commPilotExpressService.validateInOffice()) {
        return false;
      }
      if (!this.commPilotExpressService.validateOutOffice()) {
        return false;
      }
      if (!this.commPilotExpressService.validateBusy()) {
        return false;
      }
      if (!this.commPilotExpressService.validateUnavailable()) {
        return false;
      }
    }

    return true;
  }
  saveBWA(): boolean {
    if (this.contextService.checkBWAnywhere) {
      if (this.contextService.assignedServices.includes('BroadWorksAnywhere')) {
        this.bWAnywhereService.save();
        if (this.bWAnywhereService.validateFlag) {
          return false;
        }

        if (this.bWAnywhereService.currentBWPage != "0") {
          if (this.bWAnywhereService.currentBWPage === "1" && !this.bWAnywhereService.checkSaveLocationDetails) {
            this.bWAnywhereService.currentBWPage = "0";
            this.navigatePage();
          } else if (this.bWAnywhereService.currentBWPage === "2") {
            return true;
            // this.bWAnywhereService.currentBWPage = "1";

          } else if (this.bWAnywhereService.currentBWPage === "3" && !this.bWAnywhereService.checkSaveCriteria) {
            this.bWAnywhereService.currentBWPage = "2";
          }

        }
      }
    } else {
      this.bWAnywhereService.currentBWPage = "0";
      return true;
    }
    //  if(this.bWAnywhereService.currentBWPage == "1"){
    //    this.navigatePage();
    //   return false;
    //  }
    return false;
  }
  /**
   * Save services
   * 
   * @returns {boolean} 
   * @memberof SettingService
   */
  save(): boolean {

    if (this.contextService.assignedServices.includes('AnonymousCallRejection')) {
      this.anonymousCallRejectionService.save();
    }
    if (this.contextService.assignedServices.includes('CallForwardingAlways')) {
      this.callForwardningAlwaysService.save();
    }
    if (this.contextService.assignedServices.includes('CallForwardingBusy')) {
      this.callForwardingBusyService.save();
    }
    if (this.contextService.assignedServices.includes('CallForwardingNoAnswer')) {
      this.callForwardNoAnswerService.save();
    }
    if (this.contextService.assignedServices.includes('DoNotDisturb')) {
      this.doNotDisturbService.save();
    }
    if (this.contextService.assignedServices.includes('ExternalCallingLineIDDelivery')) {
      this.externalCallingLineIDDeliveryService.save();
    }
    if (this.contextService.assignedServices.includes('InternalCallingLineIDDelivery')) {
      this.internalCallingLineIDDeliveryService.save();
    }
    if (this.contextService.assignedServices.includes('SimultaneousRingPersonal')) {
      this.simultaneousRingService.save();
    }
    if (this.contextService.assignedServices.includes('CallingLineIDDeliveryBlocking')) {
      this.callingLineIDDeliveryBlockingService.save();
    }
    if (this.contextService.assignedServices.includes('BroadWorksAnywhere')) {
      this.bWAnywhereService.save();
    }
    if (this.contextService.assignedServices.includes('CallWaiting')) {
      this.callWaitingService.save();
    }
    if (this.contextService.assignedServices.includes('RemoteOffice')) {
      this.remoteOfficeService.save();
    }
    if (this.contextService.assignedServices.includes('VoiceMessaging')) {
      this.voiceMailService.save();
    }
    if (this.contextService.assignedServices.includes('HotelingGuest')) {
      this.hotelingGuestService.save();
    }
    if (this.contextService.assignedServices.includes('CallRecording')) {
      this.callRecordingService.save();
    }
    if (this.contextService.assignedServices.includes('CommPilotExpress')) {
      this.commPilotExpressService.saveCommPilotService();
      this.commPilotExpressService.saveCommPilot();
    }
    if (this.contextService.assignedServices.includes('FlexibleSeatingGuest')) {
      this.flexibleSeatingGuestService.save();
    }
    if (this.contextService.assignedServices.includes('CallTransfer')) {
      this.callTransferService.save();
    }
    if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in')) {
      this.directedCallPickUpWithBargeInService.save();
    }
    if (this.contextService.assignedServices.includes('CallCenterMonitoring')) {
      this.callCenterMonitoringService.save();
    }
    return true;

  }
  getCurrentBWPAge(): string {
    return this.bWAnywhereService.currentBWPage.toString();
  }
  navigatePage() {

    if (this.bWAnywhereService.currentBWPage === "0") {
      this.router.navigate(['/settings-service', 'bw-anywhere']);
      this.bWAnywhereService.location = "";
    } else if (this.bWAnywhereService.currentBWPage === "1") {
      this.router.navigate(['/settings-service', 'bw-anywhere-location-details']);
    } else if (this.bWAnywhereService.currentBWPage === "2") {
      this.router.navigate(['/settings-service', 'bw-anywhere-criteria']);
    }
  }
  changeBWPage() {
    if (this.bWAnywhereService.currentBWPage != "0") {
      if (this.bWAnywhereService.currentBWPage === "1") {
        this.bWAnywhereService.currentBWPage = "0";
      } else if (this.bWAnywhereService.currentBWPage === "2") {
        this.bWAnywhereService.currentBWPage = "1";
      } else if (this.bWAnywhereService.currentBWPage === "3") {
        this.bWAnywhereService.currentBWPage = "2";
      }

    }
  }
  /**
   * Cancel service changes
   * 
   * @returns {boolean} 
   * @memberof SettingService
   */
  cancel(): boolean {
    this.doNotDisturbService.cancel();
    this.callForwardingBusyService.cancel();
    this.anonymousCallRejectionService.cancel();
    this.callForwardingBusyService.cancel();
    this.callForwardningAlwaysService.cancel();
    this.callForwardNoAnswerService.cancel();
    this.callingLineIDDeliveryBlockingService.cancel();
    this.callRecordingService.cancel();
    this.callWaitingService.cancel();
    this.externalCallingLineIDDeliveryService.cancel();
    this.internalCallingLineIDDeliveryService.cancel();
    this.voiceMailService.cancel();
    this.simultaneousRingService.cancel();
    this.bWAnywhereService.cancel();
    this.hotelingGuestService.cancel();
    this.flexibleSeatingGuestService.cancel();
    this.remoteOfficeService.cancel();
    this.callTransferService.cancel();
    this.directedCallPickUpWithBargeInService.cancel();
    this.callCenterMonitoringService.cancel();
    if (this.bWAnywhereService.currentBWPage != "0") {
      if (this.bWAnywhereService.currentBWPage === "1") {
        this.bWAnywhereService.currentBWPage = "0";
      } else if (this.bWAnywhereService.currentBWPage === "2") {
        this.bWAnywhereService.currentBWPage = "1";
      } else if (this.bWAnywhereService.currentBWPage === "3") {
        this.bWAnywhereService.currentBWPage = "2";
      }
      return false;
    } else {
      return true;
    }
  }
}
