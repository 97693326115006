import { ContextService } from 'app/providers/context.service';
import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from "app/providers/settings/settings-general/settings-general.service";
@Component({
  selector: 'app-call-park-retrieve',
  templateUrl: './call-park-retrieve.component.html',
  styleUrls: ['./call-park-retrieve.component.scss']
})
export class CallParkRetrieveComponent implements OnInit {
  description: string = "";
  callParkRetrieveShowButtonLbl: string = "";
  constructor(public contextService: ContextService,public settingsGeneral:SettingsGeneral) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallParkRetrieve.Description');
    this.callParkRetrieveShowButtonLbl = this.contextService.getLanguageTagByKey('Settings.Services.CallParkRetrieve.ShowButton');
    this.settingsGeneral.settingsChanged=true;
  }

}
