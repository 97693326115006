import { loggedUserCallCenterList } from "./loggedUserCallCenterList";

export class LoggedAgentDetails {
    public state: string;
    public stateTimestamp: string;
    public unavailableCode:string;
    callCenters:Array<loggedUserCallCenterList>=[];


    public useDefaultGuardTimer: boolean = false;
    public enableGuardTimer: boolean = false;
    public guardTimerSeconds: string = ""; 
    public useSystemDefaultUnavailableSettings: boolean = false;
    public forceAgentUnavailableOnDNDActivation: boolean = false;
    public forceUnavailableOnPersonalCalls: boolean = false;
    public forceAgentUnavailableOnBouncedCallLimit: boolean = false;
    public numberConsecutiveBouncedCallsToForceAgentUnavailable: string = "";
    public makeOutgoingCallsAsCallCenter: boolean = false;
    public dnisServiceUserId:string="";
    public dnisName:string="";
   

    constructor(state: string, stateTimestamp: string,unavailableCode:string) {
        this.state = state;
        this.stateTimestamp = stateTimestamp;
        this.unavailableCode=unavailableCode;
    }
}