import { ResponseHandler } from '../../../shared/ResponseHandler';
import { BwServices } from '../../../providers/settings/settings-services/bw-services.service';
import { ResponseRouter } from '../../../shared/ResponseRouter';
import { Injectable } from "@angular/core";


@Injectable()
export class BwServiceResponseHandler extends ResponseRouter implements ResponseHandler {
    type = "GetServiceConfigurationResponse";
    
    public handle(response: Document) {
        let xmlRootService = response.documentElement.getAttribute("service");
        if (this.responseHandlersList[xmlRootService]) {
            this.responseHandlersList[xmlRootService].handle(response);
        }
    }
    constructor() {
        super();
    }

}
