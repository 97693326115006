import { Injectable } from '@angular/core';
import { VoiceMessaging } from '../../../model/bw-services/voice-messaging';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';

/**
 * service for voicemail in services
 * 
 * @export
 * @class VoiceMailService
 */
@Injectable()
export class VoiceMailService {

    isActive: boolean = false;
    processing: string = "";
    voiceMessageDeliveryEmailAddress: string = "";
    usePhoneMessageWaiting: boolean = false;
    sendVoiceMessageNotifyEmail: boolean = false;
    voiceMessageNotifyEmailAddress: string = "";
    sendCarbonCopyVoiceMessage: boolean = false;
    voiceMessageCarbonCopyEmailAddress: string = "";
    transferOnZeroToPhoneNumber: boolean = false;
    transferPhoneNumber: string = "";
    alwaysRedirectToVoiceMail: boolean = false;
    busyRedirectToVoiceMail: boolean = false;
    noAnswerRedirectToVoiceMail: boolean = false;

    voiceMessaging: VoiceMessaging;
    voicemailChanges: boolean = false;
    numberValidation: boolean = false;
    showingVoicemailTab: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }
    setData(voiceMessaging: VoiceMessaging) { }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    save() { }
    populateData() { }
    cancel() { }

}