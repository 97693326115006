import { Injectable } from '@angular/core';
import { ExternalCallingLineIDDelivery } from '../../../model/bw-services/external-calling-line-id-delivery';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';


/**
 * to register externalCallingLineIDDeliveryResponseHandler   
 * 
 * @export
 * @class ExternalCallingLineIDDeliveryService
 */
@Injectable()

export class ExternalCallingLineIDDeliveryService {
    isActive: boolean = false;
    externalCallingLineIDDelivery: ExternalCallingLineIDDelivery;
    externalLineChanges: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - ExternalCallingLineIDDeliveryService');
    }
    setData(externalCallingLineIDDelivery: ExternalCallingLineIDDelivery) { }
    validate(): boolean {
        return true;
    }
    save() { }
    populateData() { }
    cancel() { }
}