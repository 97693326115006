<div class="row">
  <p>
    {{description}}
  </p>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{enabled}} [(ngModel)]="doNotDisturbService.isActive" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{ringSplash}} [(ngModel)]="doNotDisturbService.isRingSplash" binary="true"
    [disabled]="!doNotDisturbService.isActive"></p-checkbox>
</div>