<div class="crm-wrap">
  <form
    (ngSubmit)="onFormSubmit()"
    [formGroup]="crmForm"
    #rfRef="ngForm"
    id="crmForm"
  >
    <h4 class="blue-headline">{{ title }}</h4>

    <textarea
      name="textarea"
      [(ngModel)]="note"
      formControlName="note"
      rows="5"
      required
    ></textarea>

    <p-checkbox
      name="closeImeediately"
      label="{{ check }}"
      [(ngModel)]="closeImeediately"
      class="full-width"
      binary="true"
      formControlName="closeImeediately"
    ></p-checkbox>

    <div class="crn-btns">
      <a class="btn-white" (click)="showContact()">{{ btnShow }}</a>
      <a class="btn-white" (click)="close()">{{ btnCancel }}</a>
      <button type="submit" form="crmForm" class="btn-blue-light">
        {{ btnOk }}
      </button>
    </div>
  </form>
</div>
