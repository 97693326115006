import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from 'app/providers/helper.service';
import { ContextService } from '../../../../../../../providers/context.service';
import { SettingsGeneral } from "../../../../../../../providers/settings/settings-general/settings-general.service";

@Component({
  selector: 'app-columns',
  templateUrl: './columns.component.html',
  styleUrls: ['./columns.component.scss']
})
export class ColumnsComponent implements OnInit {
  selectedQueueColumn: any = "";
  delete: string = "";
  plus: string = "";
  description: string = "";
  sortable = [];

  miscYes: string = "";
  miscNo: string = "";
  agentActivityText:string="";
  agentColumnText:string="";
  queueColumnText:string="";
  @ViewChild('datatable') queueTable;
  constructor(private router: Router, private helperService: HelperService, public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.agentActivityText=this.contextService.getLanguageTagByKey('TabShowing.AgentActivity');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.Columns.Description');
    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.settingsGeneral.settingsChanged = true;
    this.settingsGeneral.selectedAgentActivityColumn="";
    this.agentColumnText=this.contextService.getLanguageTagByKey('Settings.Services.PersonalWallboard.AgentListHeading');
    this.queueColumnText=this.contextService.getLanguageTagByKey('Settings.Services.PersonalWallboard.QueueListHeading');
  }
  ngOnDestroy() {
    this.settingsGeneral.selectQueueTab = 0;
  }
  showDeleteAlert(stat) {

    if (stat === "agentStat") {
      this.contextService.log("agent stat table delete clicked");
      if (this.settingsGeneral.agentColumnsInTable.length > 0 && this.settingsGeneral.selectedAgentColumn) {
        this.settingsGeneral.deleteAgentVis = true;
      }
    } else if (stat === "queueStat") {
      this.contextService.log("queue stat table delete clicked");
      if (this.settingsGeneral.queueColumnsInTable.length > 0 && this.settingsGeneral.selectedQueueColumn) {
        this.settingsGeneral.deleteQueueVis = true;
      }
    } else if (stat === "agentActivity") {
      this.contextService.log("agent activity stat table delete clicked");

      if (this.settingsGeneral.agentActivityColumnsInTable.length > 0 && this.settingsGeneral.selectedAgentActivityColumn) {
        this.settingsGeneral.deleteAgentActivityVis = true;
      }
    }


  }
  addAgentColumn() {

    this.contextService.agentColumnList = [];
    this.contextService.agentColumnBackupList.forEach(element => {
      this.contextService.agentColumnList.push(element);
    });
    this.settingsGeneral.agentColumnsInTable.forEach(tableElement => {
      this.contextService.agentColumnList.forEach((dropdownElement, index) => {
        if (tableElement.value === dropdownElement.value) {
          this.contextService.agentColumnList.splice(index, 1);
        }
      });
    });
    if (this.contextService.agentColumnList.length > 0) {
      this.settingsGeneral.currentCallCenterAgentPage = "1";
      this.contextService.log("agent stats in dropdown: ");
      this.contextService.log(this.contextService.agentColumnList);
      //to  clear all fields
      this.contextService.columnHeading = "";
      this.contextService.setThreshold = false;
      this.settingsGeneral.highlightNonZeroValues = false;
      this.settingsGeneral.increasingThresholdValues = false;
      this.settingsGeneral.greenThreshold = "";
      this.settingsGeneral.yellowThreshold = ""
      this.settingsGeneral.redThreshold = "";
      this.settingsGeneral.blackThreshold = "";
      this.router.navigate(['/settings-service', 'agent-threshold']);
    } else {
      this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Prompt.AllColumnsDisplayed'));
    }


  }

  addAgentActivityColumn() {
    this.settingsGeneral.disableAgentActivityColumnList=false;
    this.contextService.agentActivityColumnList = [];
    this.contextService.agentActivityColumnBackupList.forEach(element => {
      this.contextService.agentActivityColumnList.push(element);
    });
    this.settingsGeneral.agentActivityColumnsInTable.forEach(tableElement => {
      this.contextService.agentActivityColumnList.forEach((dropdownElement, index) => {
        if (tableElement.value === dropdownElement.value) {
          this.contextService.agentActivityColumnList.splice(index, 1);
        }
      });
    });
    if (this.contextService.agentActivityColumnList.length > 0) {
      this.settingsGeneral.currentAgentActivityPage = "1";
      this.contextService.log("agent activity stats in dropdown: ");
      this.contextService.log(this.contextService.agentActivityColumnList);
      //to  clear all fields
      this.contextService.columnHeading = "";
      this.contextService.setThreshold = false;
      this.settingsGeneral.highlightNonZeroValues = false;
      this.settingsGeneral.increasingThresholdValues = false;
      this.settingsGeneral.greenThreshold = "";
      this.settingsGeneral.yellowThreshold = ""
      this.settingsGeneral.redThreshold = "";
      this.settingsGeneral.blackThreshold = "";
      this.settingsGeneral.isThresholdShows=false;
      this.contextService.agentActivityStatisticsToDisplay="";
      this.router.navigate(['/settings-service', 'agent-activity-threshold']);
    } else {
      this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Prompt.AllColumnsDisplayed'));
    }


  }

  addCallCenterColumn() {
    this.contextService.queueColumnList = [];
    this.contextService.queueColumnBackupList.forEach(element => {
      this.contextService.queueColumnList.push(element);
    });

    this.settingsGeneral.queueColumnsInTable.forEach(tableElement => {
      this.contextService.queueColumnList.forEach((dropdownElement, index) => {
        if (tableElement.value === dropdownElement.value) {
          this.contextService.queueColumnList.splice(index, 1);
        }
      });
    });

    if (this.contextService.queueColumnList.length > 0) {
      this.settingsGeneral.currentCallCenterQueuePage = "1";
      this.contextService.columnHeading = "";
      this.settingsGeneral.disableQueueColumnList = false;
      if (this.contextService.queueColumnList[0].value) {
        this.contextService.queueStatisticsToDisplay = this.contextService.queueColumnList[0].value;
      }
      this.contextService.queueStatisticsList.forEach(element => {
        if (this.contextService.queueStatisticsToDisplay === element.statName) {
          for (const key in element.callCentersList) {
            if (this.contextService.agentDetails.callCenters.hasOwnProperty(key)) {
              element.callCentersList[key].yellow = "";
              element.callCentersList[key].green = "";
              element.callCentersList[key].red = "";
              element.callCentersList[key].black = "";
            }
          }
          element.callCentersList["all"].yellow = "";
          element.callCentersList["all"].green = "";
          element.callCentersList["all"].red = "";
          element.callCentersList["all"].black = "";

          this.contextService.setThreshold = element.setThreshold;

        }
      });
      this.contextService.queueThresholdList = [];
      this.contextService.queueStatisticsList.forEach(element => {
        if (this.contextService.queueStatisticsToDisplay === element.statName) {
          this.contextService.queueThresholdList.push({ id: 1, name: "All", value: "all", green: element.callCentersList["all"].green, yellow: element.callCentersList["all"].yellow, red: element.callCentersList["all"].red, black: element.callCentersList["all"].black });
          for (let i = 0; i < this.contextService.callCenterList.length; i++) {
            let element1 = this.contextService.callCenterList[i];
            this.contextService.queueThresholdList.push({ id: 2, name: this.contextService.getLastNameFromUserID(element1.value), value: element1.value, green: element.callCentersList[element1.value].green, yellow: element.callCentersList[element1.value].yellow, red: element.callCentersList[element1.value].red, black: element.callCentersList[element1.value].black });
          }
        }
      });

      this.router.navigate(['/settings-service', 'callcenter-threshold']);
    } else {
      this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Prompt.AllColumnsDisplayed'));
    }

  }

  doubleClickAgentTable(event) {
    this.contextService.log("doubleClickAgentTable clicked");
    this.settingsGeneral.selectedAgentColumn = event;
    if (this.settingsGeneral.selectedAgentColumn) {
      this.settingsGeneral.loadAgentColumnDetailsOnDoubleClick(this.settingsGeneral.selectedAgentColumn);
    }

  }

  doubleClickCallCenterTable(event) {
    this.contextService.log("doubleClickCallCenterTable clicked");
    this.settingsGeneral.selectedQueueColumn = event;
    if (this.settingsGeneral.selectedQueueColumn) {
      this.settingsGeneral.loadQueueColumnDetailsOnDoubleClick(this.settingsGeneral.selectedQueueColumn);
    }
  }
  doubleClickAgentActivityTable(event) {
    this.contextService.log("doubleClickAgentActivityTable clicked");
    this.settingsGeneral.selectedAgentActivityColumn = event;
    if (this.settingsGeneral.selectedAgentActivityColumn) {
      this.settingsGeneral.loadAgentActivityColumnDetailsOnDoubleClick(this.settingsGeneral.selectedAgentActivityColumn);
    }
  }

  changeRowToUP() {
    this.contextService.log("changeRowToUP() clicked");
    this.contextService.log(this.settingsGeneral.agentColumnsInTable);

    let selectedIndex;
    if (this.settingsGeneral.selectedAgentColumn) {
      this.settingsGeneral.agentColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedAgentColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex > 0) {
        let tempA = this.settingsGeneral.agentColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.agentColumnsInTable[selectedIndex - 1];
        this.settingsGeneral.agentColumnsInTable[selectedIndex - 1] = tempA;
        this.settingsGeneral.agentColumnsInTable[selectedIndex] = tempB;

        let showAgentsListTemp = [...this.settingsGeneral.agentColumnsInTable];
        this.settingsGeneral.agentColumnsInTable = showAgentsListTemp;
      }
      this.settingsGeneral.updateColumnsInPersonalWallboard();
    }
    this.contextService.log(this.settingsGeneral.agentColumnsInTable);


  }
  changeRowToDown() {
    this.contextService.log("changeRowToDown() clicked");

    let selectedIndex;
    if (this.settingsGeneral.selectedAgentColumn) {
      this.settingsGeneral.agentColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedAgentColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex < this.settingsGeneral.agentColumnsInTable.length - 1) {
        let tempA = this.settingsGeneral.agentColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.agentColumnsInTable[selectedIndex + 1];
        this.settingsGeneral.agentColumnsInTable[selectedIndex + 1] = tempA;
        this.settingsGeneral.agentColumnsInTable[selectedIndex] = tempB;

        let showAgentsListTemp = [...this.settingsGeneral.agentColumnsInTable];
        this.settingsGeneral.agentColumnsInTable = showAgentsListTemp;
      }
      this.settingsGeneral.updateColumnsInPersonalWallboard();
    }

  }

  changeQueueRowToUP() {
    this.contextService.log("changeQueueRowToUP() clicked");
    let selectedIndex;
    if (this.settingsGeneral.selectedQueueColumn) {
      this.settingsGeneral.queueColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedQueueColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex > 0) {
        let tempA = this.settingsGeneral.queueColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.queueColumnsInTable[selectedIndex - 1];
        this.settingsGeneral.queueColumnsInTable[selectedIndex - 1] = tempA;
        this.settingsGeneral.queueColumnsInTable[selectedIndex] = tempB;

        let showQueuesListTemp = [...this.settingsGeneral.queueColumnsInTable];
        this.settingsGeneral.queueColumnsInTable = showQueuesListTemp;
      }

      this.settingsGeneral.updateColumnsInPersonalWallboard();

    }

  }

  changeQueueRowToDown() {
    this.contextService.log("changeQueueRowToDown() clicked");
    let selectedIndex;
    if (this.settingsGeneral.selectedQueueColumn) {
      this.settingsGeneral.queueColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedQueueColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex < this.settingsGeneral.queueColumnsInTable.length - 1) {
        let tempA = this.settingsGeneral.queueColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.queueColumnsInTable[selectedIndex + 1];
        this.settingsGeneral.queueColumnsInTable[selectedIndex + 1] = tempA;
        this.settingsGeneral.queueColumnsInTable[selectedIndex] = tempB;

        let showQueuesListTemp = [...this.settingsGeneral.queueColumnsInTable];
        this.settingsGeneral.queueColumnsInTable = showQueuesListTemp;
      }

      this.settingsGeneral.updateColumnsInPersonalWallboard();

    }

  }

  changeAgentActivityRowToUP() {
    this.contextService.log("changeAgentActivityRowToUP() clicked");
    let selectedIndex;
    if (this.settingsGeneral.selectedAgentActivityColumn) {
      this.settingsGeneral.agentActivityColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedAgentActivityColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex > 0) {
        let tempA = this.settingsGeneral.agentActivityColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.agentActivityColumnsInTable[selectedIndex - 1];
        this.settingsGeneral.agentActivityColumnsInTable[selectedIndex - 1] = tempA;
        this.settingsGeneral.agentActivityColumnsInTable[selectedIndex] = tempB;

        let showAgentListTemp = [...this.settingsGeneral.agentActivityColumnsInTable];
        this.settingsGeneral.agentActivityColumnsInTable = showAgentListTemp;
      }

      this.settingsGeneral.updateAgentActivityTab();

    }

  }

  changeAgentActivityRowToDown() {
    this.contextService.log("changeAgentActivityRowToDown() clicked");
    let selectedIndex;
    if (this.settingsGeneral.selectedAgentActivityColumn) {
      this.settingsGeneral.agentActivityColumnsInTable.forEach((element, index) => {
        if (this.settingsGeneral.selectedAgentActivityColumn.value === element.value) {
          selectedIndex = index;
        }
      });
      if (selectedIndex < this.settingsGeneral.agentActivityColumnsInTable.length - 1) {
        let tempA = this.settingsGeneral.agentActivityColumnsInTable[selectedIndex];
        let tempB = this.settingsGeneral.agentActivityColumnsInTable[selectedIndex + 1];
        this.settingsGeneral.agentActivityColumnsInTable[selectedIndex + 1] = tempA;
        this.settingsGeneral.agentActivityColumnsInTable[selectedIndex] = tempB;

        let showAgentsListTemp = [...this.settingsGeneral.agentActivityColumnsInTable];
        this.settingsGeneral.agentActivityColumnsInTable = showAgentsListTemp;
      }

      this.settingsGeneral.updateAgentActivityTab();

    }

  }
  rowClick(e) {
    this.helperService.disableRows(this.queueTable);
  }

}

