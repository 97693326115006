import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-stars-rating',
  templateUrl: './stars-rating.component.html',
  styleUrls: ['./stars-rating.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StarsRatingComponent implements OnInit {

  @Input() rating: number; // 0 - 5
  percentage: string;

  constructor() { }

  ngOnInit() {
    console.log(this.rating)
    var p = (100 / 5) * this.rating;
    this.percentage = p + "%";
  }
}