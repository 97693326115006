import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../../providers/context.service';
import { CommPilotExpressService } from '../../../../../../providers/settings/settings-services/comm-pilot-express.service';


@Component({
  selector: 'app-available-in-office',
  templateUrl: './available-in-office.component.html',
  styleUrls: ['./available-in-office.component.scss']
})
export class AvailableInOfficeComponent implements OnInit {
  description: string="";
  alsoRing: string="";
  ifBusy: string="";
  sendCallToVM: string="";
  frwdCall: string="";
  to: string="";
  ifNotAnswered: string="";
  toNotAnswered: string="";


  constructor(private contextService: ContextService, public commPilotExpressService: CommPilotExpressService) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice.Description');
    this.alsoRing = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice.AlsoRing');
    this.ifBusy = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice.IfBusy');
    this.to = this.contextService.getLanguageTagByKey('Misc.To');
    this.ifNotAnswered = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice.IfNotAnswered');
    this.toNotAnswered = this.contextService.getLanguageTagByKey('Misc.To');
    this.sendCallToVM = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.SendCallToVoicemail');
    this.frwdCall = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.ForwardCall');
    this.commPilotExpressService.commpilotServiceChanges = true;
  }

}



