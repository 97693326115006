import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { CallingLineIDDeliveryBlockingService } from '../../../../../providers/settings/settings-services/calling-line-id-delivery-blocking.service';
import { BaseComponent } from '../../../../base-component';




/**
 * This serves as a interface between component and callingLineIdDeliveryBlocking service
 * 
 * @export
 * @class CallingLineIDDeliveryBlockingComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-calling-line-id-delivery-blocking',
  templateUrl: './calling-line-id-delivery-blocking.component.html',
  styleUrls: ['./calling-line-id-delivery-blocking.component.scss']
})
export class CallingLineIDDeliveryBlockingComponent extends BaseComponent implements OnInit {
  description: string = "";
  servicesEnabled: string = "";
 
  constructor(public callingLineIDDeliveryBlockingService: CallingLineIDDeliveryBlockingService, private contextService: ContextService) {
    super();
  }

  ngOnInit() {
    this.callingLineIDDeliveryBlockingService.callingLineChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallingLineIdDeliveryBlocking.Description');
    this.servicesEnabled = this.contextService.getLanguageTagByKey('Settings.Services.Enabled');

  }

}


