<div class="row">
  <p>
    {{this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Description.CallCenter')}}
  </p>
</div>

<div class="row">
  <p>
    <b>
      {{this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.CallCenterLoginDetails')}}
    </b>
  </p>
</div>

<div class="row">
  <label for="" class="label">{{this.contextService.getLanguageTagByKey('Misc.Queue')}}
    {{this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.ID')}}</label>
  <input class="input-text" type="text" name="loginid" [(ngModel)]="settingsGeneral.callCenterID"
    (keypress)="dontAllowSpace($event)" autocomplete=off [readonly]="true">
</div>

<div class="row">
  <label for=""
    class="label">{{this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Password')}}</label>
  <input class="input-text" [disabled]="this.settingsGeneral.defaultPasswordDeleteButton" type="password"
    name="password" [(ngModel)]="settingsGeneral.callCenterPassword" (keypress)="dontAllowSpace($event)"
    autocomplete=off>

  <div class="call-log-btns" style=float:right;margin-top:7px
    *ngIf="this.settingsGeneral.passwordDeleteButtonVisisbility===true">
    <span class="del" (click)="removePasswordAlert()" title="{{toolTip}}"></span>
  </div>
</div>
<!-- <div class="row" *ngIf="this.settingsGeneral.defaultPasswordDeleteButton===true">
  <label for="" class="label">{{this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Password')}}</label>
  <input class="input-text" type="password" name="password" [disabled]="true"autocomplete=off>
</div> -->

<div class="row">
  <p>
    {{this.contextService.getLanguageTagByKey('CallCenter.LoginStatus')}}
  </p>
</div>
<div class="row input-text">
  <div class="box">
    <div class="row user-loggedin">
      <p *ngIf="isErrormsg" class="user-loggedoff">
        {{loggedoff}}
      </p>
      <p *ngIf="(isLogged) && (!isErrormsg)">
        {{loggedIn}}
      </p>
      <p *ngIf="(!isLogged) && (!isErrormsg)" class="user-loggedoff">
        {{loggedMessage}}
      </p>


    </div>


  </div>
</div>
<div class="row" *ngIf="this.settingsGeneral.defaultPasswordDeleteButton==false">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="settingsGeneral.applyToAllQueues"
    label={{this.applyPasswordToAllQueues}} binary="true"></p-checkbox>
</div>
<div class="row" *ngIf="this.settingsGeneral.defaultPasswordDeleteButton==true">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="settingsGeneral.showInWallBoard"
    label={{this.showInPersonalWallBoard}} binary="true"></p-checkbox>
</div>


<p-dialog [resizable]="false" [(visible)]="this.settingsGeneral.removePasswordVis" [closable]="false" [style]="{'width':'600px'}"
  [ngClass]="{visible: this.settingsGeneral.removePasswordVis}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false">
  <div class="modal-content">
    <p>{{ConfirmPasswordDelete}}</p>
    <div>
      <button type="button" class="btn-blue-light"
        (click)="removePassword()">{{this.contextService.getLanguageTagByKey('Misc.Yes')}}</button>
      <button type="button" class="btn-white"
        (click)="this.settingsGeneral.removePasswordVis = false">{{this.contextService.getLanguageTagByKey('Misc.No')}}</button>
    </div>
  </div>
</p-dialog>

<!-- <p-dialog [resizable]="false" [(visible)]="this.settingsGeneral.nullPassword" [closable]="false" width="600"
  [ngClass]="{visible: this.settingsGeneral.nullPassword}" class="dialog-window buttons-1" dismissableMask="true"
  showHeader="false">
  <div class="modal-content">
    <p>{{noDetailsEntered}}</p>
    <div>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.nullPassword = false">{{okBtn}}</button>
    </div>
  </div>
</p-dialog> -->


<p-dialog [resizable]="false" [(visible)]="this.settingsGeneral.nullPassword" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: this.settingsGeneral.nullPassword}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{this.noDetailsEntered}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.nullPassword = false">{{okBtn}}</button>
    </div>
  </div>
</p-dialog>

