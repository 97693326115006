import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { DoNotDisturbService } from '../../../../../providers/settings/settings-services/do-not-disturb.service';
import { ContextService } from '../../../../../providers/context.service';

/**
 * This serves as a interface between component and DoNotDisturb Service
 * 
 * @export
 * @class DoNotDisturbComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-do-not-disturb',
  templateUrl: './do-not-disturb.component.html',
  styleUrls: ['./do-not-disturb.component.scss']
})
export class DoNotDisturbComponent extends BaseComponent implements OnInit {
  description: String="";
  enabled: String="";
  ringSplash: String="";
  constructor(private contextService: ContextService, public doNotDisturbService: DoNotDisturbService) {
    super();
  }

  ngOnInit() {
    this.doNotDisturbService.dndChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.DoNotDisturb.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.ringSplash = this.contextService.getLanguageTagByKey('Settings.Services.RingSplash');

  }
  

}
