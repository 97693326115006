import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../../base-component';
import { ContextService } from '../../../../../../../providers/context.service';
import { SettingsGeneral } from "../../../../../../../providers/settings/settings-general/settings-general.service";
@Component({
  selector: 'app-acd-state',
  templateUrl: './acd-state.component.html',
  styleUrls: ['./acd-state.component.scss']
})
export class AcdStateComponent extends BaseComponent implements OnInit {
  description: string = "";
  startUpState: string = "";
  postCallState: string = "";
  wrapUpDuration: string = "";
  forceDisposition: string = "";
  for: string = "";
  seconds: string = "";
  preventChangingACDState: string = "";
  showcurrentACD: string = "";
  activateSignOut: string = "";
  whenDisplayingACD: string = "";

  isActive: boolean = false;
  acdStateList: any[] = [];
  duration: any[]=[];
  constructor(private contextService: ContextService, public settingsGeneral: SettingsGeneral) {
    super()
  }
  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.Description');
    this.startUpState = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.StartupAcdState');
    this.postCallState = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.PostCallAcdState');
    this.wrapUpDuration = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.WrapupDurationInSeconds');
    this.forceDisposition = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode');
    this.for = this.contextService.getLanguageTagByKey('Misc.For');
    this.seconds = this.contextService.getLanguageTagByKey('Misc.Seconds');
    this.preventChangingACDState = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.PreventMeFromChangingMyAcdState');
    this.showcurrentACD = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.OnlyShowCurrentAcdStateButton');
    this.activateSignOut = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.ActivateSignOutWhenClosing');
    this.whenDisplayingACD = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.WhenDisplayingAcdState');
    //push various acd states to the array binded with the dropdown for setting startup acd state and post call acd state
    this.acdStateList = [];
    this.acdStateList.push({ label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable'), value: "Available" });
    this.contextService.unAvailableCodesArray.forEach(element => {
      this.acdStateList.push({ label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable')+ " - " + element.Description, value: element.code });
    });
    this.acdStateList.push({ label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp'), value: "Wrap-Up" });
    this.acdStateList.push({ label:this.contextService.getLanguageTagByKey('Misc.NotSet'), value: "none" });

    this.duration=[];
    this.duration.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.DontShowAnything'), value: "none" });
    this.duration.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.ShowStartTime'), value: "startTime" });
    this.duration.push({ label: this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.ShowDuration'), value: "duration" });
    this.settingsGeneral.settingsChanged=true;
  }

}
