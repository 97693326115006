
import { Component, OnInit } from '@angular/core';
import { AppComponent } from "app/app.component";
import { ChatConversation } from 'app/model/chat-conversation.model';
import { BwDirectoryEntry } from 'app/model/directory/bw-directory-entry';
import { ChatService } from 'app/providers/chat.service';
import { MenuItem } from 'primeng/api/menuitem';
import { timer } from 'rxjs';
import { ContextService } from "../../../providers/context.service";
import { DataService } from "../../../providers/dataservice.service";
import { HelperService } from './../../../providers/helper.service';
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  fileMenu: MenuItem[] = [];
  messagingMenu: MenuItem[] = [];
  toolsMenu: MenuItem[] = [];
  helpMenu: MenuItem[] = [];
  hideIM: boolean = true;
  closeVis: boolean = false;
  sortable = [];
  dnisVis: boolean = false;
  dnisWaitVis: boolean = false;
  signOutClicked: boolean = false;
  clearAllConnection: boolean = false;

  fileMenuBar: string = "";
  messagingFileMenu: string = "";
  menuTools: string = "";
  menuHelp: string = "";
  restartPrompt: string = "";
  miscYes: string = "";
  miscNo: string = "";

  messageUserObject = [];

  tempId: string = "";
  id: string = "";
  constructor(private helper: HelperService, private chatService: ChatService, private mainApp: AppComponent, public contextService: ContextService, public dataService: DataService) { }

  ngOnInit() {
    this.contextService.mainMenuHeight = (document.getElementById('mainMenuID').offsetHeight);
    this.fileMenuBar = this.contextService.getLanguageTagByKey('Menu.File');
    this.messagingFileMenu = this.contextService.getLanguageTagByKey('Menu.Messaging');
    this.menuTools = this.contextService.getLanguageTagByKey('Menu.Tools');
    this.menuHelp = this.contextService.getLanguageTagByKey('Menu.Help');
    this.restartPrompt = this.contextService.getLanguageTagByKey('Prompt.Restart');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.contextService.updateToolsMenu.subscribe(() => {
      this.toolsClick();
    });
    this.contextService.imConnectionLostModalVis.subscribe(val => {

      if (val === "") {
        this.hideIM = true;
      }
      else if (val === "enableMessagingMenu") {
        this.hideIM = false;
      }

    });

    this.contextService.joinLeaveQueue.subscribe(val => {
      this.toolsClick();
    });

    if (this.contextService.isElectron === true) {
      this.fileMenu = [
        { label: this.contextService.getLanguageTagByKey('Menu.File.Restart'), command: (event) => { this.closeVis = true } },
        { separator: true },
        { label: this.contextService.getLanguageTagByKey('Menu.File.Close'), command: (event) => this.helper.closeModalVis.next(true) }
      ]
    } else if (this.contextService.isElectron === false) {
      this.fileMenu = [
        { label: this.contextService.getLanguageTagByKey('Menu.File.Restart'), command: (event) => { this.closeVis = true } },
        { separator: true },
        { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignOut'), command: (event) => {} }
      ]
    }

    if (((this.contextService.objSettings.STARTUP === 2 && (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium'))) || this.contextService.objSettings.STARTUP === 3)) {//check whether the product is agent web

      this.toolsMenu = [
        { label: this.contextService.getLanguageTagByKey('Misc.Queues') },
        { separator: true },
        { label: this.contextService.getLanguageTagByKey('TabShowing.Settings'), command: (event) => this.helper.setVisible.next(true), routerLink: ['/settings-service'] }
      ];

    } else {
      this.toolsMenu = [
        { label: this.contextService.getLanguageTagByKey('TabShowing.Settings'), command: (event) => this.helper.setVisible.next(true), routerLink: ['/settings-service'] }
      ]
    }
    this.helpMenu = [
      { label: this.contextService.getLanguageTagByKey('Menu.Help.AboutUnity'), command: (event) => this.helper.aboutModalVis.next(true) }
    ]
    this.messagingMenu = [
      { label: this.contextService.getLanguageTagByKey('Menu.Messaging.StartConversation'), command: (event) => this.helper.startConversationVisible.next(true) }

    ]



  }


  /**
   *
   *
   * @param {string} transferTo
   * @memberof MainMenuComponent
   */
  callActionsInQueue(action: string, transferTo?: string) {
  }

  /**
   * This method hides remote assistance from file menu
   * 
   * @memberof MainMenuComponent
   */
  doNotShowRemoteAssistance() {
    this.helper.hideAllCMenus();

    if (this.contextService.licenseType !== "None") {
      this.helpMenu = [
        { label: this.contextService.getLanguageTagByKey('Menu.Help.AboutUnity'), command: (event) => this.helper.aboutModalVis.next(true) },
        { separator: true },
        { label: this.contextService.getLanguageTagByKey('Menu.Help.GetRemoteAssistance'), command: (event) => this.loadURL() }
      ]
    }
  }

  /**
   * This method used for restart entire application
   * 
   * @memberof MainMenuComponent
   */
  restart() {
    if (this.contextService.isElectron === true) {
      this.contextService.electronService.ipcRenderer.send('restart');
    } else if (this.contextService.isElectron === false) {
      setTimeout(() => {
        location.reload();
      }, 50)
    }
  }
  /**
   * This method performed while click on remote assistance file menu
   * 
   * @memberof MainMenuComponent
   */
  loadURL() {
    let temp = this.contextService.objLoggedUserDetails.getlicenseSupportUrl();
    if (this.contextService.isElectron === true) {
      this.contextService.electronService.ipcRenderer.send('load-url', temp);
    } else {
      window.open(temp, "_blank");
    }
  }

  /**
   * This method manage visiblity of start conversation and out-of-office  assistance file menu options according to the licence type
   * 
   * @memberof MainMenuComponent
   */
  status() {
    this.helper.hideAllCMenus();

    if (this.contextService.objLoggedUserDetails.getlicenseType() !== "None") {

      this.messagingMenu = [
        { label: this.contextService.getLanguageTagByKey('Menu.Messaging.StartConversation'), command: (event) => this.helper.startConversationVisible.next(true) },
        { separator: true },
        {
          label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant'), items: [
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.ClearCurrentStatus'), command: (event) => this.dataService.clearCurrentStatus(), visible: false },
            { separator: false, visible: false },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AddScheduledStatus'), command: (event) => this.helper.outOfOfficeModalVis.next(true) },
            { separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.InAMeeting'), command: (event) => { this.dataService.setIMStatus('In a meeting') }, visible: true, icon: '' },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutToLunch'), command: (event) => { this.dataService.setIMStatus('Out to lunch') }, visible: true, icon: '' },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutOfTheOffice'), command: (event) => { this.dataService.setIMStatus('Out of the office') }, visible: true, icon: '' },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OnLeave'), command: (event) => { this.dataService.setIMStatus('On leave') }, visible: true, icon: '' },
            { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AwaySick'), command: (event) => { this.dataService.setIMStatus('Away sick') }, visible: true, icon: '' }

          ]
        }

      ];
      (<MenuItem>this.messagingMenu[2].items[4]).icon = '';
      (<MenuItem>this.messagingMenu[2].items[5]).icon = '';
      (<MenuItem>this.messagingMenu[2].items[6]).icon = '';
      (<MenuItem>this.messagingMenu[2].items[7]).icon = '';
      (<MenuItem>this.messagingMenu[2].items[8]).icon = '';


      if (!(this.dataService.imService.statusList == null)) {

        if (!(this.dataService.imService.statusList.imStatus === "")) {

          (<MenuItem>this.messagingMenu[2].items[0]).visible = true;
          (<MenuItem>this.messagingMenu[2].items[1]).visible = true;
          (<MenuItem>this.messagingMenu[2].items[1]).separator = true;

          if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "in a meeting") {

            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.messagingMenu[2].items[4]).icon = 'tick';
            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "out to lunch") {
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.messagingMenu[2].items[5]).icon = 'tick';

            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "out of the office") {
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.messagingMenu[2].items[6]).icon = 'tick';

            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "on leave") {
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.messagingMenu[2].items[7]).icon = 'tick';

            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "away sick") {
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.messagingMenu[2].items[8]).icon = 'tick';
            }
          }

        }
        else {
          (<MenuItem>this.messagingMenu[2].items[0]).visible = false;
          (<MenuItem>this.messagingMenu[2].items[1]).visible = false;
          (<MenuItem>this.messagingMenu[2].items[1]).separator = false;
        }
      }

    }
  }

  //agentstart

  sortProperties(obj) {
    // convert object into array
    this.sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        this.sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    this.sortable.sort(function (a, b) {
      var x = a[1].callCenterName.toLowerCase(),
        y = b[1].callCenterName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  sortSupervisor(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key) && this.contextService.objLoggedUserDetails.getUserId() !== key)
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }
  toolsClick() {
    this.helper.hideAllCMenus();

    if ((this.contextService.objSettings.STARTUP === 2 && (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium')) || this.contextService.objSettings.STARTUP === 3) && !this.contextService.isSignedOut && this.toolsMenu[0].label !== this.contextService.getLanguageTagByKey('Misc.Queues')) {//check whether the product is agent web
      this.toolsMenu = [
        { label: this.contextService.getLanguageTagByKey('Misc.Queues') },
        { separator: true },
        { label: this.contextService.getLanguageTagByKey('TabShowing.Settings'), command: (event) => this.helper.setVisible.next(true), routerLink: ['/settings-service'] }
      ];
    }

    if (((this.contextService.objSettings.STARTUP === 2 && (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium'))) || this.contextService.objSettings.STARTUP === 3) && !this.contextService.isSignedOut && this.toolsMenu[0].label === this.contextService.getLanguageTagByKey('Misc.Queues')) {//check whether the product is agent web
      if (Object.keys(this.contextService.agentDetails.callCenters).length > 0) {
        this.sortProperties(this.contextService.agentDetails.callCenters);
        this.toolsMenu[0].items = [];
        let isOnCall = false;
        let activeCallId = "";
        //check whether is there any active call
        let ringingCallNumber = 0;
        let holdCallCount = 0;
        let queuedCallExist = false;
        let activeCallExist = false;
        let ringingCallExist = false;
        let holdCallExist = false;
        for (let index = 0; index < this.contextService.callStatusList.length; index++) {
          if (this.contextService.callStatusList.length == 1 && !this.contextService.callStatusList[index].isQueuedCall) {
            isOnCall = true;
            if (this.contextService.callStatusList[index].callcenterId) {
              activeCallId = this.contextService.callStatusList[index].callcenterId;
            }
          } else {
            if ((this.contextService.callStatusList[index].displayState == 'Active') && (!activeCallExist && !holdCallExist)) {
              isOnCall = true;
              activeCallExist = true;
              if (this.contextService.callStatusList[index].callcenterId) {
                activeCallId = this.contextService.callStatusList[index].callcenterId;
              }
            } else if ((this.contextService.callStatusList[index].displayState == 'Active') && (activeCallExist || holdCallExist)) {
              isOnCall = false;
              activeCallId = "";
            }
            if ((this.contextService.callStatusList[index].displayState == 'Ringing' && !this.contextService.callStatusList[index].isQueuedCall) && (activeCallExist || holdCallExist)) {
              isOnCall = false;
              activeCallId = "";
            } else if (this.contextService.callStatusList[index].displayState == 'Ringing' && !this.contextService.callStatusList[index].isQueuedCall && !activeCallExist) {
              isOnCall = true;
              ringingCallNumber++;
              ringingCallExist = true;
              if (this.contextService.callStatusList[index].callcenterId) {
                activeCallId = this.contextService.callStatusList[index].callcenterId;
              }
            }

            if ((this.contextService.callStatusList[index].displayState == 'On hold' && !this.contextService.callStatusList[index].isQueuedCall) && (activeCallExist || ringingCallExist)) {
              isOnCall = false;
              activeCallId = "";
            } else if (this.contextService.callStatusList[index].displayState == 'On hold' && !this.contextService.callStatusList[index].isQueuedCall) {
              isOnCall = true;
              holdCallExist = true;
              holdCallCount++;
              if (this.contextService.callStatusList[index].callcenterId) {
                activeCallId = this.contextService.callStatusList[index].callcenterId;
              }
            }
          }
        }
        if (ringingCallNumber > 1 || holdCallCount > 1) {
          isOnCall = false;
          activeCallId = "";
        }
        if (this.contextService.selectedRows[0]) {
          if (this.contextService.selectedRows[0].isQueuedCall) {
            isOnCall = false;
            activeCallId = "";
          } else {
            isOnCall = true;
            activeCallId = this.contextService.selectedRows[0].callcenterId;
          }
        }
        let callCenterCall = false;
        for (let i = 0; i < this.sortable.length; i++) {

          let element = this.sortable[i];
          this.contextService.log(element[1].acdUserId);
          if (activeCallId === element[1].acdUserId) {
            callCenterCall = true;
          } else if ((this.contextService.selectedRows[0] && this.contextService.selectedRows[0].callcenterId === element[1].acdUserId) && !this.contextService.selectedRows[0].isQueuedCall) {
            this.contextService.log(this.contextService.selectedRows[0]);
            callCenterCall = true;
          } else {
            callCenterCall = false;
          }
          //add supervisor menu in tools 
          let supervisorMenu: MenuItem[];
          supervisorMenu = [
            {
              label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Supervisors')
            }
          ];

          // escalate or transfer the call to the supervisor who is monitoring the agent.
          let isSupervisorForMe: boolean = false;
          supervisorMenu[0].items = [];
          let supervisorList = this.sortSupervisor(element[1].supervisorList);//sort supervisor list based on name
          let k = 0;
          for (let j = 0; j < supervisorList.length; j++) {
            let supervisor = supervisorList[j];
            if (supervisor[1].isMonitoringMe === true) {
              if (callCenterCall === true) {
                /* make true only if the call is a call center call and for 
              the monitoring supervisor so that escalate/transfer could be performed only for that supervisor*/
                isSupervisorForMe = true;
              }
            } else {
              if (this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor === true) {
                continue; // don't add other supervisors if the flag is true; skipping loop to the next supervisor
              }
              isSupervisorForMe = false;
            }
            //adding supervisors to the supervisor menu
            (<MenuItem[]>supervisorMenu[0].items).push(
              {
                label: supervisor[1].firstName + " " + supervisor[1].lastName, items: []
              });
            if (isSupervisorForMe) {
              (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
                { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall'), command: (event) => { this.callActionsInQueue('escalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true },
                { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall'), command: (event) => { this.callActionsInQueue('emergencyEscalate', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
              );
            } else {
              (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
                { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => { this.contextService.websocketService.dialAction(this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
              );
            }
            if (isOnCall) {
              (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
                { separator: isOnCall, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => { this.callActionsInQueue('transfer', this.contextService.getExtensionFromUserID(supervisor[1].id)) }, visible: true }
              );
            }
            if (this.contextService.isImConnected) {
              (<MenuItem[]>(<MenuItem>supervisorMenu[0].items[k]).items).push(
                { separator: this.contextService.isImConnected, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => { this.contextService.instantMessage(supervisor[1].id) }, visible: true }
              );
            }
            k++;
          }
          let showJoinOption = false;
          let showLeaveOption = false;
          // if (this.contextService.objSettings.STARTUP === 3) {
          if (element[1].isAgentCallcenter) {
            if (this.contextService.agentDetails.callCenters[element[1].acdUserId].joined) {
              showLeaveOption = true;
              showJoinOption = false;
            } else {
              showLeaveOption = false;
              showJoinOption = true;
            }

          } else {
            showLeaveOption = false;
            showJoinOption = false;
          }

          // }
          let callcenterName = this.contextService.getLastNameFromUserID(element[1].acdUserId);
          if (this.contextService.objSettings.STARTUP === 3) {
            if (!element[1].isSupervisorCallcenter) {
              callcenterName = this.contextService.getLastNameFromUserID(element[1].acdUserId) + " (" + this.contextService.getLanguageTagByKey('Menu.Tools.CallCenters.AgentOnly') + ")"
            }
          }
          (<MenuItem[]>this.toolsMenu[0].items).push({
            label: callcenterName, target: element[1].acdUserId, items: [
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Call'), command: (event) => { this.contextService.websocketService.dialAction(this.contextService.agentDetails.callCenters[element[1].acdUserId].extension) } },
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Transfer'), command: (event) => { this.callActionsInQueue('transfer', this.contextService.agentDetails.callCenters[element[1].acdUserId].extension) }, visible: isOnCall },
              { separator: true, visible: element[1].isAgentCallcenter && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin()},
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => {  }, disabled: !this.contextService.agentDetails.callCenters[element[1].acdUserId].isLogOffAllowed, visible: showLeaveOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin() },
              { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => { }, visible: showJoinOption && !this.contextService.objLoggedUserDetails.getPreventAgentFromCallCenterLogin()},
              { separator: callCenterCall, visible: callCenterCall },
              { label: this.contextService.getLanguageTagByKey('Prompt.EscalateCall.First'), command: (event) => { this.callActionsInQueue('escalateToFirst') }, visible: callCenterCall },
              { label: this.contextService.getLanguageTagByKey('Prompt.EmergencyEscalateCall.First'), command: (event) => { this.callActionsInQueue('emergencyEscalateToFirst') }, visible: callCenterCall }
            ]
          });
          //insert supervisor menu only if supervisors are returned from the server
          if (supervisorMenu[0].items.length > 0) {
            (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push({ separator: true, visible: true },
              supervisorMenu[0])
          }

          //supervisor

          if (this.contextService.objSettings.STARTUP === 3) {
            //adding agents menu
            let agentMenu: MenuItem[];
            agentMenu = [
              {
                label: this.contextService.getLanguageTagByKey('Misc.Agents')
              }
            ];



            agentMenu[0].items = [];

            let agentsList = this.sortAgents(element[1].agentList);//sort agent list based on name
            let s = 0;
            let supervisedAgentExist = false;
            let supervisedAgentsCount = 0;
            for (let j = 0; j < agentsList.length; j++) {
              let agent = agentsList[j];

              if (agent[1].isSupervised) {
                supervisedAgentsCount++;
                supervisedAgentExist = true;
                if (supervisedAgentsCount === 1) {
                  if (agent[1].id === this.contextService.objLoggedUserDetails.getUserId()) {
                    supervisedAgentExist = false;
                  }
                }
                (<MenuItem[]>agentMenu[0].items).push(
                  {
                    label: agent[1].firstName + " " + agent[1].lastName, items: []
                  });
                let unavailableMenuTemp = [];
                let positionIndex: number = 2;
                let defaultValue = "";

                if ((this.contextService.unAvailableCodesArray.length > 0)) {

                  for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
                    defaultValue = "";
                    if (this.contextService.unAvailableCodesArray[i].isDefault) {
                      defaultValue = " (" + this.contextService.getLanguageTagByKey('Misc.Default') + ")";
                    }
                    let icon = "";
                    if (this.contextService.agentSubscriptionList[agent[1].id].unavailableCode === this.contextService.unAvailableCodesArray[i].code) {
                      icon = 'tick';
                    }
                    unavailableMenuTemp.push({ label: this.contextService.unAvailableCodesArray[i].Description + defaultValue, target: this.contextService.unAvailableCodesArray[i].code, title: this.contextService.unAvailableCodesArray[i].Description, badge: positionIndex, command: (event) => {}, icon: icon, styleClass: 'state' });
                    positionIndex = positionIndex + 1;
                  }
                }

                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => {  }, visible: !this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState }
                  , { separator: !this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState, visible: !this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState }
                );

                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => { }, visible: this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState, disabled: !this.contextService.agentDetails.callCenters[element[1].acdUserId].isLogOffAllowed }
                  , { separator: this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState, visible: this.contextService.agentDetails.callCenters[element[1].acdUserId].agentList[agent[1].id].joinedState }
                );
                //acd state
                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignIn'), command: (event) => { }, icon: '', styleClass: 'state' }

                );
                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable'), command: (event) => {  }, icon: '', styleClass: 'state' }

                );

            
                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable'), command: (event) => { }, items: unavailableMenuTemp }

                );
                // }

                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp'), command: (event) => {  }, icon: '', styleClass: 'state' }

                );
                (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(

                  { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignOut'), command: (event) => { }, icon: '', styleClass: 'state' }
                );
                if (agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
                  (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
                    { separator: true, visible: true }
                  );
                }
                if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Sign-In") {
                  (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[4]).icon = 'tick';
                } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Available") {
                  (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[5]).icon = 'tick';
                } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState.startsWith("Unavailable")) {
                  (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[6]).icon = 'tick';

                } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Wrap-Up") {
                  (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[7]).icon = 'tick';
                } else if (this.contextService.agentSubscriptionList[agent[1].id].ACDState === "Sign-Out") {
                  (<MenuItem>(<MenuItem>agentMenu[0].items[s]).items[8]).icon = 'tick';
                }
                //acd state

                if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in') && this.contextService.assignedServices.includes('CallCenterMonitoring') && this.contextService.agentDetails.callCenters[element[1].acdUserId].type === "Premium" && agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
                  let silentMonitorItems = [];
                  if (Object.keys(this.contextService.silentMonitorArray).length > 0) {
                    for (const key in this.contextService.silentMonitorArray) {
                      if (this.contextService.silentMonitorArray.hasOwnProperty(key)) {
                        if (key === agent[1].id) {
                          silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Misc.Stop'), command: (event) => { }, visible: true });
                        } else {
                          if (Object.keys(this.contextService.agentSubscriptionList[agent[1].id].callDetails).length > 0) {
                            silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => {  }, visible: true },
                              { separator: true, visible: true },
                              { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
                              { separator: true, visible: true },
                              { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });
                          } else {
                            silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => {  }, visible: true },
                              { separator: true, visible: true },
                              { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
                              { separator: true, visible: true },
                              { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });


                          }

                        }

                      }
                    }
                  } else {
                    if (Object.keys(this.contextService.agentSubscriptionList[agent[1].id].callDetails).length > 0) {
                      silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => { }, visible: true },
                        { separator: true, visible: true },
                        { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => { } },
                        { separator: true, visible: true },
                        { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });
                    } else {
                      silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => {  }, visible: true },
                        { separator: true, visible: true },
                        { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
                        { separator: true, visible: true },
                        { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {} });


                    }
                  }

                  (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
                    { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor'), items: silentMonitorItems, visible: true }
                    , { separator: true, visible: true }
                  );
                }
                if (agent[1].id !== this.contextService.objLoggedUserDetails.getUserId()) {
                  (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
                    { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => {  }, visible: true }
                  );
                  if (this.contextService.isImConnected) {
                    (<MenuItem[]>(<MenuItem>agentMenu[0].items[s]).items).push(
                      { separator: this.contextService.isImConnected, visible: true },
                      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => {  }, visible: true }
                    );
                  }
                }
                s++;
              }
            }

            //insert agents menu only if agents are returned from the server


            if (agentMenu[0].items.length > 0 && element[1].isSupervisorCallcenter) {
              (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push({ separator: true, visible: true },
                agentMenu[0]);
              if (this.contextService.isImConnected && supervisedAgentExist) {
                (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push(
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.InstantMessageSupervisedAgents'), command: (event) => {  }, visible: true }
                );
              }
            }
            if (!this.contextService.objSettings.supervior_addAllAgentsToAgentList && element[1].isSupervisorCallcenter && !this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
              (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push(
                { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ModifySupervisedAgentList'), command: (event) => {  }, visible: true }
              );
            }


            if (this.contextService.agentDetails.callCenters[element[1].acdUserId].type === "Premium" && element[1].isSupervisorCallcenter) {
              if (this.contextService.agentDetails.callCenters[element[1].acdUserId].activeForcedForwarding) {
                (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push({ separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.DeactivateForcedForwarding') + " (" + this.contextService.agentDetails.callCenters[element[1].acdUserId].forcedForwardingNumber + ")", command: (event) => {  }, visible: true }
                );
              } else {
                (<MenuItem[]>(<MenuItem>this.toolsMenu[0].items[i]).items).push({ separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.ActivateForcedForwarding'), command: (event) => { }, visible: true }
                );
              }
            }
            //supervisor
            // });
          }
        }
      } else {
        //the product will be desktop web
        this.toolsMenu = [
          { label: this.contextService.getLanguageTagByKey('TabShowing.Settings'), command: (event) => this.helper.setVisible.next(true), routerLink: ['/settings-service'] }
        ]
      }

    }
  }
 

  numValidate(event) {
    if ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 40 || event.charCode == 41 || event.charCode == 46) {
      return true;
    }
    return false;
  }

  sortAgents(obj): any[] {
    // convert object into array
    let sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function (a, b) {
      var x = a[1].firstName + " " + a[1].lastName.toLowerCase(),
        y = b[1].firstName + " " + b[1].lastName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return sortable;
  }


  openCm(event, cm) {

    event.preventDefault();
    event.stopPropagation();
    this.helper.saveAllCMenus(cm);
    this.helper.hideAllCMenus();
    cm.show(event);
    return false;
  }
}
