import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { ContextService } from '../../../../../providers/context.service';
import { SettingsGeneral } from "./../../../../../providers/settings/settings-general/settings-general.service";
@Component({
  selector: 'app-call-center',
  templateUrl: './call-center.component.html',
  styleUrls: ['./call-center.component.scss']
})
export class CallCenterComponent extends BaseComponent implements OnInit {
  description: string = "";
  joinCallCenters: string = "";
  unityStaring: string = "";
  leaveCallCenters: string = "";
  unityClosing: string = "";
  showItems: boolean = false;
  constructor(private contextService: ContextService, public settingsGeneral: SettingsGeneral) {
    super()
  }

  ngOnInit() {
    this.joinCallCenters = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.JoinAllCallCenters');
    this.unityStaring = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.WhenUnityStarting');
    this.leaveCallCenters = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.LeaveAllCallCenters');
    this.unityClosing = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.WhenUnityClosing');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Description');
    this.settingsGeneral.settingsChanged = true;
    if (this.contextService.assignedServices.includes('CallCenter-Standard') || this.contextService.assignedServices.includes('CallCenter-Premium')) {
      this.showItems = true;
    }
  }
}
