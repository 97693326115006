<div class="row">
  <p>
    {{description}}
  </p>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{playingWarningTone}} [(ngModel)]="callCenterMonitoringService.isActive"
    binary="true"></p-checkbox>
</div>