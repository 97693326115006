import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessagesService } from '../../providers/messages.service';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadProgressComponent implements OnInit {

  constructor(public messagesService: MessagesService) {
  }

  ngOnInit() {

  }

}
