<div class="row">
  <p>
    {{description}}
  </p>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)="focus()" label={{enabled}} [(ngModel)]="callForwardingBusyService.isActive"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{forwardNumber}}</label>
  <input type="text" id="textarea" name="input-text" [(ngModel)]="callForwardingBusyService.phNumber" [disabled]="!callForwardingBusyService.isActive"
    autocomplete=off>
</div>