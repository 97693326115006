<div class="drop-call-area" (contextmenu)="showCallWindowMenu(cm)" #callMenu>
  <div class="ui-datatable ui-widget">
    <!-- <div [ngClass]="contextService.callStatusList.length > 0 ? 'ui-datatable-tablewrapper drop-call-wrapper' : 'ui-datatable-tablewrapper drop-call-wrapper empty'"
      id="drop-call-wrapper" [primeDragula]="bagCallDropWrapper" [dragulaOptions]="{ initAfterView: false, copy: false }"
      [ngClass]="{'empty': !dropMsgState}"> -->
    <!-- dropMsgState remove, we think its not used -->
    <div
      [ngClass]="
        contextService.callStatusList.length > 0
          ? 'ui-datatable-tablewrapper drop-call-wrapper'
          : 'ui-datatable-tablewrapper drop-call-wrapper empty'
      "
      id="drop-call-wrapper"
      [primeDragula]="bagCallDropWrapper"
      [dragulaOptions]="{ initAfterView: false, copy: false }"
    >
      <p-table
        [value]="contextService.callStatusList"
        [(selection)]="selectedCall"
        [(contextMenuSelection)]="selectedCall"
        [resizableColumns]="false"
        columnResizeMode="fit"
        [responsive]="true"
        [scrollable]="true"
        scrollHeight="140px"
        contextMenuSelectionMode="joint"
        #activeTable
        dataKey="name"
        id="drop-call-table"
        *ngIf="contextService.callStatusList.length > 0"
        [primeDragula]="bagCallDrop"
        [dragulaModel]="contextService.callStatusList"
        [dragulaOptions]="{
          childContainerSelector:
            '#drop-call-table .ui-table-scrollable-body-table .ui-table-tbody',
          childContainerSelectorID: 'active-calls',
          initAfterView: true,
          copy: false
        }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th class="name-cell" [pSortableColumn]="'from_display'">
              {{ miscForm }}
              <p-sortIcon [field]="'from_display'"></p-sortIcon>
            </th>
            <th class="to-cell" [pSortableColumn]="'to_display'">
              {{ miscTo }}
              <p-sortIcon [field]="'to_display'"></p-sortIcon>
            </th>
            <th class="duration-cell" [pSortableColumn]="'duration'">
              {{ duration }}
              <p-sortIcon [field]="'duration'"></p-sortIcon>
            </th>
            <th class="status-cell" [pSortableColumn]="'miscStatus'">
              {{ miscStatus }}
              <p-sortIcon [field]="'miscStatus'"></p-sortIcon>
            </th>
            <!-- <th></th> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
          <tr
            (dblclick)="onRowDblclick(rowData)"
            (click)="onRowClick($event, rowData)"
            [pSelectableRow]="rowData"
          >
            <td class="name-cell">
              <span class="ui-column-title">{{ miscForm }}</span>
              <span
                ngClass="{{ rowData.getIcon() }}"
                [attr.data-id]="rowData.id"
                >{{ rowData.from_display }}</span
              >
            </td>
            <td class="to-cell">
              <span class="ui-column-title">{{ miscTo }}</span>
              {{ rowData.to_display }}
            </td>

            <td class="duration-cell">
              <span class="ui-column-title">{{ duration }}</span>
              {{ rowData.duration }}
            </td>

            <td class="status-cell">
              <span class="ui-column-title">{{ miscStatus }}</span>
              <span
                >{{ rowData.displayState
                }}{{ rowData.recordDisplayState }}</span
              >
            </td>
            <!-- <td></td> -->
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-contextMenu
  [model]="activeCallWindowMenu"
  appendTo="body"
  [target]="callMenu"
  #cm
></p-contextMenu>
