import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Subject} from 'rxjs';

@Injectable()
export class ChatService {
    droppedToConversation = new Subject<any>();
    droppedActiveCallToConversation = new Subject<any>();
    droppedToChat = new Subject<any>();
    scrollConversationsToBottom = new Subject<any>(); 
    toggleChat = new Subject<any>(); 
    conversationHeaderChange = new Subject<any>(); 
    scrollToBottom = new Subject<any>(); 
    unFocus = new Subject<any>(); 
    srcoll = new Subject<any>();
    constructor(private http: HttpClient) {}  
}