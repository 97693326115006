import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'headline',
  pure: true
})
@Injectable()
export class HeadlinePipe implements PipeTransform {
    private namesCount: number = 2;
    constructor(private sanitizer: DomSanitizer) {
    }
 
  transform(value: string, args?: any): any {
      let headline = '';
      let names = value.split(',');
      if(names.length > this.namesCount){
        var firstNames = [], more = [];
        var count = names.length;
        for (var index = 0; index < names.length; index++) {
            var name = names[index].trim();
            var firstName = name; // use full names now // name.split(' ')[0];
            if(index < this.namesCount){
                firstNames.push(firstName);
            } else {
                more.push(name); 
                //break;
            }
        }
        headline = firstNames.join(', ');
        if(count > this.namesCount){ 
            let tooltip = more.join('<br>'); 
            headline += ' and <span class="more">' + (count - this.namesCount) + ' more<span class="chat-tooltip"><span>' + tooltip + '</span></span></span>';
        }
        
      } else {
          headline = value;
      }
      return this.sanitizer.bypassSecurityTrustHtml(headline);
  } 

}
