<div class="row">
  <p>
    {{description}}
  </p>
</div>

<p-tabView [activeIndex]="0">
  <p-tabPanel header={{this.general}} *ngIf="this.contextService.objLoggedUserDetails.getWebclientUseXSI()=== false">
    <div class="row">
      <label for="" class="label-block"></label>
      <p-checkbox name="checkboxGroup1" value="checkbox" label={{onlyIncludeJoinedAgentsLabel}}
        [(ngModel)]="this.settingsGeneral.supervisor_showOnlyJoinedAgents" binary="true"></p-checkbox>
    </div>

    <div class="row">
      <label for="" class="label">{{agentActivityImageBasedLabel}}</label>
      <p-dropdown name="dropdown" [options]="activityIcon" [(ngModel)]="this.settingsGeneral.supervisor_activityImage"
        [style]="{'width':'100%'}" [required]=true></p-dropdown>
    </div>

  </p-tabPanel>
  <p-tabPanel header={{this.calls}}>

    <div class="row" *ngIf="this.contextService.objLoggedUserDetails.getWebclientUseXSI()=== false">
      <p-checkbox name="checkboxGroup1" value="checkbox" label={{addAllAgentsLabel}}
        [(ngModel)]="this.settingsGeneral.supervior_addAllAgentsToAgentList" binary="true"></p-checkbox>
    </div>
    <div class="row">
      <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{queuedCallsInActivityList}} (click)="this.queuedCallClick()"
        [(ngModel)]="this.settingsGeneral.showQueuedCalls" binary="true"></p-checkbox>
    </div>
    <div class="row" style="padding-left: 10px;">
      <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{callPriority}} [disabled]="!this.settingsGeneral.showQueuedCalls"
        [(ngModel)]="this.settingsGeneral.callPriority" binary="true"></p-checkbox>
    </div>
    <div class="row" *ngIf="this.contextService.objLoggedUserDetails.getWebclientUseXSI()=== false">
      <p-checkbox name="checkboxGroup1" value="checkbox"
        [disabled]="this.contextService.objSettings.supervior_addAllAgentsToAgentList===true"
        label={{staffedRatioLabel}} [(ngModel)]="this.settingsGeneral.supervisor_supervisedAgentsStaffedRatio"
        binary="true"></p-checkbox>
    </div>
  </p-tabPanel>


</p-tabView>