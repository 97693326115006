import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AgentactivityService } from 'app/providers/agentactivity.service';
import { ContextService } from 'app/providers/context.service';
import { HelperService } from 'app/providers/helper.service';
import { MenuItem } from 'primeng/api/menuitem';
import { SortEvent } from 'primeng/primeng';

@Component({
  selector: 'app-agent-activity-table',
  templateUrl: './agent-activity-table.component.html',
  styleUrls: ['./agent-activity-table.component.scss']
})
export class AgentActivityTableComponent implements OnInit {
  langTag_select: string = "";
  langTag_queues: string = "";
  langTag_Agents: string = "";
  langTag_join: string = "";
  langTag_leave: string = "";
  langTag_summary: string = "";
  //dragula bags
  bag;
  statsCellDimension: Array<any> = [];
  agentActivityOptions = [];
  selectedAgent;

  scrollHeight: any;
  blankSpaceHeight: any;
  scrolling: any;
  // agentActivityTabRadioButonValue: any = 'Queues';
  // agentActivityTabDropDownValue: string = "";
  contextMenuItems: MenuItem[];
  @ViewChild('agentActivitytable') agentActivitytable;
  @ViewChild('afterdropmenuAgentActivity') afterdropmenuAgentActivity;
  afterDropCM: MenuItem[];
  afterdropmenuAgentActivityLeft: string;
  afterdropmenuAgentActivityTop: string;
  joinLeaveVis: boolean;
  alertContent: string;
  clickedButton: string;
  miscYes: string;
  miscNo: string;
  sortF: string = '';
  sortO: string = '';
  droppedToExtn: string = "";
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // setTimeout(() => { this.helper.calcCellsWidth(this.agentActivitytable) });
    setTimeout(() => {
      this.calcTableHeight();
      // this.calcCellWidths();
    }, 100);
    setTimeout(() => {
      this.calcTableHeight();
      this.calcCellWidths();
    }, 700);
  }
  constructor(private helper: HelperService, private el: ElementRef, private cd: ChangeDetectorRef,
    public contextService: ContextService, public agentactivityService: AgentactivityService) { }

  ngOnInit() {
    this.langTag_select = this.contextService.getLanguageTagByKey('Misc.Select');
    this.langTag_queues = this.contextService.getLanguageTagByKey('Misc.Queues');
    this.langTag_Agents = this.contextService.getLanguageTagByKey('Misc.Agents');
    this.langTag_join = this.contextService.getLanguageTagByKey('AgentActivity.Queues.JoinAll');
    this.langTag_leave = this.contextService.getLanguageTagByKey('AgentActivity.Queues.LeaveAll');
    this.langTag_summary = this.contextService.getLanguageTagByKey('List.Statistics.Summary');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');

    this.helper.tableTabChanged.subscribe((val) => {
      // this.cd.reattach();
      this.setSort();
      setTimeout(() => {
        if (this.agentactivityService.statToDisplay != null) {
          this.calcCellWidths();
        }
      });
    });
    this.helper.recalcagentactivityHeight.debounceTime(250).subscribe((val) => {
      if (this.agentactivityService.statToDisplay) {
        this.calcTableHeight();
        setTimeout(() => {
          this.calcTableHeight();
          this.calcCellWidths();
        }, 500);
      }
    });
    this.helper.resized.debounceTime(600).subscribe((val) => {
      this.calcCellWidths();
    });
    this.contextService.showAgentActivity.subscribe((val) => {
      if (val.command === 'ShowAgentActivity') {
        this.agentactivityService.agentActivityTabRadioButonValue = 'Queues';
        this.agentactivityService.agentActivityTabDropDownValue = val.queueID;
        this.loadDropdownOptions();
        this.dropdownValueChanged();
      } else if (val.command === 'RemoveFromPW') {
        if (val.queueID == this.agentactivityService.agentActivityTabDropDownValue) {
          this.agentactivityService.agentActivityTabDropDownValue = "";
          this.agentactivityService.statToDisplay = [];
        }
        this.helper.recalcagentactivityHeight.next();
      }

    });
    this.helper.openedMenuOnDropAgentActivity.subscribe((value) => {
      this.droppedToExtn = "";
      this.afterdropmenuAgentActivityLeft = (value.x).toString() + 'px';
      this.afterdropmenuAgentActivityTop = (value.y).toString() + 'px';

      this.afterDropCM = [
        { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.transeferOnDrop("TransferToExtension"), visible: true },
        { label: this.contextService.getLanguageTagByKey('Menu.Context.WarmTransferToExtension'), command: (event) => this.transeferOnDrop("WarmTransferToExtension"), visible: true },

      ];
      this.contextService.log("dropped to::" + this.contextService.dropedToname);
      setTimeout(() => {
        if ((this.contextService.droppedcall !== undefined) && (this.contextService.droppedcall !== null)) {
          this.contextService.log(this.contextService.droppedcall.callId)
          if (this.contextService.dropedToname !== "") {
            let droppedToUserID = "", droppedToName = "";
            if (this.agentactivityService.agentActivityTabRadioButonValue == 'Queues') {
              for (let index = 0; index < this.agentactivityService.statToDisplay.length; index++) {
                if (this.agentactivityService.statToDisplay[index].name == this.contextService.dropedToname) {
                  droppedToUserID = this.agentactivityService.statToDisplay[index].userID;
                  break;
                }
              }
            } else {
              droppedToUserID = this.agentactivityService.agentActivityTabDropDownValue;
            }
            if (droppedToUserID !== "") {
              droppedToName = this.contextService.getNameFromUserID(droppedToUserID);
              if ((droppedToName !== this.contextService.getNameFromUserID(this.contextService.droppedcall.userId)) && (droppedToName !== this.contextService.getNameFromUserID(this.contextService.droppedcall.remoteUserId))) {
                this.droppedToExtn = this.contextService.getExtensionFromUserID(droppedToUserID);
                this.afterdropmenuAgentActivity.show();
              }
            } else {
              this.contextService.log("--Active Call dropped but droppedToUserID is undefined.");
            }
          } else {
            this.contextService.log("--Active Call dropped but this.contextService.dropedToname is undefined.");
          }
        }
      }, 250);
    });
    this.agentactivityService.agentActivityTabRadioButonValue = 'Queues';
    this.agentActivityRadioButtoChanged("Queues");
    //todo
    // this.agentActivityOptions = [{ label: 'Development Queue', value: 'DRDIndiaTest@kakaposystems.com' },
    // { label: 'Holiday cruises', value: 'Holiday cruises' },
    // { label: 'Kakapo support', value: 'support@kakaposystems.com' }];
    //
    // this.setSort();
  }

  transeferOnDrop(type: string) {
    if (type == "TransferToExtension") {
      this.contextService.websocketService.blindTransferAction(this.contextService.droppedcall.callId, this.droppedToExtn);

    } else if (type == "WarmTransferToExtension") {
      this.contextService.activeCallIdWhenWarmTransfer = this.contextService.droppedcall.callId;
      this.contextService.warmTransferNumber = this.droppedToExtn;
      this.contextService.websocketService.dialAction(this.droppedToExtn);
    }

  }

  changeSort(event) {
    this.contextService.objSettings.agentTable_sortOrder = event.order;
    this.contextService.objSettings.agentTable_sortField = event.field;
    if (this.contextService.objLoggedUserDetails) {
      this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
    }
  }

  setSort() {
    if (this.contextService.objSettings.agentTable_sortField && this.contextService.objSettings.agentTable_sortOrder) {

      if (this.contextService.objSettings.agentTable_sortField == 'name') {
        this.sortF = this.contextService.objSettings.agentTable_sortField;
        this.sortO = this.contextService.objSettings.agentTable_sortOrder;

      }
      else {
        let temp = false;
        for (let index = 0; index < this.contextService.agentActivityCols.length; index++) {
          const element = this.contextService.agentActivityCols[index];
          if (element.field === this.contextService.objSettings.agentTable_sortField) {
            this.sortF = this.contextService.objSettings.agentTable_sortField;
            this.sortO = this.contextService.objSettings.agentTable_sortOrder;
            temp = true;
          }
        }
        if (!temp) {
          this.setDefaultSort();
        }
      }
      //athul

    }
    else {
      this.setDefaultSort();
    }
    setTimeout(() => {
      this.agentActivitytable.sortField = this.sortF;
      this.agentActivitytable.sortOrder = this.sortO == "1" ? 1 : -1;
      //this.datatable.sortOrder = 1;
      this.agentActivitytable.sortSingle();

    }, 50);

  }
  setDefaultSort() {
    this.sortF = 'name';
    this.sortO = '1';
  }
  customSort(event: SortEvent) {
    //event.data = Data to sort
    //event.mode = 'single' or 'multiple' sort mode
    //event.field = Sort field in single sort
    //event.order = Sort order in single sort
    //event.multiSortMeta = SortMeta array in multiple sort

    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        if (value1.includes(":") && value2.includes(":")) {
          let value1InSeconds = this.processTimeToSeconds(value1);
          let value2InSeconds = this.processTimeToSeconds(value2);
          result = (value1InSeconds < value2InSeconds) ? -1 : (value1InSeconds > value2InSeconds) ? 1 : 0;
        } else {
          result = value1.localeCompare(value2);
        }
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  agentActivityRadioButtoChanged(type: string) {
    this.selectedAgent = "";
    this.agentactivityService.agentActivityTabDropDownValue = "";
    this.agentactivityService.statToDisplay = [];
    this.agentactivityService.summaryStat[0].clearAllValues();
    if (type == "Queues") {
      this.langTag_join = this.contextService.getLanguageTagByKey('AgentActivity.Queues.JoinAll');
      this.langTag_leave = this.contextService.getLanguageTagByKey('AgentActivity.Queues.LeaveAll');

      // this.agentActivityOptions = [{ label: 'Development Queue', value: 'DRDIndiaTest@kakaposystems.com' },
      // { label: 'Holiday cruises', value: 'Holiday cruises' },
      // { label: 'Kakapo support', value: 'support@kakaposystems.com' }];
    } else {
      this.langTag_join = this.contextService.getLanguageTagByKey('AgentActivity.Agents.JoinAll');
      this.langTag_leave = this.contextService.getLanguageTagByKey('AgentActivity.Agents.LeaveAll');

      // this.agentActivityOptions = [{ label: 'Athul PS', value: 'extn8005@kakaposystems.com' },
      // { label: 'Bifin Jose', value: 'extn8006@kakaposystems.com' }];
    }


    setTimeout(() => {
      this.calcTableHeight();
      this.calcCellWidths();
    }, 50);
  }
  dropdownValueChanged() {

    // console.log(this.agentactivityService.agentActivityTabDropDownValue);
    //todo remove unused let
    if (this.agentactivityService.agentActivityTabRadioButonValue == 'Queues') {
      this.agentactivityService.filterAccordingToCC(this.agentactivityService.agentActivityTabDropDownValue);
    } else {
      this.agentactivityService.filterAccordingToAgent(this.agentactivityService.agentActivityTabDropDownValue)
    }

    setTimeout(() => {
      this.calcTableHeight();
      this.calcCellWidths();
    }, 100);
  }

  loadDropdownOptions() {
    this.selectedAgent = "";
    this.agentActivityOptions = [];
    if (this.agentactivityService.agentActivityTabRadioButonValue == 'Queues') {
      this.contextService.statistics.forEach(element => {
        if (element.isDisplayInPersonalWallboard && this.contextService.agentDetails.callCenters[element.queueID].isSupervisorCallcenter) {
          this.agentActivityOptions.push({ label: element.queueName, value: element.queueID });
        }
      });
    } else {
      let tempArray = []
      for (const key in this.contextService.agentDetails.callCenters) {
        if (Object.prototype.hasOwnProperty.call(this.contextService.agentDetails.callCenters, key)) {
          const element = this.contextService.agentDetails.callCenters[key];
          for (const key1 in element.agentList) {
            if (Object.prototype.hasOwnProperty.call(element.agentList, key1)) {
              if (element.agentList[key1].isSupervised) {
                tempArray[key1] = this.contextService.getNameFromUserID(key1);
              }
            }
          }
        }
      }
      for (const key in tempArray) {
        if (Object.prototype.hasOwnProperty.call(tempArray, key)) {
          this.agentActivityOptions.push({ label: tempArray[key], value: key });
        }
      }
    }
    this.agentActivityOptions.sort(function compare(a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    })
  }
  join() {
    if (this.agentactivityService.agentActivityTabDropDownValue) {
      this.clickedButton = "join";
      if (this.agentactivityService.agentActivityTabRadioButonValue === "Queues") {
        this.alertContent = this.contextService.getLanguageTagByKey('Prompt.JoinAllAgents.CallCenter');

      } else {
        this.alertContent = "Are you sure you want to join this agent to all call centers?";
      }
      this.joinLeaveVis = true;
    }

  }
  leave() {
    if (this.agentactivityService.agentActivityTabDropDownValue) {
      this.clickedButton = "leave";
      if (this.agentactivityService.agentActivityTabRadioButonValue === "Queues") {
        this.alertContent = this.contextService.getLanguageTagByKey('Prompt.UnjoinAllAgents.CallCenter');

      } else {
        this.alertContent = "Are you sure you want to unjoin this agent from all queues? This may result in calls being missed";
      }
      this.joinLeaveVis = true;
    }

  }
  submit() {
  }

  close() {
    this.joinLeaveVis = false;
  }
  rowClick(e) {
    this.helper.disableRows(this.agentActivitytable);
  }
  onRowDblclick(event) {
    this.contextService.log("agentactivity onRowDblclick() entered");
    this.contextService.log(event);
    // this.contextService.websocketService.dialAction(event.phone);

  }
  calcTableHeight() {
    if (this.helper.selectedTableTab === 'agentactivity' && this.agentActivitytable) {
      const heights = this.helper.calcTableHeight(this.el, this.agentactivityService.statToDisplay, 100);
      // console.log(this.el.nativeElement.querySelector('p-table').children[0].children[0].children[0].children[0].clientHeight);
      let excessHeaderHeight = 0;
      //finding the differece in height of the heaader when the string is long
      if (this.el.nativeElement.querySelector('p-table').children[0].children[0].children[0].children[0]) {
        //default header height seems to be 32
        excessHeaderHeight = this.el.nativeElement.querySelector('p-table').children[0].children[0].children[0].children[0].clientHeight - 32;
      }
      this.scrollHeight = heights.th;
      if (excessHeaderHeight > 0) {
        this.scrollHeight = (parseInt(this.scrollHeight, 10) - excessHeaderHeight) + 'px';

      }
      //substracting the height of summary table
      // this.blankSpaceHeight = (parseInt(heights.mh, 10) - 22).toString();
      this.blankSpaceHeight = (parseInt(heights.mh, 10) - 22);
      // console.log("blankSpaceHeight:::" + this.blankSpaceHeight);

      this.blankSpaceHeight = this.blankSpaceHeight >= 0 ? this.blankSpaceHeight.toString() + 'px' : '0px';
      this.scrolling = heights.scrolling;
      // console.log("blankSpaceHeight:::" + this.blankSpaceHeight);

    }
  }
  calcCellWidths() {
    if (this.helper.selectedTableTab === 'agentactivity' && this.agentActivitytable && this.agentactivityService.statToDisplay.length > 0) {
      // calc width of tbody cells
      this.helper.calcCellsWidth(this.agentActivitytable, false).then(data => { this.statsCellDimension = data });
      // if (this.blankSpaceHeight == '0px') {
      //   let ScrollBarWidth = this.helper.getScrollBarWidth();
      // }
    }
  }
  loadContextMenu(event) {

    this.contextMenuItems = [];
    let userId = event.data.userID;
    let callcenterId = "";
    if (this.agentactivityService.agentActivityTabRadioButonValue === "Queues") {

      callcenterId = this.agentactivityService.agentActivityTabDropDownValue;
    }
    if (this.agentactivityService.agentActivityTabRadioButonValue === "Agents") {
      callcenterId = event.data.callcenterId;
      // userId = this.agentactivityService.agentActivityTabDropDownValue;
    }


    const agent = this.contextService.agentDetails.callCenters[callcenterId].agentList[userId];


    let unavailableMenuTemp = [];
    let positionIndex: number = 2;
    let defaultValue = "";

    for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
      defaultValue = "";
      if (this.contextService.unAvailableCodesArray[i].isDefault) {
        defaultValue = " (" + this.contextService.getLanguageTagByKey('Misc.Default') + ")";
      }
      let icon = "";
      if (this.contextService.agentSubscriptionList[agent.id].unavailableCode === this.contextService.unAvailableCodesArray[i].code) {
        icon = 'tick';
      }
      unavailableMenuTemp.push({ label: this.contextService.unAvailableCodesArray[i].Description + defaultValue, target: this.contextService.unAvailableCodesArray[i].code, title: this.contextService.unAvailableCodesArray[i].Description, command: (event) => this.unAvailableMenuClick(event, agent.id), icon: icon, styleClass: 'state' });
      positionIndex = positionIndex + 1;
    }


    this.contextMenuItems.push(

      { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Join'), command: (event) => {  }, visible: !agent.joinedState }
      , { separator: !agent.joinedState, visible: !agent.joinedState },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.Queue.Leave'), command: (event) => { }, visible: agent.joinedState, disabled: !this.contextService.agentDetails.callCenters[callcenterId].isLogOffAllowed }
      , { separator: agent.joinedState, visible: agent.joinedState }
      , { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignIn'), command: (event) => { } }
      , { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable'), command: (event) => {  } }
    );


    this.contextMenuItems.push(
      { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable'), command: (event) => {  }, items: unavailableMenuTemp }
    );
    // }

    this.contextMenuItems.push(
      { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp'), command: (event) => {  } }
    );
    this.contextMenuItems.push(

      { label: this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignOut'), command: (event) => {} }
      , { separator: true, visible: true }
    );
    if (this.contextService.agentSubscriptionList[agent.id].ACDState === "Sign-In") {
      (this.contextMenuItems[4]).icon = 'tick';
    } else if (this.contextService.agentSubscriptionList[agent.id].ACDState === "Available") {
      (<MenuItem>this.contextMenuItems[5]).icon = 'tick';
    } else if (this.contextService.agentSubscriptionList[agent.id].ACDState.startsWith("Unavailable")) {
      (<MenuItem>this.contextMenuItems[6]).icon = 'tick';
    } else if (this.contextService.agentSubscriptionList[agent.id].ACDState === "Wrap-Up") {
      (<MenuItem>this.contextMenuItems[7]).icon = 'tick';
    } else if (this.contextService.agentSubscriptionList[agent.id].ACDState === "Sign-Out") {
      (<MenuItem>this.contextMenuItems[8]).icon = 'tick';
    }

    if (this.contextService.assignedServices.includes('DirectedCallPickupwithBarge-in') && this.contextService.assignedServices.includes('CallCenterMonitoring') && this.contextService.agentDetails.callCenters[callcenterId].type === "Premium" && agent.id !== this.contextService.objLoggedUserDetails.getUserId()) {
      let silentMonitorItems = [];
      if (Object.keys(this.contextService.silentMonitorArray).length > 0) {
        for (const key in this.contextService.silentMonitorArray) {
          if (this.contextService.silentMonitorArray.hasOwnProperty(key)) {
            if (key === agent.id) {
              silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Misc.Stop'), command: (event) => {  }, visible: true });
            } else {
              if (Object.keys(this.contextService.agentSubscriptionList[agent[1].id].callDetails).length > 0) {
                silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => { }, visible: true },
                  { separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
                  { separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => { } });
              } else {
                silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => {  }, visible: true },
                  { separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
                  { separator: true, visible: true },
                  { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });


              }

            }

          }
        }
      } else {
        if (Object.keys(this.contextService.agentSubscriptionList[agent.id].callDetails).length > 0) {
          silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current'), command: (event) => {  }, visible: true },
            { separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
            { separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });
        } else {
          silentMonitorItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Current') + " (No active call)", disabled: true, command: (event) => { }, visible: true },
            { separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.Next'), visible: true, command: (event) => {  } },
            { separator: true, visible: true },
            { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor.All'), visible: true, command: (event) => {  } });


        }
      }

      this.contextMenuItems.push(
        { label: this.contextService.getLanguageTagByKey('Menu.Context.SilentMonitor'), items: silentMonitorItems, visible: true }
        , { separator: true, visible: true }
      );
    }

    this.contextMenuItems.push(
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => this.contextService.websocketService.dialAction(this.contextService.getExtensionFromUserID(userId)), visible: true },
      { separator: this.contextService.isImConnected, visible: this.contextService.isImConnected },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.contextService.instantMessage(userId), visible: this.contextService.isImConnected }
    );
  }


  
  changeACDState(event, acdState: string, code: string, targetId: string) {

  }
  unAvailableMenuClick(event, targetId) {

  }
  contextMenuSelected(event, cm) {
    this.loadContextMenu(event);

  }
  setCellColor(val, bgcolor, col, rowData) {
    if (val != undefined || val != null) {
      if (val.toString().length >= 0 && bgcolor != "") {
        return '#ffffff';
      } else {
        let value;
        if (val.toString().search(':') != -1) {
          value = this.processTimeToSeconds(val);
        } else {
          value = parseFloat(val);
        }
        if (value > 0) {
          for (let index = 0; index < this.agentactivityService.statToDisplay.length; index++) {
            if (this.agentactivityService.statToDisplay[index].userID == rowData.userID) {
              if (this.agentactivityService.statToDisplay[index].isHighlightCollection[col.field]) {
                return '#ff0000';
              }
            }
          }
        }
      }
    }
  }

  processTimeToSeconds(time) {
    let a = time.toString().split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
  }
}
