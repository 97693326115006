<div class="row">
  <p>
    {{description}}
    <p>
</div>

<div class="row">
  <label for="" class="label">{{recordingOptions}}</label>
  <p-dropdown [options]="callRecordingService.recordMode" [(ngModel)]="callRecordingService.recordingCalls" name="dropdown"
    [style]="{'width':'100%'}"></p-dropdown>
</div>
<div class="row">
  <label for="" class="label">{{pauseResumeNotification}}</label>
  <p-dropdown [options]="callRecordingService.pauseResume" [(ngModel)]="callRecordingService.pauseResumeNotification" name="dropdown"
    [style]="{'width':'100%'}"></p-dropdown>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="callRecordingService.startStopAnnouncement" label={{callRecordingAnnouncement}}
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup2" value="checkbox" [(ngModel)]="callRecordingService.voiceMessaging" label={{voiceMailRecording}}
    binary="true"  [disabled]="!this.showRecordVoiceMessaging"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup3" value="checkbox" [(ngModel)]="callRecordingService.recordingTone" label={{repeatCallRecordingTone}}
    binary="true"></p-checkbox>
</div>
<div class="row">
  <input type="number" (keypress)="onlyNumberKey($event)" name="mini-input" class="input-mini" [min]="10" [max]="1800" [(ngModel)]="callRecordingService.seconds"
    [disabled]="!callRecordingService.recordingTone" onPaste="return false">
  <label for="" class="label">{{seconds}}</label>
</div>