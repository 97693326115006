<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" (click)='focus()' label={{enabled}} [(ngModel)]="callForwardNoAnswerService.isActive"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{forwardNumber}}</label>
  <input type="text" id="textarea" name="input-text" [(ngModel)]="callForwardNoAnswerService.phoneNumber" [disabled]="!callForwardNoAnswerService.isActive"
    autocomplete=off>
</div>
<div class="row">
  <label for="" class="label">{{numberOfRings}}</label>
  <p-dropdown [options]="callForwardNoAnswerService.numberOfRingArray" name="dropdown" [(ngModel)]="callForwardNoAnswerService.numOfRings"
    [style]="{'width':'100%'}" [disabled]="!callForwardNoAnswerService.isActive" [required]=true></p-dropdown>
</div>