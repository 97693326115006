<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <div class="cols-2">
    <div>
      <p-checkbox name="UnityOnTop" label={{includeAllContact}}
       [(ngModel)]="settingsGeneral.contact_includeAllContact"
        class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{showMonitoredUsersFirst}} (click)="disableAlwaysSort()"
        [(ngModel)]="settingsGeneral.contact_showMonitoredUsersFirst" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{alwaysSortSearchByName}} [disabled]="settingsGeneral.contact_showMonitoredUsersFirst"
        [(ngModel)]="settingsGeneral.contact_alwaysSortSearchByName" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{includeDepartmentInSearch}}
        [(ngModel)]="settingsGeneral.contact_includeDepartmentInSearch" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{clearSearchBox}}
        [(ngModel)]="settingsGeneral.contact_clearSearchBox" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{privacyEnabled}}
        [(ngModel)]="settingsGeneral.contact_privacyEnabled" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{activateContactSearch}}
        [(ngModel)]="settingsGeneral.contact_activateContactSearch" class="full-width" binary="true"></p-checkbox>

        <div class="row">
          <label for="" class="label">{{includeSearchWhen}}</label>
        </div>

        <div>
          <p-radioButton class="row" name="forIncomingCalls" value="startsWith" label="{{startsWith}}" [(ngModel)]="settingsGeneral.contact_startsWith"
            styleClass="big-radio"></p-radioButton>
          <p-radioButton class="row" name="forIncomingCalls" value="contains" label="{{contains}}" [(ngModel)]="settingsGeneral.contact_startsWith"
            styleClass="big-radio"></p-radioButton>
        </div>
    </div>
  </div>
</div>