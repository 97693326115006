<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <p>
    <b>
      {{broadworksLoginDetails}}
    </b>
  </p>
</div>
<div class="row">
  <label for="" class="label">{{loginID}}</label>
  <input type="text" name="loginid" [(ngModel)]="settingsGeneral.loginID" (keypress)="dontAllowSpace($event)">
</div>
<div class="row">
  <label for="" class="label">{{password}}</label>
  <input type="password" name="password" [(ngModel)]="settingsGeneral.password">
</div>
<div class="row">
  <p-checkbox name="rememberPassword" value="" label={{rememberLoginID}} [(ngModel)]="settingsGeneral.rememberLoginId" binary="true"></p-checkbox>
</div>
<div class="row">
  <p-checkbox name="rememberPassword" value="" label={{rememberPassword}} [(ngModel)]="settingsGeneral.rememberPassword" binary="true"></p-checkbox>
</div>