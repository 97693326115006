<ul class="emojis">
  <li
    *ngFor="let emojiName of emojiNames; let i = index"
    [ngClass]="['emoji', emojiName]"
    title="{{ messagesService.emojis[emojiName][0] }}"
    (click)="singleClick(messagesService.emojis[emojiName][0])"
    (dblclick)="doubleClick(messagesService.emojis[emojiName][0])"
    (contextmenu)="rightClick(messagesService.emojis[emojiName][0])"
  ></li>
</ul>
