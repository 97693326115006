import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { AnonymousCallRejectionService } from '../../../../../providers/settings/settings-services/anonymous-call-rejection.service';
import { ContextService } from '../../../../../providers/context.service';
/**
 *  Used to store AnonymousCallRejectionComponent properties and this serves as a interface between component and AnonymousCallRejection Service
 * 
 * @export
 * @class AnonymousCallRejectionComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-anonymous-call-rejection',
  templateUrl: './anonymous-call-rejection.component.html',
  styleUrls: ['./anonymous-call-rejection.component.scss']
})
export class AnonymousCallRejectionComponent extends BaseComponent implements OnInit {
  isActive: boolean=false;
  description: string="";
  enabled: string="";
  constructor(public anonymousCallRejectionService: AnonymousCallRejectionService, private contextService: ContextService) {
    super();
  }
  ngOnInit() {
    this.anonymousCallRejectionService.changesOccured=true;
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.AnonymousCallRejection.Description');
  }
}
