<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <p>
    <b>
      {{calls}}
    </b>
  </p>
  <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{autoAnswerCalls}} [(ngModel)]="settingsGeneral.automaticallyAnswerCall"
  binary="true"></p-checkbox>
</div>

<div class="row">
  <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{includeInCallLogs}} [(ngModel)]="settingsGeneral.includeCallCenter"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <p-checkbox name="checkboxGroup1" (click)="this.queuedCallClick()" value="checkbox 1" [disabled]="this.contextService.objSettings.STARTUP===3" label={{queuedCallsInActivityList}} [(ngModel)]="settingsGeneral.showQueuedCalls"
    binary="true"></p-checkbox>
</div>
<div class="row" style="padding-left: 20px;">
  <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{callPriority}} [disabled]="this.contextService.objSettings.STARTUP===3 || !this.settingsGeneral.showQueuedCalls"
    [(ngModel)]="this.settingsGeneral.callPriority" binary="true"></p-checkbox>
</div>
<div class="row" *ngIf="!this.contextService.objLoggedUserDetails.getWebclientUseXSI()">
  <p>
    <b>
      {{listingSupervisor}}
    </b>
  </p>
  <label for="" class="label-block"></label>
  <p-radioButton name="groupname" [value]="false" label={{allSupervisors}} [(ngModel)]="settingsGeneral.onlyShowMonitoringSupervisor" styleClass="big-radio"></p-radioButton>
  <label for="" class="label-block"></label>
  <p-radioButton name="groupname" [value]="true" label={{onlySupervising}} [(ngModel)]="settingsGeneral.onlyShowMonitoringSupervisor" styleClass="big-radio"></p-radioButton>

</div>
