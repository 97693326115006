<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label">{{statistic}}</label>
  <p-dropdown [options]="this.contextService.agentColumnList" [disabled]="this.settingsGeneral.disableAgentColumnList"
    name="dropdown" [(ngModel)]="this.settingsGeneral.statisticsToDisplay" [style]="{'width':'100%'}"></p-dropdown>
</div>
<div class="row">
  <label for="" class="label">{{heading}}</label>
  <input type="text" name="input-text" [(ngModel)]="this.contextService.columnHeading">
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{setThreshold}} binary="true" (click)="this.changeText()"
    [(ngModel)]="this.contextService.setThreshold"></p-checkbox>
</div>
<div class="row" *ngIf="this.contextService.setThreshold">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" [(ngModel)]="this.settingsGeneral.highlightNonZeroValues" value="checkbox"
    label={{highlights}} binary="true"></p-checkbox>
</div>

<div class="row" *ngIf="this.contextService.setThreshold == true">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" [(ngModel)]="this.settingsGeneral.increasingThresholdValues" value="checkbox"
    label={{increasingThreshold}} binary="true"></p-checkbox>
</div>

<div class="row" *ngIf="this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.green}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.green}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.greenThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.yellow}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.yellow}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.yellowThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.red}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.red}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.redThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>
<div class="row" *ngIf="this.contextService.setThreshold=== true">
  <label class="ico-backarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== false">{{this.black}}</label>
  <label class="ico-frontarrow" *ngIf="this.settingsGeneral.increasingThresholdValues=== true">{{this.black}}</label>
  <input type="number" name="mini-input" [min]="0" [max]="100000000" [(ngModel)]="this.settingsGeneral.blackThreshold"
    class="input-mini mar-r-20" autocomplete=off>
</div>