/** 
 * C8 - model for sms m 
 */

import { Attachment } from './attachment.model';

export class SmsMessage {
  id: number;
  text: string;
  dateTime: Date;
  person: number;
  name: string;
  attachment: any;
  itsMe: boolean

  constructor(id, itsMe, text, deteTime, person, name, attachment: Attachment = null) {
    this.id = id;
    this.itsMe = itsMe;
    this.text = text;
    this.dateTime = deteTime;
    this.person = person;
    this.name = name;
    this.attachment = attachment;
  }

}
