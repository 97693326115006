<ul class="buttons-menu">
  <!-- UnAvilable -->
  <li
    (click)="openUnavailableCm($event, unavailablecm)"
    (contextmenu)="openUnavailableCm($event, unavailablecm)"
    #unavailable
  >
    <div class="tooltip" style="white-space: nowrap">
      <span>{{ contextService.unAvailableTooltipValue }}</span>
    </div>
    <a
      href="#"
      [ngClass]="contextService.unAvailableClassName"
      *ngIf="contextService.unAvailableState"
      >{{ agentUnavailable }}</a
    >
    <a
      href="#"
      [ngClass]="contextService.unAvailableClassName"
      *ngIf="!contextService.unAvailableState"
      >{{ agentUnavailable }}</a
    >
  </li>

  <!-- 1.Avilable disable 2. Avilable -->
  <li
    *ngIf="
      !contextService.availableState &&
      (this.contextService.objSettings.STARTUP === 2 ||
        this.contextService.objSettings.STARTUP === 3) &&
      (this.contextService.assignedServices.includes('CallCenter-Premium') ||
        this.contextService.assignedServices.includes('CallCenter-Standard')) &&
      !this.contextService.objSettings.acdState_ShowCurrentACDStateButton
    "
  >
    <div class="tooltip">
      <span>{{ agentAvailable }}</span>
    </div>
    <a (click)="available($event)" class="btn-available">{{
      agentAvailable
    }}</a>
  </li>
  <li>
    <div class="tooltip">
      <span>{{ agentAvailable }}</span>
    </div>
    <a href="#" class="btn-available active">{{ agentAvailable }}</a>
  </li>

  <!-- Wrap-up -->

  <li>
    <div class="tooltip">
      <span>{{ agentWrapUp }}</span>
    </div>
    <a href="#" class="btn-wrapup active">{{ agentWrapUp }}</a>
  </li>

  <!--1.Release disable 2. release 3. Answer -->
  <li *ngIf="contextService.releaseAnswerState === 'disable'">
    <div class="tooltip">
      <span>{{ releaseBtnlbl }}</span>
    </div>
    <a
      href="#"
      class="btn-hangup inactive ico-drop"
      [primeDragula]="btnBag"
      [dragulaOptions]="{ copy: true }"
      >{{ releaseBtnlbl }}</a
    >
  </li>

  <li *ngIf="contextService.releaseAnswerState === 'release'">
    <div class="tooltip">
      <span>{{ releaseBtnlbl }}</span>
    </div>
    <a
      href="#"
      class="btn-hangup active"
      [primeDragula]="btnBag"
      [dragulaOptions]="{ copy: true }"
      (click)="release()"
      >{{ releaseBtnlbl }}</a
    >
  </li>
  <li *ngIf="contextService.releaseAnswerState === 'answer'">
    <div class="tooltip">
      <span>{{ answerBtnlbl }}</span>
    </div>
    <a class="btn-answer" (click)="answer()">{{ answerBtnlbl }}</a>
  </li>
  <!--1.Dial -->
  <li #dial>
    <div class="tooltip">
      <span>{{ diallbl }}</span>
    </div>
    <a
      (click)="openDial()"
      class="btn-keyboard"
      (contextmenu)="showDialMenu($event.phone)"
      *ngIf="contextService.islicenseTypeState"
      >{{ diallbl }}</a
    >
    <a class="btn-keyboard inactive ico-drop">{{ diallbl }}</a>
  </li>
  <!-- Transfer -->
  <li #transfer>
    <div class="tooltip" style="white-space: nowrap">
      <span *ngIf="this.contextService.showWarnTransferButton === false">{{
        transferlbl
      }}</span>
      <span *ngIf="this.contextService.showWarnTransferButton === true"
        >{{ this.transferToolTip }}
        {{ this.contextService.warmTransferName }}</span
      >
    </div>
    <a
      (click)="transferCall($event, cmtransfer)"
      (contextmenu)="transferCall($event, cmtransfer)"
      class="btn-transfer"
      *ngIf="
        contextService.transferState === 'enabled' &&
        this.contextService.showWarnTransferButton === false
      "
      >{{ transferlbl }}</a
    >
    <a
      (click)="transferCallDisable()"
      *ngIf="this.contextService.showWarnTransferButton === false"
      class="btn-transfer inactive ico-drop"
      >{{ transferlbl }}</a
    >

    <a
      (click)="warmTransfer()"
      *ngIf="this.contextService.showWarnTransferButton === true"
      class="btn-warm_transfer"
      >{{ transferlbl }}</a
    >
  </li>
  <!-- Transfer -->
  <!-- <li *ngIf="this.contextService.showWarnTransferButton === true">
    <div class="tooltip">
      <span>{{transferlbl}}</span>
    </div>
    <a (click)="warmTransfer()" class="btn-warm_transfer">{{transferlbl}}</a>

  </li> -->

  <!--1.play disable 2. play -->

  <li *ngIf="contextService.holdRetrieveState === 'retrieveDisabled'">
    <div class="tooltip">
      <span>{{ retrieveBtnlbl }}</span>
    </div>
    <a href="#" class="btn-play inactive ico-drop" [primeDragula]="btnBag">{{
      retrieveBtnlbl
    }}</a>
  </li>
  <li *ngIf="contextService.holdRetrieveState === 'retrieve'">
    <div class="tooltip">
      <span>{{ retrieveBtnlbl }}</span>
    </div>
    <a
      href="#"
      class="btn-play active"
      [primeDragula]="btnBag"
      (click)="retrieve()"
      >{{ retrieveBtnlbl }}</a
    >
  </li>

  <!--1.hold disable 2. hold -->

  <li *ngIf="contextService.holdRetrieveState === 'holdDisabled'">
    <div class="tooltip">
      <span>{{ holdBtnlbl }}</span>
    </div>
    <a href="#" class="btn-pause inactive ico-drop">{{ holdBtnlbl }}</a>
  </li>
  <li *ngIf="contextService.holdRetrieveState === 'hold'">
    <div class="tooltip">
      <span>{{ holdBtnlbl }}</span>
    </div>
    <a href="#" class="btn-pause" [primeDragula]="btnBag" (click)="hold()">{{
      holdBtnlbl
    }}</a>
  </li>

  <!--1.conference disable 2. conference -->

  <li *ngIf="contextService.conferenceState === 'conferenceDisable'">
    <div class="tooltip">
      <span>{{ conferenceBtnlbl }}</span>
    </div>
    <a
      href="#"
      class="btn-conference inactive ico-drop"
      [primeDragula]="btnBag"
      [dragulaOptions]="{ copy: true }"
      >{{ conferenceBtnlbl }}</a
    >
  </li>

  <!-- Call park -->
  <li #callPark *ngIf="this.contextService.showCallParkButton === true">
    <div class="tooltip" style="white-space: nowrap">
      <span
        >{{ this.callParkBtnLabel
        }}{{ this.contextService.callParkUserName }}</span
      >
    </div>
    <a (click)="CallPark()" class="btn-callPark"
      >{{ this.callParkBtnLabel }} {{ this.contextService.callParkUserName }}</a
    >
  </li>
  <!-- Call park -->

  <!-- survey extension -->
  <li
    #surveyExtension
    *ngIf="this.contextService.surveyExtensionExist === true"
  >
    <div class="tooltip">
      <span>{{ this.surveyExtensionBtnLabel }}</span>
    </div>
    <a (click)="surveyExtensionClick()" class="btn-survey">{{
      this.surveyExtensionBtnLabel
    }}</a>
  </li>
  <!-- survey extension -->

  <li
    *ngIf="contextService.conferenceState === 'conferenceActive'"
    (contextmenu)="conferenceBridge($event, conferencecm)"
    #conferenceContextMenu
  >
    <div class="tooltip">
      <span>{{ conferenceBtnlbl }}</span>
    </div>
    <a
      href="#"
      class="btn-conference"
      [primeDragula]="btnBag"
      [dragulaOptions]="{ copy: true }"
      (click)="conference()"
      >{{ conferenceBtnlbl }}</a
    >
  </li>

  <!--1.recording -->

  <li
    [ngStyle]="{ display: this.showRecordingButton ? 'block' : 'none' }"
    #recording
  >
    <div class="tooltip">
      <span>{{ recordinglbl }}</span>
    </div>

    <a
      (click)="recordDisble()"
      class="btn-record ico-recording"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'disabled'"
      >{{ recordinglbl }}</a
    >

    <a
      (click)="startRecord()"
      class="btn-record pause"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'startRecording'"
      >{{ recordinglbl }}</a
    >

    <a
      (click)="pauseRecord(recordingLC)"
      class="btn-record pause"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'pauseRecording'"
      >{{ recordinglbl }}</a
    >

    <a
      (click)="startRecord()"
      class="btn-recordNever"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'recordNever'"
      >{{ recordinglbl }}</a
    >

    <a
      (click)="pauseRecord(recordingLC)"
      class="btn-alwaysRecord"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'alwaysRecord'"
      >{{ recordinglbl }}</a
    >

    <a
      (click)="resumeRecord()"
      class="btn-record pause"
      [ngClass]="contextService.recordingState"
      *ngIf="contextService.recordButtonState === 'resumeRecording'"
      >{{ recordinglbl }}</a
    >
  </li>

  <!--1.voice mail disable 2. voice mail voiceMailDisable -->
  <li>
    <div class="tooltip">
      <span>{{ sendToVM }}</span>
    </div>
    <a href="#" class="btn-voicemail inactive ico-drop">{{ sendToVM }}</a>
  </li>
  <li
    *ngIf="
      contextService.voiceMailState === 'voiceMailEnable' &&
      this.contextService.assignedServices.includes('VoiceMessaging') &&
      contextService.isVoiceMailActive
    "
  >
    <div class="tooltip">
      <span>{{ sendToVM }}</span>
    </div>
    <a href="#" class="btn-voicemail" (click)="voiceMail()">{{ sendToVM }}</a>
  </li>

  <li>
    <div class="tooltip">
      <span>{{ contextService.chatTooltip }}</span>
    </div>
    <a
      (click)="toggleChat()"
      [ngClass]="{ 'close-chat': this.contextService.isChatOpen }"
      class="btn-chat"
      >chat
      <span class="unread" *ngIf="this.contextService.messageCounter > 0">{{
        this.contextService.messageCounter
      }}</span>
    </a>
  </li>
</ul>

<p-contextMenu
  [model]="transferMenu"
  appendTo="body"
  [target]="transfer"
  leftClick="true"
  #cmtransfer
></p-contextMenu>

<p-contextMenu
  [model]="unavailableMenu"
  appendTo="body"
  [target]="unavailable"
  leftClick="true"
  #unavailablecm
>
</p-contextMenu>
<p-contextMenu
  [model]="contextService.dialMenu"
  appendTo="body"
  [target]="dial"
></p-contextMenu>
<p-contextMenu
  [model]="recordingLCMenu"
  appendTo="body"
  [target]="recording"
  leftClick="true"
  #recordingLC
>
</p-contextMenu>
<p-contextMenu
  [model]="recordingRCMenu"
  appendTo="body"
  [target]="recording"
></p-contextMenu>

<p-contextMenu
  [model]="conferenceMenu"
  appendTo="body"
  [target]="conferenceContextMenu"
  leftClick="true"
  #conferencecm
>
</p-contextMenu>

<p-dialog
  [resizable]="false"
  [(visible)]="conferenceAddConfirm"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: conferenceAddConfirm }"
  class="dialog-window buttons-2"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <!-- <p>Do you want to add {{conferenceAddPerson}} to the conference?</p> -->
    <p>{{ addCalltoconference }}</p>
    <div>
      <button
        type="button"
        class="btn-white"
        (click)="conferenceAddConfirm = false"
      >
        {{ btnNo }}
      </button>
      <button type="button" class="btn-blue-light" (click)="AddConference()">
        {{ btnYes }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="conferenceAddVis"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: conferenceAddVis }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ nWaycallingnotassigned }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="conferenceAddVis = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="callNotActiveState"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: callNotActiveState }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ callAlerting }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="callNotActiveState = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="recordingAlwaysError"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: recordingAlwaysError }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ stopPausenotallowed }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="recordingAlwaysError = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="contextService.recordingErrorFlag"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: contextService.recordingErrorFlag }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ this.contextService.recordingErrorMessage }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="contextService.recordingErrorFlag = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="recordingNeverError"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: recordingNeverError }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ recStartnotallowed }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="recordingNeverError = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="CannotChangeAcdStateOnCall"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: CannotChangeAcdStateOnCall }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ cannotChangeAcdStateOnCall }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="CannotChangeAcdStateOnCall = false"
      >
        {{ btnOk }}
      </button>
    </div>
  </div>
</p-dialog>
