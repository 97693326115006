<p-dialog [resizable]="false" [(visible)]="settingsVis" [responsive]="true" breakpoint="9000" [closable]="false" class="settings-dialog" styleClass="set-dialog"
  positionLeft="0" positionTop="0" [draggable]="false" [closeOnEscape]="false">
  <p-header>
    <div class="container-center">
      <ul class="modal-nav">

        <li>
          <a routerLink="settings-service" routerLinkActive="active">{{services}}</a>
        </li>
        <li>
          <a routerLink="settings-general" routerLinkActive="active">{{tabSetting}}</a>
        </li>
      </ul>
      <span class="close-modal" (click)="closeSettings()">X</span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="container-center">
      <form (ngSubmit)="onSubmit($event,sf)" #sf="ngForm" id="setForm">
        <router-outlet></router-outlet>
      </form>
    </div>
  </div>
  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div class="save-settings">
        <a (click)="closeSettings()">{{cancel}}</a>
        <button type="button" (click)="onSubmit($event,sf)" form="setForm" class="btn-blue-light">{{save}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [(visible)]="this.restartVis" [closable]="false" [style]="{'width':'600px'}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{restartRequired}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.restartUnity()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="restartCancel()">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>
<p-dialog [(visible)]="this.broadworksError" [closable]="false" [style]="{'width':'370px'}" class="dialog-window buttons-1" dismissableMask="true"
  showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{this.error}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.broadworksError=false">{{callButtonShowingOK}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="this.settingsGeneral.manualAlert" [closable]="false" [style]="{'width':'600px'}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{confirm}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.manualAlert=false;">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.manualAlert=false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="this.settingsGeneral.showUpdateAvailable" [closable]="false" [style]="{'width':'370px'}" class="dialog-window buttons-1"
  dismissableMask="true" showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{this.settingsGeneral.alertMessage}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.showUpdateAvailable=false">{{callButtonShowingOK}}</button>
    </div>
  </div>
</p-dialog>
<!-- delete agent stat alert -->
<p-dialog [(visible)]="this.settingsGeneral.deleteAgentVis" [closable]="false" [style]="{'width':'600px'}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false"  [resizable]="false">
  <div class="modal-content">
    <p>{{deleteAlert}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.deleteAgentColumn()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.deleteAgentVis=false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>

<!-- delete queue stat alert -->
<p-dialog [(visible)]="this.settingsGeneral.deleteQueueVis" [closable]="false" [style]="{'width':'600px'}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false"  [resizable]="false">
  <div class="modal-content">
    <p>{{deleteAlert}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.deleteQueueColumn()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.deleteQueueVis=false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>

<!-- delete queue stat alert -->
<p-dialog [(visible)]="this.settingsGeneral.deleteAgentActivityVis" [closable]="false" [style]="{'width':'600px'}" class="dialog-window buttons-2" dismissableMask="true"
  showHeader="false"  [resizable]="false">
  <div class="modal-content">
    <p>{{deleteAlert}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.settingsGeneral.deleteAgentActivityColumn()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="this.settingsGeneral.deleteAgentActivityVis=false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>