import { Injectable } from '@angular/core';
import { SimultaneousRing } from '../../../model/bw-services/simultaneous-ring';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';

/**
 * to register SimultaneousRingResponseHandler   
 * 
 * @export
 * @class SimultaneousRingService
 */
@Injectable()

export class SimultaneousRingService {
    numberArray = [];
    isActive: boolean = false;
    incomingCalls: boolean = false;
    newNumber: string = "";
    simultaneousRing: SimultaneousRing;
    simultaneousChanges: boolean = false;
    numberValidation: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }

    validateNewNumber(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    addNewNumber() { }
    deleteNumber(selectedNumber) { }
    setData(simultaneousRing: SimultaneousRing) { }
    validate(): boolean {
        return true;
    }
    save() { }
    populateData() { }
    cancel() { }

}