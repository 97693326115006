import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HelperService } from 'app/providers/helper.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { ContextService } from '../../providers/context.service';


@Component({
  selector: 'app-dial',
  templateUrl: './dial.component.html',
  styleUrls: ['./dial.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('dialDisplay',{static: true}) dialDisplay;
  dialForm: FormGroup;
  dialDisplayValue: string = '';
  dialVis: boolean = false;

  phoneNumber: string = "";
  call: string = "";

  keyboardBtns: Array<string> = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    const inputEl = this.dialDisplay.nativeElement;
    const str = inputEl.value;
    if (document.getElementById("btnCall")) {
      if ((str.length) === 0) {
        (<HTMLInputElement>document.getElementById("btnCall")).disabled = true;
      } else {
        (<HTMLInputElement>document.getElementById("btnCall")).disabled = false;
      }
    }
    // close dialog on escape
    if (this.dialVis) {
      if (event.keyCode === 27) { this.closeKeyboard(); }
      if (event.keyCode === 13) { this.onSubmitCall(); }
    }
  }
  constructor(private helper: HelperService, private fb: FormBuilder, private cd: ChangeDetectorRef, private contextService: ContextService) {
    this.cd.detach();
    // this.helper.dialVisible.takeUntil(this.ngUnsubscribe).subscribe((val) => {
    //   this.cd.reattach();
    //   this.ngOnInit();
    //   this.dialVis = val;
    // });
  }

  ngOnInit() {
    this.helper.dialVisible.takeUntil(this.ngUnsubscribe).subscribe((val) => {
      this.cd.reattach();
      this.dialVis = val;

      this.phoneNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
      this.call = this.contextService.getLanguageTagByKey('Menu.Context.Call');

      if (document.getElementById("txtDial")) {
        var elementtxt = document.getElementById("txtDial").className;

        if (elementtxt === 'ng-invalid ng-dirty ng-touched' || 'ng-dirty ng-touched ng-invalid' || 'ng-invalid ng-touched ng-dirty') {
          document.getElementById("txtDial").classList.remove("ng-invalid");
          document.getElementById("txtDial").classList.add("ng-valid");
        }
        else {
          this.contextService.log("Class Name: " + elementtxt);
        }
      }

      if (document.getElementById("dialValidation")) {
        var elementLabel = document.getElementById("dialValidation");
        if (elementLabel) {
          document.getElementById("dialValidation").classList.remove("error-msg");

        }
      }

    });

    this.dialForm = this.fb.group({
      dialDisplayValue: [null, [Validators.required, Validators.pattern(/[0-9*#]{1,}/)]]
    });
  }

  focusInput() {
    this.dialDisplay.nativeElement.focus();
  }

  keyClick(e) {
    const pressedKey = e.target.dataset.key;
    if (pressedKey) {
      (<HTMLInputElement>document.getElementById("btnCall")).disabled = false;
      this.dialDisplayValue = this.dialDisplay.nativeElement.value + '' + pressedKey;
      this.dialForm.setControl('dialDisplayValue', this.fb.control(this.dialDisplayValue));
    }
  }

  deleteChar() {
    const inputEl = this.dialDisplay.nativeElement;
    const str = inputEl.value;
    let newVal = str.substr(0, str.length - 1);
    if (inputEl.selectionStart !== inputEl.selectionEnd) {
      newVal = str.substr(0, inputEl.selectionStart, inputEl.selectionEnd) + str.substr(inputEl.selectionEnd);
    }
    if ((str.length - 1) === 0) {
      (<HTMLInputElement>document.getElementById("btnCall")).disabled = true;
    }

    this.dialForm.setControl('dialDisplayValue', this.fb.control(newVal));
    this.focusInput();
  }

  /**
   * used to invoke dial action
   * 
   * @memberof DialComponent
   */
  onSubmitCall() {

    this.contextService.log("start method: onSubmitCall()");

    let phoneNum = this.dialForm.value.dialDisplayValue;
    if (phoneNum !== "") {
      if (phoneNum.charAt(0) === '+') {
        phoneNum = phoneNum.replace("+", "00");
      }
      phoneNum = phoneNum.replace(/\D/g, '');

      if (this.dialForm.valid) {
        this.contextService.log(phoneNum);
        if (this.contextService.isTransferCall) {
          if (this.contextService.transferCallId) {
            this.contextService.websocketService.blindTransferAction(this.contextService.transferCallId, phoneNum);
            this.contextService.log("blindTransferAction performed to call Ids:" + this.contextService.transferCallId + ",Phone Number:" + phoneNum);

          } else {
            this.contextService.websocketService.blindTransferAction(this.contextService.callStatusList[0].callId, phoneNum);
            this.contextService.log("blindTransferAction performed to call Ids:" + this.contextService.callStatusList[0].callId + ",Phone Number:" + phoneNum);

          }

        } else {
          this.contextService.websocketService.dialAction(phoneNum);
          this.contextService.log("dialAction performed to phone Number:" + phoneNum);

        }

        this.dialForm.setValue({ dialDisplayValue: '' });
        this.helper.dialVisible.next(false);
        this.closeKeyboard();
      }
    }
    this.contextService.log("End method: onSubmitCall()");



  }
  /**
   * clode dial window
   * 
   * @memberof DialComponent
   */
  closeKeyboard() {
    this.dialVis = false;
    this.helper.closeModal();
    this.dialForm.setValue({ dialDisplayValue: '' });
    if (document.getElementById("txtDial")) {
      var elementtxt = document.getElementById("txtDial").className;

      if (elementtxt === 'ng-invalid ng-dirty ng-touched' || 'ng-dirty ng-touched ng-invalid' || 'ng-invalid ng-touched ng-dirty') {
        document.getElementById("txtDial").classList.remove("ng-invalid");
        document.getElementById("txtDial").classList.add("ng-valid");
      }
      else {
        this.contextService.log("Class Name: " + elementtxt);
      }
    }

    if (document.getElementById("dialValidation")) {
      var elementLabel = document.getElementById("dialValidation");
      if (elementLabel) {
        // elementLabel.classList.remove("error-msg");
        document.getElementById("dialValidation").classList.remove("error-msg");

      }
    }

    if (document.getElementById("dialValidation")) {
      var elementLabel = document.getElementById("dialValidation");
      if (elementLabel) {
        elementLabel.parentNode.removeChild(elementLabel);
      }
    }



    setTimeout(() => { this.cd.detach(); });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
