import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { SelectItem } from 'primeng/primeng';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleComponent implements OnInit {

  @Output() onSubmit = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();
  @Input() scheduleDateSelected: Date;

  scheduleForm: FormGroup;
  scheduleDate: Date;
  hours: Array<string> = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes: Array<string> = ['00', '15', '30', '45'];
  scheduleHourOpts: SelectItem[] = [];
  scheduleMinOpts: SelectItem[] = [];
  scheduleHour: string;
  scheduleMin: string;

  title: string = "";
  errorMessage: string = "";
  btnOk: string = "";
  btnCancel: string = "";
  btnClear: string = "";

  wrongDateTime: boolean = false;

  constructor(
    public contextService: ContextService, private messagesService: MessagesService,
    private fb: FormBuilder
  ) {

  }

  ngOnInit() {
    this.scheduleDate = new Date();
    this.scheduleDate.setHours(this.scheduleDate.getHours() + 1); // add 1h by default

    this.hours.forEach(item => {
      this.scheduleHourOpts.push({ label: item, value: item });
    });
    this.minutes.forEach(item => {
      this.scheduleMinOpts.push({ label: item, value: item });
    });

    this.title = this.contextService.getLanguageTagByKey('Schedule.Title');
    this.btnOk = this.contextService.getLanguageTagByKey('Schedule.btnOk');
    this.btnCancel = this.contextService.getLanguageTagByKey('Schedule.btnCancel');
    this.btnClear = this.contextService.getLanguageTagByKey('Schedule.btnClear');
    this.errorMessage = this.contextService.getLanguageTagByKey('Schedule.error');

    var h = String((this.scheduleDate.getHours() < 10 ? '0' : '') + this.scheduleDate.getHours());
    //var m = String((this.scheduleDate.getMinutes() < 10 ? '0' : '') + this.scheduleDate.getMinutes());

    this.scheduleForm = this.fb.group({
      scheduleDate: [this.scheduleDate, Validators.required],
      scheduleHour: [h, Validators.required],
      scheduleMin: ['00', Validators.required],
    });

  }

  isValid() {
    this.wrongDateTime = false;
  }

  isInvalid() {
    this.wrongDateTime = true;
  }

  onFormSubmit() {

    this.wrongDateTime = true;

    var scheduleDate = this.scheduleForm.value.scheduleDate;
    var scheduleHour = this.scheduleForm.value.scheduleHour;
    var scheduleMin = this.scheduleForm.value.scheduleMin;

    scheduleDate.setHours(scheduleHour)
    scheduleDate.setMinutes(scheduleMin)
    scheduleDate.setSeconds(0)

    var now = new Date()
    if (scheduleDate.getTime() <= now.getTime()) { // in past
      this.isInvalid()
    } else {
      this.isValid()
      this.onSubmit.emit(scheduleDate);
    }
  }

  clear() {
    this.onClear.emit(true);
    this.isValid() // hide err
  }

  close() {
    this.onClose.emit(true);
    this.isValid() // hide err
  }
}
