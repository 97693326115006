import { ContextService } from "../../providers/context.service";
import { Threshold } from "../agent/statistics/threshold";

export class AgentActivityCCWise {
    monitoredAgentDetails: Array<AgentActivitystats>;
    constructor() {
        this.monitoredAgentDetails = [];
    }
}
export class AgentActivitystats {
    id: number = 0;
    userID: string = "";
    name: string = "";
    callcenterId: string = ""
    acdstate: string = ""
    private icon: string = "";
    private isOnline: boolean = false;
    private agentACDState: string = "";
    private agentACDDuration: string = "";
    private agentACDStart: string = "";
    private agentUnavailabilityReason: string = "";
    private agentReasonDuration: string = "";
    private agentType: string = "";
    private agentJoinedState: string = "";
    private agentTotalStaffedTime: string = "";
    private agentTotalCalls: number = 0;
    private agentMissedCalls: number = 0;
    private agentAnsweredCalls: number = 0;
    private agentTotalTalkTime: string = "";
    private agentAverageTalkTime: string = "";
    private agentCurrentCallDuration: string = "";
    private agentStatus: string = "";

    public lastStaffedDateTime: Date = null;
    initialUserTotalStaffedTime: number = 0;
    public unavailabilityReasonDateTime: Date = null;
    public callDurationTimestamp: number = null;


    public agentACDDurationColor: string = "";
    public agentReasonDurationColor: string = "";
    public agentTotalStaffedTimeColor: string = "";
    public agentTotalCallsColor: string = "";
    public agentMissedCallsColor: string = "";
    public agentAnsweredCallsColor: string = "";
    public agentTotalTalkTimeColor: string = "";
    public agentAverageTalkTimeColor: string = "";
    public agentCurrentCallDurationColor: string = "";
    // public queueBusyOverflowsColor: string;
    public isHighlightCollection = [];
    public threshHolds: Array<Threshold> = [];
    public acdthreshHolds: Array<Threshold> = [];

    // public contextService: ContextService;
    constructor(name: string, userID: string, callcenterId: string, contextService: ContextService) {
        // this.contextService = contextService;
        this.name = name;
        this.userID = userID;
        this.callcenterId = callcenterId;
        this.agentACDState = "";
        this.agentACDDuration = "00:00:00";
        this.agentACDStart = "00:00:00";
        this.agentUnavailabilityReason = "";
        this.agentReasonDuration = "00:00:00";
        this.agentType = "Voice";
        this.agentJoinedState = "";
        this.agentTotalStaffedTime = "00:00:00";
        this.agentTotalCalls = 0;
        this.agentMissedCalls = 0;
        this.agentAnsweredCalls = 0;
        this.agentTotalTalkTime = "00:00:00";
        this.agentAverageTalkTime = "00:00:00";
        this.agentCurrentCallDuration = "";
        this.agentStatus = status;
        this.acdstate = "";

    }
    public getIcon(): string {
        return this.icon;
    }
    public setIcon(value: string) {
        this.icon = value;
    }
    public getIsOnline(): boolean {
        return this.isOnline;
    }
    public setIsOnline(value: boolean) {
        this.isOnline = value;
    }
    public getACDState(): string {
        return this.agentACDState;
    }
    public setACDState(value: string) {
        this.agentACDState = value;
    }
    public getACDDuration(): string {
        return this.agentACDDuration;
    }
    public setACDDuration(value) {
        this.agentACDDuration = this.processSecondsToTime(value);
        this.agentACDDurationColor = this.calculateThresholdColor(this.getThresholdByACDState("agentACDDuration"), this.processTimeToSeconds(this.agentACDDuration));
    }
    public getACDStart(): string {
        return this.agentACDStart;
    }
    public setACDStart(value: string) {
        this.agentACDStart = value;
    }
    public getUnavailabilityReason(): string {
        return this.agentUnavailabilityReason;
    }
    public setUnavailabilityReason(value: string) {
        this.agentUnavailabilityReason = value;
    }
    public getReasonDuration(): string {
        if (this.agentReasonDuration === "") {
            return "";
        } else {
            return this.agentReasonDuration;
        }
    }
    public setReasonDuration(value) {
        this.agentReasonDuration = this.processSecondsToTime(value);
        if (this.agentReasonDuration == "00:00:00") {
            this.agentReasonDuration = "";
        }
        this.agentReasonDurationColor = this.calculateThresholdColor(this.getSimpleThreshold("agentReasonDuration"), this.processTimeToSeconds(this.agentReasonDuration));
    }
    public getAgentType(): string {
        return this.agentType;
    }

    public seAgentType(value: string) {
        this.agentType = value;
    }
    public getJoinedState(): string {
        return this.agentJoinedState;
    }

    public setJoinedState(queueState: boolean) {
        if (queueState) {
            this.agentJoinedState = "Joined";
            if (this.acdstate != "Sign-Out") {
                if (this.lastStaffedDateTime == null) {
                    this.lastStaffedDateTime = new Date();
                }
            }
        } else {
            this.agentJoinedState = "Not Joined";
            if (this.lastStaffedDateTime != null) {
                let staffedTime = Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);
                this.initialUserTotalStaffedTime += staffedTime;
                this.lastStaffedDateTime = null;
            }
        }
    }
    public setacdstateforstaffedtime(ACDState: string) {
        this.acdstate = ACDState;
        if (this.acdstate != "Sign-Out") {
            if (this.agentJoinedState == "Joined") {
                if (this.lastStaffedDateTime == null) {
                    this.lastStaffedDateTime = new Date();
                }
            }
        } else {
            if (this.lastStaffedDateTime != null) {
                let staffedTime = Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);
                this.initialUserTotalStaffedTime += staffedTime;
                this.lastStaffedDateTime = null;
            }
        }
    }
    //todo
    public setInitialUserTotalStaffedTime(initialUserTotalStaffedTime) {
        if (this.lastStaffedDateTime != null) {
            this.lastStaffedDateTime = new Date();
        }
        this.setTotalStaffedTime(initialUserTotalStaffedTime);
        this.initialUserTotalStaffedTime = initialUserTotalStaffedTime;
    }
    public getTotalStaffedTime(): number {
        if (this.lastStaffedDateTime != null) {
            return this.initialUserTotalStaffedTime + Math.abs((new Date().getTime() - this.lastStaffedDateTime.getTime()) / 1000);

        } else {
            return this.initialUserTotalStaffedTime;
        }
    }
    public setTotalStaffedTime(value) {
        this.agentTotalStaffedTime = this.processSecondsToTime(value);
        this.agentTotalStaffedTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalStaffedTime"), this.processTimeToSeconds(this.agentTotalStaffedTime));
    }
    public getTotalCalls(): number {
        return this.agentTotalCalls;
    }
    public setTotalCalls(value: number) {
        this.agentTotalCalls = this.setValues(this.agentTotalCalls, value);
        this.agentTotalCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalCalls"), this.agentTotalCalls);
    }
    public getMissedCalls(): number {
        return this.agentMissedCalls;
    }
    public setMissedCalls(value: number) {
        this.agentMissedCalls = this.setValues(this.agentMissedCalls, value);
        this.agentMissedCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentMissedCalls"), this.agentMissedCalls);
    }
    public getAnsweredCalls(): number {
        return this.agentAnsweredCalls;
    }
    public setAnsweredCalls(value: number) {
        this.agentAnsweredCalls = this.setValues(this.agentAnsweredCalls, value);
        this.agentAnsweredCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentAnsweredCalls"), this.agentAnsweredCalls);
    }
    public getTotalTalkTime(): number {
        return this.processTimeToSeconds(this.agentTotalTalkTime);
    }
    public setTotalTalkTime(value) {
        this.agentTotalTalkTime = this.processSecondsToTime(value);
        this.agentTotalTalkTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalTalkTime"), this.processTimeToSeconds(this.agentTotalTalkTime));
    }
    public getAverageTalkTime(): number {
        return this.processTimeToSeconds(this.agentAverageTalkTime);
    }
    public setAverageTalkTime(value) {
        this.agentAverageTalkTime = this.processSecondsToTime(value);
        this.agentAverageTalkTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentAverageTalkTime"), this.processTimeToSeconds(this.agentAverageTalkTime));
    }
    public getCurrentCallDuration(): string {
        if (this.agentCurrentCallDuration == "-") {
            return "00:00:00";
        } else {
            return this.agentCurrentCallDuration
        }
        // return this.currentCallDuration;
    }
    public setCurrentCallDuration(value) {
        this.agentCurrentCallDuration = this.processSecondsToTime(value);
        if (this.agentCurrentCallDuration == "00:00:00") {
            this.agentCurrentCallDuration = "-";
        }
        this.agentCurrentCallDurationColor = this.calculateThresholdColor(this.getSimpleThreshold("agentCurrentCallDuration"), this.processTimeToSeconds(this.agentCurrentCallDuration));
    }
    public getStatus(): string {
        return this.agentStatus;
    }
    public setStatus(value: string) {
        this.agentStatus = value;
    }

    setValues(currentvalue, value) {
        if (value === "+") {
            return parseInt(currentvalue) + 1;
        } else if (value === "-") {
            return parseInt(currentvalue) - 1;
        } else {
            return parseInt(value);
        }
    }

    calculateThresholdColor(statThreshold: Threshold, value): string {
        if (statThreshold) {
            let values = [{ color: "#91d987", value: statThreshold.green }, { color: "#ffba55", value: statThreshold.yellow }, { color: "#f96855", value: statThreshold.red }, { color: "#9da4a7", value: statThreshold.black }];
            values.forEach((element, index) => {
                if (element.value === "" || element.value === null || element.value === "null") {
                    values[index].color = ""
                    values[index].value = "0";
                }
            });
            // let values = [{ color: "green", value: statThreshold.green }, { color: "yellow", value: statThreshold.yellow }, { color: "red", value: statThreshold.red }, { color: "black", value: statThreshold.black }];
            let val = "";
            if (statThreshold.isIncreasing) {
                values.sort((a, b) => (parseInt(a.value) < parseInt(b.value) ? -1 : 1));
                for (let index = 0; index < values.length; index++) {
                    if ((values[index].value !== "") && (values[index].color !== "") && (Number(value) >= Number(values[index].value))) {
                        val = values[index].color;
                    }
                }
            } else {
                values.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? -1 : 1));
                for (let index = 0; index < values.length; index++) {
                    if ((values[index].value !== "") && (values[index].color !== "") && (Number(value) <= Number(values[index].value))) {
                        val = values[index].color;
                    }
                }
            }
            return val;
        }
    }
    getSimpleThreshold(stat) {
        if (this.name != "Summary") {
            if (this.threshHolds.hasOwnProperty(stat)) {
                return this.threshHolds[stat];
            }
        }

    }
    getThresholdByACDState(stat) {
        if (this.name != "Summary") {
            if (this.agentACDState in this.acdthreshHolds) {
                let element = this.acdthreshHolds[this.agentACDState];
                if (element.green !== "" || element.black !== "" || element.red !== "" || element.yellow !== "") {
                    this.isHighlightCollection["agentACDDuration"] = element.highLightValues;
                    return this.acdthreshHolds[this.agentACDState];
                } else {
                    this.isHighlightCollection["agentACDDuration"] = this.acdthreshHolds["all"].highLightValues;
                    return this.acdthreshHolds["all"];
                }
            }
        }
    }
    processTimeToSeconds(time) {
        let a = time.toString().split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    }
    processSecondsToTime(totalSeconds) {
        if (totalSeconds) {
            let seconds, minutes, hours;
            hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            minutes = Math.floor(totalSeconds / 60);
            seconds = Math.floor(totalSeconds % 60);
            // if (hours == 0) {
            // return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            // } else {
            return (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
            // }
        } else {
            return "00:00:00";
        }

    }
    recalculateThresholdColor() {
        this.agentACDDurationColor = this.calculateThresholdColor(this.getThresholdByACDState("agentACDDuration"), this.processTimeToSeconds(this.agentACDDuration));
        this.agentReasonDurationColor = this.calculateThresholdColor(this.getSimpleThreshold("agentReasonDuration"), this.processTimeToSeconds(this.agentReasonDuration));
        this.agentTotalStaffedTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalStaffedTime"), this.processTimeToSeconds(this.agentTotalStaffedTime));
        this.agentTotalCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalCalls"), this.agentTotalCalls);
        this.agentMissedCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentMissedCalls"), this.agentMissedCalls);
        this.agentAnsweredCallsColor = this.calculateThresholdColor(this.getSimpleThreshold("agentAnsweredCalls"), this.agentAnsweredCalls);
        this.agentTotalTalkTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentTotalTalkTime"), this.processTimeToSeconds(this.agentTotalTalkTime));
        this.agentAverageTalkTimeColor = this.calculateThresholdColor(this.getSimpleThreshold("agentAverageTalkTime"), this.processTimeToSeconds(this.agentAverageTalkTime));
        this.agentCurrentCallDurationColor = this.calculateThresholdColor(this.getSimpleThreshold("agentCurrentCallDuration"), this.processTimeToSeconds(this.agentCurrentCallDuration));
    }
    clearAllValues() {
        //for summary row
        this.agentACDState = "";
        this.agentACDDuration = "";
        this.agentACDStart = "";
        this.agentUnavailabilityReason = "";
        this.agentReasonDuration = "";
        this.agentType = "";
        this.agentTotalStaffedTime = "00:00:00";
        this.agentTotalCalls = 0;
        this.agentMissedCalls = 0;
        this.agentAnsweredCalls = 0;
        this.agentTotalTalkTime = "00:00:00";
        this.agentAverageTalkTime = "00:00:00";
        this.agentCurrentCallDuration = "";
        this.agentStatus = "";
        this.icon = "";
    }
}