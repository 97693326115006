import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/operator/takeUntil';
import { HelperService } from './../../providers/helper.service';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {
  calendarModalVisible: boolean = false;
  hours: Array<any> = [];
  width: number;
  hoursWidth: number = 101; // +2px border
  daysWidth: number = 314; // +2px border
  rowHeight: number = 34;
  padding: number = 10;
  days: Array<any> = [
    'Friday, 16 June 2017',
    'Thursday, 19 June 2017'
  ];
  events: Array<any> = [
  ];

  constructor(private helper: HelperService,
    private cd: ChangeDetectorRef) { this.cd.detach(); }

  ngOnInit() {
    this.helper.calendarModalVis.subscribe(val => {
      this.cd.reattach();
      this.calendarModalVisible = val;
    });

    for (var hour = 9; hour < 20; hour++) {
      this.hours.push(this.prefixHour(hour) + ':00 - ' + this.prefixHour(hour + 1) + ':00');
    }

    this.width = this.hoursWidth + (this.days.length * this.daysWidth);
    // demo events
    this.events.push({ title: 'Demo event here', 'top': 2 * this.rowHeight, left: this.hoursWidth + this.padding, height: 90 });
    this.events.push({ title: 'Demo event here', 'top': 6.5 * this.rowHeight, left: this.hoursWidth + this.padding, height: 50 });
    this.events.push({ title: 'Demo event here 2', 'top': 4 * this.rowHeight, left: this.daysWidth + this.hoursWidth + this.padding, height: 40 });
    this.events.push({ title: 'Demo event here 3', 'top': 6 * this.rowHeight, left: this.daysWidth + this.hoursWidth + this.padding, height: 80 });
  }

  prefixHour(hour: any) {
    if (hour < 10) {
      hour = '0' + hour;
    }
    return hour;
  }

  closeModal() {
    this.calendarModalVisible = false;
    setTimeout(() => { this.cd.detach(); });
  }
}
