/** 
 * C8 - model for user 
 */


export class User {
  id: number;
  name: string;
  email: string;
  phone: string;
  department: string;
  IP: string;
  userAgent: string;
  url: string;

  constructor(id, name, email, phone, department = '', IP = '', userAgent = '', url = '') {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.department = department;
    this.IP = IP;
    this.userAgent = userAgent;
    this.url = url;
  }
}