<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label">{{startProfile}}</label>
  <p-dropdown id="drpName_commPilotValueOnStartup" [options]="commPilotExpressService.commpilot" name="dropdown" [(ngModel)]="commPilotExpressService.commPilotValueOnStartup" [style]="{'width':'100%'}"></p-dropdown>
</div>
<div class="row" *ngIf="this.contextService.isElectron===true">
  <label for="" class="label">{{closeProfile}}</label>
  <p-dropdown id="drpName_commPilotValueOnUnityClose" [options]="commPilotExpressService.commpilot" name="dropdown" [(ngModel)]="commPilotExpressService.commPilotValueOnUnityClose" [style]="{'width':'100%'}"></p-dropdown>
</div>