/** 
 * C8 - model for attachment 
 */

import { type } from "os";

export class Attachment {
  id: number;
  name: string;
  url: string;
  dateTime: Date;
  person: number;
  size: number; // in bites
  sizeFormated: string; // in bites
  fileType: string;

  constructor(id, name, deteTime, person, url, size) {
    this.id = id;
    this.name = name;
    this.dateTime = deteTime;
    this.person = person;
    this.url = url;
    this.size = size;
    this.sizeFormated = this.formatBytes(size);
    this.fileType = this.getFileType();
  }



  getFileType() {
    var ext = this.name.split('.').pop().toLowerCase(), file_type;
    switch (ext) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
      case 'tif':
      case 'svg':
        file_type = 'img';
        break;

      case 'zip':
      case 'raf':
        file_type = 'zip';
        break;

      case 'csv':
        file_type = 'csv';
        break;

      case 'txt':
      case 'rtf':
        file_type = 'txt';
        break;

      case 'xls':
      case 'xlsx':
        file_type = 'xls';
        break;

      case 'pdf':
        file_type = 'pdf';
        break;

      case 'ppt':
      case 'pptx':
        file_type = 'ppt';
        break;

      case 'mp3':
        file_type = 'mp3';
        break;

      case 'exe':
        file_type = 'exe';
        break;

      case 'doc':
      case 'docx':
      case 'odt':
        file_type = 'doc';
        break;

      case 'htm':
      case 'html':
        file_type = 'html';
        break;

      default:
        file_type = 'file'
        break;
    }
    return file_type;
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
