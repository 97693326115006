<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" [(ngModel)]="this.settingsGeneral.displaySummaryStatisticsOnly" label={{summaryStatistics}} binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{scrollbarDescription}}</label>
  <div>
    <input type="number" [(ngModel)]="this.settingsGeneral.scrollbarCountOfPW" (keypress)="onlyNumberKey($event)" [disabled]="this.settingsGeneral.displaySummaryStatisticsOnly"
      name="mini-input" class="input-mini" [min]="2" [max]="20" onPaste="return false">
  </div>
</div>

<!-- <div class="row">
  <label for="" class="label">{{processCallCenterDescription}}</label>
  <div>
    <input type="number" [(ngModel)]="this.settingsGeneral.processCallCenterBatches" (keypress)="onlyNumberKey($event)"
      name="mini-input" class="input-mini" [min]="1" [max]="2" onPaste="return false">
  </div>
</div> -->

<!-- <div class="row">
  <label for="" class="label">{{refreshFreqDescription}}</label>
  <div>
    <input type="number" [(ngModel)]="this.settingsGeneral.refreshFrequencyStart" (keypress)="onlyNumberKey($event)"
      name="mini-input" class="input-mini" [min]="10" [max]="1800" onPaste="return false">
    <span class="hyphen-devider">-</span>
    <input type="number" [(ngModel)]="this.settingsGeneral.refreshFrequencyEnd" (keypress)="onlyNumberKey($event)"
      name="mini-input" class="input-mini" [min]="10" [max]="1800" onPaste="return false">
  </div>
</div> -->