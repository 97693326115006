import { Injectable } from '@angular/core';
import { CallRecording } from '../../../model/bw-services/call-recording';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';


@Injectable()
export class CallRecordingService {
    callRecording: CallRecording;
    recordingCalls: string = "";
    pauseResumeNotification: string = "";
    startStopAnnouncement: boolean = false;
    voiceMessaging: boolean = false;
    recordingTone: boolean = false;
    seconds: string = "10";
    recordingState: boolean = false;
    recordMode = [];
    pauseResume = [];
    callRecordingChanges: boolean = false;



    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }
    setData(callRecording: CallRecording) {
    }
    validate(): boolean {
        return true;
    }
    save() {
    }
    populateData() { }
    recordingRequest(recodingMode) {
    }
    cancel() {
        this.populateData();
    }
    record_subscriptionCalls() {
    }

}