import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from "../../../../../../../providers/context.service";
import { SettingsGeneral } from '../../../../../../../providers/settings/settings-general/settings-general.service';
@Component({
  selector: 'app-call-centers-authentication',
  templateUrl: './call-centers-authentication.component.html',
  styleUrls: ['./call-centers-authentication.component.scss']
})
export class CallCentersAuthenticationComponent implements OnInit {
  defaultPassword: string = "";
  selectedCallCenter: any = "";
  loginFailsAlert: string = "";
  callCenterList = [];
  sortable = [];
  toolTip: string = "";
  callcenterDescription: string = "";
  callCenterLoginDetails: string = "";
  confirmDefaultPasswordDelete: string = "";
  dialogYes: string = "";
  dialogNo: string = "";
  constructor(private contextService: ContextService, private router: Router, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.defaultPassword = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.DefaultPassword');
    this.loginFailsAlert = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.AlertWhenCallCenterLoginFails');
    this.callcenterDescription = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Description.CallCenter');
    this.callCenterLoginDetails = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.CallCenterLoginDetails');
    this.confirmDefaultPasswordDelete = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.ConfirmDefaultPasswordDelete');
    this.dialogYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.dialogNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.toolTip = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.RemovePassword');

    this.settingsGeneral.defaultCCPasswordExistInLicense=this.contextService.objLoggedUserDetails.getDefaultPasswordExistInLicense();

    this.sortProperties(this.contextService.agentDetails.callCenters);
    this.callCenterList = [];
    this.settingsGeneral.callCenterPassword = "";
    for (let i = 0; i < this.sortable.length; i++) {
      let element = this.sortable[i];
      let iconValue = "ico-unavailable";

      if (this.contextService.agentDetails.callCenters[element[1].acdUserId].isLoggedIn) {
        iconValue = "ico-available"
      } else if (!this.contextService.agentDetails.callCenters[element[1].acdUserId].isLoggedIn) {

        if (this.contextService.objSettings.assignedCallCenters[element[1].acdUserId]) {
          if (this.contextService.objSettings.assignedCallCenters[element[1].acdUserId].password === "") {
            iconValue = "ico-unavailable";
          } else {
            iconValue = "ico-busy";
          }
        } else {
          iconValue = "ico-unavailable";
        }
      }
      this.callCenterList.push({ id: i + 1, number: this.contextService.getLastNameFromUserID(element[1].acdUserId), value: element[1].acdUserId, icon: iconValue });
    }
    if (this.contextService.objSettings.defaultPasswordExist|| this.settingsGeneral.defaultCCPasswordExistInLicense) {
      this.settingsGeneral.defaultPasswordToCheck = "********";
      this.settingsGeneral.defaultPassword = "********";
    }

    if (this.settingsGeneral.defaultPassword !== "") {
      this.settingsGeneral.defaultPasswordDeleteButton = true;
    } else {
      this.settingsGeneral.defaultPasswordDeleteButton = false;
    }
    this.settingsGeneral.settingsChanged = true;

    }

  doubleClickCallCenterTable(event) {
    this.contextService.log("doubleClickCallCenterTable clicked");
    this.settingsGeneral.currentCallCenterAuthPage = "1";
    this.settingsGeneral.callCenterID = event.value;
    this.settingsGeneral.callCenterPassword = "";
    this.settingsGeneral.applyToAllQueues = false;
    if (this.settingsGeneral.callCenterID !== "") {
      if (this.contextService.agentDetails.callCenters[this.settingsGeneral.callCenterID].isLoggedIn) {
        this.settingsGeneral.callCenterPassword = "********";
        this.settingsGeneral.passwordToCheck = "********";
        this.settingsGeneral.passwordDeleteButtonVisisbility = true;

      } else {

        if (this.contextService.objSettings.assignedCallCenters[this.settingsGeneral.callCenterID]) {
          this.settingsGeneral.callCenterPassword = this.contextService.objSettings.assignedCallCenters[this.settingsGeneral.callCenterID].password;
        } else {
          this.settingsGeneral.callCenterPassword = "";
        }

        if (this.settingsGeneral.callCenterPassword !== "") {
          this.settingsGeneral.passwordDeleteButtonVisisbility = true;
        } else {
          this.settingsGeneral.passwordDeleteButtonVisisbility = false;
        }

      }
    }

    if (this.settingsGeneral.defaultPassword !== "") {
      this.settingsGeneral.passwordDeleteButtonVisisbility = false;
      this.settingsGeneral.defaultPasswordDeleteButton = true;
      this.settingsGeneral.callCenterPassword = "";
      this.settingsGeneral.passwordToCheck = "";
      this.settingsGeneral.showInWallBoard = true;
      for (const key in this.contextService.objSettings.showInPersonalWBList) {
        if (this.contextService.objSettings.showInPersonalWBList.hasOwnProperty(key)) {
          if (this.settingsGeneral.callCenterID === key) {
            this.settingsGeneral.showInWallBoard = this.contextService.objSettings.showInPersonalWBList[key].showInWallBoard;
          }
        }
      }
    }
    this.router.navigate(['/settings-general', 'center-authentication-details']);
  }
  sortProperties(obj) {
    // convert object into array
    this.sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        this.sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    this.sortable.sort(function (a, b) {
      var x = a[1].callCenterName.toLowerCase(),
        y = b[1].callCenterName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  removePassword() {
    this.contextService.log("removePassword clicked");
    this.settingsGeneral.removePasswordVis = true;
  }
  deletePassword() {
    this.contextService.log("deletePassword clicked")
    this.settingsGeneral.defaultPassword = "";
    this.settingsGeneral.defaultPasswordToCheck = "";
    this.contextService.objSettings.defaultPasswordExist = false;
    this.settingsGeneral.removePasswordVis = false;
    this.settingsGeneral.defaultPasswordDeleteButton = false;
    this.contextService.objSettings.showInPersonalWBList = [];
  }
}
