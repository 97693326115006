import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from "app/providers/settings/settings-general/settings-general.service";
import { ContextService } from "../../../../../providers/context.service";
import { CallTransferService } from "../../../../../providers/settings/settings-services/call-transfer.service";
@Component({
  selector: 'app-call-transfer',
  templateUrl: './call-transfer.component.html',
  styleUrls: ['./call-transfer.component.scss']
})
export class CallTransferComponent implements OnInit {
  description: string = "";
  allowCallTransferRecallText: string = "";
  numberOfRingsText: string = "";
  allowBusyCampText: string = "";
  campCallsText: string = "";
  diversionInhibitorBlindTransferText: string = "";
  diversionInhibitorConsultativeCalls: string = "";
  allowWarmTransferText: string = "";
  allowWarmTransferToVoicemailText: string = "";
  surveyExtensionText: string = "";
  disbleCampCalls: boolean = false;

  constructor(public settingsGeneral:SettingsGeneral,public contextService: ContextService, public callTransferService: CallTransferService) { }

  ngOnInit() {
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.Description');
    this.allowCallTransferRecallText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.AllowRecall');
    this.numberOfRingsText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.NumberOfRingsBeforeRecall');
    this.allowBusyCampText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.AllowBusyCampOn');
    this.campCallsText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.CampCallsDuration');
    this.diversionInhibitorBlindTransferText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.UseDiversionInhibitorForBlindTransfer');
    this.diversionInhibitorConsultativeCalls = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.UseDiversionInhibitorForConsultativeCalls');
    this.allowWarmTransferText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.AllowWarmTransfer');
    this.allowWarmTransferToVoicemailText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.AllowTransferToVoicemail');
    this.surveyExtensionText = this.contextService.getLanguageTagByKey('Settings.Services.CallTransfer.SurveyExtension');
    this.disableCampCallsField();
    this.callTransferService.calltransferChanges=true;
    this.settingsGeneral.settingsChanged=true;
  }
  disableCampCallsField() {
    if (this.callTransferService.allowCallTransferRecall) {
      if (this.callTransferService.allowBusyCampOn) {
        this.disbleCampCalls=false;
      } else{
        this.disbleCampCalls=true;
      }
    } else{
      this.disbleCampCalls=true;
    }
  }
}
