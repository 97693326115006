
import { Component, HostListener, OnInit } from '@angular/core';
import { ContextService } from "../../providers/context.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  partnerLogoPath: string = "";
  partnerLogoUrl: string = "";
  urlPresent: boolean = false;

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    if(document.getElementById('headerTest')){
      this.contextService.changeChatHeight.next(document.getElementById('headerTest').offsetHeight);
    }
  }

  constructor(private contextService: ContextService) {
    this.partnerLogoPath = "";
    this.partnerLogoUrl = "";
  }
  logo_Click() {
    this.contextService.log("start method: logo_Click()");
    if ((this.contextService.isElectron === true) && (this.partnerLogoUrl !== "")) {

      this.contextService.electronService.ipcRenderer.send('load-url', this.partnerLogoUrl);
    }
    this.contextService.log("End method: logo_Click()");

  }
  ngOnInit() {

    this.contextService.imConnectionLostModalVis.subscribe(val => {
      if(document.getElementById('headerTest')){
        this.contextService.changeChatHeight.next(document.getElementById('headerTest').offsetHeight);
      }
    });

    this.contextService.resizeChatHeight.subscribe(val => {
      if(document.getElementById('headerTest')){
        this.contextService.changeChatHeight.next(document.getElementById('headerTest').offsetHeight);
      }
    });
    
    if (this.contextService.isFirstTimeLogin !== true) {

      if (this.contextService.objLoggedUserDetails.getlogoPath() !== "") {
        this.partnerLogoPath = this.contextService.objLoggedUserDetails.getlogoPath();
      } else {
        this.partnerLogoPath = "./assets/img/default_branding.png"
      }
      if (this.contextService.objLoggedUserDetails.getlogoUrl() !== "") {
        this.partnerLogoUrl = this.contextService.objLoggedUserDetails.getlogoUrl();
        this.urlPresent = true;
        if (this.contextService.isElectron === true) {
          this.urlPresent = false;
        }
      }
    }
  }
}

