
export class BaseComponent {
    private isBlocked : boolean;

    private isVisible : boolean;

    constructor() {
    }
}
BaseComponent["__class"] = "components.BaseComponent";



