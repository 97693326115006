import { ChatConversation } from '../../model/chat-conversation.model';
import { ContextService } from '../../providers/context.service';
import { ResponseHandler } from '../../shared/ResponseHandler';


/**
 * to receive call forward busy reponse xml and to parse it
 * 
 * @export
 * @class IMMessageResponseHandler.
 * @implements {ResponseHandler}
 */
export class IMMessageResponseHandler implements ResponseHandler {

    type = "IMMessage";
    senderName: string = "";
    messageReceipients: string[] = [];
    messageReceipientsExceptMe: string = "";
    senderNames: string[] = [];
    constructor(private contextService: ContextService) {
        this.contextService.log('###### constructor - IMMessageResponseHandler');
         this.contextService.isChatOpen = this.contextService.objSettings.chat_openStatus;
         this.contextService.chatCollapsed=false;
    }
    /**
     * to parse the response xml.
     * 
     * @param {Document} xmlResponse
     * 
     * @memberOf IMMessageResponseHandler
     */
    handle(xmlResponse: Document): void {
        this.contextService.log("****** handle - IMMessage");

        let id: string = "", dateTime: string, extensions = [], sender: string = "", message: string = "";
        this.messageReceipients = [];
        this.senderNames = [];
        extensions = [];
        let noNewChat: boolean = false;
        if (xmlResponse.getElementsByTagName("id").length > 0) {
            id = xmlResponse.getElementsByTagName("id")[0].textContent;
        }
        if (xmlResponse.getElementsByTagName("dateTime").length > 0) {
            dateTime = xmlResponse.getElementsByTagName("dateTime")[0].textContent;
            this.contextService.log(dateTime + "datetym");
        }
        if (xmlResponse.getElementsByTagName("extensions").length > 0) {
            const tempStr = this.contextService.appendDomain(xmlResponse.getElementsByTagName("extensions")[0].textContent);
            if (tempStr) {
                let array = tempStr.split(',');
                for (let i = 0; i < array.length; i++) {
                    extensions.push(array[i]);
                }
            }
        }
        if (xmlResponse.getElementsByTagName("sender").length > 0) {
            sender = this.contextService.appendDomain(xmlResponse.getElementsByTagName("sender")[0].textContent);
        }
        if (xmlResponse.getElementsByTagName("message").length > 0) {
            message = (xmlResponse.getElementsByTagName("message")[0].textContent);
        }

        this.senderName = this.getName(sender);
        if (extensions.length > 0) {
            const loginUserIndex = extensions.indexOf(this.contextService.objLoggedUserDetails.getUserId());
            extensions.splice(loginUserIndex, 1);
            extensions.forEach(element => {
                this.senderNames.push( this.getName(element));
            });
            extensions.push(sender);
            this.senderNames.push( this.getName(sender));
        }
        this.contextService.log(extensions.toString());
        this.contextService.websocketService.setNotification(true,  this.getName(sender), message);
        //check whether there is a chat with same message id
        let isMatch = false;
        let convExist = false;
        this.contextService.conversations.forEach(conversationsElement => {

            if (conversationsElement.id.toString() === id) {
                isMatch = true;
                convExist = true;
            }
        });
        if (!convExist) {
            this.contextService.deletedConversations.forEach(element => {
                if (extensions.length == 1) {
                    if (element.people.length === 1) {
                        let deletedMessageID = element.id.toString().substring(0, element.id.toString().lastIndexOf("_"));
                        let incomingMessageID = id.substring(0, id.lastIndexOf("_"));
                        if (deletedMessageID === incomingMessageID) {
                            this.contextService.conversations.push(element);
                            this.contextService.active = "";
                            let index: number = this.getConversationKeyById(element.id);
                            if (index > -1) {
                                this.contextService.deletedConversations.splice(index, 1);
                            }

                        }

                        else {
                            element.people.forEach(people => {

                                if (people.id == sender) {
                                    this.contextService.conversations.push(element);
                                    this.contextService.active = "";
                                    let index: number = this.getConversationKeyById(element.id);
                                    if (index > -1) {
                                        this.contextService.deletedConversations.splice(index, 1);
                                    }
                                }
                            });
                        }


                    }
                }
            });
        }
        //check whether there is a single chat with same sender
        if (!isMatch) {
            this.contextService.conversations.forEach(conversationsElement => {
                if (conversationsElement.people.length === 1 && extensions.length === 1) {
                    conversationsElement.people.forEach(element => {
                        if (element.id === sender) {
                            isMatch = true;
                            id = conversationsElement.id.toString();
                        }
                    });
                    this.contextService.log(conversationsElement.people[0].id);
                }
                else {

                    let count = extensions.length;
                    let i = 0;
                    conversationsElement.people.forEach(element => {
                        if (extensions.includes(element.id)) {
                            i++;
                        }
                    });
                    if (count === i && count === conversationsElement.people.length) {
                        id = conversationsElement.id.toString();
                        isMatch = true;
                        return;
                    }
                }
            });
        }

        // creates a new chat
        if (!isMatch) {
            noNewChat = true;
            const people = [];
            extensions.forEach(element => {
                const obj = { "id": element, "name":  this.getName(element) };
                people.push(obj)
            });
            if (this.contextService.conversations.length > 0) {
                const objChatConversation = new ChatConversation({
                    "id": id, "people": people, "headline": this.senderNames.toString(), "unread": 0, "offline": false, "message": message
                });
                this.contextService.conversations.push(objChatConversation);

            } else {
                const objChatConversation = new ChatConversation({
                    "id": id, "people": people, "headline": this.senderNames.toString(), "unread": 0, "offline": false, "message": ""
                });
                this.contextService.conversations.push(objChatConversation);

            }
        }

        //pushes the message to message array and shown


        this.contextService.conversations.forEach(element => {

            if (element.id.toString() === id) {

                element.people.forEach((elementPeople, index) => {

                    if (!extensions.includes(elementPeople.id)) {
                        element.people.splice(index, 1);
                        this.contextService.messageArray.push({
                            dateTime: Date.now(),
                            headline: this.contextService.getLanguageTagByKey('Conversation.System'),
                            id: id,
                            itsMe: false,
                            person: [99],
                            text: (elementPeople.name + " " + this.contextService.getLanguageTagByKey('Conversation.HasLeft')).fontcolor("red")
                        });
                        const update = { 'conversationId': id, update: { 'headline': this.senderNames.toString() } };
                        this.contextService.conversationHeaderChange.next(update);
                    }
                });

            }
        });

        if (!noNewChat) {
            let tempExtensions = [];
            this.contextService.conversations.forEach(element => {

                if (element.id.toString() === id && element.people.length > 0) {

                    element.people.forEach(elementPeople => {

                        tempExtensions.push(elementPeople.id);
                    });

                    extensions.forEach(elementExtensions => {

                        if (!tempExtensions.includes(elementExtensions)) {

                            this.contextService.messageArray.push({
                                dateTime: Date.now(),
                                headline: this.contextService.getLanguageTagByKey('Conversation.System'),
                                id: id,
                                itsMe: false,
                                person: [99],
                                text:  this.getName(elementExtensions) + " " + this.contextService.getLanguageTagByKey('Conversation.HasJoined')
                            });

                            const obj = { "id": elementExtensions, "name":  this.getName(elementExtensions) };
                            element.people.push(obj);
                            const update = { 'conversationId': id, update: { 'headline': this.senderNames.toString() } };
                            this.contextService.conversationHeaderChange.next(update);
                        }
                    });
                }
            });
        }

        this.contextService.messageArray.push({
            dateTime: Date.now(),
            headline: this.senderName,
            id: id,
            itsMe: false,
            person: [99],
            text: this.contextService.replaceURLWithHTMLLinks(message)
        });

        this.contextService.log(this.contextService.messageArray);
        if (this.contextService.active === id) {
            this.contextService.messages = [];
            this.contextService.messageArray.forEach(element => {
                if (element.id === id.toString() && element.text.length > 0) {
                    this.contextService.messages.push(element);
                    setTimeout(() => {
                        this.contextService.chatService.scrollToBottom.next(id);
                        if (this.contextService.isChatOpen) {
                            this.contextService.chatFocus.next(id);
                        }
                    }, 100);
                }
            });
        }

        //increases the unopened messages count in call control button
        if (this.contextService.conversations.length > 1) {
            this.contextService.conversations.forEach(element => {
                if ((id === element.id.toString() && this.contextService.active !== id) || (id === element.id.toString() && this.contextService.active == id && !this.contextService.chatWindowStatus)) {

                    element.unread++;
                    if (element.unread === 1 || this.contextService.messageCounter === 0) {
                        this.contextService.messageCounter++;
                    }
                    const update = { 'conversationId': id, update: { 'unread': element.unread, "message": message } };
                    this.contextService.conversationHeaderChange.next(update);
                }
            });

        }
        else {
            this.contextService.messages = [];
            this.contextService.messageArray.forEach(element => {
                if (element.id == id && element.text.length > 0) {
                    this.contextService.messages.push(element);
                }
            });
            if (this.contextService.active !== id && this.contextService.chatWindowStatus) {
                this.activeId(id);
            }
            if (!this.contextService.chatWindowStatus) {
                this.contextService.conversations.forEach(element => {
                    if ((id === element.id.toString() && this.contextService.active !== id) || (id === element.id.toString() && this.contextService.active == id && !this.contextService.chatWindowStatus)) {

                        element.unread++;
                        if (element.unread === 1 || this.contextService.messageCounter === 0) {
                            this.contextService.messageCounter++;
                        }


                        const update = { 'conversationId': id, update: { 'unread': element.unread, "message": message } };
                        this.contextService.conversationHeaderChange.next(update);


                    }
                });
            }
        }
    }
    activeId(id: string) {
        this.contextService.activateId.next(id);
    }
    getConversationKeyById(id: number) {
        let ret = -1;
        let i = 0;
        for (let conv of this.contextService.deletedConversations) {
            if (conv.id == id) {
                ret = i;
            }
            i++;
        }
        return ret;
    }

    getName(userID) {
        let name = this.contextService.getNameFromUserID(userID);
        if (name === userID) {
            return 'Unavailable';
        }
        return name;
    }
}