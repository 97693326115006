<app-header></app-header>
<div
  class="main-container"
  [ngClass]="{ 'chat-collapsed': contextService.chatCollapsed }"
>
  <div class="left-col">
    <app-statistics
      [hidden]="!this.contextService.showPersonalWallboard"
    ></app-statistics>
    <app-drop-call-area></app-drop-call-area>

    <div class="table-tabs">
      <ul class="tabs">
        <li>
          <a
            href="contacts"
            [ngClass]="{ active: selectedTab === 'contacts' }"
            (click)="selectTab($event, 'contacts')"
            >{{ contactsDetails }}</a
          >
        </li>
        <li>
          <a
            href="calllogs"
            [ngClass]="{ active: selectedTab === 'calllogs' }"
            (click)="selectTab($event, 'calllogs')"
            >{{ callLogDetails }}
            <span
              class="bubble"
              *ngIf="
                this.contextService.missedCallCount > 0 &&
                this.contextService.objSettings
                  .appearance_MissedCallCountVisible
              "
              >{{ this.contextService.missedCallCount }}</span
            >
          </a>
        </li>
        <li *ngIf="contextService.voiceMailTabState && this.voicemailActive">
          <a
            href="voicemail"
            [ngClass]="{ active: selectedTab === 'voicemail' }"
            (click)="selectTab($event, 'voicemail')"
            >{{ voicemailDetails }}
            <span
              class="bubble"
              *ngIf="this.contextService.voiceMessageCount > 0"
              >{{ this.contextService.voiceMessageCount }}</span
            >
          </a>
        </li>
        <!-- //supervisor -->
        <li *ngIf="this.showAgentActivityTab === true">
          <a
            href="agentativity"
            [ngClass]="{ active: selectedTab === 'agentactivity' }"
            (click)="selectTab($event, 'agentactivity')"
            >{{ AgentActivityTag }}
          </a>
        </li>
        <!-- *ngIf="this.contextService.missedCallCount > 0" -->
      </ul>
      <div
        class="my-status tooltip"
        #myStatus
        (mouseup)="getStatus()"
        title="{{ myStatusTooltip }}"
        (click)="openCm($event, statuscm)"
      >
        <span class="status-{{ changeFontColor }}">{{ mystatus }}</span>
        <span class="{{ myCurrentStatusIco }}">
          <span class="status-{{ changeFontColor }}">{{
            myCurrentStatus
          }}</span>
        </span>

        <p-contextMenu
          [model]="statusList"
          appendTo="body"
          [target]="myStatus"
          #statuscm
          leftClick="true"
        >
        </p-contextMenu>
      </div>
    </div>
    <div class="table-content">
      <app-contact-table
        [hidden]="selectedTab !== 'contacts'"
      ></app-contact-table>
      <app-call-logs [hidden]="selectedTab !== 'calllogs'"></app-call-logs>
      <app-voicemail [hidden]="selectedTab !== 'voicemail'"></app-voicemail>
      <!-- //supervisor -->
      <app-agent-activity-table
        [hidden]="selectedTab !== 'agentactivity'"
      ></app-agent-activity-table>
    </div>
  </div>

  <!--Chat-->
  <app-chat></app-chat>
</div>

<!--Settings modal-->
<app-settings></app-settings>
<!--Reporting-->
<app-reports></app-reports>
<!--Dial-->
<app-dial></app-dial>
<!--About modal-->
<app-about></app-about>
<!-- Out of office modal-->
<app-out-of-office></app-out-of-office>
<!-- Add personal directory entry modal-->
<app-add-personal-entry></app-add-personal-entry>
<!--calendar modal-->
<app-calendar></app-calendar>

<app-view-user-details></app-view-user-details>
<!--Manage users modal-->
<app-manage-users></app-manage-users>
<!--Start Conversation modal-->
<app-start-conversation></app-start-conversation>
<!-- Close app modal -->
<app-modify-supervised-agent-list></app-modify-supervised-agent-list>

<!-- c8 new types -->
<app-call-back></app-call-back>
<app-web-chat></app-web-chat>
<app-twitter></app-twitter>
<app-email></app-email>
<app-sms></app-sms>
<app-upload-progress></app-upload-progress>

<p-dialog
  [resizable]="false"
  [(visible)]="imConnectionLostVis"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: imConnectionLostVis }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <p-header>
    <h4 class="modal-title">Unity</h4>
  </p-header>
  <div class="modal-content">
    <p>{{ this.imMessage }}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="closeChat()">
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="imConnectionLimitVis"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: imConnectionLimitVis }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <p-header>
    <h4 class="modal-title">Unity</h4>
  </p-header>
  <div class="modal-content">
    <p>{{ connectionPrompt }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="imConnectionLimitVis = false"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="broadworksErrorVis"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: broadworksErrorVis }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <p-header>
    <h4 class="modal-title">Unity</h4>
  </p-header>
  <div class="modal-content">
    <p>{{ this.broadworksError }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="this.broadworksErrorVis = false"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="this.contextService.privacyErrorVis"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: this.contextService.privacyErrorVis }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <p-header>
    <h4 class="modal-title">Unity</h4>
  </p-header>
  <div class="modal-content">
    <p>{{ this.privacyAlert }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="this.privacyAlertOkClick()"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="this.callExtensionAvailableAlertVis"
  [closable]="false"
  [style]="{ width: '470px' }"
  [ngClass]="{ visible: this.callExtensionAvailableAlertVis }"
  class="dialog-window buttons-2"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ this.callExtensionAvailableAlertText }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="this.callExtension()"
      >
        {{ okBtn }}
      </button>
      <button
        type="button"
        class="btn-white"
        (click)="this.callExtensionAvailableAlertOkClick()"
      >
        {{ miscNo }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="noServiceAlert"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: noServiceAlert }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <p-header>
    <h4 class="modal-title">Unity</h4>
  </p-header>
  <div class="modal-content">
    <p>{{ this.showAlert }}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="hideAlert()">
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>
<p-dialog
  [resizable]="false"
  [(visible)]="this.contextService.licenceExpireState"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: this.contextService.licenceExpireState }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ this.contextService.getlicenseExpiryPrompt }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="this.contextService.licenceExpireState = false"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<!-- <p-dialog [resizable]="false" [(visible)]="dispositionCodeAlert" [closable]="false" width="380" [ngClass]="{visible:dispositionCodeAlert}" class="small-dialog out-of-office-dialog"
dismissableMask="true" showHeader=true>

<p-header>
  <div class="container-center">
    <h4 class="modal-title">{{this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode')}}</h4>
  </div>
</p-header>
<div class="modal-content">
  <p>{{this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode')}}</p>
  <div>
    <button type="button" class="btn-blue-light" (click)="this.dispositionCodeAlert = false">{{this.contextService.getLanguageTagByKey('CallButtonShowing.OK')}}</button>

  </div>
</div>
</p-dialog> -->

<!-- bifin -->
<p-dialog
  [resizable]="false"
  [(visible)]="dispositionCodeAlert"
  [ngClass]="{ visible: dispositionCodeAlert }"
  [closable]="false"
  [style]="{ width: '380px' }"
  class="small-dialog"
  dismissableMask="true"
  showHeader="true"
>
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{ dispositionCode }}{{ timer }}</h4>
      <span class="close-modal small">X</span>
    </div>
  </p-header>

  <div class="modal-content">
    <div class="row">
      <label for="" class="label">Code</label>
      <p-dropdown
        [options]="dispositionCodeArray"
        name="ddl_disposition"
        appendTo="body"
        [style]="{ width: '100%' }"
        [(ngModel)]="this.selectedDipositionCode"
        placeholder="{{ dispositionPlaceholder }}"
      ></p-dropdown>
    </div>
    <div class="row" style="text-align: right">
      <button
        type="button"
        class="btn-blue-light"
        (click)="forceDispositionCodeSubmit()"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="dispositionCodeNotSetAlert"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: dispositionCodeNotSetAlert }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ dispositionNotset }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="dispositionCodeNotSetAlert = false"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>
<!-- bifin -->

<!-- phase 2 -->
<!-- <p-dialog [(visible)]="statusVis" [closable]="false" width="370" [ngClass]="{visible: statusVis}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>Would you like to clear your current '{{currentIMStatus}}' status?</p>
    <div>
      <button type="button" class="btn-white" (click)="statusVis = false">No</button>
      <button type="button" class="btn-blue-light"  (click)="removeIMStatus()">Yes</button>
    </div>
  </div>
</p-dialog> -->

<p-dialog
  [resizable]="false"
  autoZIndex="true"
  class="dialog-window buttons-1"
  dismissableMask="true"
  [(visible)]="this.isIncorrectPassword"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: this.isIncorrectPassword }"
>
  <div class="modal-content" style="text-align: center">
    <div>
      <b>
        {{ callCenterName }}
      </b>
    </div>
    <p>{{ callCenterError }}</p>
    <div>
      <button
        type="button"
        style="width: 100%"
        class="btn-blue-light"
        (click)="this.checkForCallCenterFailedAlert()"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>

<p-dialog
  [resizable]="false"
  [(visible)]="showDnisError"
  [closable]="false"
  [style]="{ width: '370px' }"
  [ngClass]="{ visible: showDnisError }"
  class="dialog-window buttons-1"
  dismissableMask="true"
  showHeader="false"
>
  <div class="modal-content">
    <p>{{ dnisError }}</p>
    <div>
      <button
        type="button"
        class="btn-blue-light"
        (click)="showDnisError = false"
      >
        {{ okBtn }}
      </button>
    </div>
  </div>
</p-dialog>
