import { PersonalDirectory } from './personal-directory';
import { CommonPhoneList } from './common-phone-list';
import { BwDirectoryEntry } from './bw-directory-entry';
import { OutlookDirectoryEntry } from "./outlook-directory";
/**
 * model class to show the contacts in datatable
 * 
 * @export
 * @class DirectoryView
 */
export class DirectoryView {
    id: number;
    icon: string = '';
    userId: string = '';
    name: string = '';
    phone: string = '';
    fullNumber: string = '';
    extension: string = '';
    mobile: string = '';
    email: string = '';
    department: string = '';
    status: string = '';
    isOnline: boolean = false;
    isMonitor: boolean = false;
    type: string = '';
    title: string = '';
    groupId: string = '';
    ACDState: string = '';
    companyName: string = "";
    location:string="";
    constructor(isOnline: boolean, icon: string, userId: string, name: string, phone: string, fullNumber: string, extension: string, mobile: string, email: string, department: string, groupId: string, status: string, isMonitor: boolean, title: string, type: string, companyName: string, location:string) {
        this.icon = icon;
        this.userId = userId;
        this.name = name;
        this.phone = phone;
        this.fullNumber = fullNumber;
        this.extension = extension;
        this.mobile = mobile;
        this.email = email;
        this.department = department;
        this.groupId = groupId;
        this.status = status;
        this.isOnline = isOnline;
        this.isMonitor = isMonitor;
        this.type = type;
        this.title = title;
        this.companyName = companyName;
        this.location=location;
    }
    /**
     * converts a pensonal directry entry to a DirectoryView entry
     * 
     * @static
     * @param {PersonalDirectory} personalDirectory 
     * @returns {DirectoryView} 
     * @memberof DirectoryView
     */
    static getPersonalDirectoryView(personalDirectory: PersonalDirectory): DirectoryView {
        return new DirectoryView(false, 'external-nonBW', '', personalDirectory.getName(), personalDirectory.getNumber(), '', '', '', '', '', '', '', false, '', 'Personal Directory', '','');
    }
    /**
     * convert CommonPhoneList entry to DirectoryView entry
     * 
     * @static
     * @param {CommonPhoneList} commonPhoneList 
     * @returns {DirectoryView} 
     * @memberof DirectoryView
     */
    static getCommonDirectoryView(commonPhoneList: CommonPhoneList): DirectoryView {
        return new DirectoryView(false, 'external-nonBW', '', commonPhoneList.getName(), commonPhoneList.getNumber(), '', '', '', '', '', '', '', false, '', 'Common Directory', '','');
    }

    /**
     *convert outlookDirectoryEntry to DirectoryView entry
     *
     * @static
     * @param {OutlookDirectoryEntry} outlookDirectoryEntry
     * @returns {DirectoryView}
     * @memberof DirectoryView
     */
    static getOutlookDirectoryView(outlookDirectoryEntry: OutlookDirectoryEntry): DirectoryView[] {
        let temp = [];

        if (outlookDirectoryEntry.getBusinessPhone().length > 0) {
            outlookDirectoryEntry.getBusinessPhone().forEach((element, index) => {
                temp.push(new DirectoryView(false, 'external-nonBW', '', outlookDirectoryEntry.getDisplayName() + " (Business)", element, '', '', outlookDirectoryEntry.getMobile(), outlookDirectoryEntry.getEmailAddress(), outlookDirectoryEntry.getDepartment(), '', '', false, outlookDirectoryEntry.getTitle(), 'Outlook Directory', outlookDirectoryEntry.getCompanyName(),''));
            });
        }

        if (outlookDirectoryEntry.getHomePhone().length > 0) {
            outlookDirectoryEntry.getHomePhone().forEach((element, index) => {
                temp.push(new DirectoryView(false, 'external-nonBW', '', outlookDirectoryEntry.getDisplayName() + " (Home)", element, '', '', outlookDirectoryEntry.getMobile(), outlookDirectoryEntry.getEmailAddress(), outlookDirectoryEntry.getDepartment(), '', '', false, outlookDirectoryEntry.getTitle(), 'Outlook Directory', outlookDirectoryEntry.getCompanyName(),''));
            });
        }
        if (outlookDirectoryEntry.getMobile().length > 0) {
            temp.push(new DirectoryView(false, 'external-nonBW', '', outlookDirectoryEntry.getDisplayName() + " (Mobile)", outlookDirectoryEntry.getMobile(), '', '', outlookDirectoryEntry.getMobile(), outlookDirectoryEntry.getEmailAddress(), outlookDirectoryEntry.getDepartment(), '', '', false, outlookDirectoryEntry.getTitle(), 'Outlook Directory', outlookDirectoryEntry.getCompanyName(),''));

        }
        // temp.push(new DirectoryView(false, 'external-nonBW', '', outlookDirectoryEntry.getDisplayName(), outlookDirectoryEntry.getMobile(), '', '', outlookDirectoryEntry.getMobile(), outlookDirectoryEntry.getEmailAddress(), outlookDirectoryEntry.getDepartment(), '', '', false, outlookDirectoryEntry.getTitle(), 'Outlook Directory'));
        return temp;
    }

    /**
     * convert BwDirectoryEntry to DirectoryView entry
     * 
     * if BwDirectoryEntry has mobile number then it is added as two entries
     * 
     * @static
     * @param {BwDirectoryEntry} bwDirectoryEntry 
     * @param {boolean} isMobile 
     * @returns {DirectoryView} 
     * @memberof DirectoryView
     */
    static getBwDirectoryView(bwDirectoryEntry: BwDirectoryEntry, isMobile: boolean): DirectoryView {
        if (!isMobile) {
            return new DirectoryView(bwDirectoryEntry.getOnline(), 'external', bwDirectoryEntry.getUserId(), this.nameIgnore(bwDirectoryEntry), bwDirectoryEntry.getNumber().length > 0 ? bwDirectoryEntry.getNumber() : bwDirectoryEntry.getExtension(),
                bwDirectoryEntry.getNumber(), bwDirectoryEntry.getExtension(), bwDirectoryEntry.getMobile(), bwDirectoryEntry.getEmailAddress(), bwDirectoryEntry.getDepartment(), bwDirectoryEntry.getGroupId(), '', bwDirectoryEntry.getisMonitored(), bwDirectoryEntry.gettitle(), 'Broadworks Directory', '',bwDirectoryEntry.getLocation());
        } else {
            return new DirectoryView(bwDirectoryEntry.getOnline(), 'external', bwDirectoryEntry.getUserId(), this.nameIgnore(bwDirectoryEntry) + " (Mobile)", bwDirectoryEntry.getMobile(), bwDirectoryEntry.getNumber(), bwDirectoryEntry.getExtension(), bwDirectoryEntry.getMobile(), bwDirectoryEntry.getEmailAddress(), bwDirectoryEntry.getDepartment(), bwDirectoryEntry.getGroupId(), '', false, '', 'Broadworks Directory','',bwDirectoryEntry.getLocation());
        }
    }

    /**
     * checks if name to display contains unwanted firstnames and returns the appropriate name
     * 
     * @static
     * @param {BwDirectoryEntry} entry
     * @returns {string}
     * @memberof DirectoryView
     */
    static nameIgnore(entry: BwDirectoryEntry): string {
        // let match: boolean = (!entry.getFirstName().includes("Flexible Seating Guest") && !entry.getFirstName().includes("Call Center") && !entry.getFirstName().includes("Hunt Group") && !entry.getFirstName().includes("Trunk Group")
        //     && !entry.getFirstName().includes("Meet-Me Conference") && !entry.getFirstName().includes("Auto Attendant") && !entry.getFirstName().includes("Meet-Me Conferencing")
        //     && !entry.getFirstName().includes("BroadWorks Anywhere") && !entry.getFirstName().includes("Voice Messaging Group") && !entry.getFirstName().includes("Group Paging")
        //     && !entry.getFirstName().includes("Instant Group") && !entry.getFirstName().includes("Voice Messaging Group") && !entry.getFirstName().includes("Group Paging"));
        let contactsThatCannotBeMonitored = ['broadworks anywhere', 'instant conferencing', 'instant group call', 'trunk group', 'voice portal',
        'auto attendant', 'call center', 'collaborate - audio', 'flexible seating guest', 'group paging',
        'hunt group', 'hunt gruppe', 'meet-me conferencing', 'voicexml','auto attendant - basic','auto attendant - standard'];
        if (contactsThatCannotBeMonitored.includes(entry.getFirstName().toLowerCase())) {
            return entry.getLastName();
        }
        return entry.getFullName();
    }
}