import { ResponseHandler } from '../../../shared/ResponseHandler';
import { ResponseRouter } from '../../../shared/ResponseRouter';

import { ContextService } from '../../../providers/context.service';
import { ACDAgentUnavailableCodesResponseHandler } from "./acd-agent-unavailablecodes-response-handler";
import { AgentCallCenterListResponseHandler } from "./agent-callcenter-list-response-handler";

export class GetAgentConfigurationResponseHandler extends ResponseRouter implements ResponseHandler {

    type = "GetAgentConfigurationResponse";


    constructor(private contextService: ContextService) {
        super();

        this.register(new ACDAgentUnavailableCodesResponseHandler(contextService));
        this.register(new AgentCallCenterListResponseHandler(contextService));
    }

    handle(xmlResponse: Document): void {


        if (xmlResponse.getElementsByTagName("ACDAgentUnavailableCodes").length > 0) {
            this.responseHandlersList["ACDAgentUnavailableCodes"].handle(xmlResponse);
        } else if (xmlResponse.getElementsByTagName("CallCenter").length > 0) {
            this.responseHandlersList["CallCenter"].handle(xmlResponse);
        }
    }
}

