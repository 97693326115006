import { Component, OnInit, Input, OnChanges, ViewEncapsulation, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { Attachment } from '../../model/attachment.model'
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AttachmentComponent implements OnInit {

  @Input() attachment: Attachment;
  menu: MenuItem[];

  constructor(public contextService: ContextService) { }

  ngOnInit() {
    console.log(this.attachment);

    this.menu = [
      {
        label: 'Open',
        icon: 'file-open',
        url: this.attachment.url,
        target: '_blank'
      },
      { separator: true },
      {
        label: 'Save as',
        icon: 'file-save',
        command: () => {
          alert('Save')
        }
      },
      {
        label: 'Save all attachments',
        icon: 'file-save-all',
        command: () => {
          alert('Save all')
        }
      },
      { separator: true },
      {
        label: 'Remove attachment',
        icon: 'file-remove',
        command: () => {
          alert('Remove')
        }
      },
      {
        label: 'Remove all attachments',
        icon: 'file-remove-all',
        command: () => {
          alert('Remove all')
        }
      },

    ];
  }

  save() {
    alert('downloading')
  }
}
