import { Component, enableProdMode, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/takeUntil';
import { timer } from 'rxjs';
import { Subject, Subscription } from 'rxjs';
import { TagDispositionCodeResponseHandler } from "./broadworks/cti/agent/tag-disposition-code-response-handler";
import { BwDirectoryEntry } from './model/directory/bw-directory-entry';
import { ContextService } from './providers/context.service';
import { DataService } from './providers/dataservice.service';
import { DirectoryService } from './providers/directory.service';
import { HelperService } from './providers/helper.service';
import { MessagesService } from './providers/messages.service';
//c8
import { Attachment } from './model/attachment.model';

enableProdMode();
/**
 * contains all the other components 
 * 
 * @export
 * @class AppComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, OnDestroy {
  selectedTab: string = '';
  myCurrentStatus: string = this.contextService.getLanguageTagByKey('Misc.None');
  myCurrentStatusIco: string = 'ico-available';
  statusList: MenuItem[] = [];
  imConnectionLostVis: boolean = false;
  imConnectionLimitVis: boolean = false;
  mystatus: string = this.contextService.getLanguageTagByKey('Menu.MyStatus');
  statusVis: boolean = false;
  myStatusTooltip: string = this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip');
  currentIMStatus: string = '';
  changeFontColor: string = "";
  imMessage: string = "";
  broadworksError: string = "";
  broadworksErrorVis: boolean = false;
  noServiceAlert: boolean = false;
  showAlert: string = '';
  firstTimeShowAlert: boolean = false;
  flexibleServiceIsActive: boolean = false;
  voicemailActive: boolean = false;
  dispositionCodeAlert: boolean = false;
  dispositionCodeArray: any[] = [];
  isAssigningDispositionCode: boolean = false;
  selectedDipositionCode: string = "";
  dispositionCodeNotSetAlert: boolean = false;
  // previousACDState: string = "";
  // previousUnavailableCode: string = "";
  isSubscription: boolean = false;
  sub: Subscription;
  timer: string = "";
  contactsDetails: string = "";
  callLogDetails: string = "";
  voicemailDetails: string = "";
  okBtn: string = "";
  connectionPrompt: string = "";
  callBtnOK: string = "";

  restart: string = "";
  dispositionCode: string = "";
  dispositionPlaceholder: string = "";
  dispositionNotset: string = "";

  isIncorrectPassword: boolean = false;
  callCenterError: string = "";
  callCenterName: string = "";

  showDnisError: boolean = false;
  dnisError: string = "";

  // privacyErrorVis: boolean = false;
  privacyAlert: string = "";

  callExtensionAvailableAlertVis: boolean = false;
  callExtensionAvailableAlertText: string = "";
  activeCallExtensionId: string = "";
  miscNo: string = "";
  miscYes: string = "";
  unAssignedServicesList = [];

  AgentActivityTag: string = "";
  showAgentActivityTab: boolean = false;
  // unsubscribe observables -> https://stackoverflow.com/questions/38008334/angular-rxjs-when-should-i-unsubscribe-from-subscription
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private dataService: DataService,
    private helper: HelperService, public contextService: ContextService, public directoryService: DirectoryService,
    public messagesService: MessagesService) {
    this.contextService.log('###### constructor - AppComponent');

    this.helper.closeModal();

    this.contextService.callCenterListAlert.subscribe((val) => {
      if (this.contextService.objSettings.STARTUP === 3 && this.contextService.objLoggedUserDetails.isSupervisorCallCenterExist() && !this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
        this.showAgentActivityTab = true;
      }
    });

    this.contextService.showIM.subscribe((val) => {

      if (val === "checkIM" || val === "clearAdHocStatus") {
        this.setIMStatusToolTip();
      }
      else if (val === "checkDND" || val === "removeDND") {
        this.setIMStatusToolTip();
      }
      else if (val === "checkCFA" || val === "removeCFA") {
        this.setIMStatusToolTip();
      }
      else if (val === "checkRemoteOffice" || val === "removeRemoteOffice") {
        this.setIMStatusToolTip();
      }
      else if (val === "commPilotActive") {
        this.setIMStatusToolTip();
      }
    });

    this.contextService.updateTabSelection.subscribe((tab) => {
      // this.selectedTab = 'contacts';
      this.selectedTab = tab;
      this.helper.selectedTableTab = this.selectedTab;
      this.helper.tableTabChanged.next(true);
      if (tab === 'agentactivity' && this.contextService.objSettings.STARTUP === 3) {
        this.helper.recalcagentactivityHeight.next(true);
      }
    });

    // c8  - file uploads on paste
    this.listenForPaste()

  }

  // c8  - file uploads on paste
  listenForPaste() {
    document.addEventListener('paste', function (e) {
      this.handlePaste(e);
    }.bind(this), false);
  };

  // c8  - file uploads on paste
  handlePaste(e) {

    var stopPropagation = false
    const items = (e.clipboardData || e.originalEvent.clipboardData).items;

    if (items.length) {

      let blob = null;
      var attachments: Array<Attachment> = [];

      for (const item of items) {
        // c8 upload file here or something ...
        blob = item.getAsFile();
        if (blob && blob.name) {

          var att = this.messagesService.uploadFile(blob)
          attachments.push(att);

          //attachments.push(new Attachment(0, blob.name, Date.now(), 99, 'https://image.shutterstock.com/image-photo/word-demo-appearing-behind-torn-260nw-1782295403.jpg', blob.size));
          /*
          blob = 
          lastModified: 1633432336317
          lastModifiedDate: Tue Oct 05 2021 13:12:16 GMT+0200 (Středoevropský letní čas) {}
          name: "2560px-DPD_logo_(2015).svg.png"
          size: 88931
          type: "image/png"
          webkitRelativePath: "
          */
          this.messagesService.uploadFileOnPaste.next(attachments);
          stopPropagation = true;
        }
      }
    }

    if (stopPropagation) {
      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();
    }

  }

  /**
   * initialization of appcomponent
   * 
   * @memberof AppComponent
   */
  ngOnInit() {

    this.contactsDetails = this.contextService.getLanguageTagByKey('TabShowing.Contacts');
    this.callLogDetails = this.contextService.getLanguageTagByKey('TabShowing.CallLog');
    this.voicemailDetails = this.contextService.getLanguageTagByKey('TabShowing.Voicemail');
    this.okBtn = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.connectionPrompt = this.contextService.getLanguageTagByKey('Prompt.IM.ConnectionFailed');
    this.callBtnOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.dispositionCode = this.contextService.getLanguageTagByKey('Menu.Context.DispositionCode');
    this.dispositionPlaceholder = this.contextService.getLanguageTagByKey('Prompt.DispositionCode.ForceMe.PlaceHolder');
    this.dispositionNotset = this.contextService.getLanguageTagByKey('Prompt.DispositionCode.NotSet');

    this.callCenterError = this.contextService.getLanguageTagByKey('Prompt.Login.CallCenterFailed');

    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');

    this.AgentActivityTag = this.contextService.getLanguageTagByKey('TabShowing.AgentActivity');

    this.statusList = this.dataService.myStatusMenuItems;
    this.selectedTab = this.helper.selectedTableTab;

    this.contextService.broadworksErrorModalVis.subscribe(val => {
      // if (window.location.href === "https://desktop.unityclient.com/" || window.location.href === "https://desktop.unityclient.com" || window.location.href === "https://agent.unityclient.com/" || window.location.href === "https://agent.unityclient.com") {
      if (!this.contextService.isSignedOut) {
        if (!this.broadworksErrorVis) {
          this.broadworksErrorVis = true;
        }
        this.broadworksError = val;
      }
      // }

    });

    this.contextService.privacyErrorModalVis.subscribe(val => {
      if (!this.contextService.isSignedOut) {
        if (this.contextService.objSettings.contact_privacyEnabled) {
          if (!this.contextService.privacyErrorVis) {
            this.contextService.privacyErrorVis = true;
          }
          this.privacyAlert = val;
        }
      }

    });


    this.contextService.voicemailActive.subscribe(val => {
      this.voicemailActive = val;
    });

    this.contextService.callCenterLoginFailed.subscribe(val => {
      this.callCenterName = this.contextService.getNameFromUserID(this.contextService.callCenterLoginFailedCollection[0]);
      this.isIncorrectPassword = true;

    });

    this.contextService.noServiceModalVis.subscribe(val => {
      if (!this.contextService.isSignedOut) {
        this.showAlert = "";
        this.unAssignedServicesList = [];
        if (!this.contextService.assignedServices.includes('Three-WayCall')) {
          this.unAssignedServicesList.push("Three-WayCall");
        }
        if (!this.contextService.assignedServices.includes('CallTransfer')) {
          this.unAssignedServicesList.push("CallTransfer");
        }
        if (!this.contextService.assignedServices.includes('InternalCallingLineIDDelivery') && !this.contextService.assignedServices.includes('CallingNumberDelivery')) {
          this.unAssignedServicesList.push("InternalCallingLineIDDelivery");
        }
        if (!this.contextService.assignedServices.includes('ExternalCallingLineIDDelivery') && !this.contextService.assignedServices.includes('CallingNumberDelivery')) {
          this.unAssignedServicesList.push('ExternalCallingLineIDDelivery');
        }
        if (!this.contextService.assignedServices.includes('BasicCallLogs') && !this.contextService.assignedServices.includes('EnhancedCallLogs')) {
          this.unAssignedServicesList.push('NoCallLogs');
        }
        this.hideAlert();

      }
    });
    this.helper.closeModalVis.subscribe(val => {
      this.contextService.electronService.ipcRenderer.send('closeUnity');
    });
    this.helper.statusModalVis.subscribe(val => {
      this.statusVis = val;
    });
    this.contextService.imConnectionLostModalVis.subscribe(val => {
      if (!this.contextService.isSignedOut) {
        if (val !== "") {
          if (val === "serverClosed") {
            if (this.contextService.isImConnected) {
              this.imMessage = this.contextService.getLanguageTagByKey("Prompt.Kemp.ServerConnectionLost");
              this.imConnectionLostVis = true;
            }
          }
          else if (val !== "enableMessagingMenu") {
            if (val === 'overridden') {
              this.imConnectionLostVis = true;
              this.imMessage = this.contextService.getLanguageTagByKey('Prompt.Kemp.RegistrationOverridden');
            }
            else if (val !== "closeChatWindow") {
              this.imMessage = val;
            }

          }
          if (!(this.dataService.imService.statusList == null)) {
            if (!(this.dataService.imService.statusList.imStatus === "")) {
              this.setIMStatusToolTip();
            }
          }
        }
      }
    });

    //im connection request limit exceeded
    this.contextService.websocketService.IMconnectionDroped.subscribe(val => {
      if (!this.contextService.isSignedOut) {
        this.imConnectionLimitVis = true;
      }

    });

    this.contextService.hotelingGuestEvent.subscribe((val) => {
      this.contextService.hostID = val.hostUserId;
    });

    this.contextService.flexibleSeatingGuestEvent.subscribe((val) => {
      this.contextService.hostIDFlexibleSeating = val.hostUserId;
      this.flexibleServiceIsActive = val.isActive;
    });

    //Voice mail
    if (this.contextService.objSettings != undefined) {
      this.contextService.voiceMailTabState = this.contextService.objSettings.voiceMailTab_state;

    }

    //call extension when available alert
    this.directoryService.callExtensionAvailableObservable.subscribe((val) => {

      if (!this.callExtensionAvailableAlertVis) {
        this.callExtensionAvailableAlertVis = true;
        this.activeCallExtensionId = val;
        this.callExtensionAvailableAlertText = this.contextService.getNameFromUserID(val) + " " + this.contextService.getLanguageTagByKey("Prompt.DialExtensionWhenFree")
      }
    });
    /**
     * will hit when ACDstate is cahnges 
     * make the dialog box visible and sets timer according to condition
     */
    this.contextService.acdStateUpdate.subscribe((id) => {
      if ((id === this.contextService.objLoggedUserDetails.getUserId()) && (this.isAssigningDispositionCode) && (this.contextService.objSettings.acdState_AssignDispositionCode)) {
        if (this.contextService.agentDetails.state == "Wrap-Up") {
          this.contextService.log("forceDisposition code: alert made visible")
          this.dispositionCodeAlert = true;
          if (this.contextService.objSettings.acdState_DispositionTime.toString() == "") {
            this.timer = "";
          } else {
            this.timerTestSetUp();
          }
        }
      }
    });
    /**
     * will hit when a call center call is released
     */

    //to show dnis error
    this.contextService.showDnisError.subscribe(val => {
      this.dnisError = val;
      this.showDnisError = true;
    });
  }
  /**
   * to load the disposition code list to the binded array
   * send wrapup request if not wrapup
   * take not of previous ACD state
   * 
   *
   * @memberof AppComponent
   */
  forceDisposition() {

  }

  /**
   * on submitting the disposition code 
   * if a code is not set then an alert is shown
   * else
   * dialog box is made invisible, tag disposition code action is called
   * and timer is unsubscribed
   *
   * @memberof AppComponent
   */
  forceDispositionCodeSubmit() {

  }

  /**
   * timer to call the function to update timer in dialog window
   *
   * @memberof AppComponent
   */
  timerTestSetUp() {
    let time = this.contextService.objSettings.acdState_DispositionTime;
    let timer1 = timer(0, 1000);
    if (!this.isSubscription) {
      this.contextService.log("forceDisposition code: timer for force me to asisgn disposition code started");
      this.isSubscription = true;
      this.sub = timer1.subscribe(
        t => {
          this.timerUpdateInDispositionCodeWindow(time);
          time--;
        }
      );
    }
  }

  /**
   * function to unsubscribe the timer
   *
   * @memberof AppComponent
   */
  timerUnsubcribe() {
    this.contextService.log("forceDisposition code: timer for force me to assign disposition code unsubscribed");
    if (!this.sub.closed) {
      this.isSubscription = false;
      this.sub.unsubscribe();
    }
  }

  /**
   * process the time to show in dialog box
   *
   * @param {*} time
   * @memberof AppComponent
   */
  timerUpdateInDispositionCodeWindow(time: any) {

  }
  /**
   * shows CallTransferNotAssigned alert only one time
   * 
   * @memberof AppComponent
   */
  hideAlert() {
    this.showAlert = "";
    let index = 0;
    this.noServiceAlert = false;
    for (index = 0; index < this.unAssignedServicesList.length; index++) {
      if (this.unAssignedServicesList[index] === "Three-WayCall") {
        this.showAlert = this.contextService.getLanguageTagByKey('Prompt.ThreeWayCallNotAssigned');
        break;
      } else if (this.unAssignedServicesList[index] === "CallTransfer") {
        this.showAlert = this.contextService.getLanguageTagByKey('Prompt.CallTransferNotAssigned');
        break;
      } else if (this.unAssignedServicesList[index] === "InternalCallingLineIDDelivery") {
        this.showAlert = this.contextService.getLanguageTagByKey('Prompt.InternalCallingLineIdDeliveryNotAssigned');
        break;
      } else if (this.unAssignedServicesList[index] === "ExternalCallingLineIDDelivery") {
        this.showAlert = this.contextService.getLanguageTagByKey('Prompt.ExternalCallingLineIdDeliveryNotAssigned');
        break;
      } else if (this.unAssignedServicesList[index] === "NoCallLogs") {
        this.showAlert = this.contextService.getLanguageTagByKey('Prompt.BasicOrEnhancedCallLogsNotAssigned');
        break;
      }

    }
    if (this.unAssignedServicesList.length > 0) {
      this.unAssignedServicesList.splice(index, 1);
      this.noServiceAlert = true;
    }
  }
  /**
   * toggles the chat window
   * 
   * @memberof AppComponent
   */
  toggleChat() {

    this.contextService.chatCollapsed = !this.contextService.chatCollapsed;
  }
  /**
   * selection of tabs
   * calllogs,contacts,voicemail
   * 
   * @param {any} e 
   * @param {any} tab 
   * @memberof AppComponent
   */
  selectTab(e, tab) {
    e.preventDefault();
    if (this.selectedTab !== tab) {
      if (this.selectedTab === 'calllogs') {
        this.focusOutFunction();
      } else if (this.selectedTab === 'voicemail') {
        this.focusOutVoiceMail();
      }
    }
    this.selectedTab = tab;
    this.helper.selectedTableTab = this.selectedTab;
    this.helper.tableTabChanged.next(true);
    if (tab === 'calllogs') {
      this.helper.recalcCallLogsHeight.next(true);
      this.contextService.missedCallCount = 0;
      this.contextService.countCall = [];
    }
    if (tab === 'contacts') {
      this.helper.recalcContactsHeight.next(true);
    }
    if (tab === 'voicemail') {
      this.helper.recalcVoicemailHeight.next(true);
      this.contextService.voiceMessageCount = 0;
    }
    //supervisor
    if (tab === 'agentactivity' && this.contextService.objSettings.STARTUP === 3) {
      this.helper.recalcagentactivityHeight.next(true);
    }
  }
  /**
   * unsbscribe on destroy
   * 
   * @memberof AppComponent
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  /**
   * send broadworks request to set selected device as host device
   * 
   * @param {any} userID 
   * @memberof AppComponent
   */
  hotelingGuestAction(userID) {

  }

  flexibleSeatingGuestAction(userID) {

  }
  /**
   * send broadworks request to set selected device as None 
   * 
   * @memberof AppComponent
   */
  cancelService() {

  }

  refreshHostList() {

  }

  /**
   * method to get status
   * 
   * @memberof AppComponent
   */
  getStatus() {

    function compare(a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    }
    if (this.contextService.assignedServices.includes('HotelingGuest') || (this.flexibleServiceIsActive && this.contextService.assignedServices.includes('FlexibleSeatingGuest'))) {
      let objectFound = this.statusList[9];
      objectFound.visible = true;
      objectFound.separator = true;
      this.statusList[10].visible = true;

      this.contextService.hotelingListWithName.sort(compare);
      let s = this.statusList[10];
      s.items = [];
      this.contextService.hotelingListWithName.forEach(element => {
        if (element.value != 'none' && element.value != this.contextService.hostID) {
          (<MenuItem[]>s.items).push({ target: element.value, label: element.label, command: (event) => { this.hotelingGuestAction(event.item.target) } });
        }
      });
      if (this.flexibleServiceIsActive) {
        this.contextService.flexibleHostListWithName.sort(compare);
        this.contextService.flexibleHostListWithName.forEach(element => {
          if (element.value != 'none' && element.value != this.contextService.hostIDFlexibleSeating) {
            (<MenuItem[]>s.items).push({ target: element.value, label: element.label, command: (event) => { this.flexibleSeatingGuestAction(event.item.target) } });
          }
        });
      }
      if (this.contextService.hostID != "" && this.contextService.hostID != this.contextService.hostIDFlexibleSeating && this.contextService.hotelingListWithName.length > 1) {
        let tempName = this.contextService.getNameFromUserID(this.contextService.hostID);
        if (tempName === this.contextService.hostID) {
          for (let index = 0; index < this.contextService.hotelingListWithName.length; index++) {
            const element5 = this.contextService.hotelingListWithName[index];
            if (element5.value === this.contextService.hostID) {
              tempName = element5.label;
              break;
            }
          }
        }
        (<MenuItem[]>s.items).unshift({ separator: true, visible: true });
        (<MenuItem[]>s.items).unshift({ target: this.contextService.hostID, label: tempName, command: (event) => { this.hotelingGuestAction(event.item.target) } });
      }

      if (this.contextService.hostIDFlexibleSeating != "" && this.contextService.hostID != this.contextService.hostIDFlexibleSeating && this.contextService.flexibleHostListWithName.length > 1 && this.flexibleServiceIsActive) {
        let tempName = this.contextService.getNameFromUserID(this.contextService.hostIDFlexibleSeating);
        if (tempName === this.contextService.hostIDFlexibleSeating) {
          for (let index = 0; index < this.contextService.flexibleHostListWithName.length; index++) {
            const element4 = this.contextService.flexibleHostListWithName[index];
            if (element4.value === this.contextService.hostIDFlexibleSeating) {
              tempName = element4.label;
              break;
            }

          }
        }
        (<MenuItem[]>s.items).unshift({ separator: true, visible: true });
        (<MenuItem[]>s.items).unshift({ target: this.contextService.hostIDFlexibleSeating, label: tempName, command: (event) => { this.flexibleSeatingGuestAction(event.item.target) } });
      }
      if (((this.contextService.hotelingListWithName.length > 1) || (this.flexibleServiceIsActive && this.contextService.flexibleHostListWithName.length > 1)) || (this.contextService.hostID != this.contextService.hostIDFlexibleSeating && this.contextService.hostID.length != 0)) {
        (<MenuItem[]>s.items).push({ separator: true, visible: true });
      }
      (<MenuItem[]>s.items).push({ target: 'none', label: this.contextService.getLanguageTagByKey('Misc.None'), visible: true, command: (event) => { this.cancelService() } });
      (<MenuItem[]>s.items).push({ separator: true, visible: true });
      (<MenuItem[]>s.items).push({ target: 'refresh', label: this.contextService.getLanguageTagByKey('Menu.MyStatus.ConnectToADevice.Refresh'), visible: true, command: (event) => { this.refreshHostList() } });
      (<MenuItem[]>s.items).forEach((element, index) => {
        if (this.contextService.hostID === "" && element.target === "none" && this.contextService.hostIDFlexibleSeating === "") {
          (<MenuItem>this.statusList[10].items[index]).icon = 'tick';
        }
        if (element.target === this.contextService.hostID || element.target === this.contextService.hostIDFlexibleSeating) {
          (<MenuItem>this.statusList[10].items[index]).icon = 'tick';
        }
      });
    }

    if (this.contextService.assignedServices.includes('CommPilotExpress')) {
      this.statusList[0].visible = true;
      this.statusList[1].visible = true;
      this.statusList[2].visible = true;
      this.statusList[3].visible = true;

    }

    if (this.contextService.assignedServices.includes('CallForwardingAlways')) {

      let objectFound = this.statusList[7];
      objectFound.visible = true;
      objectFound.separator = true;
      this.statusList[8].visible = true;

      if (this.dataService.callForwardningAlwaysService.callForwardingAlways.isActive === true) {
        (<MenuItem>this.statusList[8].items[0]).visible = true;
        (<MenuItem>this.statusList[8].items[1]).visible = false;
      }
      else {
        (<MenuItem>this.statusList[8].items[0]).visible = false;
        (<MenuItem>this.statusList[8].items[1]).visible = true;

      }
    }
    else {
      let objectFound = this.statusList[7];
      objectFound.visible = false;
      objectFound.separator = false;
      this.statusList[8].visible = false;

    }

    if (this.contextService.assignedServices.includes('RemoteOffice')) {
      let objectFound = this.statusList[11];
      objectFound.visible = true;
      objectFound.separator = true;
      this.statusList[12].visible = true;
      if (this.dataService.remoteOfficeService.remoteOffice.isActive === true) {

        (<MenuItem>this.statusList[12].items[0]).visible = true;
        (<MenuItem>this.statusList[12].items[1]).visible = false;

      } else {
        (<MenuItem>this.statusList[12].items[0]).visible = false;
        (<MenuItem>this.statusList[12].items[1]).visible = true;
      }

    }
    else {
      let objectFound = this.statusList[11];
      objectFound.visible = false;
      objectFound.separator = false;
      this.statusList[12].visible = false;

    }
    if (this.contextService.assignedServices.includes('DoNotDisturb')) {
      let objectFound = this.statusList[5];
      objectFound.visible = true;
      objectFound.separator = true;
      this.statusList[6].visible = true;
    }
    else {
      let objectFound = this.statusList[5];
      objectFound.visible = false;
      objectFound.separator = false;
      this.statusList[6].visible = false;
    }
    if (this.contextService.objLoggedUserDetails.getlicenseType() !== "None" && this.contextService.isImConnected === true) {
      if (this.contextService.isImConnected === true) {
        let objectFound = this.statusList[13];
        objectFound.visible = true;
        objectFound.separator = true;
        this.statusList[14].visible = true;


        if (!(this.dataService.imService.statusList == null)) {
          if (!(this.dataService.imService.statusList.imStatus === "")) {

            (<MenuItem>this.statusList[14].items[0]).visible = true;
            objectFound = (<MenuItem>this.statusList[14].items[1]);
            objectFound.visible = true;
            objectFound.separator = true;
          }
          else {
            (<MenuItem>this.statusList[14].items[0]).visible = false;
            objectFound = (<MenuItem>this.statusList[14].items[1]);
            objectFound.visible = false;
            objectFound.separator = false;
          }
        }

      }
      else {

        let objectFound = this.statusList[13];
        objectFound.visible = false;
        objectFound.separator = false;
        this.statusList[14].visible = false;
      }
    }
    else {

      let objectFound = this.statusList[13];
      objectFound.visible = false;
      objectFound.separator = false;
      this.statusList[14].visible = false;
    }
  }


  /**
   * set im status is my status
   * 
   * @memberof AppComponent
   */
  setIMStatusToolTip() {
    this.changeFontColor = "";
    (<MenuItem>this.statusList[14].items[4]).icon = '';
    (<MenuItem>this.statusList[14].items[5]).icon = '';
    (<MenuItem>this.statusList[14].items[6]).icon = '';
    (<MenuItem>this.statusList[14].items[7]).icon = '';
    (<MenuItem>this.statusList[14].items[8]).icon = '';


    if (!(this.dataService.imService.statusList == null)) {
      let today = new Date();
      if (this.contextService.isImConnected === true) {
        if (!(this.dataService.imService.statusList.imStatus === "")) {
          let monthNames = [this.contextService.getLanguageTagByKey('Month.1'), this.contextService.getLanguageTagByKey('Month.2'), this.contextService.getLanguageTagByKey('Month.3'), this.contextService.getLanguageTagByKey('Month.4'), this.contextService.getLanguageTagByKey('Month.5'), this.contextService.getLanguageTagByKey('Month.6'),
          this.contextService.getLanguageTagByKey('Month.7'), this.contextService.getLanguageTagByKey('Month.8'), this.contextService.getLanguageTagByKey('Month.9'), this.contextService.getLanguageTagByKey('Month.10'), this.contextService.getLanguageTagByKey('Month.11'), this.contextService.getLanguageTagByKey('Month.12')
          ];
          let startMonth = parseInt(this.dataService.imService.statusList.startMonth);
          this.currentIMStatus = this.dataService.imService.statusList.currentStatus.toLowerCase();
          let toLowerCase = this.dataService.imService.statusList.currentStatus;
          if (this.dataService.imService.statusList.endHour == "") {
            if (parseInt(this.dataService.imService.statusList.startDay) === today.getDate() && parseInt(this.dataService.imService.statusList.startMonth) === today.getMonth() + 1 && parseInt(this.dataService.imService.statusList.startYear) === today.getFullYear()) {
              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Today') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.FurtherNotice') + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";

            }
            else {
              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startDay + " " + monthNames[startMonth - 1] + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.FurtherNotice') + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";
            }
          }
          else {

            let endMonth = parseInt(this.dataService.imService.statusList.endMonth);

            if (parseInt(this.dataService.imService.statusList.startDay) === today.getDate() && parseInt(this.dataService.imService.statusList.startMonth) === today.getMonth() + 1 && parseInt(this.dataService.imService.statusList.startYear) === today.getFullYear()) {
              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Today') + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.dataService.imService.statusList.endDay + " " + monthNames[endMonth - 1] + " " + this.contextService.getLanguageTagByKey('Misc.At') + " " + this.dataService.imService.statusList.endHour + ":" + this.dataService.imService.statusList.endMinute + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";
            }
            else if (parseInt(this.dataService.imService.statusList.endDay) === today.getDate() && parseInt(this.dataService.imService.statusList.startMonth) === today.getMonth() + 1 && parseInt(this.dataService.imService.statusList.startYear) === today.getFullYear()) {
              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startDay + " " + monthNames[startMonth - 1] + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.dataService.imService.statusList.endHour + ":" + this.dataService.imService.statusList.endMinute + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";

            }

            else if (parseInt(this.dataService.imService.statusList.endDay) === (today.getDate() + 1) && parseInt(this.dataService.imService.statusList.startMonth) === today.getMonth() + 1 && parseInt(this.dataService.imService.statusList.startYear) === today.getFullYear()) {
              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startDay + " " + monthNames[startMonth - 1] + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.dataService.imService.statusList.endHour + ":" + this.dataService.imService.statusList.endMinute + " " + this.contextService.getLanguageTagByKey('Misc.Tomorrow') + " " + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";
            }
            else {

              this.myStatusTooltip = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm') + " " + toLowerCase.toLocaleLowerCase() + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start') + " " + this.dataService.imService.statusList.startDay + " " + monthNames[startMonth - 1] + " " + this.dataService.imService.statusList.startHour + ":" + this.dataService.zeroPad(parseInt(this.dataService.imService.statusList.startMinute)) + " " + this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End') + " " + this.dataService.imService.statusList.endDay + " " + monthNames[endMonth - 1] + " " + this.contextService.getLanguageTagByKey('Misc.At') + " " + this.dataService.imService.statusList.endHour + ":" + this.dataService.imService.statusList.endMinute + "." + "(" + this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip') + ")";
            }

          }

          if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "in a meeting") {
            this.myCurrentStatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.InAMeeting');
            this.myCurrentStatusIco = '';
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.statusList[14].items[4]).icon = 'tick';

            }

          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "out to lunch") {
            this.myCurrentStatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutToLunch');
            this.myCurrentStatusIco = '';
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.statusList[14].items[5]).icon = 'tick';
            }

          }

          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "out of the office") {
            this.myCurrentStatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutOfTheOffice');
            this.myCurrentStatusIco = '';
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.statusList[14].items[6]).icon = 'tick';
            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "on leave") {
            this.myCurrentStatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OnLeave');
            this.myCurrentStatusIco = '';
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.statusList[14].items[7]).icon = 'tick';

            }
          }
          else if (this.dataService.imService.statusList.currentStatus.toLocaleLowerCase() === "away sick") {
            this.myCurrentStatus = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AwaySick');
            this.myCurrentStatusIco = '';
            if (this.dataService.imService.statusList.endDay == "") {
              (<MenuItem>this.statusList[14].items[8]).icon = 'tick';
            }
          }
        }



        else {
          this.myStatusTooltip = this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip');
          this.setDND();
        }
      }
      else {
        this.myStatusTooltip = this.contextService.getLanguageTagByKey('Menu.MyStatus.ToolTip');
        this.setDND();
      }

    }
    else {
      this.setDND();
    }

  }
  /**
   * set DND in my status
   * 
   * @memberof AppComponent
   */
  setDND() {
    if (!(this.dataService.doNotDisturbService.doNotDisturb == null)) {
      if (this.dataService.doNotDisturbService.doNotDisturb.isActive === true) {

        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.DoNotDisturb');
        this.myCurrentStatusIco = 'ico-onHook-DND';
        this.changeFontColor = "dnd";
        this.contextService.isDNDActive = true;
        // this.contextService.setStatusInTitle();
        this.contextService.timerSetUpForTitle();

      }
      else {
        this.contextService.isDNDActive = false;
        // this.contextService.setStatusInTitle();
        this.contextService.timerSetUpForTitle();

        this.setCFA();
      }
    }
    else {
      this.setCFA();
    }
  }
  /**
   * set CFA in my status
   * 
   * @memberof AppComponent
   */
  setCFA() {
    if (!(this.dataService.callForwardningAlwaysService.callForwardingAlways == null)) {
      if (this.dataService.callForwardningAlwaysService.callForwardingAlways.isActive === true) {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.CallForwardAlways');
        this.myCurrentStatusIco = 'ico-callforward';
        this.changeFontColor = "dnd";
      }
      else {
        this.setRemoteOffice();
      }
    }
    else {
      this.setRemoteOffice();
    }
  }
  /**
   * set remote officce in my status
   * 
   * @memberof AppComponent
   */
  setRemoteOffice() {
    if (!(this.dataService.remoteOfficeService.remoteOffice == null)) {
      if (this.dataService.remoteOfficeService.remoteOffice.isActive === true) {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.RemoteOffice');
        this.myCurrentStatusIco = 'ico-door';
        this.changeFontColor = "remoteOffice";
      }
      else {
        this.setCommPilot();
      }
    }
    else {
      this.setCommPilot();
    }

  }

  /**
   * set comm piolet in my status
   * 
   * @memberof AppComponent
   */
  setCommPilot() {
    if (!(this.contextService.objLoggedUserDetails.getCommPilotProfile() === "")) {
      if (this.contextService.objLoggedUserDetails.getCommPilotProfile() === 'Available In Office') {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice');
        this.myCurrentStatusIco = 'ico-available';
      }
      else if (this.contextService.objLoggedUserDetails.getCommPilotProfile() === 'Available Out Of Office') {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.OutOfOffice');
        this.myCurrentStatusIco = 'ico-available';
      }
      else if (this.contextService.objLoggedUserDetails.getCommPilotProfile() === 'Busy') {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Busy');
        this.myCurrentStatusIco = 'ico-dnd';
      }
      else if (this.contextService.objLoggedUserDetails.getCommPilotProfile() === 'Unavailable') {
        this.myCurrentStatus = this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable');
        this.myCurrentStatusIco = 'ico-unavailable';
      }


    }
    else {
      this.myCurrentStatus = this.contextService.getLanguageTagByKey('Misc.None');
      this.myCurrentStatusIco = 'ico-available';

    }
  }
  //athul
  /**
   * to get focus out of call logs tab
   * 
   * @memberof AppComponent
   */
  focusOutFunction() {
    this.contextService.missedCallReferenceDate = new Date();
  }
  focusOutVoiceMail() {
    this.contextService.newVoiceMessageTime = new Date();
  }
  toggleChatByValue(value: boolean) {
    this.contextService.chatCollapsed = value;
  }
  closeChat() {
    this.imConnectionLostVis = false;
    this.contextService.imConnectionLostModalVis.next('closeChatWindow');
  }
  openCm(event, cm) {

    event.preventDefault();
    event.stopPropagation();
    this.helper.saveAllCMenus(cm);
    this.helper.hideAllCMenus();
    cm.show(event);
    return false;
  }
  checkForCallCenterFailedAlert() {
    this.isIncorrectPassword = false;
    if (this.contextService.callCenterLoginFailedCollection.length > 1) {
      this.contextService.callCenterLoginFailedCollection.splice(0, 1);
      this.callCenterName = this.contextService.getNameFromUserID(this.contextService.callCenterLoginFailedCollection[0]);
      this.isIncorrectPassword = true;
      return;
    }
    this.contextService.callCenterLoginFailedCollection = [];
  }


  privacyAlertOkClick() {
    this.contextService.privacyErrorVis = false;
    this.contextService.privacyEnabledUsersList[this.contextService.activePrivacyId] = false;;
    if (Object.keys(this.contextService.privacyEnabledUsersList).length > 0) {
      for (const key in this.contextService.privacyEnabledUsersList) {
        if (this.contextService.privacyEnabledUsersList.hasOwnProperty(key)) {
          if (this.contextService.privacyEnabledUsersList[key]) {
            let alertMessage = this.contextService.getLanguageTagByKey('Prompt.MonitoredUser.Privacy');
            let alert = this.contextService.getNameFromUserID(key) + ' ' + alertMessage;
            this.contextService.privacyErrorModalVis.next(alert);
            this.contextService.activePrivacyId = key;
            break;
          }
        }
      }

    }
  }

  callExtensionAvailableAlertOkClick() {
    this.callExtensionAvailableAlertVis = false;
    delete this.directoryService.callExtensionAvailableArrayList[this.activeCallExtensionId];
    this.activeCallExtensionId = "";
    for (const key in this.directoryService.callExtensionAvailableArrayList) {
      if (this.directoryService.callExtensionAvailableArrayList.hasOwnProperty(key)) {
        const element = this.directoryService.callExtensionAvailableArrayList[key];
        if (element) {
          this.activeCallExtensionId = key;
          this.callExtensionAvailableAlertVis = true;
          this.callExtensionAvailableAlertText = this.contextService.getNameFromUserID(key) + " " + this.contextService.getLanguageTagByKey("Prompt.DialExtensionWhenFree")

        }
      }
    }
  }
  callExtension() {
    this.contextService.log("call extension when available method clicked and the id is " + this.activeCallExtensionId);
    this.contextService.websocketService.dialAction(this.getExtensionFromUserID(this.activeCallExtensionId));
    this.callExtensionAvailableAlertOkClick();
  }

  getExtensionFromUserID(loginID: string): string {
    for (let i = 0; i < this.contextService.directoryList.length; i++) {
      if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
        let element = this.contextService.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === loginID) {
          return element.getExtension();
        }
      }
    }
  }
}
