import { BwDirectoryEntry } from '../../model/directory/bw-directory-entry';
import { MonitoredUser } from '../../model/directory/monitored-user';
import { ContextService } from '../../providers/context.service';
import { ResponseHandler } from '../../shared/ResponseHandler';

/**
 * To process PersonalDirectory
 * 
 * @export
 * @class GetPersonalDirectoryResponseHandler
 * @implements {ResponseHandler}
 */
export class PersonalDirectoryResponseHandler implements ResponseHandler {

    type = "GetPersonalDirectoryResponse";
    isFirstTime: boolean = false;
    public tempDirectoryList: Array<BwDirectoryEntry>;
    constructor(private contextService: ContextService) {
        this.contextService.log('###### constructor - PersonalDirectoryResponseHandler');
        this.isFirstTime = true;
        if (this.contextService.objLoggedUserDetails.getlicenseUserListMode() == "None") {
            this.contextService.objSettings.removeUserFromUserTreeArray = [];
            this.contextService.objSettings.bwGroupNamesList_Monitor = [];
        } else {
            this.creatingMoniteredUserList();
            this.checkForMonitoredUsers();

        }
    }

    handle(xmlResponse: Document): void {
    }


    checkForMonitoredUsers() {
        if (this.contextService.objLoggedUserDetails.getlicenseType() !== "None") {

            let tempmonitoredUserList = [];
            if (this.contextService.objSettings.removeUserFromUserTreeArray) {
                try {
                    tempmonitoredUserList = this.contextService.objSettings.removeUserFromUserTreeArray;

                } catch (error) {
                    this.contextService.log("error loading monitoredusers: " + error);
                    this.loadMonitorUsersAccordingToLiscense();
                }
                this.contextService.log(tempmonitoredUserList.length);
                if (tempmonitoredUserList.length > 0) {
                    this.contextService.monitoredUserList = [];
                    let count = 30;
                    let tempArray = [];
                    tempmonitoredUserList.forEach(element => {
                        if (element != this.contextService.objLoggedUserDetails.getUserId()) {
                            if (count > 0) {
                                this.contextService.monitoredUserList[element] = new MonitoredUser('', false, '', '', '', '');
                                tempArray.push(element);
                                count--;
                            }
                        }
                    });
                    this.contextService.objSettings.removeUserFromUserTreeArray = tempArray;
                    tempArray = [];
                } else {
                    this.loadMonitorUsersAccordingToLiscense();
                }

            } else {
                this.loadMonitorUsersAccordingToLiscense();
            }
        }
        this.contextService.getDirectoryView.next();
    }



    loadMonitorUsersAccordingToLiscense() {
        let tempmonitoredUserList = [];
        let index = 0;
        let limit = 0;
        if (this.contextService.objLoggedUserDetails.getlicenseType() === "None") {
            return;
        } else {
            limit = 30;
        }

        this.contextService.directoryList.forEach(element => {
            if (index < limit) {
                if (element instanceof BwDirectoryEntry) {
                    // let canMonitor: boolean = (element.getUserId() !== this.contextService.objLoggedUserDetails.getUserId() && !element.getFullName().includes("Flexible Seating Guest") && !element.getFullName().includes("Call Center") && !element.getFullName().includes("Hunt Group") && !element.getFullName().includes("Trunk Group")
                    //     && !element.getFullName().includes("Meet-Me Conference") && !element.getFullName().includes("Auto Attendant") && !element.getFullName().includes("Meet-Me Conferencing")
                    //     && !element.getFullName().includes("BroadWorks Anywhere") && !element.getFullName().includes("Voice Messaging Group") && !element.getFullName().includes("Group Paging")
                    //     && !element.getFullName().includes("Instant Group") && !element.getFullName().includes("Voice Messaging Group") && !element.getFullName().includes("Group Paging"));
                    let canMonitor: boolean = (element.getUserId() !== this.contextService.objLoggedUserDetails.getUserId()
                        && this.contextService.checkIfContactCanBeMonitored(element.getFirstName(), false));

                    if (canMonitor) {
                        this.contextService.monitoredUserList[element.getUserId()] = new MonitoredUser('', false, '', '', '', '');
                        tempmonitoredUserList.push(element.getUserId());
                        index++;
                    }
                }
            }
        });
        this.contextService.objSettings.removeUserFromUserTreeArray = tempmonitoredUserList;
    }

    creatingMoniteredUserList() {
        this.tempDirectoryList = [];
        let groupList = [];
        this.contextService.objSettings.removeUserFromUserTreeArray.forEach(element => {

            for (let i = 0; i < this.contextService.directoryList.length; i++) {
                if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
                    const directoryElement = this.contextService.directoryList[i] as BwDirectoryEntry;

                    if (element === directoryElement.getUserId()) {
                        let groupID = directoryElement.getGroupId();
                        this.tempDirectoryList.push(directoryElement);
                        var departmentArray = [];
                        if (!(groupID in groupList)) {
                            groupList[groupID] = departmentArray;
                        }
                        if (directoryElement.getDepartment() !== "") {
                            departmentArray = [...groupList[groupID]];
                            if (!(departmentArray.includes(directoryElement.getDepartment()))) {
                                departmentArray.push(directoryElement.getDepartment());
                                groupList[groupID] = departmentArray;
                            }
                        }
                        departmentArray = [];
                        groupID = "";
                        break;
                    }
                }
            }
        });

        for (const key in groupList) {
            if (groupList.hasOwnProperty(key)) {
                // const element = test[key];
                let groupcontacts = this.tempDirectoryList.filter(contact => contact.getGroupId() == key);
                this.contextService.log(groupcontacts)
                let bwDepartmentsList = [];
                groupList[key].forEach(element => {
                    this.contextService.log("dept::" + element);
                    let bwUsersInDepartmentList = [];
                    let departmentContacts = groupcontacts.filter(contact => contact.getDepartment() === element);
                    departmentContacts.forEach(contacts => {
                        bwUsersInDepartmentList.push({ departmentId: element, loginId: contacts.getUserId(), name: contacts.getFullName(), type: "user", groupId: key });
                    });
                    bwDepartmentsList.push({ departmentId: element, type: "user", groupId: key, bwUserList: bwUsersInDepartmentList });
                    this.contextService.log(departmentContacts);

                });
                let contactWithoutDepartment = groupcontacts.filter(contact => contact.getDepartment() === "");
                this.contextService.log(contactWithoutDepartment);
                let bwUsersList = [];
                contactWithoutDepartment.forEach(element => {
                    bwUsersList.push({ departmentId: element.getDepartment(), loginId: element.getUserId(), name: element.getFullName(), type: "user", groupId: key });
                });
                this.contextService.objSettings.bwGroupNamesList_Monitor.push({ groupName: key, type: "user", bwDepartmentList: bwDepartmentsList, bwUserList: bwUsersList });
            }
        }
    }
}
