<div class="row">
  <p>
  {{description}}
  </p>
</div>

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" (click)="focus()" value="checkbox" label={{enabled}} [(ngModel)]="remoteOfficeService.isActive" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{frwdNumber}}</label>
  <input type="text" id="textarea" name="input-text" [(ngModel)]="remoteOfficeService.frwdNumber" [disabled]="!remoteOfficeService.isActive" autocomplete=off>
</div>
