import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { CallWaitingService } from '../../../../../providers/settings/settings-services/call-waiting.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-call-waiting',
  templateUrl: './call-waiting.component.html',
  styleUrls: ['./call-waiting.component.scss']
})
export class CallWaitingComponent extends BaseComponent implements OnInit {
  description: string="";
  enabled: string="";
  isActive: boolean=false;
  constructor(public callWaitingService: CallWaitingService, private contextService: ContextService) {
    super();
  }

  ngOnInit() {
    this.callWaitingService.callWaitingChanges=true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallWaiting.Description');
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
  }
}
