import { BWUser } from './bw-user';
/**
 * model to store users department wise
 * 
 * @export
 * @class BWDepartment
 */
export class BWDepartment {
  public departmentId: string;
  public loginId: string;
  //  public userList: any[];
  public groupId: string;
  public type: string;

  public bwUserList: Array<BWUser>;
  constructor() {
    this.bwUserList = [];
  }

}