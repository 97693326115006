import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ChatService } from "app/providers/chat.service";
import { DataService } from "app/providers/dataservice.service";
import { HelperService } from 'app/providers/helper.service';
import { Table } from 'primeng';
import { MenuItem } from 'primeng/api/menuitem';
import 'rxjs/add/operator/debounceTime';
import { fromEvent } from 'rxjs';
import { ContextService } from '../../providers/context.service';
import { DirectoryService } from './../../providers/directory.service';


///athul
@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.scss']
})
export class ContactTableComponent implements OnInit, AfterViewInit {
  contacts: Array<any>[] = [];
  selectedContacts: Array<any>[] = [];
  selectedContact;
  contextMenuItems: MenuItem[];
  contextMenuOutside: MenuItem[];
  afterDropCM: MenuItem[];

  searchMenu: MenuItem[];
  layoutType: string;
  alphabet: Array<string> = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'Z'];
  contactFilterType: string;
  @ViewChild('afterdropmenu') afterdropmenu;
  @ViewChild('datatable') contactsTable;
  //athul
  @ViewChild('contactFilter', { static: true }) searchInput: ElementRef;
  //athul
  afterdropmenuLeft: string;
  afterdropmenuTop: string;
  scrolling: boolean = false;
  componentHeight;
  contactTableHeight;
  contactTableMenuHeight;
  clickedLetter: any = false;
  // show hide cols
  acdHidden = true;
  depHidden = true;
  extHidden = true;
  searchTerm: string = "";
  isOnCall: boolean = false;

  tempDirType: string = "";

  statusHidden: boolean = false;
  mobileHidden: boolean = true;
  tempDepHidden = true;
  tempExtHidden = true;
  tempACDHidden = true;
  //athul
  titleHidden = true;
  emailHidden = true;
  tempTitleHidden = true;
  tempEmailHidden = true;
  businessHidden = true;
  hiddenColumn = false;

  locationHidden = true;
  tempLocationHidden = true;
  //athul
  sortO: string = '1';
  sortF: string = 'name';

  tooltip: string = "";
  miscName: string = "";
  miscPhone: string = "";
  extension: string = "";
  mobile: string = "";
  department: string = "";
  acdState: string = "";
  miscStatus: string = "";

  title: string = "";
  email: string = "";
  location: string = "";

  businessName: string = "";
  activeCallId: string = "";
  showCallingFuntionalities: boolean = false;
  //dragula bags
  locBag;
  bag;
  globalFilterArray: Array<string> = [];

  searchMode: string = "startsWith";
  rowToolTip: string = "";

  @ViewChild('datatable') datatable: Table;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.helper.selectedTableTab === 'contacts' && this.contactsTable) {
      setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) });
      this.calcContactsHeight();
    }
  }
  constructor(private dataService: DataService,
    private helper: HelperService,
    private chatService: ChatService, public contextService: ContextService,
    private el: ElementRef, public directoryService: DirectoryService) {
    this.directoryService.updateCellWidth.subscribe(() => {
      setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);
    });

    this.contextService.setSearchSettingsInContacttable.subscribe(val => {
      if (val == "settingsSaved") {
        this.searchMode = this.contextService.objSettings.contact_startsWith;
        if (this.globalFilterArray.includes('department')) {
          if (!this.contextService.objSettings.contact_includeDepartmentInSearch) {
            this.globalFilterArray.pop();
          }
        } else {
          if (this.contextService.objSettings.contact_includeDepartmentInSearch) {
            this.globalFilterArray.push('department');
          }
        }
      } else if (val == "callAnswered") {
        if (this.searchTerm !== "") {
          this.clearSearch();
        }
        this.searchInput.nativeElement.focus();
      }
    });

  }

  ngOnInit() {
    this.globalFilterArray = ['name', 'phone', 'extension', 'mobile', 'email', 'title', 'location'];
    if (this.contextService.objSettings.contact_includeDepartmentInSearch) {
      this.globalFilterArray.push('department');
    }
    fromEvent(this.searchInput.nativeElement, 'keyup').debounceTime(500).subscribe(value => {
      if (this.contextService.objSettings.contact_includeAllContact) {
        this.contextService.log("searching outlook contact............");
      }
    });
    //athul

    this.tempACDHidden = this.acdHidden;
    this.layoutType = 'Details';
    this.contactFilterType = 'location';

    this.searchMode = this.contextService.objSettings.contact_startsWith;
    this.tooltip = this.contextService.getLanguageTagByKey('TabShowing.Directory.SearchButton.ToolTip');
    this.miscName = this.contextService.getLanguageTagByKey('Misc.Name');
    this.miscPhone = this.contextService.getLanguageTagByKey('Misc.Phone');
    this.extension = this.contextService.getLanguageTagByKey('List.Directories.Column.Extension');
    this.mobile = this.contextService.getLanguageTagByKey('List.Directories.Column.Mobile');
    this.department = this.contextService.getLanguageTagByKey('List.UserStatus.Column.Department');
    this.acdState = this.contextService.getLanguageTagByKey('List.Statistics.Column.AcdState');
    this.miscStatus = this.contextService.getLanguageTagByKey('Misc.Status');

    this.title = this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.Title');
    this.email = this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.Email');
    this.businessName = this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.BusinessName');
    this.location = this.contextService.getLanguageTagByKey('UserDetails.Location');
    // this.dataService.getContacts().subscribe(
    //   (response: Response) => {
    //     const data = response.json().data;
    //     this.contacts = data;
    //     setTimeout(() => {
    //       if (this.contacts != null) {
    //         this.helper.calcCellsWidth(this.contactsTable);
    //       }
    //     });
    //   }
    // );
    /**
     *  to show context menu when a call is dropped on contact table
     */
    this.helper.openedMenuOnDrop.subscribe((value) => {
      const left = (value.x).toString() + 'px';
      const top = (value.y).toString() + 'px';

      this.afterdropmenuLeft = left;
      this.afterdropmenuTop = top;
      setTimeout(() => {
        this.directoryService.droppedToContact = null;
        /** 
         * checks if dropped call is present
         * to name, to number and to type is present
         * and droped to contact is not an active participant of the call
        */
        if ((this.contextService.droppedcall !== undefined) && (this.contextService.droppedcall !== null)) {
          if ((this.contextService.dropedToname !== "") && (this.contextService.droppedToNumber !== "") && (this.contextService.dropedToType != "")) {
            if ((this.contextService.dropedToname !== this.contextService.droppedcall.from) && (this.contextService.dropedToname !== this.contextService.droppedcall.to)) {
              this.directoryService.directoryDisplayArray.forEach(element => {
                if ((element.name === this.contextService.dropedToname) && (element.phone === this.contextService.droppedToNumber)) {
                  if (('ico-' + element.icon) === this.contextService.dropedToType) {
                    this.directoryService.droppedToContact = element;
                  }
                }
              });


              this.contextService.log("dropped to::" + this.directoryService.droppedToContact.name);
              this.afterDropCM = [
                { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.directoryService.transferOnDrop("extension"), visible: false },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToMobile'), command: (event) => this.directoryService.transferOnDrop("mobile"), visible: false },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToNumber'), command: (event) => this.directoryService.transferOnDrop("number"), visible: false },
                { separator: false, visible: false },
                { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.directoryService.transferOnDrop("voicemail"), visible: false }
              ];
              if ((this.directoryService.droppedToContact.type === 'Broadworks Directory') && (this.directoryService.droppedToContact.name.indexOf("(Mobile)") === -1)) {
                // show extension
                this.afterDropCM[0].visible = true;
                // objectFound.visible = true;
                //show mobile if present
                if (this.directoryService.droppedToContact.mobile !== '') {
                  this.afterDropCM[1].visible = true;
                }
                let objectFound = this.afterDropCM[3];
                objectFound.visible = true;
                objectFound.separator = true;
                // show voicemail
                this.afterDropCM[4].visible = true;
              } else {
                // show number
                this.afterDropCM[2].visible = true;
              }
              this.afterdropmenu.show();
            }
          } else {
            this.contextService.log("--Active Call dropped but Dropped to contact is undefined.");
          }
        }

      }, 250);

    });

    this.helper.recalcContactsHeight.debounceTime(250).subscribe((value) => {
      this.calcContactsHeight();
    });

    this.helper.resized.debounceTime(600).subscribe((val) => {
      this.helper.calcCellsWidth(this.contactsTable);
    });

    this.helper.tableTabChanged.subscribe((val) => {
      setTimeout(() => {
        if (this.contacts != null) {
          this.helper.calcCellsWidth(this.contactsTable);
        }
      });
    });

    this.helper.dragStart.subscribe((val) => {
      this.selectedContact = [];
    });

    this.contextMenuOutside = [
      {
        label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory'),
        items: [
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Add'), command: (event) => this.helper.addPersonalEntryModalVis.next(true) },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Import'), command: (event) => this.contextService.log("import personal directory"), icon: ' ', visible: false },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Export'), icon: ' ', visible: false }
        ], visible: true
      },
      { separator: true },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.ManageUserList'), command: (event) => this.helper.manageUsersVisible.next(true) },
      { separator: true },
      {
        label: this.contextService.getLanguageTagByKey('Misc.Appearance'),
        items: [
          {
            label: this.contextService.getLanguageTagByKey('View.Details'),
            command: (event) => {
              this.setContactLayout('Details');
              this.helper.setTickIcon(this.contextMenuItems, 0, 30, 'grp1');
              this.helper.setTickIcon(this.contextMenuOutside, 0, 4, 'grp1');
            },
            icon: '',
            styleClass: 'grp1'
          },
          {
            label: this.contextService.getLanguageTagByKey('View.SmallIcon'),
            command: (event) => {
              this.setContactLayout("Small icons");
              this.helper.setTickIcon(this.contextMenuItems, 1, 30, 'grp1');
              this.helper.setTickIcon(this.contextMenuOutside, 1, 4, 'grp1');
            },
            icon: '',
            styleClass: 'grp1'
          },
          {
            label: this.contextService.getLanguageTagByKey('View.Twocolumns'),
            command: (event) => {
              this.setContactLayout("2 columns");
              this.helper.setTickIcon(this.contextMenuItems, 2, 30, 'grp1');
              this.helper.setTickIcon(this.contextMenuOutside, 2, 4, 'grp1');
            },
            icon: '',
            styleClass: 'grp1'
          },
          { separator: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowExtension'), command: (event) => { this.toggleExt(); this.helper.setTickIcon(this.contextMenuOutside, 4, 4); }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowDepartment'), command: (event) => { this.toggleDep(); this.helper.setTickIcon(this.contextMenuOutside, 5, 4) }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowAcdState'), command: (event) => { this.toggleAcd(); this.helper.setTickIcon(this.contextMenuOutside, 6, 4) }, icon: '', visible: (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) },
          //  athul
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowTitle'), command: (event) => { this.toggleTitle(); this.helper.setTickIcon(this.contextMenuOutside, 7, 4) }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowEmail'), command: (event) => { this.toggleEmail(); this.helper.setTickIcon(this.contextMenuOutside, 8, 4) }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowLocation'), command: (event) => { this.toggleLocation(); this.helper.setTickIcon(this.contextMenuOutside, 9, 4) }, icon: '' },
          //athul
          { separator: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowCallDuration'), command: (event) => { this.toggleDuration(); } }
        ]
      }
    ];
    if ((this.contextService.objLoggedUserDetails !== undefined) && (this.contextService.objLoggedUserDetails.getlicenseType() !== 'None')) {
      this.searchMenu = [
        { label: this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.DefaultList'), command: (event) => { this.setTickIcon(0, "Default List") }, icon: '', styleClass: 'srch' },
        { label: this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.GroupCommon'), command: (event) => { this.setTickIcon(1, "Common Directory") }, icon: '', styleClass: 'srch' },
        { label: this.contextService.getLanguageTagByKey('TabShowing.Directory.DirectoryType.User'), command: (event) => { this.setTickIcon(2, "Personal Directory") }, icon: '', styleClass: 'srch' },
      ];

      this.setSearchMenuDropDown();

    }

    // this.loadContextMenu();
    /**
     * set layout
     * states of extnsion,department and acd coloumns
     */
    if (this.contextService.objSettings.dir_Appearance) {
      // this.setContactLayout(this.contextService.objSettings.dir_Appearance);
      this.layoutType = this.contextService.objSettings.dir_Appearance;
      setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);
      setTimeout(() => {
        this.calcContactsHeight(this.layoutType);
      }, 500);
    }

    if (!this.contextService.objSettings.dir_Extension_Hidden) {
      this.extHidden = this.contextService.objSettings.dir_Extension_Hidden;
      this.tempExtHidden = this.extHidden;
    }
    if (!this.contextService.objSettings.dir_Department_Hidden) {
      this.depHidden = this.contextService.objSettings.dir_Department_Hidden;
      this.tempDepHidden = this.depHidden;
    }
    //athul
    if (!this.contextService.objSettings.dir_Title_Hidden) {
      this.titleHidden = this.contextService.objSettings.dir_Title_Hidden;
      this.tempTitleHidden = this.titleHidden;
    }

    if (!this.contextService.objSettings.dir_Email_Hidden) {
      this.emailHidden = this.contextService.objSettings.dir_Email_Hidden;
      this.tempEmailHidden = this.emailHidden;
    }
    //todo
    if (!this.contextService.objSettings.dir_Location_Hidden) {
      this.locationHidden = this.contextService.objSettings.dir_Location_Hidden;
      this.tempLocationHidden = this.locationHidden;
    }
    //athul
    if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {/*doubt*/
      this.acdHidden = this.contextService.objSettings.dir_ACDStatus_Hidden;
      this.tempACDHidden = this.acdHidden;
    }
    this.setSort();
    this.loadContextMenu();
  }
  /**
   * set sort field and sort order according to settings (restart persisit)
   * if persist value is extension or department orACDState and if it is hidden
   * then default sort is applied
   */
  setSort() {
    if (this.contextService.objSettings.dir_sortField && this.contextService.objSettings.dir_sortOrder) {
      if (this.contextService.objSettings.dir_sortField == 'extension') {
        if (!this.extHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      }
      else if (this.contextService.objSettings.dir_sortField == 'department') {
        if (!this.depHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      } else if (this.contextService.objSettings.dir_sortField == 'ACDState') {
        if (!this.acdHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      }    //athul
      else if (this.contextService.objSettings.dir_sortField == 'title') {
        if (!this.titleHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      }
      else if (this.contextService.objSettings.dir_sortField == 'email') {
        if (!this.emailHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      }
      else if (this.contextService.objSettings.dir_sortField == 'location') {
        if (!this.locationHidden) {
          this.sortF = this.contextService.objSettings.dir_sortField;
          this.sortO = this.contextService.objSettings.dir_sortOrder;
        } else {
          this.setDefaultSort();
        }
      }
      //athul
      else {
        this.sortF = this.contextService.objSettings.dir_sortField;
        this.sortO = this.contextService.objSettings.dir_sortOrder;
      }
    }
    else {
      this.setDefaultSort();
    }
    setTimeout(() => {
      this.datatable.sortField = this.sortF;
      this.datatable.sortOrder = this.sortO == "1" ? 1 : -1;
      //this.datatable.sortOrder = 1;
      this.datatable.sortSingle();
      if (this.searchTerm !== "") {
        this.filterDirectoryDisplayArray(this.searchTerm);
      }

    }, 50);

  }
  /**
   * name field is set as default sort
   */
  setDefaultSort() {
    this.sortF = 'name';
    this.sortO = '1';
    // if (this.contextService.objLoggedUserDetails != undefined) {
    //   this.contextService.objSettings.dir_sortField = 'name';;
    //   this.contextService.objSettings.dir_sortOrder = '1';
    //   this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
    // }
  }

  loadContextMenu() {
    this.contextMenuItems = [
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtension'), command: (event) => this.directoryService.call("extension"), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallExtensionWhenAvailable'), command: (event) => this.directoryService.callExtensionWhenAvailable(), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallMobile'), command: (event) => this.directoryService.call("mobile"), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CallNumber'), command: (event) => this.directoryService.call("number"), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.directoryService.transfer("extension"), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToMobile'), command: (event) => this.directoryService.transfer("mobile"), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToNumber'), command: (event) => this.directoryService.transfer("number"), visible: false },

      { label: this.contextService.getLanguageTagByKey('Menu.Context.WarmTransferToExtension'), command: (event) => this.directoryService.warmTransfer("extension", this.activeCallId), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.WarmTransferToMobile'), command: (event) => this.directoryService.warmTransfer("mobile", this.activeCallId), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.WarmTransferToNumber'), command: (event) => this.directoryService.warmTransfer("number", this.activeCallId), visible: false },


      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.directoryService.transfer("voicemail"), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.ParkOnExtension'), command: (event) => this.directoryService.parkOnExtension(), visible: false },
      { separator: false, visible: false },

      { label: this.contextService.getLanguageTagByKey('Menu.Context.AnswerThisCall'), command: (event) => this.directoryService.answerThisCall(), visible: false },

      { label: this.contextService.getLanguageTagByKey('Menu.Context.BargeIn'), command: (event) => this.directoryService.bargeIntoThiscall(), visible: false },
      { separator: false, visible: false },

      { label: this.contextService.getLanguageTagByKey('Menu.Context.ViewUserDetails'), command: (event) => this.directoryService.viewUserDetails(), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.InstantMessage'), command: (event) => this.directoryService.instantMessage(), visible: false },
      { separator: false, visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CopyNumber'), command: (event) => this.directoryService.CopyToClipboard('number'), visible: false },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.CopyMobile'), command: (event) => this.directoryService.CopyToClipboard('mobile'), visible: false },
      { separator: false, visible: false },
      {
        label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory'),
        items: [
          { label: '', command: (event) => { this.directoryService.setContactValues(), this.helper.addPersonalEntryModalVis.next(true) }, icon: ' ', visible: false },
          { label: '', command: (event) => this.directoryService.deleteEntryVisible = true, icon: ' ', visible: false },
          { separator: false, visible: false },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Add'), command: (event) => this.helper.addPersonalEntryModalVis.next(true) },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Import'), command: (event) => this.contextService.log("import personal directory"), icon: ' ', visible: false },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.PersonalDirectory.Export'), icon: ' ', visible: false }
        ], visible: true
      },
      { separator: true },
      { label: this.contextService.getLanguageTagByKey('Menu.Context.ManageUserList'), command: (event) => this.helper.manageUsersVisible.next(true) },
      { separator: true },
      {
        label: this.contextService.getLanguageTagByKey('Misc.Appearance'),
        items: [
          {
            label: this.contextService.getLanguageTagByKey('View.Details'),
            command: (event) => {
              this.setContactLayout('Details');
              this.helper.setTickIcon(this.contextMenuOutside, 0, 4, 'grp1');
            },
            icon: ' ',
            styleClass: 'grp1'
          },
          {
            label: this.contextService.getLanguageTagByKey('View.SmallIcon'),
            command: (event) => {
              this.setContactLayout('Small icons');
              this.helper.setTickIcon(this.contextMenuOutside, 1, 4, 'grp1');
            },
            icon: ' ',
            styleClass: 'grp1'
          },
          {
            label: '2 columns',
            command: (event) => {
              this.setContactLayout('2 columns');
              this.helper.setTickIcon(this.contextMenuOutside, 2, 4, 'grp1');
            },
            icon: ' ',
            styleClass: 'grp1'
          },
          { separator: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowExtension'), command: (event) => { this.toggleExt(); this.helper.setTickIcon(this.contextMenuOutside, 4, 4); }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowDepartment'), command: (event) => { this.toggleDep(); this.helper.setTickIcon(this.contextMenuOutside, 5, 4); }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowAcdState'), command: (event) => { this.toggleAcd(); this.helper.setTickIcon(this.contextMenuOutside, 6, 4); }, icon: '', visible: (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) },
          //  athul
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowTitle'), command: (event) => { this.toggleTitle(); this.helper.setTickIcon(this.contextMenuOutside, 7, 4) }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowEmail'), command: (event) => { this.toggleEmail(); this.helper.setTickIcon(this.contextMenuOutside, 8, 4) }, icon: '' },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowLocation'), command: (event) => { this.toggleLocation(); this.helper.setTickIcon(this.contextMenuOutside, 9, 4) }, icon: '' },
          //athul
          { separator: true },
          { label: this.contextService.getLanguageTagByKey('Menu.Context.Appearance.ShowCallDuration'), command: (event) => { this.toggleDuration(); } }
        ]
      }
    ];
    this.setTickClass(this.layoutType);
  }


  /**
   * set tick to layout type and and columns which are selected
   * @param layoutType 
   */
  setTickClass(layoutType: string) {
    if (layoutType === 'Details') {
      (<MenuItem>this.contextMenuItems[30].items[0]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[0]).icon = 'tick';
    } else if (layoutType === 'Small icons') {
      (<MenuItem>this.contextMenuItems[30].items[1]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[1]).icon = 'tick';
    } else if (layoutType === '2 columns') {
      (<MenuItem>this.contextMenuItems[30].items[2]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[2]).icon = 'tick';
    }
    if (!this.acdHidden) {
      (<MenuItem>this.contextMenuItems[30].items[6]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[6]).icon = 'tick';
    }
    if (!this.depHidden) {
      (<MenuItem>this.contextMenuItems[30].items[5]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[5]).icon = 'tick';

    }
    if (!this.extHidden) {
      (<MenuItem>this.contextMenuItems[30].items[4]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[4]).icon = 'tick';

    }
    //athul

    if (!this.titleHidden) {
      (<MenuItem>this.contextMenuItems[30].items[7]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[7]).icon = 'tick';

    }
    if (!this.emailHidden) {
      (<MenuItem>this.contextMenuItems[30].items[8]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[8]).icon = 'tick';

    }
    if (!this.locationHidden) {
      (<MenuItem>this.contextMenuItems[30].items[9]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[9]).icon = 'tick';
    }
    if (!this.directoryService.calldurationHidden) {
      (<MenuItem>this.contextMenuItems[30].items[11]).icon = 'tick';
      (<MenuItem>this.contextMenuOutside[4].items[11]).icon = 'tick';
    } else {
      (<MenuItem>this.contextMenuOutside[4].items[11]).icon = '';

    }

    //athul
  }
  /**
   *to set the dropdown for groups and departments filter in contact table
   *
   * @memberof ContactTableComponent
   */
  setSearchMenuDropDown() {
    let position = 3;
    let keys = Object.keys(this.directoryService.groupAndDepartmentList).sort();
    if (keys.length > 0) {
      keys.forEach(key => {
        let tempKey = key;
        this.searchMenu.push({ separator: true });
        position = position + 1;
        let pos: string = position.toString();
        this.searchMenu.push({ label: this.contextService.objLoggedUserDetails.getgroupNamesForClientDirectory(key), command: (event) => { this.setTickIcon(pos, tempKey) }, icon: '', styleClass: 'srch' });
        position = position + 1;
        if (this.directoryService.groupAndDepartmentList[key] !== []) {
          this.directoryService.groupAndDepartmentList[key].sort();
          this.directoryService.groupAndDepartmentList[key].forEach(element => {
            let tempDept = element;
            let pos: string = position.toString();
            this.searchMenu.push({ label: '\xa0\xa0\xa0\xa0' + element, command: (event) => { this.setTickIcon(pos, tempKey, tempDept) }, icon: '', styleClass: 'srch' });
            position = position + 1;

          });
        }
      });
    }
  }
  /**
   *set tick icon in dropdown
   * 
   * @param {*} id
   * @param {*} dirType
   * @param {*} [department]
   * @memberof ContactTableComponent
   */
  setTickIcon(id, dirType, department?) {
    this.searchTerm = '';
    this.directoryService.tempSearchTerm = "";
    this.datatable.reset();

    setTimeout(() => {
      this.searchMenu.forEach((element, index) => {
        element.icon = '';
      });


      if (this.tempDirType !== id) {
        this.tempDirType = id;
        this.statusHidden = true;
        this.acdHidden = true;
        this.searchMenu[id].icon = 'tick';
        /** 
         * if Broadworks Directory
        */
        if ((dirType !== "Common Directory") && (dirType !== "Personal Directory") && (dirType !== "Default List")) {
          if (department) {
            this.contextService.log("DirType-->Broadworks Directory" + "group-->" + dirType + "department-->" + department);
            this.directoryService.groupDirectoryDisplayArray("Broadworks Directory", dirType, department);
          } else {
            this.contextService.log("DirType-->Broadworks Directory" + "group-->" + dirType + "department-->");
            this.directoryService.groupDirectoryDisplayArray("Broadworks Directory", dirType, '');
          }
          this.mobileHidden = false;
          this.extHidden = this.tempExtHidden;
          this.depHidden = this.tempDepHidden;
          this.titleHidden = this.tempTitleHidden;
          this.emailHidden = this.tempEmailHidden;
          this.locationHidden = this.tempLocationHidden;
          this.hiddenColumn = false;
        } else {
          if (dirType === 'Default List') {
            this.tempDirType = "";
            this.statusHidden = false;
            this.extHidden = this.tempExtHidden;
            this.depHidden = this.tempDepHidden;
            this.acdHidden = this.tempACDHidden;
            this.titleHidden = this.tempTitleHidden;
            this.emailHidden = this.tempEmailHidden;
            this.locationHidden = this.tempLocationHidden;
            this.mobileHidden = true;
            setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 100);
            this.contextService.log("DirType-->" + "group-->" + "department-->");
            this.directoryService.groupDirectoryDisplayArray('', '', '');
          } else {
            this.hiddenColumn = true;
            this.extHidden = true;
            this.depHidden = true;
            this.mobileHidden = true;
            this.titleHidden = true;
            this.emailHidden = true;
            this.locationHidden = true;
            this.contextService.log("DirType-->" + dirType + "group-->" + "department-->");
            this.directoryService.groupDirectoryDisplayArray(dirType, '', '');
          }

        }
        setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 100);
      } else {
        this.tempDirType = "";
        this.searchMenu[0].icon = 'tick';

        this.statusHidden = false;
        this.extHidden = this.tempExtHidden;
        this.depHidden = this.tempDepHidden;
        this.acdHidden = this.tempACDHidden;
        this.titleHidden = this.tempTitleHidden;
        this.emailHidden = this.tempEmailHidden;
        this.locationHidden = this.tempLocationHidden;
        this.mobileHidden = true;
        setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 100);
        this.contextService.log("DirType-->" + "group-->" + "department-->");
        this.directoryService.groupDirectoryDisplayArray('', '', '');
      }
    }, 50);

    setTimeout(() => {
      this.calcContactsHeight();
    }, 50);
    this.setSort();
  }

  /**
   *filter contacts according to the search term
   *
   * @param {*} searchTerm
   * @memberof ContactTableComponent
   */
  filterDirectoryDisplayArray(searchTerm) {
    this.statusHidden = false;
    this.extHidden = this.tempExtHidden;
    this.depHidden = this.tempDepHidden;
    this.acdHidden = this.tempACDHidden;
    this.titleHidden = this.tempTitleHidden;
    this.emailHidden = this.tempEmailHidden;
    this.locationHidden = this.tempLocationHidden;
    this.mobileHidden = true;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 400);
    this.directoryService.filterDirectoryDisplayArray(searchTerm);
    this.tempDirType = "";

    if (!(searchTerm.length > 1) && (this.searchMenu)) {
      this.searchMenu.forEach(element => {
        element.icon = '';
      });
    }

    //sort the search results according to settings
    if (searchTerm !== '') {
      let searchSortField = "";
      if (this.contextService.objSettings.contact_showMonitoredUsersFirst) {
        searchSortField = "isMonitor"
      } else if (this.contextService.objSettings.contact_alwaysSortSearchByName) {
        searchSortField = "name";
      }
      if (searchSortField !== "") {
        this.datatable.sortField = searchSortField;
        this.datatable.sortOrder = searchSortField == "isMonitor" ? -1 : 1;
        this.datatable.sortSingle();
      }
    } else {
      this.setSort();
    }
  }

  ngAfterViewInit() {
    if (this.contacts) {
      setTimeout(() => {
        this.calcContactsHeight();
      }, 1000);
    }
    this.setTickIcon(0, "Default List");
  }

  calcContactsHeight(layoutType?) {
    const heights = this.helper.calcTableHeight(this.el, this.directoryService.directoryDisplayArray, 73, layoutType);
    this.contactTableHeight = heights.th;
    this.contactTableMenuHeight = heights.mh;
    this.scrolling = heights.scrolling;
  }

  /**
   * to toggle between showing and not showing duration in status
   *
   * @memberof ContactTableComponent
   */
  toggleDuration() {
    this.directoryService.calldurationHidden = !this.directoryService.calldurationHidden;
    this.directoryService.timerUnsubcribe();

    this.contextService.objSettings.dir_CallDuration_Hidden = this.directoryService.calldurationHidden;
    this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
  }

  /**
   * toggles ACD state visibility
   *
   * @memberof ContactTableComponent
   */
  toggleAcd() {
    this.acdHidden = !this.acdHidden;
    this.tempACDHidden = this.acdHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_ACDStatus_Hidden = this.acdHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }

  /**
   * toggle department column visibility
   *
   * @memberof ContactTableComponent
   */
  toggleDep() {
    this.depHidden = !this.depHidden;
    this.tempDepHidden = this.depHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_Department_Hidden = this.depHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }


  //athul
  /**
   * toggle title column visibility
   *
   * @memberof ContactTableComponent
   */
  toggleTitle() {
    this.titleHidden = !this.titleHidden;
    this.tempTitleHidden = this.titleHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_Title_Hidden = this.titleHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }

  /**
 * toggle email column visibility
 *
 * @memberof ContactTableComponent
 */
  toggleEmail() {
    this.emailHidden = !this.emailHidden;
    this.tempEmailHidden = this.emailHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_Email_Hidden = this.emailHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }
  toggleLocation() {
    this.locationHidden = !this.locationHidden;
    this.tempLocationHidden = this.locationHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_Location_Hidden = this.locationHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }

  //athul
  /**
   * toggle department coloumn visibility
   *
   * @memberof ContactTableComponent
   */
  toggleExt() {
    this.extHidden = !this.extHidden;
    this.tempExtHidden = this.extHidden;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);

    this.contextService.objSettings.dir_Extension_Hidden = this.extHidden;
    this.contextService.websocketService.settings(this.contextService.objSettings);
    this.setSort();
  }

  rowClick(e) {
    this.helper.disableRows(this.contactsTable);
  }

  colResized(e) {
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);
  }


  /**
   * configures context menu according to conditions
   *
   * @param {*} event
   * @memberof ContactTableComponent
   */
  contextMenuSelected(event, cm) {
    this.helper.hideAllCMenus();
    this.helper.saveAllCMenus(cm);
    this.helper.disableRows(this.contactsTable);
    /** 
     * checks whether the user is on call
    */
    this.isOnCall = false;
    for (let index = 0; index < this.contextService.callStatusList.length; index++) {
      if (this.contextService.callStatusList[index].callStatus == 'Active') {
        this.activeCallId = this.contextService.callStatusList[index].callId;
        this.isOnCall = true;
        break;
      } else {
        this.isOnCall = false;
      }

    }
    for (let index = 0; index < this.contextService.callStatusList.length; index++) {
      if (this.contextService.callStatusList[index].remoteUserId === event.data.userId) {
        this.showCallingFuntionalities = false;
        break;
      } else {
        this.showCallingFuntionalities = true;
      }

    }
    if (this.contextService.callStatusList.length < 1) {
      this.showCallingFuntionalities = true;
    }

    this.loadContextMenu();
    /** 
     * sets the selected contact in directory service
    */
    if (event !== undefined) {
      this.directoryService.setDirectoryView(event.data);
      this.setContextMenu(event);
      cm.show();
    } else {
      //athul
      let objectFound;
      if (this.layoutType === 'Small icons') {

        objectFound = this.contextMenuOutside[4].items[3];
        objectFound.visible = false;
        objectFound.separator = false;
        // hide show extension, department, acd state
        (<MenuItem>this.contextMenuOutside[4].items[4]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[5]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[6]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[7]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[8]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[9]).visible = false;

        objectFound = this.contextMenuOutside[4].items[10];
        objectFound.visible = false;
        objectFound.separator = false;
        // hide call duration
        (<MenuItem>this.contextMenuOutside[4].items[11]).visible = false;

      } else if (this.layoutType === '2 columns') {

        // hide call duration

        objectFound = this.contextMenuOutside[4].items[10];
        objectFound.visible = false;
        objectFound.separator = false;
        // hide call duration
        (<MenuItem>this.contextMenuOutside[4].items[11]).visible = false;

        objectFound = this.contextMenuOutside[4].items[3];
        objectFound.visible = true;
        objectFound.separator = true;
        //show ectension and department in outside contextmenu
        (<MenuItem>this.contextMenuOutside[4].items[4]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[5]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[6]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[7]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[8]).visible = false;
        (<MenuItem>this.contextMenuOutside[4].items[9]).visible = false;

      } else {
        objectFound = this.contextMenuOutside[4].items[3];
        objectFound.visible = true;
        objectFound.separator = true;
        //show ectension and department and acd state in outside contextmenu
        (<MenuItem>this.contextMenuOutside[4].items[4]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[5]).visible = true;
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
          (<MenuItem>this.contextMenuOutside[4].items[6]).visible = true;
        }
        (<MenuItem>this.contextMenuOutside[4].items[7]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[8]).visible = true;
        (<MenuItem>this.contextMenuOutside[4].items[9]).visible = true;
        objectFound = this.contextMenuOutside[4].items[10];
        objectFound.visible = true;
        objectFound.separator = true;
        // show call duration
        (<MenuItem>this.contextMenuOutside[4].items[11]).visible = true;
      }

      //athul
      if (this.tempDirType !== "") {
        let objectFound;
        this.contextMenuOutside[2].visible = false;
        objectFound = this.contextMenuOutside[1];
        objectFound.visible = false;
        objectFound.separator = false;
        objectFound = this.contextMenuOutside[3];
        objectFound.visible = false;
        objectFound.separator = false;
        this.contextMenuOutside[4].visible = false;
      } else {
        let objectFound;
        this.contextMenuOutside[2].visible = true;
        objectFound = this.contextMenuOutside[1];
        objectFound.visible = true;
        objectFound.separator = true;
        objectFound = this.contextMenuOutside[3];
        objectFound.visible = true;
        objectFound.separator = true;
        this.contextMenuOutside[4].visible = true;
      }
      if (this.contextService.objLoggedUserDetails.getlicenseUserListMode() == "None") {
        let objectFound;
        this.contextMenuOutside[2].visible = false;
        objectFound = this.contextMenuOutside[1];
        objectFound.visible = false;
        objectFound.separator = false;
      }
    }
  }
  /**
   *set the context menu for that contact
   *
   * @param {*} event
   * @memberof ContactTableComponent
   */
  setContextMenu(event) {

    let objectFound;
    if (this.layoutType === 'Small icons') {
      objectFound = this.contextMenuItems[30].items[3];
      objectFound.visible = false;
      objectFound.separator = false;
      // hide show extension, department, acd state
      (<MenuItem>this.contextMenuItems[30].items[4]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[5]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[6]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[7]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[8]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[9]).visible = false;

      objectFound = this.contextMenuItems[30].items[10];
      objectFound.visible = false;
      objectFound.separator = false;
      (<MenuItem>this.contextMenuItems[30].items[11]).visible = false;
      // hide call duration
      (<MenuItem>this.contextMenuItems[30].items[8]).visible = false;

      objectFound = this.contextMenuOutside[4].items[3];
      objectFound.visible = false;
      objectFound.separator = false;
      // hide show extension, department, acd state
      (<MenuItem>this.contextMenuOutside[4].items[4]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[5]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[6]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[7]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[8]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[9]).visible = false;
      objectFound = this.contextMenuOutside[4].items[10];
      objectFound.visible = false;
      objectFound.separator = false;
      // hide call duration
      (<MenuItem>this.contextMenuOutside[4].items[11]).visible = false;

    } else if (this.layoutType === '2 columns') {
      objectFound = this.contextMenuItems[30].items[10];
      objectFound.visible = false;
      objectFound.separator = false;
      // hide show title and show email and show location
      (<MenuItem>this.contextMenuItems[30].items[7]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[8]).visible = false;
      (<MenuItem>this.contextMenuItems[30].items[9]).visible = false;
      // hide call duration
      (<MenuItem>this.contextMenuItems[30].items[11]).visible = false;

      objectFound = this.contextMenuOutside[4].items[10];
      objectFound.visible = false;
      objectFound.separator = false;
      // hide call duration
      (<MenuItem>this.contextMenuOutside[4].items[11]).visible = false;

      objectFound = this.contextMenuOutside[4].items[3];
      objectFound.visible = true;
      objectFound.separator = true;
      //show ectension and department in outside contextmenu
      (<MenuItem>this.contextMenuOutside[4].items[4]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[5]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[6]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[7]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[8]).visible = false;
      (<MenuItem>this.contextMenuOutside[4].items[9]).visible = false;

    } else {
      objectFound = this.contextMenuOutside[4].items[3];
      objectFound.visible = true;
      objectFound.separator = true;
      //show ectension and department and acd state in outside contextmenu
      (<MenuItem>this.contextMenuOutside[4].items[4]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[5]).visible = true;
      if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
        (<MenuItem>this.contextMenuOutside[4].items[6]).visible = true;
      }
      (<MenuItem>this.contextMenuOutside[4].items[7]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[8]).visible = true;
      (<MenuItem>this.contextMenuOutside[4].items[9]).visible = true;
      objectFound = this.contextMenuOutside[4].items[10];
      objectFound.visible = true;
      objectFound.separator = true;
      // show call duration
      (<MenuItem>this.contextMenuOutside[4].items[11]).visible = true;
    }

    if (this.tempDirType !== "") {
      objectFound = this.contextMenuItems[27];
      objectFound.visible = false;
      objectFound.separator = false;
      // manage user list
      this.contextMenuItems[28].visible = false;

      objectFound = this.contextMenuItems[29];
      objectFound.visible = false;
      objectFound.separator = false;
      // appearence
      this.contextMenuItems[30].visible = false;

      objectFound = this.contextMenuOutside[1];
      objectFound.visible = false;
      objectFound.separator = false;
      // manage user list
      this.contextMenuOutside[2].visible = false;

      objectFound = this.contextMenuOutside[3];
      objectFound.visible = false;
      objectFound.separator = false;
      // appearence
      this.contextMenuOutside[4].visible = false;
      objectFound.visible = false;
    } else {
      objectFound = this.contextMenuOutside[1];
      objectFound.visible = true;
      objectFound.separator = true;
      // manage user list
      this.contextMenuOutside[2].visible = true;

      objectFound = this.contextMenuOutside[3];
      objectFound.visible = true;
      objectFound.separator = true;
      // appearence
      this.contextMenuOutside[4].visible = true;
    }
    if (this.contextService.objLoggedUserDetails.getlicenseUserListMode() == "None") {
      this.contextMenuItems[28].visible = false;

      objectFound = this.contextMenuItems[29];
      objectFound.visible = false;
      objectFound.separator = false;
    }
    if (event !== undefined) {
      if (event.data.type === 'Personal Directory') {
        if (event.data.phone != '') {
          // call number
          this.contextMenuItems[3].visible = true;
          this.setSeperator(4);
          // copy number
          this.contextMenuItems[23].visible = true;
          this.setSeperator(25);
        }
        if (this.isOnCall) {
          // transfer to number
          this.contextMenuItems[7].visible = true;
          this.contextMenuItems[10].visible = true;
          this.setSeperator(11);
        }
        // personal directory edit
        objectFound = this.contextMenuItems[26].items[0];
        objectFound.visible = true;
        objectFound.label = 'Edit' + " " + event.data.name;
        // personal directory remove
        objectFound = this.contextMenuItems[26].items[1];
        objectFound.visible = true;
        objectFound.label = 'Remove' + " " + event.data.name;

        objectFound = this.contextMenuItems[26].items[2];
        objectFound.visible = true;
        objectFound.separator = true;

      }
      if (event.data.type === 'Common Directory') {
        if (event.data.phone != '') {
          // call mobile        
          this.contextMenuItems[3].visible = true;
          this.setSeperator(4);
          // copy number
          this.contextMenuItems[23].visible = true;
          this.setSeperator(25);
        }
        if (this.isOnCall) {
          //  transfer to number
          this.contextMenuItems[7].visible = true;
          this.contextMenuItems[10].visible = true;
          this.setSeperator(11);
        }
      }
      if (event.data.type === 'Broadworks Directory') {
        if (event.data.name.search("(Mobile)") != -1) {
          // call mobile              
          this.contextMenuItems[3].visible = true;
          this.setSeperator(4);
          // compy number
          this.contextMenuItems[23].visible = true;
          this.setSeperator(25);
          if (this.isOnCall) {
            // tansfer to number(mobile)
            this.contextMenuItems[7].visible = true;
            this.contextMenuItems[10].visible = true;
            this.setSeperator(11);
          }
        }
        else {

          if (event.data.extension != '' && this.showCallingFuntionalities) {
            // call extension
            this.contextMenuItems[0].visible = true;
            if (this.isOnCall == true) {
              this.contextService.log("is on call");

              this.contextMenuItems[5].visible = true;
              //warm transfer to extension
              // if (this.contextService.objSettings.allow_WarmTransfer) {
              this.contextMenuItems[8].visible = true;
              this.setSeperator(11);
              // }
            }
          }
          if (event.data.mobile != '') {
            // call mobile
            if (this.showCallingFuntionalities) {
              this.contextMenuItems[2].visible = true;
            }
            // copy mobile
            this.contextMenuItems[24].visible = true;
            if (this.isOnCall && this.showCallingFuntionalities) {
              //transfer call to mobile
              this.contextMenuItems[6].visible = true;
              //warm transfer to mobile
              // if (this.contextService.objSettings.allow_WarmTransfer) {
              this.contextMenuItems[9].visible = true;
              this.setSeperator(11);
              // }
            }
          }
          if (this.showCallingFuntionalities) {
            this.setSeperator(4);
          }
          // compy number
          this.contextMenuItems[23].visible = true;
          this.setSeperator(25);
        }
        // view user details
        this.contextMenuItems[19].visible = true;
        this.setSeperator(20);

        if (event.data.userId in this.contextService.monitoredUserList) {
          for (let m in this.contextService.monitoredUserList[event.data.userId].callDetails) {
            if (this.showCallingFuntionalities) {
              if (this.contextService.monitoredUserList[event.data.userId].callDetails[m].callState == "Talking to") {
                //shows barge-in to call
                if (this.contextService.assignedServices.includes("DirectedCallPickupwithBarge-in")) {
                  this.contextMenuItems[17].visible = true;
                  this.setSeperator(18);
                }
                //shows call extension when available
                this.contextMenuItems[1].visible = true;
                this.setSeperator(4);

              } else {
                //Shows answer this call
                if (this.contextService.assignedServices.includes("DirectedCallPickup") || this.contextService.isCallPickUpAssigned) {
                  this.contextMenuItems[16].visible = true;
                  this.setSeperator(18);
                }
              }
            }
          }
        }


        if (this.isOnCall && this.showCallingFuntionalities) {
          if (this.contextService.monitoredUserList[event.data.userId]) {

            if (this.contextService.monitoredUserList[event.data.userId].voicemailAssigned) {
              this.contextMenuItems[12].visible = true;
              this.setSeperator(13);
            }



          } else if (this.contextService.objSettings.allow_WarmTransfer_To_Voicemail) {
            this.contextMenuItems[12].visible = true;
            this.setSeperator(13);
          }
          //park on extension
          if (this.contextService.isCallParkAssigned) {
            this.contextMenuItems[14].visible = true;
            this.setSeperator(15);
          }


        }
        if (this.contextService.isImConnected) {
          // Instant message
          // todo
          if (this.contextService.checkIfContactCanBeMonitored(this.contextService.getFirstNameFromUserID(this.selectedContact.userId), false)) {
            this.contextMenuItems[21].visible = true;
            this.setSeperator(22);
          }

        }
      } if (event.data.type === 'Outlook Directory') {

        if (event.data.phone != '') {
          // call number
          this.contextMenuItems[3].visible = true;
          this.setSeperator(4);
          // copy number
          this.contextMenuItems[23].visible = true;
          this.setSeperator(25);
        }
        if (this.isOnCall) {
          // transfer to number
          this.contextMenuItems[7].visible = true;
          //warm transfer to number
          // if (this.contextService.objSettings.allow_WarmTransfer) {
          this.contextMenuItems[10].visible = true;
          this.setSeperator(11);
          // }
        }
      }
    }
  }

  /**
   * to set seperator in context menu
   *
   * @param {*} position
   * @memberof ContactTableComponent
   */
  setSeperator(position) {
    let objectFound = this.contextMenuItems[position];
    objectFound.visible = true;
    objectFound.separator = true;
  }

  sendIM(contact) {
    this.chatService.droppedToChat.next(contact[0]);
  }

  // demo - logic should be somewhere in settings
  switchFilter(type) {
    if (type === 'alphabet') {
      this.contactFilterType = 'location';
    } else {
      this.contactFilterType = 'alphabet';
    }
  }

  letterClick(letter) {
    this.clickedLetter = letter;
  }

  setContactLayout(layout: string) {
    this.layoutType = layout;
    setTimeout(() => { this.helper.calcCellsWidth(this.contactsTable) }, 200);
    setTimeout(() => {
      this.calcContactsHeight(this.layoutType);
    }, 500);

    if (this.contextService.objLoggedUserDetails != undefined) {
      this.contextService.objSettings.dir_Appearance = layout;
      this.contextService.websocketService.settings(this.contextService.objSettings);
    }
  }

  //dial request when double click on contact
  onRowDblclick(event) {
    if (event.type === 'Broadworks Directory') {
      this.contextService.websocketService.dialAction(event.extension);
    } else {
      this.contextService.websocketService.dialAction(event.phone);

    }
  }
  /**
   * clear search term
   *
   * @memberof ContactTableComponent
   */
  clearSearch() {
    this.datatable.reset();
    this.searchTerm = '';
    this.directoryService.filterDirectoryDisplayArray('');
    this.setSort();
  }
  clearSearchOnActivation() {
    if (this.contextService.objSettings.contact_clearSearchBox) {
      this.clearSearch();
    }
  }
  /**
   *to save sort field and sort to settings file
   *
   * @param {*} event
   * @memberof ContactTableComponent
   */
  changeSort(event) {
    if (event.field !== "isMonitor" && !(event.field == "name" && this.contextService.objSettings.contact_alwaysSortSearchByName && this.searchTerm !== "")) {
      this.contextService.objSettings.dir_sortOrder = event.order;
      this.contextService.objSettings.dir_sortField = event.field;
      if (this.contextService.objLoggedUserDetails) {
        this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
      }
    }
  }

  contactsfilter() {
    this.contextService.log("tempDirType " + this.tempDirType);
    if (this.tempDirType === "") {
      this.searchMenu[0].icon = 'tick';
    }
  }
  mouseenterOnContact(contact) {
    if (this.layoutType != 'Details') {
      console.log("mouseenterOnContact: " + contact.name);
      this.rowToolTip = contact.status;
    }
  }

  mouseleaveOnContact() {
    this.rowToolTip = "";
  }
}
