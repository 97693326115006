import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//agentp3
import { CallCenterAuthentication } from 'app/model/agent/callCenterAuthentication';
import { AgentactivityService } from 'app/providers/agentactivity.service';
import { HelperService } from 'app/providers/helper.service';
import { timer } from 'rxjs';
import { Subscription } from 'rxjs';
import { Settings } from "../../../model/settings-models/settings";
import { ContextService } from '../../context.service';
/**
 * service used in settings 
 * 
 * @export
 * @class SettingsGeneral
 */
@Injectable()

export class SettingsGeneral {

    restart: boolean = false;
    restartIM: boolean = false;
    loginidchanged: boolean = false;
    objSettings: Settings;

    appearence_unityOnTop: boolean;
    appearence_showMissedCallCount: boolean;

    dialingRule_toReplace: string;
    dialingRule_toReplaceWith: string;
    // 0-ignore for incomming calls
    // 1-use for incomming calls
    // 2-reverse this rules for incomming calls
    dialingRule_forIncomingCalls: string;


    callNoti_showRemotePartyPhoneNumber: boolean;
    callNoti_overRideNameUsingNumber: boolean;
    callNoti_autoAnswer: boolean;
    callNoti_popUnityOnCall: boolean;
    callNoti_autoHideUnity: boolean;
    callNoti_popSummaryNotification: boolean;
    callNoti_popSummarySeconds: number;

    startUpApp: number;

    update_autoUpdateUnity: boolean;
    update_updateCheckIntervall: number;
    // 0-alert
    // 1-autorestart
    update_restartOrAlertAfterUpdate: string;

    selectedLanguage: string;

    bwServerAddress: string;
    bwServerPort: string;
    imServerAddress: string;
    imServerPort: string = "";

    loginID: string;
    password: string;
    rememberLoginId: boolean;
    rememberPassword: boolean;

    // for other purposes
    domainAbsent: boolean = false;
    sub: Subscription;
    manualAlert: boolean = false;
    updateAvailabe: boolean = false;
    showUpdateAvailable: boolean = false;
    alertMessage: string = '';

    //agent
    selectJoinQueue: boolean = false;
    automaticallyAnswerCall: boolean = false;
    leaveAllQueue: boolean = false;

    startUpACDState: string = "";
    postCallACDState: string = "";
    wrapUpDuration: number = 0;
    wrapUpDurationLimit: boolean = false;
    showCurrentACDStateButton: boolean = false;
    signOutACDState: boolean = false;
    assignDispositionCode: boolean = false;
    dispositionTime: number = 0;
    showDuration: string = "";
    includeCallCenter: boolean = false;
    preventACDStateChage: boolean = false;
    onlyShowMonitoringSupervisor: boolean = false;
    showQueuedCalls: boolean = false;
    //agent
    //agentP3
    selectedCallCenterColumn: any = "";
    selectedQueueColumn: any = "";
    disableQueueColumnList: boolean = false;
    previousQueuePage: string = "";
    callCenterID: string = "";
    callCenterPassword: string = "";
    passwordDeleteButtonVisisbility: boolean = false;
    passwordToCheck: string = "";
    defaultPassword: string = "";
    defaultPasswordToCheck: string = "";
    applyToAllQueues: boolean = false;
    defaultPasswordDeleteButton: boolean = false;
    nullPassword: boolean = false;
    showInWallBoard: boolean = true;
    displaySummaryStatisticsOnly: boolean = false;
    scrollbarCountOfPW: string = "2";
    processCallCenterBatches: string = "1";
    refreshFrequencyStart: string = "60";
    refreshFrequencyEnd: string = "300";

    removePasswordVis: boolean = false;
    currentCallCenterAuthPage: string = "0";
    currentCallCenterAgentPage: string = "0";
    currentCallCenterQueuePage: string = "0";
    statisticsToDisplay: string = "";
    queueColumnRemoveItemsList = [];
    selectQueueTab: number = 0;
    showElements: boolean = false;
    showThresholdValues: boolean = false;
    highlightNonZeroValues: boolean = false;
    increasingThresholdValues: boolean = false;
    greenThreshold: string = "0";
    yellowThreshold: string = "0";
    redThreshold: string = "0";
    blackThreshold: string = "0";
    disableAgentColumnList: boolean = false;
    deleteAgentVis: boolean = false;
    deleteQueueVis: boolean = false;

    selectedAgentColumn: any = "";

    agentColumnsInTable = [];
    queueColumnsInTable = [];
    refreshFreqTimer: Subscription;
    applyAllQueuesList = [];
    applyAllQueuesMasterID: string = "";
    showCallCenterLoginFailedAlert: boolean = false;
    settingsChanged: boolean = false;
    defaultCCPasswordExistInLicense: boolean = false;
    //agentP3


    browserInt_URL: string = "";
    browserInt_InboundCallCenter: boolean = false;
    browserInt_InboundNonCallCenter: boolean = false;
    browserInt_OutboundCallCenter: boolean = false;
    browserInt_OutbountNonCallCenter: boolean = false;
    browserInt_EncodeURL: boolean = false;
    browserInt_browserInterface: boolean = false;
    showBusinessNameColumn: boolean = false;
    callParkRetrieveShowButton: boolean = false;
    allowWarmTransfer: boolean = false;
    allowWarmTransferToVoicemail: boolean = false;
    surveyExtension: string = "";
    contact_includeAllContact: boolean = false;
    contact_showMonitoredUsersFirst: boolean = false;
    contact_alwaysSortSearchByName: boolean = false;
    contact_includeDepartmentInSearch: boolean = false;
    contact_clearSearchBox: boolean = false;
    contact_privacyEnabled: boolean = false;
    contact_activateContactSearch: boolean = false;
    xsiServerAddress: string = "";
    contact_startsWith: string;
    //supervisor
    selectedAgentActivityColumn: any = "";
    agentActivityColumnsInTable = [];
    deleteAgentActivityVis: boolean = false;
    supervisor_showOnlyJoinedAgents: boolean = false;
    supervisor_supervisedAgentsStaffedRatio: boolean = false;
    supervisor_activityImage: string;
    supervior_addAllAgentsToAgentList: boolean;
    currentAgentActivityPage: string = "";
    noThresholdList = [];
    disableAgentActivityColumnList: boolean;
    showACDStateTable: boolean;
    selectedACDStateRow: any = "";
    previousAgentActivityPage: string;
    isThresholdShows: boolean = true;
    callPriority: boolean = false;
    //supervisor
    constructor(private router: Router, private helper: HelperService, public contextService: ContextService, public agentactivityService: AgentactivityService) {
        this.contextService.log('###### constructor - SettingsGeneral');
        this.appearence_unityOnTop = this.contextService.objSettings.appearance_AlwaysOnTop;
        this.appearence_showMissedCallCount = this.contextService.objSettings.appearance_MissedCallCountVisible;
        this.dialingRule_toReplace = this.contextService.objSettings.dialingRule_ToBeReplaced;
        this.dialingRule_toReplaceWith = this.contextService.objSettings.dialingRule_ReplacingValue;
        this.dialingRule_forIncomingCalls = this.contextService.objSettings.dialingRule_forIncomingCalls;
        this.callNoti_showRemotePartyPhoneNumber = this.contextService.objSettings.callNotification_ShowRemotePartyNumber;
        this.callNoti_overRideNameUsingNumber = this.contextService.objSettings.callNotification_OverrideContactName;
        this.callNoti_autoAnswer = this.contextService.objSettings.callNotification_AutoAnswer;
        this.callNoti_popUnityOnCall = this.contextService.objSettings.callNotification_PopUpUnity;
        this.callNoti_autoHideUnity = this.contextService.objSettings.callNotification_AutoHide;
        this.callNoti_popSummaryNotification = this.contextService.objSettings.callNotification_PopUpSummary;
        this.callNoti_popSummarySeconds = this.contextService.objSettings.callNotification_PopUpSummaryDuration;
        this.startUpApp = this.contextService.objSettings.STARTUP;
        this.contact_startsWith = this.contextService.objSettings.contact_startsWith;
        this.update_autoUpdateUnity = this.contextService.objSettings.update_AutoUpdatePermitted;
        this.update_updateCheckIntervall = this.contextService.objSettings.update_UpdateInterval;
        this.update_restartOrAlertAfterUpdate = this.contextService.objSettings.update_RestartOrAlertAfterUpdate;
        this.selectedLanguage = this.contextService.objSettings.language_selected;
        this.bwServerAddress = this.contextService.objSettings.network_BWServerAddress;
        this.bwServerPort = this.contextService.objSettings.network_BWServerPort;
        this.imServerAddress = this.contextService.objSettings.network_IMServerAddress;
        this.imServerPort = this.contextService.objSettings.network_IMServerPort;
        this.loginID = this.contextService.objSettings.broadworks_Login;
        this.password = this.contextService.objSettings.broadworks_Password;
        this.rememberLoginId = this.contextService.objSettings.broadworks_RememberBroadworksLogin;
        this.rememberPassword = this.contextService.objSettings.broadworks_RememberBroadworksPassword;

        this.browserInt_URL = this.contextService.objSettings.browserInt_URL;
        this.browserInt_InboundCallCenter = this.contextService.objSettings.browserInt_InboundCallCenter;
        this.browserInt_InboundNonCallCenter = this.contextService.objSettings.browserInt_InboundNonCallCenter;
        this.browserInt_OutboundCallCenter = this.contextService.objSettings.browserInt_OutboundCallCenter;
        this.browserInt_OutbountNonCallCenter = this.contextService.objSettings.browserInt_OutbountNonCallCenter;
        this.browserInt_EncodeURL = this.contextService.objSettings.browserInt_EncodeURL;
        this.browserInt_browserInterface = this.contextService.objSettings.browserInt_browserInterface;
        this.showBusinessNameColumn = this.contextService.objSettings.showBusinessNameColumn;

        this.callParkRetrieveShowButton = this.contextService.objSettings.callPark_Retrieve_ShowButton;
        this.allowWarmTransfer = this.contextService.objSettings.allow_WarmTransfer;
        this.allowWarmTransferToVoicemail = this.contextService.objSettings.allow_WarmTransfer_To_Voicemail;
        this.surveyExtension = this.contextService.objSettings.survey_Extension;

        this.contact_includeAllContact = this.contextService.objSettings.contact_includeAllContact;
        this.contact_showMonitoredUsersFirst = this.contextService.objSettings.contact_showMonitoredUsersFirst;
        this.contact_alwaysSortSearchByName = this.contextService.objSettings.contact_alwaysSortSearchByName;
        this.contact_includeDepartmentInSearch = this.contextService.objSettings.contact_includeDepartmentInSearch;
        this.contact_clearSearchBox = this.contextService.objSettings.contact_clearSearchBox;
        this.contact_privacyEnabled = this.contextService.objSettings.contact_privacyEnabled;
        this.contact_activateContactSearch = this.contextService.objSettings.contact_activateContactSearch;



        this.contextService.loadSettingsToUI.subscribe(() => {
            this.loadSettings();
        });

        //agent
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
            this.selectJoinQueue = this.contextService.objSettings.callCenter_JoinAllQueue;
            this.leaveAllQueue = this.contextService.objSettings.callCenter_LeaveAllQueue;
            this.automaticallyAnswerCall = this.contextService.objSettings.agent_AutomaticCallAnswer;
            this.startUpACDState = this.contextService.objSettings.acdState_StartupACDState;
            this.postCallACDState = this.contextService.objSettings.acdState_PostCallACDState;
            this.wrapUpDuration = this.contextService.objSettings.acdState_WrapUpDuration;
            this.showCurrentACDStateButton = this.contextService.objSettings.acdState_ShowCurrentACDStateButton;
            this.signOutACDState = this.contextService.objSettings.acdState_SignOutACDState;
            this.assignDispositionCode = this.contextService.objSettings.acdState_AssignDispositionCode;
            this.dispositionTime = this.contextService.objSettings.acdState_DispositionTime;
            this.showDuration = this.contextService.objSettings.acdState_ShowDuration;
            this.includeCallCenter = this.contextService.objSettings.agent_IncludeCallCenterCalls;
            this.preventACDStateChage = this.contextService.objSettings.acdState_PreventACDStateChage;
            this.onlyShowMonitoringSupervisor = this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor;
            this.showQueuedCalls = this.contextService.objSettings.agent_ShowQueuedCalls;

            //agentp3
            this.refreshFrequencyEnd = this.contextService.objSettings.statistics_RefreshFrequencyEndValue;
            this.refreshFrequencyStart = this.contextService.objSettings.statistics_RefreshFrequencyStartValue;
            this.displaySummaryStatisticsOnly = this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly;
            this.scrollbarCountOfPW = this.contextService.objSettings.statistics_ScrollbarCountPersonalWB;
            this.processCallCenterBatches = this.contextService.objSettings.statistics_ProcessCallCenterCount;
            this.showCallCenterLoginFailedAlert = this.contextService.objSettings.showCallCenterLoginFailed;
            //agentP3
            this.supervisor_supervisedAgentsStaffedRatio = this.contextService.objSettings.supervisor_supervisedAgentsStaffedRatio;
            this.supervisor_showOnlyJoinedAgents = this.contextService.objSettings.supervisor_showOnlyJoinedAgents;
            this.supervisor_activityImage = this.contextService.objSettings.supervisor_activityImage;
            this.supervior_addAllAgentsToAgentList = this.contextService.objSettings.supervior_addAllAgentsToAgentList;
            this.callPriority = this.contextService.objSettings.callPriority;
            if (this.contextService.objSettings.STARTUP === 3) {
                this.noThresholdList.push("agentJoinedState");
                this.noThresholdList.push("agentACDState");
                this.noThresholdList.push("agentACDStart");
                this.noThresholdList.push("agentStatus");
                this.noThresholdList.push("agentUnavailabilityReason");
                this.noThresholdList.push("agentType");

            }
        }
        //agent
    }


    //agentP3
    loadAgentColumnDetailsOnDoubleClick(selectedColumn) {
        this.currentCallCenterAgentPage = "agentDoubleClick";
        this.disableAgentColumnList = true;

        this.contextService.agentColumnList = [];
        this.contextService.agentColumnBackupList.forEach(element => {
            this.contextService.agentColumnList.push(element);
        });
        this.contextService.agentStatisticsList.forEach(element => {
            if (selectedColumn.value == element.statName) {

                if (element.columnHeader !== "") {
                    this.contextService.columnHeading = element.columnHeader;
                } else {
                    this.contextService.columnHeading = "";
                }
                this.statisticsToDisplay = element.statName;
                this.contextService.setThreshold = element.setThreshold;
                if (!this.contextService.setThreshold) {
                    element.threshold.highLightValues = false;
                    element.threshold.isIncreasing = false;
                    element.threshold.green = "";
                    element.threshold.yellow = "";
                    element.threshold.red = "";
                    element.threshold.red = "";
                }
                this.highlightNonZeroValues = element.threshold.highLightValues;
                this.increasingThresholdValues = element.threshold.isIncreasing;
                this.greenThreshold = element.threshold.green;
                this.yellowThreshold = element.threshold.yellow;
                this.redThreshold = element.threshold.red;
                this.blackThreshold = element.threshold.black;

            }
            // if (element.columnHeader !== "") {
            //     if (element.columnHeader == selectedColumn.label) {

            //         this.statisticsToDisplay = element.statName;
            //         this.contextService.columnHeading = element.columnHeader;
            //         this.contextService.setThreshold = element.setThreshold;
            //     }
            // } else if (element.statToDisplay == selectedColumn.label) {

            //     this.statisticsToDisplay = element.statName;
            //     this.contextService.columnHeading = element.columnHeader;
            //     this.contextService.setThreshold = element.setThreshold;

            // }
            // if (element.columnHeader == selectedColumn.label || element.statToDisplay == selectedColumn.label) {
            //     this.highlightNonZeroValues = element.threshold.highLightValues;
            //     this.increasingThresholdValues = element.threshold.isIncreasing;
            //     this.greenThreshold = element.threshold.green;
            //     this.yellowThreshold = element.threshold.yellow;
            //     this.redThreshold = element.threshold.red;
            //     this.blackThreshold = element.threshold.black;


            // }
        });

        this.router.navigate(['/settings-service', 'agent-threshold']);
    }
    loadAgentActivityColumnDetailsOnDoubleClick(selectedAgentActivityColumn) {
        this.contextService.log("loadQueueColumnDetailsOnDoubleClick() entered");
        this.previousAgentActivityPage = this.currentAgentActivityPage;
        this.currentAgentActivityPage = "agentActivityDoubleClick";
        this.disableAgentActivityColumnList = true;
        this.contextService.agentActivityColumnList = [];
        this.contextService.agentActivityColumnBackupList.forEach(element => {
            this.contextService.agentActivityColumnList.push(element);
        });
        this.contextService.agentActivityStatisticsList.forEach(element => {
            if (selectedAgentActivityColumn.value == element.statName) {
                if (element.columnHeader !== "") {
                    this.contextService.columnHeading = element.columnHeader;
                } else {
                    this.contextService.columnHeading = "";
                }
                this.contextService.agentActivityStatisticsToDisplay = element.statName;
                this.contextService.setThreshold = element.setThreshold;
                if (element.statName === "agentACDDuration") {
                    this.contextService.loadAgentActivityData();
                } else {
                    if (!this.contextService.setThreshold) {
                        element.threshold.highLightValues = false;
                        element.threshold.isIncreasing = false;
                        element.threshold.green = "";
                        element.threshold.yellow = "";
                        element.threshold.red = "";
                        element.threshold.red = "";
                    }
                    this.highlightNonZeroValues = element.threshold.highLightValues;
                    this.increasingThresholdValues = element.threshold.isIncreasing;
                    this.greenThreshold = element.threshold.green;
                    this.yellowThreshold = element.threshold.yellow;
                    this.redThreshold = element.threshold.red;
                    this.blackThreshold = element.threshold.black;
                }

            }
            // if (element.columnHeader !== "") {
            //     if (element.columnHeader == selectedColumn.label) {
            //         this.contextService.log("loadQueueColumnDetailsOnDoubleClick() ---> column header exist");
            //         this.contextService.queueStatisticsToDisplay = element.statName;
            //         this.contextService.columnHeading = element.columnHeader;
            //         this.contextService.setThreshold = element.setThreshold;
            //     }
            // } else if (element.statToDisplay == selectedColumn.label) {
            //     this.contextService.log("loadQueueColumnDetailsOnDoubleClick() ---> column header not exist");
            //     this.contextService.queueStatisticsToDisplay = element.statName;
            //     this.contextService.columnHeading = element.columnHeader;
            //     this.contextService.setThreshold = element.setThreshold;
            // }
            // this.contextService.loadQueueData();

        });

        this.router.navigate(['/settings-service', 'agent-activity-threshold']);
    }
    loadQueueColumnDetailsOnDoubleClick(selectedColumn) {
        this.contextService.log("loadQueueColumnDetailsOnDoubleClick() entered");
        this.previousQueuePage = this.currentCallCenterQueuePage;
        this.currentCallCenterQueuePage = "queueDoubleClick";
        this.disableQueueColumnList = true;
        this.contextService.queueColumnList = [];
        this.contextService.queueColumnBackupList.forEach(element => {
            this.contextService.queueColumnList.push(element);
        });
        this.contextService.queueStatisticsList.forEach(element => {
            if (selectedColumn.value == element.statName) {
                if (element.columnHeader !== "") {
                    this.contextService.columnHeading = element.columnHeader;
                } else {
                    this.contextService.columnHeading = "";
                }
                this.contextService.queueStatisticsToDisplay = element.statName;
                this.contextService.setThreshold = element.setThreshold;
                this.contextService.loadQueueData();
            }
            // if (element.columnHeader !== "") {
            //     if (element.columnHeader == selectedColumn.label) {
            //         this.contextService.log("loadQueueColumnDetailsOnDoubleClick() ---> column header exist");
            //         this.contextService.queueStatisticsToDisplay = element.statName;
            //         this.contextService.columnHeading = element.columnHeader;
            //         this.contextService.setThreshold = element.setThreshold;
            //     }
            // } else if (element.statToDisplay == selectedColumn.label) {
            //     this.contextService.log("loadQueueColumnDetailsOnDoubleClick() ---> column header not exist");
            //     this.contextService.queueStatisticsToDisplay = element.statName;
            //     this.contextService.columnHeading = element.columnHeader;
            //     this.contextService.setThreshold = element.setThreshold;
            // }
            // this.contextService.loadQueueData();

        });

        this.router.navigate(['/settings-service', 'callcenter-threshold']);
    }
    loadAgentActivityThresholdValues(selectedACDState) {
        this.contextService.log("loadQueueThresholdValues() entered");
        this.contextService.agentActivityStatisticsList.forEach(element => {

            if (this.contextService.agentActivityStatisticsToDisplay == element.statName) {
                this.contextService.log("loadQueueThresholdValues() --->" + this.contextService.agentActivityStatisticsToDisplay);
                let elementCC = element.unavailableCodeList[selectedACDState];
                this.highlightNonZeroValues = elementCC.highLightValues;
                this.increasingThresholdValues = elementCC.isIncreasing;
                this.greenThreshold = elementCC.green;
                this.yellowThreshold = elementCC.yellow;
                this.redThreshold = elementCC.red;
                this.blackThreshold = elementCC.black;
            }
        });
    }
    loadQueueThresholdValues(selectedCallCenter) {
        this.contextService.log("loadQueueThresholdValues() entered");
        this.contextService.queueStatisticsList.forEach(element => {

            if (this.contextService.queueStatisticsToDisplay == element.statName) {
                this.contextService.log("loadQueueThresholdValues() --->" + this.contextService.queueStatisticsToDisplay);
                let elementCC = element.callCentersList[selectedCallCenter];
                this.highlightNonZeroValues = elementCC.highLightValues;
                this.increasingThresholdValues = elementCC.isIncreasing;
                this.greenThreshold = elementCC.green;
                this.yellowThreshold = elementCC.yellow;
                this.redThreshold = elementCC.red;
                this.blackThreshold = elementCC.black;
            }
        });
    }
    deleteAgentColumn() {
        this.contextService.log("deleteAgentColumn() entered");
        let showAgentsListTemp = [...this.agentColumnsInTable];
        if (showAgentsListTemp.length > 1) {
            this.contextService.log("more than 1 column exist in agentColumnsInTable");
            showAgentsListTemp.forEach((element, index) => {

                if (element.value === this.selectedAgentColumn.value) {
                    this.contextService.log("deleteAgentColumn() -->" + element.value);
                    this.contextService.agentColumnList.push(element);
                    showAgentsListTemp.splice(index, 1);
                }
            });
        } else {
            this.contextService.log("1 column exist in agentColumnsInTable");
            this.contextService.agentColumnList.push(showAgentsListTemp[0]);
            showAgentsListTemp = [];
        }

        this.agentColumnsInTable = showAgentsListTemp;
        this.selectedAgentColumn = "";
        this.contextService.agentColumnList.sort(function (a, b) {
            return a.id - b.id
        });
        this.deleteAgentVis = false;
    }

    deleteQueueColumn() {
        this.contextService.log("deleteQueueColumn() entered")
        let showQueueColumnsListTemp = [...this.queueColumnsInTable];
        if (showQueueColumnsListTemp.length > 1) {
            this.contextService.log("more than 1 column exist in queueColumnsInTable");
            if (this.selectedQueueColumn) {

                showQueueColumnsListTemp.forEach((element, index) => {
                    if (element.value === this.selectedQueueColumn.value) {
                        this.contextService.queueColumnList.push(element);
                        showQueueColumnsListTemp.splice(index, 1);
                    }
                });
            }
        } else {
            this.contextService.log("1 column exist in queueColumnsInTable");
            if (showQueueColumnsListTemp[0]) {
                this.contextService.queueColumnList.push(showQueueColumnsListTemp[0]);
                showQueueColumnsListTemp = [];
            }
        }
        this.queueColumnsInTable = showQueueColumnsListTemp;
        this.selectedQueueColumn = "";
        this.contextService.queueColumnList.sort(function (a, b) {
            return a.id - b.id
        });
        this.deleteQueueVis = false;
    }
    deleteAgentActivityColumn() {
        this.contextService.log("deleteAgentActivityColumn() entered")
        let showAgentActivityColumnsListTemp = [...this.agentActivityColumnsInTable];
        if (showAgentActivityColumnsListTemp.length > 1) {
            this.contextService.log("more than 1 column exist in agentActivityColumnsInTable");
            if (this.selectedAgentActivityColumn) {

                showAgentActivityColumnsListTemp.forEach((element, index) => {
                    if (element.value === this.selectedAgentActivityColumn.value) {
                        this.contextService.queueColumnList.push(element);
                        showAgentActivityColumnsListTemp.splice(index, 1);
                    }
                });
            }
        } else {
            this.contextService.log("1 column exist in agentActivityColumnsInTable");
            if (showAgentActivityColumnsListTemp[0]) {
                this.contextService.agentActivityColumnList.push(showAgentActivityColumnsListTemp[0]);
                showAgentActivityColumnsListTemp = [];
            }
        }
        this.agentActivityColumnsInTable = showAgentActivityColumnsListTemp;
        this.selectedAgentActivityColumn = "";
        this.contextService.agentActivityColumnList.sort(function (a, b) {
            return a.id - b.id
        });
        this.deleteAgentActivityVis = false;
    }
    updateColumnsInPersonalWallboard() {
        this.contextService.log("updateColumnsInPersonalWallboard() entered");
        this.contextService.statistics.forEach(element => {
            element.isHighlightCollection = [];
            this.contextService.agentStatisticsList.forEach(element1 => {
                if (element1.threshold.highLightValues) {
                    element.isHighlightCollection[element1.statName] = true;
                }
            });

            this.contextService.queueStatisticsList.forEach(element2 => {
                let callcenterInStat = element2.callCentersList[element.queueID];
                if (callcenterInStat.highLightValues) {
                    element.isHighlightCollection[element2.statName] = true;
                }
                let allInStat = element2.callCentersList["all"];
                if (allInStat.highLightValues) {
                    element.isHighlightCollection[element2.statName] = true;
                }
            });
        });
        this.contextService.cols = [];
        this.contextService.showAgentColumnsList.forEach(element => {
            this.contextService.cols.push({ field: element.value, header: element.label, color: element.value + "Color" });
        });
        this.contextService.showQueueColumnsList.forEach(element => {
            this.contextService.cols.push({ field: element.value, header: element.label, color: element.value + "Color" });
        });

        this.contextService.statistics.forEach(element => {
            element.recalculateThresholdColor();
        });
    }

    updateAgentActivityTab() {
        // this.agentactivityService.statToDisplay.forEach(element => {
        //     element.isHighlightCollection = [];
        //     this.contextService.agentStatisticsList.forEach(element1 => {
        //         if (element1.threshold.highLightValues) {
        //             element.isHighlightCollection[element1.statName] = true;
        //         }
        //     });

        //     this.contextService.agentActivityStatisticsList.forEach(element2 => {
        //         let callcenterInStat = element2.unavailableCodeList[element.queueID];
        //         if (callcenterInStat.highLightValues) {
        //             element.isHighlightCollection[element2.statName] = true;
        //         }
        //         let allInStat = element2.unavailableCodeList["all"];
        //         if (allInStat.highLightValues) {
        //             element.isHighlightCollection[element2.statName] = true;
        //         }
        //     });
        // });
        this.contextService.agentActivityCols = [];
        this.contextService.showAgentActivityColumnsList.forEach(element => {
            this.contextService.agentActivityCols.push({ field: element.value, header: element.label, color: element.value + "Color" });
        });


        this.agentactivityService.statToDisplay.forEach(element => {
            // element.recalculateThresholdColor();
        });
    }
    loadStatsToTable() {
        this.contextService.log("loadStatsToTable() entered");
        this.agentColumnsInTable = this.contextService.showAgentColumnsList;
        this.queueColumnsInTable = this.contextService.showQueueColumnsList;
        this.agentActivityColumnsInTable = this.contextService.showAgentActivityColumnsList;

    }
    //agentp3


    manualAlertVisible() {
        if (this.updateAvailabe) {
            this.alertMessage = this.contextService.getLanguageTagByKey('Settings.Update.PerformManualUpdateNow.UpdatePending');
            this.showUpdateAvailable = true;
        } else {
            this.manualAlert = true;
        }
    }
    /**
     * 
     * 
     * @memberof SettingsGeneral
     */
    loadSettings() {
        this.contact_startsWith = this.contextService.objSettings.contact_startsWith;
        this.appearence_unityOnTop = this.contextService.objSettings.appearance_AlwaysOnTop;
        this.appearence_showMissedCallCount = this.contextService.objSettings.appearance_MissedCallCountVisible;
        this.dialingRule_toReplace = this.contextService.objSettings.dialingRule_ToBeReplaced;
        this.dialingRule_toReplaceWith = this.contextService.objSettings.dialingRule_ReplacingValue;
        this.dialingRule_forIncomingCalls = this.contextService.objSettings.dialingRule_forIncomingCalls;
        this.callNoti_showRemotePartyPhoneNumber = this.contextService.objSettings.callNotification_ShowRemotePartyNumber;
        this.callNoti_overRideNameUsingNumber = this.contextService.objSettings.callNotification_OverrideContactName;
        this.callNoti_autoAnswer = this.contextService.objSettings.callNotification_AutoAnswer;
        this.callNoti_popUnityOnCall = this.contextService.objSettings.callNotification_PopUpUnity;
        this.callNoti_autoHideUnity = this.contextService.objSettings.callNotification_AutoHide;
        this.callNoti_popSummaryNotification = this.contextService.objSettings.callNotification_PopUpSummary;
        this.callNoti_popSummarySeconds = this.contextService.objSettings.callNotification_PopUpSummaryDuration;
        this.startUpApp = this.contextService.objSettings.STARTUP;
        this.update_autoUpdateUnity = this.contextService.objSettings.update_AutoUpdatePermitted;
        this.update_updateCheckIntervall = this.contextService.objSettings.update_UpdateInterval;
        this.update_restartOrAlertAfterUpdate = this.contextService.objSettings.update_RestartOrAlertAfterUpdate;
        this.selectedLanguage = this.contextService.objSettings.language_selected;
        this.bwServerAddress = this.contextService.objSettings.network_BWServerAddress;
        this.bwServerPort = this.contextService.objSettings.network_BWServerPort;
        this.imServerAddress = this.contextService.objSettings.network_IMServerAddress;
        this.imServerPort = this.contextService.objSettings.network_IMServerPort;
        this.loginID = this.contextService.objSettings.broadworks_Login;
        this.password = this.contextService.objSettings.broadworks_Password;
        this.rememberLoginId = this.contextService.objSettings.broadworks_RememberBroadworksLogin;
        this.rememberPassword = this.contextService.objSettings.broadworks_RememberBroadworksPassword;

        this.browserInt_URL = this.contextService.objSettings.browserInt_URL;
        this.browserInt_InboundCallCenter = this.contextService.objSettings.browserInt_InboundCallCenter;
        this.browserInt_InboundNonCallCenter = this.contextService.objSettings.browserInt_InboundNonCallCenter;
        this.browserInt_OutboundCallCenter = this.contextService.objSettings.browserInt_OutboundCallCenter;
        this.browserInt_OutbountNonCallCenter = this.contextService.objSettings.browserInt_OutbountNonCallCenter;
        this.browserInt_EncodeURL = this.contextService.objSettings.browserInt_EncodeURL;
        this.browserInt_browserInterface = this.contextService.objSettings.browserInt_browserInterface;
        this.showBusinessNameColumn = this.contextService.objSettings.showBusinessNameColumn;
        //agent
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
            this.selectJoinQueue = this.contextService.objSettings.callCenter_JoinAllQueue;
            this.leaveAllQueue = this.contextService.objSettings.callCenter_LeaveAllQueue;
            this.automaticallyAnswerCall = this.contextService.objSettings.agent_AutomaticCallAnswer;
            this.startUpACDState = this.contextService.objSettings.acdState_StartupACDState;
            this.postCallACDState = this.contextService.objSettings.acdState_PostCallACDState;
            this.wrapUpDuration = this.contextService.objSettings.acdState_WrapUpDuration;
            this.showCurrentACDStateButton = this.contextService.objSettings.acdState_ShowCurrentACDStateButton;
            this.signOutACDState = this.contextService.objSettings.acdState_SignOutACDState;
            this.assignDispositionCode = this.contextService.objSettings.acdState_AssignDispositionCode;
            this.dispositionTime = this.contextService.objSettings.acdState_DispositionTime;
            this.showDuration = this.contextService.objSettings.acdState_ShowDuration;
            this.includeCallCenter = this.contextService.objSettings.agent_IncludeCallCenterCalls;
            this.preventACDStateChage = this.contextService.objSettings.acdState_PreventACDStateChage;
            this.onlyShowMonitoringSupervisor = this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor;
            this.showQueuedCalls = this.contextService.objSettings.agent_ShowQueuedCalls;

            // agentp3
            this.refreshFrequencyEnd = this.contextService.objSettings.statistics_RefreshFrequencyEndValue;
            this.refreshFrequencyStart = this.contextService.objSettings.statistics_RefreshFrequencyStartValue;

            this.displaySummaryStatisticsOnly = this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly;
            this.scrollbarCountOfPW = this.contextService.objSettings.statistics_ScrollbarCountPersonalWB;
            this.processCallCenterBatches = this.contextService.objSettings.statistics_ProcessCallCenterCount;

            this.supervisor_showOnlyJoinedAgents = this.contextService.objSettings.supervisor_showOnlyJoinedAgents;
            this.supervisor_activityImage = this.contextService.objSettings.supervisor_activityImage;
            this.supervior_addAllAgentsToAgentList = this.contextService.objSettings.supervior_addAllAgentsToAgentList;
            this.supervisor_supervisedAgentsStaffedRatio = this.contextService.objSettings.supervisor_supervisedAgentsStaffedRatio;
            this.callPriority = this.contextService.objSettings.callPriority;
        }
        //agent


        if (this.contextService.objLoggedUserDetails) {
            if (this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
                this.xsiServerAddress = this.bwServerAddress;
            } else {
                this.xsiServerAddress = this.contextService.objLoggedUserDetails.getreportingUrl();
            }
        }
    }
    /**
     * validate settings
     * 
     * @returns {boolean} 
     * @memberof SettingsGeneral
     */
    validate(): boolean {
        //agentp3
        if (!this.validateStatistics() && (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3)) {
            return false;
        }
        else if (!this.validateACDState() && (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3)) {
            return false;
        } else if (!this.validateDialingRule()) {
            return false;
        } else if (!this.validateBrowserIntegration()) {
            return false;
        } else if (!this.validateCallNotification()) {
            this.router.navigate(['/settings-general', 'call-notification']);
            return false;
        } else if (!this.validateLanguage()) {

        } else if (!this.validateNetwork()) {
            return false;
        } else if (!this.validateAuthentication()) {
            if (!this.domainAbsent) {
                if (this.contextService.route === 'noAuthentication') {
                    this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.NoDetailsEntered'));
                }
                else if (this.contextService.route === 'noDomain') {
                    this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.MustIncludeDomain'));
                }
            }
            return false;
        } else {
            return true;
        }
    }
    /**
     *
     *validate ACDState service page
     * @returns {boolean}
     * @memberof SettingsGeneral
     */
    validateACDState(): boolean {
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
            let reg = /^(\s*|\d+)$/;
            if (this.wrapUpDuration > 7200) {
                this.contextService.serviceModalVis.next('The wrap up time duration must be between 0 and 7200 seconds (2 hours).');
                this.contextService.route = 'acdState';
                return false;
            } else if (!this.wrapUpDuration.toString().match(reg)) {
                this.contextService.serviceModalVis.next('The wrap up time duration value is invalid. A number must be entered ');
                this.contextService.route = 'acdState';
                return false;
            } else if (this.assignDispositionCode) {
                if (this.wrapUpDuration > 0) {
                    this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode.Error'));
                    this.contextService.route = 'acdState';
                    return false;
                } else if (this.dispositionTime < 3 || this.dispositionTime > 120 || !this.dispositionTime.toString().match(reg)) {
                    this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode.InvalidNumber'));
                    this.contextService.route = 'acdState';
                    return false;
                }
                return true;
            }
            else {
                return true;
            }
        }
    }
    /**
     * validate language
     * 
     * @returns 
     * @memberof SettingsGeneral
     */
    validateLanguage() {

        if (this.selectedLanguage !== this.contextService.objSettings.language_selected) {
            this.restart = true;
        }
        return true;
    }

    /**
     * validate dialing rule
     * 
     * @returns {boolean} 
     * @memberof SettingsGeneral
     */
    validateDialingRule(): boolean {
        let patt1 = /^[+]?([0-9]+-)*[0-9]{1,30}$/;
        if ((this.dialingRule_toReplace != "") && (this.dialingRule_toReplaceWith == "") || (this.dialingRule_toReplace == "") && (this.dialingRule_toReplaceWith != "")) {
            this.contextService.route = 'dialing';
            if ((this.dialingRule_toReplace != "") && (this.dialingRule_toReplaceWith == "")) {
                this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule.WithTheseCharacters.NotEntered'));
            }
            else {
                this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule.BeforeDialing.NotEntered'));
            }
            return false;
        }
        else if (this.dialingRule_toReplace === this.dialingRule_toReplaceWith) {
            if (this.dialingRule_toReplace != "") {
                this.contextService.route = 'dialing';
                this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule.WithTheseCharacters.CannotBeTheSame'));
                return false;
            }
            else {
                return true;
            }
        }
        else if (this.dialingRule_toReplace.match(patt1) && this.dialingRule_toReplaceWith.match(patt1)) {

            return true;
        } else if (!this.dialingRule_toReplace.match(patt1)) {
            this.contextService.route = 'dialing';
            this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule.BeforeDialing.Invalid'));
            return false;
        }
        else if (!this.dialingRule_toReplaceWith.match(patt1)) {
            this.contextService.route = 'dialing';
            this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.DialRule.WithTheseCharacters.Invalid'));
            return false;
        }
    }

    /**
     * validate call notification
     * 
     * @returns {boolean} 
     * @memberof SettingsGeneral
     */
    validateCallNotification(): boolean {
        if (this.callNoti_popSummaryNotification) {
            if ((this.callNoti_popSummarySeconds == null) || (this.callNoti_popSummarySeconds == 0) || this.callNoti_popSummarySeconds > 24) {
                this.callNoti_popSummarySeconds = this.contextService.objSettings.callNotification_PopUpSummaryDuration;
            }
        }
        return true;
    }

    /**
     * validate network
     * 
     * @returns {boolean} 
     * @memberof SettingsGeneral
     */
    validateNetwork(): boolean {
        if (this.contextService.objLoggedUserDetails.getWebclientUseXSI()) {
            this.bwServerAddress = this.xsiServerAddress;
        }
        this.bwServerAddress = this.bwServerAddress.replace(/[ ]/g, '');
        this.bwServerPort = this.bwServerPort.replace(/[ ]/g, '');
        this.imServerPort = this.imServerPort.replace(/[ ]/g, '');
        this.imServerAddress = this.imServerAddress.replace(/[ ]/g, '');
        this.restartIM = false;
        let regex = /[0-9]/g;
        if ((this.bwServerAddress == "") || (this.bwServerPort == "")) {
            this.contextService.route = 'network';
            this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Connection.Network.VoipServer.NotEntered'));
            return false;
        } else if (parseInt(this.bwServerPort) > 65535 || parseInt(this.imServerPort) > 65535) {
            this.contextService.route = 'network';
            this.contextService.serviceModalVis.next("Invalid port number");
            return false;
        }
        else if (!this.bwServerPort.match(regex)) {
            this.contextService.route = 'network';
            this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Connection.Network.VoipServerPort.Invalid'));
            return false;
        }
        else if (!this.imServerPort.match(regex) && this.imServerPort != "") {
            this.contextService.route = 'network';
            this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Connection.Network.MessageServerPort.Invalid'));
            return false;
        }
        else if ((this.bwServerAddress !== this.contextService.objSettings.network_BWServerAddress) || (this.bwServerPort !== this.contextService.objSettings.network_BWServerPort)) {
            this.restart = true;
        } else if ((this.imServerAddress !== this.contextService.objSettings.network_IMServerAddress) || (this.imServerPort !== this.contextService.objSettings.network_IMServerPort)) {
            if (this.contextService.isFirstTimeLogin === true) {
                this.restartIM = false;

            } else {
                this.restartIM = true;
            }
        }
        return true;
    }
    /**
     * validate authentication
     * 
     * @returns {boolean} 
     * @memberof SettingsGeneral
     */
    validateAuthentication(): boolean {
        let regex = new RegExp("@");
        if (((this.loginID == "") && (this.rememberLoginId === true)) || ((this.password == "") && (this.rememberPassword === true))) {
            this.contextService.route = 'noAuthentication';
            return false;
        }
        if ((this.loginID !== "") && ((!regex.test(this.loginID)) || (this.loginID.split("@")[1] == ""))) {
            this.contextService.route = 'noDomain';
            return false;
        }
        if ((this.loginID !== this.contextService.objSettings.broadworks_Login)) {
            if (this.contextService.objLoggedUserDetails === undefined) {
                this.loginidchanged = true;
            } else if ((this.loginID !== this.contextService.objLoggedUserDetails.getUserId())) {
                this.loginidchanged = true;
            } else {
                this.loginidchanged = false;
            }
        }
        if ((this.loginID !== this.contextService.objSettings.broadworks_Login)) {
            if (this.contextService.objLoggedUserDetails === undefined) {
                this.restart = true;
            } else if ((this.loginID !== this.contextService.objLoggedUserDetails.getUserId())) {
                this.restart = true;
            }
        }
        if ((this.password !== this.contextService.objSettings.broadworks_Password)) {
            this.restart = true;
        }
        if (this.contextService.isFirstTimeLogin === true) {
            this.restart = true;
        }
        return true;
    }

    validateBrowserIntegration(): boolean {
        if (this.browserInt_InboundCallCenter || this.browserInt_InboundNonCallCenter || this.browserInt_OutboundCallCenter || this.browserInt_OutbountNonCallCenter) {
            if (!this.browserInt_URL) {
                this.contextService.route = 'browserIntegration';
                this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.BrowseToUrl.NotEntered'));
                return false;
            }
        }

        if (this.browserInt_URL !== "") {
            if (!(this.browserInt_URL.startsWith('http://') || this.browserInt_URL.startsWith('https://'))) {
                this.contextService.route = 'browserIntegration';
                this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Cti.BrowserIntegration.BrowseToUrl.Invalid'));
                return false;
            }
        }

        return true;
    }

    /**
     * to reload/restart 
     * timeout is given to give time for saving the current settings
     * 
     * @memberof SettingsGeneral
     */
    restartUnity() {
        this.save();
        this.restart = false;
        if (this.contextService.isElectron === true) {
            this.contextService.electronService.ipcRenderer.send('restart');
        } else {
            setTimeout(() => {
                location.reload();
            }, 50)
        }
    }

    //agentp3
    /**
     *validate Statistics page in service
     *
     * @returns {boolean}
     * @memberof SettingsGeneral
     */
    validateStatistics(): boolean {
        if (this.processCallCenterBatches == "" || parseInt(this.processCallCenterBatches) < 1 || this.processCallCenterBatches == null) {
            this.processCallCenterBatches = "1";
        }
        if (this.scrollbarCountOfPW == "" || parseInt(this.scrollbarCountOfPW) < 2 || this.scrollbarCountOfPW == null) {
            this.scrollbarCountOfPW = "2";
        }
        // if (this.refreshFrequencyStart == null || this.refreshFrequencyEnd == null) {
        //     this.contextService.route = 'statistics';
        //     this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.RefreshFrequency.NotEntered'));
        //     return false;
        // }
        // else if (parseInt(this.refreshFrequencyStart) < 60) {
        //     this.contextService.route = 'statistics';
        //     this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.RefreshFrequency.TooLow') + " 60");
        //     return false;
        // } else if (parseInt(this.refreshFrequencyEnd) < 300) {
        //     this.contextService.route = 'statistics';
        //     this.contextService.serviceModalVis.next(this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.RefreshFrequency.TooLow') + " 300");
        //     return false;
        // }
        return true;
    }
    /**
     * save the settings
     * 
     * @memberof SettingsGeneral
     */
    save(): boolean {
        this.bwServerAddress = (this.bwServerAddress.replace(/https?\:\/\//g, '')).replace(/^\/+|\/+$/g, '');
        //agentstart
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
            //agentP3
            let index;
            if (this.currentCallCenterAgentPage == "1") {
                this.contextService.log("statisticsAgent detailed page save clicked");

                if (this.contextService.agentColumnList.length > 0) {
                    this.contextService.log("agentColumnList contains columns");
                    if (this.statisticsToDisplay === "") {
                        this.contextService.log(this.contextService.agentColumnList);
                        this.contextService.log("agentColumnList[0].value :" + this.contextService.agentColumnList[0].value);
                        this.statisticsToDisplay = this.contextService.agentColumnList[0].value;
                    }
                    this.contextService.log("Agent statisticsToDisplay: " + this.statisticsToDisplay);
                    this.contextService.agentStatisticsList.forEach(element => {
                        this.contextService.log("Agent statName: " + this.statisticsToDisplay);
                        if (element.statName == this.statisticsToDisplay) {
                            element.columnHeader = this.contextService.columnHeading;
                            element.setThreshold = this.contextService.setThreshold;
                            this.contextService.log("Agent columnHeader: " + this.contextService.columnHeading);
                            this.contextService.log("Agent setThreshold: " + this.contextService.setThreshold);
                        }
                    });

                    let id_value: number = 1;
                    this.agentColumnsInTable.forEach(element => {
                        element.id = id_value;
                        id_value++;

                    });

                    //to remove from dropdown
                    this.contextService.agentColumnList.forEach(elementAgentColumnList => {
                        if (elementAgentColumnList.value == this.statisticsToDisplay) {
                            index = elementAgentColumnList.id;
                            // this.agentColumnRemoveItemsList.push(elementAgentColumnList);
                        }
                    });

                    this.contextService.agentStatisticsList.forEach(elementStatistics => {
                        this.contextService.log("elementStatistics.statName: " + elementStatistics.statName)
                        if (elementStatistics.statName === this.statisticsToDisplay) {
                            let showColumnsListTemp = [...this.agentColumnsInTable];
                            if (elementStatistics.columnHeader != "") {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.columnHeader, value: elementStatistics.statName });
                            } else {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.statToDisplay, value: elementStatistics.statName });
                            }
                            this.agentColumnsInTable = showColumnsListTemp;

                            if (elementStatistics.setThreshold) {
                                elementStatistics.threshold.highLightValues = this.highlightNonZeroValues;
                                elementStatistics.threshold.isIncreasing = this.increasingThresholdValues;
                                elementStatistics.threshold.green = this.greenThreshold;
                                elementStatistics.threshold.yellow = this.yellowThreshold;
                                elementStatistics.threshold.red = this.redThreshold;
                                elementStatistics.threshold.black = this.blackThreshold;
                            } else {
                                elementStatistics.threshold.highLightValues = false;
                                elementStatistics.threshold.isIncreasing = false;
                                elementStatistics.threshold.green = "";
                                elementStatistics.threshold.yellow = "";
                                elementStatistics.threshold.red = "";
                                elementStatistics.threshold.black = "";
                            }
                            // elementStatistics.threshold.highLightValues = this.highlightNonZeroValues;
                            // elementStatistics.threshold.isIncreasing = this.increasingThresholdValues;
                            // elementStatistics.threshold.green = this.greenThreshold;
                            // elementStatistics.threshold.yellow = this.yellowThreshold;
                            // elementStatistics.threshold.red = this.redThreshold;
                            // elementStatistics.threshold.black = this.blackThreshold;
                        }

                    });

                    let id: number = 1;
                    this.agentColumnsInTable.forEach(element => {
                        element.id = id;
                        id++;
                    });
                    this.contextService.log(this.agentColumnsInTable);
                    this.contextService.log(this.contextService.agentColumnList);
                    this.contextService.log(this.contextService.agentStatisticsList);
                    this.router.navigate(['/settings-service', 'columns']);
                    this.statisticsToDisplay = "";
                    this.currentCallCenterAgentPage = "0";
                    return false;
                }
            }
            if (this.currentAgentActivityPage === "1") {
                this.contextService.log("agent activity detailed page save clicked");
                if (this.contextService.agentActivityStatisticsToDisplay === "") {
                    this.contextService.agentActivityStatisticsToDisplay = this.contextService.agentActivityColumnList[0].value;
                }
                this.contextService.agentActivityColumnList.forEach(elementAgentActivityColumnList => {
                    if (elementAgentActivityColumnList.value == this.contextService.agentActivityStatisticsToDisplay) {
                        index = elementAgentActivityColumnList.id;
                    }
                });
                // if (this.contextService.agentActivityStatisticsToDisplay === "agentACDDuration") {

                this.contextService.agentActivityStatisticsList.forEach(element => {
                    if (element.statName == this.contextService.agentActivityStatisticsToDisplay) {
                        element.columnHeader = this.contextService.columnHeading;
                        if (!this.noThresholdList.includes(this.contextService.agentActivityStatisticsToDisplay)) {
                            element.setThreshold = this.contextService.setThreshold;
                            //to remove thresholds if setthreshold is false
                            if (this.contextService.agentActivityStatisticsToDisplay === "agentACDDuration") {
                                if (!this.contextService.setThreshold) {
                                    for (const key in element.unavailableCodeList) {
                                        if (element.unavailableCodeList.hasOwnProperty(key)) {
                                            const element1 = element.unavailableCodeList[key];

                                            element1.highLightValues = false;
                                            element1.isIncreasing = false;
                                            element1.black = "";
                                            element1.green = "";
                                            element1.red = "";
                                            element1.yellow = "";
                                        }
                                    }
                                }
                            } else if (!this.noThresholdList.includes(element.statName)) {
                                if (element.setThreshold) {
                                    element.threshold.highLightValues = this.highlightNonZeroValues;
                                    element.threshold.isIncreasing = this.increasingThresholdValues;
                                    element.threshold.green = this.greenThreshold;
                                    element.threshold.yellow = this.yellowThreshold;
                                    element.threshold.red = this.redThreshold;
                                    element.threshold.black = this.blackThreshold;
                                } else {
                                    element.threshold.highLightValues = false;
                                    element.threshold.isIncreasing = false;
                                    element.threshold.green = "";
                                    element.threshold.yellow = "";
                                    element.threshold.red = "";
                                    element.threshold.black = "";
                                }
                            } else {
                                element.threshold = null;
                            }
                        }
                    }

                });

                let columnExist = false;
                let columnIndex: number;
                let showColumnsListTemp = [...this.agentActivityColumnsInTable];
                for (let index = 0; index < showColumnsListTemp.length; index++) {
                    const element = showColumnsListTemp[index];
                    if (element.value == this.contextService.agentActivityStatisticsToDisplay) {
                        columnIndex = index;
                        columnExist = true;
                    }
                }

                this.contextService.agentActivityStatisticsList.forEach(elementStatistics => {
                    if (elementStatistics.statName === this.contextService.agentActivityStatisticsToDisplay) {
                        if (elementStatistics.columnHeader != "") {
                            if (columnExist) {
                                showColumnsListTemp[columnIndex].label = elementStatistics.columnHeader;
                            } else {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.columnHeader, value: elementStatistics.statName });
                            }
                        } else {
                            if (columnExist) {
                                showColumnsListTemp[columnIndex].label = elementStatistics.statToDisplay;
                            } else {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.statToDisplay, value: elementStatistics.statName });
                            }
                        }
                        this.agentActivityColumnsInTable = showColumnsListTemp;
                    }
                });
                // } else {
                //     this.contextService.agentActivityStatisticsList.forEach(element => {
                //         if (element.statName == this.contextService.agentActivityStatisticsToDisplay) {
                //             element.columnHeader = this.contextService.columnHeading;
                //             element.setThreshold = this.contextService.setThreshold;
                //             this.contextService.log("Agent columnHeader: " + this.contextService.columnHeading);
                //             this.contextService.log("Agent setThreshold: " + this.contextService.setThreshold);
                //         }
                //     });

                //     let id_value: number = 1;
                //     this.agentActivityColumnsInTable.forEach(element => {
                //         element.id = id_value;
                //         id_value++;

                //     });

                //     //to remove from dropdown
                //     this.contextService.agentActivityColumnList.forEach(elementAgentColumnList => {
                //         if (elementAgentColumnList.value == this.contextService.agentActivityStatisticsToDisplay) {
                //             index = elementAgentColumnList.id;
                //             // this.agentColumnRemoveItemsList.push(elementAgentColumnList);
                //         }
                //     });

                //     this.contextService.agentActivityStatisticsList.forEach(elementStatistics => {
                //         if (elementStatistics.statName === this.contextService.agentActivityStatisticsToDisplay) {
                //             let showColumnsListTemp = [...this.agentActivityColumnsInTable];
                //             if (elementStatistics.columnHeader != "") {
                //                 showColumnsListTemp.push({ id: index, label: elementStatistics.columnHeader, value: elementStatistics.statName });
                //             } else {
                //                 showColumnsListTemp.push({ id: index, label: elementStatistics.statToDisplay, value: elementStatistics.statName });
                //             }
                //             this.agentActivityColumnsInTable = showColumnsListTemp;

                //             if (elementStatistics.setThreshold) {
                //                 elementStatistics.threshold.highLightValues = this.highlightNonZeroValues;
                //                 elementStatistics.threshold.isIncreasing = this.increasingThresholdValues;
                //                 elementStatistics.threshold.green = this.greenThreshold;
                //                 elementStatistics.threshold.yellow = this.yellowThreshold;
                //                 elementStatistics.threshold.red = this.redThreshold;
                //                 elementStatistics.threshold.black = this.blackThreshold;
                //             } else {
                //                 elementStatistics.threshold.highLightValues = false;
                //                 elementStatistics.threshold.isIncreasing = false;
                //                 elementStatistics.threshold.green = "";
                //                 elementStatistics.threshold.yellow = "";
                //                 elementStatistics.threshold.red = "";
                //                 elementStatistics.threshold.black = "";
                //             }
                //             // elementStatistics.threshold.highLightValues = this.highlightNonZeroValues;
                //             // elementStatistics.threshold.isIncreasing = this.increasingThresholdValues;
                //             // elementStatistics.threshold.green = this.greenThreshold;
                //             // elementStatistics.threshold.yellow = this.yellowThreshold;
                //             // elementStatistics.threshold.red = this.redThreshold;
                //             // elementStatistics.threshold.black = this.blackThreshold;
                //         }

                //     });

                // }
                let id: number = 1;
                this.agentActivityColumnsInTable.forEach(element => {
                    element.id = id;
                    id++;
                });
                this.contextService.log(this.contextService.agentActivityColumnList);
                this.router.navigate(['/settings-service', 'columns']);
                this.contextService.agentActivityStatisticsToDisplay = "";
                this.currentAgentActivityPage = "0";
                this.disableAgentActivityColumnList = false;
                return false;
            } else if (this.currentAgentActivityPage === "2") {
                this.contextService.log("agent activity threshold page save clicked");
                // this.contextService.setThreshold = true;
                this.showElements = false;
                this.currentAgentActivityPage = "1";
                this.showACDStateTable = true;
                this.contextService.agentActivityStatisticsList.forEach(element => {
                    if (element.statName == this.contextService.agentActivityStatisticsToDisplay) {
                        element.setThreshold = this.contextService.setThreshold;

                        let objACDSate = element.unavailableCodeList[this.selectedACDStateRow.value];
                        if (this.greenThreshold == "null" || this.greenThreshold == null || !this.contextService.setThreshold) {
                            this.greenThreshold = "";
                        }
                        if (this.yellowThreshold == "null" || this.yellowThreshold == null || !this.contextService.setThreshold) {
                            this.yellowThreshold = "";
                        }
                        if (this.redThreshold == "null" || this.redThreshold == null || !this.contextService.setThreshold) {
                            this.redThreshold = "";
                        }
                        if (this.blackThreshold == "null" || this.blackThreshold == null || !this.contextService.setThreshold) {
                            this.blackThreshold = "";
                        }
                        objACDSate.green = this.greenThreshold;
                        objACDSate.yellow = this.yellowThreshold;
                        objACDSate.red = this.redThreshold;
                        objACDSate.black = this.blackThreshold;
                        objACDSate.highLightValues = this.highlightNonZeroValues;
                        objACDSate.isIncreasing = this.increasingThresholdValues;
                    }

                });

                this.contextService.agentActivityThresholdList = [];
                this.contextService.agentActivityStatisticsList.forEach(element => {
                    if (this.contextService.agentActivityStatisticsToDisplay == element.statName) {
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "All", value: "all", green: element.unavailableCodeList["all"].green, yellow: element.unavailableCodeList["all"].yellow, red: element.unavailableCodeList["all"].red, black: element.unavailableCodeList["all"].black });
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "Sign-In", value: "Sign-In", green: element.unavailableCodeList["Sign-In"].green, yellow: element.unavailableCodeList["Sign-In"].yellow, red: element.unavailableCodeList["Sign-In"].red, black: element.unavailableCodeList["Sign-In"].black });
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "Available", value: "Available", green: element.unavailableCodeList["Available"].green, yellow: element.unavailableCodeList["Available"].yellow, red: element.unavailableCodeList["Available"].red, black: element.unavailableCodeList["Available"].black });
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "Wrap-Up", value: "Wrap-Up", green: element.unavailableCodeList["Wrap-Up"].green, yellow: element.unavailableCodeList["Wrap-Up"].yellow, red: element.unavailableCodeList["Wrap-Up"].red, black: element.unavailableCodeList["Wrap-Up"].black });
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "Unavailable", value: "Unavailable", green: element.unavailableCodeList["Unavailable"].green, yellow: element.unavailableCodeList["Unavailable"].yellow, red: element.unavailableCodeList["Unavailable"].red, black: element.unavailableCodeList["Unavailable"].black });

                        for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
                            let element1 = this.contextService.unAvailableCodesArray[i];
                            this.contextService.agentActivityThresholdList.push({ id: 2, name: "Unavailable (" + element1.Description + ")", value: "Unavailable - " + element1.Description, green: element.unavailableCodeList["Unavailable - " + element1.Description].green, yellow: element.unavailableCodeList["Unavailable - " + element1.Description].yellow, red: element.unavailableCodeList["Unavailable - " + element1.Description].red, black: element.unavailableCodeList["Unavailable - " + element1.Description].black });
                        }
                        this.contextService.agentActivityThresholdList.push({ id: 1, name: "Sign-Out", value: "Sign-Out", green: element.unavailableCodeList["Sign-Out"].green, yellow: element.unavailableCodeList["Sign-Out"].yellow, red: element.unavailableCodeList["Sign-Out"].red, black: element.unavailableCodeList["Sign-Out"].black });
                    }
                });

                let id_value: number = 1;
                this.contextService.agentActivityThresholdList.forEach(element => {
                    element.id = id_value;
                    id_value++;

                });
                let temp = [...this.contextService.agentActivityThresholdList];
                this.contextService.agentActivityThresholdList = temp;
                return false;
            } else if (this.currentAgentActivityPage == "agentActivityDoubleClick") {
                this.contextService.log("statisticsagent activity double clicked");
                this.disableAgentActivityColumnList = false;
                this.contextService.agentActivityStatisticsList.forEach(element => {
                    if (this.contextService.agentActivityStatisticsToDisplay == element.statName) {
                        element.columnHeader = this.contextService.columnHeading;
                        this.contextService.agentActivityColumnList.forEach(element2 => {

                            if (element2.value == element.statName) {
                                element.statToDisplay = element2.label;
                            }
                        });

                        element.setThreshold = this.contextService.setThreshold;
                        if (element.statName !== "agentACDDuration" && !this.noThresholdList.includes(element.statName)) {
                            if (element.setThreshold) {
                                element.threshold.highLightValues = this.highlightNonZeroValues;
                                element.threshold.isIncreasing = this.increasingThresholdValues;
                                element.threshold.green = this.greenThreshold;
                                element.threshold.yellow = this.yellowThreshold;
                                element.threshold.red = this.redThreshold;
                                element.threshold.black = this.blackThreshold;
                            } else {
                                element.threshold.highLightValues = false;
                                element.threshold.isIncreasing = false;
                                element.threshold.green = "";
                                element.threshold.yellow = "";
                                element.threshold.red = "";
                                element.threshold.black = "";
                            }
                        }
                        this.agentActivityColumnsInTable.forEach(element1 => {
                            if (element1.value == element.statName) {
                                if (this.contextService.columnHeading != "") {
                                    element1.label = this.contextService.columnHeading;
                                } else {
                                    element1.label = element.statToDisplay;
                                }
                            }
                        });
                    }
                });

                this.router.navigate(['/settings-service', 'columns']);
                this.contextService.queueStatisticsToDisplay = "";
                this.contextService.columnHeading = "";
                this.contextService.setThreshold = false;
                this.highlightNonZeroValues = false;
                this.increasingThresholdValues = false;
                this.greenThreshold = "";
                this.yellowThreshold = "";
                this.redThreshold = "";
                this.blackThreshold = "";
                this.currentAgentActivityPage = this.previousAgentActivityPage;
                return false;
            }
            if (this.currentCallCenterQueuePage === "1") {
                this.contextService.log("statisticsQueue detailed page save clicked");
                if (this.contextService.queueStatisticsToDisplay === "") {
                    this.contextService.queueStatisticsToDisplay = this.contextService.queueColumnList[0].value;
                }
                this.contextService.queueColumnList.forEach(elementQueueColumnList => {
                    if (elementQueueColumnList.value == this.contextService.queueStatisticsToDisplay) {
                        index = elementQueueColumnList.id;
                    }
                });

                this.contextService.queueStatisticsList.forEach(element => {
                    if (element.statName == this.contextService.queueStatisticsToDisplay) {
                        element.columnHeader = this.contextService.columnHeading;
                        element.setThreshold = this.contextService.setThreshold;
                        //to remove thresholds if setthreshold is false
                        if (!this.contextService.setThreshold) {
                            for (const key in element.callCentersList) {
                                if (element.callCentersList.hasOwnProperty(key)) {
                                    const element1 = element.callCentersList[key];

                                    element1.highLightValues = false;
                                    element1.isIncreasing = false;
                                    element1.black = "";
                                    element1.green = "";
                                    element1.red = "";
                                    element1.yellow = "";
                                }
                            }
                        }
                    }

                });

                let columnExist = false;
                let columnIndex: number;
                let showColumnsListTemp = [...this.queueColumnsInTable];
                for (let index = 0; index < showColumnsListTemp.length; index++) {
                    const element = showColumnsListTemp[index];
                    if (element.value == this.contextService.queueStatisticsToDisplay) {
                        columnIndex = index;
                        columnExist = true;
                    }
                }

                this.contextService.queueStatisticsList.forEach(elementStatistics => {
                    if (elementStatistics.statName === this.contextService.queueStatisticsToDisplay) {
                        if (elementStatistics.columnHeader != "") {
                            if (columnExist) {
                                showColumnsListTemp[columnIndex].label = elementStatistics.columnHeader;
                            } else {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.columnHeader, value: elementStatistics.statName });
                            }
                        } else {
                            if (columnExist) {
                                showColumnsListTemp[columnIndex].label = elementStatistics.statToDisplay;
                            } else {
                                showColumnsListTemp.push({ id: index, label: elementStatistics.statToDisplay, value: elementStatistics.statName });
                            }
                        }
                        let id: number = 1;
                        showColumnsListTemp.forEach(element => {
                            element.id = id;
                            id++;
                        });

                        this.queueColumnsInTable = showColumnsListTemp;
                    }
                });
                this.contextService.log(this.contextService.agentColumnList);
                this.router.navigate(['/settings-service', 'columns']);
                this.contextService.queueStatisticsToDisplay = "";
                this.currentCallCenterQueuePage = "0";
                this.disableQueueColumnList = false;
                return false;
            } else if (this.currentCallCenterQueuePage === "2") {
                this.contextService.log("statisticsQueue threshold page save clicked");
                // this.contextService.setThreshold = true;
                this.showElements = false;
                this.currentCallCenterQueuePage = "1";

                this.contextService.queueStatisticsList.forEach(element => {
                    if (element.statName == this.contextService.queueStatisticsToDisplay) {
                        element.setThreshold = this.contextService.setThreshold;

                        let objCallCenter = element.callCentersList[this.selectedCallCenterColumn.value];
                        if (this.greenThreshold == "null" || this.greenThreshold == null || !this.contextService.setThreshold) {
                            this.greenThreshold = "";
                        }
                        if (this.yellowThreshold == "null" || this.yellowThreshold == null || !this.contextService.setThreshold) {
                            this.yellowThreshold = "";
                        }
                        if (this.redThreshold == "null" || this.redThreshold == null || !this.contextService.setThreshold) {
                            this.redThreshold = "";
                        }
                        if (this.blackThreshold == "null" || this.blackThreshold == null || !this.contextService.setThreshold) {
                            this.blackThreshold = "";
                        }
                        objCallCenter.green = this.greenThreshold;
                        objCallCenter.yellow = this.yellowThreshold;
                        objCallCenter.red = this.redThreshold;
                        objCallCenter.black = this.blackThreshold;
                        objCallCenter.highLightValues = this.highlightNonZeroValues;
                        objCallCenter.isIncreasing = this.increasingThresholdValues;
                    }

                });

                this.contextService.queueThresholdList = [];
                this.contextService.queueStatisticsList.forEach(element => {
                    if (this.contextService.queueStatisticsToDisplay == element.statName) {
                        this.contextService.queueThresholdList.push({ id: 1, name: "All", value: "all", green: element.callCentersList["all"].green, yellow: element.callCentersList["all"].yellow, red: element.callCentersList["all"].red, black: element.callCentersList["all"].black });
                        for (let i = 0; i < this.contextService.callCenterList.length; i++) {
                            let element1 = this.contextService.callCenterList[i];
                            this.contextService.queueThresholdList.push({ id: 2, name: this.contextService.getLastNameFromUserID(element1.value), value: element1.value, green: element.callCentersList[element1.value].green, yellow: element.callCentersList[element1.value].yellow, red: element.callCentersList[element1.value].red, black: element.callCentersList[element1.value].black });
                        }
                    }
                });

                let id_value: number = 1;
                this.contextService.queueThresholdList.forEach(element => {
                    element.id = id_value;
                    id_value++;

                });
                let temp = [...this.contextService.queueThresholdList];
                this.contextService.queueThresholdList = temp;
                return false;
            } else if (this.currentCallCenterAgentPage == "agentDoubleClick") {
                this.contextService.log("statisticsAgent double clicked");
                this.disableAgentColumnList = false;

                this.contextService.agentStatisticsList.forEach(element => {

                    if (this.statisticsToDisplay == element.statName) {
                        element.columnHeader = this.contextService.columnHeading;
                        this.contextService.agentColumnList.forEach(element1 => {
                            if (element1.value == element.statName) {
                                element.statToDisplay = element1.label;
                            }
                        });

                        element.setThreshold = this.contextService.setThreshold;
                        //settings threshold values
                        if (element.setThreshold) {
                            element.threshold.highLightValues = this.highlightNonZeroValues;
                            element.threshold.isIncreasing = this.increasingThresholdValues;
                            element.threshold.green = this.greenThreshold;
                            element.threshold.yellow = this.yellowThreshold;
                            element.threshold.red = this.redThreshold;
                            element.threshold.black = this.blackThreshold;
                        } else {
                            element.threshold.highLightValues = false;
                            element.threshold.isIncreasing = false;
                            element.threshold.green = "";
                            element.threshold.yellow = "";
                            element.threshold.red = "";
                            element.threshold.black = "";
                        }


                        this.agentColumnsInTable.forEach(element1 => {
                            if (element1.value == element.statName) {
                                if (this.contextService.columnHeading === "") {
                                    element1.label = element.statToDisplay;
                                } else {
                                    element1.label = this.contextService.columnHeading;
                                }
                            }
                        });
                    }

                });
                this.contextService.log(this.contextService.agentStatisticsList);

                this.router.navigate(['/settings-service', 'columns']);
                this.currentCallCenterAgentPage = "0";
                this.statisticsToDisplay = "";
                this.contextService.columnHeading = "";
                this.contextService.setThreshold = false;
                this.highlightNonZeroValues = false;
                this.increasingThresholdValues = false;
                this.greenThreshold = "";
                this.yellowThreshold = "";
                this.redThreshold = "";
                this.blackThreshold = "";
                return false;
            } else if (this.currentCallCenterQueuePage == "queueDoubleClick") {
                this.contextService.log("statisticsQueue double clicked");
                this.disableQueueColumnList = false;
                this.contextService.queueStatisticsList.forEach(element => {
                    if (this.contextService.queueStatisticsToDisplay == element.statName) {
                        element.columnHeader = this.contextService.columnHeading;
                        this.contextService.queueColumnList.forEach(element2 => {

                            if (element2.value == element.statName) {
                                element.statToDisplay = element2.label;
                            }
                        });

                        element.setThreshold = this.contextService.setThreshold;
                        this.queueColumnsInTable.forEach(element1 => {
                            if (element1.value == element.statName) {
                                if (this.contextService.columnHeading != "") {
                                    element1.label = this.contextService.columnHeading;
                                } else {
                                    element1.label = element.statToDisplay;
                                }
                            }
                        });
                    }
                });

                this.router.navigate(['/settings-service', 'columns']);
                this.contextService.queueStatisticsToDisplay = "";
                this.contextService.columnHeading = "";
                this.contextService.setThreshold = false;
                this.highlightNonZeroValues = false;
                this.increasingThresholdValues = false;
                this.greenThreshold = "";
                this.yellowThreshold = "";
                this.redThreshold = "";
                this.blackThreshold = "";
                this.currentCallCenterQueuePage = this.previousQueuePage;
                return false;
            }
            //agentp3
            this.contextService.objSettings.callCenter_JoinAllQueue = this.selectJoinQueue;
            this.contextService.objSettings.callCenter_LeaveAllQueue = this.leaveAllQueue;
            this.contextService.objSettings.agent_AutomaticCallAnswer = this.automaticallyAnswerCall;
            this.contextService.objSettings.acdState_StartupACDState = this.startUpACDState;
            this.contextService.objSettings.acdState_PostCallACDState = this.postCallACDState;
            if (this.wrapUpDuration.toString() === "") {
                this.wrapUpDuration = 0;
            }
            this.contextService.objSettings.acdState_WrapUpDuration = this.wrapUpDuration;
            this.contextService.objSettings.acdState_ShowCurrentACDStateButton = this.showCurrentACDStateButton;
            this.contextService.objSettings.acdState_SignOutACDState = this.signOutACDState;
            this.contextService.objSettings.acdState_AssignDispositionCode = this.assignDispositionCode;
            this.contextService.objSettings.acdState_DispositionTime = this.dispositionTime;
            this.contextService.objSettings.acdState_ShowDuration = this.showDuration;
            this.contextService.objSettings.agent_IncludeCallCenterCalls = this.includeCallCenter;
            this.contextService.objSettings.acdState_PreventACDStateChage = this.preventACDStateChage;
            this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor = this.onlyShowMonitoringSupervisor;
            this.contextService.objSettings.agent_ShowQueuedCalls = this.showQueuedCalls;
            this.contextService.objSettings.showCallCenterLoginFailed = this.showCallCenterLoginFailedAlert;
            this.contextService.objSettings.supervisor_activityImage = this.supervisor_activityImage;
            this.contextService.objSettings.callPriority = this.callPriority;

            this.contextService.updateUnAvailableButton.next();
            if (this.contextService.objSettings.STARTUP === 3) {
                //supervisor
                if (this.supervior_addAllAgentsToAgentList) {
                    if (!this.contextService.objSettings.supervior_addAllAgentsToAgentList) {
                        for (const callcenterId in this.contextService.agentDetails.callCenters) {
                            if (this.contextService.agentDetails.callCenters.hasOwnProperty(callcenterId)) {
                                let userList = [], nonSupervisedAgentExist = false;
                                const callCenterObj = this.contextService.agentDetails.callCenters[callcenterId];
                                if (callCenterObj.isSupervisorCallcenter) {
                                    for (const userId in callCenterObj.agentList) {
                                        if (callCenterObj.agentList.hasOwnProperty(userId)) {
                                            userList.push(userId);
                                            if (!callCenterObj.agentList[userId].isSupervised) {
                                                nonSupervisedAgentExist = true;
                                            }
                                        }
                                    }
                                    if (nonSupervisedAgentExist) {

                                    }
                                }
                            }
                        }

                    }

                }

                if (this.supervisor_supervisedAgentsStaffedRatio !== this.contextService.objSettings.supervisor_supervisedAgentsStaffedRatio) {
                    if (!this.supervisor_supervisedAgentsStaffedRatio) {
                        for (const ccKey in this.contextService.agentDetails.callCenters) {
                            if (this.contextService.agentDetails.callCenters.hasOwnProperty(ccKey)) {
                                for (const key in this.contextService.agentDetails.callCenters[ccKey].agentList) {
                                    if (this.contextService.agentDetails.callCenters[ccKey].agentList.hasOwnProperty(key)) {
                                        if (!(key in this.contextService.monitoredUserList)) {
                                            if (!(key in this.contextService.staffedRatioSubscriptionList)) {
                                                if (!(key in this.contextService.agentSubscriptionList)) {
                                                    if (!(this.contextService.objLoggedUserDetails.getUserId() === key && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard')))) {
                                                        this.contextService.staffedRatioSubscriptionList[key] = "";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    } else {
                        for (const ccKey in this.contextService.agentDetails.callCenters) {
                            if (this.contextService.agentDetails.callCenters.hasOwnProperty(ccKey)) {
                                for (const key in this.contextService.agentDetails.callCenters[ccKey].agentList) {
                                    if (this.contextService.agentDetails.callCenters[ccKey].agentList.hasOwnProperty(key)) {
                                        const element = this.contextService.agentDetails.callCenters[ccKey].agentList[key];

                                        if (!element.isSupervised) {
                                            if (!(key in this.contextService.monitoredUserList) && !(key in this.contextService.agentSubscriptionList)) {
                                                if (!(this.contextService.objLoggedUserDetails.getUserId() === key && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard')))) {
                                                    if (key in this.contextService.staffedRatioSubscriptionList) {
                                                        // this.contextService.websocketService.deleteEventAction(key, this.contextService.staffedRatioSubscriptionList[key], false);
                                                    }
                                                    delete this.contextService.staffedRatioSubscriptionList[key];
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                }
                this.contextService.objSettings.supervisor_showOnlyJoinedAgents = this.supervisor_showOnlyJoinedAgents;
                this.contextService.objSettings.supervisor_supervisedAgentsStaffedRatio = this.supervisor_supervisedAgentsStaffedRatio;
                this.contextService.objSettings.supervior_addAllAgentsToAgentList = this.supervior_addAllAgentsToAgentList;
                this.contextService.calculateStaffedRatio();
                this.contextService.agentAactivityTableUpdation.next('totalUpdate');
                this.helper.recalcagentactivityHeight.next(true);
                this.contextService.objSettings.agentActivityStatistics = [];
                this.agentActivityColumnsInTable.forEach(element => {
                    this.contextService.agentActivityStatisticsList.forEach(element1 => {
                        if (element1.statName == element.value) {
                            this.contextService.objSettings.agentActivityStatistics.push(element1);
                        }
                    });
                });
            }

            //agentP3
            this.contextService.objSettings.agentStatistics = [];
            this.agentColumnsInTable.forEach(element => {
                this.contextService.agentStatisticsList.forEach(element1 => {
                    if (element1.statName == element.value) {
                        this.contextService.objSettings.agentStatistics.push(element1);
                    }
                });
            });
            this.contextService.objSettings.queueStatistics = [];
            this.queueColumnsInTable.forEach(element => {
                this.contextService.queueStatisticsList.forEach(element1 => {
                    if (element1.statName == element.value) {

                        this.contextService.objSettings.queueStatistics.push(element1);
                    }
                });
            });

            this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly = this.displaySummaryStatisticsOnly;
            this.contextService.objSettings.statistics_ScrollbarCountPersonalWB = this.scrollbarCountOfPW;
            this.contextService.objSettings.statistics_ProcessCallCenterCount = this.processCallCenterBatches;
            this.contextService.objSettings.statistics_RefreshFrequencyStartValue = this.refreshFrequencyStart;
            this.contextService.objSettings.statistics_RefreshFrequencyEndValue = this.refreshFrequencyEnd;

            if (this.currentCallCenterAuthPage == "1") {
                if (!this.defaultPasswordDeleteButton) {
                    if (this.callCenterPassword != "" && this.passwordToCheck != this.callCenterPassword) {
                        let noCallCenterExist = false;
                        this.contextService.loggedInCallCenters.forEach(element => {
                            if (element.callCenterID == this.callCenterID) {
                                element.callCenterPassword = this.callCenterPassword;
                                element.showInWallBoard = this.showInWallBoard;
                                noCallCenterExist = true;
                            }
                        });
                        if (!noCallCenterExist) {
                            this.contextService.loggedInCallCenters.push({ callCenterID: this.callCenterID, callCenterPassword: this.callCenterPassword, showInWallBoard: this.showInWallBoard, applyAllQueues: false });
                        }
                        if (this.applyToAllQueues) {
                            for (const key in this.contextService.agentDetails.callCenters) {
                                if (this.contextService.agentDetails.callCenters.hasOwnProperty(key)) {
                                    let exist = false;
                                    this.contextService.loggedInCallCenters.forEach(element => {
                                        if (element.callCenterID == key && key !== this.callCenterID) {
                                            exist = true;
                                        }
                                    });

                                    if (!this.contextService.agentDetails.callCenters[key].isLoggedIn && key != this.callCenterID && !exist) {
                                        this.applyAllQueuesMasterID = this.callCenterID;
                                        this.contextService.loggedInCallCenters.push({ callCenterID: key, callCenterPassword: this.callCenterPassword, showInWallBoard: this.showInWallBoard, applyAllQueues: true });
                                    } else if (!this.contextService.agentDetails.callCenters[key].isLoggedIn && key != this.callCenterID) {
                                        this.contextService.loggedInCallCenters.forEach(element => {
                                            if (element.callCenterID == key && element.callCenterPassword === "") {
                                                element.callCenterPassword = this.callCenterPassword;
                                                element.showInWallBoard = this.showInWallBoard;
                                                element.applyAllQueues = true;
                                                this.applyAllQueuesMasterID = this.callCenterID;
                                            }
                                        });
                                    }
                                }
                            }
                        }

                    } else if (this.callCenterPassword === "") {
                        this.contextService.loggedInCallCenters.forEach((element, index) => {
                            if (element.callCenterID == this.callCenterID) {
                                this.contextService.loggedInCallCenters.splice(index, 1);
                            }
                        });
                        this.nullPassword = true;
                        return false;
                    } else {
                        if (this.applyToAllQueues) {
                            for (const key in this.contextService.agentDetails.callCenters) {
                                if (this.contextService.agentDetails.callCenters.hasOwnProperty(key)) {
                                    let exist = false;
                                    this.contextService.loggedInCallCenters.forEach(element => {
                                        if (element.callCenterID === key) {
                                            exist = true;
                                        }
                                    });
                                    if (!this.contextService.agentDetails.callCenters[key].isLoggedIn && key != this.callCenterID && !exist) {
                                        this.applyAllQueuesMasterID = this.callCenterID;
                                        this.applyAllQueuesList.push(key);
                                    } else if (!this.contextService.agentDetails.callCenters[key].isLoggedIn && key != this.callCenterID) {
                                        this.contextService.loggedInCallCenters.forEach((element, index) => {
                                            if (element.callCenterID === key && element.callCenterPassword === "") {
                                                this.applyAllQueuesMasterID = this.callCenterID;
                                                this.applyAllQueuesList.push(key);
                                                this.contextService.loggedInCallCenters.splice(index, 1);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                    if (this.contextService.objSettings.showInPersonalWBList[this.callCenterID]) {
                        delete this.contextService.objSettings.showInPersonalWBList[this.callCenterID];
                    }
                } else {
                    this.contextService.loggedInCallCenters.forEach(element => {
                        if (element.callCenterID == this.callCenterID) {
                            element.showInWallBoard = this.showInWallBoard;
                        }
                    });
                    if (this.contextService.objSettings.showInPersonalWBList[this.callCenterID]) {
                        this.contextService.objSettings.showInPersonalWBList[this.callCenterID].showInWallBoard = this.showInWallBoard;
                    } else {
                        this.contextService.objSettings.showInPersonalWBList[this.callCenterID] = { showInWallBoard: this.showInWallBoard };
                    }
                }

                this.router.navigate(['/settings-general', 'call-centers-authentication']);
                this.currentCallCenterAuthPage = "0";
                this.showInWallBoard = true;
                return false;
            }

            //agentp3
        }
        //agentend
        this.contextService.imOverridden = false;
        this.contextService.objSettings.appearance_AlwaysOnTop = this.appearence_unityOnTop;
        this.contextService.objSettings.appearance_MissedCallCountVisible = this.appearence_showMissedCallCount;
        this.contextService.objSettings.dialingRule_ToBeReplaced = this.dialingRule_toReplace;
        this.contextService.objSettings.dialingRule_ReplacingValue = this.dialingRule_toReplaceWith;
        this.contextService.objSettings.dialingRule_forIncomingCalls = this.dialingRule_forIncomingCalls;
        this.contextService.objSettings.contact_startsWith = this.contact_startsWith;
        this.contextService.objSettings.callNotification_ShowRemotePartyNumber = this.callNoti_showRemotePartyPhoneNumber;
        this.contextService.objSettings.callNotification_OverrideContactName = this.callNoti_overRideNameUsingNumber;
        this.contextService.objSettings.callNotification_AutoAnswer = this.callNoti_autoAnswer;
        this.contextService.objSettings.callNotification_PopUpUnity = this.callNoti_popUnityOnCall;
        this.contextService.objSettings.callNotification_AutoHide = this.callNoti_autoHideUnity;
        this.contextService.objSettings.callNotification_PopUpSummary = this.callNoti_popSummaryNotification;
        this.contextService.objSettings.callNotification_PopUpSummaryDuration = this.callNoti_popSummarySeconds;
        this.contextService.objSettings.STARTUP = this.startUpApp;
        this.contextService.objSettings.update_AutoUpdatePermitted = this.update_autoUpdateUnity;
        this.contextService.objSettings.update_UpdateInterval = this.update_updateCheckIntervall;
        this.contextService.objSettings.update_RestartOrAlertAfterUpdate = this.update_restartOrAlertAfterUpdate;
        this.contextService.objSettings.language_selected = this.selectedLanguage;
        this.contextService.objSettings.network_BWServerAddress = this.bwServerAddress;
        this.contextService.objSettings.network_BWServerPort = this.bwServerPort;
        this.contextService.objSettings.network_IMServerAddress = this.imServerAddress;
        this.contextService.objSettings.network_IMServerPort = this.imServerPort;

        this.contextService.objSettings.browserInt_URL = this.browserInt_URL;
        this.contextService.objSettings.browserInt_InboundCallCenter = this.browserInt_InboundCallCenter;
        this.contextService.objSettings.browserInt_InboundNonCallCenter = this.browserInt_InboundNonCallCenter;
        this.contextService.objSettings.browserInt_OutboundCallCenter = this.browserInt_OutboundCallCenter;
        this.contextService.objSettings.browserInt_OutbountNonCallCenter = this.browserInt_OutbountNonCallCenter;
        this.contextService.objSettings.browserInt_EncodeURL = this.browserInt_EncodeURL;
        this.contextService.objSettings.browserInt_browserInterface = this.browserInt_browserInterface;
        this.contextService.objSettings.showBusinessNameColumn = this.showBusinessNameColumn;
        this.contextService.objSettings.callPark_Retrieve_ShowButton = this.callParkRetrieveShowButton;
        this.contextService.objSettings.allow_WarmTransfer = this.allowWarmTransfer;
        this.contextService.objSettings.allow_WarmTransfer_To_Voicemail = this.allowWarmTransferToVoicemail;
        this.contextService.objSettings.survey_Extension = this.surveyExtension;

        this.contextService.objSettings.contact_includeAllContact = this.contact_includeAllContact;
        this.contextService.objSettings.contact_showMonitoredUsersFirst = this.contact_showMonitoredUsersFirst;
        this.contextService.objSettings.contact_alwaysSortSearchByName = this.contact_alwaysSortSearchByName;
        this.contextService.objSettings.contact_includeDepartmentInSearch = this.contact_includeDepartmentInSearch;
        this.contextService.objSettings.contact_clearSearchBox = this.contact_clearSearchBox;
        this.contextService.objSettings.contact_privacyEnabled = this.contact_privacyEnabled;
        this.contextService.objSettings.contact_activateContactSearch = this.contact_activateContactSearch;

        if (this.contextService.objSettings.survey_Extension) {
            this.contextService.surveyExtensionExist = true;
        } else {
            this.contextService.surveyExtensionExist = false;
        }
        if (this.contextService.objLoggedUserDetails !== undefined) {
            this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
        }
        if (this.rememberLoginId === true) {
            this.contextService.objSettings.broadworks_Login = this.loginID;
        } else {
            this.contextService.objSettings.broadworks_Login = "";
            if (this.contextService.isFirstTimeLogin === true) {
                this.contextService.setLocalStorageItem('temp_Broadworks_Login', this.loginID);
            }
        }
        if (this.rememberPassword === false) {
            this.contextService.objSettings.sessionId = "";
            if (this.contextService.isFirstTimeLogin === true) {
                this.contextService.setLocalStorageItem('temp_Broadworks_Password', this.password);
            }
        } else {
            if (this.contextService.sessionPassword) {
                this.contextService.objSettings.sessionId = this.contextService.sessionPassword;
            }

            if ((this.password !== this.contextService.objSettings.broadworks_Password)) {
                this.contextService.objSettings.sessionId = "";
                this.contextService.setLocalStorageItem('temp_Broadworks_Password', this.password);
            }
        }
        this.contextService.objSettings.broadworks_RememberBroadworksLogin = this.rememberLoginId;
        this.contextService.objSettings.broadworks_RememberBroadworksPassword = this.rememberPassword;

        if ((this.contextService.objLoggedUserDetails !== undefined) && (this.loginidchanged === false)) {
            this.contextService.setLocalStorageItem("LoggedUser", this.contextService.objLoggedUserDetails.getUserId());
        } else {
            this.contextService.setLocalStorageItem("LoggedUser", this.loginID);
        }
        if (this.loginidchanged === true) {
            if (this.contextService.getLocalStorageItemValueByKey(this.loginID) == null) {

                this.objSettings = new Settings();
                this.objSettings.broadworks_Login = this.loginID;
                this.objSettings.broadworks_Password = this.password;
                //agentP3
                this.contextService.objSettings.sessionId = "";
                this.objSettings.network_BWServerAddress = this.bwServerAddress;
                this.contextService.setLocalStorageItem('temp_Broadworks_Password', this.password);
                // localStorage.setItem(this.objSettings.Broadworks_Login, JSON.stringify(this.objSettings));
                localStorage.setItem(this.objSettings.broadworks_Login, JSON.stringify({ voiceMessage_sortField: this.objSettings.voiceMessage_sortField, callLog_sortField: this.objSettings.callLog_sortField, voiceMessage_sortOrder: this.objSettings.voiceMessage_sortOrder, callLog_sortOrder: this.objSettings.callLog_sortOrder, dir_sortField: this.objSettings.dir_sortField, dir_sortOrder: this.objSettings.dir_sortOrder, sessionId: this.objSettings.sessionId, Broadworks_Login: this.objSettings.broadworks_Login, Broadworks_RememberBroadworksLogin: this.objSettings.broadworks_RememberBroadworksLogin, Broadworks_RememberBroadworksPassword: this.objSettings.broadworks_RememberBroadworksPassword, Network_BWServerAddress: this.objSettings.network_BWServerAddress, Network_BWServerPort: this.objSettings.network_BWServerPort, chat_openStatus: this.objSettings.chat_openStatus, language_selected: this.objSettings.language_selected, stat_sortField: this.objSettings.stat_sortField, stat_sortOrder: this.objSettings.stat_sortOrder, userLoginForTheFirstTime: this.objSettings.userLoginForTheFirstTime, personalWallboardPersist: this.objSettings.personalWallboardPersist }));
                //this.contextService.objSettings.setSettings(this.objSettings.broadworks_Login);

            } else {
                this.contextService.objSettings.getSettings(this.loginID);
                this.contextService.objSettings.sessionId = "";

                if (this.rememberPassword === true) {
                    this.contextService.setLocalStorageItem('temp_Broadworks_Password', this.password);
                }

                if (this.rememberLoginId === true) {
                    this.contextService.objSettings.broadworks_Login = this.loginID;
                } else {
                    this.contextService.objSettings.broadworks_Login = "";
                    if (this.contextService.isFirstTimeLogin === true) {
                        this.contextService.setLocalStorageItem('temp_Broadworks_Login', this.loginID);
                    }

                }
                this.contextService.objSettings.broadworks_RememberBroadworksLogin = this.rememberLoginId;
                this.contextService.objSettings.broadworks_RememberBroadworksPassword = this.rememberPassword;
                localStorage.removeItem(this.loginID);
                localStorage.setItem(this.loginID, JSON.stringify({ voiceMessage_sortField: this.contextService.objSettings.voiceMessage_sortField, callLog_sortField: this.contextService.objSettings.callLog_sortField, voiceMessage_sortOrder: this.contextService.objSettings.voiceMessage_sortOrder, callLog_sortOrder: this.contextService.objSettings.callLog_sortOrder, dir_sortField: this.contextService.objSettings.dir_sortField, dir_sortOrder: this.contextService.objSettings.dir_sortOrder, sessionId: "", Broadworks_Login: this.contextService.objSettings.broadworks_Login, Broadworks_RememberBroadworksLogin: this.contextService.objSettings.broadworks_RememberBroadworksLogin, Broadworks_RememberBroadworksPassword: this.contextService.objSettings.broadworks_RememberBroadworksPassword, Network_BWServerAddress: this.bwServerAddress, Network_BWServerPort: this.contextService.objSettings.network_BWServerPort, chat_openStatus: this.contextService.objSettings.chat_openStatus, language_selected: this.contextService.objSettings.language_selected, stat_sortField: this.contextService.objSettings.stat_sortField, stat_sortOrder: this.contextService.objSettings.stat_sortOrder, userLoginForTheFirstTime: this.contextService.objSettings.userLoginForTheFirstTime, personalWallboardPersist: this.contextService.objSettings.personalWallboardPersist }));

            }

        } else {
            this.contextService.objSettings.setSettings(this.loginID);
            if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
                this.contextService.showAgentColumnsList = this.agentColumnsInTable;
                this.contextService.showQueueColumnsList = this.queueColumnsInTable;
                this.contextService.statistics.forEach(element => {
                    if (this.contextService.objSettings.showInPersonalWBList[element.queueID]) {
                        element.isDisplayInPersonalWallboard = this.contextService.objSettings.showInPersonalWBList[element.queueID].showInWallBoard;
                        if (!this.contextService.objSettings.defaultPasswordExist) {
                            element.isDisplayInPersonalWallboard = true;
                        }
                    } else {
                        element.isDisplayInPersonalWallboard = true;
                    }
                });
                this.contextService.showOnlySummaryStatInPW.next();
                this.updateColumnsInPersonalWallboard();
                this.contextService.calculateSummaryStats.next("waittime");
                this.contextService.checkIfShowPersonalWallboard();
                this.applyAllQueuesList = [];
                if (this.contextService.objSettings.STARTUP === 3) {
                    this.contextService.showAgentActivityColumnsList = this.agentActivityColumnsInTable;
                    if (this.contextService.showAgentActivityColumnsList.length < 1) {
                        this.contextService.showAgentActivityColumnsList.push({ id: 5, label: this.contextService.getLanguageTagByKey('Misc.Status'), value: 'agentStatus' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 2, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AcdState'), value: 'agentACDState' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 3, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.AcdStateDuration'), value: 'agentACDDuration' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 9, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'agentTotalCalls' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 11, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalMissedCalls'), value: 'agentMissedCalls' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 13, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalTalkTimeNew'), value: 'agentTotalTalkTime' });
                        this.contextService.showAgentActivityColumnsList.push({ id: 14, label: this.contextService.getLanguageTagByKey('List.Statistics.Column.TotalStaffedTime'), value: 'agentTotalStaffedTime' });

                    }
                    this.updateAgentActivityTab();
                }
            }

            if (this.settingsChanged) {
                this.contextService.websocketService.settings(this.contextService.objSettings);
                this.settingsChanged = false;
            }

        }


        this.contextService.timerSetUpForTitle();
        this.contextService.setSearchSettingsInContacttable.next("settingsSaved");
        return true;
    }


    /**
     * cancel settings save
     * 
     * @param {string} cancelMode 
     * @memberof SettingsGeneral
     */
    cancel(cancelMode: string): boolean {
        //agent
        if (this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) {
            //agentP3
            if (this.currentCallCenterAuthPage == "1") {
                this.router.navigate(['/settings-general', 'call-centers-authentication']);
                this.currentCallCenterAuthPage = "0";
                return false;
            }
            if (this.currentCallCenterAgentPage == "1") {
                this.router.navigate(['/settings-service', 'columns']);
                this.currentCallCenterAgentPage = "0";
                return false;
            }
            if (this.currentCallCenterQueuePage == "1") {
                this.router.navigate(['/settings-service', 'columns']);
                this.currentCallCenterQueuePage = "0";
                return false;
            }
            if (this.currentCallCenterQueuePage == "2") {
                this.contextService.setThreshold = true;
                this.showElements = false;
                this.currentCallCenterQueuePage = "1";
                // this.router.navigate(['/settings-service', 'callcenter-threshold']);
                return false;
            }
            if (this.currentAgentActivityPage == "1") {
                this.router.navigate(['/settings-service', 'columns']);
                this.currentAgentActivityPage = "0";
                return false;
            }
            if (this.currentAgentActivityPage == "2") {
                this.contextService.setThreshold = true;
                this.showElements = false;
                this.currentAgentActivityPage = "1";
                // this.router.navigate(['/settings-service', 'callcenter-threshold']);
                return false;
            }
            if (this.currentCallCenterAgentPage == "agentDoubleClick") {
                this.disableAgentColumnList = false;
                this.router.navigate(['/settings-service', 'columns']);
                this.currentCallCenterAgentPage = "0";
                return false;
            }

            if (this.currentCallCenterQueuePage == "queueDoubleClick") {
                this.router.navigate(['/settings-service', 'columns']);
                this.currentCallCenterQueuePage = "0";
                return false;
            }
            if (this.currentAgentActivityPage == "agentActivityDoubleClick") {
                this.router.navigate(['/settings-service', 'columns']);
                this.currentAgentActivityPage = "0";
                return false;
            }
        }
        if (cancelMode === 'closecancel') {
            this.appearence_unityOnTop = this.contextService.objSettings.appearance_AlwaysOnTop;
            this.appearence_showMissedCallCount = this.contextService.objSettings.appearance_MissedCallCountVisible;
            // this.appearence_onsystemtrayOrTaskbar = this.contextService.objSettings.;
            this.dialingRule_toReplace = this.contextService.objSettings.dialingRule_ToBeReplaced;
            this.dialingRule_toReplaceWith = this.contextService.objSettings.dialingRule_ReplacingValue;
            this.dialingRule_forIncomingCalls = this.contextService.objSettings.dialingRule_forIncomingCalls;
            this.callNoti_showRemotePartyPhoneNumber = this.contextService.objSettings.callNotification_ShowRemotePartyNumber;
            this.callNoti_overRideNameUsingNumber = this.contextService.objSettings.callNotification_OverrideContactName;
            this.callNoti_autoAnswer = this.contextService.objSettings.callNotification_AutoAnswer;
            this.contact_startsWith = this.contextService.objSettings.contact_startsWith;
            this.callNoti_popUnityOnCall = this.contextService.objSettings.callNotification_PopUpUnity;
            this.callNoti_autoHideUnity = this.contextService.objSettings.callNotification_AutoHide;
            this.callNoti_popSummaryNotification = this.contextService.objSettings.callNotification_PopUpSummary;
            this.callNoti_popSummarySeconds = this.contextService.objSettings.callNotification_PopUpSummaryDuration;
            this.startUpApp = this.contextService.objSettings.STARTUP;
            this.update_autoUpdateUnity = this.contextService.objSettings.update_AutoUpdatePermitted;
            this.update_updateCheckIntervall = this.contextService.objSettings.update_UpdateInterval;
            this.update_restartOrAlertAfterUpdate = this.contextService.objSettings.update_RestartOrAlertAfterUpdate;
            this.selectedLanguage = this.contextService.objSettings.language_selected;
            this.bwServerAddress = this.contextService.objSettings.network_BWServerAddress;
            this.bwServerPort = this.contextService.objSettings.network_BWServerPort;
            this.imServerAddress = this.contextService.objSettings.network_IMServerAddress;
            this.imServerPort = this.contextService.objSettings.network_IMServerPort;
            this.loginID = this.contextService.objSettings.broadworks_Login;
            this.password = this.contextService.objSettings.broadworks_Password;
            this.rememberLoginId = this.contextService.objSettings.broadworks_RememberBroadworksLogin;
            this.rememberPassword = this.contextService.objSettings.broadworks_RememberBroadworksPassword;

        } else {
            this.appearence_unityOnTop = this.contextService.objSettings.appearance_AlwaysOnTop;
            this.appearence_showMissedCallCount = this.contextService.objSettings.appearance_MissedCallCountVisible;
            this.dialingRule_toReplace = this.contextService.objSettings.dialingRule_ToBeReplaced;
            this.dialingRule_toReplaceWith = this.contextService.objSettings.dialingRule_ReplacingValue;
            this.dialingRule_forIncomingCalls = this.contextService.objSettings.dialingRule_forIncomingCalls;
            this.contact_startsWith = this.contextService.objSettings.contact_startsWith;
            this.callNoti_showRemotePartyPhoneNumber = this.contextService.objSettings.callNotification_ShowRemotePartyNumber;
            this.callNoti_overRideNameUsingNumber = this.contextService.objSettings.callNotification_OverrideContactName;
            this.callNoti_autoAnswer = this.contextService.objSettings.callNotification_AutoAnswer;
            this.callNoti_popUnityOnCall = this.contextService.objSettings.callNotification_PopUpUnity;
            this.callNoti_autoHideUnity = this.contextService.objSettings.callNotification_AutoHide;
            this.callNoti_popSummaryNotification = this.contextService.objSettings.callNotification_PopUpSummary;
            this.callNoti_popSummarySeconds = this.contextService.objSettings.callNotification_PopUpSummaryDuration;
            this.startUpApp = this.contextService.objSettings.STARTUP;
            this.update_autoUpdateUnity = this.contextService.objSettings.update_AutoUpdatePermitted;
            this.update_updateCheckIntervall = this.contextService.objSettings.update_UpdateInterval;
            this.update_restartOrAlertAfterUpdate = this.contextService.objSettings.update_RestartOrAlertAfterUpdate;
            this.rememberLoginId = this.contextService.objSettings.broadworks_RememberBroadworksLogin;
            this.rememberPassword = this.contextService.objSettings.broadworks_RememberBroadworksPassword;
        }

        this.browserInt_URL = this.contextService.objSettings.browserInt_URL;
        this.browserInt_InboundCallCenter = this.contextService.objSettings.browserInt_InboundCallCenter;
        this.browserInt_InboundNonCallCenter = this.contextService.objSettings.browserInt_InboundNonCallCenter;
        this.browserInt_OutboundCallCenter = this.contextService.objSettings.browserInt_OutboundCallCenter;
        this.browserInt_OutbountNonCallCenter = this.contextService.objSettings.browserInt_OutbountNonCallCenter;
        this.browserInt_EncodeURL = this.contextService.objSettings.browserInt_EncodeURL;
        this.browserInt_browserInterface = this.contextService.objSettings.browserInt_browserInterface;
        this.showBusinessNameColumn = this.contextService.objSettings.showBusinessNameColumn;
        this.selectJoinQueue = this.contextService.objSettings.callCenter_JoinAllQueue;
        this.contextService.objSettings.callCenter_LeaveAllQueue = this.leaveAllQueue;
        this.automaticallyAnswerCall = this.contextService.objSettings.agent_AutomaticCallAnswer;
        this.startUpACDState = this.contextService.objSettings.acdState_StartupACDState;
        this.postCallACDState = this.contextService.objSettings.acdState_PostCallACDState;
        this.wrapUpDuration = this.contextService.objSettings.acdState_WrapUpDuration;
        this.showCurrentACDStateButton = this.contextService.objSettings.acdState_ShowCurrentACDStateButton;
        this.signOutACDState = this.contextService.objSettings.acdState_SignOutACDState;
        this.assignDispositionCode = this.contextService.objSettings.acdState_AssignDispositionCode;
        this.dispositionTime = this.contextService.objSettings.acdState_DispositionTime;
        this.showDuration = this.contextService.objSettings.acdState_ShowDuration;
        this.includeCallCenter = this.contextService.objSettings.agent_IncludeCallCenterCalls;
        this.preventACDStateChage = this.contextService.objSettings.acdState_PreventACDStateChage;
        this.onlyShowMonitoringSupervisor = this.contextService.objSettings.agent_OnlyShowMonitoringSupervisor;
        this.showQueuedCalls = this.contextService.objSettings.agent_ShowQueuedCalls;

        //agentp3
        this.refreshFrequencyEnd = this.contextService.objSettings.statistics_RefreshFrequencyEndValue;
        this.refreshFrequencyStart = this.contextService.objSettings.statistics_RefreshFrequencyStartValue;
        this.displaySummaryStatisticsOnly = this.contextService.objSettings.statistics_DisplaySummaryStatisticsOnly;
        this.scrollbarCountOfPW = this.contextService.objSettings.statistics_ScrollbarCountPersonalWB;
        this.processCallCenterBatches = this.contextService.objSettings.statistics_ProcessCallCenterCount;
        this.agentColumnsInTable = this.contextService.showAgentColumnsList;
        this.queueColumnsInTable = this.contextService.showQueueColumnsList;
        this.allowWarmTransfer = this.contextService.objSettings.allow_WarmTransfer;
        this.allowWarmTransferToVoicemail = this.contextService.objSettings.allow_WarmTransfer_To_Voicemail;
        this.surveyExtension = this.contextService.objSettings.survey_Extension;
        this.callParkRetrieveShowButton = this.contextService.objSettings.callPark_Retrieve_ShowButton;

        this.contact_includeAllContact = this.contextService.objSettings.contact_includeAllContact;
        this.contact_showMonitoredUsersFirst = this.contextService.objSettings.contact_showMonitoredUsersFirst;
        this.contact_alwaysSortSearchByName = this.contextService.objSettings.contact_alwaysSortSearchByName;
        this.contact_includeDepartmentInSearch = this.contextService.objSettings.contact_includeDepartmentInSearch;
        this.contact_clearSearchBox = this.contextService.objSettings.contact_clearSearchBox;
        this.contact_privacyEnabled = this.contextService.objSettings.contact_privacyEnabled;
        this.contact_activateContactSearch = this.contextService.objSettings.contact_activateContactSearch;
        this.supervisor_showOnlyJoinedAgents = this.contextService.objSettings.supervisor_showOnlyJoinedAgents;
        this.supervisor_activityImage = this.contextService.objSettings.supervisor_activityImage;
        this.supervior_addAllAgentsToAgentList = this.contextService.objSettings.supervior_addAllAgentsToAgentList;
        this.supervisor_supervisedAgentsStaffedRatio = this.contextService.objSettings.supervisor_supervisedAgentsStaffedRatio;
        this.agentActivityColumnsInTable = this.contextService.showAgentActivityColumnsList;
        this.callPriority = this.contextService.objSettings.callPriority;
        return true;

    }

}



