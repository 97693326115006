<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <div class="cols-2">
    <div>
      <p-checkbox name="UnityOnTop" label={{SettingsAppearanceAlwaysShowUnityOnTop}} *ngIf="this.settingsGeneral.contextService.isElectron" [(ngModel)]="settingsGeneral.appearence_unityOnTop"
        class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="MissedCallCount" label={{SettingsAppearanceShowMissedCallCount}} [(ngModel)]="settingsGeneral.appearence_showMissedCallCount"
        class="full-width" binary="true"></p-checkbox>

    </div>
  </div>
</div>