<div class="row column-view">
  <div class="srch-field-voice-mail">
    <input type="text" placeholder="{{voicemailSearch}}" #gb pInputText [(ngModel)]="searchTerm" (input)="voicemail.filterGlobal($event.target.value, 'contains')">
    <span *ngIf="searchTerm.length===0" class="srch-ico">Search voice messages</span>
    <span *ngIf="searchTerm.length!==0" class="srch-ico-x" (click)="clearSearch()" style="right: 0 !important;">X</span>

  </div>
</div>


<p-table [value]="contextService.voiceMailList" selectionMode="single" [(selection)]="selectedVoiceMessageEntry"
  [globalFilterFields]="['name','phone']" [responsive]="true" [sortField]="sortF" [sortOrder]="sortO" rowGroupMode="subheader"
  [(contextMenuSelection)]="selectedVoiceMessageEntry" [contextMenu]="voicecm" contextMenuSelectionMode="joint" id="voicemail"
  #voicemail [scrollable]="true" scrollHeight="{{voiceTableHeight}}" (onSort)="changeSortVoiceMessage($event)"
  [ngClass]="{'scrolling':scrolling === true}" (onContextMenuSelect)="contextMenuSelected($event,voicecm)" (onSort)="changeSortVoiceMessage($event)"
  dataKey="id" [primeDragula]="voicebag" [dragulaModel]="contextService.voiceMailList" [dragulaOptions]="{ childContainerSelector: '#voicemail .ui-table-scrollable-body-table .ui-table-tbody', initAfterView: true, copy: false, childContainerSelectorID: 'voicemail-body' }">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      <th [pSortableColumn]="'name'">{{name}}
        <p-sortIcon [field]="'name'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'phone'">{{phoneNumber}}
        <p-sortIcon [field]="'phone'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'duration_display'">{{duration}}
        <p-sortIcon [field]="'duration_display'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'date_time'">{{callDate}}
        <p-sortIcon [field]="'date_time'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
    <tr class="ui-widget-header" *ngIf="(rowGroupMetadata[rowData.period].index === rowIndex)&&(searchTerm=='')">
      <td colspan="5">
        <span class="ui-rowgroup-header-name">{{rowData.period}}</span>
      </td>
    </tr>
    <tr (dblclick)="onRowDblclick_play(rowData)" (click)="rowClick(rowData)" [pContextMenuRow]="rowData"
      [pSelectableRow]="rowData">
      <td>
        <a href="#" class="ico-status ico-play" pTooltip="Play voicemail"></a>
      </td>
      <td styleClass="name-cell">
        <span class="ui-column-title">{{name}}</span>
        <span [attr.data-id]="rowData.id">{{rowData.name}}</span>
      </td>
      <td>
        <span class="ui-column-title">{{phoneNumber}}</span>
        {{rowData.address}}</td>
      <td>
        <span class="ui-column-title">{{duration}}</span>
        {{rowData.duration_display}}</td>
      <td>
        <span class="ui-column-title">{{callDate}}</span>
        {{rowData.date_time|datex:'MM/DD/YYYY hh:mm:ss A'}}</td>
    </tr>
  </ng-template>
</p-table>

<div #voiceTableMenu [ngStyle]="{'height': voiceTableMenuHeight}"></div>

<p-contextMenu #voicecm [model]="contextMenuItems" appendTo="body"></p-contextMenu>


<!-- <p-dialog [resizable]="false" [(visible)]="this.contextService.mediaPlayerState" [ngClass]="{visible: this.contextService.mediaPlayerState}"
  [closable]="true" width="350" class="small-dialog media-player-dialog" dismissableMask="false" showHeader="false">
  <div class="modal-content">
    <span class="close-modal small" (click)="closePlayer()">X</span>
    <div style="margin-top: 30px;margin-left: 10px;">
      <audio id="myAudio" controls>
        <source id="mediaSource" [src]="contextService.urlVoiceMessage" />
        <source id="mediaSource1" [src]="contextService.urlVoiceMessage" />
        <a id="oggSource2" [href]="contextService.urlVoiceMessage">My Audio</a>
      </audio>
    </div>
  </div>
</p-dialog> -->
<p-dialog [resizable]="false" [(visible)]="this.contextService.mediaPlayerState" [ngClass]="{visible: this.contextService.mediaPlayerState}"
  [closable]="true" [style]="{'width':'350px'}" class="small-dialog media-player-dialog" dismissableMask="false" showHeader="false">
  <div class="modal-content">
    <span class="close-modal small" (click)="closePlayer()">X</span>
    <div style="margin-top: 30px;margin-left: 10px;">
      <audio id="myAudio" controls src={{contextService.urlVoiceMessage}}>
        <!-- <source id="mediaSource" [src]="contextService.urlVoiceMessage" />
        <source id="mediaSource1" [src]="contextService.urlVoiceMessage" />
        <a id="oggSource2" [href]="contextService.urlVoiceMessage">My Audio</a> -->
      </audio>
    </div>
  </div>
</p-dialog>

<a download="VoiceMessage.mp3" [href]="myUrl" id="forhref" style="display: none;">DownLoad</a>

<p-dialog [resizable]="false" [(visible)]="deleteVoiceMessageConfirm" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: deleteVoiceMessageConfirm}"
  class="dialog-window buttons-2" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{deleteVoicemail}}</p>
    <div>
      <button type="button" class="btn-white" (click)="deleteVoiceMessageConfirm = false">{{miscNo}}</button>
      <button type="button" class="btn-blue-light" (click)="deleteVoiceMailEntry()">{{miscYes}}</button>

    </div>
  </div>
</p-dialog>
