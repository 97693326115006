import { Directive, HostListener, HostBinding, ElementRef } from '@angular/core';
@Directive({
    selector: '[appDropdown]'
})
export class DropdownDirective{
    constructor(private elRef : ElementRef) {}
    @HostBinding('class.open') isOpen = false;
    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement) {
        const clickedInside = this.elRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.isOpen = false;
        }else{
            this.isOpen = !this.isOpen;
        }
    }
}