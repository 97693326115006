import { Injectable } from '@angular/core';
import { CallForwardingNoAnswer } from '../../../model/bw-services/call-forwarding-no-answer';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
/**
 * to register callForwardingNoAnswerResponseHandler   
 * 
 * @export
 * @class CallForwardingBusyService
 */
@Injectable()

export class CallForwardNoAnswerService {
    numberOfRingArray = [];
    numOfRings: string = "";
    phoneNumber: string = "";
    isActive: boolean = false;
    callForwardingNoAnswer: CallForwardingNoAnswer;
    noAnswerChanges: boolean = false;
    numberValidation: boolean = false;
    constructor(private bwServices: BwServices, public contextService: ContextService) {
        this.contextService.log('###### constructor - CallForwardNoAnswerService');
    }
    setData(callForwardingNoAnswer: CallForwardingNoAnswer) {
    }
    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) {
    }
    save() {
    }
    populateData() {
    }
    cancel() {
    }

}