<!-- <div class="row">
  <p>
    {{description}}
    <p>
</div> -->
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{this.showBusinessNameColumn}} [(ngModel)]="settingsGeneral.showBusinessNameColumn" (ngModelChange)="showBusinessColumn()"
    binary="true"></p-checkbox>
</div>

<div class="row">
  <div class="error"  class="user-{{this.cssClass}}">{{authoriseStatus}}</div>
  <!-- <div class="error"  class="user-loggedin">{{authorisationTokenSaved}}</div> -->
</div>

<div class="row test">
     <!-- <button type="submit" form="loginForm" class="btn-blue-light" (click)="this.authoriseClick()">{{authorise}}</button> -->
     <!-- <button pButton type="submit" class="btn-blue-light" icon="ico-callforward" iconPos="left">{{authorise}}</button> -->
     <!-- <p-button label="{{authorise}}" class="button-color" icon="ico-available" iconPos="left"></p-button> -->

     <button pButton type="button" *ngIf="this.authoriseButtonClicked===false" label="{{authorise}}" class="button-color" icon="ico-available" iconPos="left" (click)="this.authoriseClick()"></button>

     <button pButton type="button" *ngIf="this.authoriseButtonClicked===true" label="{{authoriseComplete}}" class="button-color" icon="ico-available" iconPos="left" (click)="this.authoriseCompleteClick()"></button>
</div>
<div class="row test">
    <button pButton type="button" *ngIf="this.authoriseButtonClicked===true" label="{{authoriseCancel}}" class="button-color" icon="ico-busy" iconPos="left" (click)="this.authoriseCancelClick()"></button>
    <!-- <button type="submit" form="loginForm" class="btn-blue-light" (click)="this.authoriseClick()">{{authoriseCancel}}</button> -->
</div>
  
<!-- 
<p-dialog [(visible)]="this.authorisationFailedAlert" [closable]="false" width="370" class="dialog-window buttons-1" dismissableMask="true"
  showHeader="false" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{this.authorisationFailedError}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="this.authorisationFailedAlert=false">{{callButtonShowingOK}}</button>
    </div>
  </div>
</p-dialog> -->
  
  
<p-dialog [resizable]="false" [(visible)]="this.authorisationFailedAlert" [closable]="false" [style]="{'width':'330px'}" [ngClass]="{visible: this.authorisationFailedAlert}"
  class="dialog-window buttons-1" dismissableMask="true" showHeader="false">
  <div class="modal-content" style="text-align: center;">
    <h4>{{this.authorisationFailedError}}</h4>
    <button type="button" class="btn-blue-light" (click)="this.authorisationFailedAlertClick()">{{callButtonShowingOK}}</button>
  </div>
</p-dialog>