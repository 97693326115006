import { HelperService } from './../providers/helper.service';
import { DataService } from './../providers/dataservice.service';
import { ChatService } from "app/providers/chat.service";
import { Directive, OnChanges, AfterViewInit, OnInit, Input, ElementRef, SimpleChange, OnDestroy, HostListener } from '@angular/core';
import { DragulaService, dragula } from 'ng2-dragula';
import { ContextService } from "./../providers/context.service";
import { BwDirectoryEntry } from '../model/directory/bw-directory-entry';
@Directive({ selector: '[primeDragula]' })
export class PrimeDragulaDirective implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() public primeDragula: string;
  @Input() public dragulaModel: any;
  @Input() public dragulaOptions: any;
  protected container: any;
  private drake: any;
  private options: any;
  srcIsActiveTable: boolean = false;
  dropAreaClass = 'drop-call-wrapper';

  dragDelay = 200; // milliseconds
  draggable = false;
  touchTimeout;

  // @HostListener('touchmove', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  onMove(e) {

    //For set curson position
    let cordx = 0;
    let cordy = 0;
    if (!e) {
      e = window.event;
    }
    if (e.pageX || e.pageY) {
      cordx = e.pageX;
      cordy = e.pageY;
    } else if (e.clientX || e.clientY) {
      cordx = e.clientX;
      cordy = e.clientY;
    }

    let draggedElements = document.getElementsByClassName('ui-selectable-row ng-star-inserted gu-mirror') as HTMLCollectionOf<HTMLElement>;
    for (let i = 0; i < draggedElements.length; i++) {
      draggedElements[0].style.left = (cordx - 1) + 'px';
      draggedElements[0].style.top = (cordy - 10) + 'px';
    }

    if (this.draggable) {
      e.stopPropagation();
      clearTimeout(this.touchTimeout);
      if (this.srcIsActiveTable === true) {
        if (e.target.tagName === 'TD' || e.target.tagName === 'SPAN') {
          const rows = e.target.parentNode.parentNode.children;
          const tr = e.target.parentNode;
          this.helper.forEach(rows, (i, row) => {
            this.helper.removeClass(row, 'hl');
            this.helper.addClass(tr, 'hl');
          });
          this.helper.setContextMenuCoordinate(e.pageX, e.pageY);
        }
      }
    }
  }

  // @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onDown(e) {
    e.preventDefault();
    this.touchTimeout = setTimeout(() => {
      this.draggable = true;
    }, this.dragDelay);
  }

  // @HostListener('touchend', ['$event'])
  @HostListener('document:mouseup', ['$event'])
  onUp(e) {
    clearTimeout(this.touchTimeout);
    this.helper.tableDropped.subscribe((val) => {
      const rows = val.querySelectorAll('tbody tr');
      this.helper.forEach(rows, (i, row) => {
        this.helper.removeClass(row, 'hl');
      });
    });
    this.draggable = false;
  }

  public constructor(private el: ElementRef,
    private dragulaService: DragulaService,
    private dataService: DataService,
    private chatService: ChatService,
    private helper: HelperService,
    private contextService: ContextService) {

    this.dragulaService.drag.subscribe((value) => {
      this.onDrag(value.slice(1));
    });
    this.dragulaService.drop.subscribe((value) => {
      this.onDrop(value.slice(1));
    });
    this.dragulaService.over.subscribe((value) => {
      this.onOver(value.slice(1));
    });
    this.dragulaService.out.subscribe((value) => {
      this.onOut(value.slice(1));
    });
  }

  ngOnInit() {
    this.options = Object.assign({}, this.dragulaOptions);
    this.container = this.el.nativeElement;

    if (!this.options.initAfterView) {
      this.initialize();
    }
  }

  ngAfterViewInit() {
    if (this.options.initAfterView) {
      this.initialize();
    }
  }

  ngOnDestroy() {
    //this.dragulaService.destroy(this.primeDragula);
  }

  private onDrag(args) {
    let [e, el] = args;
    this.helper.removeClass(e, 'ex-moved');
    this.helper.dragStart.next(true);
  }

  private onDrop(args) {
    const [e, el, src] = args;
    this.getDroppedData(e, src.id).then((res) => {
      let data;
      if (res) {
        data = res;
        if (el !== null && data) {
          if (this.helper.hasClass(el, 'drop-call-wrapper')) {
            // remove copied HTML row, we are gonna generate a new one
            e.remove();
            // add dropped object to a new callsQueue array if it is not already there and - MOVED TO SERVICE
            // generate a new row based on that in a drop-call-area component
            this.dataService.droppedToCall.next(data.rowIdModel);
          }
          // dropping a person to header button
          if (this.helper.hasClass(el, 'ico-drop')) {
            e.remove();
          }
          // dropping a person to location label
          if (this.helper.hasClass(el, 'loc-label')) {
            e.remove();
          }
          //supervisor
          //drpped to Agent activity table
          if (el.id === 'agentActivity-body') {
            this.contextService.dropedToname = "";
            this.contextService.dropedToType = "";
            this.contextService.droppedToNumber = "";
            this.contextService.droppedcall = null;
            e.remove();
            const coo = this.helper.getContextMenuCoordinate();
            this.contextService.droppedcall = data.rowIdModel;
            if ((data.rowIdModel.displayState === "Active") || (data.rowIdModel.displayState === "On hold")) {
              //supervisor todo
              if (args[3]) {
                if (args[3].children[1]) {
                  console.log("dropedToname:" + args[3].children[1].innerText);
                  this.contextService.dropedToname = args[3].children[1].innerText
                }
              }
              this.helper.openedMenuOnDropAgentActivity.next(coo);
            }
            this.helper.tableDropped.next(el);
          }
          // dropping a person from Active calls to Contact table          
          if (el.id === 'calllogs-body') {
            this.contextService.droppedToNumber = "";
            this.contextService.droppedcall = null;
            e.remove();
            const coo = this.helper.getContextMenuCoordinate();
            this.contextService.droppedcall = data.rowIdModel;
            if ((data.rowIdModel.displayState === "Active") || (data.rowIdModel.displayState === "On hold")) {
              this.helper.openedMenuOnDropCallLogs.next(coo);
              if (args[3]) {
                if (args[3].children[2]) {

                  console.log(args[3].children[2].innerText);
                  this.contextService.droppedToNumber = args[3].children[2].innerText
                  // this.contextService.websocketService.blindTransferAction(data.rowIdModel.callId, args[3].children[2].innerText);

                }
              }

            }
            this.helper.tableDropped.next(el);

          }
          // dropping a person from Active calls to Contact table
          if (el.id === 'contact-body') {
            this.contextService.dropedToname = "";
            this.contextService.dropedToType = "";
            this.contextService.droppedToNumber = "";
            this.contextService.droppedcall = null;
            e.remove();
            const coo = this.helper.getContextMenuCoordinate();
            if ((data.rowIdModel.displayState === "Active") || (data.rowIdModel.displayState === "On hold")) {
              this.helper.openedMenuOnDrop.next(coo);
            }
            this.contextService.droppedcall = data.rowIdModel;
            this.helper.tableDropped.next(el);
            if (args[3]) {
              if (args[3].children[2]) {
                if (args[3].children[1].innerText) {
                  this.contextService.dropedToname = args[3].children[1].innerText;
                }
                // if (args[3].children[0].children[1].classList[1]) {
                //   this.contextService.dropedToType = args[3].children[0].children[1].classList[1];
                // }


                if (args[3].children[0].children[1].classList[1]) {
                  this.contextService.dropedToType = args[3].children[0].children[1].className.replace('ico-status', '').trim();
                }
                for (let index = 0; index < args[3].children.length; index++) {
                  const element = args[3].children[index];
                  if (element.className == "phone-cell") {
                    if (element.innerText) {
                      // this.contextService.droppedToNumber = args[3].children[2].innerText;
                      this.contextService.droppedToNumber = (element.innerText.replace(/(\r\n|\n|\r)/gm, "").replace(/ +/g, "")).replace("Phone", "");
                      break;
                    }
                  }
                }

              }
            }
          }

          // dropping a person from Active calls to Statistics
          if (el.id === 'stats-body') {
            e.remove();
            const coo = this.helper.getContextMenuCoordinate();
            this.helper.openedMenuOnDropStats.next([data.rowIdModel, args[3].children[1].innerText]);
            this.helper.tableDropped.next(el);
            // console.log(el);
            // console.log('dropped rowData',data.rowIdModel);
          }

          /** CHAT */
          // dropping a person to start chat
          if (el.id == 'new-chat' && (src.id == 'contact-body' || src.id == 'small-contact-table')) {
            e.remove();
            // NOT OFFLINE, NOT EXTERNAL
            if (data.rowIdModel.mystatusico != 'external' && data.rowIdModel.online != false) {
              this.chatService.droppedToChat.next(data.rowIdModel);
              this.helper.removeClass(document.getElementById('chat-panel'), 'dropping');
            }
          }

          // dropping a person to start chat
          if (el.tagName == 'APP-CONVERSATION' && (src.id == 'contact-body' || src.id == 'small-contact-table')) {
            e.remove();
            // NOT OFFLINE, NOT EXTERNAL
            if (data.rowIdModel.mystatusico != 'external' && data.rowIdModel.online != false) {
              const dropped = { 'element': el, 'data': data };
              this.chatService.droppedToConversation.next(dropped);
              this.helper.removeClass(document.getElementById('chat-panel'), 'dropping');
            }
          }

          // dropping a person for more options from activa calls
          if (el.tagName == 'APP-CONVERSATION' && src.id == 'active-calls') {
            e.remove();
            const dropped = { 'element': el, 'data': data };
            this.contextService.droppedcall = data.rowIdModel;
            this.chatService.droppedActiveCallToConversation.next(dropped);
            this.helper.removeClass(document.getElementById('chat-panel'), 'dropping');
          }
        }
      };
    }).catch((e) => { console.log('Error getting data:' + e) });

    this.srcIsActiveTable = false;
  }

  async getDroppedData(e, srcID?) {
    // get ID of the dropped row (not ideal but other options didn't work)
    const el = e.querySelector('td span[data-id]');
    const data = await this.getData(el, srcID);
    if (el) {
      return data;
    } else {
      return { rowId: 0, rowIdModel: 0 }
    }
  }

  getData(el, srcID?) {
    return new Promise((resolve) => {
      let data: any;
      let rowId: any;
      let rowIdModel: any;
      if (el) {
        rowId = Number(el.dataset.id);
      }
      if (srcID && this.container && this.container.id === srcID) {
        if (this.dragulaModel.length) {
          rowIdModel = this.dragulaModel.filter(val => val.id === rowId)[0];
          data = { rowId: rowId, rowIdModel: rowIdModel }
        }
      }
      resolve(data);
    });
  }

  private onOver(args) {
    const [e, el, container] = args;
    // dropping a person to call table
    if (this.helper.hasClass(el, this.dropAreaClass)) {
      this.helper.addClass(el, 'drag-over');
    }
    // dropping a person to header button
    if (this.helper.hasClass(el, 'ico-drop')) {
      this.helper.addClass(el, 'drop');
    }
    // dropping a person to location label
    if (this.helper.hasClass(el, 'loc-label')) {
      this.helper.addClass(el, 'active');
    }

    /** CHAT */
    var chatDiv = document.getElementById('chat-panel');

    if (container.id === 'active-calls' || el.id === 'stats-body') {
      this.srcIsActiveTable = true;
    }

    // dropping a person to start chat
    if ((el.id == 'chat-conversations' || el.id == 'new-chat') && (container.id == 'contact-body' || container.id == 'small-contact-table')) {
      let data;
      this.getDroppedData(e, container.id).then((res) => {
        if (res) {
          data = res;
          // NOT OFFLINE, NOT EXTERNAL

          if (data.rowIdModel.type === 'Broadworks Directory') {
            this.helper.addClass(chatDiv, 'dropping');
          } else if (data.rowIdModel.mystatusico == 'external') {
            this.helper.addClass(chatDiv, 'external-drop');
          } else if (data.rowIdModel.type != 'Broadworks Directory') {
            this.helper.addClass(document.body, 'offline-person');
          }
        }
      });
    }
    // dropping person from active call table to chat
    if (el.tagName == 'APP-CONVERSATION' && container.id == 'active-calls') {
      let data;
      this.getDroppedData(e, container.id).then((res) => {
        if (res) {
          data = res;
          // NOT OFFLINE, NOT EXTERNAL
          if (data.rowIdModel.mystatusico != 'external' && data.rowIdModel.online != false) {
            this.helper.addClass(el, 'dropping-person');
          } else if (data.rowIdModel.online == false) { // only offline
            this.helper.addClass(document.body, 'offline-person');
          }
        }
      });
    }

    if ((el.id === 'new-chat') && this.helper.hasClass(container, 'ui-datatable-data')) {
      let data;
      var temp = false;
      this.getDroppedData(e, container.id).then((res) => {
        if (res) {
          data = res;
          // NOT OFFLINE, NOT EXTERNAL
          if (data.rowIdModel.type === 'Broadworks Directory') {
            this.helper.addClass(el, 'over');
          }
          this.contextService.directoryList.forEach(elementChat => {
            if (elementChat instanceof BwDirectoryEntry) {
              if (data.rowIdModel.phone === elementChat.getExtension()) {
                temp = true;

                this.helper.addClass(el, 'over');

              }
            }
          });
          if (!temp) {
            if (data.rowIdModel.type != 'Broadworks Directory') { // only offline
              this.helper.addClass(document.body, 'offline-person');
            }
          }
        }
      });
    }

    // dropping a person to start chat
    if (this.helper.hasClass(el, 'app-conversation') && (container.id == 'contact-body' || container.id == 'small-contact-table')) { //this.helper.hasClass(container,'ui-datatable-data')
      let data;
      this.getDroppedData(e, container.id).then((res) => {
        if (res) {
          data = res;
          // NOT OFFLINE, NOT EXTERNAL
          if (data.rowIdModel.type === 'Broadworks Directory') {
            this.helper.addClass(el, 'adding-person');
            this.helper.addClass(chatDiv, 'dropping');
          } else if (data.rowIdModel.type != 'Broadworks Directory') { // only offline
            this.helper.addClass(el, 'offline-person');
            this.helper.addClass(document.body, 'offline-person');
          } else {
            this.helper.addClass(document.body, 'external-drop');
          }
        }
      });
    }
  }
  private onOut(args) {
    const [e, el, container] = args;

    var chatDiv = document.getElementById('chat-panel');
    if (this.helper.hasClass(el, this.dropAreaClass)) {
      this.helper.removeClass(el, 'drag-over');
    }
    if (this.helper.hasClass(el, 'ico-drop')) {
      this.helper.removeClass(el, 'drop');
    }
    if (this.helper.hasClass(el, 'loc-label')) {
      this.helper.removeClass(el, 'active');
    }

    /** CHAT */
    // dropping a person to start chat
    if (this.helper.hasClass(el, 'conversations')) {
      this.helper.removeClass(el, 'adding-coversation');
      this.helper.removeClass(chatDiv, 'dropping');
      //this.helper.removeClass(chatDiv, 'external-drop');
    }

    // dropping person from active call table
    if (el.tagName == 'APP-CONVERSATION' && container.id == 'active-calls') {
      this.helper.removeClass(el, 'dropping-person');
    }

    // dropping a person to start chat
    if (this.helper.hasClass(el, 'app-conversation')) {
      this.helper.removeClass(el, 'adding-person');
      this.helper.removeClass(el, 'offline-person');
      this.helper.removeClass(document.body, 'offline-person');
      this.helper.removeClass(chatDiv, 'dropping');
    }
    if (el.id == 'new-chat') {
      this.helper.removeClass(el, 'over');
    }

    if (el.className == 'disabled-chat') {
      this.helper.removeClass(document.body, 'external-drop');
    }

    if (this.helper.hasClass(el, 'conversations')) {
      this.helper.removeClass(chatDiv, 'dropping');
      this.helper.removeClass(document.body, 'offline-person');

    }

    this.helper.removeClass(chatDiv, 'over');
  }

  //since we dont have access to the ngprime datatable body or table itself we need to bing laters in the angular event cycle
  //Once this fires we have a tbody tag to attach to and create the drag drop area from.
  //because we need to setup dragula later we needed to create our own version of the directive so we have access to the private property container.
  //If ngdragula ever changes that to protected we can just extend that directive outright and override the container.
  protected initialize() {
    if (this.options.childContainerSelector) {
      this.container = this.el.nativeElement.querySelector(this.options.childContainerSelector);
      if (this.options.childContainerSelectorID) {
        this.container.setAttribute('id', this.options.childContainerSelectorID);
      }
      if (this.options.itemClass) {
        this.dataService.getContacts().subscribe(
          (response) => {
            const items = this.container.querySelectorAll('tr');
            this.helper.forEach(items, (i, element) => {
              this.helper.addClass(element, this.options.itemClass);
            });
          }
        );
      }
    }
    const bag = this.dragulaService.find(this.primeDragula);
    // console.log('bag',bag);

    this.options.accepts = function (el, target, source, sibling) {
      //console.log(this); console.log('el',el); console.log('target',target); console.log('source', source); console.log('sibling', sibling); console.log('target.tagName', target.tagName ); 
      if (target) {
        if (target.id === 'chat-conversations' && source.tagName === 'APP-CONVERSATION') {
          return false;
        }
        if (target.id === 'chat-conversations' && el.tagName != 'TR') {
          return false;
        }
        if (target.tagName === 'APP-CONVERSATION') {
          if (el.tagName != 'TR') {
            return false;
          }
        }
        // bifin start
        if ((target.id === 'calllogs-body') && (source.id === 'active-calls')) {
          return true;
        }
        //supervisor
        if ((target.id === 'agentActivity-body') && (source.id === 'active-calls')) {
          return true;
        }
        if (target.id == 'agentActivity' && source.id == 'stats-body') {
          return false;
        }
        //supervisor
        //bifin end
        if (target.id === 'active-calls' || target.id === 'calllogs-body' || target.id === 'voicemail-body') {
          return false;
        }
        if (target.id == 'contact-body' && source.id == 'stats-body') {
          return false;
        }
        if (target.id == 'drop-call-wrapper' && source.id == 'active-calls') {
          return false;
        }
      }

      return source.id !== 'drop-call-table';
    }
    /*
    this.options.isContainer = function (el) {
      return false; // only elements in drake.containers will be taken into account
    }*/

    this.options.moves = function (el, source, handle) {
      if (source.tagName == 'APP-CONVERSATION') {
        return false;
      }

      // console.log(el); console.log(container); console.log(handle);
      if ((source.id == 'contact-body' && handle) || (source.id == 'stats-body' && handle)) { // only names dragable
        // if (handle.parentNode.className !== 'name-cell') {
        //   return false;
        // }
      }
      if (source.id == 'calllogs-body' && handle) { // only names dragable
        // if (handle.parentNode.parentNode.className !== 'name-cell') {
        //   return false;
        // }
      }
      if (source.id == 'voicemail-body' && handle) { // only names dragable
        // if (handle.parentNode.parentNode.className !== 'name-cell') {
        //   return false;
        // }
      }
      if (source.id == 'active-calls' && handle) { // only names dragable
        if (handle.parentNode.className.indexOf('name-cell') === -1) {
          return false;
        }
      }
      if (source.id == 'drop-call-wrapper' || source.id === 'my-stats-table') {
        return false;
      }

      return true;
    }

    /*
    this.options.copy = function (el, source) {
       console.log('this.container:',this.container);
       // To copy only elements in left container, the right container can still be sorted
       return source === this.container;
    }*/

    // console.log('bag',bag);
    // console.log('opts',this.options);

    const checkModel = () => {
      if (this.dragulaModel) {
        if (this.drake.models) {
          this.drake.models.push(this.dragulaModel);
        } else {
          this.drake.models = [this.dragulaModel];
        }
      }
    };

    if (bag) {
      this.drake = bag.drake;
      checkModel();
      this.drake.containers.push(this.container);
    } else {
      this.drake = dragula([this.container], this.options);
      checkModel();
      this.dragulaService.add(this.primeDragula, this.drake);
    }
    //console.log(this.drake);
  }

  public ngOnChanges(changes: { dragulaModel?: SimpleChange }): void {
    if (changes && changes.dragulaModel) {
      if (this.drake) {
        if (this.drake.models) {
          const modelIndex = this.drake.models.indexOf(changes.dragulaModel.previousValue);
          this.drake.models.splice(modelIndex, 1, changes.dragulaModel.currentValue);
        } else {
          this.drake.models = [changes.dragulaModel.currentValue];
        }
      }
    }
  }
}
