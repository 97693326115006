

import { Subscription } from 'rxjs';
/**
 * model class to store each call details
 * 
 * @export
 * @class CallStatusEntry
 */
export class CallStatusEntry {
  public imagePath: string;
  public id: number;
  public callId: string;
  public from: string;
  public to: string;
  public callStatus: string;
  public number: string;
  public userId: string;
  public isRedirected: boolean;
  public extTrackingId: string;
  public personality: string;
  public isConference: boolean = false;
  public conferenceState: string;
  public displayState: string;
  public remoteUserId: string;
  public userDNCountryCode: string;
  public userDNtel: string;
  public userDNext: string;


  public from_display: string;
  public to_display: string;
  // public displayStatus: string;
  public callTime: string;
  public duration: string = '00:00';
  public callIconState: string;
  public isTransferCall: boolean = false;
  sub: Subscription;
  ticks = 0;

  public recordState: string = "none";
  public recordDisplayState: string = "";
  public recordingEnabled = true;

  public isCallCenterCall: boolean = false;
  public callcenterId: string = "";

  //ACD Events
  public addTime: string;
  public position: number = 0;
  public acdPriority: string;
  public isQueuedCall: boolean;
  public targetId: string;
  public isQueuedTransferCall: boolean;

  constructor(callId: string, from: string, to: string, callStatus: string, number: string, userId: string, isRedirected: boolean, extTrackingId: string, personality: string, displayState: string,
    remoteUserId: string, userDNCountryCode: string, userDNtel: string, userDNext: string, callTime: string, callIconState: string, isCallCenterCall: boolean,
    callcenterId: string, addTime: string, isQueuedCall: boolean, isQueuedTransferCall: boolean) {
    this.id = parseInt(callId.replace(/[^0-9]/g, ''));
    this.callId = callId;
    this.from = from;
    this.to = to;
    this.callStatus = callStatus;
    this.number = number;
    this.userId = userId;
    this.isRedirected = isRedirected;
    this.extTrackingId = extTrackingId;
    this.personality = personality;
    this.displayState = displayState;
    this.remoteUserId = remoteUserId;
    this.userDNCountryCode = userDNCountryCode;
    this.userDNtel = userDNtel;
    this.userDNext = userDNext;
    this.callTime = callTime;
    this.callIconState = callIconState;
    this.isCallCenterCall = isCallCenterCall;
    this.callcenterId = callcenterId;

    this.from_display = "";
    for (let index = 0; index < from.length; index++) {
      if (index % 2 == 0) {
        this.from_display = this.from_display + from.substring(index, index + 2) + "\u00AD";
      }
    }
    this.to_display = "";
    for (let index = 0; index < to.length; index++) {
      if (index % 2 == 0) {
        this.to_display = this.to_display + to.substring(index, index + 2) + "\u00AD";
      }
    }

    this.addTime = addTime;
    this.isQueuedCall = isQueuedCall;
    this.isQueuedTransferCall = isQueuedTransferCall;

  }


  getIcon() {
    return this.callIconState
  }

  setFromDisplay() {
    this.from_display = "";
    for (let index = 0; index < this.from.length; index++) {
      if (index % 2 == 0) {
        this.from_display = this.from_display + this.from.substring(index, index + 2) + "\u00AD";
      }
    }
  }
  setToDisplay() {
    this.to_display = "";
    for (let index = 0; index < this.to.length; index++) {
      if (index % 2 == 0) {
        this.to_display = this.to_display + this.to.substring(index, index + 2) + "\u00AD";
      }
    }
  }
}
