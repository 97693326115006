/**
 * model to store schedule status list of im users
 * 
 * @export
 * @class ScheduledStatusList
 */
export class ScheduledStatusList {
    public imStatus: string;
    public id: string;
    public sDate: string;
    public eDate: string;
    public sFDate: string;
    public eFDate: string;

    constructor(imStatus: string, id: string,  startDate: string, endDate: string,  fStartDate: string, fEndDate: string) {
        this.imStatus = imStatus;
        this.id = id;
        this.sDate = startDate;
        this.eDate = endDate;
        this.sFDate = fStartDate;
        this.eFDate = fEndDate;
     
    }
   
}