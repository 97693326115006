

export class WebClientActionBase {
    private document: Document;
    protected getRootElement(): Element {
        return this.getElementByTagName("webClientAction");
    }
    protected getElementByTagName(tagName: string): Element {
        return this.getElementsByTagName(tagName)[0];
    }
    protected getElementByTagName1(parentName: string, tagName: string): Element {
        let tag = null;
        for (let index = 0; index < this.getElementsByTagName(tagName).length; index++) {
            if (this.getElementsByTagName(tagName)[index].parentNode.nodeName == parentName) {
                tag = this.getElementsByTagName(tagName)[index];
                break;
            }
        }
        return tag;
    }
    protected getElementsByTagName(tagName: string): HTMLCollectionOf<Element> {
        return this.document.getElementsByTagName(tagName);
    }
    protected setType(type: string) {
        this.setAttribute("type", type);
    }
    protected addElement(tagName: string, value: string): void {
        let element = this.document.createElement(tagName);
        element.appendChild(document.createTextNode(value));
        this.getRootElement().appendChild(element);
    }
    protected addElementToChild(parentName: string, tagName: string, value: string): void {
        let element = this.document.createElement(tagName);
        element.appendChild(document.createTextNode(value));
        this.getElementByTagName(parentName).appendChild(element);
    }

    protected addElementToChild1(parentName: string, tagName: string, value: string, childValue: string): void {
        let element = this.document.createElement(tagName);
        let element1 = this.document.createElement(value);
        element1.appendChild(document.createTextNode(childValue));
        element.appendChild(element1);
        this.getElementByTagName(parentName).appendChild(element);
    }

    protected addElementToChild2(parentName: string, tagName: string, value1: string, value2: string, childValue1: string, childValue2: string): void {
        let element = this.document.createElement(tagName);
        let element1 = this.document.createElement(value1);
        element1.appendChild(document.createTextNode(childValue1));
        element.appendChild(element1);

        let element2 = this.document.createElement(value2);
        element2.appendChild(document.createTextNode(childValue2));
        element.appendChild(element2);

        this.getElementByTagName(parentName).appendChild(element);
    }

    protected addElementToChild4(parentName: string, tagName: string, value: any[], childValue: any[], objectList?: object): void {
        let element = this.document.createElement(tagName);

        for (let i = 0; i < childValue.length; i++) {
            let element1 = this.document.createElement(value[i]);
            element1.appendChild(document.createTextNode(childValue[i]));
            element.appendChild(element1);
        }

        this.getElementByTagName(parentName).appendChild(element);

        if (objectList != undefined) {
            let callCenters = this.document.createElement("callCenters");
            element.appendChild(callCenters);
            var keysCallCenter = ["id", "highLightNonZero", "isIncreasing", "black", "green", "red", "yellow"];

            for (const key in objectList) {
                let valuesCallCenter = [];
                let callCenter = this.document.createElement("callCenter");
                callCenters.appendChild(callCenter);

                valuesCallCenter.push(key, objectList[key].highLightValues, objectList[key].isIncreasing,
                    objectList[key].black, objectList[key].green, objectList[key].red, objectList[key].yellow);

                for (let i = 0; i < keysCallCenter.length; i++) {
                    let callCenterChild = this.document.createElement(keysCallCenter[i]);
                    callCenterChild.appendChild(document.createTextNode(valuesCallCenter[i]));
                    callCenter.appendChild(callCenterChild);
                }

            }

        }

    }

    protected addElementToChild5(parentName: string, tagName: string, value: any[], childValue: any[], objectList?: object): void {
        let element = this.document.createElement(tagName);

        for (let i = 0; i < childValue.length; i++) {
            let element1 = this.document.createElement(value[i]);
            element1.appendChild(document.createTextNode(childValue[i]));
            element.appendChild(element1);
        }

        this.getElementByTagName(parentName).appendChild(element);

        if (objectList != undefined) {
            let acdStates = this.document.createElement("acdStates");
            element.appendChild(acdStates);
            var keysACDState= ["id", "highLightNonZero", "isIncreasing", "black", "green", "red", "yellow"];

            for (const key in objectList) {
                let valuesACDState = [];
                let acdState = this.document.createElement("acdState");
                acdStates.appendChild(acdState);

                valuesACDState.push(key, objectList[key].highLightValues, objectList[key].isIncreasing,
                    objectList[key].black, objectList[key].green, objectList[key].red, objectList[key].yellow);

                for (let i = 0; i < keysACDState.length; i++) {
                    let acdStateChild = this.document.createElement(keysACDState[i]);
                    acdStateChild.appendChild(document.createTextNode(valuesACDState[i]));
                    acdState.appendChild(acdStateChild);
                }

            }

        }

    }
    protected addElementToChildWithPatentNode(rootParentName: string, parentName: string, tagName: string, value: string): void {
        let element = this.document.createElement(tagName);
        element.appendChild(document.createTextNode(value));
        this.getElementByTagName1(rootParentName, parentName).appendChild(element);
    }


    protected setAttribute(attribName: string, value: string) {
        this.getRootElement().setAttribute(attribName, value);
    }
    protected setAttributeToChild(parentElementName: string, attribName: string, value: string) {
        this.getElementByTagName(parentElementName).setAttribute(attribName, value);
    }
    protected setAttributeToParticularChild(rootParentName: string, parentElementName: string, attribName: string, value: string) {
        this.getElementByTagName1(rootParentName, parentElementName).setAttribute(attribName, value);
    }
    public toString(): string {
        return (new XMLSerializer()).serializeToString(this.document);
    }

    //monitored users
    protected createXmlElement(elementName: string) {
        return this.document.createElement(elementName);
    }

    protected addHtmlElement(parentName: string, value: HTMLElement): void {
        // let element = this.document.createElement(tagName);
        // element.appendChild(value);
        this.getElementByTagName(parentName).appendChild(value);
    }
    protected addElementWithValue(tagName: string, value: string) {
        let element = this.createXmlElement(tagName);
        element.appendChild(document.createTextNode(value));
        return element;
    }

    protected buildUser(user) {
        let bwUser = this.createXmlElement("bwUser");
        bwUser.appendChild(this.addElementWithValue("departmentId", user.departmentId));
        bwUser.appendChild(this.addElementWithValue("loginId", user.loginId));
        bwUser.appendChild(this.addElementWithValue("name", user.name));
        bwUser.appendChild(this.addElementWithValue("type", user.type));
        bwUser.appendChild(this.addElementWithValue("groupId", user.groupId));
        return bwUser;
    }

    constructor(type: string) {
        this.document = document.implementation.createDocument(null, "webClientAction", null);
        this.setType(type);

    }

}

