<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" (click)="hotelingGuestService.changeHostID()" value="checkbox" label={{enabled}} [(ngModel)]="hotelingGuestService.isActive" binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{hostName}}</label>
  <p-dropdown [options]="this.contextService.hotelingListWithName" name="dropdown" (onChange)="onChange($event)" [(ngModel)]="hotelingGuestService.hostUserId" [style]="{'width':'95%'} "
    [disabled]="!hotelingGuestService.isActive" [required]=true #dropdown></p-dropdown>
    <div class="call-log-btns" style=float:right;margin-top:7px>
      <span *ngIf="hotelingGuestService.isActive===true" class="ref" (click)="hotelingGuestService.requestHostList()" title="{{toolTip}}"></span>
    </div>  
</div>
<div class="row" *ngIf="hotelingGuestService.hostUserId!=='none' && hotelingGuestService.isActive=== true">
  <p>
    {{association}}
  </p>
  <input [(ngModel)]="hotelingGuestService.associationLimitHours"  [maxLength]="2" type="text" id="associationNumber" name="mini-input"  class="input-mini">
  <label style="padding-left: 10px" class="label">{{hours}}</label>
  <!-- <label *ngIf="this.hotelingGuestService.showUnlimitedAssociationText ==false" style="padding-left: 10px" class="label">{{hours}} ({{hotelingGuestService.hostAssociationLimitHours}} {{maximum}})</label>
  <label *ngIf="this.hotelingGuestService.showUnlimitedAssociationText ==true"  style="padding-left: 10px"  class="label">{{this.unlimitedHours}}</label> -->
</div>