import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../base-component';
import { CallForwardNoAnswerService } from '../../../../../providers/settings/settings-services/call-forward-no-answer.service';
import { ContextService } from '../../../../../providers/context.service';


/**
 *   Used to store CallForwardNoAnswerComponent properties and this serves as a interface between component and calllogs service
 * 
 * @export
 * @class CallForwardNoAnswerComponent
 * @extends {BaseComponent}
 * @implements {OnInit}
 */
@Component({
  selector: 'app-call-forward-no-answer',
  templateUrl: './call-forward-no-answer.component.html',
  styleUrls: ['./call-forward-no-answer.component.scss']
})
export class CallForwardNoAnswerComponent extends BaseComponent implements OnInit {

  numberOfRings: String = "";
  forwardNumber: String = "";
  description: String = "";
  enabled: String = "";

  constructor(private contextService: ContextService, public callForwardNoAnswerService: CallForwardNoAnswerService) {
    super();
  }

  ngOnInit() {
    this.callForwardNoAnswerService.noAnswerChanges = true;
    this.enabled = this.contextService.getLanguageTagByKey('Settings.Services.Sca.Device.Enable');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallForwardNoAnswer.Description');
    this.forwardNumber = this.contextService.getLanguageTagByKey('Misc.PhoneNumber');
    this.numberOfRings = this.contextService.getLanguageTagByKey('Settings.Services.CallForwardNoAnswer.NumberOfRings');
  }

  //text field auto focus
  focus() {
    document.getElementById("textarea").focus();
  }


}