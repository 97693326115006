/**
 * enum for call type
 * 
 * @export
 * @enum {number}
 */
export enum CallType {
    MISSED, DIALED, RECEIVED
}


