/** 
 * C8 - model for note 
 */

import { Attachment } from './attachment.model';

export class NoteEntry {
  id: number;
  headline: string;
  info: string;
  text: string;
  dateTime: Date;
  person: number;
  ico: string;
  attachment: any;

  constructor(id, headline, info, text, deteTime, person, ico, attachment: Attachment = null) {
    this.id = id;
    this.headline = headline;
    this.info = info;
    this.text = text;
    this.dateTime = deteTime;
    this.ico = ico;
    this.person = person;
    this.attachment = attachment;
  }

}
