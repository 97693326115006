import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChatConversation } from "app/model/chat-conversation.model";
import { ChatService } from "app/providers/chat.service";
import { ContextService } from "app/providers/context.service";
import { ImService } from "app/providers/im.service";
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import PerfectScrollbar from 'perfect-scrollbar';
import { Subscription } from 'rxjs';
import { DirectoryView } from "../../model/directory/directory-view";


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None//,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
  conversations: Array<any> = [];
  activeConversation: number = 0;
  subscription: Subscription;
  conversationsCountClass: string;
  conversationsCount: number;
  private sTimeoutScroll: any;
  private sTimeoutScrollBottom: any;
  private sTimeoutScrollBottomConversations: any;
  directoryView: DirectoryView;
  tempId: string = "";

  newUser: string = "";
  offlineUser: string = "";

  scrollTextWindow = null;
  chatHeight: number = 0;
  
  //dragula bags
  chatBag;
  newChatBag;
  externalBag;

  constructor(private chatService: ChatService, public contextService: ContextService, private imService: ImService, private cd: ChangeDetectorRef) {
    this.contextService.activateId.subscribe(value => this.activate(value));
  }

  ngOnInit() {
    this.chatService.srcoll.subscribe(val => {
      this.scroll();
    });
    this.contextService.changeChatHeight.subscribe(val => {
      let totalHeight = window.innerHeight;
      this.chatHeight = (totalHeight - (val + this.contextService.mainMenuHeight) - 4);
    });
    this.newUser = this.contextService.getLanguageTagByKey('Conversation.NewChat');
    this.offlineUser = this.contextService.getLanguageTagByKey('Conversation.OfflineUser');

    this.chatService.droppedToChat.subscribe(person => {
      if (person.type === 'Broadworks Directory') {
        if (person instanceof DirectoryView) {
          this.directoryView = person;
          this.contextService.instantMessage(this.directoryView.userId);
        }
      }
    });

    this.scrollTextWindow = new PerfectScrollbar('#scrollChat', {
      wheelSpeed: 1,
      wheelPropagation: false,
      minScrollbarLength: 20,
      suppressScrollX: true
    });


  }
  activeId(id: string) {
    this.contextService.activateId.next(id);
  }
  removeConversation(conversation: ChatConversation) {
    let index: number = this.getConversationKeyById(conversation.id, this.contextService.conversations);
    if (index > -1) {
      this.contextService.conversations.splice(index, 1);
      this.sTimeoutScroll = setTimeout(() => this.updateScrollbar(), 100);
    }
  }

  private scrollToBottom(id) {
    if (window.outerWidth > 837) {
      setTimeout(() => {
        this.contextService.chatFocus.next(id);
      }, 100);
    }
  }
  private scrollConversationsToBottom() {
    this.chatService.scrollConversationsToBottom.next(true);
  }

  private updateScrollbar() {
    var element = document.getElementById("scrollChat");
    element.scrollTop = 0;
  }

  private getConversationKeyById(id: number, a) {
    let ret = -1;
    let i = 0;
    for (let conv of a) {
      if (conv.id == id) {
        ret = i;
      }
      i++;
    }
    return ret;
  }

  private getConversationById(id: number) {
    let ret = false;
    for (let conv of this.contextService.conversations) {
      if (conv.id == id) {
        return conv;
      }
    }
    return ret;
  }

  ngOnDestroy() {
    //Called once, before the instance is destroyed. Add 'implements OnDestroy' to the class.
    // this.subscription.unsubscribe();
  }

  stripHtml(html) {
    // Create a new div element
    let temporalDivElement = document.createElement("div");
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  scroll() {
    const viewChatDiv = document.getElementById('scrollChat');
    viewChatDiv.scrollTop = viewChatDiv.scrollHeight;
    this.scrollTextWindow.update(viewChatDiv);
  }
  activate(id: number) {
    if (this.activeConversation == id) {
      if (this.contextService.messages.length > 0) {
        var lastMessage = this.contextService.messages[this.contextService.messages.length - 1];
        const update = { 'conversationId': id, update: { "message": this.stripHtml(lastMessage.text) } };
        this.contextService.conversationHeaderChange.next(update);
      }
      this.activeConversation = 0;
      this.contextService.active = "";
      this.chatService.unFocus.next(id);

    } else {
      let tempMessage = [];
      if (this.activeConversation !== 0) {
        if (this.contextService.messageArray.length > 0) {
          this.contextService.messageArray.forEach(element => {
            if (element.id === this.activeConversation) {
              tempMessage.push(element);
            }
          });
          lastMessage = tempMessage[tempMessage.length - 1];
          const update = { 'conversationId': this.activeConversation, update: { "message": this.stripHtml(lastMessage.text) } };
          this.contextService.conversationHeaderChange.next(update);

        }
      }
      const update = { 'conversationId': id, update: { "message": "" } };
      this.contextService.conversationHeaderChange.next(update);

      this.activeConversation = id;
      this.contextService.active = id.toString();
    }
    // this.cd.detectChanges();

    const viewChatDiv = document.getElementById('scrollChat');
    viewChatDiv.scrollTop = viewChatDiv.scrollHeight;
    this.scrollTextWindow.update(viewChatDiv);
    // setTimeout(() => {
    //   var elementScroll = document.getElementById("scrollChat");
    //   if (elementScroll.scrollHeight != null) {
    //     elementScroll.scrollTop = 0;
    //   //  setTimeout(() => {
    //   //   elementScroll.scrollTop = 9999999;
    //   //  }, 100);
    //   }
    // }, 100);

    this.sTimeoutScrollBottom = setTimeout(() => this.scrollToBottom(id), 100);//here id is passing to get focus on text area
    this.sTimeoutScrollBottomConversations = setTimeout(() => this.scrollConversationsToBottom(), 100);
    this.contextService.chatService.scrollToBottom.next(id);

  }

  isActive(id: number) {
    return this.activeConversation === id;
  }

  toggleChat() {
    this.chatService.toggleChat.next(true);
  }


}
