import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../../providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  description: string = "";
  broadworksLoginDetails: string = "";
  loginID: string = "";
  password: string = "";
  rememberPassword: string = "";
  rememberLoginID: string = "";
  constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {

    this.description = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Description');
    this.broadworksLoginDetails = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.BroadworksLoginDetails');
    this.loginID = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.LoginID');
    this.password = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Password');
    this.rememberPassword = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.RememberPassword');
    this.rememberLoginID = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Connection.Authentication.RememberLoginID');
    this.settingsGeneral.settingsChanged=true;
    if ((this.settingsGeneral.contextService.objLoggedUserDetails !== undefined) && (this.settingsGeneral.loginID === "")) {
      this.settingsGeneral.loginID = this.settingsGeneral.contextService.objLoggedUserDetails.getUserId();

    }
  }
  dontAllowSpace(event) {
    return (event.charCode == 32) ? false : true;
  }
}
