<p-dialog [(visible)]="manageUsersVisible" [responsive]="true" breakpoint="9000" [closable]="false"
  class="settings-dialog" positionLeft="0" positionTop="0" [draggable]="false" [closeOnEscape]="false">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{lblManageUsers}}</h4>
      <span class="close-modal" (click)="closeSettings()">X</span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="container-center">
      <div class="pick-list-wrapper" [ngClass]="{'removeHierarchy': !hierarchy}">
        <div class="pick-list-row clearfix">
          <div class="pl-left-part">
            <div class="pl-border">
              <form (ngSubmit)="searchUsersAction()">
                <fieldset>
                  <input type="text" placeholder="search" name="text" [(ngModel)]="searchString.text" #name="ngModel"
                    #input (input)="searchUsersAction()">
                  <button type="submit"></button>
                </fieldset>
              </form>
              <div class="tree-view">
                <perfect-scrollbar class="container">
                <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
                  <p-tree [value]="manageUserTreeData" selectionMode="checkbox" [(selection)]="selectedUsers"></p-tree>
                <!-- </p-scrollPanel> -->
                </perfect-scrollbar>
              </div>
            </div>
            <p-checkbox label="Show hierarchy" (onChange)="hierarchyChange($event)" [(ngModel)]="hierarchy"
              binary="true"></p-checkbox>
          </div>
          <div class="pl-actions">
            <a class="add" (click)="adduserForMonitor()" [ngClass]="{'active': selectedUsers.length}"></a>
            <a class="remove" (click)="removeUsersFromMonitor()" [ngClass]="{'active': deselectedUsers.length}"></a>
          </div>
          <div class="pl-right-part">
            <div class="pl-border">
              <div class="tree-view">
                <perfect-scrollbar class="container">
                <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
                  <p-tree [value]="usersPicked" selectionMode="checkbox" [(selection)]="deselectedUsers"></p-tree>
                <!-- </p-scrollPanel> -->
                </perfect-scrollbar>
              </div>
            </div>
            <div [ngClass]="remaining_className">{{maxSelectedDisplay}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div class="save-settings">
        <a (click)="closeSettings()">{{btnCancel}}</a>
        <button type="submit" form="setFormManage" class="btn-blue-light" [disabled]="saveButtonState"
          (click)="saveUserList()" (dblclick)="onRowDblclick($event)">{{contextSave}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<p-dialog [resizable]="false" [(visible)]="monitorUserVis" [closable]="false" [style]="{'width':'370px'}"
  class="dialog-window buttons-1" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{monitoredListmin}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="monitorUserVis = false">{{btnOK}}</button>
    </div>
  </div>
</p-dialog>