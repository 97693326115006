import { Injectable } from '@angular/core';
import { ContextService } from '../providers/context.service';
import { DirectoryService } from '../providers/directory.service';
import { StatusList } from "../model/im/im-status-list";
import { WebsocketService } from "../providers/websocket.service";
@Injectable()
export class ImService {
    message: string = "";
    messageID: string = "";
    extension: string = "";
    extensionArray: string[] = [];
    statusList: StatusList

    constructor(public contextService: ContextService, private directoryService: DirectoryService) {
        this.contextService.log('###### constructor - ImService');
        this.contextService.imEvent.subscribe((val) => {
            this.statusList = val;
            if (this.statusList.imStatus === "") {
                this.contextService.showIM.next("clearAdHocStatus");
            }
            this.contextService.showIM.next("checkIM");
        });
    }
    sendMessage(id) {
          }
}