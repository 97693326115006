import { Observable, Subject, Subscription, timer } from 'rxjs';
import * as Rx from 'rxjs/Rx';
import { RegisterResponseHandler } from "../broadworks/startUp/register-response-handler";
import { ResponseRouter } from '../shared/ResponseRouter';
import { KeepAliveAction } from '../shared/web-client-commands/channel/keep-alive-action';
import { ContextService } from './context.service';

const CHAT_URL = 'wss:///localhost/websocket';

export class WebsocketService extends ResponseRouter {
  ws: WebSocket;
  loadAuthentication: Subject<boolean> = new Subject();
  connectionDroped: Subject<any> = new Subject();
  IMconnectionDroped: Subject<boolean> = new Subject();

  private subject: Rx.Subject<MessageEvent>;
  public messages: Subject<string> = new Subject();
  requestNumber: number = 1;
  registerResponseHandler: RegisterResponseHandler;

  sub: Subscription = new Subscription();
  isSubscription: boolean = false;
  ctiMessagesQueueList = [];

  sub1: Subscription = new Subscription();
  isSubscription1: boolean = false;

  constructor(private contextService: ContextService) {
    super();

    this.contextService.log('constructor of WebsocketService');
    this.createWebsocketConnection();
  }


  public createWebsocketConnection() {
    // this.subject = null;
    // this.messages = <Subject<string>>

    //   this.connect().pipe(
    //     map((response: MessageEvent): string => {
    //       return response.data;
    //     }));
    // this.messages.subscribe(msg => {
    //   this.onReceive(msg);
    // });

    this.contextService.loginComponentLoad.subscribe(() => {
      if (this.contextService.isFirstTimeLogin === true) {
        if (this.contextService.isNetworkError === true) {
          this.loadAuthentication.next(false);
        } else {
          this.loadAuthentication.next(true);
        }
      }
    });
    // this.ws.onclose = e => {
    // }
    // this.ws.onerror = e => {
    //   this.contextService.log("now error" + new Date() + "-" + new Date().getMilliseconds() + " error-------");
    //   this.contextService.log(e);
    // }
  }
  deleteWebsocketConnection() {
    // this.ws.close();
  }
  getWebsocketConnectStatus(): boolean {
    if (this.ws.readyState === WebSocket.OPEN) {
      return true;
    } else {
      return false;
    }
  }
  public connect(): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.contextService.log("Going to connect...." + new Date() + "-" + new Date().getMilliseconds());
      this.subject = this.create(CHAT_URL);
      this.contextService.log("Successfully connected: " + CHAT_URL);
    }
    return this.subject;
  }

  reconnectionTimerStarts() {
    let time = timer(15000, 15000);
    if (!this.isSubscription1) {

      this.isSubscription1 = true;
      this.sub1 = time.subscribe(
        t => {
          this.getOnlineStatus();
        }
      );
    }
  }
  reconnectionTimerStop() {
    if (!this.sub1.closed) {
      this.isSubscription1 = false;
      this.sub1.unsubscribe();
      console.log("Timer deleted...." + new Date() + "-" + new Date().getMilliseconds());
    }
  }

  async getOnlineStatus() {
    this.contextService.log("Checking internet connection" + new Date() + "-" + new Date().getMilliseconds());
    this.reconnectionTimerStop();
    const result = await this.checkOnlineStatus();
    
      //
  
  }
  checkOnlineStatus = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append('pragma', 'no-cache');
      myHeaders.append('cache-control', 'no-cache');
      myHeaders.append('cache-control', 'no-store');


      var myInit = {
        method: 'GET',
        headers: myHeaders,
      };
      this.contextService.log("performing http request to server " + new Date() + "-" + new Date().getMilliseconds());
      const online = await fetch("/assets/img/unity1.ico", myInit);
      this.contextService.log("Online status: " + online.status);

      return online.status >= 200 && online.status < 300; // either true or false
    } catch (err) {
      this.contextService.log(err);
      return false; // definitely offline
    }
  };

  private create(url): Rx.Subject<MessageEvent> {
    this.ws = new WebSocket(url);
    this.ws.onopen = e => {
      this.contextService.log("now open" + new Date() + "-" + new Date().getMilliseconds());
      // this.startUp("");
    }


    let observable = Observable.create(
      (obs: Rx.Observer<MessageEvent>) => {

        this.ws.onmessage = obs.next.bind(obs);
        this.ws.onerror = obs.error.bind(obs);
        this.ws.onclose = obs.complete.bind(obs);
        return this.ws.close.bind(this.ws);
      })
    let observer = {
      next: (data: any) => {
        this.ws.send(data);

      }
    }
    return Subject.create(observer, observable);
  }



  /**
   * 
   * @param response 
   */
  onReceive(response: string) {

  }
  send(message: string, shouldLog = true) {

  }

  blindTransferAction(callId: string, number: string) {}
  dialAction(number: string, conferenceId?: string) { }
  holdAction(callId: string) {}
  releaseAction(callId: string) {}
  setAgentStateAction(agentACDState: string, agentUnavailableCode?: string, targetId?: string) { }
  commPilotExpress(profile: string) {}
  joinLeaveQueue(callCenterID: string, available: boolean, targetId?: string) {}
  answerQueuedCallAction(phoneno: string, callId: string, callcenterId: string) {}



  iMAddScheduledStatusAction(status: string, id: string, start: string, end: string) {

  }

  iIMClearStatusAction() {
 
  }

  iMContactListAction(loginId: string, product: string, action: string) {
  
  }

  iMErrorAction(number: string, description: string) {
 
  }

  iMChannelCloseAction() {
  
  }

  iMListScheduledStatusesAction(start: string, end: string) {
   
  }

  iMMessageAction(id: string, dateTime: string, extensions: string, sender: string, message: string) {
   
  }

  iMRegisterAction(group: string, imHostName: string, imPort: string) {
   

  }

  iMScheduledStatusListAction(scheduledStatusList: string) {
  
  }

  iMSetStatusAction(status: string) {
  
  }

  iMStatusListAction(loginId: string, status: string, time: string) {
  
  }

  iMRemoveScheduledStatusAction(id: string) {
  
  }

  keepAliveAction() {
    const keepAliveAction: KeepAliveAction = new KeepAliveAction();
    this.send(keepAliveAction.toString());
  }


  /** 
* below functions are used to communicate with electron app using inter process communication
* 
* 
*/
  setNotification(isIncoming: boolean, user: string, ImMessage: string) {
    if (this.contextService.objSettings.callNotification_PopUpSummary === true) {
      let myNotification;
      let title;
      if (ImMessage === "") {
        if (user === '') {
          user = this.contextService.getLanguageTagByKey('Misc.Unavailable');
        }
        if (isIncoming === true) {
          title = this.contextService.getLanguageTagByKey('Settings.Services.IncomingCalls');
          myNotification = new Notification(title, {
            body: this.contextService.getLanguageTagByKey('Misc.From') + ': ' + user, icon: './assets/img/unity.ico',
          })
        } else if (isIncoming === false) {
          title = this.contextService.getLanguageTagByKey('Settings.Services.OutgoingCalls');
          myNotification = new Notification(title, {
            body: this.contextService.getLanguageTagByKey('Misc.To') + ': ' + user, icon: './assets/img/unity.ico',
          })
        }
      } else {
        myNotification = new Notification(user, {
          body: ImMessage, icon: './assets/img/unity.ico'
        })
      }

      if (myNotification) {
        myNotification.onclick = (event) => {
          this.contextService.log('Notification clicked');
          this.contextService.log(event);

          if (this.contextService.isElectron === true) {
            this.contextService.electronService.ipcRenderer.send('call-notification', 'data');
          } else {
            window.focus();
            myNotification.close();
          }
        }
      }
      // if (ImMessage === "") {
      setTimeout(myNotification.close.bind(myNotification), parseInt(this.contextService.objSettings.callNotification_PopUpSummaryDuration + '000'));
      // } else {
      //   setTimeout(myNotification.close.bind(myNotification), parseInt('2000'));
      // }
    }
  }






  //agentP3
  settings(settingsObj) {

  }

}




