<div class="row">
  <p>
    {{dialRuleDescription}}
    <p>
</div>
<div class="row">
  <label for="" class="label">{{dialRuleBeforeDialing}}</label>
  <input type="text" name="input-text" [(ngModel)]="settingsGeneral.dialingRule_toReplace">
</div>

<div class="row">
  <label for="" class="label">{{WithTheseCharacters}}</label>
  <input type="text" name="input-text" [(ngModel)]="settingsGeneral.dialingRule_toReplaceWith">
</div>

<div>
  <p-radioButton class="row" name="forIncomingCalls" value="0" label="{{Ignore}}" [(ngModel)]="settingsGeneral.dialingRule_forIncomingCalls"
    styleClass="big-radio"></p-radioButton>
  <p-radioButton class="row" name="forIncomingCalls" value="1" label="{{use}}" [(ngModel)]="settingsGeneral.dialingRule_forIncomingCalls"
    styleClass="big-radio"></p-radioButton>
  <p-radioButton class="row" name="forIncomingCalls" value="2" label="{{reverse}}" [(ngModel)]="settingsGeneral.dialingRule_forIncomingCalls"
    styleClass="big-radio"></p-radioButton>
</div>
