import { ContextService } from './../../../../../../providers/context.service';
import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from "../../../../../../providers/settings/settings-general/settings-general.service";

@Component({
  selector: 'app-supervisor',
  templateUrl: './supervisor.component.html',
  styleUrls: ['./supervisor.component.scss']
})
export class SupervisorComponent implements OnInit {
  description: string;
  general: string;
  calls: string;
  onlyIncludeJoinedAgentsLabel: string
  agentActivityImageBasedLabel: string;
  queuedCallsInActivityList: string;
  staffedRatioLabel: string;
  addAllAgentsLabel: string;
  activityIcon = [];
  callPriority: string = "";
  constructor(public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.staffedRatioLabel = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.MyAgentsOnlyInStaffedRatio');
    this.addAllAgentsLabel = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.AutomaticallyAddAllAgents');
    this.queuedCallsInActivityList = this.contextService.getLanguageTagByKey('Settings.Reception.CurrentCalls.ShowQueuedCallsInCallList');
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Agent.Description');
    this.general = this.contextService.getLanguageTagByKey('Misc.General');
    this.calls = this.contextService.getLanguageTagByKey('Misc.Calls');
    this.onlyIncludeJoinedAgentsLabel = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Supervisor.JoinedAgentsOnlyInAgentActivityList');
    this.agentActivityImageBasedLabel = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.AgentActivityImageBasedOn');
    this.activityIcon = [];
    this.activityIcon.push({ label: this.contextService.getLanguageTagByKey('Settings.AgentActivityImageOption.JoinedState'), value: "joined" });
    this.activityIcon.push({ label: this.contextService.getLanguageTagByKey('Settings.AgentActivityImageOption.AcdState'), value: "acdState" });
    this.activityIcon.push({ label: this.contextService.getLanguageTagByKey('Settings.AgentActivityImageOption.HookState'), value: "hookState" });
    this.activityIcon.push({ label: this.contextService.getLanguageTagByKey('Settings.AgentActivityImageOption.AcdAndHookState'), value: "acdAndHooKState" });
    this.activityIcon.push({ label: this.contextService.getLanguageTagByKey('Settings.AgentActivityImageOption.AvailableToTakeCalls'), value: "availabilityToTakeCalls" });
    this.settingsGeneral.settingsChanged = true;
    this.callPriority = this.contextService.getLanguageTagByKey('Settings.Reception.CurrentCalls.ShowQueuedCallPriority');
  }
  queuedCallClick() {
    if (!this.settingsGeneral.showQueuedCalls) {
      this.settingsGeneral.callPriority = false;
    }

  }
}
