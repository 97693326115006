<p-dialog [(visible)]="outOfOfficeModalVisible" [ngClass]="{visible: outOfOfficeModalVisible}"  [closable]="false" [style]="{'width':'480px'}" class="small-dialog out-of-office-dialog" dismissableMask="true" showHeader="true" [resizable]="false">
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{outOfOfficeAssistant}}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
    </div>
  </p-header>
  <div class="modal-content">
    <form id=setForm (ngSubmit)="onSubmit()" [formGroup]="oooForm" #rfRef="ngForm" id="oooForm">
      <div class="row">
        <p-tabView [activeIndex]="tabIndex" (onChange)="tabChange($event)">
          <p-tabPanel header="{{currentPresence}}">
            <div class="row">
              <label for="" class="label">{{outOfOfficeAssistantIAm}}</label>
              <p-dropdown [options]="oooOpts" formControlName="selectedReason" [style]="{'width':'100%'}" [(ngModel)]="currentStatus" datakey="value.id"></p-dropdown>
            </div>
            <div class="row">
              <label class="label">{{outOfOfficeAssistantStart}}</label>
              <div class="cols-2">
                <div class="col">
                  <div>
                    <p-calendar readonlyInput="true" hourFormat="24" formControlName="startDate" [minDate]="curDate" [(ngModel)]="startDate"
                      (onSelect)="startHourChange()" dateFormat="D - dd. M" appendTo="body" [showIcon]="true" [locale]="lang"></p-calendar>
                  </div>
                </div>
                <div class="col">
                  <div>
                    <p-dropdown [options]="startHourOpts" appendTo="body" formControlName="startHour" [(ngModel)]="startHour" (onChange)="startMinChange()"
                      [style]="{'width':'47%'}"></p-dropdown>
                    <span>:</span>
                    <p-dropdown [options]="startMinOpts" appendTo="body" formControlName="startMin" [(ngModel)]="startMins" (onChange)="endHourChange()"
                      [style]="{'width':'47%'}"></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <label class="label">{{outOfOfficeAssistantEnd}}</label>
              <div class="cols-2">
                <div class="col">
                  <div>
                    <p-calendar readonlyInput="true" hourFormat="24" formControlName="endDate" [minDate]="endCurDate" [(ngModel)]="endDate" (onSelect)="endHourChange()"
                      dateFormat="D - dd. M" appendTo="body" [showIcon]="true" [locale]="lang"></p-calendar>
                  </div>
                </div>
                <div class="col">
                  <div>
                    <p-dropdown [options]="endHourOpts" appendTo="body" formControlName="endHour" [(ngModel)]="endHour" (onChange)="endMinChange()"
                      [style]="{'width':'47%'}"></p-dropdown>
                    <span>:</span>
                    <p-dropdown [options]="endMinOpts" appendTo="body" formControlName="endMin" [(ngModel)]="endMins" [style]="{'width':'47%'}"></p-dropdown>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="{{scheduledPresence}}">
            <div class="row">
              <!-- <p-dataTable [value]="contextService.scheduledStatusListCollection" selectionMode="single" scrollable="true" [(selection)]="selectedStatus"
                [contextMenu]="cm">
                <p-header></p-header>
                <p-column field="sFDate" header="{{outOfOfficeAssistantStart}}"></p-column>
                <p-column field="eFDate" header="{{outOfOfficeAssistantEnd}}"></p-column>
                <p-column field="imStatus" header="{{imstatus}}"></p-column>
              </p-dataTable> -->
              <p-table [value]="contextService.scheduledStatusListCollection" selectionMode="single" scrollable="true"
              [(contextMenuSelection)]="selectedStatus" [(selection)]="selectedStatus" [contextMenu]="cm">
                <ng-template pTemplate="header">

                  <tr>
                    <th style="background-color: inherit">{{outOfOfficeAssistantStart}}
                    </th>
                    <th style="background-color: inherit">{{outOfOfficeAssistantEnd}}
                    </th>
                    <th style="background-color: inherit">{{imstatus}}
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                  <tr [pSelectableRow]="rowData" [pContextMenuRow]="rowData">
                    <td>

                      {{rowData.sFDate}}</td>
                    <td>

                      {{rowData.eFDate}}</td>
                    <td>

                      {{rowData.imStatus}}</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-rowData>
                  <tr>
                      <td>
                          No records found
                      </td>
                  </tr>
              </ng-template>
              </p-table>
              <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </form>
  </div>
  <p-footer>
    <div class="container-center ui-helper-clearfix">
      <div class="save-settings" *ngIf="isValid">
        <button type="button" form="setForm" (click)="onSubmit()" class="btn-blue-light">{{callButtonShowingOK}}</button>
        <a (click)="closeModal()">{{callButtonShowingCancel}}</a>
      </div>
    </div>
  </p-footer>

</p-dialog>


<p-dialog [resizable]="false" [(visible)]="statusAlertVisible" [closable]="false" [style]="{'width':'350px'}" class="dialog-window buttons-1" dismissableMask="true" showHeader="true" modal="true" [resizable]="false">
  <div class="modal-content" style="text-align: center">
    <p>{{alertMessage}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="statusAlertVisible = false">{{callButtonShowingOK}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [(visible)]="statusConfirmationVisible" [closable]="false" [style]="{'width':'470px'}" class="dialog-window buttons-2" showHeader="false" dismissableMask="true" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{outOfOfficeAssistantClash}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="overwriteSS()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="statusConfirmationVisible = false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog [resizable]="false" [(visible)]="deleteConfirmationVisible" [closable]="false" [style]="{'width':'510px'}" class="dialog-window buttons-2" showHeader="false" dismissableMask="true" modal="true" [resizable]="false">
  <div class="modal-content">
    <p>{{Prompt}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="deleteSS()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="deleteConfirmationVisible = false">{{miscNo }}</button>
    </div>
  </div>
</p-dialog>