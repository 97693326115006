import { Component, OnInit } from '@angular/core';
import { ContextService } from 'app/providers/context.service';
import { SettingsGeneral } from 'app/providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-agent-activity-threshold',
  templateUrl: './agent-activity-threshold.component.html',
  styleUrls: ['./agent-activity-threshold.component.scss']
})
export class AgentActivityThresholdComponent implements OnInit {

  setThreshold: string = "";
  increasingThreshold: string = "";
  highlights: string = "";
  description: string = "";
  statistic: string = "";
  heading: string = "";
  green: string = "";
  black: string = "";
  red: string = "";
  yellow: string = "";
  callsNameList = [];
  secondsNameList = [];
  noThresholdList = [];
  previousSelectedStat: string = "";
  acdStateText:string="";
  constructor(public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.acdStateText=this.contextService.getLanguageTagByKey('List.Statistics.Column.AcdState');
    this.setThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.SetThresholdValue');
    this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds');
    this.highlights = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.HighlightNonZeroValues');

    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallCenter.Statistics.Columns.Description');
    this.statistic = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Statistic');
    this.heading = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.Heading');

    this.green = this.contextService.getLanguageTagByKey('Color.Green');
    this.black = this.contextService.getLanguageTagByKey('Color.Black');
    this.red = this.contextService.getLanguageTagByKey('Color.Red');
    this.yellow = this.contextService.getLanguageTagByKey('Color.Yellow');
    this.settingsGeneral.selectQueueTab = 2;
    this.callsNameList.push("agentTotalCalls");
    this.callsNameList.push("agentAnsweredCalls");
    this.callsNameList.push("agentMissedCalls");
    this.callsNameList.push("agentAverageTalkTime");

    this.secondsNameList.push("agentCurrentCallDuration");
    this.secondsNameList.push("agentTotalTalkTime");
    this.secondsNameList.push("agentTotalStaffedTime");


    this.changeText();
    this.showThreshold();
  }

  showThreshold() {
    this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds');
    if (!this.contextService.agentActivityStatisticsToDisplay) {
      this.contextService.agentActivityStatisticsToDisplay = this.contextService.agentActivityColumnList[0].value;
    }
    if (this.previousSelectedStat !== this.contextService.agentActivityStatisticsToDisplay) {
      this.previousSelectedStat = this.contextService.agentActivityStatisticsToDisplay;

      if (this.settingsGeneral.currentAgentActivityPage !== "agentActivityDoubleClick") {
        this.settingsGeneral.showElements = false;
        this.contextService.setThreshold = false;
      }
      if (this.contextService.setThreshold && this.contextService.agentActivityStatisticsToDisplay !== "agentACDDuration") {
        this.settingsGeneral.showElements = true;
      } else if (this.contextService.setThreshold && this.contextService.agentActivityStatisticsToDisplay === "agentACDDuration") {
        this.settingsGeneral.showElements = false;
        this.settingsGeneral.showACDStateTable = true;
      }
      else {
        this.settingsGeneral.showElements = false;
        this.settingsGeneral.showACDStateTable = false;
      }
      if (this.settingsGeneral.noThresholdList.includes(this.contextService.agentActivityStatisticsToDisplay)) {
        this.settingsGeneral.isThresholdShows = false;
        this.settingsGeneral.showACDStateTable = false;
        // this.showACDStateTable = false;
      }
      // else {
      //   this.isThresholdShows = true;
      // }
      else if (this.secondsNameList.includes(this.contextService.agentActivityStatisticsToDisplay)) {
        this.settingsGeneral.isThresholdShows = true;
        this.settingsGeneral.showACDStateTable = false;
        this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";

      } else if (this.callsNameList.includes(this.contextService.agentActivityStatisticsToDisplay)) {
        this.settingsGeneral.isThresholdShows = true;
        this.settingsGeneral.showACDStateTable = false;
        this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Menu.Context.Call')).toLocaleLowerCase() + ")";
      } else if (this.contextService.agentActivityStatisticsToDisplay === "agentReasonDuration") {
        this.settingsGeneral.isThresholdShows = true;
        this.settingsGeneral.showACDStateTable = false;
      }
      else if (this.contextService.agentActivityStatisticsToDisplay === "agentACDDuration") {
        this.settingsGeneral.isThresholdShows = true;
      }
      //else {
      //   console.log("not in list");

      // }
    }
  }
  changeText() {
    if (this.callsNameList.includes(this.settingsGeneral.selectedAgentColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Menu.Context.Call')).toLocaleLowerCase() + ")";
    } else if (this.secondsNameList.includes(this.settingsGeneral.selectedAgentColumn.value)) {
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";
    }
  }
  dbACDStateTable(data) {
    this.contextService.log("dbACDStateTable clicked");
    this.contextService.log(data);

    //change set threshold text
    this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";

    this.settingsGeneral.selectedACDStateRow = data;
    if (this.settingsGeneral.selectedACDStateRow) {
      this.settingsGeneral.showElements = true;
      this.contextService.log(this.settingsGeneral.selectedAgentActivityColumn);
      this.increasingThreshold = this.contextService.getLanguageTagByKey('Settings.Wallboard.Columns.IncreasingThresholds') + " (" + (this.contextService.getLanguageTagByKey('Misc.Seconds')).toLocaleLowerCase() + ")";

      this.settingsGeneral.loadAgentActivityThresholdValues(this.settingsGeneral.selectedACDStateRow.value);
      this.settingsGeneral.currentAgentActivityPage = "2";
    }
  }

  showThresholdTable() {
    this.contextService.log("showThresholdTable clicked");
    this.settingsGeneral.currentAgentActivityPage = "1";
    if (this.contextService.agentActivityStatisticsToDisplay === "agentACDDuration") {
      this.settingsGeneral.showACDStateTable = true;
      this.settingsGeneral.showElements = false;
      this.contextService.agentActivityThresholdList = [];
      this.contextService.agentActivityStatisticsList.forEach(element => {
        if ("agentACDDuration" == element.statName) {
          if (!element.setThreshold) {
            element.unavailableCodeList["Sign-In"].green = "";
            element.unavailableCodeList["Sign-In"].yellow = "";
            element.unavailableCodeList["Sign-In"].red = "";
            element.unavailableCodeList["Sign-In"].black = "";

            element.unavailableCodeList["Sign-Out"].green = "";
            element.unavailableCodeList["Sign-Out"].yellow = "";
            element.unavailableCodeList["Sign-Out"].red = "";
            element.unavailableCodeList["Sign-Out"].black = "";

            element.unavailableCodeList["Available"].green = "";
            element.unavailableCodeList["Available"].yellow = "";
            element.unavailableCodeList["Available"].red = "";
            element.unavailableCodeList["Available"].black = "";

            element.unavailableCodeList["Wrap-Up"].green = "";
            element.unavailableCodeList["Wrap-Up"].yellow = "";
            element.unavailableCodeList["Wrap-Up"].red = "";
            element.unavailableCodeList["Wrap-Up"].black = "";

            element.unavailableCodeList["all"].green = "";
            element.unavailableCodeList["all"].yellow = "";
            element.unavailableCodeList["all"].red = "";
            element.unavailableCodeList["all"].black = "";

            element.unavailableCodeList["Unavailable"].green = "";
            element.unavailableCodeList["Unavailable"].yellow = "";
            element.unavailableCodeList["Unavailable"].red = "";
            element.unavailableCodeList["Unavailable"].black = "";
          }
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "All", value: "all", green: element.unavailableCodeList["all"].green, yellow: element.unavailableCodeList["all"].yellow, red: element.unavailableCodeList["all"].red, black: element.unavailableCodeList["all"].black });
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "Sign-In", value: "Sign-In", green: element.unavailableCodeList["Sign-In"].green, yellow: element.unavailableCodeList["Sign-In"].yellow, red: element.unavailableCodeList["Sign-In"].red, black: element.unavailableCodeList["Sign-In"].black });
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "Available", value: "Available", green: element.unavailableCodeList["Available"].green, yellow: element.unavailableCodeList["Available"].yellow, red: element.unavailableCodeList["Available"].red, black: element.unavailableCodeList["Available"].black });
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "Wrap-Up", value: "Wrap-Up", green: element.unavailableCodeList["Wrap-Up"].green, yellow: element.unavailableCodeList["Wrap-Up"].yellow, red: element.unavailableCodeList["Wrap-Up"].red, black: element.unavailableCodeList["Wrap-Up"].black });
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "Unavailable", value: "Unavailable", green: element.unavailableCodeList["Unavailable"].green, yellow: element.unavailableCodeList["Unavailable"].yellow, red: element.unavailableCodeList["Unavailable"].red, black: element.unavailableCodeList["Unavailable"].black });

          for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
            let element1 = this.contextService.unAvailableCodesArray[i];
            if (!element.setThreshold) {
              element.unavailableCodeList["Unavailable - "+element1.Description].green = "";
              element.unavailableCodeList["Unavailable - "+element1.Description].yellow = "";
              element.unavailableCodeList["Unavailable - "+element1.Description].red = "";
              element.unavailableCodeList["Unavailable - "+element1.Description].black = "";

            }
            this.contextService.agentActivityThresholdList.push({ id: 1, name: "Unavailable (" + element1.Description + ")", value: "Unavailable - "+element1.Description, green: element.unavailableCodeList["Unavailable - "+element1.Description].green, yellow: element.unavailableCodeList["Unavailable - "+element1.Description].yellow, red: element.unavailableCodeList["Unavailable - "+element1.Description].red, black: element.unavailableCodeList["Unavailable - "+element1.Description].black });
          }
          this.contextService.agentActivityThresholdList.push({ id: 1, name: "Sign-Out", value: "Sign-Out", green: element.unavailableCodeList["Sign-Out"].green, yellow: element.unavailableCodeList["Sign-Out"].yellow, red: element.unavailableCodeList["Sign-Out"].red, black: element.unavailableCodeList["Sign-Out"].black });

        }

      });

    } else {
      this.settingsGeneral.showElements = true;
      this.settingsGeneral.showACDStateTable = false;
    }
    let temp = [...this.contextService.agentActivityThresholdList];
    this.contextService.agentActivityThresholdList = temp;

    let id_value: number = 1;
    this.contextService.agentActivityThresholdList.forEach(element => {
      element.id = id_value;
      id_value++;

    });

  }

}
