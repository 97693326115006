import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';

import { PersonalDirectory } from '../model/directory/personal-directory';
import { CommonPhoneList } from '../model/directory/common-phone-list';
import { DirectoryEntry } from '../model/directory/directory-entry';
import { BwDirectoryEntry } from '../model/directory/bw-directory-entry';
import { CallLogEntry } from '../model/call-logs/call-log-entry';
import { LoggedUserDetails } from '../model/loggedUserDetails';
import { Languages } from '../model/languages';
import { MonitoredUser } from '../model/directory/monitored-user';
import { CallStatusEntry } from '../model/call-status/call-status-entry';
import { Settings } from "../model/settings-models/settings";

import { ScheduledStatusList } from "../model/im/scheduled-status-list";
import { ElectronService } from "./electron.service";
import { ChatConversation } from '../model/chat-conversation.model';
import { Subject, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { Title } from '@angular/platform-browser';
import { timer } from 'rxjs';
import { ISubscription } from "rxjs/Subscription";
import { VoiceMailEntry } from '../model/voice-mail/voice-mail-entry';
import { ChatService } from "../providers/chat.service";

import { UnAvailableCode } from '../model/agent/unavailableCode';
import { LoggedAgentDetails } from "../model/agent/loggedAgentDetails";
import { AssignedDispositionCodes } from '../model/agent/assignedDispositionCodes';

import { StatToDisplay } from "../model/agent/statistics/statDisplayModel";

//agentp3
import { QueueStatistics } from "../model/agent/statistics/queueStatistics";
import { AgentStatistics } from "../model/agent/statistics/agentStatistics";
import { Threshold } from 'app/model/agent/statistics/threshold';

//agentp3


import { UserMeetMeConferencing } from "../model/bw-services/user-meet-me-conference";
import { UserMeetMeConferencingBridge } from "../model/bw-services/user-meet-me-conference-bridge";
import { FeatureAccessCode } from 'app/model/feature-access-code.model';
import { UserCallPickUp } from 'app/model/user-call-pick-up-list.model';

// import { ConnectionService } from 'ng-connection-service';
import { AgentActivityStatistics } from 'app/model/agent/statistics/agentActivityStatistics';
import { AgentSubscription } from 'app/model/supervisor/agent-subscription';
import { AgentActivityCCWise } from 'app/model/supervisor/agentActivitystats';

import { ConnectionService } from 'ngx-connection-service';
import { GroupDirectoryResponseHandler } from 'app/broadworks/startUp/group-directory-response-handler';
// const electronPackage = require('../../electron/package.json');
const webPackage = require('../../../package.json');

// c8 new models 
import { CallBackEntry } from '../model/call-back.model';
import { WebChatEntry } from '../model/web-chat.model';
import { TwitterEntry } from '../model/twitter.model';
import { EmailEntry } from '../model/email.model';
import { SmsEntry } from '../model/sms.model';

@Injectable()
export class ContextService {

  confirmModalVis: Subject<any> = new Subject();
  errorVisible: Subject<any> = new Subject();
  noIMOptions: boolean = false;
  active: string = "";
  messageCounter = 0;
  activeWindow: string = "";
  chatWindowStatus: boolean = false;
  isChatOpen = false;
  isImConnected: boolean = true;
  websocketService: WebsocketService;
  isElectron: boolean = false;
  loadingFlag: boolean = true;
  loadingState: string = "Web-Client";
  isRestart: boolean = false;
  //isRegistered is made true when unity authenticate successfully
  isAuthenticationFailure = false;
  public objLoggedUserDetails: LoggedUserDetails;
  public directoryList: DirectoryEntry[];
  public languageList: Languages[];

  public assignedServices: any[];
  public objSettings: Settings;
  channelId: string = "";
  sessionId: string = "";
  subscriptionId = new Set();

  public callLogsList: Array<CallLogEntry>;
  public monitoredUserList: Array<MonitoredUser>;

  public callStatusList: Array<CallStatusEntry | CallBackEntry>;  // C8 - added multiple types?
  public selectedlanguageCollection;
  public defaultLanguageCollection;
  public isFirstTimeLogin: boolean = false;
  public isLoadBrowserLang: boolean = false;
  selectedRows: Array<any> = [];

  conversations: Array<ChatConversation> = [];
  conversations1: Array<ChatConversation> = [];
  messages: Array<any> = [];

  missedCallReferenceDate;
  missedCallCount: number = 0;
  countCall: any[] = [];
  dialMenu: MenuItem[];

  scheduledStatusListCollection: ScheduledStatusList[];

  islicenseTypeState: boolean = true;
  releaseAnswerState: string = "release";
  transferState: string = "enabled";
  holdRetrieveState: string = "hold";
  conferenceState: string = "conferenceActive";
  voiceMailState: string = "voiceMailEnable";
  isTransferCall: boolean = false;
  transferCallId: string = "";
  timerState: boolean = false;

  loginComponentLoad: Subject<boolean> = new Subject();
  remoteOfficeEvent = new Subject<any>();
  doNotDisturbEvent = new Subject<any>();
  cfaEvent = new Subject<any>();
  showIM = new Subject<any>();
  imEvent = new Subject<any>();
  updateCallControlButtons: Subject<any> = new Subject();
  monitorStatusUpdate: Subject<any> = new Subject();
  imRemoveFromDirectories: Subject<any> = new Subject();
  MonitoredUsersupdate: Subject<any> = new Subject();
  viewUserDetailsModalVis: Subject<any> = new Subject<any>();
  updatecalllogs: Subject<any> = new Subject<any>();
  serviceModalVis = new Subject<any>();
  broadworksErrorModalVis = new Subject<any>();
  imConnectionLostModalVis = new Subject<any>();
  noServiceModalVis = new Subject<any>();
  loadSettingsToUI = new Subject<any>();

  isVoiceMailActive: boolean = false;
  noStatus: boolean = false;
  version: string = "";
  route: string = "";
  loginVisible = new Subject<any>();
  //chat
  public messageArray: Array<any>;
  conversationHeaderChange = new Subject<any>();
  activateId: Subject<any> = new Subject();
  isNetworkError: boolean = false;
  chatCollapsed: boolean = true;
  chatTooltip: string = "";

  licenceExpireState: boolean = false;
  getlicenseExpiryPrompt: string = "";
  licenseType: string = "";
  userID: string = "";
  //on drag to contacts table
  droppedToNumber: string;
  dropedToname: string;
  dropedToType: string;
  droppedcall: CallStatusEntry;

  sub: ISubscription;

  //Record
  recordButtonState: string = "startRecording";
  recordingState: string = "";
  recordingMode: string = "";
  callrecordingInitiateCallid: string = "";
  recordingErrorFlag: boolean = false;
  recordingErrorMessage: string = "";
  setRecordingMode = new Subject<any>();

  //voice mail Tab
  urlVoiceMessage: string = "";
  deletedVoiceMessageId: string = "";
  public voiceMailList: Array<VoiceMailEntry>;
  updateMediaPlayerSource: Subject<any> = new Subject();
  updateVoiceMailTableUpdate: Subject<any> = new Subject();
  voiceMailTabState: boolean = true;
  updateTabSelection: Subject<any> = new Subject();
  mediaPlayerState: boolean = false;
  downloadFlag = false;

  newVoiceMessageTime: Date = new Date();
  voiceMessageCount: number = 0;

  flexibleHostListWithName = [];
  hotelingListWithName = [];
  hostID: string = "";
  hostIDFlexibleSeating: string = "";
  deviceName: string = "";
  devicePort: string = "";
  deviceLevel: string = "";
  hotelingGuestEvent = new Subject<any>();
  flexibleSeatingGuestEvent = new Subject<any>();
  getDirectoryView = new Subject<any>();
  loadLoginPage: boolean = true;
  reConnect: boolean = false;
  openServerDetails: boolean = true;
  checkBWAnywhere: boolean = false;
  broadworksCriteriaAdd = new Subject<any>();
  deletedConversations: Array<ChatConversation> = [];
  imOverridden: boolean = false;
  voicemailActive = new Subject<any>();
  chatFocus = new Subject<any>();
  // ACD Buttons
  availableState: boolean = false;
  unAvailableState: boolean = false;
  wrapUpState: boolean = false;
  unAvailableClassName: string = "btn-unavailable";
  unAvailableTooltipValue: string = "";
  public unAvailableCodesArray: Array<UnAvailableCode> = [];
  updateUnAvailableButton: Subject<any> = new Subject();
  updateACDButtons: Subject<any> = new Subject();

  agentDetails: LoggedAgentDetails;
  isDNDActive: boolean = false;
  acdState: string = "";
  joinLeaveQueue: Subject<any> = new Subject();
  setUnavailableCodes: Subject<any> = new Subject();
  acdStateUpdate: Subject<any> = new Subject();
  signout: Subject<any> = new Subject();
  isSignedOut: boolean = false;

  ongoingCallCenterCallDetails: CallStatusEntry;
  previousCallCenterCallDetails: CallStatusEntry;
  previousDispositionSetList: Array<string> = [];
  onGoingDispositionSetList: Array<AssignedDispositionCodes> = [];
  selectedDispositionCodeForPrevious: string = "";
  previousAgentState: string = "";
  previousUnavailableCode: string = "";
  isAssigningDispositionCodeForPrevious: boolean = false;
  updateToolsMenu: Subject<any> = new Subject();
  subForTitle: Subscription;
  isTitleSubscription: boolean = false;
  popForceDispositionCode: Subject<any> = new Subject();
  setDnisResponse: boolean = false;
  setJoinLeaveResponse: boolean = false;
  SetAgentStateResponse: boolean = false;
  callCenterBlindTransferCallDetails: CallStatusEntry;
  changeChatHeight: Subject<any> = new Subject();
  mainMenuHeight: number = 10;

  // agent end
  statistics: Array<StatToDisplay> = [];
  cols: any[] = [];
  calcStatWidth: Subject<any> = new Subject();
  summary: any[] = [];
  statColWidth: string;

  summaryStatToDisplay: Array<StatToDisplay> = [];

  //agentP3
  callCenterListAlert: Subject<any> = new Subject();
  agentColumnList = [];
  queueColumnList = [];
  queueStatisticsList: QueueStatistics[];
  agentStatisticsList: AgentStatistics[];

  agentColumnBackupList = [];
  queueColumnBackupList = [];
  queueThresholdList = [];
  callCenterList = [];
  sortable = [];
  queueStatisticsToDisplay: string = "";
  columnHeading: string = "";
  setThreshold: boolean = false;
  showAgentColumnsList = [];
  showQueueColumnsList = [];
  callCenterLoginFailed: Subject<any> = new Subject();
  sessionPassword: string = "";
  showPersonalWallboard: boolean = false;
  calculateSummaryStats: Subject<any> = new Subject();
  pwCallcenterIndex = [];
  refreshFrequencyTimer: Subject<any> = new Subject();
  loggedInCallCenters = [];
  resizeChatHeight: Subject<any> = new Subject();
  showOnlySummaryStatInPW: Subject<any> = new Subject<any>();
  callCenterLoginFailedCollection = [];
  cticonnectiondroppedvis: boolean = false;
  stopTimer: Subject<any> = new Subject<any>();

  //agentP3

  showDnisError: Subject<any> = new Subject<any>();
  flexibleSeatingAssociationLimit: string = "";
  hotelingGuestAssociationLimit: string = "";

  public bridgeList: Array<UserMeetMeConferencingBridge> = [];
  public conferenceList: Array<UserMeetMeConferencing> = [];
  isMeetMeConferenceAssigned: boolean = false;

  subscriptionList = [];
  privacyEnabledUsersList = [];
  activePrivacyId: string = "";
  privacyErrorModalVis = new Subject<any>();
  privacyErrorVis: boolean = false;
  outlookContactPush = new Subject<any>();
  startIndexOutlook: number = 0;
  outlookContactLookUpCollection = [];
  outlookContactLookUpCount = 0;
  showSurveyExtensionButton: boolean = false;
  isCallParkAssigned: boolean = false;
  surveyExtensionExist: boolean = false;
  showCallParkButton: boolean = false;
  isCallPickUpAssigned: boolean = false;
  //keyvalue pair
  public featureAccessCodeList = [];
  public userCallPickUpList: Array<UserCallPickUp> = [];
  activeCallIdWhenWarmTransfer: string = "";
  warmTransferNumber: string = "";
  showWarnTransferButton: boolean = false;
  warmTransferCallId: string = "";
  callParkUserName: string = "";
  warmTransferName: string = "";
  ctiErrorVis: boolean = false;
  addChannelServerAddress: string = "";
  addChannelServerPort: string = "";
  noRegisterResponseVis: boolean = false;
  setSearchSettingsInContacttable = new Subject<any>();
  sessionTerminated: boolean = false;
  office365APISupported: boolean = true;

  isConnectedToInternet: boolean = true;
  noNetworkVis: Subject<any> = new Subject();
  commonObservable: Subject<any> = new Subject();
  startupCompleted: boolean = false;
  subscriptionCompleted: boolean = false;
  //supervisor
  agentActivityColumnList = [];
  agentActivityStatisticsList: AgentActivityStatistics[];
  showAgentActivityColumnsList = [];
  agentActivityCols: any[] = [];
  agentSubscriptionList: Array<AgentSubscription> = [];
  agentStatisticsRaw: Array<AgentActivityCCWise> = [];
  agentAactivityTableUpdation: Subject<any> = new Subject<any>();
  forcedForwardingCCId: string;
  forcedForwardingVisible: boolean;
  agentActivityStatistics = [];
  agentActivityColumnBackupList = [];
  agentActivityThresholdList = [];
  agentActivityStatisticsToDisplay: string = "";
  modifySupervisedAgentsVisible: Subject<any> = new Subject<any>();
  showAgentActivity: Subject<any> = new Subject();
  staffedRatioSubscriptionList: Array<any> = [];

  midnightPurgeSub: Subscription = new Subscription();
  isSubscriptionMidnight: boolean = false;
  silentMonitorArray: Array<any> = [];
  silentMonitorAllCalls: boolean = false;
  silentMonitorAllCallsUserId: string = "";
  //suprvisor

  hasNetworkConnection: boolean;
  hasInternetAccess: boolean;
  status: string;
  connectionLostAlertVisible: boolean = false;
  previousNetworkState: string = "";

  constructor(private connectionService: ConnectionService, public electronService: ElectronService, private titleService: Title, public chatService: ChatService) {

    this.cols = [];



    this.summaryStatToDisplay.push(new StatToDisplay("", "Summary", "", 100, "", this));


    //console.log('###### constructor - ContextService');
    this.selectedlanguageCollection = {};
    this.defaultLanguageCollection = {};
    this.loadDeveloperLanguageTags();

    this.missedCallReferenceDate = new Date();

    if (electronService.isElectron()) {
      //this.log('Load Mode electron');
      this.isElectron = true;
    } else {
      //this.log('Load Mode web');
      this.isElectron = false;
    }

    this.callStatusList = [];
    this.languageList = [];
    this.directoryList = [];
    this.callLogsList = [];
    this.assignedServices = [];
    this.monitoredUserList = [];
    this.scheduledStatusListCollection = [];

    this.objSettings = new Settings();
    this.checkForLoggedUserAndLogIn();
    this.websocketService = new WebsocketService(this);
    this.dialMenu = [];

    this.messageArray = [];
    const browser = this.get_browser_info();
    //console.log(browser.name + "browser.name");

    this.connectionService.monitor(false).subscribe(currentState => {
    });

    if (browser.name !== 'Safari') {
      Notification.requestPermission().then(function (result) {

      });
    } else {
      //this.log("safari browser");
      try {
        let ua = window.navigator.userAgent;
        let platform = window.navigator.platform;
        let osVersion = /10[\.\_\d]+/.exec(ua)[0];
        if (/[\_]/.test(osVersion)) {
          osVersion = osVersion.split('_').join('.');
        }
        let before = parseInt(osVersion.split(".")[0]);
        let after = parseInt(osVersion.split(".")[1]);
        if (before > 10) {
          Notification.requestPermission();
          //this.log('OS version :' + osVersion);
        } else {
          if (before === 10 && after > 8) {
            Notification.requestPermission();
            //this.log('OS version :' + osVersion);
          } else {
            //this.log('Doesnot support Notification and the os version is :' + osVersion);
          }
        }
      } catch (error) {
        //this.log("Safari browser doesnot support local notification");
      }

    }
    this.droppedToNumber = "";
    this.dropedToname = "";
    this.dropedToType = "";
    this.voiceMailList = [];
    // if (this.isElectron) {
    //   this.version = electronPackage.version;
    // } else {
    this.version = webPackage.version;
    // }

    this.unAvailableCodesArray = [];
    if (this.objSettings.STARTUP === 2 || this.objSettings.STARTUP === 3) {
      this.agentDetails = new LoggedAgentDetails("", "", "");
    }

    //agentP3
    this.loadAgentStatColumns();
    this.loadQueueStatColumns();

    this.queueStatisticsList = [];
    this.agentStatisticsList = [];

    //supervisor
    if (this.objSettings.STARTUP === 3) {


      this.loadAgentActivityStatColumns();
      this.agentActivityStatisticsList = [];
      this.agentActivityColumnList.forEach(element => {
        this.agentActivityStatisticsList.push(new AgentActivityStatistics(element.label, element.value, this.columnHeading, this.setThreshold));
      });

      this.agentActivityStatisticsList.forEach(element => {
        element.threshold = new Threshold(false, false, "", "", "", "");
      });
    }
    this.setUnavailableCodes.subscribe(() => {

      let unavailableCodesList = [];

      for (let i = 0; i < this.unAvailableCodesArray.length; i++) {
        let element = this.unAvailableCodesArray[i];
        unavailableCodesList.push({ id: i + 1, number: element.code, value: element.Description });
      }
      if (this.objSettings.STARTUP === 3) {
        this.agentActivityStatisticsList.forEach(element1 => {
          if (element1.statName === "agentACDDuration") {
            if (!("all" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["all"] = new Threshold(false, false, "", "", "", "");
            }
            if (!("Sign-In" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["Sign-In"] = new Threshold(false, false, "", "", "", "");
            }
            if (!("Sign-Out" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["Sign-Out"] = new Threshold(false, false, "", "", "", "");
            }
            if (!("Available" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["Available"] = new Threshold(false, false, "", "", "", "");
            }
            if (!("Wrap-Up" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["Wrap-Up"] = new Threshold(false, false, "", "", "", "");
            }
            if (!("Unavailable" in element1.unavailableCodeList)) {
              element1.unavailableCodeList["Unavailable"] = new Threshold(false, false, "", "", "", "");
            }
            for (let i = 0; i < unavailableCodesList.length; i++) {
              let element = unavailableCodesList[i];
              if (!("Unavailable - " + element.value in element1.unavailableCodeList)) {
                element1.unavailableCodeList["Unavailable - " + element.value] = new Threshold(false, false, "", "", "", "");
              }
            }
          }

        });
      }
      //     this.loadQueueData();
    });

    //
    this.queueColumnList.forEach(element => {
      this.queueStatisticsList.push(new QueueStatistics(element.label, element.value, this.columnHeading, this.setThreshold));
    });


    this.agentColumnList.forEach(element => {
      this.agentStatisticsList.push(new AgentStatistics(element.label, element.value, this.columnHeading, this.setThreshold));
    });


    this.agentStatisticsList.forEach(element => {
      element.threshold = new Threshold(false, false, "", "", "", "");
    });

    this.callCenterListAlert.subscribe(() => {

      this.sortProperties(this.agentDetails.callCenters);
      this.callCenterList = [];

      for (let i = 0; i < this.sortable.length; i++) {
        let element = this.sortable[i];
        this.callCenterList.push({ id: i + 1, number: this.getLastNameFromUserID(element[1].acdUserId), value: element[1].acdUserId });
      }
      this.queueStatisticsList.forEach(element1 => {

        if (!("all" in element1.callCentersList)) {
          element1.callCentersList["all"] = new Threshold(false, false, "", "", "", "");
        }

        for (let i = 0; i < this.callCenterList.length; i++) {
          let element = this.callCenterList[i];
          if (!(element.value in element1.callCentersList)) {
            element1.callCentersList[element.value] = new Threshold(false, false, "", "", "", "");
          }
        }
      });
      //     this.loadQueueData();
    });
    this.forCCSDesignTeam();

    //     let c = new ChatConversation({
    //       "id": "123", "people": [{ "id": "extn8004@kakaposystems.com", "name": "Arjun Harikumar" }], "headline": "james", "unread": 1, "offline": false, "message": "Starting new conversation demo"
    //   });
    //   this.conversations.push(c);
    //   let c1 = new ChatConversation({
    //     "id": "123", "people": [{ "id": "extn8006@kakaposystems.com", "name": "Bifin Jose" },{ "id": "extn8005@kakaposystems.com", "name": "Athul P S" }], "headline": "james", "unread": 1, "offline": false, "message": "Starting new conversation demo"
    // });
    // this.conversations.push(c1);
  }
  forCCSDesignTeam() {

    let callStatusList = [...this.callStatusList];
    callStatusList.push(new CallStatusEntry("callhalf-5173092035:0", "Athul PS", "Bifin Jose",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Originator", "Active", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-incoming", false, "", '', false, false));
    callStatusList.push(new CallStatusEntry("callhalf-5173092035:1", "Bifin Jose", "Athul PS",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-incoming", false, "", '', false, false));


    callStatusList.push(new CallBackEntry("xx-5173092035:1", "Call me back guy", "Mike",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-callback", false, "", '', false, false));

    callStatusList.push(new WebChatEntry("xx-5173092035:22", "Chat me guy", "Mike",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-webchat", false, "", '', false, false));

    callStatusList.push(new TwitterEntry("xx-5173092035:12", "Twitter chat", "Mike",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-twitter", false, "", '', false, false));

    callStatusList.push(new EmailEntry("xx-5173092035:99", "Email received", "Mike",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-email", false, "", '', false, false));

    callStatusList.push(new SmsEntry("xx-5173092035:99", "New SMS!", "Mike",
      "Active", "8005", "test", false, "752c1c58-9d95-40c2-92a9-1cac5aeda955", "Terminator", "Ringing", "testuser", "44", "8005", "8005", "1643353840895",
      "ico-sms", false, "", '', false, false));


    this.callStatusList = callStatusList;
    console.warn(this.callStatusList)
  }
  calculateStaffedRatio(queueID?: string) {

  }

  sortProperties(obj) {
    // convert object into array
    this.sortable = [];
    for (var key in obj)
      if (obj.hasOwnProperty(key))
        this.sortable.push([key, obj[key]]); // each item is an array in format [key, value]

    // sort items by value
    this.sortable.sort(function (a, b) {
      var x = a[1].callCenterName.toLowerCase(),
        y = b[1].callCenterName.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  loadAgentActivityData() {
    this.agentActivityThresholdList = [];
    this.agentActivityStatisticsList.forEach(element => {
      if (this.agentActivityStatisticsToDisplay == element.statName) {
        this.agentActivityThresholdList.push({ id: 1, name: "All", value: "all", green: element.unavailableCodeList["all"].green, yellow: element.unavailableCodeList["all"].yellow, red: element.unavailableCodeList["all"].red, black: element.unavailableCodeList["all"].black });
        this.agentActivityThresholdList.push({ id: 1, name: "Sign-In", value: "Sign-In", green: element.unavailableCodeList["Sign-In"].green, yellow: element.unavailableCodeList["Sign-In"].yellow, red: element.unavailableCodeList["Sign-In"].red, black: element.unavailableCodeList["Sign-In"].black });
        this.agentActivityThresholdList.push({ id: 1, name: "Available", value: "Available", green: element.unavailableCodeList["Available"].green, yellow: element.unavailableCodeList["Available"].yellow, red: element.unavailableCodeList["Available"].red, black: element.unavailableCodeList["Available"].black });
        this.agentActivityThresholdList.push({ id: 1, name: "Wrap-Up", value: "Wrap-Up", green: element.unavailableCodeList["Wrap-Up"].green, yellow: element.unavailableCodeList["Wrap-Up"].yellow, red: element.unavailableCodeList["Wrap-Up"].red, black: element.unavailableCodeList["Wrap-Up"].black });
        this.agentActivityThresholdList.push({ id: 1, name: "Unavailable", value: "Unavailable", green: element.unavailableCodeList["Unavailable"].green, yellow: element.unavailableCodeList["Unavailable"].yellow, red: element.unavailableCodeList["Unavailable"].red, black: element.unavailableCodeList["Unavailable"].black });

        for (let i = 0; i < this.unAvailableCodesArray.length; i++) {
          let element1 = this.unAvailableCodesArray[i];
          this.agentActivityThresholdList.push({ id: 1, name: "Unavailable (" + element1.Description + ")", value: "Unavailable - " + element1.Description, green: element.unavailableCodeList["Unavailable - " + element1.Description].green, yellow: element.unavailableCodeList["Unavailable - " + element1.Description].yellow, red: element.unavailableCodeList["Unavailable - " + element1.Description].red, black: element.unavailableCodeList["Unavailable - " + element1.Description].black });
        }
        this.agentActivityThresholdList.push({ id: 1, name: "Sign-Out", value: "Sign-Out", green: element.unavailableCodeList["Sign-Out"].green, yellow: element.unavailableCodeList["Sign-Out"].yellow, red: element.unavailableCodeList["Sign-Out"].red, black: element.unavailableCodeList["Sign-Out"].black });

      }
    });

    let temp = [...this.agentActivityThresholdList];
    this.agentActivityThresholdList = temp;

    let id_value: number = 1;
    this.agentActivityThresholdList.forEach(element => {
      element.id = id_value;
      id_value++;

    });
  }
  loadQueueData() {

    this.queueThresholdList = [];
    this.queueStatisticsList.forEach(element => {
      if (this.queueStatisticsToDisplay == element.statName) {

        this.queueThresholdList.push({ id: 1, name: "All", value: "all", green: element.callCentersList["all"].green, yellow: element.callCentersList["all"].yellow, red: element.callCentersList["all"].red, black: element.callCentersList["all"].black });
        for (let i = 0; i < this.callCenterList.length; i++) {
          let element1 = this.callCenterList[i];
          this.queueThresholdList.push({ id: 1, name: this.getLastNameFromUserID(element1.value), value: element1.value, green: element.callCentersList[element1.value].green, yellow: element.callCentersList[element1.value].yellow, red: element.callCentersList[element1.value].red, black: element.callCentersList[element1.value].black });
        }
      }
    });

    let temp = [...this.queueThresholdList];
    this.queueThresholdList = temp;

    let id_value: number = 1;
    this.queueThresholdList.forEach(element => {
      element.id = id_value;
      id_value++;

    });
  }
  recordingButtonState(callState: string, recordState: string) {
    if (callState === 'Alerting' && recordState === 'none') {
      this.recordingState = 'inactive ico-drop';
      this.recordButtonState = 'disabled';
    } else if (callState === 'Active' && recordState === 'none' && this.recordingMode != 'never') {
      this.recordButtonState = 'startRecording';
      this.recordingState = 'recording';
    } else if (callState === 'Active' && recordState === 'started' && (this.recordingMode != 'never' && this.recordingMode != 'always')) {
      this.recordButtonState = 'pauseRecording';
      this.recordingState = 'paused';
    } else if ((callState === 'Active' && recordState === 'paused')) {
      this.recordButtonState = 'startRecording';
      this.recordingState = 'recording';
    } else if (callState === 'Held' && (this.recordingMode != 'never' && this.recordingMode != 'always')) {
      this.recordButtonState = 'startRecording';
      this.recordingState = 'recording';
    } else if (callState === 'Active' && recordState === 'stoped') {
      this.recordButtonState = 'startRecording';
      this.recordingState = 'recording';
    } else if (callState === 'Active' && recordState === 'resume') {
      this.recordButtonState = 'pauseRecording';
      this.recordingState = 'paused';
    }

  }

  get_browser_info() {

    //this.log("start method: get_browser_info()");

    let ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };

  }

  checkForLoggedUserAndLogIn() {
    //this.log("start method: checkForLoggedUserAndLogIn()");

    if (this.getLocalStorageItemValueByKey("LoggedUser") != null) {
      this.loadLoginPage = false;
      this.objSettings.getSettings(this.getLocalStorageItemValueByKey("LoggedUser"));
    } else {
      this.isFirstTimeLogin = true;
      //      this.loadingFlag = false;
    }

    //this.log("End method: checkForLoggedUserAndLogIn()");

  }



  setLocalStorageItem(key: string, value: string) {
    // localStorage.setItem(key, value);
  }
  getLocalStorageItemValueByKey(key: string): string {
    return localStorage.getItem(key);
  }


  contactLookUp(lookupNumber: string): string {


    return lookupNumber;

  }

  getExtensionFromUserID(loginID: string): string {
    for (let i = 0; i < this.directoryList.length; i++) {
      if (this.directoryList[i] instanceof BwDirectoryEntry) {
        let element = this.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === loginID) {
          return element.getExtension();
        }
      }
    }
  }

  outlookContactLookUp(lookupNumber: string): string {
    return lookupNumber;
  }

  /**
   * Ignore first name if it contains Call Center, Hunt Group etc.
   * @param entry BwDirectoryEntry
   */
  nameIgnore(entry: BwDirectoryEntry): string {

    // let match: boolean = (!entry.getFirstName().includes("Flexible Seating Guest") && !entry.getFirstName().includes("Call Center") && !entry.getFirstName().includes("Hunt Group") && !entry.getFirstName().includes("Trunk Group")
    //   && !entry.getFirstName().includes("Meet-Me Conference") && !entry.getFirstName().includes("Auto Attendant") && !entry.getFirstName().includes("Meet-Me Conferencing")
    //   && !entry.getFirstName().includes("BroadWorks Anywhere") && !entry.getFirstName().includes("Voice Messaging Group") && !entry.getFirstName().includes("Group Paging")
    //   && !entry.getFirstName().includes("Instant Group") && !entry.getFirstName().includes("Voice Messaging Group") && !entry.getFirstName().includes("Group Paging"));

    let match: boolean = this.checkIfContactCanBeMonitored(entry.getFirstName(), true);
    if (!match) {
      return entry.getLastName();
    }
    return entry.getFullName();
  }

  checkIfContactCanBeMonitored(firstName: string, isContactLookUp: boolean): boolean {
    let contactsThatCannotBeMonitored = ['broadworks anywhere', 'instant conferencing', 'instant group call', 'trunk group', 'voice portal',
      'auto attendant', 'call center', 'collaborate - audio', 'flexible seating guest', 'group paging',
      'hunt group', 'hunt gruppe', 'meet-me conferencing', 'voicexml', 'auto attendant - basic', 'auto attendant - standard'];
    if (!isContactLookUp) {
      contactsThatCannotBeMonitored.push('voice messaging group');
    }
    return !contactsThatCannotBeMonitored.includes(firstName.toLowerCase());
  }
  getNameFromUserID(userId: string): string {

    for (let i = 0; i < this.directoryList.length; i++) {
      if (this.directoryList[i] instanceof BwDirectoryEntry) {
        const element = this.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === userId) {
          return this.nameIgnore(element);
        }
      }
    }

    return userId;
  }

  getLastNameFromUserID(userId: string): string {

    for (let i = 0; i < this.directoryList.length; i++) {
      if (this.directoryList[i] instanceof BwDirectoryEntry) {
        const element = this.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === userId) {
          return element.getLastName();
        }
      }
    }

    return userId;
  }

  getFirstNameFromUserID(userId: string): string {

    for (let i = 0; i < this.directoryList.length; i++) {
      if (this.directoryList[i] instanceof BwDirectoryEntry) {
        const element = this.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === userId) {
          return element.getFirstName();
        }
      }
    }

    return userId;
  }

  getLanguageTagByKey(input: string): string {
    if (this.selectedlanguageCollection[input]) {
      return this.selectedlanguageCollection[input];
    } else if (this.defaultLanguageCollection[input]) {
      return this.defaultLanguageCollection[input];
    } else {
      return input;
    }

  }

  //method used for append domain to userId
  //---------------------------------------
  appendDomain(userId: string): string {

    const regex = new RegExp("@");
    if (!(regex.test(userId))) {
      if (this.objLoggedUserDetails.getinstantMessageDomain() !== '') {
        //assuming 'getlicenseInstantMessageDomain()' doesn't return '@' with the domain name
        userId = userId + "@" + this.objLoggedUserDetails.getinstantMessageDomain();
      }
      else {
        userId = userId + "@" + this.objLoggedUserDetails.getUserId().split("@")[1];
      }
    }

    return userId;
  }

  setTitle(newTitle: string) {
    if (this.isElectron === true) {
      this.electronService.ipcRenderer.send("Set-Electron-Title", newTitle);
    } else {
      this.titleService.setTitle(newTitle);
    }
  }


  setStatusInTitle() {
    let timervalue = "";

    if (this.objSettings.STARTUP === 2 || this.objSettings.STARTUP === 3) {
      let stateTime: number = parseInt(this.agentDetails.stateTimestamp);
      if (this.objSettings.acdState_ShowDuration === "startTime") {
        //if acd show start time is set
        let date = new Date(stateTime);

        let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
        let amPm = date.getHours() >= 12 ? "PM" : "AM";
        hours = parseInt(hours < 10 ? "0" + hours.toString() : hours.toString(), 10);
        let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        timervalue = " (since " + hours + ":" + minutes + ":" + seconds + " " + amPm + ")";
        this.timerUnsubscribeForTitle();
      } else if (this.objSettings.acdState_ShowDuration === "duration") {
        //if acd show duration is set
        let timeDifference = new Date().getTime() - new Date(stateTime).getTime();
        let Seconds = Number(timeDifference / 1000);
        let Minutes = Number(Seconds / 60);
        let Hours = Number(Minutes / 60);
        Seconds = Number(Seconds % 60);
        Minutes = Number(Minutes % 60);
        timervalue = " (" + this.getLanguageTagByKey("List.CurrentCalls.Column.Duration").toLowerCase() + ": " + (Hours >= 1 ? (("0" + Math.trunc(Hours)).slice(-2) + ':') : "") + ("0" + Math.trunc(Minutes)).slice(-2) + ':' + ("0" + Math.trunc(Seconds)).slice(-2) + ")";
      } else {
        //if show nothing is set
        this.timerUnsubscribeForTitle();
      }
    }
    // alert(this.objSettings.STARTUP=Unity Call Center Agent)
    let AcdState = this.acdState;
    //if DND is active
    if (this.isDNDActive) {
      if (AcdState != "") {
        AcdState = "/" + AcdState + timervalue;
      }
      this.setTitle(this.objSettings.currentProduct + ' Web: ' + this.objLoggedUserDetails.getFullName() + " - " + this.getLanguageTagByKey("List.UserStatus.State.DoNotDisturbActivated") + AcdState);
    } else if (AcdState) {
      // if only acd state is there
      this.setTitle(this.objSettings.currentProduct + ' Web: ' + this.objLoggedUserDetails.getFullName() + " - " + AcdState + timervalue);
    } else {
      // if no status
      this.setTitle(this.objSettings.currentProduct + ' Web: ' + this.objLoggedUserDetails.getFullName());
    }
    if (this.objSettings.STARTUP === 1) {
      this.timerUnsubscribeForTitle();
    }
  }

  /**
   *timer to show status in title
   *
   * @memberof ContextService
   */
  timerSetUpForTitle() {
    let timerforstatus = timer(0, 1000);
    if (!this.isTitleSubscription) {
      this.isTitleSubscription = true;
      this.subForTitle = timerforstatus.subscribe(
        t => {
          this.setStatusInTitle();
        }
      );
    }
  }

  /**
   *to unsubscribe the timer to show status in title
   *
   * @memberof ContextService
   */
  timerUnsubscribeForTitle() {
    if ((this.subForTitle !== undefined) && (!this.subForTitle.closed)) {
      this.isTitleSubscription = false;
      this.subForTitle.unsubscribe();
    }
  }

  log(msg) {
    if (this.isElectron === true) {
      this.electronService.ipcRenderer.send('logging', msg);
      //console.log(msg);//to do delete 
    } else {
      //console.log(msg);
    }
  }

  /**
     * copy to clipboard
     * 
     * @param {any} textTocopy 
     * @memberof DirectoryService
     */
  copy(textTocopy) {
    let aux = document.createElement("input");
    // Get the text from the element passed into the input
    aux.setAttribute("value", textTocopy);
    // Append the aux input to the body
    document.body.appendChild(aux);

    // Highlight the content
    aux.select();

    // Execute the copy command
    document.execCommand("copy");
    document.body.removeChild(aux);
  }

  timerSetUp() {
    let time = timer(1, 1);
    this.sub = time.subscribe(
      t => {
        this.timerState = true;
        this.calculateCallTime();

      }
    );

  }
  calculateCallTime() {


  }
  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
            o[prop] = this.deepCopy(source[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }
  instantMessage(senderID) {
    let activeUserID;
    let tempOffline;
    for (let i = 0; i < this.directoryList.length; i++) {

      if (this.directoryList[i] instanceof BwDirectoryEntry) {

        let element = this.directoryList[i] as BwDirectoryEntry;

        if (senderID === element.getUserId()) {
          tempOffline = element.getOnline();
        }
      }
    }

    if (this.chatWindowStatus === false) {
      this.chatService.toggleChat.next(true);
    }
    let id = senderID + "_" + Math.floor(Date.now() / 1000);
    let isMatch = false;
    let openConversation: boolean = false;
    this.conversations.forEach(conversationsElement => {
      if (conversationsElement.people.length === 1) {
        conversationsElement.people.forEach(element => {
          let result = id.substring(0, id.lastIndexOf("_"));
          if (element.id === result) {
            isMatch = true;
            this.userID = conversationsElement.id.toString();
            activeUserID = conversationsElement.id.toString();
          }
        });
      }
    });

    this.deletedConversations.forEach(deletedConversations => {
      if (deletedConversations.people.length === 1) {
        let result = id.substring(0, id.lastIndexOf("_"));
        let tempID = deletedConversations.id.toString().substring(0, deletedConversations.id.toString().lastIndexOf("_"));
        let extn = "";
        deletedConversations.people.forEach(element => {
          extn = element.id;
        });
        if (result === tempID && result === extn) {
          isMatch = true;
          let idExist: boolean = false;
          openConversation = true;
          this.userID = deletedConversations.id.toString();
          activeUserID = deletedConversations.id.toString();
          this.conversations.forEach(elementChat => {
            let convID = elementChat.id.toString().substring(0, elementChat.id.toString().lastIndexOf("_"));
            if (elementChat.people.length === 1) {
              if (convID === result) {
                idExist = true;
              }
            }

          });
          if (!idExist) {
            this.conversations.push(deletedConversations);


          }

          let index: number = this.getConversationKeyById(deletedConversations.id, this.deletedConversations);
          if (index > -1) {
            this.deletedConversations.splice(index, 1);
          }
        }
        else {
          deletedConversations.people.forEach(elementPeople => {
            if (elementPeople.id == senderID) {
              openConversation = true;
              this.userID = deletedConversations.id.toString();
              activeUserID = deletedConversations.id.toString();
              isMatch = true;
              let idExist: boolean = false;
              this.conversations.forEach(elementChat => {
                let convID = elementChat.id.toString().substring(0, elementChat.id.toString().lastIndexOf("_"));
                if (elementChat.people.length === 1) {
                  if (convID === result) {
                    idExist = true;
                  }
                }

              });
              if (!idExist) {
                this.conversations.push(deletedConversations);

              }

              let index: number = this.getConversationKeyById(deletedConversations.id, this.deletedConversations);
              if (index > -1) {
                this.deletedConversations.splice(index, 1);
              }
            }
          });
        }

      }
    });

    if (!isMatch) {
      activeUserID = id;
      let people = [];


      let obj = { "id": senderID, "name": this.getNameFromUserID(senderID) };
      people.push(obj)

      let c = new ChatConversation({
        "id": id, "people": people, "headline": this.getNameFromUserID(senderID), "unread": 0, "offline": !tempOffline, "message": ""
      });
      this.conversations.push(c);
      // this.contextService.messages = [];

      this.messageArray.push({
        dateTime: "",
        headline: "",
        id: id,
        itsMe: false,
        person: [99],
        text: ""
      });


      if (this.active != id) {
        this.activeId(id);
        this.active = activeUserID;

      }
      this.messageArray.forEach(element => {
        if (this.active === id) {
          this.messages = [];
          this.messageArray.forEach(element => {


            if (element.id === id.toString() && element.text.length > 0) {
              this.messages.push(element);
            }
          });
        }
      });
    }
    else if (this.conversations.length !== 1 || openConversation) {
      this.messages = [];
      this.messageArray.forEach(element => {
        if (element.id === this.userID && element.text.length > 0) {
          this.messages.push(element);
        }
      });

      if (this.active != this.userID) {
        this.activeId(this.userID);
        this.active = activeUserID;
      }
    }

  }

  private getConversationKeyById(id: number, a) {
    let ret = -1;
    let i = 0;
    for (let conv of a) {
      if (conv.id == id) {
        ret = i;
      }
      i++;
    }
    return ret;
  }
  activeId(id: string) {
    this.activateId.next(id);
  }
  replaceURLWithHTMLLinks(text) {
    //this.log("before adding anchor tags" + text);
    let str = text.replace(/</ig, '&lt;');
    let expForHttpAndHttps = new RegExp(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    if (this.isElectron) {
      str = str.replace(expForHttpAndHttps, "<a class='chat_with_http'>$1</a>");
    } else {
      str = str.replace(expForHttpAndHttps, "<a href='$1' target='_blank' >$1</a>");
    }
    let expStartsWithWww = new RegExp(/([^\/]{2,2}|^)(www\.[\w-.]*?[\w-]+?(\/[\w-]*?)*?)(([^\w.\/-]+?)|$)+/ig);
    if (this.isElectron) {
      str = str.replace(expStartsWithWww, "$1<a class='chat_without_http' >$2</a>$4");
    } else {
      str = str.replace(expStartsWithWww, "$1<a href='https://$2' target='_blank' >$2</a>$4");
    }
    let expForEmail = new RegExp(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g);
    if (this.isElectron) {
      str = str.replace(expForEmail, "<a class='chat_mailid' >$1</a>");
    } else {
      str = str.replace(expForEmail, "<a href=mailto:$1 >$1</a>");
    }
    //this.log("after adding anchor tags" + str);
    return str;
  }

  //supervisor
  loadAgentActivityStatColumns() {
    this.agentActivityColumnList.push({ id: 1, label: this.getLanguageTagByKey('Settings.AgentActivityImageOption.JoinedState'), value: 'agentJoinedState' });
    this.agentActivityColumnList.push({ id: 2, label: this.getLanguageTagByKey('List.Statistics.Column.AcdState'), value: 'agentACDState' });
    this.agentActivityColumnList.push({ id: 3, label: this.getLanguageTagByKey('List.Statistics.Column.AcdStateDuration'), value: 'agentACDDuration' });
    this.agentActivityColumnList.push({ id: 4, label: 'ACD Start', value: 'agentACDStart' });
    this.agentActivityColumnList.push({ id: 5, label: this.getLanguageTagByKey('Misc.Status'), value: 'agentStatus' });
    this.agentActivityColumnList.push({ id: 6, label: this.getLanguageTagByKey('List.Statistics.Column.CurrentCallDuration'), value: 'agentCurrentCallDuration' });
    this.agentActivityColumnList.push({ id: 7, label: this.getLanguageTagByKey('Unavailability Reason'), value: 'agentUnavailabilityReason' });
    this.agentActivityColumnList.push({ id: 8, label: this.getLanguageTagByKey('Unavailability Reason Duration'), value: 'agentReasonDuration' });
    this.agentActivityColumnList.push({ id: 9, label: this.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'agentTotalCalls' });
    this.agentActivityColumnList.push({ id: 10, label: this.getLanguageTagByKey('List.Statistics.Column.AnsweredCalls'), value: 'agentAnsweredCalls' });
    this.agentActivityColumnList.push({ id: 11, label: this.getLanguageTagByKey('List.Statistics.Column.TotalMissedCalls'), value: 'agentMissedCalls' });
    this.agentActivityColumnList.push({ id: 12, label: this.getLanguageTagByKey('List.Statistics.Column.AverageTalkTime'), value: 'agentAverageTalkTime' });
    this.agentActivityColumnList.push({ id: 13, label: this.getLanguageTagByKey('List.Statistics.Column.TotalTalkTimeNew'), value: 'agentTotalTalkTime' });
    this.agentActivityColumnList.push({ id: 14, label: this.getLanguageTagByKey('List.Statistics.Column.TotalStaffedTime'), value: 'agentTotalStaffedTime' });
    this.agentActivityColumnList.push({ id: 15, label: this.getLanguageTagByKey('List.Statistics.Column.AgentType'), value: 'agentType' });
    this.agentActivityColumnList.forEach(element => {
      this.agentActivityColumnBackupList.push(element);
    });
  }
  //
  //agent phase 3
  loadAgentStatColumns() {
    this.agentColumnList.push({ id: 1, label: this.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'userTotalCalls' });
    this.agentColumnList.push({ id: 2, label: this.getLanguageTagByKey('List.Statistics.Column.AnsweredCalls'), value: 'userAnsweredCalls' });
    this.agentColumnList.push({ id: 3, label: this.getLanguageTagByKey('List.Statistics.Column.TotalMissedCalls'), value: 'userMissedCalls' });
    this.agentColumnList.push({ id: 4, label: this.getLanguageTagByKey('List.Statistics.Column.AverageTalkTime'), value: 'userAverageTalkTime' });
    this.agentColumnList.push({ id: 5, label: this.getLanguageTagByKey('List.Statistics.Column.TotalTalkTimeNew'), value: 'userTotalTalkTime' });
    this.agentColumnList.push({ id: 6, label: this.getLanguageTagByKey('List.Statistics.Column.TotalStaffedTime'), value: 'userTotalStaffedTime' });
    // this.agentColumnList.push({ id: 7, label: this.getLanguageTagByKey('List.Statistics.Column.CallbackAnswered'), value: 'userCallbacksAnswered' });

    this.agentColumnList.forEach(element => {
      this.agentColumnBackupList.push(element);
    });
  }

  loadQueueStatColumns() {
    this.queueColumnList.push({ id: 1, label: this.getLanguageTagByKey('List.Statistics.Column.TotalCalls'), value: 'queueTotalCalls' });
    this.queueColumnList.push({ id: 2, label: this.getLanguageTagByKey('List.Statistics.Column.TotalMissedCallsNew'), value: 'queueTotalMissedCalls' });
    this.queueColumnList.push({ id: 3, label: this.getLanguageTagByKey('List.Statistics.Column.TotalMissedCallsPercentageNew'), value: 'queueTotalMissedCallsPercentage' });
    this.queueColumnList.push({ id: 4, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfBusyOverflowsNew'), value: 'queueBusyOverflows' });
    this.queueColumnList.push({ id: 5, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfBusyOverflowsPercentageNew'), value: 'queueBusyOverflowsPercentage' });
    this.queueColumnList.push({ id: 6, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsAnswered'), value: 'queueCallsAnswered' });
    this.queueColumnList.push({ id: 7, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsAnsweredPercentage'), value: 'queueCallsAnsweredPercentage' });
    this.queueColumnList.push({ id: 8, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsAbandoned'), value: 'queueCallsAbandoned' });
    this.queueColumnList.push({ id: 9, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsAbandonedPercentage'), value: 'queueCallsAbandonedPercentage' });
    this.queueColumnList.push({ id: 10, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsTransferred'), value: 'queueCallsTransfered' });
    this.queueColumnList.push({ id: 11, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsTransferredPercentageNew'), value: 'queueCallsTransferedPercentage' });
    this.queueColumnList.push({ id: 12, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsTimedout'), value: 'queueCallsTimedOut' });
    this.queueColumnList.push({ id: 13, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsTimedoutPercentage'), value: 'queueCallsTimedOutPercentage' });
    this.queueColumnList.push({ id: 14, label: this.getLanguageTagByKey('List.Statistics.Column.AverageNumberOfAgentsTalking'), value: 'queueAverageAgentsTalking' });
    this.queueColumnList.push({ id: 15, label: this.getLanguageTagByKey('List.Statistics.Column.AverageNumberOfAgentsStaffed'), value: 'queueAverageAgentsStaffed' });
    this.queueColumnList.push({ id: 16, label: this.getLanguageTagByKey('List.Statistics.Column.AverageWaitTime'), value: 'queueAverageWaitTime' });
    this.queueColumnList.push({ id: 17, label: this.getLanguageTagByKey('List.Statistics.Column.AverageAbandonmentTimeNew'), value: 'queueAverageAbandonedTime' });
    this.queueColumnList.push({ id: 18, label: this.getLanguageTagByKey('List.Statistics.Column.AverageTalkTime'), value: 'queueAverageTalkTime' });
    this.queueColumnList.push({ id: 19, label: this.getLanguageTagByKey('List.Statistics.Column.TotalTalkTimeNew'), value: 'queueTotalTalkTime' });
    this.queueColumnList.push({ id: 20, label: this.getLanguageTagByKey('List.Statistics.Column.StaffRatio'), value: 'queueStaffedRatio' });
    this.queueColumnList.push({ id: 21, label: this.getLanguageTagByKey('List.Statistics.Column.LongestWaitTime'), value: 'queueLongestWaitTime' });
    this.queueColumnList.push({ id: 22, label: this.getLanguageTagByKey('List.Statistics.Column.NumberOfCallsInQueue'), value: 'queueCallsInQueue' });
    this.queueColumnList.push({ id: 23, label: this.getLanguageTagByKey('List.Statistics.Column.OverflowedTotal'), value: 'queueOverflowedTotal' });
    this.queueColumnList.push({ id: 24, label: this.getLanguageTagByKey('List.Statistics.Column.OverflowedTotalPercentage'), value: 'queueOverflowedTotalPercentage' });
    this.queueColumnList.push({ id: 25, label: this.getLanguageTagByKey('List.Statistics.Column.TotalReceivedCalls'), value: 'queueTotalReceivedCalls' });


    this.queueColumnList.forEach(element => {
      this.queueColumnBackupList.push(element);
    });
  }

  checkIfShowPersonalWallboard() {
    if ((this.objSettings.STARTUP === 2 && (this.assignedServices.includes('CallCenter-Premium') || this.assignedServices.includes('CallCenter-Standard')) || this.objSettings.STARTUP === 3)) {
      let callCenterLogged = false;
      let callCentersShowInPW = false;
      for (const key in this.agentDetails.callCenters) {
        if (this.agentDetails.callCenters.hasOwnProperty(key)) {
          if (this.agentDetails.callCenters[key].isLoggedIn) {
            callCenterLogged = true;
            break;
          }
        }
      }
      for (let index = 0; index < this.statistics.length; index++) {
        if (this.statistics[index].isDisplayInPersonalWallboard) {
          callCentersShowInPW = true;
          break;
        }
      }

      if (callCenterLogged && this.cols.length > 0 && ((this.objSettings.defaultPasswordExist && (callCentersShowInPW || this.objSettings.statistics_DisplaySummaryStatisticsOnly)) || !this.objSettings.defaultPasswordExist)) {

        if (!this.objLoggedUserDetails.getWebclientUseXSI()) {
          this.showPersonalWallboard = true;
          this.calcStatWidth.next();
        }
        return;
      }
    }

    //false and remove next()

    this.showPersonalWallboard = false;

    // this.calcStatWidth.next();

  }
  calculateIndexOfPW() {
    this.pwCallcenterIndex = [];
    this.statistics.forEach((element, index) => {
      this.pwCallcenterIndex[element.queueID] = index;
    });
  }
  //agent phase 3

  loadDeveloperLanguageTags() {
    this.defaultLanguageCollection['About.32bit'] = '32-bit';
    this.defaultLanguageCollection['About.64bit'] = '64-bit';
    this.defaultLanguageCollection['About.CurrentlyRunningOn'] = 'Currently running on';
    this.defaultLanguageCollection['About.ImportantNotice'] = 'Important information';
    this.defaultLanguageCollection['About.ImportantNotice.Body'] = 'This software and its associated documentation are the copyright of Kakapo Systems Limited. The use of this software is governed by the Licence Agreement accompanying this software.';
    this.defaultLanguageCollection['About.LicensedMacAddress'] = 'Licensed MAC address';
    this.defaultLanguageCollection['About.LicenseType'] = 'License type';
    this.defaultLanguageCollection['About.LicenseType.Expires'] = 'expires';
    this.defaultLanguageCollection['About.LicenseType.NoExpiry'] = 'no expiry';
    this.defaultLanguageCollection['About.LicenseType.Unknown'] = 'unknown';
    this.defaultLanguageCollection['About.Notice'] = 'This software and its associated documentation are the copyright of Kakapo Systems Limited. The use of this software is governed by the Licence Agreement accompanying this software.';
    this.defaultLanguageCollection['About.OperatingSystem'] = 'Operating system';
    this.defaultLanguageCollection['About.RunningAsAdmin'] = 'Running as administrator';
    this.defaultLanguageCollection['About.RunningOn'] = 'Running on';
    this.defaultLanguageCollection['About.Text'] = 'About';
    this.defaultLanguageCollection['About.UnityProcess'] = 'Unity process';
    this.defaultLanguageCollection['About.Version'] = 'Version';
    this.defaultLanguageCollection['About.WorkingFolder'] = 'Working folder';
    this.defaultLanguageCollection['AgentActivity.Agents.JoinAll'] = 'Force this agent to join all call centers';
    this.defaultLanguageCollection['AgentActivity.Agents.LeaveAll'] = 'Force this agent to leave all call centers';
    this.defaultLanguageCollection['AgentActivity.Export'] = 'Export statistics to CSV file';
    this.defaultLanguageCollection['AgentActivity.Queues.JoinAll'] = 'Force these agents to join the call center';
    this.defaultLanguageCollection['AgentActivity.Queues.LeaveAll'] = 'Force these agents to leave the call center';
    this.defaultLanguageCollection['AgentActivity.Refresh'] = 'Refresh statistics';
    this.defaultLanguageCollection['CallButtonShowing.AgentAvailable'] = 'Available';
    this.defaultLanguageCollection['CallButtonShowing.AgentSignIn'] = 'Sign-In';
    this.defaultLanguageCollection['CallButtonShowing.AgentSignOut'] = 'Sign-Out';
    this.defaultLanguageCollection['CallButtonShowing.AgentUnavailable'] = 'Unavailable';
    this.defaultLanguageCollection['CallButtonShowing.AgentWrapUp'] = 'Wrap-Up';
    this.defaultLanguageCollection['CallButtonShowing.Answer'] = 'Answer';
    this.defaultLanguageCollection['CallButtonShowing.CallParkRetrieve'] = 'Pickup Call';
    this.defaultLanguageCollection['CallButtonShowing.CallParkRetrieve.ToolTip'] = 'Pickup the call from';
    this.defaultLanguageCollection['CallButtonShowing.CallRetrieve'] = 'Call Pull';
    this.defaultLanguageCollection['CallButtonShowing.Cancel'] = 'Cancel';
    this.defaultLanguageCollection['CallButtonShowing.Conference'] = 'Conference';
    this.defaultLanguageCollection['CallButtonShowing.Dial'] = 'Dial';
    this.defaultLanguageCollection['CallButtonShowing.Dial.ToolTip'] = 'Right-click to dial recent numbers';
    this.defaultLanguageCollection['CallButtonShowing.Down'] = 'Move down';
    this.defaultLanguageCollection['CallButtonShowing.GroupCallPark'] = 'Call Park';
    this.defaultLanguageCollection['CallButtonShowing.GroupPaging'] = 'Announcement';
    this.defaultLanguageCollection['CallButtonShowing.Hold'] = 'Hold';
    this.defaultLanguageCollection['CallButtonShowing.Minus'] = 'Remove';
    this.defaultLanguageCollection['CallButtonShowing.Next'] = 'Next';
    this.defaultLanguageCollection['CallButtonShowing.OK'] = 'OK';
    this.defaultLanguageCollection['CallButtonShowing.Plus'] = 'Add';
    this.defaultLanguageCollection['CallButtonShowing.Recording'] = 'Recording';
    this.defaultLanguageCollection['CallButtonShowing.Recording.Pause'] = 'Pause recording';
    this.defaultLanguageCollection['CallButtonShowing.Recording.Start.NotAllowed'] = 'The call recording option prevents calls from being recorded';
    this.defaultLanguageCollection['CallButtonShowing.Recording.Stop'] = 'Stop recording';
    this.defaultLanguageCollection['CallButtonShowing.Recording.Stop.NotAllowed'] = 'The call recording option prevents call recording being stopped';
    this.defaultLanguageCollection['CallButtonShowing.Recording.StopPause.NotAllowed'] = 'The call recording option prevents call recording being stopped or paused';
    this.defaultLanguageCollection['CallButtonShowing.Refresh'] = 'Refresh';
    this.defaultLanguageCollection['CallButtonShowing.Release'] = 'Release';
    this.defaultLanguageCollection['CallButtonShowing.Reports'] = 'Reports';
    this.defaultLanguageCollection['CallButtonShowing.Retrieve'] = 'Retrieve';
    this.defaultLanguageCollection['CallButtonShowing.Run'] = 'Run';
    this.defaultLanguageCollection['CallButtonShowing.Search'] = 'Search';
    this.defaultLanguageCollection['CallButtonShowing.SendToVM'] = 'Voicemail';
    this.defaultLanguageCollection['CallButtonShowing.Settings'] = 'Settings';
    this.defaultLanguageCollection['CallButtonShowing.Test'] = 'Test';
    this.defaultLanguageCollection['CallButtonShowing.Test.Fail'] = 'The CRM connection test failed because of an unknown error. Please refer to the error log for further details';
    this.defaultLanguageCollection['CallButtonShowing.Test.FailAuthentication'] = 'The CRM conntection test failed because Unity wasn\'t able to login with the details provided. The error message is below';
    this.defaultLanguageCollection['CallButtonShowing.Test.License'] = 'Testing CRM connection';
    this.defaultLanguageCollection['CallButtonShowing.Test.Success'] = 'The CRM connection test succeeded';
    this.defaultLanguageCollection['CallButtonShowing.Transfer'] = 'Transfer';
    this.defaultLanguageCollection['CallButtonShowing.Transfer.ToNumber'] = 'to number';
    this.defaultLanguageCollection['CallButtonShowing.Transfer.ToolTip'] = 'Transfer call to';
    this.defaultLanguageCollection['CallButtonShowing.Up'] = 'Move up';
    this.defaultLanguageCollection['CallCenter.LoginStatus'] = 'Login status';
    this.defaultLanguageCollection['CallCenter.ShowInPersonalWallboard'] = 'Show in personal wallboard';
    this.defaultLanguageCollection['Color.Black'] = 'Black';
    this.defaultLanguageCollection['Color.Green'] = 'Green';
    this.defaultLanguageCollection['Color.Red'] = 'Red';
    this.defaultLanguageCollection['Color.Yellow'] = 'Yellow';
    this.defaultLanguageCollection['Conversation.GoneOffline'] = 'is offline';
    this.defaultLanguageCollection['Conversation.HasGoneOffline'] = 'has gone offline';
    this.defaultLanguageCollection['Conversation.HasJoined'] = 'has joined the conversation';
    this.defaultLanguageCollection['Conversation.HasLeft'] = 'has left the conversation';
    this.defaultLanguageCollection['Conversation.LastMessageReceivedAt'] = 'Last message recieved at';
    this.defaultLanguageCollection['Conversation.Log.Date'] = 'Date';
    this.defaultLanguageCollection['Conversation.Log.Message'] = 'Message';
    this.defaultLanguageCollection['Conversation.Log.Time'] = 'Time';
    this.defaultLanguageCollection['Conversation.Menu.AddNumberFromPersonalDirectory'] = 'Add number from personal directory';
    this.defaultLanguageCollection['Conversation.Menu.Call'] = 'Call conversation recipients';
    this.defaultLanguageCollection['Conversation.Menu.Close'] = 'Close the window';
    this.defaultLanguageCollection['Conversation.Menu.Collapse'] = 'Dock conversation in main window';
    this.defaultLanguageCollection['Conversation.Menu.Copy'] = 'Copy';
    this.defaultLanguageCollection['Conversation.Menu.ManageRecipients'] = 'Add or remove conversation recipients';
    this.defaultLanguageCollection['Conversation.Menu.Paste'] = 'Paste';
    this.defaultLanguageCollection['Conversation.Menu.Send'] = 'Send instant message';
    this.defaultLanguageCollection['Conversation.NoOnlineUsers'] = 'No online users';
    this.defaultLanguageCollection['Conversation.System'] = 'System';
    this.defaultLanguageCollection['Conversation.Text'] = 'Conversation';
    this.defaultLanguageCollection['List.AbandonedCall.AssignedBy'] = 'Assigned By';
    this.defaultLanguageCollection['List.AbandonedCall.AssignedDate'] = 'Assigned Date';
    this.defaultLanguageCollection['List.AbandonedCall.AssignedTo'] = 'Assigned To';
    this.defaultLanguageCollection['List.AbandonedCall.DNIS'] = 'DNIS';
    this.defaultLanguageCollection['List.CallLogs.Column.CallDate'] = 'Call Date';
    this.defaultLanguageCollection['List.CurrentCalls.Column.Duration'] = 'Duration';
    this.defaultLanguageCollection['List.CurrentCalls.Column.Notes'] = 'Notes';
    this.defaultLanguageCollection['List.CurrentCalls.Item.AbandonedAt'] = 'Abandoned At';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Active'] = 'Active';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ActiveConference'] = 'Active (conference)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ActiveRedirection'] = 'Active (redirection)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ActiveSilentMonitor'] = 'Active (silent monitor)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Camped'] = 'Call camped';
    this.defaultLanguageCollection['List.CurrentCalls.Item.CampedAndRinging'] = 'Call camped (ringing)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.CampedFor'] = 'Call camped for';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Held'] = 'On hold';
    this.defaultLanguageCollection['List.CurrentCalls.Item.HeldConference'] = 'On hold (conference)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.HeldSilentMonitor'] = 'On hold (silent monitor)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.NotRecording'] = 'Not recording';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Parked'] = 'Call parked';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ParkedAndRinging'] = 'Call parked (ringing)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ParkedFor'] = 'Call parked for';
    this.defaultLanguageCollection['List.CurrentCalls.Item.ParkedOnExtension'] = 'Call parked on extension';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Paused'] = 'Recording paused';
    this.defaultLanguageCollection['List.CurrentCalls.Item.PreviouslyAbandoned'] = 'Previously abandoned';
    this.defaultLanguageCollection['List.CurrentCalls.Item.QueuedAtPosition'] = 'Queued at position';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Recording'] = 'Recording';
    this.defaultLanguageCollection['List.CurrentCalls.Item.RingingRedirection'] = 'Ringing (redirection)';
    this.defaultLanguageCollection['List.CurrentCalls.Item.TransferredTo'] = 'Previously transferred to';
    this.defaultLanguageCollection['List.CurrentCalls.Item.Unknown'] = 'Unknown';
    this.defaultLanguageCollection['List.Directories.Column.BusinessName'] = 'Business Name';
    this.defaultLanguageCollection['List.Directories.Column.BusinessPhone'] = 'Business Phone';
    this.defaultLanguageCollection['List.Directories.Column.Extension'] = 'Extension';
    this.defaultLanguageCollection['List.Directories.Column.HomePhone'] = 'Home Phone';
    this.defaultLanguageCollection['List.Directories.Column.Mobile'] = 'Mobile';
    this.defaultLanguageCollection['List.Directories.Column.Source'] = 'Source';
    this.defaultLanguageCollection['List.Statistics.CallCenter'] = 'Call Center';
    this.defaultLanguageCollection['List.Statistics.Collapse'] = 'Collapse statistics list';
    this.defaultLanguageCollection['List.Statistics.Column.AbandonedCallsPercentage'] = 'Abandoned Calls %';
    this.defaultLanguageCollection['List.Statistics.Column.AcdState'] = 'ACD State';
    this.defaultLanguageCollection['List.Statistics.Column.AcdStateDuration'] = 'ACD Duration';
    this.defaultLanguageCollection['List.Statistics.Column.AcdStateStartDateTime'] = 'ACD Start Time';
    this.defaultLanguageCollection['List.Statistics.Column.AnsweredCalls'] = 'Answered Calls';
    this.defaultLanguageCollection['List.Statistics.Column.AnsweredCallsPercentage'] = 'Answered Calls %';
    this.defaultLanguageCollection['List.Statistics.Column.AverageHandlingTime'] = 'Average Handling Time';
    this.defaultLanguageCollection['List.Statistics.Column.AverageNumberOfAgentsStaffed'] = 'Average Agents Staffed';
    this.defaultLanguageCollection['List.Statistics.Column.AverageNumberOfAgentsTalking'] = 'Average Agents Talking';
    this.defaultLanguageCollection['List.Statistics.Column.AverageSpeedOfAnswer'] = 'Average Answer Speed';
    this.defaultLanguageCollection['List.Statistics.Column.AverageTalkTime'] = 'Average Talk Time';
    this.defaultLanguageCollection['List.Statistics.Column.AverageWaitTime'] = 'Average Wait Time';
    this.defaultLanguageCollection['List.Statistics.Column.CallsInQueue'] = 'Calls In Queue';
    this.defaultLanguageCollection['List.Statistics.Column.CurrentCallDuration'] = 'Current Call Duration';
    this.defaultLanguageCollection['List.Statistics.Column.ExpectedWaitTime'] = 'Expected Wait Time';
    this.defaultLanguageCollection['List.Statistics.Column.JoinedState'] = 'Queue State';
    this.defaultLanguageCollection['List.Statistics.Column.JoinedState.Joined'] = 'Joined';
    this.defaultLanguageCollection['List.Statistics.Column.JoinedState.NotJoined'] = 'Not Joined';
    this.defaultLanguageCollection['List.Statistics.Column.LongestWaitTime'] = 'Longest Wait Time';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfAgentsAssigned'] = 'Agents Assigned';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfAgentsStaffed'] = 'Agents Staffed';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsAbandoned'] = 'Calls Abandoned';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsTimedout'] = 'Calls Timedout';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsTimedoutPercentage'] = 'Calls Timedout %';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfStaffedAgentsIdle'] = 'Staffed & Idle Agents';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfStaffedAgentsUnavailable'] = 'Staffed & Unavailable Agents';
    this.defaultLanguageCollection['List.Statistics.Column.StaffRatio'] = 'Staff Ratio';
    this.defaultLanguageCollection['List.Statistics.Column.TotalCalls'] = 'Total Calls';
    this.defaultLanguageCollection['List.Statistics.Column.TotalStaffedTime'] = 'Total Staffed Time';
    this.defaultLanguageCollection['List.Statistics.Expand'] = 'Expand statistics list';
    this.defaultLanguageCollection['List.Statistics.MyStatistics'] = 'My Statistics';
    this.defaultLanguageCollection['List.Statistics.OverallStatistics'] = 'Overall Queue Statistics';
    this.defaultLanguageCollection['List.Statistics.RemoteParty'] = 'Remote Party';
    this.defaultLanguageCollection['List.Statistics.Summary'] = 'Summary';
    this.defaultLanguageCollection['List.UserStatus.Column.Department'] = 'Department';
    this.defaultLanguageCollection['List.UserStatus.Column.UserName'] = 'User Name';
    this.defaultLanguageCollection['List.UserStatus.State.AcdStateIs'] = 'ACD state is';
    this.defaultLanguageCollection['List.UserStatus.State.AllCallsForwardTo'] = 'All calls forward to';
    this.defaultLanguageCollection['List.UserStatus.State.Away'] = 'Currently away';
    this.defaultLanguageCollection['List.UserStatus.State.BusyProfileActivated'] = 'Currently busy';
    this.defaultLanguageCollection['List.UserStatus.State.DoNotDisturbActivated'] = 'Do not disturb';
    this.defaultLanguageCollection['List.UserStatus.State.Meeting'] = 'In a meeting';
    this.defaultLanguageCollection['List.UserStatus.State.OnThePhone'] = 'On the phone';
    this.defaultLanguageCollection['List.UserStatus.State.TalkingTo'] = 'Talking to';
    this.defaultLanguageCollection['List.UserStatus.State.UnavailableProfileActivated'] = 'Currently unavailable';
    this.defaultLanguageCollection['ManageUsers.ContactList.ToolTip'] = 'Users that are available for selection. Double click the user, department or group to move';
    this.defaultLanguageCollection['ManageUsers.NoAgentsAssigned'] = 'No agents assigned';
    this.defaultLanguageCollection['ManageUsers.NoOnlineUsers'] = 'No users are online';
    this.defaultLanguageCollection['ManageUsers.NoUsersToMonitor'] = 'No users available to monitor';
    this.defaultLanguageCollection['ManageUsers.Prompt.ConversationMinimum'] = 'At least one user must be selected for the instant message conversation';
    this.defaultLanguageCollection['ManageUsers.Prompt.MonitoredListMaximum.Exceeded'] = 'The maximum number of users that can be monitered has been exceeded. Please remove one or more users to continue';
    this.defaultLanguageCollection['ManageUsers.Prompt.MonitoredListMaximum.Selected'] = 'The maximum number of users have already been selected';
    this.defaultLanguageCollection['ManageUsers.Prompt.MonitoredListMinimum'] = 'At least one user must be included in the monitered user list';
    this.defaultLanguageCollection['ManageUsers.Prompt.SelectedUsers'] = 'There are users selected but not moved, are you sure you want to close?';
    this.defaultLanguageCollection['ManageUsers.Remaining'] = 'remaining';
    this.defaultLanguageCollection['ManageUsers.Search.ToolTip'] = 'Search for users or departments within the company';
    this.defaultLanguageCollection['ManageUsers.SelectedContactList.ToolTip'] = 'Currently selected users. Double click the user, department or group to move';
    this.defaultLanguageCollection['ManageUsers.ShowHierarchy'] = 'Show hierarchy';
    this.defaultLanguageCollection['ManageUsers.Text.ConversationMembers'] = 'Conversation members';
    this.defaultLanguageCollection['ManageUsers.Text.ManageUsers'] = 'Manage users';
    this.defaultLanguageCollection['ManageUsers.Text.SupervisedAgents'] = 'Supervised agents';
    this.defaultLanguageCollection['ManageUsers.TooMany'] = 'too many';
    this.defaultLanguageCollection['Menu.Context.AbandonedCall.MarkAsProcessed'] = 'Mark as processed';
    this.defaultLanguageCollection['Menu.Context.AccountCode.AssignAllCalls'] = 'Assign all calls to account code';
    this.defaultLanguageCollection['Menu.Context.AccountCode.AssignCall'] = 'Assign call to account code';
    this.defaultLanguageCollection['Menu.Context.AddCallNote'] = 'Add call note';
    this.defaultLanguageCollection['Menu.Context.AddCrmCallLog'] = 'Add CRM call log entry';
    this.defaultLanguageCollection['Menu.Context.AddNote'] = 'Add note';
    this.defaultLanguageCollection['Menu.Context.AddToDefaultList'] = 'Add to default list';
    this.defaultLanguageCollection['Menu.Context.AddToDefaultList.Overwrite'] = 'There is already a contact in the default list with this number. Would you like to over-write it?';
    this.defaultLanguageCollection['Menu.Context.AlwaysShowInDefaultList'] = 'Always show in default list';
    this.defaultLanguageCollection['Menu.Context.AlwaysShowInDefaultList.Maximum'] = 'The maximum number of users are flagged as always to be displayed';
    this.defaultLanguageCollection['Menu.Context.AnswerCall'] = 'Answer call';
    this.defaultLanguageCollection['Menu.Context.Appearance.ChangeView'] = 'Change view';
    this.defaultLanguageCollection['Menu.Context.Appearance.ChangeView.Icon'] = 'Icon';
    this.defaultLanguageCollection['Menu.Context.Appearance.ChangeView.List'] = 'List';
    this.defaultLanguageCollection['Menu.Context.Appearance.ResetColumnWidths'] = 'Reset column widths';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowAcdState'] = 'Show ACD state';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowCallDuration'] = 'Show call duration';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowDepartment'] = 'Show department';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowExtension'] = 'Show extension';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowPhoneNumber'] = 'Show phone number';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowSource'] = 'Show directory source';
    this.defaultLanguageCollection['Menu.Context.AssignForCallback'] = 'Assign for callback';
    this.defaultLanguageCollection['Menu.Context.AssignToMe'] = 'Assign to me';
    this.defaultLanguageCollection['Menu.Context.BargeIn'] = 'Barge into call';
    this.defaultLanguageCollection['Menu.Context.Call'] = 'Call';
    this.defaultLanguageCollection['Menu.Context.CallBusinessPhone'] = 'Call business phone';
    this.defaultLanguageCollection['Menu.Context.CallExtension'] = 'Call extension';
    this.defaultLanguageCollection['Menu.Context.CallExtensionWhenFree'] = 'Call number when available';
    this.defaultLanguageCollection['Menu.Context.CallHomePhone'] = 'Call home phone';
    this.defaultLanguageCollection['Menu.Context.CallMobile'] = 'Call mobile';
    this.defaultLanguageCollection['Menu.Context.CallNumber'] = 'Call number';
    this.defaultLanguageCollection['Menu.Context.CampOnExtension'] = 'Camp call on extension';
    this.defaultLanguageCollection['Menu.Context.ChangeOutboundNumber'] = 'Change outbound number';
    this.defaultLanguageCollection['Menu.Context.ContactGroup'] = 'Contact Group';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup'] = 'Add to contact group';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.All'] = 'All contact groups';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.Duplicate'] = 'A contact group with this name already exists';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.Exists'] = 'This contact already exists in the contact group';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.LimitReached'] = 'This contact group already contains 50 internal users';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.Missing'] = 'The contact group name must be entered';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.New'] = 'New contact group';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.AddToContactGroup.New.Max'] = 'A maximum of 12 contact groups can be created';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.Delete.Confirm'] = 'Are you sure you want to delete this contact group?';
    this.defaultLanguageCollection['Menu.Context.ContactGroup.RemoveFromContactGroup'] = 'Remove from contact group';
    this.defaultLanguageCollection['Menu.Context.CopyMobile'] = 'Copy mobile';
    this.defaultLanguageCollection['Menu.Context.CopyNumber'] = 'Copy number';
    this.defaultLanguageCollection['Menu.Context.Delete'] = 'Delete';
    this.defaultLanguageCollection['Menu.Context.Directory.Entire'] = 'Entire Directory';
    this.defaultLanguageCollection['Menu.Context.DispositionCode'] = 'Assign disposition code';
    this.defaultLanguageCollection['Menu.Context.DispositionCode.LastCall'] = 'Last call between';
    this.defaultLanguageCollection['Menu.Context.DispositionCode.LastCall.FullText'] = 'Assign disposition code to previous call between';
    this.defaultLanguageCollection['Menu.Context.DispositionCode.SelectedCall'] = 'Selected call';
    this.defaultLanguageCollection['Menu.Context.ForwardAllCalls'] = 'Forward all calls';
    this.defaultLanguageCollection['Menu.Context.ForwardCallsToAnother'] = 'Forward calls to another number';
    this.defaultLanguageCollection['Menu.Context.ForwardCallsToMe'] = 'Forward calls to me';
    this.defaultLanguageCollection['Menu.Context.ForwardCallsToMobile'] = 'Forward calls to mobile';
    this.defaultLanguageCollection['Menu.Context.InstantMessage'] = 'Instant message';
    this.defaultLanguageCollection['Menu.Context.InstantMessage.OnlineOffline'] = 'Offline users cannot be part of an instant message conversation with online users';
    this.defaultLanguageCollection['Menu.Context.ManageUserList'] = 'Manage user list';
    this.defaultLanguageCollection['Menu.Context.MarkAsProcessed'] = 'Mark as processed';
    this.defaultLanguageCollection['Menu.Context.OutlookContact.Show'] = 'Show Outlook contact';
    this.defaultLanguageCollection['Menu.Context.OutlookContact.ShowFolder'] = 'Show Outlook contact folder';
    this.defaultLanguageCollection['Menu.Context.OutOfOfficeAssistant.DeleteStatus'] = 'Delete Status';
    this.defaultLanguageCollection['Menu.Context.ParkOnExtension'] = 'Park call on extension';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory'] = 'Personal directory';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Add'] = 'Add new entry';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.AddTo'] = 'Add to personal directory';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Edit'] = 'Edit';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Export'] = 'Export directory';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Export.NoEntries'] = 'There are no entries in the personal directory';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Export.Successful'] = 'Export successful';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Import'] = 'Import directory';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Import.NoEntries'] = 'There are no entries to import';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Import.Successful'] = 'Import successful';
    this.defaultLanguageCollection['Menu.Context.PersonalDirectory.Remove.Confirm'] = 'Are you sure you want to delete this entry?';
    this.defaultLanguageCollection['Menu.Context.Play'] = 'Play';
    this.defaultLanguageCollection['Menu.Context.PromoteCall'] = 'Promote call';
    this.defaultLanguageCollection['Menu.Context.Queue.ActivateForcedForwarding'] = 'Activate forced forwarding';
    this.defaultLanguageCollection['Menu.Context.Queue.Call'] = 'Call queue';
    this.defaultLanguageCollection['Menu.Context.Queue.DeactivateForcedForwarding'] = 'Deactivate forced forwarding';
    this.defaultLanguageCollection['Menu.Context.Queue.InstantConference'] = 'Instant conference';
    this.defaultLanguageCollection['Menu.Context.Queue.InstantMessageSupervisedAgents'] = 'Instant message all supervised agents';
    this.defaultLanguageCollection['Menu.Context.Queue.Join'] = 'Join queue';
    this.defaultLanguageCollection['Menu.Context.Queue.Leave'] = 'Leave queue';
    this.defaultLanguageCollection['Menu.Context.Queue.ModifySupervisedAgentList'] = 'Modify supervised agent list';
    this.defaultLanguageCollection['Menu.Context.Queue.Refresh'] = 'Refresh statistics';
    this.defaultLanguageCollection['Menu.Context.Queue.RemoveFromPersonalWallboard'] = 'Remove from personal wallboard';
    this.defaultLanguageCollection['Menu.Context.Queue.ShowActivity'] = 'Show agent activity';
    this.defaultLanguageCollection['Menu.Context.Queue.Supervisors'] = 'Supervisors';
    this.defaultLanguageCollection['Menu.Context.Queue.Transfer'] = 'Transfer call to queue';
    this.defaultLanguageCollection['Menu.Context.ReleaseCall'] = 'Release call';
    this.defaultLanguageCollection['Menu.Context.RemoveFromDefaultList'] = 'Remove from default list';
    this.defaultLanguageCollection['Menu.Context.RemoveFromDefaultList.Confirm'] = 'Are you sure you want to remove this contact from default list?';
    this.defaultLanguageCollection['Menu.Context.RemoveNote'] = 'Remove note';
    this.defaultLanguageCollection['Menu.Context.Save'] = 'Save';
    this.defaultLanguageCollection['Menu.Context.SendEmail'] = 'Send email';
    this.defaultLanguageCollection['Menu.Context.ServiceConfiguration'] = 'Service configuration';
    this.defaultLanguageCollection['Menu.Context.ShowCalendar'] = 'Show calendar';
    this.defaultLanguageCollection['Menu.Context.ShowCrmContact'] = 'Show CRM contact';
    this.defaultLanguageCollection['Menu.Context.SilentMonitor'] = 'Silent monitor';
    this.defaultLanguageCollection['Menu.Context.SilentMonitor.All'] = 'All calls';
    this.defaultLanguageCollection['Menu.Context.SilentMonitor.Current'] = 'Current call';
    this.defaultLanguageCollection['Menu.Context.SilentMonitor.Next'] = 'Next call only';
    this.defaultLanguageCollection['Menu.Context.Transfer'] = 'Transfer';
    this.defaultLanguageCollection['Menu.Context.Transfer.ToAgent'] = 'To agent';
    this.defaultLanguageCollection['Menu.Context.Transfer.ToQueue'] = 'To queue';
    this.defaultLanguageCollection['Menu.Context.TransferTo'] = 'Transfer call to';
    this.defaultLanguageCollection['Menu.Context.TransferToExtension'] = 'Transfer call to extension';
    this.defaultLanguageCollection['Menu.Context.TransferTogether'] = 'Transfer calls together (leave conference)';
    this.defaultLanguageCollection['Menu.Context.TransferTogetherNoConference'] = 'Transfer calls together';
    this.defaultLanguageCollection['Menu.Context.TransferToMobile'] = 'Transfer call to mobile';
    this.defaultLanguageCollection['Menu.Context.TransferToNumber'] = 'Transfer call to number';
    this.defaultLanguageCollection['Menu.Context.TransferToVoicemail'] = 'Transfer call to voicemail';
    this.defaultLanguageCollection['Menu.Context.Unassign'] = 'Unassign';
    this.defaultLanguageCollection['Menu.Context.ViewUserDetails'] = 'View user details';
    this.defaultLanguageCollection['Menu.Context.WaitAndSee'] = 'Hold call and link to user';
    this.defaultLanguageCollection['Menu.Context.WaitAndSee.Cancel'] = 'Remove link to user';
    this.defaultLanguageCollection['Menu.Context.WaitAndSee.ExistingCall'] = 'There is already a call waiting for this user from';
    this.defaultLanguageCollection['Menu.Context.WaitAndSee.Waiting'] = 'Waiting for';
    this.defaultLanguageCollection['Menu.Context.WarmTransferToExtension'] = 'Warm transfer call to extension';
    this.defaultLanguageCollection['Menu.Context.WarmTransferToMobile'] = 'Warm transfer call to mobile';
    this.defaultLanguageCollection['Menu.Context.WarmTransferToNumber'] = 'Warm transfer call to number';
    this.defaultLanguageCollection['Menu.Context.XMPP.AddSubscription'] = 'Add XMPP presence';
    this.defaultLanguageCollection['Menu.Context.XMPP.RemoveSubscription'] = 'Remove XMPP presence';
    this.defaultLanguageCollection['Menu.File'] = 'File';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ActivateSearchOnCallAnswer'] = 'Activate contact search box when call answered';
    this.defaultLanguageCollection['Menu.File.Close'] = 'Close';
    this.defaultLanguageCollection['Menu.File.Restart'] = 'Restart';
    this.defaultLanguageCollection['Menu.Help'] = 'Help';
    this.defaultLanguageCollection['Menu.Help.AboutUnity'] = 'About';
    this.defaultLanguageCollection['Menu.Help.Contents'] = 'Contents';
    this.defaultLanguageCollection['Menu.Help.ExportRegistry'] = 'Export Unity settings';
    this.defaultLanguageCollection['Menu.Help.GetRemoteAssistance'] = 'Get remote assistance';
    this.defaultLanguageCollection['Menu.Help.PurchaseLicense'] = 'Purchase license';
    this.defaultLanguageCollection['Menu.Messaging'] = 'Messaging';
    this.defaultLanguageCollection['Menu.Messaging.NotCurrentlyAvailable'] = 'Instant messaging is not available as Unity is attempting to connect to the messaging server';
    this.defaultLanguageCollection['Menu.Messaging.StartConversation'] = 'Start conversation';
    this.defaultLanguageCollection['Menu.MyStatus'] = 'My status:';
    this.defaultLanguageCollection['Menu.MyStatus.Activate'] = 'Activate';
    this.defaultLanguageCollection['Menu.MyStatus.Configure'] = 'Configure';
    this.defaultLanguageCollection['Menu.MyStatus.ConnectToADevice'] = 'Connect to a device';
    this.defaultLanguageCollection['Menu.MyStatus.ConnectToADevice.Refresh'] = 'Refresh';
    this.defaultLanguageCollection['Menu.MyStatus.Deactivate'] = 'Deactivate';
    this.defaultLanguageCollection['Menu.MyStatus.InOffice'] = 'In office';
    this.defaultLanguageCollection['Menu.MyStatus.ToolTip'] = 'Click here to change your current status';
    this.defaultLanguageCollection['Menu.Tools'] = 'Tools';
    this.defaultLanguageCollection['Menu.Tools.CallCenters'] = 'Call centers';
    this.defaultLanguageCollection['Menu.Tools.CallCenters.AgentCannotJoinOrLeaveQueue'] = 'Agent cannot join or leave this queue';
    this.defaultLanguageCollection['Menu.Tools.CallCenters.AgentOnly'] = 'Agent only';
    this.defaultLanguageCollection['Menu.Tools.CustomDirectory'] = 'Custom directory';
    this.defaultLanguageCollection['Menu.Tools.CustomDirectory.CreateNew'] = 'Create New';
    this.defaultLanguageCollection['Misc.Account'] = 'Account';
    this.defaultLanguageCollection['Misc.Agents'] = 'Agents';
    this.defaultLanguageCollection['Misc.All'] = 'All';
    this.defaultLanguageCollection['Misc.And'] = '&';
    this.defaultLanguageCollection['Misc.Appearance'] = 'Appearance';
    this.defaultLanguageCollection['Misc.At'] = 'at';
    this.defaultLanguageCollection['Misc.Business'] = 'Business';
    this.defaultLanguageCollection['Misc.Calls'] = 'calls';
    this.defaultLanguageCollection['Misc.Car'] = 'Car';
    this.defaultLanguageCollection['Misc.Column'] = 'Column';
    this.defaultLanguageCollection['Misc.Company'] = 'Company';
    this.defaultLanguageCollection['Misc.Configuration'] = 'Configuration';
    this.defaultLanguageCollection['Misc.Contact'] = 'Contact';
    this.defaultLanguageCollection['Misc.Current'] = 'Current';
    this.defaultLanguageCollection['Misc.CurrentValue'] = 'Current Value';
    this.defaultLanguageCollection['Misc.Days'] = 'days';
    this.defaultLanguageCollection['Misc.Default'] = 'Default';
    this.defaultLanguageCollection['Misc.Delete'] = 'Delete';
    this.defaultLanguageCollection['Misc.Edit'] = 'Edit';
    this.defaultLanguageCollection['Misc.Every'] = 'every';
    this.defaultLanguageCollection['Misc.For'] = 'for';
    this.defaultLanguageCollection['Misc.From'] = 'From';
    this.defaultLanguageCollection['Misc.Home'] = 'Home';
    this.defaultLanguageCollection['Misc.Hours'] = 'Hours';
    this.defaultLanguageCollection['Misc.Ignore'] = 'Ignore';
    this.defaultLanguageCollection['Misc.InvalidNumber'] = 'Either a number was not entered, or the number entered is not valid.';
    this.defaultLanguageCollection['Misc.InvalidPhoneNumber'] = 'Either a phone number was not entered, or the phone number entered is not valid.';
    this.defaultLanguageCollection['Misc.Later'] = 'Later';
    this.defaultLanguageCollection['Misc.Lead'] = 'Lead';
    this.defaultLanguageCollection['Misc.Maximum'] = 'Maximum';
    this.defaultLanguageCollection['Misc.Minutes'] = 'minutes';
    this.defaultLanguageCollection['Misc.Mobile'] = 'Mobile';
    this.defaultLanguageCollection['Misc.Name'] = 'Name';
    this.defaultLanguageCollection['Misc.New'] = 'New';
    this.defaultLanguageCollection['Misc.None'] = 'None';
    this.defaultLanguageCollection['Misc.NotSet'] = 'Not Set';
    this.defaultLanguageCollection['Misc.Number'] = 'Number';
    this.defaultLanguageCollection['Misc.Offline'] = 'Offline';
    this.defaultLanguageCollection['Misc.Older'] = 'Older';
    this.defaultLanguageCollection['Misc.Online'] = 'Online';
    this.defaultLanguageCollection['Misc.Other'] = 'Other';
    this.defaultLanguageCollection['Misc.Pager'] = 'Pager';
    this.defaultLanguageCollection['Misc.Path'] = 'Path';
    this.defaultLanguageCollection['Misc.Percent'] = 'percent';
    this.defaultLanguageCollection['Misc.Phone'] = 'Phone';
    this.defaultLanguageCollection['Misc.PhoneNumber'] = 'Phone number';
    this.defaultLanguageCollection['Misc.Previous'] = 'Previous';
    this.defaultLanguageCollection['Misc.Primary'] = 'Primary';
    this.defaultLanguageCollection['Misc.Queue'] = 'Queue';
    this.defaultLanguageCollection['Misc.Queued'] = 'Queued';
    this.defaultLanguageCollection['Misc.Queues'] = 'Queues';
    this.defaultLanguageCollection['Misc.Radio'] = 'Radio';
    this.defaultLanguageCollection['Misc.Recent'] = 'Recent';
    this.defaultLanguageCollection['Misc.Refresh'] = 'Refresh';
    this.defaultLanguageCollection['Misc.Remove'] = 'Remove';
    this.defaultLanguageCollection['Misc.Rename'] = 'Rename';
    this.defaultLanguageCollection['Misc.Ringing'] = 'Ringing';
    this.defaultLanguageCollection['Misc.Seconds'] = 'seconds';
    this.defaultLanguageCollection['Misc.SecondsOrMore'] = 'seconds or more';
    this.defaultLanguageCollection['Misc.Select'] = 'Select';
    this.defaultLanguageCollection['Misc.ShowGridlines'] = 'Show gridlines';
    this.defaultLanguageCollection['Misc.Status'] = 'Status';
    this.defaultLanguageCollection['Misc.Stop'] = 'Stop';
    this.defaultLanguageCollection['Misc.Template'] = 'Template';
    this.defaultLanguageCollection['Misc.ThresholdValue'] = 'Threshold Value';
    this.defaultLanguageCollection['Misc.Through'] = 'through';
    this.defaultLanguageCollection['Misc.To'] = 'To';
    this.defaultLanguageCollection['Misc.Today'] = 'Today';
    this.defaultLanguageCollection['Misc.Tomorrow'] = 'Tomorrow';
    this.defaultLanguageCollection['Misc.Type'] = 'Type';
    this.defaultLanguageCollection['Misc.Unanswered'] = 'Unanswered';
    this.defaultLanguageCollection['Misc.Unavailable'] = 'Unavailable';
    this.defaultLanguageCollection['Misc.Unknown'] = 'Unknown';
    this.defaultLanguageCollection['Misc.URL'] = 'URL';
    this.defaultLanguageCollection['Misc.URL.Invalid'] = 'The URL is invalid as it must start with either http:// or https://';
    this.defaultLanguageCollection['Misc.View'] = 'View';
    this.defaultLanguageCollection['Misc.Waiting'] = 'Waiting';
    this.defaultLanguageCollection['Misc.XMPP'] = 'XMPP';
    this.defaultLanguageCollection['Month.1'] = 'January';
    this.defaultLanguageCollection['Month.10'] = 'October';
    this.defaultLanguageCollection['Month.11'] = 'November';
    this.defaultLanguageCollection['Month.12'] = 'December';
    this.defaultLanguageCollection['Month.2'] = 'February';
    this.defaultLanguageCollection['Month.3'] = 'March';
    this.defaultLanguageCollection['Month.4'] = 'April';
    this.defaultLanguageCollection['Month.5'] = 'May';
    this.defaultLanguageCollection['Month.6'] = 'June';
    this.defaultLanguageCollection['Month.7'] = 'July';
    this.defaultLanguageCollection['Month.8'] = 'August';
    this.defaultLanguageCollection['Month.9'] = 'September';
    this.defaultLanguageCollection['Day.1'] = 'Mon';
    this.defaultLanguageCollection['Day.2'] = 'Tue';
    this.defaultLanguageCollection['Day.3'] = 'Wed';
    this.defaultLanguageCollection['Day.4'] = 'Thu';
    this.defaultLanguageCollection['Day.5'] = 'Fri';
    this.defaultLanguageCollection['Day.6'] = 'Sat';
    this.defaultLanguageCollection['Day.7'] = 'Sun';
    this.defaultLanguageCollection['OutOfOfficeAssistant'] = 'Out of office assistant';
    this.defaultLanguageCollection['OutOfOfficeAssistant.AddScheduledStatus'] = 'Add scheduled status';
    this.defaultLanguageCollection['OutOfOfficeAssistant.AllDayOn'] = 'all day on';
    this.defaultLanguageCollection['OutOfOfficeAssistant.AllDayToday'] = 'all day today.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Away'] = 'Away';
    this.defaultLanguageCollection['OutOfOfficeAssistant.AwaySick'] = 'Away sick';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Busy'] = 'Busy';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Clash'] = 'This presence status cannot be saved as it clashes with another status. Would you like to overwrite this existing status? If you click no the new status will not be saved.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Clash.WithParameters'] = 'This presence status cannot be saved as it clashes with another status [%s] which is set to run from %s until %s. Would you like to overwrite this existing status? If you click no the new status will not be saved.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.ClearCurrentStatus'] = 'Clear current status';
    this.defaultLanguageCollection['OutOfOfficeAssistant.ClearCurrentStatus.Prompt'] = 'Would you like to clear your current \'out of office\' status?';
    this.defaultLanguageCollection['OutOfOfficeAssistant.ClearScheduledStatus.Prompt'] = 'Are you sure you want to delete this scheduled status? This action cannot be undone.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.CurrentlyActive'] = 'The Out of Office Assistant is currently active. Would you like to clear this status?';
    this.defaultLanguageCollection['OutOfOfficeAssistant.End'] = 'Until';
    this.defaultLanguageCollection['OutOfOfficeAssistant.End.BeforeStart'] = 'The end time of cannot be before the start time.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.End.Invalid'] = 'The end time cannot be in the past';
    this.defaultLanguageCollection['OutOfOfficeAssistant.End.Invalid.WithParameters'] = 'The end time of %s at %s cannot be in the past';
    this.defaultLanguageCollection['OutOfOfficeAssistant.End.NotEntered'] = 'The end time must be set.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.FurtherNotice'] = 'further notice';
    this.defaultLanguageCollection['OutOfOfficeAssistant.IAm'] = 'I am';
    this.defaultLanguageCollection['OutOfOfficeAssistant.InAMeeting'] = 'In a meeting';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Is'] = 'is';
    this.defaultLanguageCollection['OutOfOfficeAssistant.On'] = 'on';
    this.defaultLanguageCollection['OutOfOfficeAssistant.OnLeave'] = 'On leave';
    this.defaultLanguageCollection['OutOfOfficeAssistant.OutOfTheOffice'] = 'Out of the office';
    this.defaultLanguageCollection['OutOfOfficeAssistant.OutToLunch'] = 'Out to lunch';
    this.defaultLanguageCollection['OutOfOfficeAssistant.PromptToClear'] = 'Would you like to clear your current \'out of office\' status?';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Start'] = 'From';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Start.Invalid'] = 'The start time cannot be in the past';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Start.Invalid.WithParameters'] = 'The start time of %s at %s cannot be in the past';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Start.NotEntered'] = 'The start time must be set.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Start.Same'] = 'The start time and the end time cannot be the same';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Status.Delete.Prompt'] = 'Are you sure you want to delete this scheduled status? This action cannot be undone.';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Today'] = 'today';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Tomorrow'] = 'tomorrow';
    this.defaultLanguageCollection['OutOfOfficeAssistant.Unavailable'] = 'Unavailable';
    this.defaultLanguageCollection['PersonalDirectory.Prompt.NameExists'] = 'There is already an entry in your personal directory with this name, duplicates are not permitted';
    this.defaultLanguageCollection['PersonalDirectory.Prompt.NameNotValid'] = 'The name is invalid, a character is not permitted';
    this.defaultLanguageCollection['PersonalDirectory.Prompt.NameNotValid.WithParameter'] = 'The name is invalid, the %s character is not permitted';
    this.defaultLanguageCollection['PersonalDirectory.Prompt.NumberExists'] = 'There is already an entry in your personal directory with this number, duplicates are not permitted';
    this.defaultLanguageCollection['PersonalDirectory.Prompt.UnityNotConnected'] = 'This operation is not available as Unity is not connected';
    this.defaultLanguageCollection['Prompt.AccountCode.Assigned'] = 'Call(s) assigned to account code';
    this.defaultLanguageCollection['Prompt.AcdStateChangesNotPermitted'] = 'The Call Center Standard or Call Center Premium user service has not been assigned meaning ACD state changes are not permitted. Would you like to change to Unity Desktop?';
    this.defaultLanguageCollection['Prompt.AcdStateChangesRequired'] = 'The Call Center Standard or Call Center Premium user service is currently assigned meaning the user must change their ACD state to receive call center calls. Would you like to change to Unity Agent?';
    this.defaultLanguageCollection['Prompt.AddCallToConference'] = 'Do you want to add this call to the conference?';
    this.defaultLanguageCollection['Prompt.AddToIMConversation'] = 'Add this user to the conversation';
    this.defaultLanguageCollection['Prompt.AllColumnsDisplayed'] = 'All available columns are already being displayed';
    this.defaultLanguageCollection['Prompt.ApplyingCompanyBrand'] = 'Applying company brand';
    this.defaultLanguageCollection['Prompt.AuthenticationDetailsIncomplete'] = 'Authentication details have not been provided or are incomplete.Please enter this information.';
    this.defaultLanguageCollection['Prompt.BasicOrEnhancedCallLogsNotAssigned'] = 'The Basic Call Logs or Enhanced Call Logs user service has not been assigned. Call history information is not available.';
    this.defaultLanguageCollection['Prompt.BroadworksVersion'] = 'Unity Desktop does not support Broadworks';
    this.defaultLanguageCollection['Prompt.CallParkRetrieve.CallParked'] = 'Call parked from';
    this.defaultLanguageCollection['Prompt.CallParkRetrieve.Pickup'] = 'Click here to pickup the call';
    this.defaultLanguageCollection['Prompt.CallPull'] = 'ActiveCallOnly';
    this.defaultLanguageCollection['Prompt.CallRecording.CouldNotConnect'] = 'Unity could not connect to the recording server';
    this.defaultLanguageCollection['Prompt.CallTransferNotAssigned'] = 'This feature is unavailable as the Call Transfer user service has not been assigned to you.';
    this.defaultLanguageCollection['Prompt.Cancel'] = 'If you click Cancel you will not be asked again.';
    this.defaultLanguageCollection['Prompt.CannotChangeAcdState'] = 'ACD state changes are not permitted';
    this.defaultLanguageCollection['Prompt.CannotChangeAcdStateOnCall'] = 'You cannot change your ACD state while on a call center call';
    this.defaultLanguageCollection['Prompt.CannotConnectToInternet'] = 'An operation cannot complete because Unity cannot connect to the internet';
    this.defaultLanguageCollection['Prompt.CheckingCurrentUnityLicense'] = 'Checking current Unity license';
    this.defaultLanguageCollection['Prompt.ClearDirectory'] = 'Clear directory';
    this.defaultLanguageCollection['Prompt.ClickToCall'] = 'Click here to call';
    this.defaultLanguageCollection['Prompt.ClientCallControlNotAssigned'] = 'The Client Call Control service has not been assigned. Unity needs this service assigned in order to function properly. Without it all application functionality will be disabled. Please contact your service provider for assistance.';
    this.defaultLanguageCollection['Prompt.CollectingVersionInformation'] = 'Collecting version information';
    this.defaultLanguageCollection['Prompt.ConnectingToBroadworks'] = 'Connecting to Broadworks';
    this.defaultLanguageCollection['Prompt.ConnectionDetailsIncomplete'] = 'Connectivity details have not been provided or are invalid. Please enter this information.';
    this.defaultLanguageCollection['Prompt.ConnectionDropped'] = 'Connection dropped. Attempting reconnection every 15 seconds';
    this.defaultLanguageCollection['Prompt.ConnectionFailed'] = 'Unity could not connect to the Broadworks server. Please check the connectivity details and contact your network administrator if problems persist';
    this.defaultLanguageCollection['Prompt.CouldNotConnectToHost'] = 'Unity could not connect to the VoIP server. Would you like to check the connection details and attempt to re-connect? If you click no Unity will close.';
    this.defaultLanguageCollection['Prompt.CreateNewTemplate'] = 'Are you sure you want to create a new template file? When all applicable settings have been changed you must click OK in this window to save the template. Please note that during this time you will not be able to change the startup application.';
    this.defaultLanguageCollection['Prompt.CreateNewTemplate.Failure'] = 'The new user template was not saved successfully. Please refer to the error log for more details';
    this.defaultLanguageCollection['Prompt.CreateNewTemplate.Override'] = 'There is already a saved template with this name. Are you sure you want to override it? If you click yes this template will be applied to users currently using the existing template';
    this.defaultLanguageCollection['Prompt.CreateNewTemplate.Success'] = 'The new user template was saved successfully';
    this.defaultLanguageCollection['Prompt.Crm.ApiNotAvailable'] = 'Unity was unable to connect to the CRM platform as the API that is required to integrate is not available with this CRM license.';
    this.defaultLanguageCollection['Prompt.Crm.AuthenticationError'] = 'Unity was unable to connect to the CRM platform using the login details provided. The error returned is below';
    this.defaultLanguageCollection['Prompt.Crm.ConnectionError'] = 'Unity was unable to connect to the CRM platform so integration will not be available. Please check the authentication details and refer to the error log for more information.';
    this.defaultLanguageCollection['Prompt.Crm.LogInboundCall'] = 'Call made to';
    this.defaultLanguageCollection['Prompt.Crm.LoginError'] = 'Unity was unable to authenticate on the CRM platform so integration will not be available. Please check the authentication details are correct and the account isn\'t locked out';
    this.defaultLanguageCollection['Prompt.Crm.LogOutboundCall'] = 'Call received from';
    this.defaultLanguageCollection['Prompt.Crm.NoLongerLicensed'] = 'CRM integration is no longer available because the applicable license is not assigned. Please contact your service provider for more information';
    this.defaultLanguageCollection['Prompt.Crm.NotLicensed'] = 'CRM integration is not available because the applicable license has not been assigned. Please contact your service provider for more information';
    this.defaultLanguageCollection['Prompt.Crm.SearchError'] = 'Unity was unable to perform a search on the CRM platform. Please check the authentication details and refer to the error log for more information.';
    this.defaultLanguageCollection['Prompt.Crm.SearchError.Authentication'] = 'Unity was unable to perform a search on the CRM platform using the login details provided. The error returned is below';
    this.defaultLanguageCollection['Prompt.Crm.TestRequired'] = 'The CRM settings have changed so a connection test must be performed before the new settings can be saved';
    this.defaultLanguageCollection['Prompt.Cti.ClickToDial.Confirm'] = 'Are you sure you want Unity to be your default application for click to dial from a web browser? This will override any existing click to dial configuration';
    this.defaultLanguageCollection['Prompt.DeactivateHotelingHost'] = 'You are currently assigned to a host device %s. Would you like to remove this assignment?';
    this.defaultLanguageCollection['Prompt.DeactivateOutboundDnis'] = 'Unity is currently configured to use an outbound DNIS number when making calls. Would you like to remove this assignment?';
    this.defaultLanguageCollection['Prompt.DeactivateRemoteOffice'] = 'Remote office is currently active, would you like to deactivate this service?';
    this.defaultLanguageCollection['Prompt.DeleteVoicemail'] = 'Are you sure you want to delete the selected voicemails?';
    this.defaultLanguageCollection['Prompt.DeleteXmppPassword'] = 'Are you sure you want to delete this password? If you click yes Unity will not attempt to reconnect to the XMPP server when restarted.';
    this.defaultLanguageCollection['Prompt.DeleteXmppPasswordWhenConnected'] = 'Are you sure you want to delete this password? If you click yes Unity will close the connection to the XMPP server and not attempt to reconnect when restarted';
    this.defaultLanguageCollection['Prompt.DialExtensionWhenFree'] = 'is now available. Would you like to call this extension? If you click No you will not be prompted to call again.';
    this.defaultLanguageCollection['Prompt.DispositionCode.NotSet'] = 'A disposition code must be selected';
    this.defaultLanguageCollection['Prompt.DownloadingBrandDetails'] = 'Downloading brand details';
    this.defaultLanguageCollection['Prompt.DownloadingLanguage'] = 'Downloading language';
    this.defaultLanguageCollection['Prompt.DownloadingUserTemplate'] = 'Downloading user template';
    this.defaultLanguageCollection['Prompt.EmergencyEscalateCall'] = 'Instant conference/emergency escalate';
    this.defaultLanguageCollection['Prompt.EmergencyEscalateCall.First'] = 'Instant conference/emergency escalate to first supervisor';
    this.defaultLanguageCollection['Prompt.EscalateCall'] = 'Call/escalate';
    this.defaultLanguageCollection['Prompt.EscalateCall.First'] = 'Call/escalate to first supervisor';
    this.defaultLanguageCollection['Prompt.ExternalCallingLineIdDeliveryNotAssigned'] = 'The External Calling Line Id Delivery user service has not been assigned. Unity uses this service to find contact details for inbound calls from outside the business';
    this.defaultLanguageCollection['Prompt.FetchingMonitoredUserDetails'] = 'Fetching monitored user details';
    this.defaultLanguageCollection['Prompt.ForcedASMigration'] = 'User account has migrated. Attempting reconnection every 15 seconds';
    this.defaultLanguageCollection['Prompt.ForcedBroadworksLogout'] = 'Unity has been forced to logout by the VoIP platform so needs to restart. However this may be caused by an underlying VoIP issue, so please contact your service provider if Unity functionality is lost after restarting';
    this.defaultLanguageCollection['Prompt.HostName'] = 'The Broadworks server could not be found. Please check the connection details';
    this.defaultLanguageCollection['Prompt.IM.ConnectionFailed'] = 'Unity could not connect to the Unity server after five attempts. Please check the connection details or contact your network administrator if problems persist.';
    this.defaultLanguageCollection['Prompt.InternalCallingLineIdDeliveryNotAssigned'] = 'The Internal Calling Line Id Delivery user service has not been assigned. Unity uses this service to find contact details for inbound calls from within the business';
    this.defaultLanguageCollection['Prompt.InternalError'] = 'An internal error occurred. Please check the log files for more information';
    this.defaultLanguageCollection['Prompt.JavaError'] = 'An internal java error has occurred, which has affected the Unity user interface. Please click OK to restart Unity and contact your service provider if problems persist.';
    this.defaultLanguageCollection['Prompt.Kemp.AddToIMConversation'] = 'Add this user to the conversation';
    this.defaultLanguageCollection['Prompt.KEMP.ConnectionFailed'] = 'Unity could not connect to the Unity server after five attempts. Please check the connection details or contact your network administrator if problems persist.';
    this.defaultLanguageCollection['Prompt.Kemp.ConnectionIsBlacklisted'] = 'Unity cannot connect to the instant messaging server because it has been black-listed, most likely because of unexpected activity. Please contact your service provider to remove the block.';
    this.defaultLanguageCollection['Prompt.Kemp.OfflineMessagingNotAvailable'] = 'Instant messages cannot currently be sent to offline users. Would you like to activate this feature?';
    this.defaultLanguageCollection['Prompt.Kemp.RegistrationOverridden'] = 'Your registration on the Unity instant messaging server has been overridden, meaning all messaging functionality has been lost. You must restart Unity to re-register on the instant messaging server with this login.';
    this.defaultLanguageCollection['Prompt.Kemp.ServerConnectionLost'] = 'The Unity server has experienced an internal error and your connection has been closed, meaning all messaging functionality has been lost. You must restart Unity to re-register on the instant messaging server with this login.';
    this.defaultLanguageCollection['Prompt.Kemp.StartNewIMConversation'] = 'Start a new conversation';
    this.defaultLanguageCollection['Prompt.Kemp.UseGroupChat'] = 'You are currently sending instant messages to this user through Unity & XMPP, but only Unity supports group chat. Do you want to remove XMPP message delivery to start group chat?\r\n\r\nPlease note this will only apply to this conversation.';
    this.defaultLanguageCollection['prompt.Licence.InvalidLicence'] = 'License response was unsuccessful, Shutting unity down.';
    this.defaultLanguageCollection['Prompt.License.CheckingCurrentUnityLicense'] = 'Checking current Unity license';
    this.defaultLanguageCollection['Prompt.License.CouldNotContactServer'] = 'Unity could not contact the licensing server, please check your network connection and consult your service provider if problems persist.  Please note that Unity must be restarted before it will attempt to fetch another license.';
    this.defaultLanguageCollection['Prompt.License.LicenseRequestFailed'] = 'An error has occured five times on the Unity licensing server while trying to process this license request, please consult your service provider. Please note that Unity must be restarted before it will attempt to fetch another license.';
    this.defaultLanguageCollection['Prompt.LoadingAbandonedCalls'] = 'Loading abandoned call log';
    this.defaultLanguageCollection['Prompt.LoadingBusinessDirectories'] = 'Loading business directories';
    this.defaultLanguageCollection['Prompt.LoadingDialedCalls'] = 'Loading dialed call log';
    this.defaultLanguageCollection['Prompt.LoadingMissedCalls'] = 'Loading missed call log';
    this.defaultLanguageCollection['Prompt.LoadingReceivedCalls'] = 'Loading received call log';
    this.defaultLanguageCollection['Prompt.LoadingServiceDetails'] = 'Loading service details';
    this.defaultLanguageCollection['Prompt.LoggingIntoBroadworks'] = 'Logging into Broadworks';
    this.defaultLanguageCollection['Prompt.Login.CallCenterFailed'] = 'Either the password entered for the call center is not valid or has expired';
    this.defaultLanguageCollection['Prompt.Login.Failed'] = 'The authentication details provided are not valid or have expired. Please check the details and try again';
    this.defaultLanguageCollection['Prompt.Login.GroupAdminFailed'] = 'The business administrator authentication details provided are not valid or have expired';
    this.defaultLanguageCollection['Prompt.Login.GroupAdminIsEndUser'] = 'You have tried to login as an administrator using an end user account. You must use a group or enterprise administrator login.';
    this.defaultLanguageCollection['Prompt.Login.MaxAttempts'] = 'The maximum number of login attempts has been reached so Unity will now close. Please check your login details with your system administrator.';
    this.defaultLanguageCollection['Prompt.Login.UserIsAdmin'] = 'You have tried to login using an administrator account which is not permitted, you must use an end-user login. Unity will now close';
    this.defaultLanguageCollection['Prompt.MonitoredUser.Failure'] = 'could not be monitored for an unknown reason. Please ensure the user exists and doesn\'t have the Privacy service enabled';
    this.defaultLanguageCollection['Prompt.MonitoredUser.Privacy'] = 'could not be monitored because the Privacy service is enabled for this user';
    this.defaultLanguageCollection['Prompt.MonitoredUser.Unauthorized'] = 'is not available to be monitored as this is not a valid user account';
    this.defaultLanguageCollection['Prompt.NetworkUnavailable'] = 'Network unavailable. Attempting reconnection every 15 seconds';
    this.defaultLanguageCollection['Prompt.NoNetworkConnectionDetected'] = 'Unity has not detected a valid network connection. Please ensure this computer is connected to a network and click Retry, otherwise click Abort to close or Ignore to continue.';
    this.defaultLanguageCollection['Prompt.NotSuperivingQueues'] = 'The Call Center Standard or Call Center Premium user service has not been assigned and you are not currently supervising any call centers. Would you like to change to Unity Desktop?';
    this.defaultLanguageCollection['Prompt.NWayCallingNotAssigned'] = 'Calls cannot be added to the conference as the N-Way Call user service has not been assigned.';
    this.defaultLanguageCollection['Prompt.OfflineMessagingNotAvailable'] = 'Instant messages cannot be sent to offline users. Would you like to activate this feature?';
    this.defaultLanguageCollection['Prompt.OperatingSystemSleep'] = 'Windows has entered sleep mode. Attempting reconnection every 15 seconds';
    this.defaultLanguageCollection['Prompt.Organization.Missing'] = 'An organization must be selected';
    this.defaultLanguageCollection['Prompt.ProFunctionality'] = 'This functionality is only available with a Unity Pro license. Please contact your service provider to upgrade.';
    this.defaultLanguageCollection['Prompt.PromptCall'] = 'Would you like to call';
    this.defaultLanguageCollection['Prompt.PromptToChangeAcdState'] = 'Unity needs to change your ACD state to Wrap-Up to assign the disposition code to the last call. It will then be changed back to the current ACD state. Can Unity proceed with this change? If you click cancel you will not be asked again.';
    this.defaultLanguageCollection['Prompt.Reconnecting'] = 'Reconnecting';
    this.defaultLanguageCollection['Prompt.RegistryExportFailed'] = 'Unity settings could not be saved, please see the error log for more information';
    this.defaultLanguageCollection['Prompt.RegistryExportSuccessful'] = 'Unity settings were successfully saved';
    this.defaultLanguageCollection['Prompt.ResponseNotReceivedFromServer'] = 'Unity was able to connect to the VoIP server but a response was not received from the server within 15 seconds. This is most likely because the login id entered is incorrect. Would you like to check the authentication details and attempt to re-connect? If you click no Unity will close.';
    this.defaultLanguageCollection['Prompt.Restart'] = 'Are you sure you want to restart Unity?';
    this.defaultLanguageCollection['Prompt.RestartAfterTemplate'] = 'Unity must restart because some settings that were saved locally have been changed through the user template';
    this.defaultLanguageCollection['Prompt.RestartRequired'] = 'Unity needs to be restarted, would you like to do this now?';
    this.defaultLanguageCollection['Prompt.ServerNotFound'] = 'The VoIP server could not be found. Please check the connection log for more details. Would you like to check the connection details and attempt to re-connect? If you click no Unity will close.';
    this.defaultLanguageCollection['Prompt.SessionExpired'] = 'Session expired. Attempting reconnection every 15 seconds';
    this.defaultLanguageCollection['Prompt.SettingsRestart'] = 'For the changes in settings to be applied you have to start unity.Are you sure that you want to continue?';
    this.defaultLanguageCollection['Prompt.Shutdown'] = 'Are you sure you want to close Unity?';
    this.defaultLanguageCollection['Prompt.Shutdown.CallCenterEvents'] = 'There are call center events that have not yet been recorded so closing Unity now will result in these events being lost. Are you sure you want to close Unity?';
    this.defaultLanguageCollection['Prompt.StartNewIMConversation'] = 'Start a new conversation';
    this.defaultLanguageCollection['Prompt.TemplateFileCannotLoad'] = 'The user template failed to download or there was an error while processing it. Unity cannot continue without the user template so will now close. Please consult the error log for more information and consult your service provider if the error persists.';
    this.defaultLanguageCollection['Prompt.ThreeWayCallingNotAssigned'] = 'This feature is unavailable as the Three Way Call user service has not been assigned to you.';
    this.defaultLanguageCollection['Prompt.ThreeWayCallNotAssigned'] = 'The Three-Way Call user service has not been assigned. Without it conference calling will not be available';
    this.defaultLanguageCollection['Prompt.UnassignHotelingHost'] = 'You are currently assigned to a hoteling host device, would you like to remove this assignment?';
    this.defaultLanguageCollection['Prompt.UseKempForGroupChat'] = 'You are currently sending instant messages to this user through Unity & XMPP but only Unity supports group chat. Do you want to remove XMPP message delivery to start group chat?\r\n\r\nPlease note this will only apply to this conversation.';
    this.defaultLanguageCollection['Prompt.UserTemplateChanged'] = 'The user template has changed so Unity must be restarted for the new user template to be applied';
    this.defaultLanguageCollection['Prompt.UseUnityForClickToDial'] = 'Unity is not currently the default application for callto: and tel: hyperlinks which can be used to provide click to dial functionality from within a web browser. Would you like to set this now? If you click Cancel you will not be asked again';
    this.defaultLanguageCollection['Prompt.Xmpp.Error'] = 'Unity experienced a general XMPP error which has resulted in XMPP functionality being lost.\r\nPlease consult the error log for more details.';
    this.defaultLanguageCollection['Prompt.Xmpp.StreamError'] = 'Unity could not connect to the XMPP server or there were unexpected network problems and the connection was severed.\r\nPlease consult the error log for more details.';
    this.defaultLanguageCollection['Prompt.XmppLogin.Failed'] = 'The XMPP login failed meaning IM&P with non-Unity clients is not available';
    this.defaultLanguageCollection['Prompt.ZipLogFolder'] = 'Would you like to zip the Log folder and save the zip file to the working folder?';
    this.defaultLanguageCollection['ReportViewer.1Value'] = 'Measured in seconds. Only one value can be entered';
    this.defaultLanguageCollection['ReportViewer.2Values'] = 'Measured in seconds. A maximum of two values can be entered';
    this.defaultLanguageCollection['ReportViewer.3Values'] = 'Measured in seconds. A maximum of three values can be entered';
    this.defaultLanguageCollection['ReportViewer.4Values'] = 'Measured in seconds. A maximum of four values can be entered';
    this.defaultLanguageCollection['ReportViewer.5Values'] = 'Measured in seconds. A maximum of five values can be entered';
    this.defaultLanguageCollection['ReportViewer.AbandonedCallInterval'] = 'Abandoned Call Interval';
    this.defaultLanguageCollection['ReportViewer.AbandonedCallInterval.Invalid'] = 'The abandoned call interval value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.AbandonedCallThreshold'] = 'Abandoned Call Threshold';
    this.defaultLanguageCollection['ReportViewer.AbandonedCallThreshold.Invalid'] = 'The abandoned call value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.AbandonedCallThreshold.TooMany'] = 'Too many abandoned call values have been entered';
    this.defaultLanguageCollection['ReportViewer.CallCompletion'] = 'Call Completion';
    this.defaultLanguageCollection['ReportViewer.CallCompletion.Invalid'] = 'The call completion value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.DnisGroup'] = 'Call Center Filter';
    this.defaultLanguageCollection['ReportViewer.EndPeriod'] = 'End Period';
    this.defaultLanguageCollection['ReportViewer.EndPeriod.Invalid'] = 'The end date/time value cannot be equal to or before the start date/time value';
    this.defaultLanguageCollection['ReportViewer.FilterList'] = 'Filters';
    this.defaultLanguageCollection['ReportViewer.FilterList.Invalid'] = 'At least one filter must be selected';
    this.defaultLanguageCollection['ReportViewer.FilterType'] = 'Filter Type';
    this.defaultLanguageCollection['ReportViewer.IncludeOtherTransfersInServiceLevel'] = 'Include other transfers in service level';
    this.defaultLanguageCollection['ReportViewer.IncludeOverflowTimeInServiceLevel'] = 'Include overflow transfer time in service level';
    this.defaultLanguageCollection['ReportViewer.OutputType'] = 'Output Type';
    this.defaultLanguageCollection['ReportViewer.RealTimeReport'] = 'Real-time report';
    this.defaultLanguageCollection['ReportViewer.RealTimeReport.ToolTip'] = 'If checked then an end date is not used to create the report';
    this.defaultLanguageCollection['ReportViewer.ReportTemplate'] = 'Report';
    this.defaultLanguageCollection['ReportViewer.Sampling'] = 'Sampling';
    this.defaultLanguageCollection['ReportViewer.ServiceLevel'] = 'Service Level';
    this.defaultLanguageCollection['ReportViewer.ServiceLevel.Invalid'] = 'The service level value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.ServiceLevel.TooMany'] = 'Too many abandoned service level values have been entered';
    this.defaultLanguageCollection['ReportViewer.ServiceLevelOption'] = 'Service Level Option';
    this.defaultLanguageCollection['ReportViewer.ServiceLevelPercentage'] = 'Service Level Percentage';
    this.defaultLanguageCollection['ReportViewer.ServiceLevelPercentage.Invalid'] = 'The service level percentage value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.ShortDuration'] = 'Short Duration';
    this.defaultLanguageCollection['ReportViewer.ShortDuration.Invalid'] = 'The short duration value is either not entered or is not a valid number';
    this.defaultLanguageCollection['ReportViewer.StartPeriod'] = 'Start Period';
    this.defaultLanguageCollection['ReportViewer.StartPeriod.Invalid'] = 'The start date/time value cannot be in the future';
    this.defaultLanguageCollection['ReportViewer.Text'] = 'Unity Report Viewer';
    this.defaultLanguageCollection['Settings.AgentActivityImageOption.AcdAndHookState'] = 'ACD & hook state';
    this.defaultLanguageCollection['Settings.AgentActivityImageOption.AcdState'] = 'ACD state';
    this.defaultLanguageCollection['Settings.AgentActivityImageOption.AvailableToTakeCalls'] = 'Available to take calls';
    this.defaultLanguageCollection['Settings.AgentActivityImageOption.HookState'] = 'Hook state';
    this.defaultLanguageCollection['Settings.AgentActivityImageOption.JoinedState'] = 'Joined state';
    this.defaultLanguageCollection['Settings.Appearance.AlwaysShowUnityOnTop'] = 'Always show Unity on top of all windows';
    this.defaultLanguageCollection['Settings.Appearance.Description'] = 'Specify how Unity is displayed on the desktop. Also how Unity performs and displays contact searches, and how current calls are displayed';
    this.defaultLanguageCollection['Settings.Appearance.ShowMissedCallCount'] = 'Show missed call count in call log tab';
    this.defaultLanguageCollection['Settings.Appearance.ShowRemotePartyNumber'] = 'Show remote party phone number';
    this.defaultLanguageCollection['Settings.Appearance.Skin'] = 'Skin';
    this.defaultLanguageCollection['Settings.Appearance.Skin.CurrentSkin'] = 'Current skin';
    this.defaultLanguageCollection['Settings.Appearance.Skin.Description'] = 'Select the Unity skin to use which controls the color scheme of all windows';
    this.defaultLanguageCollection['Settings.Appearance.Skin.GradientBlue'] = 'Gradient Blue';
    this.defaultLanguageCollection['Settings.Appearance.Skin.GradientGray'] = 'Gradient Gray';
    this.defaultLanguageCollection['Settings.Appearance.Skin.GradientLightGray'] = 'Gradient Light Gray';
    this.defaultLanguageCollection['Settings.Appearance.Skin.PlainBlue'] = 'Plain Blue';
    this.defaultLanguageCollection['Settings.Appearance.Skin.PlainGray'] = 'Plain Gray';
    this.defaultLanguageCollection['Settings.Appearance.Skin.PlainLightGray'] = 'Plain Light Gray';
    this.defaultLanguageCollection['Settings.Appearance.SystemTray'] = 'system tray';
    this.defaultLanguageCollection['Settings.Appearance.Taskbar'] = 'taskbar';
    this.defaultLanguageCollection['Settings.Appearance.WhenUnityMinimised'] = 'When minimised';
    this.defaultLanguageCollection['Settings.Connection'] = 'Connection';
    this.defaultLanguageCollection['Settings.Connection.Authentication'] = 'Authentication';
    this.defaultLanguageCollection['Settings.Connection.Authentication.AlertWhenCallCenterLoginFails'] = 'Alert me when a call center login fails';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ApplyToAllUsers'] = 'Applies to all users';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ApplyToAllUsers.ToolTip'] = 'Specify whether all users will use the same login details on this computer';
    this.defaultLanguageCollection['Settings.Connection.Authentication.BroadworksLoginDetails'] = 'Broadworks Login Details';
    this.defaultLanguageCollection['Settings.Connection.Authentication.CallCenterLoginDetails'] = 'Call Center Login Details';
    this.defaultLanguageCollection['Settings.Connection.Authentication.CallCenters'] = 'Call Centers';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword'] = 'Change Password';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.Confirmation'] = 'Confirmation:';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.Description'] = 'Change the password used to log into Unity as well as any other VoIP portals or client applications.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.Failure'] = 'The password was not changed';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.NewPassword'] = 'New password:';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.NoMatch'] = 'The new password and confirmation do not match.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.NotSaved'] = 'The password must be changed before changing other settings.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.ChangePassword.Success'] = 'The password was changed successfully.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.DefaultPassword'] = 'Default password';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Description'] = 'Specify authentication details for the VoIP platform. The login id and password are case sensitive and the login id must include the domain.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Description.CallCenter'] = 'Specify call center authentication details. All login ids and passwords are case sensitive and each login id must include the domain.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Description.Reception'] = 'Specify authentication details for the VoIP platform. Admininstrator login details can be entered to modify service configuration details for other users. All login ids and passwords are case sensitive and the login id must include the domain.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Description.Wallboard'] = 'Specify call center authentication details. All login ids and passwords are case sensitive and each login id must include the domain. Statistics will be displayed in the order listed.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.GroupAdmin.LoggedInAsEnterprise'] = 'Currently logged in at enterprise level';
    this.defaultLanguageCollection['Settings.Connection.Authentication.GroupAdmin.LoggedInAsGroup'] = 'Currently logged in at group level';
    this.defaultLanguageCollection['Settings.Connection.Authentication.GroupAdmin.NotLoggedIn'] = 'Not currently logged in';
    this.defaultLanguageCollection['Settings.Connection.Authentication.GroupAdminLoginDetails'] = 'Administrator Login Details';
    this.defaultLanguageCollection['Settings.Connection.Authentication.LoginID'] = 'Login ID';
    this.defaultLanguageCollection['Settings.Connection.Authentication.MustIncludeDomain'] = 'The login id must include the domain.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.NoDetailsEntered'] = 'Authentication details must be entered.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Password'] = 'Password';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.AlwaysShowTheseStatistics'] = 'Always show these statistics';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ApplyPasswordToAllQueues'] = 'Apply to all queues without a password';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ApplyToNewQueues'] = 'Apply to all new queues (default password)';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ApplyToNewQueues.ConfirmChange'] = 'There is already a default call center password entered. Are you sure you want to change it now? If you click yes the new password will be immediately applied to all call centers.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ClientCallControlNotAssigned'] = 'The Client Call Control service is not assigned to this queue meaning the \'calls in queue\' statistic is not available in real-time';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ConfirmDefaultPasswordDelete'] = 'Are you sure you want to delete the default call center password? If you click yes Unity will no longer attempt to login as any call centers until a password has been entered';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ConfirmDelete'] = 'Are you sure you want to delete this call center? This will mean statistics will no longer be available.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ConfirmPasswordDelete'] = 'Are you sure you want to delete this password? If you click yes Unity will no longer attempt to login as this call center.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.DoubleClickToChange'] = 'Double click the list entry to change the call center authentication details.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.DoubleClickToSet'] = 'Double click the list entry to set the password and enable statistics.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.ID'] = 'ID';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.InProgress'] = 'Authentication is currently in progress.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.IsBasic'] = 'This is a basic call center meaning statistics are not available.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.LoginAlreadyExists'] = 'This login id already exists';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.LoginDetails'] = 'Login Details';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.LoginFailed'] = 'The call center login failed. Please check the authentication details are correct and ensure the account is not disabled.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.LoginSuccessful'] = 'The call center logged in successfully.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.NoDetailsEntered'] = 'The authentication details for at least one call center must be entered.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.NotShowing'] = 'The call center is not configured to be displayed in the personal wallboard';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.PasswordChangeWhileLoggedIn'] = 'This call center is currently logged in';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.PasswordNotEntered'] = 'The password has not been entered.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.PasswordTooltip'] = 'Delete call center password';
    this.defaultLanguageCollection['Settings.Connection.Authentication.Queue.Waiting'] = 'Unity is waiting to login as this call center.';
    this.defaultLanguageCollection['Settings.Connection.Authentication.RememberLoginID'] = 'Remember my login ID';
    this.defaultLanguageCollection['Settings.Connection.Authentication.RememberPassword'] = 'Remember my password';
    this.defaultLanguageCollection['Settings.Connection.Network'] = 'Network';
    this.defaultLanguageCollection['Settings.Connection.Network.BroadworksServerConnectionDetails'] = 'Broadworks Server Connection Details';
    this.defaultLanguageCollection['Settings.Connection.Network.CTIPort'] = 'CTI Port';
    this.defaultLanguageCollection['Settings.Connection.Network.Description'] = 'Specify connection properties for different services.';
    this.defaultLanguageCollection['Settings.Connection.Network.LogConnections'] = 'Log connections';
    this.defaultLanguageCollection['Settings.Connection.Network.LogTrace'] = 'Log trace';
    this.defaultLanguageCollection['Settings.Connection.Network.MessageServer.NotEntered'] = 'The messaging server connection details must be entered.';
    this.defaultLanguageCollection['Settings.Connection.Network.MessageServerPort.Invalid'] = 'The messaging server port must be numeric.';
    this.defaultLanguageCollection['Settings.Connection.Network.SendKeepAliveMessages'] = 'Send keep alive messages';
    this.defaultLanguageCollection['Settings.Connection.Network.ServerAddress'] = 'Server address';
    this.defaultLanguageCollection['Settings.Connection.Network.ServerPort'] = 'Server Port';
    this.defaultLanguageCollection['Settings.Connection.Network.UnityServerConnectionDetails'] = 'Unity Server IM&P Connection Details';
    this.defaultLanguageCollection['Settings.Connection.Network.UseCTI'] = 'Use CTI if available';
    this.defaultLanguageCollection['Settings.Connection.Network.VoipServer.NotEntered'] = 'The VoIP server connection details must be entered.';
    this.defaultLanguageCollection['Settings.Connection.Network.VoipServerPort.Invalid'] = 'The VoIP server port must be numeric.';
    this.defaultLanguageCollection['Settings.Connection.Proxy'] = 'Proxy';
    this.defaultLanguageCollection['Settings.Connection.Proxy.AuthenticationDetails'] = 'Proxy Authentication Details';
    this.defaultLanguageCollection['Settings.Connection.Proxy.AuthenticationMissing'] = 'If the proxy connection details have been set then the authentication details must also be set.';
    this.defaultLanguageCollection['Settings.Connection.Proxy.ConnectionDetails'] = 'Proxy Connection Details';
    this.defaultLanguageCollection['Settings.Connection.Proxy.Description'] = 'Specify proxy settings if a proxy server is used on your network. Please note the login id and password are both case sensitive. Only HTTP proxy servers are supported.';
    this.defaultLanguageCollection['Settings.Connection.Proxy.Domain'] = 'Domain';
    this.defaultLanguageCollection['Settings.Connection.Proxy.Port.Invalid'] = 'The proxy port is empty or invalid. A number must be entered.';
    this.defaultLanguageCollection['Settings.Connection.Proxy.UseBrowserSettings'] = 'Use browser settings';
    this.defaultLanguageCollection['Settings.Connection.Proxy.UseWindowsAuthentication'] = 'Use Windows authentication';
    this.defaultLanguageCollection['Settings.Cti'] = 'Computer/Phone Integration';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration'] = 'Browser Integration';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.BrowseToUrl'] = 'Browse to this URL';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.BrowseToUrl.Invalid'] = 'The URL entered is invalid. Please ensure it starts with http:// or https://';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.BrowseToUrl.NotEntered'] = 'A URL must be entered when choosing to browse to a URL when answering or placing a call';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.BrowseToUrlWithoutBrowser'] = 'Don\'t use the browser user interface';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.Description'] = 'Configure Unity to browse to a URL in the default browser. Use the [ContactNumber], [Direction], [LocalCallID] and [LoginID] placeholders which Unity will replace with the call details.';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.NotThroughCallCenter'] = 'that is not through a call center';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.OnInboundCall'] = 'Everytime I answer an inbound call';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.OnOutboundCall'] = 'Everytime I make an outbound call';
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.ThroughCallCenter'] = 'that is through a call center';
    this.defaultLanguageCollection['Settings.Cti.ClickToDial'] = 'Click To Dial';
    this.defaultLanguageCollection['Settings.Cti.ClickToDial.ConfigureNow'] = 'Configure Unity click to dial now';
    this.defaultLanguageCollection['Settings.Cti.ClickToDial.PromptFirst'] = 'Prompt before performing click to dial';
    this.defaultLanguageCollection['Settings.Cti.Clipboard'] = 'Clipboard Integration';
    this.defaultLanguageCollection['Settings.Cti.Clipboard.Description'] = 'Unity can automatically prompt to dial a number when cut or copied to the clipboard';
    this.defaultLanguageCollection['Settings.Cti.Crm'] = 'CRM';
    this.defaultLanguageCollection['Settings.Cti.Crm.Agile'] = 'Agile CRM';
    this.defaultLanguageCollection['Settings.Cti.Crm.ApiKey'] = 'API key';
    this.defaultLanguageCollection['Settings.Cti.Crm.ApiKeyMissing'] = 'The CRM API key must be entered';
    this.defaultLanguageCollection['Settings.Cti.Crm.AutomaticContactPop'] = 'Automatic Contact Pop';
    this.defaultLanguageCollection['Settings.Cti.Crm.CloseCallLogEntry'] = 'Close call log entry immediately';
    this.defaultLanguageCollection['Settings.Cti.Crm.Description'] = 'Configure Unity to seamlessly integrate with a CRM platform for contact search and CLI presentation.';
    this.defaultLanguageCollection['Settings.Cti.Crm.DomainMissing'] = 'The CRM domain must be entered';
    this.defaultLanguageCollection['Settings.Cti.Crm.Dynamics'] = 'Microsoft CRM Dynamics';
    this.defaultLanguageCollection['Settings.Cti.Crm.Dynamics.ServerPart'] = 'Enter the part of the server address before dynamics.com. Examples are crm11';
    this.defaultLanguageCollection['Settings.Cti.Crm.IncludeAccounts'] = 'Include accounts';
    this.defaultLanguageCollection['Settings.Cti.Crm.IncludeCompanies'] = 'Include companies';
    this.defaultLanguageCollection['Settings.Cti.Crm.IncludeContacts'] = 'Include contacts';
    this.defaultLanguageCollection['Settings.Cti.Crm.IncludeLeads'] = 'Include leads';
    this.defaultLanguageCollection['Settings.Cti.Crm.IsOnlineOrOffice365'] = 'Online or Office 365 deployment';
    this.defaultLanguageCollection['Settings.Cti.Crm.LoginID.Tooltip'] = 'Please enter the login ID exactly as it would be entered when logging into the CRM platform. This will often include the domain if one if used';
    this.defaultLanguageCollection['Settings.Cti.Crm.LoginIdMissing'] = 'The CRM login ID must be entered';
    this.defaultLanguageCollection['Settings.Cti.Crm.OnlineRegion'] = 'Online region';
    this.defaultLanguageCollection['Settings.Cti.Crm.OnlineRegion.Missing'] = 'The online region of the CRM platform must be selected';
    this.defaultLanguageCollection['Settings.Cti.Crm.Organization'] = 'Organization';
    this.defaultLanguageCollection['Settings.Cti.Crm.PasswordMissing'] = 'The CRM password must be entered';
    this.defaultLanguageCollection['Settings.Cti.Crm.RecordTypeMissing'] = 'At least one record type must be selected';
    this.defaultLanguageCollection['Settings.Cti.Crm.Salesforce'] = 'Salesforce';
    this.defaultLanguageCollection['Settings.Cti.Crm.Salesforce.AddCallLogEntry'] = 'Automatically add call log entry';
    this.defaultLanguageCollection['Settings.Cti.Crm.Salesforce.AddCallLogEntry.Close'] = 'Close call log entry immediately';
    this.defaultLanguageCollection['Settings.Cti.Crm.SecurityToken'] = 'Security token';
    this.defaultLanguageCollection['Settings.Cti.Crm.SecurityTokenMissing'] = 'The CRM security token must be entered. Please consult the user guide for instructions on how to obtain your security token.';
    this.defaultLanguageCollection['Settings.Cti.Crm.SelectOrganization'] = 'Select organization';
    this.defaultLanguageCollection['Settings.Cti.Crm.ServerAddress.Invalid'] = 'The CRM server address is an invalid URL';
    this.defaultLanguageCollection['Settings.Cti.Crm.ServerAddress.Missing'] = 'The CRM server address must be entered';
    this.defaultLanguageCollection['Settings.Cti.Crm.ServerAddress.Tooltip'] = 'Specify the CRM server including server port if applicable. The server location must be a valid URL.';
    this.defaultLanguageCollection['Settings.Cti.Crm.ShowRecordType'] = 'Show record type when searching';
    this.defaultLanguageCollection['Settings.Cti.Crm.Zendesk'] = 'Zendesk';
    this.defaultLanguageCollection['Settings.Cti.DDE'] = 'DDE Integration';
    this.defaultLanguageCollection['Settings.Cti.DDE.Application'] = 'Application:';
    this.defaultLanguageCollection['Settings.Cti.DDE.Description'] = 'Allow Unity to connect to other applications through Dynamic Data Exchange to provide contact lookup functionality for incoming calls.';
    this.defaultLanguageCollection['Settings.Cti.DDE.Item'] = 'Item:';
    this.defaultLanguageCollection['Settings.Cti.DDE.ServerConnectionDetails'] = 'DDE Server Connection Details';
    this.defaultLanguageCollection['Settings.Cti.DDE.ServerConnectionDetails.NotEntered'] = 'In order to user DDE integration the DDE server connection details must be entered.';
    this.defaultLanguageCollection['Settings.Cti.DDE.Topic'] = 'Topic:';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration'] = 'Desktop Integration';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.CopyToClipboard'] = 'Copy remote number to clipboard';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.Description'] = 'Configure Unity to integrate with other applications on the computer through a shared file or the clipboard';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.UseNumberAsFileName'] = 'Use remote number as file name';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.UseStaticFileName'] = 'Use static file name';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.UseStaticFileName.Missing'] = 'A static file name must be entered';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.WriteToFile'] = 'Write remote number to file';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.WriteToFile.Fail'] = 'Unity could not write a file to this folder path so it cannot be saved';
    this.defaultLanguageCollection['Settings.Cti.DesktopIntegration.WriteToFile.Missing'] = 'The desktop integration folder path must be specified';
    this.defaultLanguageCollection['Settings.Cti.DialRule'] = 'Dialing Rule';
    this.defaultLanguageCollection['Settings.Cti.DialRule.BeforeDialing'] = 'Replace these leading characters';
    this.defaultLanguageCollection['Settings.Cti.DialRule.BeforeDialing.Invalid'] = 'One or more start characters entered in the dialing rule are invalid.';
    this.defaultLanguageCollection['Settings.Cti.DialRule.BeforeDialing.NotEntered'] = 'Start characters must be entered when setting a dialing rule.';
    this.defaultLanguageCollection['Settings.Cti.DialRule.Description'] = 'Specify custom dialing rules when using click to dial and contact popping for incoming calls. This is particularly useful when dialling a number sequence for international calls.';
    this.defaultLanguageCollection['Settings.Cti.DialRule.Ignore'] = 'Ignore this rule for incoming calls';
    this.defaultLanguageCollection['Settings.Cti.DialRule.IntlDialling'] = 'When dialling a number that starts with a + character';
    this.defaultLanguageCollection['Settings.Cti.DialRule.IntlDialling.IgnoreBrackets'] = 'Ignore any numbers within brackets';
    this.defaultLanguageCollection['Settings.Cti.DialRule.IntlDialling.IncludeBrackets'] = 'Also dial numbers within brackets';
    this.defaultLanguageCollection['Settings.Cti.DialRule.IntlDialling.ReplaceWithBrackets'] = 'Replace numbers before brackets with numbers within brackets';
    this.defaultLanguageCollection['Settings.Cti.DialRule.Reverse'] = 'Reverse this rule for incoming calls';
    this.defaultLanguageCollection['Settings.Cti.DialRule.Use'] = 'Use this rule for incoming calls';
    this.defaultLanguageCollection['Settings.Cti.DialRule.WithTheseCharacters'] = 'with these characters';
    this.defaultLanguageCollection['Settings.Cti.DialRule.WithTheseCharacters.CannotBeTheSame'] = 'The start and end characters must not be the same when setting a dialing rule.';
    this.defaultLanguageCollection['Settings.Cti.DialRule.WithTheseCharacters.Invalid'] = 'One or more end characters entered in the dialing rule are invalid.';
    this.defaultLanguageCollection['Settings.Cti.DialRule.WithTheseCharacters.NotEntered'] = 'End characters must be entered when setting a dialing rule.';
    this.defaultLanguageCollection['Settings.Cti.Directory'] = 'Custom Directory Integration';
    this.defaultLanguageCollection['Settings.Cti.Directory.AddLocalTemplate'] = 'Add a local directory template';
    this.defaultLanguageCollection['Settings.Cti.Directory.BrowseToTemplate'] = 'Browse to a remote directory template';
    this.defaultLanguageCollection['Settings.Cti.Directory.Configuration.Description'] = 'Specify the template file to use when loading custom directories';
    this.defaultLanguageCollection['Settings.Cti.Directory.ContactSearchDirectory'] = 'Contact search directory';
    this.defaultLanguageCollection['Settings.Cti.Directory.CustomDirectories'] = 'Custom Directories';
    this.defaultLanguageCollection['Settings.Cti.Directory.DeleteTemplate'] = 'Remove the directory template';
    this.defaultLanguageCollection['Settings.Cti.Directory.Description'] = 'Specify which custom directory to use when searching contacts and phone numbers.';
    this.defaultLanguageCollection['Settings.Cti.Directory.NoAddOrRemove'] = 'Custom directories cannot be added';
    this.defaultLanguageCollection['Settings.Cti.Directory.PhoneNumberLookup'] = 'Phone number lookup directory';
    this.defaultLanguageCollection['Settings.Cti.Directory.RefreshDirectoryConfig'] = 'Refresh directory config from this location.';
    this.defaultLanguageCollection['Settings.Cti.Directory.RefreshDirectoryConfig.AlertOnFail'] = 'Alert me when the file cannot be refreshed';
    this.defaultLanguageCollection['Settings.Cti.Directory.RefreshDirectoryConfig.Confirm'] = 'Are you sure you want to stop automatically refreshing this file? The directories will still be available but can now be deleted';
    this.defaultLanguageCollection['Settings.Cti.Directory.RefreshDirectoryConfig.FailPrompt'] = 'The custom directory template file could not be refreshed meaning custom directory details may not be correct';
    this.defaultLanguageCollection['Settings.Cti.Directory.UseFirstWhenPerformingCliLookup'] = 'Use first when performing CLI lookup (this is the most accurate contact list.)';
    this.defaultLanguageCollection['Settings.Cti.Notification'] = 'Call Notification';
    this.defaultLanguageCollection['Settings.Cti.Notification.AlwaysPopFromRemoteNumber'] = 'Override the contact name using the number';
    this.defaultLanguageCollection['Settings.Cti.Notification.Description'] = 'Specify how to be notified of incoming or outgoing calls.';
    this.defaultLanguageCollection['Settings.Cti.Notification.OnCall'] = 'On an incoming or outgoing call';
    this.defaultLanguageCollection['Settings.Cti.Notification.PerformAutoAnswer'] = 'perform auto answer when using click-to-dial';
    this.defaultLanguageCollection['Settings.Cti.Notification.PopSummary'] = 'pop summary notification';
    this.defaultLanguageCollection['Settings.Cti.Notification.PopSummary.NotEntered'] = 'The call notification display duration must be entered.';
    this.defaultLanguageCollection['Settings.Cti.Notification.PopUnity'] = 'pop Unity';
    this.defaultLanguageCollection['Settings.Cti.Notification.PopUnity.AutoHide'] = 'auto-hide Unity when call completes';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked'] = 'When call details clicked';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.AlwaysPopOnAnswer'] = 'always pop this URL when I answer a call';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.PopBrowser'] = 'browse to URL';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.PopOutlook'] = 'pop Outlook (if applicable)';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.PopUnity'] = 'pop Unity';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.UseCrm'] = 'Pop CRM';
    this.defaultLanguageCollection['Settings.Cti.Notification.WhenCallDetailsClicked.UseDde'] = 'send CLI using DDE integration';
    this.defaultLanguageCollection['Settings.Cti.Outlook'] = 'Outlook Integration';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar'] = 'Shared calendar';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.Description'] = 'Configure how Unity displays Outlook shared calendar details. Unity uses the email address from the user profile to load the shared calendar. The start and end times must be in 24 hour format.';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.DisplayDuration'] = 'Show for';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.EndHour'] = 'End hour';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.IncludeTomorrow'] = 'Include tomorrow in calendar';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.InvalidTime'] = 'The end time must be later than the start time';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.ShowLocation'] = 'Show appointment location [if possible]';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.StartHour'] = 'Start hour';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Calendar.Use24HourFormat'] = 'Use 24 hour format';
    this.defaultLanguageCollection['Settings.Cti.Outlook.Description'] = 'Specify which Outlook contact folders are to be imported (only Outlook 2003 or later is supported). Choosing to include public folders may increase the time taken to load.';
    this.defaultLanguageCollection['Settings.Cti.Outlook.IncludePrivateFolders'] = 'Load contacts from my private folders';
    this.defaultLanguageCollection['Settings.Cti.Outlook.IncludePublicFolders'] = 'Load contacts from public folders';
    this.defaultLanguageCollection['Settings.Cti.Outlook.IncludeTheseFolders'] = 'Include these Outlook contact folders:';
    this.defaultLanguageCollection['Settings.Cti.Outlook.IncludeTheseFolders.NotEntered'] = 'Outlook integration is turned on but no Outlook folders have been selected. Either select one or more folders or turn off Outlook integration.';
    this.defaultLanguageCollection['Settings.Cti.Outlook.RestartDcomServer'] = 'Restart the Outlook API before connecting';
    this.defaultLanguageCollection['Settings.Cti.Outlook.ShowBusinessNameColumn'] = 'Include business name in directory list';
    this.defaultLanguageCollection['Settings.Cti.Outlook.UseCaching'] = 'Use Outlook caching for greater speed';
    this.defaultLanguageCollection['Settings.Cti.pnlSettingsClickToDial.Description'] = 'Configure Unity for click to dial functionality from Mozilla Firefox or Google Chrome browsers';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect'] = 'Unity Connect';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect.ClientName'] = 'Client Name';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect.Confirm'] = 'If you disable Unity Connect then the currently connected clients listed will lost functionality. Are you sure you want to proceed?';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect.Description'] = 'Allow Unity add-ons and third party applications to integrate with Unity to provide click to dial call details and presence integration.';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect.Licensed'] = 'Licensed';
    this.defaultLanguageCollection['Settings.Cti.UnityConnect.ListeningOn'] = 'Listening on';
    this.defaultLanguageCollection['Settings.InstantMessaging'] = 'Instant Messaging & Presence';
    this.defaultLanguageCollection['Settings.InstantMessaging.Description'] = 'Set instant messaging and presence preferences. Changes will not affect existing IM conversations.';
    this.defaultLanguageCollection['Settings.InstantMessaging.KeepDockedInstantMessagePanelVisible'] = 'Always show docked panel';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations'] = 'Log instant message conversations';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations.LogFileType'] = 'Log file type';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations.LogFolder'] = 'Log Folder';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations.NewFilePerConversation'] = 'Create a new file for each conversation';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations.NewFilePerDay'] = 'Create a new file every day';
    this.defaultLanguageCollection['Settings.InstantMessaging.LogConversations.SameFile'] = 'Always use the same file';
    this.defaultLanguageCollection['Settings.InstantMessaging.Logging'] = 'Logging';
    this.defaultLanguageCollection['Settings.InstantMessaging.Logging.Description'] = 'Set instant messaging and presence logging preferences. Log files can be saved in a central location for security purposes.';
    this.defaultLanguageCollection['Settings.InstantMessaging.PlayNotification'] = 'Play a notification sound when instant messages are received.';
    this.defaultLanguageCollection['Settings.InstantMessaging.Priority'] = 'When a contact is online in Unity IMP and XMPP';
    this.defaultLanguageCollection['Settings.InstantMessaging.Priority.BOTH'] = 'Use Both';
    this.defaultLanguageCollection['Settings.InstantMessaging.Priority.KEMP'] = 'Use Unity IM&P';
    this.defaultLanguageCollection['Settings.InstantMessaging.Priority.XMPP'] = 'Use XMPP';
    this.defaultLanguageCollection['Settings.InstantMessaging.SendIMsToOfflineUsers'] = 'Send instant messages to offline users';
    this.defaultLanguageCollection['Settings.InstantMessaging.ShowInstantMessagesInDockedPanel'] = 'Show instant messages in docked panel';
    this.defaultLanguageCollection['Settings.InstantMessaging.ShowToast'] = 'Show popup notification';
    this.defaultLanguageCollection['Settings.InstantMessaging.UseExistingImConversationOnDragDrop'] = 'Use existing conversation for drag and drop';
    this.defaultLanguageCollection['Settings.InstantMessaging.WhenMessageReceived'] = 'When an instant message is received';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.AutoAcceptSubscribeRequests'] = 'Automatically accept subscription requests';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.AutoAcceptXmppSubscribeRequests'] = 'Automatically accept subscription requests';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.AutoSendSubscribeRequests'] = 'Automatically send subscription requests';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.AutoSendXmppSubscribeRequests'] = 'Automatically send subscription requests';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.ChangePassword'] = 'Change Password';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.ChangePassword.Confirm'] = 'Unity is currently connected to the XMPP server. Are you sure you want to change the password?';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.ChangePassword.Description'] = 'Change the Integrated Messaging password. Please note that UC-One will need to be restarted after changing the password in Unity.';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.Description'] = 'Configure XMPP details for Unity. The XMPP server will be assumed from the domain entered as part of the login ID. The XMPP password must be manually set in the VoIP platform then set in Unity.';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.LoginDetails'] = 'XMPP Login Details';
    this.defaultLanguageCollection['Settings.InstantMessaging.XMPP.SubscribeRequest'] = 'would like to share XMPP presence information with you. Would you like to allow this contact?';
    this.defaultLanguageCollection['Settings.Language'] = 'Language';
    this.defaultLanguageCollection['Settings.Language.CurrentLanguage'] = 'Current language';
    this.defaultLanguageCollection['Settings.Language.Description'] = 'Set the language you would like Unity to use';
    this.defaultLanguageCollection['Settings.Language.RefreshLanguageFile'] = 'Refesh the language file';
    this.defaultLanguageCollection['Settings.Language.RefreshLanguageFile.Confirm'] = 'Are you sure you want to delete the current language file? Ths will force Unity to download it when next started.';
    this.defaultLanguageCollection['Settings.LicenseKey'] = 'License Key';
    this.defaultLanguageCollection['Settings.LicenseKey.Description'] = 'Enter the Unity license key. Once entered Unity will automatically restart.';
    this.defaultLanguageCollection['Settings.LicenseKey.DescriptionWithoutRestart'] = 'Enter the Unity license key.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey'] = 'License Key:';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid'] = 'The license key is invalid.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid.AnotherProduct'] = 'The license key entered is for another Unity product.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid.AnotherUser'] = 'The license key entered is for another user. Either change the login id or license key.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid.Expired'] = 'The license key has expired.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid.TempLicense'] = 'The license key is for a temporary license which is not permitted.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.Invalid.UnknownProduct'] = 'The license key entered is for an unknown product.';
    this.defaultLanguageCollection['Settings.LicenseKey.LicenseKey.NotEntered'] = 'The license key must be entered.';
    this.defaultLanguageCollection['Settings.NotifyUsingBalloon'] = 'Notify me using a Windows balloon';
    this.defaultLanguageCollection['Settings.NotifyUsingPrompt'] = 'Notify me using a popup message';
    this.defaultLanguageCollection['Settings.NotifyUsingToast'] = 'Notify me using a Unity toast notification';
    this.defaultLanguageCollection['Settings.QuickKeys'] = 'Quick Keys';
    this.defaultLanguageCollection['Settings.QuickKeys.Action'] = 'Action';
    this.defaultLanguageCollection['Settings.QuickKeys.ActionNotSelected'] = 'The quick key cannot be added as an action has not been selected.';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands'] = 'Commands';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.CombinationExistsForList'] = 'This key combination already exists for the selected list';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.CommandExistsForList'] = 'This command already exists for the selected list';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.Delete.Confirm'] = 'Are you sure you want to delete the selected quick key(s)? This action cannot be undone';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.Description'] = 'Map key combinations to command to create quick keys. Quick keys can be created for a specific list or for all lists. If a number is not entered now the user will be prompted to enter one when using the quick key';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.DialListItem'] = 'Dial selected list item';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.DialPresetNumber'] = 'Dial preset number';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.DialWindow'] = 'Show dial window';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.PerformContactSearch'] = 'Perform contact search';
    this.defaultLanguageCollection['Settings.QuickKeys.Commands.ShowContextMenu'] = 'Show context menu';
    this.defaultLanguageCollection['Settings.QuickKeys.Description'] = 'Quick keys allow you to perform tasks such as answering calls or dialing phone numbers easily using key shortcuts.';
    this.defaultLanguageCollection['Settings.QuickKeys.Key'] = 'Key';
    this.defaultLanguageCollection['Settings.QuickKeys.Key.AlreadyAssigned'] = 'This key has already been mapped to an action. Please choose another key.';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations'] = 'Key Combinations';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.CombinationExists'] = 'This key combination already exists';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.Command'] = 'Command';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.Delete.Confirm'] = 'Are you sure you want to delete the selected key combination(s)? This will also delete any commands associated to the key combination(s) and cannot be undone';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.Description'] = 'Specify the key combinations that will be mapped to quick key commands.';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.IllegalKey'] = 'This key cannot be used in a quick key combination as it is reserved by Unity';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.Key'] = 'Key';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.List'] = 'List';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.Modifier'] = 'Modifier';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyCombinations.ModifierRequired'] = 'A modifier must be specified as this is not a reserved key';
    this.defaultLanguageCollection['Settings.QuickKeys.KeyNotSelected'] = 'The quick key cannot be added as a key or key sequence has not been selected.';
    this.defaultLanguageCollection['Settings.QuickKeys.List.CallLog'] = 'Call log list';
    this.defaultLanguageCollection['Settings.QuickKeys.List.Contacts'] = 'Contacts list';
    this.defaultLanguageCollection['Settings.QuickKeys.List.CurrentCalls'] = 'Current calls list';
    this.defaultLanguageCollection['Settings.QuickKeys.List.PersonalWallboard'] = 'Personal wallboard';
    this.defaultLanguageCollection['Settings.QuickKeys.Lookup'] = 'Lookup';
    this.defaultLanguageCollection['Settings.QuickKeys.Map'] = 'Map';
    this.defaultLanguageCollection['Settings.QuickKeys.PhoneNumberNotEntered'] = 'The quick key cannot be added as the phone number to dial has not been entered.';
    this.defaultLanguageCollection['Settings.QuickKeys.PhoneNumberNotSelected'] = 'The quick key cannot be added as the phone number to dial has not been selected.';
    this.defaultLanguageCollection['Settings.Reception.ContactDisplay'] = 'Contact Display';
    this.defaultLanguageCollection['Settings.Reception.ContactDisplay.Description'] = 'Configure the way Unity Reception displays contacts as well as drag & drop options';
    this.defaultLanguageCollection['Settings.Reception.ContactDisplay.MenuOptionsWhenCallDragged'] = 'Menu options when a call is dragged over a contact';
    this.defaultLanguageCollection['Settings.Reception.ContactDisplay.MenuOptionsWhenCallDragged.NoneSelected'] = 'At least one menu option must be selected';
    this.defaultLanguageCollection['Settings.Reception.ContactDisplay.MenuOptionsWhenCallDragged.ToolTip'] = 'If only one option is selected this action will be performed rather than show a menu';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch'] = 'Contact Search';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.AlwaysShowCallDetailsForMonitoredUsers'] = 'Always show call details';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.AlwaysSortNewSearchResultsByName'] = 'Always sort new search results by name';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ClearContactSearchBoxOnFocus'] = 'Clear the search box when activated';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ContactSearchBasedOnContains'] = 'The name contains the search criteria';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ContactSearchBasedOnStartsWith'] = 'The name starts with the search criteria';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.Description'] = 'Configure the way Unity searches for contacts and displays search results';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.IncludeCallDurationInMonitoredUserList'] = 'Show call duration in contact list';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.IncludeDepartmentNameInContactSearch'] = 'Include department name in search';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.IncludeDepartmentNameInContactSearch.Tooltip'] = 'If set Unity will include the department name in the contact search criteria';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.IncludeExternalUsersWhenSearching'] = 'Include external contacts in all searches';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.IncludeExternalUsersWhenSearching.Tooltip'] = 'If not set then external contacts will only be included in the search when the enter key is pressed or the search button is clicked';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.PromptWhenPrivacyEnabled'] = 'Tell me when a contact has privacy enabled';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.RefreshList'] = 'Refresh list every';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowContactGroups'] = 'Show contact groups';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowContactSearchCharacters'] = 'Show contact search letters';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowContactSearchCharacters.FirstName'] = 'Based on fist name';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowContactSearchCharacters.LastName'] = 'Based on last name';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowMonitoredUsersFirstInSearchResults'] = 'Show monitored users first when searching';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowMonitoredUsersFirstInSearchResults.Tooltip'] = 'If set then Unity will display all monitored users at the top of the search results list';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowMostSearchedContactList'] = 'Show most searched on users by default';
    this.defaultLanguageCollection['Settings.Reception.ContactSearch.ShowMostSearchedContactList.Tooltip'] = 'You can select which users to monitor by default or Unity can always show the most searched contacts';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls'] = 'Current Calls';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.AutomaticallyResizeCurrentCallList'] = 'Resize the current call list to ensure all calls are visible';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.Description'] = 'Configure how Unity displays calls in the current call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.DoubleClickHeldCallToRetrieve'] = 'Double click to retrieve a call on hold';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.DoubleClickToRetrieve'] = 'Double click to retrieve a call on hold';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.KeepCampledCallsInCallList'] = 'Display camped calls in the call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.KeepParkedCallsInCallList'] = 'Display parked calls in the call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowCallNotes'] = 'Display notes column in call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowGroupsInCallList'] = 'Show call type groups in the call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowQueuedCallsGroup'] = 'Group queued calls together';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowQueuedCallsInCallList'] = 'Display queued call center calls in the list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowTransferHistory'] = 'Display transfer history in the call list';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowWaitingCallsGroup'] = 'Group waiting calls together';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.TransferHistory'] = 'Transfer History';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.TransferHistory.Description'] = 'Manage how Unity saves transfer history information';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee'] = 'Hold Call & Link To User';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.DisplayHold'] = 'Display the hold duration';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.IndicateThreshold'] = 'Indicate when the hold duration is over';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.WhenFree'] = 'When the linked user becomes available';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.WhenFree.Indicate'] = 'Indicate only';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.WhenFree.Prompt'] = 'Prompt to transfer call';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.WhenFree.Prompt.Text'] = 'is now available. Would you like to transfer the call from';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.WaitAndSee.WhenFree.Transfer'] = 'Automatically transfer call';
    this.defaultLanguageCollection['Settings.Security'] = 'Security';
    this.defaultLanguageCollection['Settings.Security.CreateTemplateFile'] = 'Create template file';
    this.defaultLanguageCollection['Settings.Security.Description'] = 'Specify whether Unity settings can only be modified by an administrator of this computer.';
    this.defaultLanguageCollection['Settings.Security.IfTemplateFileCannotLoad'] = 'If the template cannot be loaded';
    this.defaultLanguageCollection['Settings.Security.IfTemplateFileCannotLoad.KeepLoadingUnity'] = 'Continue loading Unity';
    this.defaultLanguageCollection['Settings.Security.IfTemplateFileCannotLoad.StopLoadingUnity'] = 'Stop loading Unity';
    this.defaultLanguageCollection['Settings.Security.IllegalFileSelected'] = 'This file cannot be selected as it is reserved by Unity';
    this.defaultLanguageCollection['Settings.Security.NotAnAdministrator'] = 'You have chosen to allow only the administrator to change Unity settings. Because you are not an administrator this means that you will lose this ability. Are you sure you want to continue?';
    this.defaultLanguageCollection['Settings.Security.OnlyAdminCanEditSettings'] = 'Only the administrator can edit Unity settings';
    this.defaultLanguageCollection['Settings.Security.UseTemplateFile'] = 'Use template';
    this.defaultLanguageCollection['Settings.Security.UseTemplateFile.FileNotLoaded'] = 'The template file has not been selected';
    this.defaultLanguageCollection['Settings.Services.AnonymousCallRejection'] = 'Anonymous Call Rejection';
    this.defaultLanguageCollection['Settings.Services.AnonymousCallRejection.Description'] = 'Prevent a caller from reaching you when the caller has explicitly restricted his/her number';
    this.defaultLanguageCollection['Settings.Services.AutomaticallyActivate'] = 'Automatically activate when starting';
    this.defaultLanguageCollection['Settings.Services.AutomaticallyActivate.ForceNumber'] = 'Force confirmation of remote office number on startup';
    this.defaultLanguageCollection['Settings.Services.AutomaticallyDeactivate'] = 'Automatically deactivate when closing';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere'] = 'Broadworks Anywhere';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.AlertAllLocations'] = 'Alert all locations for click-to-dial calls';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Description'] = 'Allows you to connect other mobile or fixed-line devices to your VoIP account and easily switch calls between these devices.';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Confirm'] = 'Are you sure you want to remove this number?';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.AcceptCallsFrom'] = 'Accept calls from:';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.AnyExternalNumber'] = 'any external phone number';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.AnyPrivateNumber'] = 'any private number';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.AnyUnavailableNumber'] = 'any unavailable number';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.Confirm'] = 'Are you sure you want to remove this criteria?';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.Description'] = 'Description';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.Description.NoCritera'] = 'At least one specific phone number or number type must be included in the criteria.';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.Description.NotEntered'] = 'A criteria description must be entered.';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.TheseNumbers'] = 'These numbers';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Criteria.TimeSchedule'] = 'Time schedule';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.Description'] = 'Description';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.EnableDiversionInhibitor'] = 'Enable diversion inhibitor';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.OutboundAlternateNumber'] = 'Outbound alternate phone number';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.RequireAnswerConfirmation'] = 'Require answer confirmation';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.ShowSelectionCriteria'] = 'Show selection criteria';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.TooManyNumbers'] = 'A maximum of 12 numbers can be entered. Please remote a number from the list before adding a new one.';
    this.defaultLanguageCollection['Settings.Services.BroadworksAnywhere.Number.UseCallControlServices'] = 'Use BroadWorks-based call control services';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility'] = 'Broadworks Mobility';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.AlertClickToDial'] = 'Alert for click-to-dial calls';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.AlertGroupPaging'] = 'Alert for group paging calls';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.Description'] = 'Allows you to connect another mobile or fixed-line device to your VoIP account and easily switch calls between devices';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.EnableDiversionInhibitor'] = 'Enable diversion inhibitor';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.MobileNumber'] = 'Mobile number';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.PhonesToRing'] = 'Phones to ring';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.RequireAnswerConfirm'] = 'Require answer confirmation';
    this.defaultLanguageCollection['Settings.Services.BroadworksMobility.UseCallControlServices'] = 'Use BroadWorks-based call control services';
    this.defaultLanguageCollection['Settings.Services.BusyForwardingPhoneNumberError'] = 'A forwarding number must be provided when using a Call Forward Busy service';
    this.defaultLanguageCollection['Settings.Services.CallCenter'] = 'Call Center';
    this.defaultLanguageCollection['Settings.Services.CallCenter.AbandonedCalls'] = 'Abandoned Calls';
    this.defaultLanguageCollection['Settings.Services.CallCenter.AbandonedCalls.Description'] = 'Configure how Unity displays and manages abandoned calls.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.AbandonedCalls.RefreshFrequency'] = 'Abandoned call list refresh frequency';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent'] = 'Agent';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.AcdState'] = 'ACD State';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.ActivateSignOutWhenClosing'] = 'Activate sign-out ACD state when Unity is closing.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.AutomaticallyAnswerCallCenterCalls'] = 'Automatically answer call center calls when I\'m available';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.Description'] = 'Configure call center agent settings such as ACD state';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.DesktopLockedAcdState'] = 'Desktop locked ACD state';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode'] = 'Force me to assign a disposition code';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode.Error'] = 'The Wrap-Up duration cannot be set when being forced to assign a disposition code';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode.InvalidNumber'] = 'The disposition code duration value is invalid. If specified it must be a valid number between 3 and 120, if not specified a duration timer will not be used';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.NoAvailableStateUntilDispositionCode.ToolTip'] = 'If a duration is specified then Unity will automatically close the popup after the duration has elapsed, meaning it is possible for the agent to not assign a disposition code to the call. This is especially relevant if there is a Wrap-Up timer in place for the call center';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.OnlyShowCurrentAcdStateButton'] = 'Only show the current ACD state button';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.PostCallAcdState'] = 'Post call ACD state';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.PreventMeFromChangingMyAcdState'] = 'Prevent me from manually changing my ACD state when on a call center call.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.ReleaseCallAfterAssigningDispositionCode'] = 'Release call after assigning disposition code';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.ShowAllAbandonedCalls'] = 'Show all abandoned calls in call log';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.StartupAcdState'] = 'Startup ACD state';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.WhenDisplayingAcdState'] = 'When displaying ACD state';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.WhenListingSupervisors'] = 'When listing supervisors:';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.WhenListingSupervisors.ShowAll'] = 'Include all supervisors in the call center';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.WhenListingSupervisors.ShowMine'] = 'Include only those supervisors that are currently supervising me';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.WrapupDurationInSeconds'] = 'Wrap-up duration (sec)';
    this.defaultLanguageCollection['Settings.Services.CallCenter.AgentActivityImageBasedOn'] = 'Agent activity image based on';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Alerts'] = 'Threshold Alerts';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Alerts.Description'] = 'Configure how Unity will display call center alerts as well as the default audio notification to play when thresholds are met';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Alerts.Display'] = 'When displaying a threshold alert';
    this.defaultLanguageCollection['Settings.Services.CallCenter.BatchLimit'] = 'Process call centers in batches of';
    this.defaultLanguageCollection['Settings.Services.CallCenter.CallCenterMonitoring.PlayWarningTone'] = 'Play warning tone when monitoring';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Description'] = 'Configure automatic call center login/logout. Only call centers that allow agent login/logout will be configured. Configuring call centers when the desktop is locked or unlocked is only available on Windows XP or above.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.IncludeCallsInCallLogs'] = 'Include call center calls in my calls logs';
    this.defaultLanguageCollection['Settings.Services.CallCenter.JoinAllCallCenters'] = 'Join all my call centers';
    this.defaultLanguageCollection['Settings.Services.CallCenter.LeaveAllCallCenters'] = 'Leave all my call centers';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Reporting'] = 'Reporting';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Reporting.DateFormat'] = 'Date format';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Reporting.Description'] = 'Configure the display format to use when building enhanced call center reports';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Reporting.FirstWeekDay'] = 'First day of week';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Reporting.TimeFormat'] = 'Time format';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics'] = 'Statistics';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.Columns.Description'] = 'Specify which columns should be displayed in the personal wallboard.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.Description'] = 'Configure call center statistics delivery. Call center passwords (required to gather statistics) can be entered in the Authentication section. The Client Call Control service must be assigned to the call center if real-time statistics are required';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeAcdStartStatistic'] = 'Include the \'ACD Start\' agent statistic';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeAcdStartStatistic.AsDuration'] = 'display the duration';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeAcdStartStatistic.AsTime'] = 'display the start time';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeDuration'] = 'Include call duration in user status';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeLongestWaitStatistic'] = 'Include \'Longest Wait Time\' statistic';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.IncludeUserStatus'] = 'Include user status in the agent statistics list';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.OnlyShowSummaryStatistics'] = 'Only display summary statistics';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.RefreshFrequency'] = 'Refresh frequency (seconds)';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.RefreshFrequency.MustBeWithinRange'] = 'The call center statistics refresh frequency must be equal to or greater than';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.RefreshFrequency.NotEntered'] = 'The statistics refresh frequency must be entered.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.RefreshFrequency.RangeTooLow'] = 'The call center statistics refresh frequency end value must be greater than the start value';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.RefreshFrequency.TooLow'] = 'The call center statistics refresh frequency must be equal or greater than';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.ShowCallsInQueueAsRealtimeStatistic'] = 'Show number of calls currently in queue as real-time statistic.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Statistics.UseScrollbars'] = 'Use scrollbars when the call center count equals';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.ApplyToAllQueues'] = 'Apply to all queues';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.AverageWaitTime'] = 'Average wait time (sec)';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.CallsInQueue'] = 'Calls in queue';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.CallsInQueue.Invalid'] = 'A valid number must be entered for the calls in queue threshold.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.MissedCalls'] = 'Missed calls';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.Queue'] = 'Queue:';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.SendEmailTo'] = 'Send email to';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Alert.SendEmailTo.ToolTip'] = 'Multiple email addresses must be seperated by a semicolon';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.AutomaticallyAddAllAgents'] = 'Automatically add all agents to my supervised agent list.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.JoinedAgentsOnlyInAgentActivityList'] = 'Only include joined agents in the agent activity list';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.MyAgentsOnlyInStaffedRatio'] = 'Only include my supervised agents in the staffed ratio';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.ShowQueuedCallsInCurrentCallList'] = 'Show queued calls in my current call list.';
    this.defaultLanguageCollection['Settings.Services.CallCenter.WhenComputerLocked'] = 'when the computer is locked';
    this.defaultLanguageCollection['Settings.Services.CallCenter.WhenComputerUnlocked'] = 'when the computer is unlocked';
    this.defaultLanguageCollection['Settings.Services.CallCenter.WhenUnityClosing'] = 'when Unity is closing';
    this.defaultLanguageCollection['Settings.Services.CallCenter.WhenUnityStarting'] = 'when Unity is starting';
    this.defaultLanguageCollection['Settings.Services.CallControl'] = 'Call Control';
    this.defaultLanguageCollection['Settings.Services.CallForwardAlways'] = 'Call Forward Always';
    this.defaultLanguageCollection['Settings.Services.CallForwardAlways.Description'] = 'Automatically forward all your incoming calls to a different phone number.';
    this.defaultLanguageCollection['Settings.Services.CallForwardAlwaysForwardingPhoneNumberError'] = 'A forwarding number must be provided when using a CallForwardAlways service';
    this.defaultLanguageCollection['Settings.Services.CallForwardBusy'] = 'Call Forward Busy';
    this.defaultLanguageCollection['Settings.Services.CallForwardBusy.Description'] = 'Automatically forward your calls to a different phone number when your phone is busy.';
    this.defaultLanguageCollection['Settings.Services.CallForwardNoAnswer'] = 'Call Forward No Answer';
    this.defaultLanguageCollection['Settings.Services.CallForwardNoAnswer.Description'] = 'Automatically forward your calls to a different phone number when you do not answer your phone after a certain number of rings.';
    this.defaultLanguageCollection['Settings.Services.CallForwardNoAnswer.NumberOfRings'] = 'Number of rings before forwarding';
    this.defaultLanguageCollection['Settings.Services.CallForwardNoAnswer.NumberOfRings.NotEntered'] = 'The number of rings before forwarding must be provided when using the Call Forward No Answer service.';
    this.defaultLanguageCollection['Settings.Services.CallingLineIdDeliveryBlocking'] = 'Calling Line ID Delivery Blocking';
    this.defaultLanguageCollection['Settings.Services.CallingLineIdDeliveryBlocking.Description'] = 'Prevent your phone number from being displayed when calling other numbers';
    this.defaultLanguageCollection['Settings.Services.CallLog.NumberOfCallsToInclude'] = 'Number of calls to include in each log';
    this.defaultLanguageCollection['Settings.Services.CallLog.NumberOfCallsToInclude.NotEntered'] = 'The number of calls to include in the call log must be entered.';
    this.defaultLanguageCollection['Settings.Services.CallLog.ServerAttempts'] = 'Call log server attempts';
    this.defaultLanguageCollection['Settings.Services.CallParkRetrieve'] = 'Call Park Retrieve';
    this.defaultLanguageCollection['Settings.Services.CallParkRetrieve.Description'] = 'Configure Unity to alert you when a call has been parked against your extension';
    this.defaultLanguageCollection['Settings.Services.CallParkRetrieve.ShowButton'] = 'Allow me to to retrieve parked calls';
    this.defaultLanguageCollection['Settings.Services.CallRecording'] = 'Call Recording';
    this.defaultLanguageCollection['Settings.Services.CallRecording.Description'] = 'Configure how calls are recorded and if notifications are played when changing the recording state for a call';
    this.defaultLanguageCollection['Settings.Services.CallRecording.NotificationType'] = 'Pause/resume notification';
    this.defaultLanguageCollection['Settings.Services.CallRecording.PlayStartStopAlert'] = 'Play call recording start/stop announcement';
    this.defaultLanguageCollection['Settings.Services.CallRecording.RecordingOption'] = 'Record calls';
    this.defaultLanguageCollection['Settings.Services.CallRecording.RecordVoicemail'] = 'Record voice messaging';
    this.defaultLanguageCollection['Settings.Services.CallRecording.RepeatAlert'] = 'Repeat call recording tone';
    this.defaultLanguageCollection['Settings.Services.CallRecording.ShowRecordingStateForCalls'] = 'Show recording state for calls';
    this.defaultLanguageCollection['Settings.Services.CallTransfer'] = 'Call Transfer';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.AllowBusyCampOn'] = 'Allow busy camp on';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.AllowRecall'] = 'Allow call transfer recall';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.AllowTransferToVoicemail'] = 'Allow transfer to voicemail for all internal users';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.AllowWarmTransfer'] = 'Allow warm transfer through Unity';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.CampCallsDuration'] = 'Camp calls for (seconds)';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.CampCallsDuration.MustBeWithinRange'] = 'The camp call duration must be between 30 and 600 seconds.';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.Description'] = 'Configure call transfer recall and busy camp on features. Busy camp on allows you to hold a call against another person\'s [busy] extension until they are available.';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.NumberOfRingsBeforeRecall'] = 'Number of rings before recall';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.NumberOfRingsBeforeRecall.MustBeWithinRange'] = 'The call transfer recall value must be between 2 and 20 rings.';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.SurveyExtension'] = 'Transfer calls to survey extension';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.SurveyExtension.Invalid'] = 'The survey extension is invalid';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.UseDiversionInhibitorForBlindTransfer'] = 'Use diversion inhibitor for blind transfer';
    this.defaultLanguageCollection['Settings.Services.CallTransfer.UseDiversionInhibitorForConsultativeCalls'] = 'Use diversion inhibitor for consultative calls';
    this.defaultLanguageCollection['Settings.Services.CallWaiting'] = 'Call Waiting';
    this.defaultLanguageCollection['Settings.Services.CallWaiting.Description'] = 'Answer a call while already on another call.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress'] = 'Comm Pilot Express';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Busy'] = 'Busy';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Busy.Description'] = 'This profile is used when you are temporarily unavailable to take calls, for example when you are in a meeting.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Busy.ForwardingNumberIsBusy'] = 'The number provided as a forwarding number when busy is invalid.The character\'%S\'is not allowed';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Busy.NotifyMeOfNewVoicemails'] = 'Notify me of new voicemails via email';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.ButForwardCallsFromTheseNumbers'] = 'But forward calls from these numbers';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.ButForwardCallsFromTheseNumbers.NotEntered'] = 'At least one number must be provided when forwarding calls.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.ButForwardCallsFromTheseNumbers.TooManyNumbers'] = 'Only three numbers can be used in the exception list. Please remove a number before adding this one.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Description'] = 'CommPilot Express allows you to pre-configure four profiles to control your inbound calls. These profiles can quickly be changed through Unity when you leave your desk or are at a remote location.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.DivertAllCallsToVoicemail'] = 'Divert all calls to voicemail (if configured)';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.ForwardCall'] = 'forward call';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice'] = 'Available: In Office';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice.AlsoRing'] = 'Also ring';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice.Description'] = 'This profile is used when you are working from your desk where your phone is located.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice.ForwardingIfBusy'] = 'The number provided as a forwarding number when busy is invalid.The character %s is not allowed';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice.IfBusy'] = 'If busy:';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.InOffice.IfNotAnswered'] = 'If not answered';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.NoLongerAssigned'] = 'The CommPilot Express user service is no longer assigned meaning call profile functionality has been lost. Please contact your service provider to re-assign this service.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.OutOfOffice'] = 'Available: Out Of Office';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.OutOfOffice.Description'] = 'This profile is used when you are working away from your desk for an extended period of time.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.OutOfOffice.ForwardingNotAnswered'] = 'The number provided as a forwarding number when not answered is invalid. The character %s is not allowed';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.OutOfOffice.NotifyMeOfCallsViaEmail'] = 'Notify me of all calls via email';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.OutOfOffice.WhenCallArrives'] = 'When a call arrives:';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.SendCallToVoicemail'] = 'send call to voicemail';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable'] = 'Unavailable';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable.Description'] = 'This profile is used outside of business hours or when you are on vacation or holiday.';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable.ForwardIBusyNumberError'] = 'The forwarding number entered for forwarding calls is invalid. The character %s is not allowed';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail'] = 'When a call is sent to voicemail';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail.UseNoAnswerGreeting'] = 'use \"no answer\" greeting';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.Unavailable.WhenCallSentToVoicemail.UseUnavailableGreeting'] = 'use \"unavailable\" greeting';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.WhenUnityClosing'] = 'When Unity closing set my profile to';
    this.defaultLanguageCollection['Settings.Services.CommPilotExpress.WhenUnityStarting'] = 'When Unity starting set my profile to';
    this.defaultLanguageCollection['Settings.Services.DirectedCallPickupWithBargeIn'] = 'Directed Call Pickup With Barge-in';
    this.defaultLanguageCollection['Settings.Services.DirectedCallPickupWithBargeIn.Description'] = 'Pick up or barge-in on a call to another extension. If the call has not been answered then it is picked up otherwise a conference call is created between you the called extension and the calling party.';
    this.defaultLanguageCollection['Settings.Services.DirectedCallPickupWithBargeIn.PlayWarningTone'] = 'Play warning tone';
    this.defaultLanguageCollection['Settings.Services.DoNotDisturb'] = 'Do Not Disturb';
    this.defaultLanguageCollection['Settings.Services.DoNotDisturb.Description'] = 'Automatically forward your calls to your voice messaging service if configured, otherwise the caller hears a busy tone.';
    this.defaultLanguageCollection['Settings.Services.DoNotDisturb.NoLongerAssigned'] = 'The do not disturb user service is no longer assigned. Please contact your service provider to re-assign this service.';
    this.defaultLanguageCollection['Settings.Services.Email.Invalid'] = 'The email address entered is not valid.';
    this.defaultLanguageCollection['Settings.Services.Email.NotEntered'] = 'A valid email address must be entered.';
    this.defaultLanguageCollection['Settings.Services.Enabled'] = 'Enabled';
    this.defaultLanguageCollection['Settings.Services.ExternalCallingLineIdDelivery'] = 'External Calling Line ID Delivery';
    this.defaultLanguageCollection['Settings.Services.ExternalCallingLineIdDelivery.Confirm'] = 'Unity uses this service to find contact details for calls to and from parties outside the business. Are you sure you want to disable it?';
    this.defaultLanguageCollection['Settings.Services.ExternalCallingLineIdDelivery.startUpPrompt'] = 'The External Calling Line Id Delivery user service is assigned but not currently enabled. Unity requires this service to find contact details of incoming calls from outside the business. Would you like to enable this service now?';
    this.defaultLanguageCollection['Settings.Services.ExternalCallLineIdDelivery.Description'] = 'Provides Calling Line ID information of an incoming call from outside the group. Unity requires this service to be enabled in order to show caller details.';
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest'] = 'Flexible Seating Guest';
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest.AssociationLimit.MustBeWithinRange'] = 'The flexible seating guest association limit must be within the range';
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest.AssociationLimit.NotEntered'] = 'The flexible seating guest association limit must be entered when associating your profile with a flexible seating host';
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest.DeviceName'] = 'Device name';
    this.defaultLanguageCollection['Settings.Services.ForwardNumber.NotEntered'] = 'A forwarding number must be provided when forwarding calls.';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest'] = 'Hoteling Guest';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.AssociationLimit'] = 'Association limit';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.AssociationLimit.MustBeWithinRange'] = 'The hoteling guest association limit must be within the range';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.AssociationLimit.NotEntered'] = 'The hoteling guest association limit must be entered when associating your profile with a hoteling host.';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.Description'] = 'Allows a user to associate their account with a host device and use the host device as their primary device.';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.HostName'] = 'Host name';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.Hours'] = 'hours (enter 0 for unlimited association)';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.NoLongerAssigned'] = 'The Hoteling Guest user service is no longer assigned meaning all hot-desking functionality has been lost. Please contact your service provider to re-assign this service.';
    this.defaultLanguageCollection['Settings.Services.HotelingGuest.ToolTip'] = 'Refresh the list of available hoteling hosts';
    this.defaultLanguageCollection['Settings.Services.IncomingCalls'] = 'Incoming Calls';
    this.defaultLanguageCollection['Settings.Services.InternalCallingLineIdDelivery'] = 'Internal Calling Line ID Delivery';
    this.defaultLanguageCollection['Settings.Services.InternalCallingLineIdDelivery.Confirm'] = 'Unity uses this service to find contact details for calls to and from parties within the business. Are you sure you want to disable it?';
    this.defaultLanguageCollection['Settings.Services.InternalCallingLineIdDelivery.startUpPrompt'] = 'The Internal Calling Line Id Delivery user service is assigned but not currently enabled. Unity requires this service to find contact details of incoming calls from within the business. Would you like to enable this service now?';
    this.defaultLanguageCollection['Settings.Services.InternalCallLineIdDelivery.Description'] = 'Provide Calling Line ID information of an incoming call from within the group. Unity requires this service to be enabled in order to show caller details.';
    this.defaultLanguageCollection['Settings.Services.InvalidPhoneNumber'] = 'This number is not a valid phone number The character \'%s\' is not permitted';
    this.defaultLanguageCollection['Settings.Services.Messaging'] = 'Messaging';
    this.defaultLanguageCollection['Settings.Services.NoAnswerForwardingPhoneNumberError'] = 'A forwarding number must be provided when using a Call Forward No Answer service';
    this.defaultLanguageCollection['Settings.Services.OutgoingCalls'] = 'Outgoing Calls';
    this.defaultLanguageCollection['Settings.Services.PersonalWallboard.AgentListHeading'] = 'Agent Columns';
    this.defaultLanguageCollection['Settings.Services.PersonalWallboard.Description'] = 'Specify which columns should be displayed in the personal wallboard. Threshold values can also be set for many columns';
    this.defaultLanguageCollection['Settings.Services.PersonalWallboard.QueueListHeading'] = 'Call Center Columns';
    this.defaultLanguageCollection['Settings.Services.RemoteOffice'] = 'Remote Office';
    this.defaultLanguageCollection['Settings.Services.RemoteOffice.Description'] = 'Use your home mobile or any other other phone as your business phone and control it through Unity.';
    this.defaultLanguageCollection['Settings.Services.RemoteOffice.Number.NotEntered'] = 'A phone number must be entered when using the Remote Office service.';
    this.defaultLanguageCollection['Settings.Services.RingSplash'] = 'Ring splash';
    this.defaultLanguageCollection['Settings.Services.Sca'] = 'Shared Call Appearance';
    this.defaultLanguageCollection['Settings.Services.Sca.AlertAllAppearances'] = 'Alert all appearances for click-to-dial calls';
    this.defaultLanguageCollection['Settings.Services.Sca.AllowBridging'] = 'Allow bridging between locations';
    this.defaultLanguageCollection['Settings.Services.Sca.AllowCallRetrieve'] = 'Allow call retrieve from another location';
    this.defaultLanguageCollection['Settings.Services.Sca.Description'] = 'Manage all phones that are assigned to your user account.';
    this.defaultLanguageCollection['Settings.Services.Sca.Device.AllowInboundCalls'] = 'Allow inbound calls';
    this.defaultLanguageCollection['Settings.Services.Sca.Device.AllowOutboundCalls'] = 'Allow outbound calls';
    this.defaultLanguageCollection['Settings.Services.Sca.Device.DeviceType'] = 'Device Type';
    this.defaultLanguageCollection['Settings.Services.Sca.Device.Enable'] = 'Enabled';
    this.defaultLanguageCollection['Settings.Services.Sca.Device.Type'] = 'Device Type';
    this.defaultLanguageCollection['Settings.Services.Sca.LinePort'] = 'Line Port';
    this.defaultLanguageCollection['Settings.Services.Sca.MultipleCallArrangement'] = 'Multiple call arrangement';
    this.defaultLanguageCollection['Settings.Services.Sca.SecondaryDevices'] = 'Secondary Devices';
    this.defaultLanguageCollection['Settings.Services.Sca.ToolTip'] = 'Double click a device for configuration options';
    this.defaultLanguageCollection['Settings.Services.Sca.WarningTone'] = 'Warning tone';
    this.defaultLanguageCollection['Settings.Services.SimRing'] = 'Simultaneous Ring';
    this.defaultLanguageCollection['Settings.Services.SimRing.Description'] = 'Ring multiple phones simultaneously when calls are received.';
    this.defaultLanguageCollection['Settings.Services.SimRing.DoNotRingWhenOnACall'] = 'Don\'t ring these numbers when I\'m on a call.';
    this.defaultLanguageCollection['Settings.Services.SimRing.Numbers.NotEntered'] = 'At least one telephone number must be provided when using the Simultaneous Ring service.';
    this.defaultLanguageCollection['Settings.Services.Voicemail'] = 'Voicemail';
    this.defaultLanguageCollection['Settings.Services.Voicemail.AlwaysRedirectToVoiceMail'] = 'Send all calls to voicemail';
    this.defaultLanguageCollection['Settings.Services.Voicemail.BusyRedirectToVoiceMail'] = 'Send busy calls to voicemail';
    this.defaultLanguageCollection['Settings.Services.Voicemail.Description'] = 'Configure your personal voice messaging.';
    this.defaultLanguageCollection['Settings.Services.Voicemail.EmailCopyOfVoicemail'] = 'Email a copy of the voicemail';
    this.defaultLanguageCollection['Settings.Services.Voicemail.EmailNotificationOfNewVoicemails'] = 'Email notification of new voicemails';
    this.defaultLanguageCollection['Settings.Services.Voicemail.ForwardViaEmail'] = 'Forward the voicemail via email';
    this.defaultLanguageCollection['Settings.Services.Voicemail.NoAnswerRedirectToVoiceMail'] = 'Send unanswered calls to voicemail';
    this.defaultLanguageCollection['Settings.Services.Voicemail.ShowVisualVoicemailTab'] = 'Show visual voicemail tab';
    this.defaultLanguageCollection['Settings.Services.Voicemail.TransferFromVoicemailOnZero'] = 'Transfer from voicemail on zero';
    this.defaultLanguageCollection['Settings.Services.Voicemail.TransferFromVoicemailOnZero.NotEntered'] = 'A forwarding number must be supplied when transferring on zero.';
    this.defaultLanguageCollection['Settings.Services.Voicemail.UseMwi'] = 'Use phone message waiting indicator';
    this.defaultLanguageCollection['Settings.Services.Voicemail.UseUnifiedMessaging'] = 'Use unified messaging';
    this.defaultLanguageCollection['Settings.Startup'] = 'Startup';
    this.defaultLanguageCollection['Settings.Startup.AlwaysStartMinimized'] = 'Always start in minimized state';
    this.defaultLanguageCollection['Settings.Startup.AutoStartOnOsBoot'] = 'Auto start Unity on operating system boot';
    this.defaultLanguageCollection['Settings.Startup.Description'] = 'Configure Unity to automatically start when the operating system does. You can also select which Unity application to load on startup.';
    this.defaultLanguageCollection['Settings.Startup.MultipleInstancesPermitted'] = 'Multiple Unity instances permitted';
    this.defaultLanguageCollection['Settings.Startup.StartupApplication'] = 'Startup application:';
    this.defaultLanguageCollection['Settings.Text'] = 'Services & Settings';
    this.defaultLanguageCollection['Settings.UnityConnect.CurrentlyConnectedClients'] = 'Currently connected clients:';
    this.defaultLanguageCollection['Settings.UnitySettings'] = 'Unity Settings';
    this.defaultLanguageCollection['Settings.Update'] = 'Update';
    this.defaultLanguageCollection['Settings.Update.AlertMe'] = 'Alert me after update';
    this.defaultLanguageCollection['Settings.Update.AutoRestart'] = 'Automatically restart after update';
    this.defaultLanguageCollection['Settings.Update.AutoUpdate'] = 'Auto update Unity';
    this.defaultLanguageCollection['Settings.Update.AutoUpdate.CheckForUpdateEvery'] = 'Check for updates every';
    this.defaultLanguageCollection['Settings.Update.AutoUpdate.Hours'] = 'hours';
    this.defaultLanguageCollection['Settings.Update.AutoUpdateOnCitrix'] = 'Auto update when in a Terminal Server or Citrix environment';
    this.defaultLanguageCollection['Settings.Update.Description'] = 'Configure Unity to automatically update periodically.';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow'] = 'Perform manual update now';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.Confirm'] = 'Are you sure you want to attempt an update now? Unity cannot be closed while the update is in progress';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.Failure'] = 'An error has occurred while applying the update latest Unity update';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.NoUpdatesAvailable'] = 'There are currently no updates available.';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.RuntimeDownloadCancelled'] = 'The required runtime must be installed before the latest Unity update can be applied.';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.RuntimeDownloadInProgress'] = 'Please perform another manual update once installation is complete.';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.UpdateExists'] = 'The latest update has already been downloaded. Use the rollback feature to change the version.';
    this.defaultLanguageCollection['Settings.Update.PerformManualUpdateNow.UpdatePending'] = 'There is already an update or rollback waiting to be applied. Please restart Unity.';
    this.defaultLanguageCollection['Settings.Update.Rollback'] = 'Rollback';
    this.defaultLanguageCollection['Settings.Update.Rollback.Confirm'] = 'Are you sure you want to rollback Unity?';
    this.defaultLanguageCollection['Settings.Update.Rollback.Failure'] = 'Unity was unable to rollback as it could not read version information from the executable file.';
    this.defaultLanguageCollection['Settings.Update.Rollback.FileNotFound'] = 'Unable to perform rollback as the associated Unity file was not found.';
    this.defaultLanguageCollection['Settings.Update.Rollback.Success'] = 'Unity has been successfully rolled back but a restart is required. Would you like to do this now?';
    this.defaultLanguageCollection['Settings.Update.Rollback.UpdateAvailable'] = 'Unity cannot be rolled back now because an update is available. Please restart the application then try again.';
    this.defaultLanguageCollection['Settings.Update.Rollback.UpdateInProgress'] = 'Unity cannot be rolled back now because an update is in progress. Please try again later.';
    this.defaultLanguageCollection['Settings.Update.Rollback.VersionsAvailable'] = 'Versions available';
    this.defaultLanguageCollection['Settings.Update.Runtime'] = 'There is a Unity update available but it requires the below component which is not currently installed. Would you like to install this component now?';
    this.defaultLanguageCollection['Settings.Update.Runtime.DownloadPath'] = 'Location';
    this.defaultLanguageCollection['Settings.Update.Runtime.MinimumVersion'] = 'Minimum Version';
    this.defaultLanguageCollection['Settings.Update.Runtime.Name'] = 'Runtime Name';
    this.defaultLanguageCollection['Settings.Update.Success'] = 'Unity has updated itself. Would you like to restart the application now to use the latest version?';
    this.defaultLanguageCollection['Settings.Wallboard.Columns'] = 'Columns';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Alignment'] = 'Alignment';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Alignment.NotEntered'] = 'The column alignment must be selected.';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.AudioFileToPlay'] = 'Audio file to play when alerting';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.AudioFileToPlay.DefaultToolTip'] = 'This is the default file which can be overridden when setting thresholds';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.AudioFileToPlay.OverrideToolTip'] = 'This will override the default WAV file entered';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Confirm'] = 'Are you sure you want to remove this column? This will mean the statistic will no longer be displayed.';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Heading'] = 'Column heading';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Heading.NotEntered'] = 'The column heading must be entered.';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.HighlightNonZeroValues'] = 'Highlight non-zero values';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.IncreasingThresholds'] = 'Increasing thresholds';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.NotEntered'] = 'At least one column must be included in the statistics list';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.PlayAudioFile'] = 'Play audio file';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.PlayAudioFile.Invalid'] = 'The audio notification threshold value must be a valid number.';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.PlayAudioFile.NotEntered'] = 'The WAV file to play as a threshold notification must be selected';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.PlayAudioFile.NoThresholdValueEntered'] = 'At least one threshold value must be set when specifying an audio notification';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.SetThresholdValue'] = 'Set threshold alert value';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.SetThresholdValue.Invalid'] = 'The threshold value must be a valid number';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.SetThresholdValue.ToolTip'] = 'Unity will ignore this threshold if a number is not entered';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.ShowAlert'] = 'Show threshold alert';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.ShowSummaryRowInAgentActivity'] = 'Show summary row in list';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Statistic'] = 'Statistic to display';
    this.defaultLanguageCollection['Settings.Wallboard.Columns.Statistic.NotEntered'] = 'The column type must be selected.';
    this.defaultLanguageCollection['Settings.Wallboard.Display.FontSize'] = 'Font size';
    this.defaultLanguageCollection['Settings.Wallboard.Display.HeadingText'] = 'Heading text';
    this.defaultLanguageCollection['Settings.Wallboard.Display.LogoPath'] = 'Logo path';
    this.defaultLanguageCollection['Settings.Wallboard.Display.LoopQueues'] = 'Loop queues if required';
    this.defaultLanguageCollection['Settings.Wallboard.Display.Queues'] = 'queues at a time';
    this.defaultLanguageCollection['Settings.Wallboard.Display.Queues.NotEntered'] = 'The number of queues to display must be entered.';
    this.defaultLanguageCollection['Settings.Wallboard.Display.RefreshEvery'] = 'Refresh statistics every';
    this.defaultLanguageCollection['Settings.Wallboard.Display.RefreshEvery.NotEntered'] = 'The queue display frequency must be entered.';
    this.defaultLanguageCollection['Settings.Wallboard.Display.Show'] = 'Show';
    this.defaultLanguageCollection['Settings.Wallboard.Display.ShowAllQueues'] = 'Show all queues at once';
    this.defaultLanguageCollection['Settings.Wallboard.Display.ShowQueuesWithThresholds'] = 'Always show queues with threshold alerts';
    this.defaultLanguageCollection['Settings.Wallboard.Display.UseLogoFromBrand'] = 'Use logo from brand';
    this.defaultLanguageCollection['TabShowing.AbandonedCalls'] = 'Abandoned Calls';
    this.defaultLanguageCollection['TabShowing.AbandonedCalls.New'] = 'New';
    this.defaultLanguageCollection['TabShowing.AgentActivity'] = 'Agent Activity';
    this.defaultLanguageCollection['TabShowing.CallLog'] = 'Call Logs';
    this.defaultLanguageCollection['TabShowing.CallLog.Clear.Confirm'] = 'Are you sure you want to clear all call logs? This action cannot be undone.';
    this.defaultLanguageCollection['TabShowing.CallLog.Clear.ToolTip'] = 'Clear all call logs';
    this.defaultLanguageCollection['TabShowing.CallLog.Dialled'] = 'Dialled calls';
    this.defaultLanguageCollection['TabShowing.CallLog.Export.AllLogs'] = 'Export all logs';
    this.defaultLanguageCollection['TabShowing.CallLog.Export.Success'] = 'The call log export was successfully completed.';
    this.defaultLanguageCollection['TabShowing.CallLog.Export.ThisLog'] = 'Export this log';
    this.defaultLanguageCollection['TabShowing.CallLog.Export.ToolTip'] = 'Export call log';
    this.defaultLanguageCollection['TabShowing.CallLog.Missed'] = 'Missed calls';
    this.defaultLanguageCollection['TabShowing.CallLog.Missed.WithNumber'] = 'Missed';
    this.defaultLanguageCollection['TabShowing.CallLog.Received'] = 'Received calls';
    this.defaultLanguageCollection['TabShowing.CallLog.Refresh.ToolTip'] = 'Reload call log';
    this.defaultLanguageCollection['TabShowing.CallRecording'] = 'Call Recording';
    this.defaultLanguageCollection['TabShowing.Contacts'] = 'Contacts';
    this.defaultLanguageCollection['TabShowing.CurrentPresence'] = 'Current';
    this.defaultLanguageCollection['TabShowing.Directory'] = 'Directories';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryName'] = 'Directory name';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Custom'] = 'Custom';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Department'] = 'Department';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Enterprise'] = 'Enterprise';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Group'] = 'Group';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.GroupCommon'] = 'Common phone list';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Outlook'] = 'Outlook contacts';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.SpeedDial'] = 'Speed dials';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.User'] = 'Personal directory';
    this.defaultLanguageCollection['TabShowing.Directory.SearchBox.ToolTip'] = 'Search company and personal directories for contact details. Press enter to include Outlook contacts and custom directories.';
    this.defaultLanguageCollection['TabShowing.Directory.SearchButton.ToolTip'] = 'Search contacts';
    this.defaultLanguageCollection['TabShowing.ScheduledPresence'] = 'Scheduled';
    this.defaultLanguageCollection['TabShowing.Services'] = 'Services';
    this.defaultLanguageCollection['TabShowing.Settings'] = 'Settings';
    this.defaultLanguageCollection['TabShowing.Statistics'] = 'Statistics';
    this.defaultLanguageCollection['TabShowing.UserStatus'] = 'User Status';
    this.defaultLanguageCollection['TabShowing.Voicemail'] = 'Voicemail';
    this.defaultLanguageCollection['ThresholdAlert.Close.Tooltip'] = 'Close this alert but keep the threshold active';
    this.defaultLanguageCollection['ThresholdAlert.View.Toolip'] = 'Pause/ignore this threshold alert until Unity is restarted';
    this.defaultLanguageCollection['ThresholdAlert.View.Tooltip'] = 'Show the threshold value for this alert';
    this.defaultLanguageCollection['UnityServer.RegistrationOverridden'] = 'Your registration on the Unity instant messaging server has been overridden, meaning all messaging functionality has been lost. You must restart Unity to re-register on the instant messaging server with this login.';
    this.defaultLanguageCollection['UnityServer.UnknownError'] = 'The Unity server has experienced an internal error and your connection has been closed, meaning all messaging functionality has been lost. You must restart Unity to re-register on the instant messaging server with this login.';
    this.defaultLanguageCollection['UserDetails.Email'] = 'Email:';
    this.defaultLanguageCollection['UserDetails.Mobile'] = 'Mobile:';
    this.defaultLanguageCollection['UserDetails.Title'] = 'Title:';
    this.defaultLanguageCollection['UserDetails.WavFile'] = 'Wav File:';
    this.defaultLanguageCollection['UserDetails.WavFile.Delete'] = 'Are you sure you want to delete this audio notification?';
    this.defaultLanguageCollection['UserDetails.WavFile.ToolTip'] = 'This WAV file will be played on a loop when the users phone is ringing';
    this.defaultLanguageCollection['UserDetails.WavFile.ToolTip.Add'] = 'Add a WAV file which will be played on a loop when the users phone is ringing';
    this.defaultLanguageCollection['UserDetails.WavFile.ToolTip.Remove'] = 'Remove the alerting WAV file associated with this user';
    this.defaultLanguageCollection['UserTemplate.Name.Invalid'] = 'The user template name must be entered';
    this.defaultLanguageCollection['View.Details'] = 'Details';
    this.defaultLanguageCollection['View.LargeIcon'] = 'Large icon';
    this.defaultLanguageCollection['View.List'] = 'List';
    this.defaultLanguageCollection['View.SmallIcon'] = 'Small icon';
    this.defaultLanguageCollection['View.Twocolumns'] = '2 columns';
    this.defaultLanguageCollection['View.Tile'] = 'Tile';
    this.defaultLanguageCollection['Prompt.CallAlerting'] = "This action cannot be performed as the call is not active";
    this.defaultLanguageCollection['Prompt.CallTransferNotAssigned'] = 'The Call Transfer user service has not been assigned. Without it call transfer functionality will not be available.';
    this.defaultLanguageCollection['Misc.Yes'] = 'Yes';
    this.defaultLanguageCollection['Misc.No'] = 'No';
    this.defaultLanguageCollection['About.BrowserDetails'] = 'Browser details';
    this.defaultLanguageCollection['Conversation.NewChat'] = 'New chat';
    this.defaultLanguageCollection['Conversation.OfflineUser'] = 'Offline user';
    this.defaultLanguageCollection['Conversation.OfflineNotAdded'] = 'Offline user cannot be added';
    this.defaultLanguageCollection['Misc.Send'] = 'Send';
    this.defaultLanguageCollection['Conversation.WriteMessageHere'] = 'Write your message here';
    this.defaultLanguageCollection['Prompt.Subscribing'] = 'Subscribing';
    this.defaultLanguageCollection['Prompt.EstablishingCtiConnection'] = 'Establishing CTI connection';
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest.Description'] = "Allows a user to associate their service profile with a host user and use the host user's device as their primary device.";//hardcode
    this.defaultLanguageCollection['Settings.Services.FlexibleSeatingGuest.HostAssociation'] = "Host Association";//hardcode
    this.defaultLanguageCollection['ContactCenter.Type.Call'] = 'Call';
    this.defaultLanguageCollection['TabShowing.Voicemail.Search'] = 'Search voicemail';
    //new tags
    this.defaultLanguageCollection['Misc.Login'] = 'Login';
    this.defaultLanguageCollection['Prompt.DispositionCode.ForceMe.PlaceHolder'] = 'Select a disposition code';

    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.ShowDuration'] = 'Show duration';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.ShowStartTime'] = 'Show start time';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Agent.DontShowAnything'] = "Don't show anything";
    this.defaultLanguageCollection['CallButtonShowing.OpenChat'] = 'Open Chat';
    this.defaultLanguageCollection['CallButtonShowing.CloseChat'] = 'Close Chat';
    this.defaultLanguageCollection['Conversation.AddToChat'] = 'Add to chat';
    this.defaultLanguageCollection['Settings.Services.CallRecording.Always'] = 'Always';
    this.defaultLanguageCollection['Settings.Services.CallRecording.Never'] = 'Never';
    this.defaultLanguageCollection['Settings.Services.CallRecording.OnDemand'] = 'On Demand';
    this.defaultLanguageCollection['Settings.Services.CallRecording.AlwaysWithPauseResume'] = 'Always with pause/resume';
    this.defaultLanguageCollection['Settings.Services.CallRecording.OnDemandWithUserInitiatedStart'] = 'On Demand with User Initiated Start';
    this.defaultLanguageCollection['Settings.Services.CallRecording.PlayAnnouncement'] = 'Play Announcement';
    this.defaultLanguageCollection['Settings.Services.CallRecording.Beep'] = 'Beep';
    this.defaultLanguageCollection['Misc.Logout.Success'] = 'Successfully logged out';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsInQueue'] = 'Calls In Queue';
    this.defaultLanguageCollection['List.Statistics.Column.CallbackAnswered'] = 'Callback Answered';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsAnswered'] = 'Calls Answered';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsAnsweredPercentage'] = 'Calls Answered %';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsAbandonedPercentage'] = 'Calls Abandoned %';

    this.defaultLanguageCollection['List.Statistics.Column.TotalTalkTimeNew'] = 'Total Talk Time';
    this.defaultLanguageCollection['List.Statistics.Column.TotalMissedCallsPercentageNew'] = 'Total Missed Calls %';
    this.defaultLanguageCollection['List.Statistics.Column.OverflowedTotal'] = 'Overflowed-Total';
    this.defaultLanguageCollection['List.Statistics.Column.OverflowedTotalPercentage'] = 'Overflowed-Total %';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsTransferred'] = 'Calls Transferred';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfCallsTransferredPercentageNew'] = 'Calls Transferred %';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfBusyOverflowsNew'] = 'Overflowed-Busy';
    this.defaultLanguageCollection['List.Statistics.Column.NumberOfBusyOverflowsPercentageNew'] = 'Overflowed-Busy %';
    this.defaultLanguageCollection['List.Statistics.Column.AverageAbandonmentTimeNew'] = 'Average Abandoned Time';
    this.defaultLanguageCollection['List.Statistics.Column.TotalMissedCallsNew'] = 'Total Missed Calls';
    this.defaultLanguageCollection['List.Statistics.Column.TotalMissedCalls'] = 'Missed Calls';

    this.defaultLanguageCollection['Settings.Connection.Authentication.RemovePassword'] = 'Remove password';

    this.defaultLanguageCollection['Menu.Context.MeetMeConference.MyConferenceBridge'] = 'My conference bridge';
    this.defaultLanguageCollection['Menu.Context.MeetMeConference.MyConferenceBridge.Copy'] = 'Copy details to clipboard';
    this.defaultLanguageCollection['Menu.Context.MeetMeConference.MyConferenceBridge.DialAsModerator'] = 'Call bridge as moderator';
    this.defaultLanguageCollection['Menu.Context.MeetMeConference.MyConferenceBridge.Show'] = 'Show details';

    this.defaultLanguageCollection['Settings.Connection.Network.ConnectionIsSecure'] = 'Secure connection using TLS';
    this.defaultLanguageCollection['Settings.Connection.Network.TlsServerPort'] = 'TLS server port';
    this.defaultLanguageCollection['Settings.Connection.Network.TlsCertificateServerHost'] = 'TLS certificate hostname';
    this.defaultLanguageCollection['Settings.Connection.Network.TlsSecurityProtocol'] = 'TLS security protocol';
    this.defaultLanguageCollection['Settings.Connection.Network.ClientsUseCti'] = 'Use CTI protocol';
    this.defaultLanguageCollection['Prompt.Connection.Dropped'] = 'The connection has dropped, please restart Unity';

    this.defaultLanguageCollection['Misc.Authorise'] = 'Authorise';
    this.defaultLanguageCollection['Misc.Authorise.TokenNotSaved'] = 'Authorisation token not saved';
    this.defaultLanguageCollection['Misc.Authorise.TokenSaved'] = 'Authorisation token saved';
    this.defaultLanguageCollection['Misc.Authorise.Cancel'] = 'Cancel authorisation';
    this.defaultLanguageCollection['Misc.Authorise.Complete'] = 'Complete authorisation';
    this.defaultLanguageCollection['Misc.Authorise.TokenNotReturnedError'] = 'There was an error when trying to download the token. Please ensure you have successfully completed authorisation in the browser and try again';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowTitle'] = 'Show title';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowEmail'] = 'Show email';
    this.defaultLanguageCollection['Prompt.BrandingResponseUnsuccessful'] = 'Unity was unable to fetch branding settings from the Unity cloud.  Please try again and consult your system provider if problems persist';
    this.defaultLanguageCollection['Prompt.License.LicenseRequestFailed'] = 'An error has occurred on the Unity licensing server while trying to process this license request.  Please try again and consult your system provider if problems persist';
    this.defaultLanguageCollection['Prompt.SettingsNotSaved'] = 'There was an unexpected error that prevented user details from being saved in the Unity cloud.  Please consult your system provider if this leads to unexpected behaviour';
    this.defaultLanguageCollection['Prompt.RequestFailed'] = 'Unity attempted to send a request to the VoIP platform but it was unsuccessful.  The command type was';
    //  not in portal
    this.defaultLanguageCollection['Settings.Cti.BrowserIntegration.EncodeDelimiter'] = 'Encode delimiter values when adding to the URL';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Title'] = 'Title';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.Email'] = 'Email';
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.BusinessName'] = 'Business Name';

    //new
    this.defaultLanguageCollection['TabShowing.Directory.DirectoryType.DefaultList'] = 'Default List';
    this.defaultLanguageCollection['CallButtonShowing.Survey'] = 'Survey';
    this.defaultLanguageCollection['Menu.Context.AnswerThisCall'] = 'Answer this call';
    this.defaultLanguageCollection['Menu.Context.CallExtensionWhenAvailable'] = 'Call extension when available';
    this.defaultLanguageCollection['UserDetails.Location'] = 'Location';
    this.defaultLanguageCollection['Menu.Context.Appearance.ShowLocation'] = 'Show location';
    this.defaultLanguageCollection['Prompt.EstablishingXsiConnection'] = 'Establishing XSI connection';

    this.defaultLanguageCollection['Prompt.OciOverSoap'] = 'Unity is configured to use OCI-P over SOAP but this interface is not available on the VoIP platform.  Please contact your service provider for more information';
    //supervisor
    this.defaultLanguageCollection['List.Statistics.Column.AgentType'] = 'Agent type';

    this.defaultLanguageCollection['Prompt.JoinAllAgents.CallCenter'] = "Are you sure you want to join all agents to this queue?";

    this.defaultLanguageCollection['Prompt.UnjoinAllAgents.CallCenter'] = "Are you sure you want to unjoin all agents from this queue? This may result in calls being missed";

    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.Description'] = "Configure call center supervisor setting. Individual alert can also be set when overall queue thresholds are reached. The Client Call Control service must be assigned to the call center in order to show queued calls";
    this.defaultLanguageCollection['Misc.General'] = "General";
    this.defaultLanguageCollection['Settings.Services.CallCenter.AgentActivityImageBasedOn'] = "Agent activity image based on";

    this.defaultLanguageCollection['List.Statistics.Column.TotalReceivedCalls'] = 'Total Received Calls';
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor'] = "Supervisor";
    this.defaultLanguageCollection['Misc.ForcedForward'] = 'Forced Forward';

    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.CallCenterMonitoring'] = "Call Center Monitoring";
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.CallCenterMonitoring.Description'] = "Configure call center monitoring to alert the agent when they are being monitored";
    this.defaultLanguageCollection['Settings.Services.CallCenter.Supervisor.CallCenterMonitoring.PlayingWarningTone'] = "Playing warning tone when monitoring";
    this.defaultLanguageCollection['List.CurrentCalls.Item.Priority'] = 'Priority';
    this.defaultLanguageCollection['Settings.Reception.CurrentCalls.ShowQueuedCallPriority'] = 'Include call priority';

    // call back c8
    this.defaultLanguageCollection['CallBack.Title'] = "Callback";
    this.defaultLanguageCollection['Messages.Reject'] = "Reject";
    this.defaultLanguageCollection['Messages.Reserve'] = "Reserve";
    this.defaultLanguageCollection['Messages.Transfer'] = "Transfer";
    this.defaultLanguageCollection['CallBack.Schedule'] = "Schedule";
    this.defaultLanguageCollection['CallBack.AddNotePlaceholder'] = "Add you note here";
    this.defaultLanguageCollection['Messages.Close'] = "Close";
    this.defaultLanguageCollection['History.Title'] = "History";
    this.defaultLanguageCollection['Schedule.Title'] = "Schedule";
    this.defaultLanguageCollection['Schedule.btnOk'] = "OK";
    this.defaultLanguageCollection['Schedule.btnCancel'] = "Cancel";
    this.defaultLanguageCollection['Schedule.btnClear'] = "Clear";
    this.defaultLanguageCollection['Schedule.error'] = "The scheduled callback date and time must be in the future.";
    this.defaultLanguageCollection['AttachFile.Title'] = "Attach File";
    this.defaultLanguageCollection['WebChat.Title'] = "Web Chat";
    this.defaultLanguageCollection['Messages.Conversation'] = "Conversation";
    this.defaultLanguageCollection['Messages.CannedReplies'] = "Canned Replies";
    this.defaultLanguageCollection['Messages.Replies'] = "Replies";
    this.defaultLanguageCollection['Messages.Resources'] = "Resources";
    this.defaultLanguageCollection['Messages.ViewInCRM'] = "View in CRM";
    this.defaultLanguageCollection['Messages.addCRMLogEntry'] = "Add CRM log entry";
    this.defaultLanguageCollection['Messages.AddAttachment'] = "Add attachment"

    this.defaultLanguageCollection['AddCrmEntry.Title'] = "Add activity log entry";
    this.defaultLanguageCollection['AddCrmEntry.Check'] = "Close log entry immediately";
    this.defaultLanguageCollection['AddCrmEntry.ShowInCRMContact'] = "Show CRM Contact";
    this.defaultLanguageCollection['AddCrmEntry.btnOk'] = "OK";
    this.defaultLanguageCollection['AddCrmEntry.btnCancel'] = "Cancel";


    this.defaultLanguageCollection['Twitter.Title'] = "Twitter";
    this.defaultLanguageCollection['Twitter.SendDirect'] = "Direct Message";
    this.defaultLanguageCollection['Sms.Title'] = "SMS";

    this.defaultLanguageCollection['Email.Title'] = "Email";
    this.defaultLanguageCollection['Email.Send'] = "Send email";;
    this.defaultLanguageCollection['Email.Emails'] = "Email addresses";
    this.defaultLanguageCollection['Email.EmailsOptional'] = "Email addresses (optional)";


    this.defaultLanguageCollection['Misc.Search'] = "Search";
    this.defaultLanguageCollection['Misc.NoEntries'] = "No entries";

  }
}




