import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from '../../../../../providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  languagArray: any[];
  SettingsLanguageDescription: string = "";
  SettingsLanguageCurrentLanguage: string = "";

  constructor(public settingsGeneral: SettingsGeneral) {
  }

  ngOnInit() {
    this.languagArray = [];
    this.SettingsLanguageDescription = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Language.Description');
    this.SettingsLanguageCurrentLanguage = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Language.CurrentLanguage');
    this.settingsGeneral.contextService.languageList.forEach(element => {
      this.languagArray.push({ label: element.name, value: element.isoCode });
    });
    this.settingsGeneral.settingsChanged=true;
  }
  languageReset() {
    this.settingsGeneral.contextService.isRestart = true;
    // this.settingsGeneral.contextService.websocketService.getLanguagePhrases(this.settingsGeneral.selectedLanguage);
  }

}
