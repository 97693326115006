<div class="row">
  <p>
    {{SettingsCtiNotificationDescription}}
    <p>
</div>

<div class="row">
  <div class="cols-2">
    <div>
      <p-checkbox name="showRemotePartyPhoneNumber" value="" label={{SettingsAppearanceShowRemotePartyNumber}} [(ngModel)]="settingsGeneral.callNoti_showRemotePartyPhoneNumber"
        class="full-width" binary="true"></p-checkbox>
    </div>
  </div>
</div>

<div class="row">
  <p>
    {{SettingsCtiNotificationOnCall}}
    <p>
</div>

<div class="row">
  <div class="cols-2">
    <div>
      <p-checkbox name="overridenameusingnumber" value="" label={{SettingsCtiNotificationAlwaysPopFromRemoteNumber}} [(ngModel)]="settingsGeneral.callNoti_overRideNameUsingNumber"
        class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="autoanswer" value="" label={{SettingsCtiNotificationPerformAutoAnswer}} [(ngModel)]="settingsGeneral.callNoti_autoAnswer"
        class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="popunity" value="" label={{SettingsCtiNotificationPopUnity}} *ngIf="this.settingsGeneral.contextService.isElectron" [(ngModel)]="settingsGeneral.callNoti_popUnityOnCall"
        class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="autohideunity" value="" label={{SettingsCtiNotificationPopUnityAutoHide}} *ngIf="this.settingsGeneral.contextService.isElectron" [disabled]="!settingsGeneral.callNoti_popUnityOnCall"
        [(ngModel)]="settingsGeneral.callNoti_autoHideUnity" class="full-width" binary="true"></p-checkbox>

      <p-checkbox name="popsummary" value="" label={{SettingsCtiNotificationPopSummary}} [(ngModel)]="settingsGeneral.callNoti_popSummaryNotification"
        class="full-width" binary="true"></p-checkbox>

      <div class="row">
        <label for="" class="label">{{SettingsCtiNotificationFor}}</label>

        <input type="number" (keypress)="onlyNumberKey($event)" name="mini-input" class="input-mini" [min]="1" [max]="24" [(ngModel)]="settingsGeneral.callNoti_popSummarySeconds"  [disabled]="!settingsGeneral.callNoti_popSummaryNotification" onPaste="return false">
        <!-- <p-spinner size="30" [(ngModel)]="settingsGeneral.callNoti_popSummarySeconds" [min]="0" [max]="24" [disabled]="!settingsGeneral.callNoti_popSummaryNotification"></p-spinner> -->
        <label for="" class="label">{{SettingsCtiNotificationSeconds}}</label>
    </div>
  </div>
</div>
