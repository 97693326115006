import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MenuItem } from 'primeng/api/menuitem';
import { SelectItem } from 'primeng/primeng';
import { ScheduledStatusList } from '../../model/im/scheduled-status-list';
import { ContextService } from './../../providers/context.service';
import { HelperService } from './../../providers/helper.service';


@Component({
  selector: 'app-out-of-office',
  templateUrl: './out-of-office.component.html',
  styleUrls: ['./out-of-office.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OutOfOfficeComponent implements OnInit {

  outOfOfficeAssistant: string = "";
  Prompt: string = "";
  miscNo: string = "";
  miscYes: string = "";
  outOfOfficeAssistantClash: string = "";
  callButtonShowingCancel: string = "";
  callButtonShowingOK: string = "";
  imstatus: string = "";
  outOfOfficeAssistantEnd: string = "";
  outOfOfficeAssistantStart: string = "";
  scheduledPresence: string = "";
  outOfOfficeAssistantIAm: string = "";
  currentPresence: string = "";
  



  statusAlertVisible: boolean = false;
  statusConfirmationVisible: boolean = false;
  deleteConfirmationVisible: boolean = false;
  outOfOfficeModalVisible: boolean = false;
  isValid: boolean = true;
  alertMessage: string = "";
  delIndex: number = 0;
  del: number[] = [];
  tabIndex: number = 0;
  currentStatus: any;
  selectedStatus: ScheduledStatusList;
  pArrayIndex: number = 0;
  oooForm: FormGroup;
  startDateString: string = "";
  endDateString: string = "";
  startDate: Date;
  endDate: Date;
  curDate: Date;
  endCurDate: Date;
  startHour: number = 0;
  endHour: number = 0;
  startMins: number = 0;
  endMins: number = 0;
  tDate1: Date;
  tDate2: Date;
  imConnectionLost: boolean = false;
  lang = {
    firstDayOfWeek: 0,
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: [this.contextService.getLanguageTagByKey('Day.7'), this.contextService.getLanguageTagByKey('Day.1'), this.contextService.getLanguageTagByKey('Day.2'), this.contextService.getLanguageTagByKey('Day.3'), this.contextService.getLanguageTagByKey('Day.4'), this.contextService.getLanguageTagByKey('Day.5'), this.contextService.getLanguageTagByKey('Day.6')],

    monthNames: [this.contextService.getLanguageTagByKey('Month.1'), this.contextService.getLanguageTagByKey('Month.2'), this.contextService.getLanguageTagByKey('Month.3'), this.contextService.getLanguageTagByKey('Month.4'), this.contextService.getLanguageTagByKey('Month.5'), this.contextService.getLanguageTagByKey('Month.6'), this.contextService.getLanguageTagByKey('Month.7'), this.contextService.getLanguageTagByKey('Month.8'), this.contextService.getLanguageTagByKey('Month.9'), this.contextService.getLanguageTagByKey('Month.10'), this.contextService.getLanguageTagByKey('Month.11'), this.contextService.getLanguageTagByKey('Month.12')],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: this.contextService.getLanguageTagByKey('Misc.Today'),
    clear: 'Clear'
  };
  hours: Array<string> = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes: Array<string> = ['00', '15', '30', '45'];
  startHourOpts: SelectItem[] = [];
  endHourOpts: SelectItem[] = [];
  startMinOpts: SelectItem[] = [];
  endMinOpts: SelectItem[] = [];
  oooOpts: SelectItem[] = [
    { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.InAMeeting'), value: { id: 1, name: 'In a meeting' } }, { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutToLunch'), value: { id: 2, name: 'Out to lunch' } },
    { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutOfTheOffice'), value: { id: 3, name: 'Out of the office' } }, { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OnLeave'), value: { id: 4, name: 'On leave' } },
    { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AwaySick'), value: { id: 5, name: 'Away sick' } },
  ];
  contextMenuItems: MenuItem[] = [
    { label: 'Delete Status', command: (event) => this.deleteConfirmationVisible = true }
  ];

  constructor(private helper: HelperService, private fb: FormBuilder, private cd: ChangeDetectorRef, public contextService: ContextService) {
    this.currentStatus = {};
    this.tabIndex = 0;
    this.cd.detach();
  }

  tabChange(event) {
    this.tabIndex = event.index;
    if (this.tabIndex == 1) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  ngOnInit() {

    this.outOfOfficeAssistant = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant');
    this.currentPresence = this.contextService.getLanguageTagByKey('TabShowing.CurrentPresence');
    this.outOfOfficeAssistantIAm = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.IAm');
    this.outOfOfficeAssistantStart = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start');
    this.outOfOfficeAssistantEnd = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End');
    this.scheduledPresence = this.contextService.getLanguageTagByKey('TabShowing.ScheduledPresence');
    this.imstatus = this.contextService.getLanguageTagByKey('Misc.Status');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.callButtonShowingCancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
    this.outOfOfficeAssistantClash = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Clash');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.Prompt = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.ClearScheduledStatus.Prompt');





    this.oooForm = this.fb.group({
      selectedReason: [null, Validators.required],
      startDate: [this.startDate, Validators.required],
      endDate: [this.endDate, Validators.required],
      startHour: [null, Validators.required],
      startMin: [null, Validators.required],
      endHour: [null, Validators.required],
      endMin: [null, Validators.required]
    });
    this.currentStatus.name = this.oooOpts[0].value.name;
    this.currentStatus.id = this.oooOpts[0].value.id;
    this.helper.outOfOfficeModalVis.subscribe(val => {
      this.initialise();
      this.cd.reattach();
      this.outOfOfficeModalVisible = val;
    });
    this.populateHours();
    this.populateStartMinutes();
    this.populateEndMinutes();

    this.contextService.imConnectionLostModalVis.subscribe(val => {
      if (val === '') {
        this.imConnectionLost = true;
        this.closeModal();
      }
    });
  }

  initialise() {
    this.isValid = true;
    this.tDate1 = new Date();
    this.tDate1 = new Date(this.tDate1.getUTCFullYear(), this.tDate1.getUTCMonth(), this.tDate1.getUTCDate(), this.tDate1.getUTCHours(), this.tDate1.getUTCMinutes(), this.tDate1.getSeconds());
    this.tDate2 = new Date(this.tDate1.getUTCFullYear() + 1, this.tDate1.getUTCMonth(), this.tDate1.getUTCDate(), this.tDate1.getUTCHours(), this.tDate1.getUTCMinutes(), this.tDate1.getSeconds());
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate = new Date();
    this.endDate.setHours(0, 0, 0, 0);
    this.curDate = new Date();
    this.endCurDate = new Date();
    this.populateHours();
    this.startHourChange();
    this.startHour = this.startHourOpts[0].value;
    this.endHour = this.endHourOpts[0].value;
    this.contextService.log("dates");
    this.contextService.log(this.tDate1);
    this.contextService.log(this.dateToString(this.tDate1));
    this.contextService.log(this.tDate2);
    this.contextService.log(this.dateToString(this.tDate2));
     }
  populateHours() {
    this.hours.forEach(item => {
      this.startHourOpts.push({ label: item, value: item });
      this.endHourOpts.push({ label: item, value: item });
    });
  }
  populateStartMinutes() {
    this.startMinOpts = [];
    this.minutes.forEach(item => {
      this.startMinOpts.push({ label: item, value: item });
    });
  }
  populateEndMinutes() {
    this.endMinOpts = [];
    this.minutes.forEach(item => {
      this.endMinOpts.push({ label: item, value: item });
    });
  }

  closeModal() {

    if (this.imConnectionLost) {
      if (this.statusAlertVisible) {
        this.statusAlertVisible = false;
      }
      if (this.statusConfirmationVisible) {
        this.statusConfirmationVisible = false;
      }
      if (this.deleteConfirmationVisible) {
        this.deleteConfirmationVisible = false;
      }
      let event = new MouseEvent('click', { bubbles: true });
      document.body.dispatchEvent(event);
    }

    this.tabIndex = 0;
    this.outOfOfficeModalVisible = false;
    setTimeout(() => { this.cd.detach(); });

  }

  onSubmit() {
    let tempDate = new Date, pastFlag: boolean = false, errorflag: boolean = false;

    if (this.startDate.getFullYear() < tempDate.getFullYear()) {
      pastFlag = true;
    }
    else if (this.startDate.getFullYear() == tempDate.getFullYear() && this.startDate.getMonth() < tempDate.getMonth()) {
      pastFlag = true;
    }
    else if (this.startDate.getFullYear() == tempDate.getFullYear() && this.startDate.getMonth() == tempDate.getMonth() && this.startDate.getDate() < tempDate.getDate()) {
      pastFlag = true;
    }
    else if (this.startDate.getFullYear() == tempDate.getFullYear() && this.startDate.getMonth() == tempDate.getMonth() && this.startDate.getDate() == tempDate.getDate() && this.startHour < tempDate.getHours()) {
      pastFlag = true;
    }
    else if (this.startDate.getFullYear() == tempDate.getFullYear() && this.startDate.getMonth() == tempDate.getMonth() && this.startDate.getDate() == tempDate.getDate() && this.startHour == tempDate.getHours() && this.startMins <= tempDate.getMinutes()) {
      pastFlag = true;
    }
    if (pastFlag) {
      this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start.Invalid');
      this.statusAlertVisible = true;
      errorflag = true;
    }
    if (!errorflag) {
      if (this.endDate.getFullYear() < tempDate.getFullYear()) {
        pastFlag = true;
      }
      else if (this.endDate.getFullYear() == tempDate.getFullYear() && this.endDate.getMonth() < tempDate.getMonth()) {
        pastFlag = true;
      }
      else if (this.endDate.getFullYear() == tempDate.getFullYear() && this.endDate.getMonth() == tempDate.getMonth() && this.endDate.getDate() < tempDate.getDate()) {
        pastFlag = true;
      }
      else if (this.endDate.getFullYear() == tempDate.getFullYear() && this.endDate.getMonth() == tempDate.getMonth() && this.endDate.getDate() == tempDate.getDate() && this.endHour < tempDate.getHours()) {
        pastFlag = true;
      }
      else if (this.endDate.getFullYear() == tempDate.getFullYear() && this.endDate.getMonth() == tempDate.getMonth() && this.endDate.getDate() == tempDate.getDate() && this.endHour == tempDate.getHours() && this.endMins <= tempDate.getMinutes()) {
        pastFlag = true;
      }
      if (pastFlag) {
        this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End.Invalid');
        this.statusAlertVisible = true;
        errorflag = true;
      }
    }
    if (!errorflag) {
      if (this.endDate.getFullYear() == this.startDate.getFullYear() && this.endDate.getMonth() == this.startDate.getMonth() && this.endDate.getDate() == this.startDate.getDate() && this.endHour == this.startHour && this.endMins == this.startMins) {
        pastFlag = true;
      }
      if (pastFlag) {
        this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Start.Same');
        this.statusAlertVisible = true;
        errorflag = true;
      }
    }
    if (!errorflag) {
      if (this.startDate > this.endDate) {
        this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End.BeforeStart');
        this.statusAlertVisible = true;
      } else if (this.startHour > this.endHour) {
        if (this.startDate >= this.endDate) {
          this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End.BeforeStart');
          this.statusAlertVisible = true;
        } else {
          this.addSStatus();
        }
      } else if (this.startMins >= this.endMins) {
        if (this.startHour >= this.endHour) {
          if (this.startDate >= this.endDate) {
            this.alertMessage = this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.End.BeforeStart');
            this.statusAlertVisible = true;
          } else {
            this.addSStatus();
          }
        } else {
          this.addSStatus();
        }
      } else {
        this.addSStatus();
      }
    }
  }


  addSStatus() {
    this.del = [], this.delIndex = 0;
    let arrayIndex = 0, uid: string, status: string, localStatus: string;
    uid = this.contextService.objLoggedUserDetails.getUserId() + "_" + Date.now();
    this.startDateString = this.partsToString(this.startDate.getFullYear(), this.startDate.getMonth() + 1, this.startDate.getDate(), this.startHour, this.startMins, true);
    this.endDateString = this.partsToString(this.endDate.getFullYear(), this.endDate.getMonth() + 1, this.endDate.getDate(), this.endHour, this.endMins, false);

    let startUTCDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate(), this.startHour, this.startMins, 0, 0);
    let endUTCDate = new Date(this.endDate.getFullYear(), this.endDate.getMonth(), this.endDate.getDate(), this.endHour, this.endMins, 0, 0);
    let startUTCDateString = this.partsToString(startUTCDate.getUTCFullYear(), startUTCDate.getUTCMonth() + 1, startUTCDate.getUTCDate(), startUTCDate.getUTCHours(), startUTCDate.getUTCMinutes(), true);
    let endUTCDateString = this.partsToString(endUTCDate.getUTCFullYear(), endUTCDate.getUTCMonth() + 1, endUTCDate.getUTCDate(), endUTCDate.getUTCHours(), endUTCDate.getUTCMinutes(), false);

    for (let index = 0; index < 5; index++) {
      if (this.currentStatus.id == this.oooOpts[index].value.id) {
        localStatus = this.oooOpts[index].value.name;
        break;
      }
    }
    status = ("ID=" + uid + ";CurrentStatus=" + localStatus + ";StartDay=" + this.zeroPad(this.startDate.getDate()) + ";StartMonth=" + this.zeroPad(this.startDate.getMonth() + 1) + ";StartYear=" + this.startDate.getFullYear() +
      ";StartHour=" + this.startHour + ";StartMinute=" + this.startMins + ";EndDay=" + this.zeroPad(this.endDate.getDate()) + ";EndMonth=" + this.zeroPad(this.endDate.getMonth() + 1) + ";EndYear=" + this.endDate.getFullYear() + ";EndHour=" + this.endHour + ";EndMinute=" + this.endMins + ";");
    if (this.contextService.scheduledStatusListCollection.length == 0) {
       this.contextService.scheduledStatusListCollection[this.pArrayIndex] = new ScheduledStatusList(localStatus, uid, this.startDateString, this.endDateString, this.dateFormatter(this.startDateString), this.dateFormatter(this.endDateString));
      this.pArrayIndex++;
      this.contextService.log("Status Scheduled");
      this.closeModal();
    } else {
      this.pArrayIndex = this.contextService.scheduledStatusListCollection.length;
      for (arrayIndex = 0; arrayIndex < this.contextService.scheduledStatusListCollection.length; arrayIndex++) {
        if ((this.startDateString >= this.contextService.scheduledStatusListCollection[arrayIndex].sDate && this.startDateString <= this.contextService.scheduledStatusListCollection[arrayIndex].eDate) || (this.endDateString >= this.contextService.scheduledStatusListCollection[arrayIndex].sDate && this.endDateString <= this.contextService.scheduledStatusListCollection[arrayIndex].eDate) || (this.startDateString <= this.contextService.scheduledStatusListCollection[arrayIndex].sDate && this.endDateString >= this.contextService.scheduledStatusListCollection[arrayIndex].eDate)) {
          this.del[this.delIndex] = arrayIndex;
          this.delIndex++;
        }
      }
      if (this.delIndex > 0) {
        this.statusConfirmationVisible = true;
      } else {
            this.contextService.log("Status Scheduled");
        this.contextService.scheduledStatusListCollection[this.pArrayIndex] = new ScheduledStatusList(localStatus, uid, this.startDateString, this.endDateString, this.dateFormatter(this.startDateString), this.dateFormatter(this.endDateString));
        this.pArrayIndex++;
        this.closeModal();
      }
    }
  }

  deleteSS() {
    
  }
  overwriteSS() {
   
  }

  startHourChange() {
    if (this.startDate.getFullYear() == this.curDate.getFullYear() && this.startDate.getMonth() == this.curDate.getMonth() && this.startDate.getDate() == this.curDate.getDate()) {
      this.startHourOpts = [];
      this.hours.forEach(element => {
        if (parseInt(element) >= this.curDate.getHours()) {
          if (!((parseInt(element) == this.curDate.getHours()) && this.curDate.getMinutes() > 45)) {
            this.startHourOpts.push({ label: element, value: element })
          }
        }
      });
    } else {
      this.startHourOpts = [];
      this.hours.forEach(item => {
        this.startHourOpts.push({ label: item, value: item });
      });
    }
    this.startHour = this.startHourOpts[0].value;

    if (this.startDate.getFullYear() > this.endDate.getFullYear()) {
      this.endDate = this.startDate;
    } else if (this.startDate.getFullYear() == this.endDate.getFullYear() && this.startDate.getMonth() > this.endDate.getMonth()) {
      this.endDate = this.startDate;
    }
    else if (this.startDate.getFullYear() == this.endDate.getFullYear() && this.startDate.getMonth() == this.endDate.getMonth() && this.startDate.getDate() > this.endDate.getDate()) {
      this.endDate = this.startDate;
    }
    this.endCurDate = this.startDate;
    this.startMinChange();
  }

  startMinChange() {
    this.startMinOpts = [];
    if (this.startHour > this.curDate.getHours() || this.startDate.getDate() > this.curDate.getDate() || this.startDate.getMonth() > this.curDate.getMonth() || this.startDate.getFullYear() > this.curDate.getFullYear()) {
      this.populateStartMinutes();
    } else {
      this.minutes.forEach(element => {
        if (parseInt(element) >= this.curDate.getMinutes()) {
          this.startMinOpts.push({ label: element, value: element })
        }
      });
      if (this.startMinOpts.length === 0) {
        this.populateStartMinutes();
      }
    }
    this.startMins = this.startMinOpts[0].value;
    this.endHourChange();
  }

  endHourChange() {
    this.endHourOpts = [];
    if ((this.endDate.getFullYear() == this.startDate.getFullYear()) && (this.endDate.getMonth() == this.startDate.getMonth()) && (this.endDate.getDate() == this.startDate.getDate()) && (this.endDate.getFullYear() == this.curDate.getFullYear() && this.endDate.getMonth() == this.curDate.getMonth() && this.endDate.getDate() == this.curDate.getDate())) {
      this.hours.forEach(element => {
        if ((parseInt(element) >= this.curDate.getHours()) && (parseInt(element) >= this.startHour)) {
          if (!((parseInt(element) == this.curDate.getHours()) && this.curDate.getMinutes() > 45)) {
            this.endHourOpts.push({ label: element, value: element })
          }
        }
      });
    } else if ((this.endDate.getFullYear() == this.startDate.getFullYear()) && (this.endDate.getMonth() == this.startDate.getMonth()) && this.endDate.getDate() == this.startDate.getDate()) {
      this.hours.forEach(element => {
        if (parseInt(element) >= this.startHour) {
          if (!((parseInt(element) == this.curDate.getHours()) && this.curDate.getMinutes() > 45)) {
            this.endHourOpts.push({ label: element, value: element })
          }
        }
      });
    } else {
      this.hours.forEach(item => {
        this.endHourOpts.push({ label: item, value: item });
      });
    }
    this.endHour = this.endHourOpts[0].value;
    this.endMinChange();
  }

  endMinChange() {
    this.endMinOpts = [];
    if ((this.endHour > this.startHour) || (this.endDate > this.startDate)) {
      this.populateEndMinutes();
    } else {
      this.minutes.forEach(element => {
        if (parseInt(element) > this.startMins) {
          this.endMinOpts.push({ label: element, value: element })
        }
      });
      if (this.endMinOpts.length === 0) {
        if (this.endHourOpts.length === 1) {
          this.endMinOpts = [];
          this.endMinOpts.push({ label: this.minutes[3], value: this.minutes[3] });
        } else {
          this.endHourOpts.splice(0, 1);
          this.populateEndMinutes();
        }
        this.endHour = this.endHourOpts[0].value;
      }
    }
    this.endMins = this.endMinOpts[0].value;
  }

  partsToString(year: number, month: number, date: number, hour: number, minute: number, type: boolean): string {
    if (type) {
      return (year + "-" + this.zeroPad(month) + "-" + this.zeroPad(date) + ":" + hour + ":" + minute + ":01:00");
    } else {
      return (year + "-" + this.zeroPad(month) + "-" + this.zeroPad(date) + ":" + hour + ":" + minute + ":00:00");
    }
  }

  dateToString(stringDate: Date): string {
    let year = stringDate.getFullYear(),
      month = this.zeroPad(stringDate.getMonth() + 1),
      date = this.zeroPad(stringDate.getDate()),
      hour = this.zeroPad(stringDate.getHours()),
      minute = this.zeroPad(stringDate.getMinutes()),
      second = this.zeroPad(stringDate.getSeconds());
    return (year + "-" + month + "-" + date + ":" + hour + ":" + minute + ":" + second + ":00");
  }

  dateFormatter(dateString: string): string {
    let fhour, year = parseInt(dateString.split("-")[0]), month = parseInt(dateString.split("-")[1]), time = dateString.split("-")[2];
    let date = parseInt(time.split(":")[0]), hour = parseInt(time.split(":")[1]), minute = parseInt(time.split(":")[2]), second = parseInt(time.split(":")[3]);
    if (hour >= 12) {
      fhour = 'PM';
      if (hour > 12) {
        hour -= 12;
      }
    } else {
      fhour = 'AM';
      if (hour == 0) {
        hour = 12;
      }
    }
    return (this.zeroPad(month) + "/" + this.zeroPad(date) + "/" + year + " at " + this.zeroPad(hour) + ":" + this.zeroPad(minute) + " " + fhour);
  }

  zeroPad(val: number) {
    if (val < 10) {
      return '0' + val;
    } else {
      return val;
    }
  }
}
