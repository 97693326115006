/** 
 * C8 - model for history entry item
 */

export class HistoryEntry {
  title: string;
  info: Array<any>;
  dateTime: Date;
  ico: string;
  rating: number

  constructor(title, info, deteTime, ico = '', rating = 0) {
    this.title = title;
    this.info = info;
    this.dateTime = deteTime;
    this.ico = ico;
    this.rating = rating;
  }

}
