import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BWGroup } from "app/model/directory/bw-group";
import { Agent } from 'app/model/supervisor/agent';
import { ContextService } from "app/providers/context.service";
import { DataService } from "app/providers/dataservice.service";
import { TreeNode } from "primeng";
import { HelperService } from './../../providers/helper.service';





@Component({
  selector: 'app-modify-supervised-agent-list',
  templateUrl: './modify-supervised-agent-list.component.html',
  styleUrls: ['./modify-supervised-agent-list.component.scss']
})
export class ModifySupervisedAgentListComponent implements OnInit {


  messageUserObject = [];

  tempId: string = "";
  id: string = "";

  enterpriseName: string = "";
  modifySupervisedAgentsVisible: boolean = false;
  data: any[];
  // users: TreeNode[];
  selectedUsers: TreeNode[] = [];
  selectedUsers_temp: TreeNode[] = [];

  // usersPicked: TreeNode[] = [];
  deselectedUsers: TreeNode[] = [];

  hierarchy: boolean = true;
  searchString: any = {};

  maxSelected: number = 0;
  saveButtonState: boolean;
  conversationrVis: boolean = false;

  maxSelectedDisplay: string = "30 remaining";
  saveButtonStatus: boolean = false;
  selectedUserCount: number = 0;
  monitoredUserArray: any[] = [];

  nonSupervisingTreeData: TreeNode[] = [];
  supervisingTreeData: TreeNode[] = [];

  userArray: any[] = [];
  deptArray: any[] = [];
  groupArray: any[] = [];
  nonSupervisingTreeDataTemp: any[] = [];
  supervisingTreeDataTemp: any[] = [];

  public bwGroupNamesList1: Array<BWGroup> = [];

  public bwGroupNamesList_Monitor1: Array<BWGroup> = [];

  monitor_userArray: any[] = [];
  monitor_deptArray: any[] = [];
  monitor_groupArray: any[] = [];
  MonitorTreeTemp: any[] = [];
  monitor_deptArray1: any[] = [];

  removeUserFromUserTreeArray: any[] = [];
  removeUserFromMonitorTreeArray: any[] = [];

  isEnterpriseRootRemove_FromUserTree: boolean = false;
  isEnterpriseRootRemove_FromMonitorTree: boolean = false;
  parent_deselected_heirchicalView: boolean = false;

  selectedUsersStatus: boolean = false;
  userArray_Temp: any[] = [];
  deptArray_Temp: any[] = [];
  groupArray_Temp: any[] = [];

  isHeirachy: boolean = true;
  isSearch: boolean = false;


  supervisedAgents: string = "";
  cancel: string = "";
  conversationMinimum: string = "";
  callButtonShowingOK: string = "";




  public bwGroupNamesList_Monitor_For_Heirachy_Users: Array<Agent> = [];
  public bwGroupNamesList_For_Heirachy_Users: Array<Agent> = [];
  acdUserId: string = "";

  constructor(
    private helper: HelperService, public contextService: ContextService,
    private dataService: DataService,
    private cd: ChangeDetectorRef) { this.cd.detach(); }

  ngOnInit() {
    // modal observer
    this.cancel = this.contextService.getLanguageTagByKey('CallButtonShowing.Cancel');
    this.conversationMinimum = this.contextService.getLanguageTagByKey('ManageUsers.Prompt.ConversationMinimum');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');

    this.contextService.modifySupervisedAgentsVisible.subscribe(val => {

      this.nonSupervisingTreeDataTemp = [];
      this.supervisingTreeDataTemp = [];
      this.acdUserId = val.acdUserId;
      let temp1: TreeNode[] = [];
      let temp2: TreeNode[] = [];
      this.deselectedUsers=[];
      this.selectedUsers=[];
      for (const key in val.agentList) {
        if (val.agentList.hasOwnProperty(key)) {
          const element = val.agentList[key];
          if (!element.isSupervised) {


            temp1.push({ "label": '' + this.contextService.getNameFromUserID(key) + '', "data": key, "styleClass": "folder" });
          } else {

            temp2.push({ "label": '' + this.contextService.getNameFromUserID(key) + '', "data": key, "styleClass": "folder" });
          }
        }
      }
      this.nonSupervisingTreeDataTemp = [{
        "label": "Kakaposystems",
        "isFolder": true,
        "data": "kakaposystems",
        "expanded": true,
        "styleClass": "folder",
        "type": 'parent',
        "children":
          this.sort('label', temp1)

      }];

      this.supervisingTreeDataTemp = [{
        "label": "Kakaposystems",
        "isFolder": true,
        "data": "kakaposystems",
        "expanded": true,
        "styleClass": "folder",
        "type": 'parent',
        "children": this.sort('label', temp2)
      }];
      this.nonSupervisingTreeData = [];
      this.nonSupervisingTreeData = this.sort('label', this.deepCopy(this.nonSupervisingTreeDataTemp));

      this.supervisingTreeData = [];
      this.supervisingTreeData = this.sort('label', this.deepCopy(this.supervisingTreeDataTemp));



      this.modifySupervisedAgentsVisible = val;
      this.cd.reattach();
    });

    // this.dataService.getUsers().subscribe(
    //   (response: Response)=>{
    //     this.data = this.sort('label', response.json().data);
    //     this.users = this.sort('label', response.json().data);
    //   }
    // );

  }

  saveAgentsList() {
    let newSupervisingList = [];
    if (this.supervisingTreeData.length > 0) {
      this.supervisingTreeData[0].children.forEach(element => {
        newSupervisingList.push(element.data);
      });
    }
  
    this.modifySupervisedAgentsVisible = false;
  }
  deepCopy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.deepCopy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop));
            o[prop] = this.deepCopy(source[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }
  searchUsersAction(event) {
    if (this.searchString.text.length) {
      this.nonSupervisingTreeData = this.helper.searchNodes(this.searchString.text, this.helper.clone(this.data, true), 'children');
    } else {
      this.nonSupervisingTreeData = this.data;
    }
  }

  getUsersOnly(users: any[], nodes: any[]) {
    nodes.forEach(node => {
      if (!node.isFolder) {
        users.push(node)
      }
      if (node.children && node.children.length) {
        users = this.getUsersOnly(users, node.children);
      }
    });
    return users;
  }


  getNodeByData(data: any, nodes: any[]) {
    var node = false;
    for (let n of nodes) {
      if (n.data == data) {
        // console.log('node', n.data, data);
        node = n;
        break;
      }
      if (n.children && n.children.length) {
        node = this.getNodeByData(data, n.children);
      }
    };
    return node;
  }

  hierarchyChange(checked: boolean) {
    /*
    if(!checked){
      // remove hierarchy 
      this.users = this.getUsersOnly([], this.data); 
    } else {
      this.users = this.data;
    }*/
  }

  removeUsers() {
    this.nonSupervisingTreeData = this.getSelected(this.nonSupervisingTreeData, this.supervisingTreeData, this.deselectedUsers);
    this.nonSupervisingTreeData[0].children = this.sort('label', this.nonSupervisingTreeData[0].children);
    this.deselectedUsers = [];
    this.selectedUsers = [];
  }

  addUsers() {
    this.supervisingTreeData = this.getSelected(this.supervisingTreeData, this.nonSupervisingTreeData, this.selectedUsers);
    this.supervisingTreeData[0].children = this.sort('label', this.supervisingTreeData[0].children);
    this.selectedUsers = [];
    this.deselectedUsers = [];

  }

  isNodeSelected(node: any, nodes: any) {
    //console.log(nodes); console.log(node);
    let selected = false;
    for (let n of nodes) {
      if (n.data == node.data) {
        selected = true;
        break;
      }
    };

    if (!selected) {
      if (node.partialSelected) {
        selected = true;
      }
    }

    return selected;
  }

  private getSelected(newTree: any, tree: any, nodes: TreeNode[]) {
    var index = -1;
    var addParent, removeEmpty, children;
    var nodesToRemove: any[] = [];

    tree.forEach((node, index) => {
      addParent = false;
      let nodeCopy = this.helper.clone(node, true);

      // check children first
      if (node.children && node.children.length) {
        children = this.getSelected([], node.children, nodes);
        //console.log(children);
        if (children.length) { // some children selected
          tree[index] = node // update tree
          nodeCopy.children = children; // new children
          //nodeCopy.children = nodeCopy.children.concat(children);
          addParent = true;
        }
      }

      if (this.isNodeSelected(node, nodes) || addParent) {
        nodeCopy.partialSelected = false;
        nodeCopy.selected = false;
        let nodeExist = this.getNodeByData(node.data, newTree);
        if (!nodeExist) {
          //console.log(nodeCopy.label);
          newTree.push(nodeCopy);
        } else {
          if (nodeExist.children && nodeCopy.children) {
            nodeCopy.children.forEach(children => {
              let childrenExist = this.getNodeByData(children.data, nodeExist.children);
              if (childrenExist) {
                // update children
                childrenExist.children = childrenExist.children.concat(children.children);
              } else {
                nodeExist.children.push(children);
              }
            });
            //nodeExist.children = nodeExist.children.concat(nodeCopy.children);
          }
        }

        // remove only if no children left
        if (!node.children || node.children.length === 0) {
          nodesToRemove.push(index);
        }
      }
      // unselect
      node.partialSelected = false;
      node.selected = false;
    });

    if (nodesToRemove.length) {
      for (let index of nodesToRemove.reverse()) {
        this.helper.removeNode(tree, index);
      }
    }

    return newTree;
  }

  sort(prop, arr) {
    prop = prop.split('.');
    var len = prop.length;

    arr.sort(function (a, b) {
      var i = 0;
      while (i < len) { a = a[prop[i]]; b = b[prop[i]]; i++; }
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return arr;
  };

  closeSettings() {
    this.modifySupervisedAgentsVisible = false;
    setTimeout(() => { this.cd.detach(); });
  }

}
