<p-dialog
  [(visible)]="webChatModalVisible"
  [ngClass]="{ visible: webChatModalVisible, 'is-reserved': reserved }"
  [closable]="false"
  class="small-dialog messages-dialog web-chat-dialog"
  dismissableMask="true"
  showHeader="true"
  [resizable]="false"
  #dialWebchat
>
  <p-header>
    <div class="container-center">
      <h4 class="modal-title">{{ title }}</h4>
      <span class="close-modal small" (click)="closeModal()">X</span>
      <span
        class="maximize-modal"
        (click)="messagesService.toggleDialogMaximized(dialWebchat)"
      ></span>
    </div>
  </p-header>
  <div class="modal-content">
    <div class="messages-modal-content">
      <ul class="buttons-menu in-modal">
        <!-- reject -->
        <li *ngIf="!reserved">
          <div class="tooltip">
            <span>{{ rejectBtn }}</span>
          </div>
          <a (click)="reject($event)" class="btn-reject new-i">{{
            rejectBtn
          }}</a>
        </li>
        <!-- reserve -->
        <li *ngIf="!reserved">
          <div class="tooltip" style="white-space: nowrap">
            <span>{{ reserveBtn }}</span>
          </div>
          <a (click)="reserve($event)" class="btn-reserve new-i">{{
            reserveBtn
          }}</a>
        </li>

        <!-- transfer -->
        <li *ngIf="reserved" #transferToChat>
          <div class="tooltip">
            <span>{{ transferBtn }}</span>
          </div>
          <a
            (click)="openTransferToCm($event, transfertocmChat)"
            class="btn-transfer-cb new-i"
            >{{ transferBtn }}</a
          >

          <p-contextMenu
            [model]="transferToList"
            appendTo="body"
            [target]="transferToChat"
            #transfertocmChat
          >
          </p-contextMenu>
        </li>
        <!-- close -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ closeBtn }}</span>
          </div>
          <a (click)="close($event)" class="btn-close new-i">{{ closeBtn }}</a>
        </li>
        <!-- view in CRM -->
        <li>
          <div class="tooltip">
            <span>{{ viewInCRMBtn }}</span>
          </div>
          <a (click)="viewInCRM($event)" class="btn-view-in-crm new-i">{{
            viewInCRMBtn
          }}</a>
        </li>
        <!-- add entry to CRM -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ addCRMBtn }}</span>
          </div>
          <a
            (click)="addCRMLogEntry($event)"
            class="btn-add-crm-entry new-i"
            [ngClass]="{ active: crmFormVisible }"
            >{{ addCRMBtn }}</a
          >
        </li>
        <!-- att -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ attachmentBtn }}</span>
          </div>
          <label for="cb_att_web_chat" class="btn-attachment new-i">
            {{ attachmentBtn }}
          </label>
        </li>

        <li *ngIf="reserved" class="separator"></li>

        <!-- conversation -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ conversationBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'conversation' }"
            (click)="selectTab($event, 'conversation')"
            class="btn-email-conversation new-i"
            >{{ conversationBtn }}</a
          >
        </li>

        <!-- replies -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ respliesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'replies' }"
            (click)="selectTab($event, 'replies')"
            class="btn-email-replies new-i"
            >{{ respliesBtn }}</a
          >
        </li>

        <!-- resources -->
        <li *ngIf="reserved">
          <div class="tooltip">
            <span>{{ resourcesBtn }}</span>
          </div>
          <a
            [ngClass]="{ active: selectedTab === 'resources' }"
            (click)="selectTab($event, 'resources')"
            class="btn-email-resources new-i"
            >{{ resourcesBtn }}</a
          >
        </li>
      </ul>

      <div class="messages-window">
        <div class="mw-left">
          <div class="info-crm">
            <div class="info-wrap">
              <app-user-details [user]="user"></app-user-details>
            </div>
          </div>
          <app-history
            [historyEntries]="history"
            [visible]="webChatModalVisible"
          ></app-history>
        </div>
        <div class="mw-right" [ngClass]="{ 'crm-visible': crmFormVisible }">
          <app-add-crm-entry
            [hidden]="!crmFormVisible"
            (onSubmit)="onCrmSubmit($event)"
            (onClose)="onCrmClose($event)"
          ></app-add-crm-entry>

          <div class="chat-wrapper">
            <div class="container" [perfectScrollbar] id="web-chat-scroll-pane">
              <div
                class="content chat-conversation"
                [hidden]="selectedTab !== 'conversation'"
              >
                <div class="conversation-text">
                  <div>
                    <ul class="conversation-messages">
                      <li
                        *ngFor="let message of messagesArray; let i = index"
                        [ngClass]="{
                          'date-time': !message.id,
                          you: !message.itsMe && message.id,
                          me: message.itsMe && message.id
                        }"
                      >
                        <p *ngIf="!message.id">{{ message.dateTime }}</p>
                        <p *ngIf="message.id">
                          <strong class="chat-person" *ngIf="message.name">{{
                            message.name
                          }}</strong>
                          <span class="timestamp">{{
                            message.dateTime | date: "shortTime"
                          }}</span>
                          <span [innerHTML]="message.text"></span>
                          <span
                            class="note-attachment"
                            *ngIf="message.attachment?.url"
                          >
                            <app-attachment
                              [attachment]="message.attachment"
                            ></app-attachment>
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <app-canned-replies
                [hidden]="selectedTab !== 'replies'"
                [visible]="selectedTab === 'replies'"
                (onRightClick)="repliesRightClick($event)"
                (onDoubleClick)="repliesDoubleClick($event)"
                [repliesEntries]="respliesList"
              ></app-canned-replies>

              <app-resources
                [hidden]="selectedTab !== 'resources'"
                [visible]="selectedTab === 'resources'"
                (onRightClick)="resourcesRightClick($event)"
                (onDoubleClick)="resourcesDoubleClick($event)"
                [resourceEntries]="resourcesList"
              ></app-resources>
            </div>
          </div>
          <div class="replies-wrapper">
            <!--
            <div class="table-tabs">
              <ul class="tabs">
                <li>
                  <a
                    href="conversation"
                    [ngClass]="{ active: selectedTab === 'conversation' }"
                    (click)="selectTab($event, 'conversation')"
                    >{{ conversation }}</a
                  >
                </li>
                <li>
                  <a
                    href="canned-replies"
                    [ngClass]="{ active: selectedTab === 'replies' }"
                    (click)="selectTab($event, 'replies')"
                    >{{ cannedReplies }}</a
                  >
                </li>
                <li>
                  <a
                    href="resources"
                    [ngClass]="{ active: selectedTab === 'resources' }"
                    (click)="selectTab($event, 'resources')"
                    >{{ resources }}</a
                  >
                </li>
              </ul>
            </div>
            -->
            <div class="table-content">
              <app-web-chat-conversation
                [predefinedMessage]="predefinedMessage"
                [componentName]="'web_chat'"
                (onMessageSubmit)="onMessageSubmit($event[0], $event[1])"
              ></app-web-chat-conversation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
