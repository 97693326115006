import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { Subject, Subscription } from 'rxjs';
import { CallStatusEntry } from '../model/call-status/call-status-entry';
import { BwDirectoryEntry } from '../model/directory/bw-directory-entry';
import { CommonPhoneList } from '../model/directory/common-phone-list';
import { DirectoryView } from '../model/directory/directory-view';
import { PersonalDirectory } from '../model/directory/personal-directory';
import { ChatService } from "./chat.service";
import { ContextService } from './context.service';
import { HelperService } from './helper.service';

@Injectable()
export class DirectoryService {
  public directoryDisplayArray: Array<DirectoryView>;
  public directoryDisplayArrayView: Array<DirectoryView>;
  public objDirectoryView: DirectoryView;
  viewUserDetailsModalVis = new Subject<boolean>();
  updateCellWidth = new Subject<any>();

  calldurationHidden: boolean = false;

  //>personal directory
  selectedName: string = '';
  selectedPhone: string = '';
  tempSelectedName: string = '';
  tempSelectedPhone: string = '';
  duplicateAlertVisible: boolean = false;
  deleteEntryVisible: boolean = false;
  editFlag: boolean;
  visibilityFlag: boolean;
  duplicateMessage: string = '';
  tempId: string = "";
  tempSearchTerm: string = "";
  //<personal directory

  // for chat
  userID: string = "";
  // for timer
  sub: Subscription = new Subscription();
  isSubscription: boolean = false;
  groupAndDepartmentList: any = {};
  groupDeptname: string = "";
  groupGName: string = "";
  groupDirType: string = "";
  businessHidden = true;
  callExtensionAvailableArrayList = [];
  callExtensionAvailableObservable = new Subject<any>();
  public droppedToContact: DirectoryView;

  private contactsNotToBeshown = ['broadworks anywhere', 'instant conferencing', 'instant group call', 'trunk group', 'voice portal'];
  constructor(public contextService: ContextService, public chatService: ChatService, private helperService: HelperService) {
    this.calldurationHidden = this.contextService.objSettings.dir_CallDuration_Hidden;
    /**
     * to update monitor user status
     * if val is not undefined then the updation is related to IM 
     *      if val==IMOveridden then all contacts are made offline
     *      else val==userID, then that userID is made online
     * else
     *      monitor user status is updated after checking whether to show call duration or not
     */
    this.contextService.monitorStatusUpdate.subscribe((val) => {
      if (val !== undefined) {
        if (val === "IMOveridden") {
          this.directoryDisplayArrayView.forEach(element => {
            element.isOnline = false;
          });

        } else {
          this.directoryDisplayArrayView.forEach(element => {
            if ((element.userId === val) && (element.name.indexOf("(Mobile)") === -1)) {
              element.isOnline = true;
            }
          });
        }
      }
      if (this.calldurationHidden) {

        this.monitoredUserStatus();
      } else {
        this.timerTestSetUp();
      }
    });
    /**
     * to set a particular userID as IM offline
     */
    this.contextService.imRemoveFromDirectories.subscribe((val) => {
      this.directoryDisplayArrayView.forEach(element => {
        if ((element.userId === val) && (element.name.indexOf("(Mobile)") === -1)) {
          element.isOnline = false;
        }
      });
    });

    this.contextService.MonitoredUsersupdate.subscribe(() => {
      this.setMonitoredUserInDirecroryView();
    });
    this.contextService.getDirectoryView.subscribe(() => {
      this.loadContactToTable();
    });
    this.contextService.acdStateUpdate.subscribe((id) => {
      if (id !== this.contextService.objLoggedUserDetails.getUserId()) {
        this.updateACDState(id);
      }
    });
    this.loadContactToTable();

    this.contextService.signout.subscribe(val => {
      this.timerUnsubcribe();
      this.directoryDisplayArray = [];
      this.directoryDisplayArrayView = [];
      this.contextService.directoryList = [];
    });

    this.contextService.commonObservable.subscribe(val => {
      if (val === "autoReconnect") {
        this.timerUnsubcribe();
        this.directoryDisplayArray = [];
        this.directoryDisplayArrayView = [];
        this.contextService.directoryList = [];
      }
    });

    this.contextService.outlookContactPush.subscribe((outLookContactsCollection) => {
      if (outLookContactsCollection.mode == "ContactPush") {
        if (outLookContactsCollection.searchTerm === this.tempSearchTerm && outLookContactsCollection.value.length > 0) {
          if (this.contextService.startIndexOutlook === 1) {
            for (var j = this.directoryDisplayArray.length - 1; j >= 0; j--) {
              const element = this.directoryDisplayArray[j];
              if (element.type === 'Outlook Directory') {
                this.directoryDisplayArray.splice(j, 1);
                let temp = [...this.directoryDisplayArray];
                this.directoryDisplayArray = temp;
              }

            }
          }
          this.addContactNotExist(outLookContactsCollection.value);
          this.directoryDisplayArrayView = this.directoryDisplayArray;
          this.reIndexDirectory();
          this.helperService.resized.next();
        }
      }
    });
  }

  addContactNotExist(contact: any) {
    let contactExist = false;
    contact.forEach(element => {
      DirectoryView.getOutlookDirectoryView(element).forEach(element2 => {
        this.directoryDisplayArray.push(element2);
        contactExist = true
      });
    });

    if (contactExist) {
      if (this.contextService.objSettings.showBusinessNameColumn) {
        this.businessHidden = false;
      }
    }
  }

  /**
   * updates the ACD State of a particular monitored user
   *
   * @param {string} id
   * @memberof DirectoryService
   */
  updateACDState(id: string) {
    this.directoryDisplayArrayView.forEach(element => {
      if ((element.userId === id) && (element.name.indexOf("(Mobile)") === -1)) {
        element.ACDState = this.contextService.monitoredUserList[id].ACDState;
      }
    });
    this.groupDirectoryDisplayArray(this.groupDirType, this.groupGName, this.groupDeptname);
  }

  /**
   * initially load contats from raw data array to directoryDisplayArrayView
   *
   * @memberof DirectoryService
   */
  loadContactToTable() {
    this.directoryDisplayArray = [];
    this.directoryDisplayArrayView = [];
    this.contextService.directoryList.forEach(element => {
      if (element instanceof PersonalDirectory) {
        if (element.getNumber() !== "") {
          this.directoryDisplayArrayView.push(DirectoryView.getPersonalDirectoryView(element));
        }
      } else if (element instanceof CommonPhoneList) {
        if (element.getNumber() !== "") {
          this.directoryDisplayArrayView.push(DirectoryView.getCommonDirectoryView(element));
        }
      } else if (element instanceof BwDirectoryEntry) {
        if (element.getUserId() !== this.contextService.objLoggedUserDetails.getUserId() && !this.contactsNotToBeshown.includes(element.getFirstName().toLowerCase())) {
          if (element.getExtension() !== "" || element.getNumber() !== "" || element.getMobile() !== "") {
            if ((element.getFirstName().toLowerCase() == "voice messaging group" && element.getGroupId() !== this.contextService.objLoggedUserDetails.getgroupId())) {
              this.contextService.log("voice messaging group not included directory: " + element.getFirstName());
              return;
            }
            if (element.getMobile().length > 0) {
              this.directoryDisplayArrayView.push(DirectoryView.getBwDirectoryView(element, true));
            }
            this.directoryDisplayArrayView.push(DirectoryView.getBwDirectoryView(element, false));
            this.getGroupAndDepartment(element);
          }
        }
      }

    });
    this.setId();
    this.setMonitoredUserInDirecroryView();
  }

  /**
   *gets the list of groups and departments used to show in filter dropdown
   *
   * @param {BwDirectoryEntry} entry
   * @memberof DirectoryService
   */
  getGroupAndDepartment(entry: BwDirectoryEntry) {
    let newgroup = true;
    let newDept = true;
    let length = entry.getDepartment().length;
    for (let key in this.groupAndDepartmentList) {
      if ((key === entry.getGroupId()) || (entry.getGroupId() === '')) {
        newgroup = false;
      }
    }
    if (newgroup) {
      this.groupAndDepartmentList[entry.getGroupId()] = [];
    }
    for (let key in this.groupAndDepartmentList) {
      if (key === entry.getGroupId()) {
        if (length === 0) {
          newDept = false;
        } else {
          this.groupAndDepartmentList[key].forEach(element => {
            if ((element === entry.getDepartment())) {
              newDept = false;
            }
          });
        }
      }
    }
    if (newDept) {
      this.groupAndDepartmentList[entry.getGroupId()].push(entry.getDepartment());
    }
  }

  /**
   * sets id to directories
   *
   * @memberof DirectoryService
   */
  setId() {
    let id_value: number = 1;
    this.directoryDisplayArrayView.forEach(element => {
      element.id = id_value;
      id_value++;

    });
  }

  /**
   * sets monitor users in directories by changing icons
   *
   * @memberof DirectoryService
   */
  setMonitoredUserInDirecroryView() {
    this.directoryDisplayArrayView.forEach(element => {
      element.isMonitor = false;
      element.status = "";
      if (element.type === 'Broadworks Directory') {
        element.icon = 'external';
      } else {
        element.icon = 'external-nonBW';
      }
    });
    this.directoryDisplayArrayView.forEach(element => {
      if ((element.userId in this.contextService.monitoredUserList) && (element.name.indexOf("(Mobile)") === -1)) {
        element.isMonitor = true;
        element.icon = 'available';
      }
    });
    this.filterDirectoryDisplayArray('');
  }

  /**
   * filter table according to serach term
   *
   * @param {*} searchTerm
   * @memberof DirectoryService
   */
  filterDirectoryDisplayArray(searchTerm) {
    if (this.groupDirType !== "") {
      this.groupDirType = "";
      this.groupDeptname = "";
      this.groupGName = "";
    }
    this.tempSearchTerm = searchTerm;
    if (searchTerm !== '') {
      if (!this.contextService.objSettings.showBusinessNameColumn) {
        this.businessHidden = true;
      }
      // else{
      //     this.businessHidden=false;
      // }
      this.directoryDisplayArray = this.directoryDisplayArrayView;
    } else {
      this.businessHidden = true;
      this.removeOutlookContacts();
      this.directoryDisplayArray = this.directoryDisplayArrayView;
      this.directoryDisplayArray = this.directoryDisplayArray.filter(DirectoryView => DirectoryView.isMonitor === true);
      this.helperService.resized.next();
    }
  }

  removeOutlookContacts() {
    for (var j = this.directoryDisplayArrayView.length - 1; j >= 0; j--) {
      const element = this.directoryDisplayArrayView[j];
      if (element.type === "Outlook Directory") {
        this.directoryDisplayArrayView.splice(j, 1);
        let temp = [...this.directoryDisplayArrayView];
        this.directoryDisplayArrayView = temp;
      }
    }
  }
  /**
   * filter table according to directory type, group or department
   *
   * @param {*} dirType
   * @param {*} group
   * @param {*} department
   * @memberof DirectoryService
   */
  groupDirectoryDisplayArray(dirType, group, department) {
    if (dirType !== '') {
      this.groupDirType = dirType;
      this.directoryDisplayArray = this.directoryDisplayArrayView;
      this.directoryDisplayArray = this.directoryDisplayArray.filter(DirectoryView => DirectoryView.name.includes('(Mobile)') !== true);
      this.directoryDisplayArray = this.directoryDisplayArray.filter(DirectoryView => DirectoryView.type === dirType);
      if (group !== '') {
        this.groupGName = group;
        this.directoryDisplayArray = this.directoryDisplayArray.filter(DirectoryView => DirectoryView.groupId === group);
      } else {
        this.groupGName = "";
      }
      if (department !== '') {
        this.groupDeptname = department;
        this.directoryDisplayArray = this.directoryDisplayArray.filter(DirectoryView => DirectoryView.department === department);
      } else {
        this.groupDeptname = department;
      }
    } else {
      this.groupDeptname = "";
      this.groupDirType = "";
      this.groupGName = "";
      this.filterDirectoryDisplayArray(this.tempSearchTerm);
    }
  }

  /**
   * sets the selected contact
   *
   * @param {DirectoryView} objDirectoryView
   * @memberof DirectoryService
   */
  setDirectoryView(objDirectoryView: DirectoryView) {
    this.objDirectoryView = objDirectoryView;
  }

  /**
   * timer to show status in contact table
   *
   * @memberof DirectoryService
   */
  timerTestSetUp() {

    let time = timer(0, 1000);
    if (!this.isSubscription) {

      this.isSubscription = true;
      this.sub = time.subscribe(
        t => {
          this.monitoredUserCallDuration();
        }
      );
    }
  }
  timerUnsubcribe() {
    if (!this.sub.closed) {
      this.isSubscription = false;
      this.sub.unsubscribe();
    }
    if (!this.contextService.sessionTerminated) {
      this.contextService.monitorStatusUpdate.next();
    }
  }

  /**
   * show status with call duration
   *
   * @memberof DirectoryService
   */
  monitoredUserCallDuration() {

  }

  monitoredUserStatus() {

  }

  setSlahBar(statusToDisplay) {
    if (statusToDisplay === '') {
      return statusToDisplay;
    } else {
      statusToDisplay = statusToDisplay + '\/';
      return statusToDisplay;
    }
  }
  /**
* context menu functions
*/
  /**
   * call from context menu
   *
   * @param {string} callTo
   * @memberof DirectoryService
   */
  call(callTo: string) {
    if (callTo === "extension") {
      this.contextService.websocketService.dialAction(this.objDirectoryView.extension);
      this.contextService.log("call:" + this.objDirectoryView.extension);
    } else if (callTo === "mobile") {
      this.contextService.websocketService.dialAction(this.objDirectoryView.mobile);
      this.contextService.log("call:" + this.objDirectoryView.mobile);
    } else if (callTo === "number") {
      this.contextService.websocketService.dialAction(this.objDirectoryView.phone);
      this.contextService.log("call:" + this.objDirectoryView.phone)
    }

  }

  /**
   * transfer from contextmenu
   *
   * @param {string} transferTo
   * @memberof DirectoryService
   */
  transfer(transferTo: string) {

  }

  /**
   * transfer call action on drop from active call window
   *
   * @param {string} transferTo
   * @memberof DirectoryService
   */
  transferOnDrop(transferTo: string) {
  }

  /**
   * shows view user details window
   *
   * @memberof DirectoryService
   */
  viewUserDetails() {
    this.contextService.viewUserDetailsModalVis.next(this.objDirectoryView.userId);
  }

  /**
   * send IM to a broadworks directory
   *
   * @memberof DirectoryService
   */
  instantMessage() {
    this.contextService.instantMessage(this.objDirectoryView.userId);
  }


  /**
   * copy number to clipboard
   *
   * @param {*} text
   * @memberof DirectoryService
   */
  CopyToClipboard(text) {
    if (text === 'number') {
      this.contextService.copy(this.objDirectoryView.phone);
    } else if (text === 'mobile') {
      this.contextService.copy(this.objDirectoryView.mobile);
    }
  }

  //>personal directory
  resetUserDetails() {
    this.selectedName = '';
    this.selectedPhone = '';
  }

  setContactValues() {
    this.selectedName = this.objDirectoryView.name;
    this.selectedPhone = this.objDirectoryView.phone;
    this.tempSelectedName = this.selectedName;
    this.tempSelectedPhone = this.selectedPhone;
    this.editFlag = true;
  }

  setCallLogValues(name: string, phone: string) {
    this.selectedName = name;
    this.selectedPhone = phone;
  }

  setDropAreaValues(callLog: CallStatusEntry) {
    if (callLog.personality === 'Originator') {
      this.selectedName = callLog.to;
    }
    else {
      this.selectedName = callLog.from;
    }
    this.selectedPhone = callLog.number;
  }

  reIndexDirectory() {
    let searchTerm = this.tempSearchTerm;
    this.setId();
    // this.filterDirectoryDisplayArray('');
    this.groupDirectoryDisplayArray(this.groupDirType, this.groupGName, this.groupDeptname);
    if (searchTerm !== "") {
      this.filterDirectoryDisplayArray(searchTerm);
    }
    this.directoryDisplayArray = [...this.directoryDisplayArray];
  }

  strip(value: string): string {
    let regex = new RegExp(/[(.)(.)]/, 'g');
    return value.replace(regex, '');
  }

  addUsertoPD() {
  }

  editUserFromPD() {
  }

  deleteUserFromPD() {

  }

  parkOnExtension(): void {
    let callId: string = "";
    if (this.contextService.selectedRows.length > 0) {
      if (this.contextService.selectedRows[0] && this.contextService.selectedRows[0].callStatus == 'Active') {
        callId = this.contextService.selectedRows[0].callId;
      } else {
        this.contextService.log("Selected call is not active");
      }
    }
    else {
      if (this.contextService.callStatusList.length > 0 && this.contextService.callStatusList[0].callStatus == 'Active') {
        callId = this.contextService.callStatusList[0].callId;
      } else {
        this.contextService.log("first call in call control window is not active");
      }
    }

  }
  //<personal directory
  answerThisCall(): void {

  }
  bargeIntoThiscall() {

  }
  warmTransfer(type: string, activecallId: string) {

  }
  callExtensionWhenAvailable() {

  }
}

