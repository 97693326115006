<div class="row">
  <p>
    {{description}}
  </p>
</div>
<div class="row">
  <label for="" class="label">{{startUpState}}</label>
  <p-dropdown [options]="this.acdStateList" name="dpd_startUpACDState" [style]="{'width':'100%'}" [(ngModel)]="this.settingsGeneral.startUpACDState"></p-dropdown>
</div>
<div class="row" >
  <label for="" class="label">{{postCallState}}</label>
  <p-dropdown [options]="this.acdStateList" name="dpd_postCallACDState"  [style]="{'width':'100%'}" [(ngModel)]="this.settingsGeneral.postCallACDState"></p-dropdown>
</div>
<div class="row">
  <label for="" class="label">{{wrapUpDuration}}</label>
  <input type="text" name="input-text" id="txt_wrapUpDuration"  placeholder="" autocomplete=off [(ngModel)]="this.settingsGeneral.wrapUpDuration" style="width: 20%">
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{forceDisposition}} [(ngModel)]="this.settingsGeneral.assignDispositionCode"
    binary="true"></p-checkbox>
    <div class="row">
      <label for="" class="label">{{for}}</label>
      <input type="text" name="input-text" id="textarea"  [disabled]="!this.settingsGeneral.assignDispositionCode" [(ngModel)]="this.settingsGeneral.dispositionTime" autocomplete=off style="width: 20%">
      <label for="" class="label">{{seconds}}</label>
    </div>
</div>


<div class="row">
  <p-checkbox name="checkboxGroup1" value="checkbox 1" label={{preventChangingACDState}} [(ngModel)]="this.settingsGeneral.preventACDStateChage" binary="true"></p-checkbox>
</div>
<div class="row">
  <p-checkbox name="chk_showCurrentACDStateButton" value="checkbox 1" label={{showcurrentACD}} [(ngModel)]="this.settingsGeneral.showCurrentACDStateButton" binary="true"></p-checkbox>
</div>
<div class="row">
  <p-checkbox name="chk_signOutACDState" value="checkbox" label={{activateSignOut}} [(ngModel)]="this.settingsGeneral.signOutACDState" binary="true"></p-checkbox>
</div>
<div class="row" >
  <label for="" class="label">{{whenDisplayingACD}}</label>
  <p-dropdown [options]="this.duration" name="dropdown" [(ngModel)]="this.settingsGeneral.showDuration"  [style]="{'width':'100%'}"></p-dropdown>
</div>