
import { ResponseHandler } from './ResponseHandler'; 
export abstract class ResponseRouter {
    responseHandlersList : ResponseHandler[];

    public register(handler : ResponseHandler) {
        
    console.log('ResponseRouter - register--'+ handler.type);
        	this.responseHandlersList[handler.type]=handler;
    }

    public unregister(handler : ResponseHandler) { 
        delete this.responseHandlersList[handler.type];
    }
    public unregisterByHandlerType(handlerType : string) { 
        delete this.responseHandlersList[handlerType];
    }
    constructor() {
        console.log('###### constructor - ResponseRouter ');
        this.responseHandlersList = [];
    }

    public logResponseHandlersList() { 
        console.log('ResponseRouter -   this.responseHandlersList');
        console.log(this.responseHandlersList);
    }
    
    public isHandlerRegistered(handlerType:string) : boolean{ 
      if(handlerType in this.responseHandlersList)
          {
          return true;
          }
        return false;
    }
    
}




