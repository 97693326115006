<div class="row">
  <p>
    {{description}}
    <p>
</div>

<div class="row">
  <label for="" class="label">{{this.browseToUrl}}</label>
  <textarea name="textarea" style="font-size:15px; color:#4f5457" [(ngModel)]="settingsGeneral.browserInt_URL" rows="10"></textarea>
</div>

<div class="rowtest">
  <p>
    {{onInboundCall}}
    <p>
</div>
<div class="rowtest">
  <div class="rowtest">
    <label for="" class="label-block"></label>
    <p-checkbox name="checkboxGroup1" value="checkbox" label={{throughCallCenter}} binary="true" [(ngModel)]="settingsGeneral.browserInt_InboundCallCenter"></p-checkbox>
  </div>

  <div class="row">
    <label for="" class="label-block"></label>
    <p-checkbox name="checkboxGroup1" value="checkbox" label={{notThroughCallCenter}} binary="true" [(ngModel)]="settingsGeneral.browserInt_InboundNonCallCenter"></p-checkbox>
  </div>
</div>
<div class="rowtest">
  <p>
    {{onOutboundCall}}
    <p>
</div>
<div class="rowtest">
  <div class="rowtest">
    <label for="" class="label-block"></label>
    <p-checkbox name="checkboxGroup1" value="checkbox" label={{throughCallCenter}} binary="true" [(ngModel)]="settingsGeneral.browserInt_OutboundCallCenter"></p-checkbox>
  </div>

  <div class="row">
    <label for="" class="label-block"></label>
    <p-checkbox name="checkboxGroup1" value="checkbox" label={{notThroughCallCenter}} binary="true" [(ngModel)]="settingsGeneral.browserInt_OutbountNonCallCenter"></p-checkbox>
  </div>
</div>

<!-- <div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{doNotUseBrowserInterface}} binary="true" [(ngModel)]="settingsGeneral.browserInt_browserInterface"></p-checkbox>
</div> -->

<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{encodeDelimiter}} binary="true" [(ngModel)]="settingsGeneral.browserInt_EncodeURL"></p-checkbox>
</div>