import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { HelperService } from './../providers/helper.service';
import { ContextService } from './../providers/context.service';
import { ImService } from './../providers/im.service';

import { CallForwardningAlwaysService } from "./../providers/settings/settings-services/call-forwarding-always.service";
import { RemoteOfficeService } from "./../providers/settings/settings-services/remote-office.service";
import { DoNotDisturbService } from "./../providers/settings/settings-services/do-not-disturb.service";
import { SettingService } from "./../providers/settings/settings-general/setting.service";
import { DoNotDisturb } from '../model/bw-services/do-not-disturb';
import { Router } from '@angular/router';
import { AppComponent } from "app/app.component";
@Injectable()
export class DataService {
    startDate: Date;
    dropMsgState = new Subject<boolean>();
    droppedToCall = new Subject<any>();
    selectedStatus = new Subject<any>();
    myStatusMenuItems: MenuItem[] = [
        { label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.InOffice'), icon: 'ico-available', command: (event) => this.onClickCommPilot("Available In Office"), visible: true },
        { label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.OutOfOffice'), icon: 'ico-available', command: (event) => this.onClickCommPilot("Available Out Of Office"), visible: true },
        { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.Busy'), icon: 'ico-dnd', visible: true, command: (event) => this.onClickCommPilot("Busy") },
        { label: this.contextService.getLanguageTagByKey('Settings.Services.CommPilotExpress.Unavailable'), icon: 'ico-unavailable', command: (event) => this.onClickCommPilot("Unavailable"), visible: true },
        { label: this.contextService.getLanguageTagByKey('Misc.None'), icon: 'ico-available', command: (event) => this.noDNDCommPilot(), visible: true },
        { separator: false, visible: false },
        { label: this.contextService.getLanguageTagByKey('Settings.Services.DoNotDisturb'), icon: 'ico-onHook-DND', command: (event) => this.dndClick(), visible: false },
        { separator: false, visible: false },
        {
            label: this.contextService.getLanguageTagByKey('Settings.Services.CallForwardAlways'), icon: 'ico-callforward',
            items: [
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Deactivate'), command: (event) => { this.callForwardningAlwaysService.deactivateCFA() }, visible: false },
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Activate'), command: (event) => { this.callForwardningAlwaysService.activateCFA() }, visible: false },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Configure'), command: (event) => this.navigateToCallForwardAlways(), visible: true }
            ], visible: false
        },
        { separator: false, visible: false },
        {
            label: this.contextService.getLanguageTagByKey('Menu.MyStatus.ConnectToADevice'), icon: 'ico-adddevice',
            items: [], visible: false
        },
        { separator: false, visible: false },
        {
            label: this.contextService.getLanguageTagByKey('Settings.Services.RemoteOffice'), icon: 'ico-door',
            items: [
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Deactivate'), command: (event) => { this.remoteOfficeService.deactivateRemoteOffice() }, visible: false },
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Activate'), command: (event) => { this.remoteOfficeService.activateRemoteOffice() }, visible: false },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('Menu.MyStatus.Configure'), command: (event) => this.navigateToRemoteOffice(), visible: true }
            ], visible: false
        },
        { separator: false, visible: false },
        {
            label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant'), icon: 'ico-unavailable', items: [
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.ClearCurrentStatus'), command: (event) => { this.clearCurrentStatus() }, visible: false },
                { separator: false, visible: false },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AddScheduledStatus'), command: (event) => this.helper.outOfOfficeModalVis.next(true) },
                { separator: true, visible: true },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.InAMeeting'), command: (event) => { this.setIMStatus('In a meeting') }, visible: true, icon: '' },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutToLunch'), command: (event) => { this.setIMStatus('Out to lunch') }, visible: true, icon: '' },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OutOfTheOffice'), command: (event) => { this.setIMStatus('Out of the office') }, visible: true, icon: '' },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.OnLeave'), command: (event) => { this.setIMStatus('On leave') }, visible: true, icon: '' },
                { label: this.contextService.getLanguageTagByKey('OutOfOfficeAssistant.AwaySick'), command: (event) => { this.setIMStatus('Away sick') }, visible: true, icon: '' }

            ], visible: false
        },
    ];

    constructor(private helper: HelperService, private router: Router,
        public settingService: SettingService, public doNotDisturbService: DoNotDisturbService,
        public callForwardningAlwaysService: CallForwardningAlwaysService, public remoteOfficeService: RemoteOfficeService,
        private http: HttpClient, private contextService: ContextService, public imService: ImService) {

        this.contextService.log('###### constructor - DataService');

    }

    /**
     * Method user for current status
     * 
     * @memberof DataService
     */
    removeCurrentStatus() {
     
    }

    /**
     * used for clear current status
     * 
     * @memberof DataService
     */
    clearCurrentStatus() {
    


    }

    clearAdHocStatus() {
    

    }


    /**
     * used for set CFA
     * 
     * @memberof DataService
     */
    setCFA() {
      

    }

    setNone() {
     

    }

    navigateToCallForwardAlways() {
     

    }

    navigateToRemoteOffice() {
     
    }
    /**
     * Used for set IM Status
     * 
     * @param {any} e 
     * @memberof DataService
     */
    setIMStatus(e) {
       

    }

    noDNDCommPilot() {
       

    }

    dndClick() {

    

    }

    onClickCommPilot(c) {
   
    }


    zeroPad(val: number) {
        if (val < 10) {
            return '0' + val;
        } else {
            return val;
        }
    }
    getContacts() {
        return this.http.get('assets/data-big.json');
    }
    getLogs() {
        return this.http.get('assets/call-logs.json');
    }
    getStats() {
        return this.http.get('assets/mystats.json');
    }
    getOverallStats() {
        return this.http.get('assets/overall-stats.json');
    }
}