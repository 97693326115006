/** 
 * C8 - model for reply entry item
 */

export class ReplyEntry {
  id: number;
  title: string;
  description: string;
  text: string;

  constructor(id, title, description, text) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.text = text;
  }

}
