import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, SimpleChanges, ViewChild, AfterViewInit } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { Attachment } from '../../model/attachment.model'
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmojisComponent implements OnInit {

  @Output() onRightClick = new EventEmitter<any>();
  @Output() onDoubleClick = new EventEmitter<any>();
  @Output() onSingleClick = new EventEmitter<any>();

  emojiNames: Array<String> = [];

  constructor(public contextService: ContextService, public messagesService: MessagesService) { }

  ngOnInit() {
    this.emojiNames = Object.keys(this.messagesService.emojis)
  }

  singleClick(emojiShortCode) {
    this.onSingleClick.emit(emojiShortCode);
  }

  doubleClick(emojiShortCode) {
    this.onDoubleClick.emit(emojiShortCode);
  }

  rightClick(emojiShortCode) {
    this.onRightClick.emit(emojiShortCode);
  }
}
