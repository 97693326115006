import { Component, OnInit } from '@angular/core';
import { SettingsGeneral } from 'app/providers/settings/settings-general/settings-general.service';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss']
})
export class ContactSearchComponent implements OnInit {
  description: string = "";
  includeAllContact: string = "";
  showMonitoredUsersFirst: string = "";
  alwaysSortSearchByName: string = "";
  includeDepartmentInSearch: string = "";
  clearSearchBox: string = "";
  privacyEnabled: string = "";
  activateContactSearch: string = "";

  includeSearchWhen:string = "";
  startsWith:string="";
  contains:string="";
  constructor(public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.description = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.Description');
    this.includeAllContact = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.IncludeExternalUsersWhenSearching');
    this.showMonitoredUsersFirst = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.ShowMonitoredUsersFirstInSearchResults');
    this.alwaysSortSearchByName = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.AlwaysSortNewSearchResultsByName');
    this.includeDepartmentInSearch = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.IncludeDepartmentNameInContactSearch');
    this.clearSearchBox = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.ClearContactSearchBoxOnFocus');
    this.privacyEnabled = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.PromptWhenPrivacyEnabled');
    this.activateContactSearch = this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.ActivateSearchOnCallAnswer');
    this.includeSearchWhen = "Include contacts when";
    this.startsWith=this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.ContactSearchBasedOnStartsWith');
    this.contains=this.settingsGeneral.contextService.getLanguageTagByKey('Settings.Reception.ContactSearch.ContactSearchBasedOnContains');
    this.settingsGeneral.settingsChanged = true;
  }
  disableAlwaysSort() {
    if (this.settingsGeneral.contact_showMonitoredUsersFirst) {
      this.settingsGeneral.contact_alwaysSortSearchByName = false;
    }
  }
}
