<div class="schedule-wrap">
  <form
    (ngSubmit)="onFormSubmit()"
    [formGroup]="scheduleForm"
    #rfRef="ngForm"
    id="scheduleForm"
  >
    <h4 class="blue-headline">{{ title }}</h4>
    <div class="select-date-time">
      <p-calendar
        formControlName="scheduleDate"
        [(ngModel)]="scheduleDate"
        [showIcon]="true"
      ></p-calendar>

      <div class="select-time">
        <p-dropdown
          [options]="scheduleHourOpts"
          formControlName="scheduleHour"
        ></p-dropdown>
        <span>:</span>
        <p-dropdown
          [options]="scheduleMinOpts"
          formControlName="scheduleMin"
        ></p-dropdown>
      </div>
    </div>

    <div class="sbtns">
      <a class="btn-white" (click)="close()">{{ btnCancel }}</a>
      <a
        *ngIf="scheduleDateSelected"
        class="btn-white sh-clear"
        (click)="clear()"
        >{{ btnClear }}</a
      >
      <button type="submit" form="scheduleForm" class="btn-blue-light">
        {{ btnOk }}
      </button>
    </div>
  </form>
  <p *ngIf="wrongDateTime" class="error-message">
    {{ errorMessage }}
  </p>
</div>
