import { Component, OnInit } from '@angular/core';
import { AppComponent } from "app/app.component";
import { BwDirectoryEntry } from "app/model/directory/bw-directory-entry";
import { ChatService } from "app/providers/chat.service";
import { MenuItem } from 'primeng/primeng';
import { timer } from 'rxjs';
import { Subject } from 'rxjs';
import { ISubscription } from "rxjs/Subscription";
import { CallType } from '../../../model/call-logs/call-type';
import { ContextService } from '../../../providers/context.service';
import { CallRecordingService } from '../../../providers/settings/settings-services/call-recording.service';
import { HelperService } from './../../../providers/helper.service';


@Component({
  selector: 'app-buttons-menu',
  templateUrl: './buttons-menu.component.html',
  styleUrls: ['./buttons-menu.component.scss']
})
export class ButtonsMenuComponent implements OnInit {
  unavailableMenu: MenuItem[];
  dialMenu: MenuItem[];
  recordingLCMenu: MenuItem[];
  recordingRCMenu: MenuItem[];
  recordingState: string = 'none';
  recordingTooltip: string = '';

  // chatTooltip: string = 'Open chat';
  transferMenu: MenuItem[];
  closeModalVis = new Subject<boolean>();
  conferenceAddCallId: string = "";
  conferenceAddPerson: string = "";
  conferenceAddConfirm: boolean = false;
  conferenceAddVis: boolean = false;
  callNotActiveState: boolean = false;
  public objCallLogs: Array<any>;
  //recording
  recordingAlwaysError: boolean = false;
  recordingNeverError: boolean = false;
  recordingNeverMessage: string = "";
  recordingMode: string = "";

  unavailableMenuTemp: any[] = [];
  DNISArray: any = [];

  subject: ISubscription;
  timerValue: number = 0;
  CannotChangeAcdStateOnCall: boolean = false;


  addCalltoconference: string = "";
  btnNo: string = "";
  btnYes: string = "";
  nWaycallingnotassigned: string = "";
  btnOk: string = "";
  callAlerting: string = "";
  stopPausenotallowed: string = "";
  recStartnotallowed: string = "";
  cannotChangeAcdStateOnCall: string = "";

  agentUnavailable: string = "";
  agentAvailable: string = "";
  agentWrapUp: string = "";
  releaseBtnlbl: string = "";
  answerBtnlbl: string = "";
  diallbl: string = "";
  transferlbl: string = "";
  retrieveBtnlbl: string = "";
  holdBtnlbl: string = "";
  conferenceBtnlbl: string = "";
  recordinglbl: string = "";
  sendToVM: string = "";
  conferenceMenu: MenuItem[];
  notes: Notification[] = [];
  conferenceContextMenu: any;
  showRecordingButton: boolean = false;
  callParkBtnLabel: string = "";
  surveyExtensionBtnLabel: string = "";
  transferToolTip: string = "";
  constructor(private helper: HelperService, private mainApp: AppComponent, private chatService: ChatService, public contextService: ContextService, private callRecordingService: CallRecordingService) {

    this.transferMenu = [];
    //Update call control buttons
    this.contextService.updateCallControlButtons.subscribe(() => {
      if (this.contextService.callStatusList.length > 0) {

        if (this.contextService.selectedRows.length > 0) {

          this.contextService.log(this.contextService.selectedRows[0].callStatus);

          let callState: string = this.contextService.selectedRows[0].callStatus;
          let personalityState: string = this.contextService.selectedRows[0].personality;
          let recordState: string = this.contextService.selectedRows[0].recordState;
          this.buttonStates(callState, personalityState, recordState);


        } else {
          this.buttonStates(this.contextService.callStatusList[0].callStatus, this.contextService.callStatusList[0].personality, this.contextService.callStatusList[0].recordState);
        }
      }
      else {
        this.DisableButtons();
      }

    });

    /**
     * Populate updateUnAvailableButton context menu
     */
    this.contextService.updateUnAvailableButton.subscribe(() => {
      if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) { // check button shows  not single   
        this.unavailableMenu = [];
        this.unavailableMenuTemp = [];
        this.sortUnAvailableCodes(); //sort unAvailable codes      
        this.insertUnAvailbleOptions();
        this.unavailableMenu = this.unavailableMenuTemp;
        if (this.contextService.agentDetails.state === 'Unavailable') {
          this.contextService.unAvailableClassName = "btn-unavailable active";
        } else {
          this.contextService.unAvailableClassName = "btn-unavailable";
        }
      } else { //button state is single  

        this.unavailableMenu = [];
        if (this.contextService.agentDetails.state === 'Available') {
          this.contextService.unAvailableClassName = 'btn-available active';
        } else if (this.contextService.agentDetails.state === 'Wrap-Up') {
          this.contextService.unAvailableClassName = 'btn-wrapup active';
        } else if (this.contextService.agentDetails.state === 'Unavailable') {
          this.contextService.unAvailableClassName = "btn-unavailable active";
        }
        this.unavailableMenu = [
          { label: contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable'), target: "Available", command: (event) => { this.available(event); }, icon: '', styleClass: 'state' },
          { label: contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp'), target: "Wrap-Up", command: (event) => { this.wrapUp(event); this.helper.setTickIcon(this.unavailableMenu, 1, null, 'state'); }, icon: '', styleClass: 'state' },
        ];
        this.unavailableMenuTemp = [];
        this.sortUnAvailableCodes();
        this.insertUnAvailbleOptions();
        if (this.unavailableMenuTemp.length > 0) {
          this.unavailableMenu.push({
            label: contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable'),
            items: this.unavailableMenuTemp
          });
        } else {
          this.unavailableMenu.push({
            label: contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable'), command: (event) => { this.contextService.websocketService.setAgentStateAction('Unavailable', "") }, icon: '', styleClass: 'state'
          });
        }

      }
      this.contextService.updateACDButtons.next();
    });
    /**
     * For update ACD Buttons
     */
    this.contextService.updateACDButtons.subscribe(() => {

      if (this.contextService.agentDetails.state === 'Unavailable') {

        if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
          this.makeUnAvailableMenuSelection(this.unavailableMenu);
        } else {
          this.removeUnAvailableMenuSelection(this.unavailableMenu);
          this.makeUnAvailableMenuSelection(this.unavailableMenuTemp);
          if (this.unavailableMenu.length > 0) {
            this.unavailableMenu[2].icon = 'tick';
          }
        }

      } else if (this.contextService.agentDetails.state === 'Wrap-Up') {
        //set wrap up       
        if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {      // check button shows  not single   
          this.contextService.wrapUpState = true;
          this.contextService.availableState = false;
          this.contextService.unAvailableState = false;
          this.contextService.unAvailableClassName = "btn-unavailable";
          this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');
          this.removeUnAvailableMenuSelection(this.unavailableMenu);
        } else { //button state is single
          this.contextService.unAvailableClassName = 'btn-wrapup active';
          this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp');
          this.removeUnAvailableMenuSelection(this.unavailableMenu);
          this.removeUnAvailableMenuSelection(this.unavailableMenuTemp);

          if (this.unavailableMenu.length > 0) {
            this.unavailableMenu[0].icon = '';
            this.unavailableMenu[1].icon = 'tick';
          }
        }

        if (!this.contextService.objSettings.acdState_AssignDispositionCode && !this.contextService.selectedDispositionCodeForPrevious && this.contextService.objSettings.acdState_WrapUpDuration != 0) {

          let time = timer(1, 1000);
          this.subject = time.subscribe(
            t => {
              this.timerValue = this.pad(t % 60);
              if (this.timerValue == this.pad(this.contextService.objSettings.acdState_WrapUpDuration)) {

                this.subject.unsubscribe();
                if (this.contextService.acdState === "Wrap-Up") {
                  this.contextService.websocketService.setAgentStateAction('Available', '');
                  this.contextService.log("Performed call center action with Available by considering wrap-up duration");
                }


              }
            }
          );
        }
      } else if (this.contextService.agentDetails.state === 'Available') {
        //set avilable

        if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) { // check button shows  not single
          this.contextService.availableState = true;
          this.contextService.unAvailableState = false;
          this.contextService.wrapUpState = false;
          this.contextService.unAvailableClassName = "btn-unavailable";
          this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');
          this.removeUnAvailableMenuSelection(this.unavailableMenu);
        } else { //button state is single         
          this.contextService.unAvailableClassName = 'btn-available active';
          this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable');
          this.removeUnAvailableMenuSelection(this.unavailableMenu);
          this.removeUnAvailableMenuSelection(this.unavailableMenuTemp);

          if (this.unavailableMenu.length > 0) {
            this.unavailableMenu[0].icon = 'tick';
          }
        }

      } else if (this.contextService.agentDetails.state === 'Sign-Out' || this.contextService.agentDetails.state === 'Sign-In') {

        this.contextService.wrapUpState = false;
        this.contextService.availableState = false;
        this.contextService.unAvailableState = false;
        this.contextService.unAvailableClassName = "btn-unavailable";
        this.removeUnAvailableMenuSelection(this.unavailableMenu);
        this.removeUnAvailableMenuSelection(this.unavailableMenuTemp);
        if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) { // check button shows  not single
          this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');
        } else {
          this.contextService.agentDetails.state == 'Sign-In' ? this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignIn') : this.contextService.getLanguageTagByKey('CallButtonShowing.AgentSignOut');
        }
      }

    });

  }

  ngOnInit() {
    this.surveyExtensionBtnLabel = this.contextService.getLanguageTagByKey('CallButtonShowing.Survey');
    this.callParkBtnLabel = this.contextService.getLanguageTagByKey('CallButtonShowing.CallParkRetrieve.ToolTip');
    this.agentUnavailable = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');
    this.agentAvailable = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentAvailable');
    this.agentWrapUp = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentWrapUp');
    this.releaseBtnlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Release');
    this.answerBtnlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Answer');
    this.diallbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Dial');
    this.transferlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Transfer');
    this.retrieveBtnlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Retrieve');
    this.holdBtnlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Hold');
    this.conferenceBtnlbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Conference');
    this.recordinglbl = this.contextService.getLanguageTagByKey('CallButtonShowing.Recording');
    this.sendToVM = this.contextService.getLanguageTagByKey('CallButtonShowing.SendToVM');
    this.transferToolTip = this.contextService.getLanguageTagByKey('CallButtonShowing.Transfer.ToolTip');


    this.addCalltoconference = this.contextService.getLanguageTagByKey('Prompt.AddCallToConference');
    this.btnNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.btnYes = this.contextService.getLanguageTagByKey('Misc.Yes');
    this.nWaycallingnotassigned = this.contextService.getLanguageTagByKey('Prompt.NWayCallingNotAssigned');
    this.btnOk = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.callAlerting = this.contextService.getLanguageTagByKey('Prompt.CallAlerting');
    this.stopPausenotallowed = this.contextService.getLanguageTagByKey('CallButtonShowing.Recording.StopPause.NotAllowed');
    this.recStartnotallowed = this.contextService.getLanguageTagByKey('CallButtonShowing.Recording.Start.NotAllowed');
    this.cannotChangeAcdStateOnCall = this.contextService.getLanguageTagByKey('Prompt.CannotChangeAcdStateOnCall');


    this.contextService.setRecordingMode.subscribe((val) => {
      this.recordingMode = val;
      let position: number = 0;
      if (this.recordingMode === 'always') {
        position = 0;
      } else if (this.recordingMode === 'on-demand') {
        position = 1;

      } else if (this.recordingMode === 'never') {
        position = 2;

      } else if (this.recordingMode === 'always-pause-resume') {
        position = 3;

      } else if (this.recordingMode === 'on-demand-user-start') {
        position = 4;

      }
      if (this.contextService.objLoggedUserDetails.getuserCanChangeCallRecordingOption() === "1") {
        this.helper.setTickIcon(this.recordingRCMenu, position, null, 'state');
      }
    });
    this.contextService.imConnectionLostModalVis.subscribe(val => {

      if (val === "closeChatWindow") {
        if (this.contextService.isChatOpen) {
          this.mainApp.toggleChat();
          this.contextService.chatTooltip = this.contextService.getLanguageTagByKey('CallButtonShowing.OpenChat');
          this.contextService.chatWindowStatus = false;
        }
      }
    });
    if (this.contextService.objLoggedUserDetails.getuserCanChangeCallRecordingOption() === "1") {
      this.recordingRCMenu = [
        { label: 'Always', command: (event) => { this.helper.setTickIcon(this.recordingRCMenu, 0, null, 'state'); this.callRecordingService.recordingRequest('always'); }, icon: '', styleClass: 'state' },
        { label: 'On Demand', command: (event) => { this.helper.setTickIcon(this.recordingRCMenu, 1, null, 'state'); this.callRecordingService.recordingRequest('on-demand'); }, icon: '', styleClass: 'state' },
        { label: 'Never', command: (event) => { this.helper.setTickIcon(this.recordingRCMenu, 2, null, 'state'); this.callRecordingService.recordingRequest('never'); }, icon: '', styleClass: 'state' },
        { label: 'Always with Pause/Resume', command: (event) => { this.helper.setTickIcon(this.recordingRCMenu, 3, null, 'state'); this.callRecordingService.recordingRequest('always-pause-resume'); }, icon: '', styleClass: 'state' },
        { label: 'On Demand with User Initiated Start', command: (event) => { this.helper.setTickIcon(this.recordingRCMenu, 4, null, 'state'); this.callRecordingService.recordingRequest('on-demand-user-start'); }, icon: '', styleClass: 'state' },
      ];
    }
    this.recordingLCMenu = [];
    this.recordingTooltip = 'Recording';

    this.chatService.toggleChat.subscribe((val) => {
      this.toggleChat()
    });
    this.contextService.recordButtonState = 'disabled';
    this.contextService.recordingState = 'inactive ico-drop';

    this.contextService.unAvailableTooltipValue = this.contextService.getLanguageTagByKey('CallButtonShowing.AgentUnavailable');

    if (this.contextService.assignedServices.includes('CallRecording') && this.contextService.objLoggedUserDetails.getuserCanChangeCallRecordingOption() !== '0') {
      this.showRecordingButton = true;
    }
  }

  buttonStates(callState: string, personalityState: string, recordState: string) {
    //Incoming call
    if (personalityState === 'Terminator' && callState === 'Alerting') {
      this.contextService.releaseAnswerState = "answer";
      //athul
      this.contextService.transferState = "disabled";
      this.contextService.holdRetrieveState = "holdDisabled";
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = "conferenceDisable";
      }
      this.contextService.recordButtonState = "disabled";
      this.contextService.recordingState = 'inactive ico-drop';
      //athul
    }
    //Outgoing Call
    if (personalityState === 'Originator' && callState === 'Alerting') {
      this.contextService.releaseAnswerState = "release";
      //athul
      this.contextService.transferState = "disabled";
      this.contextService.holdRetrieveState = "holdDisabled";
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = "conferenceDisable";
      }
      this.contextService.recordButtonState = "disabled";
      this.contextService.recordingState = 'inactive ico-drop';
      //athul

    }
    //Release, Transfer
    if (callState === "Released" || callState === 'Detached') {
      this.contextService.releaseAnswerState = "release";
    }
    //Active
    if (callState === "Active") {
      this.contextService.releaseAnswerState = "release";
      this.contextService.holdRetrieveState = "hold";
      this.contextService.transferState = 'enabled';
    }
    //Hold
    if (callState === "Held") {
      this.contextService.releaseAnswerState = "release";
      this.contextService.holdRetrieveState = "retrieve";
      this.contextService.transferState = 'enabled';
    }
    let callCount: number = 0;
    this.contextService.callStatusList.forEach(element => {
      if (element.callStatus !== 'Alerting') {
        callCount = callCount + 1;
      }
    });

    // If call count >=2, enable conference button else disable conference button
    if (callCount >= 2) {
      if ((this.contextService.assignedServices.includes('Three-WayCall')) || (this.contextService.assignedServices.includes('N-WayCall'))) {

        this.contextService.conferenceState = 'conferenceActive';
      }
    }
    else {
      if (!this.contextService.isMeetMeConferenceAssigned) {
        this.contextService.conferenceState = 'conferenceDisable';
      }
    }
    this.contextService.recordingButtonState(callState, recordState);
  }
  //athul

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  /**
   * Sorting: Unavailable code list sort by name
   */
  sortUnAvailableCodes() {
    //Sort Menulist by Description
    this.contextService.unAvailableCodesArray.sort(function (a, b) {
      var nameA = a.Description.toLowerCase(), nameB = b.Description.toLowerCase()
      if (nameA < nameB) //sort string ascending
        return -1
      if (nameA > nameB)
        return 1
      return 0 //default return value (no sorting)
    })
  }
  /**
   * Insert Unavailable codes for generating Unavailble code menu
   */
  insertUnAvailbleOptions() {
    let positionIndex: number = 2;
    let defaultValue = "";
    if (this.contextService.unAvailableCodesArray.length > 0) {
      this.unavailableMenuTemp.push({ label: "None", title: "None", badge: 0, target: "", command: (event) => this.unAvailableMenuClick(event), icon: '', styleClass: 'state', visible: true });

      this.unavailableMenuTemp.push({ separator: true, visible: true });
    }
    for (let i = 0; i < this.contextService.unAvailableCodesArray.length; i++) {
      defaultValue = "";
      if (this.contextService.unAvailableCodesArray[i].isDefault) {
        defaultValue = " (" + this.contextService.getLanguageTagByKey('Misc.Default') + ")";
      }
      this.unavailableMenuTemp.push({ label: this.contextService.unAvailableCodesArray[i].Description + defaultValue, title: this.contextService.unAvailableCodesArray[i].Description, badge: positionIndex, target: this.contextService.unAvailableCodesArray[i].code, command: (event) => this.unAvailableMenuClick(event), icon: '', styleClass: 'state' });
      positionIndex = positionIndex + 1;
    }
  }
  /**
   * Make tick mark on selected unavailble option in menu
   * @param itemList Unavailble menu list
   */
  makeUnAvailableMenuSelection(itemList) {
    if (itemList !== undefined && itemList.length > 0 && this.contextService.agentDetails.unavailableCode !== "") {
      itemList.forEach(element => {
        element.icon = '';
        if (element.target === this.contextService.agentDetails.unavailableCode) {
          element.icon = 'tick';
          this.contextService.unAvailableTooltipValue = "Unavailable  - " + element.title;
          this.unavailable();
        }
      });
    } else {
      if (this.contextService.agentDetails.unavailableCode == "") {
        this.contextService.unAvailableTooltipValue = "Unavailable";
        if (itemList.length > 0) {
          itemList[0].icon = 'tick';
        }
        this.unavailable();
      }
    }
  }


  /**
   * Disable all control button if no calls
   * 
   * @memberof ButtonsMenuComponent
   */
  DisableButtons() {
    this.contextService.releaseAnswerState = "disable";
    this.contextService.transferState = "disabled";
    this.contextService.holdRetrieveState = "holdDisabled";
    if (!this.contextService.isMeetMeConferenceAssigned) {
      this.contextService.conferenceState = "conferenceDisable";
    }
    this.contextService.isTransferCall = false;
    this.contextService.recordingState = 'inactive ico-drop';
    this.contextService.recordButtonState = 'disabled';
  }

  setRecording(state) {
    this.recordingState = state;

    switch (state) {
      case 'recording':
        this.recordingLCMenu = [
          { label: 'Pause', command: (event) => this.setRecording('paused'), icon: '' },
          { label: 'Stop', command: (event) => this.setRecording('stop'), icon: '' }
        ];
        this.recordingTooltip = 'Recording';
        break;
      case 'paused':
        this.recordingLCMenu = [
          { label: 'Resume', command: (event) => this.setRecording('recording'), icon: '' },
          { label: 'Stop', command: (event) => this.setRecording('stop'), icon: '' }
        ];
        this.recordingTooltip = 'Paused';
        break;
      case 'stop':
        this.recordingLCMenu = [
          { label: 'Start recording', command: (event) => this.setRecording('recording'), icon: '' }
        ];
        this.recordingTooltip = 'Stopped';
        break;

      default:
        break;
    }
  }

  openReports() {
    this.helper.repVisible.next(true);
  }

  /**
   * Open dial window
   * 
   * @memberof ButtonsMenuComponent
   */
  openDial() {
    this.contextService.log("start Method: openDial() ");
    this.contextService.isTransferCall = false;
    this.helper.dialVisible.next(true);
    this.contextService.log("End Method: openDial() ");
  }

  openDialTransfer(e) {

    this.contextService.log("start Method: openDialTransfer()");
    this.contextService.transferCallId = e.item.target;
    this.helper.dialVisible.next(true);
    this.contextService.log("End Method: openDialTransfer()");

  }

  toggleChat() {
    if (this.contextService.chatCollapsed) {
      let tempID;
      this.contextService.objSettings.chat_openStatus = true;
      this.contextService.chatTooltip = this.contextService.getLanguageTagByKey('CallButtonShowing.CloseChat');
      if (this.contextService.conversations.length == 1) {
        this.contextService.conversations.forEach(element => {
          tempID = element.id;
          if (element.unread > 0) {
            if (element.id == tempID) {
              this.contextService.messageCounter--;
              element.unread = 0;
              const update = { 'conversationId': tempID, update: { 'unread': element.unread, "message": "" } };
              this.contextService.conversationHeaderChange.next(update);
            }
          }
        });
        this.contextService.messages = [];
        this.contextService.messageArray.forEach(element => {
          if (element.id == tempID && element.text.length > 0) {
            this.contextService.messages.push(element);
          }
        });
        if (this.contextService.active != tempID) {
          this.activeId(tempID.toString());
        }
      }
      if (this.contextService.active != "") {
        setTimeout(() => {
          this.contextService.chatFocus.next(this.contextService.active);
        }, 100);
        this.contextService.conversations.forEach(element => {
          tempID = this.contextService.active;
          if (element.id == tempID) {
            if (element.unread > 0) {
              this.contextService.messageCounter--;
              element.unread = 0;
              const update = { 'conversationId': tempID, update: { 'unread': element.unread, "message": "" } };
              this.contextService.conversationHeaderChange.next(update);
            }
          }
        });
        this.contextService.messages = [];
        this.contextService.messageArray.forEach(element => {
          if (element.id == tempID && element.text.length > 0) {
            this.contextService.messages.push(element);
          }
        });
      }
    }
    else {
      this.contextService.objSettings.chat_openStatus = false;
      this.contextService.chatTooltip = this.contextService.getLanguageTagByKey('CallButtonShowing.OpenChat');
    }
    this.mainApp.toggleChat();
    this.contextService.isChatOpen = !this.contextService.isChatOpen;
    if (this.contextService.isChatOpen) {
      this.contextService.chatWindowStatus = true;
    } else {
      this.contextService.chatWindowStatus = false;
    }
    this.helper.resized.next(true);
    this.contextService.objSettings.setSettings(this.contextService.objLoggedUserDetails.getUserId());
  }

  activeId(id: string) {
    this.contextService.activateId.next(id);
  }

  /**
   * perform call release button click
   * 
   * @memberof ButtonsMenuComponent
   */
  release() {

    

  }
  checkAnyCallCenterCall(): string {
    this.contextService.callStatusList.forEach(element => {
      if (element.isCallCenterCall) {
        return element.callId;
      }
    });
    return "";
  }
  /**
   * perform answer button click
   * 
   * @memberof ButtonsMenuComponent
   */
  answer() {
    this.contextService.log("start Method: answer()");

    this.contextService.log("End Method: answer()");
  }
  /**
   * perform hold button click
   * 
   * @memberof ButtonsMenuComponent
   */
  hold() {
    this.contextService.log("start Method: hold()");


    this.contextService.log("End Method: hold()");

  }
  /**
   * Perform call retrieve button click
   * 
   * @memberof ButtonsMenuComponent
   */
  retrieve() {
    this.contextService.log("start Method: retrieve()");

  }

  transferCallDisable() {
    this.transferMenu = [];
  }

  /**
   * This method is used for transfer call together if there is 2 calls in active call window
   * 
   * @memberof ButtonsMenuComponent
   */
  transferCallsTogether() {
    this.contextService.log("start method: transferCallsTogether()");
    this.contextService.log("End method: transferCallsTogether()");

  }
  selectedOption(e) {
    this.contextService.log("start method: selectedOption()");
    if (e.item.badge && e.item.target) {
      if (!this.checkCallStateForTransfer(e.item.badge) && !this.checkCallStateForTransfer(e.item.target)) {

        this.contextService.log("Performed announcedTransferAction from call Id:" + e.item.badge + "to call Id:" + e.item.target);

      }
      else {
        this.callNotActiveState = true;
      }
    }
    this.contextService.log("End method: selectedOption()");

  }

  /**
   * method check selected call state  if alerting return true and return for perfoming transfer
   * 
   * @param {any} callId1 
   * @returns {boolean} 
   * @memberof ButtonsMenuComponent
   */
  checkCallStateForTransfer(callId1): boolean {
    let alertState: boolean = false;
    this.contextService.callStatusList.forEach(element => {
      if (callId1 === element.callId) {
        if (element.callStatus === 'Alerting') {
          alertState = true;
        }
      }

    });
    return alertState;
  }

  /**
   * perform conference button click
   * 
   * @memberof ButtonsMenuComponent
   */
  conference() {
    this.contextService.log("start: conference()");

    this.contextService.log("End method: conference()");

  }
  /**
   * This method is used for add a new person to conference
   * 
   * @memberof ButtonsMenuComponent
   */
  AddConference() {
    this.contextService.log("start method: AddConference()");

    this.contextService.log("End method: AddConference()");

  }
  /**
   * perform voice mail button click
   * 
   * @memberof ButtonsMenuComponent
   */
  voiceMail() {
   
  }
  /**
   * 
   * insert dialed calls to context menu
   * @param {any} $event 
   * @memberof ButtonsMenuComponent
   */
  showDialMenu(event) {

    this.contextService.log("start method: showDialMenu(event)");

    this.contextService.dialMenu = [];
    let dialMenuSet = new Set();
    let counter = 0;

    this.objCallLogs = [];
    this.objCallLogs = this.contextService.callLogsList.filter(CallLogEntry => (CallLogEntry.callType == CallType.DIALED));

    this.objCallLogs.sort(function (a, b) {
      return b.time - a.time;
    });

    for (let i = 0; i < this.objCallLogs.length; i++) {
      if (counter < 10 && !dialMenuSet.has(this.objCallLogs[i].phone)) {
        dialMenuSet.add(this.objCallLogs[i].phone);
        this.contextService.dialMenu.push({ label: this.objCallLogs[i].name, target: this.objCallLogs[i].phone, command: (event) => this.contextService.websocketService.dialAction(event.item.target) });
        counter++;
      }
    }
    //Insert outbound number to dial menu
    if ((this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard'))) {
      this.DNISArray = [];
      let positionValue: number = 0;
      let iconvalue: string = '';
      for (let key in this.contextService.agentDetails.callCenters) {
        this.contextService.agentDetails.callCenters[key].dnisInfoList.forEach(element => {
          iconvalue = '';
          if (this.contextService.agentDetails.callCenters[key].acdUserId == this.contextService.agentDetails.dnisServiceUserId && element.name == this.contextService.agentDetails.dnisName) {
            iconvalue = 'tick';
          }
          this.DNISArray.push({ label: this.contextService.getLastNameFromUserID(key) + " (" + element.name + ")", target: key, badge: element.name, command: (event) => this.setDNISRequest(event), icon: iconvalue });
          positionValue = positionValue + 1;
        });
      }
      this.DNISArray.sort(function (a, b) {
        var nameA = a.label.toLowerCase(), nameB = b.label.toLowerCase()
        if (nameA < nameB) //sort string ascending
          return -1
        if (nameA > nameB)
          return 1
        return 0 //default return value (no sorting)
      })
      if (this.DNISArray.length > 0) {
        iconvalue = '';
        this.DNISArray.push({ separator: true, visible: true });
        if (!this.contextService.agentDetails.dnisServiceUserId && !this.contextService.agentDetails.dnisName) {
          iconvalue = 'tick';
        }
        this.DNISArray.push({ label: this.contextService.getLanguageTagByKey('Misc.None'), target: 'none', badge: '', command: (event) => this.setDNISRequest(event), icon: iconvalue });

        this.contextService.dialMenu.push({ separator: true, visible: true });
        this.contextService.dialMenu.push({
          label: this.contextService.getLanguageTagByKey('Menu.Context.ChangeOutboundNumber'),
          items: this.DNISArray
        });
      }

    }
    this.contextService.log("End method: showDialMenu(event)");

  }
  recordDisble() {
    this.recordingLCMenu = [];
  }
  /**
   * Used for start recording
   */
  startRecord() {

    this.contextService.log("start method: startRecord()");
    this.contextService.log("End method: startRecord()");
  }
  /**
   * Used for pause record
   */
  pauseRecord(cm) {

    this.contextService.log("start method: PauseRecord()");

    this.recordingLCMenu = [];
    event.preventDefault();
    event.stopPropagation();

    if (this.contextService.callStatusList.length > 0) {
      if (this.contextService.recordingMode === 'always') {
        this.recordingAlwaysError = true;
      }
      else if (this.contextService.recordingMode === 'on-demand' || this.contextService.recordingMode === 'always-pause-resume') {

        this.recordPauseRequest();

      }

      else if (this.contextService.recordingMode === 'on-demand-user-start') {

        if (this.contextService.recordingMode === 'on-demand-user-start') {
          this.recordingLCMenu = [
            { label: 'Pause', command: (event) => this.recordPauseRequest(), icon: '' },
            { label: 'Stop', command: (event) => this.stopRecord(), icon: '' }
          ];

        }
      }
      else if (this.contextService.recordingMode === 'never') {
        this.recordingAlwaysError = true;
      }
      this.helper.saveAllCMenus(cm);
      this.helper.hideAllCMenus();
      cm.show(event);
    }
    this.contextService.log("End method: PauseRecord()");

  }
  /**
   * Used for send pause record request
   */
  recordPauseRequest() {
    this.contextService.log("start method: recordPauseRequest()");

    this.contextService.log("End method: recordPauseRequest()");

  }
  /**
   * used for resume record
   */
  resumeRecord() {
    this.contextService.log("start method: resumeRecord()");


    this.contextService.log("End method: resumeRecord()");

  }
  /**
   *used for Stop recording
   */
  stopRecord() {
    this.contextService.log("start method: stopRecord()");
    this.contextService.log("End method: stopRecord()");

  }
  /**
   *perform avilable ACD Action on button click
   *
   * @memberof ButtonsMenuComponent
   */
  available(event?) {
    this.contextService.log("start: available()");


    if ((this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard'))) {
      let sendRequest = false;
      if (this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
        if (event.item.icon !== "tick") {
          sendRequest = true;
        }
      } else {
        sendRequest = true;
      }
      if (sendRequest) {
        if (this.contextService.callStatusList.length > 0 && this.contextService.ongoingCallCenterCallDetails) {
          if (this.contextService.objSettings.acdState_PreventACDStateChage) {
            this.CannotChangeAcdStateOnCall = true;
          } else {

            this.contextService.websocketService.setAgentStateAction('Available', '');
          }
        } else {

          this.contextService.websocketService.setAgentStateAction('Available', '');
        }
        this.contextService.log("Performed call center action with Available");
        if (this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
          this.helper.setTickIcon(this.unavailableMenu, 0, null, 'state');
        }
      }

    }

    this.contextService.log("End: available()");
  }
  /**
   *perform Unavilable ACD Action on button click
   *
   * @param {*} event
   * @memberof ButtonsMenuComponent
   */
  unAvailableMenuClick(event) {
    this.contextService.log("start: unAvailableMenuClick()");
    if ((this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard'))) {
      if (event.item.icon !== "tick") {
        if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
          this.helper.setTickIcon(this.unavailableMenu, event.item.badge, null, 'state');
        } else {
          this.unavailableMenu.forEach(elementItems => {
            if (elementItems.label === 'Unavailable') {
              this.helper.setTickIcon(elementItems.items, event.item.badge, null, 'state');

            }
          });
        }
        this.contextService.websocketService.setAgentStateAction('Unavailable', event.item.target);
        this.contextService.log("Performed call center action with Unavailable");
      }
    }
    this.contextService.log("End: unAvailableMenuClick()");
  }
  /**
   *Remove selection from unavilable button context menu
   *
   * @memberof ButtonsMenuComponent
   */
  removeUnAvailableMenuSelection(list) {
    this.contextService.log("start: removeUnAvailableMenuSelection()");
    if (list !== undefined && list.length > 0) {
      list.forEach(element => {
        element.icon = '';
      });
    }
    this.contextService.log("End: removeUnAvailableMenuSelection()");
  }
  /**
   *Change Unavilable button style
   *
   * @memberof ButtonsMenuComponent
   */
  unavailable() {
    this.contextService.log("start: unavailable()");
    this.contextService.unAvailableState = true;
    this.contextService.availableState = false;
    this.contextService.wrapUpState = false;
    this.contextService.unAvailableClassName = "btn-unavailable active";
    this.contextService.log("End: unavailable()");

  }
  /**
   *perform wrapUp ACD Action on button click
   *
   * @memberof ButtonsMenuComponent
   */
  wrapUp(event?) {
    this.contextService.log("start: wrapUp()");
    if ((this.contextService.objSettings.STARTUP === 2 || this.contextService.objSettings.STARTUP === 3) && (this.contextService.assignedServices.includes('CallCenter-Premium') || this.contextService.assignedServices.includes('CallCenter-Standard'))) {
      let sendRequest = false;
      if (this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
        if (event.item.icon !== "tick") {
          sendRequest = true;
        }
      } else {
        sendRequest = true;
      }
      if (sendRequest) {
        if (this.contextService.callStatusList.length > 0 && this.contextService.ongoingCallCenterCallDetails && this.contextService.objSettings.acdState_PreventACDStateChage) {
          this.CannotChangeAcdStateOnCall = true;
        } else {
          this.contextService.websocketService.setAgentStateAction('Wrap-Up', '');
        }
        this.contextService.log("Performed call center action with wrapUp");
      }
    }
    this.contextService.log("End: wrapUp()");
  }
  /**
   * send SetOutboundDnis request
   * @param event get selected menu data
   */
  setDNISRequest(event) {
  }
  /**
   * 
   * @param event Unavailable acd button click
   */
  unAvailableClick(event) {

    if (this.contextService.callStatusList.length > 0 && this.contextService.ongoingCallCenterCallDetails && this.contextService.objSettings.acdState_PreventACDStateChage) { // check if any call and call is call center call
      this.CannotChangeAcdStateOnCall = true;
      this.unavailableMenu = [];
    }
    else {
      this.contextService.updateUnAvailableButton.next();
    }
  }

  openUnavailableCm(event, cm) {
    event.preventDefault();
    event.stopPropagation();

    this.unAvailableClick(event);
    this.helper.saveAllCMenus(cm);
    this.helper.hideAllCMenus();
    cm.show(event);
    if (this.contextService.unAvailableCodesArray.length < 1) {
      if (!this.contextService.objSettings.acdState_ShowCurrentACDStateButton) {
        if (!this.contextService.unAvailableState) {
          this.contextService.websocketService.setAgentStateAction('Unavailable', "");
        }
      }
    }

    return false;
  }

  /**
* perform transfer button click
* 
* @memberof ButtonsMenuComponent
*/
  transferCall(event, cm) {
    this.contextService.log("start method: transferCall()");
    if (this.contextService.assignedServices.includes("CallTransfer")) {
      this.contextService.transferCallId = "";
      this.contextService.isTransferCall = true;
      this.transferMenu = [];
      if (this.contextService.callStatusList.length > 0) {

        // if there is one call in active call window
        if (this.contextService.callStatusList.length > 0 && this.contextService.callStatusList.length == 1) {
          if (this.contextService.callStatusList[0].callStatus !== 'Alerting') {
            this.helper.dialVisible.next(true);
            this.contextService.log("only one window in active call window and dial window opend for transfer call");
          }
        }
        else {
          event.preventDefault();
          event.stopPropagation();
          //if there is more than one call in active call window, it show mutiple menu options for transfer calls
          for (let index = 0; index < this.contextService.callStatusList.length; index++) {

            if (!this.contextService.callStatusList[index].isQueuedCall) {  //check not a queued call.

              let subMenu = [];
              for (let index2 = 0; index2 < this.contextService.callStatusList.length; index2++) {


                this.contextService.log(this.contextService.callStatusList[index2].callId + "----" + this.contextService.callStatusList[index].callId + "----" + (this.contextService.callStatusList[index2].callId !== this.contextService.callStatusList[index].callId));

                if (this.contextService.callStatusList[index2].callId !== this.contextService.callStatusList[index].callId) {
                  //badge holds first callId, target holds second callId

                  let transferNameTo: string;
                  if (this.contextService.callStatusList[index2].personality === 'Originator') {
                    transferNameTo = this.contextService.callStatusList[index2].to;
                  }
                  else {
                    transferNameTo = this.contextService.callStatusList[index2].from;

                  }

                  if (this.contextService.callStatusList[index2].callStatus !== 'Alerting') {

                    subMenu.push({ label: transferNameTo, badge: this.contextService.callStatusList[index].callId, target: this.contextService.callStatusList[index2].callId, command: (event) => this.selectedOption(event) });
                    subMenu.push({ separator: true });
                  }

                }

              }

              subMenu.push({ label: 'to number', target: this.contextService.callStatusList[index].callId, command: (event) => this.openDialTransfer(event) });

              let transferNameFrom: string;
              if (this.contextService.callStatusList[index].personality === 'Originator') {
                transferNameFrom = this.contextService.callStatusList[index].to;
              }
              else {
                transferNameFrom = this.contextService.callStatusList[index].from;
              }
              this.transferMenu.push({ label: 'Transfer ' + transferNameFrom + '', items: subMenu, command: (event) => this.contextService.log('clicked') });
              this.helper.saveAllCMenus(cm);
              this.helper.hideAllCMenus();
              cm.show(event);
            }

          }
          // push call transfer together option if there is only 2 calls
          if (this.contextService.callStatusList.length == 2) {

            if (this.contextService.callStatusList[0].callStatus !== 'Alerting' && this.contextService.callStatusList[1].callStatus !== 'Alerting') {
              this.transferMenu.push({ separator: true });
              this.transferMenu.push({ label: 'Transfer call together', command: (event) => this.transferCallsTogether() });
              this.helper.saveAllCMenus(cm);
              this.helper.hideAllCMenus();
              cm.show(event);
            }
          }

        }
      }
    }
    this.contextService.log("End Method: transferCall()");

  }

  conferenceBridge(event, cm) {
    event.preventDefault();
    event.stopPropagation();
    let count = 0;
    for (const key in this.contextService.bridgeList) {
      if (this.contextService.bridgeList.hasOwnProperty(key)) {
        let conferenceListCount = Object.keys(this.contextService.bridgeList[key].conferenceList).length
        if (conferenceListCount > 0) {
          count += conferenceListCount;
        }
      }
      if (count > 1) {
        break;
      }
    }

    if (count > 1) {
      this.conferenceMenu = [
        {
          label: this.contextService.getLanguageTagByKey('Menu.Context.MeetMeConference.MyConferenceBridge'), icon: '', styleClass: 'state', items: []
        }
      ];

      this.conferenceMenu[0].items = [];

    
    } else if (count === 1) {
      this.conferenceMenu = [
        {
          label: this.contextService.getLanguageTagByKey('Menu.Context.MeetMeConference.MyConferenceBridge'), icon: '', styleClass: 'state', items: []
        }
      ];

    
    }
    this.helper.saveAllCMenus(cm);
    this.helper.hideAllCMenus();
    cm.show(event);
  }

  showDetails(conferenceID, bridgeID) {
    let conferenceText = 'Conference Details';
    let extension = this.contextService.bridgeList[bridgeID].extension;
    let phone = this.contextService.bridgeList[bridgeID].phoneNumber;
    let myNotifications;
    myNotifications = new Notification(conferenceText, {
      body: this.contextService.getLanguageTagByKey('Misc.Phone') + ': ' + phone + '\n' + this.contextService.getLanguageTagByKey('List.Directories.Column.Extension') + ': ' + extension + '\n' + 'ID: ' + conferenceID + '#', icon: './assets/img/unity.ico',
    })
    if (myNotifications) {
      myNotifications.onclick = (event) => {
        this.contextService.log('Notification clicked');
        window.focus();
        myNotifications.close();

      }
    }
    setTimeout(myNotifications.close.bind(myNotifications), parseInt('20' + '000'));
  }

  copyToClipBoard(conferenceID, bridgeID) {
    let textArea = document.createElement("textarea");
    let extension = this.contextService.bridgeList[bridgeID].extension;
    let phone = this.contextService.bridgeList[bridgeID].phoneNumber;

    textArea.value = "Conference" + " | " + "Phone: " + phone + " | " + "Extension: " + extension + " | " + "ID: " + conferenceID;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);

  }
  callConferenceBridge(conferenceID, bridgeID) {
    this.contextService.websocketService.dialAction(this.contextService.bridgeList[bridgeID].extension, conferenceID);
  }

  getExtensionFromUserID(loginID: string): String {
    for (let i = 0; i < this.contextService.directoryList.length; i++) {
      if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
        let element = this.contextService.directoryList[i] as BwDirectoryEntry;
        if (element.getUserId() === loginID) {
          return element.getNumber();
        }
      }
    }
  }

  CallPark() {

  }

  surveyExtensionClick() {
   
  }
  warmTransfer() {
    this.contextService.log("warm transfer button clicked");
  
  }
}





