import { Component, OnInit, Input, Output, ViewEncapsulation, SimpleChanges, EventEmitter, ViewChild } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { ResourceEntry } from '../../model/resource-entry.model'
import { Attachment } from '../../model/attachment.model';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResourcesComponent implements OnInit {
  @ViewChild('input', { static: true }) searchInput;
  @Input() resourceEntries: Array<ResourceEntry>;
  @Input() visible: boolean;
  @Output() onRightClick = new EventEmitter<any>();
  @Output() onDoubleClick = new EventEmitter<any>();

  listFiltered: Array<ResourceEntry>;

  noResults: string = "";
  searchString: string = "";
  search: string = "";

  constructor(public contextService: ContextService, private messagesService: MessagesService) { }

  ngOnInit() {
    this.search = this.contextService.getLanguageTagByKey('Misc.Search');
    this.noResults = this.contextService.getLanguageTagByKey('Misc.NoEntries');

    this.searchString = '';
  }

  onSubmit() {

  }

  doubleClick(data) {
    console.log(data)
    this.onDoubleClick.emit(new Attachment(0, data.title, Date.now(), 99, data.url, 255555));
  }

  rightClick(data) {
    console.log(data)
    this.onRightClick.emit(new Attachment(0, data.title, Date.now(), 99, data.url, 255555));
  }


  filter(s) {
    this.searchString = s;

    if (s.length) {
      var found: Array<ResourceEntry> = [];
      var sl = s.toLowerCase()
      this.resourceEntries.forEach(entry => {
        if (entry.title.toLowerCase().indexOf(sl) >= 0 || entry.description.toLowerCase().indexOf(sl) >= 0 || entry.url.toLowerCase().indexOf(sl) >= 0) {
          found.push(entry)
        }
      });
      this.listFiltered = found;
    } else {
      this.listFiltered = this.resourceEntries;
    }
  }

  focusSearch() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.visible) {
      if (changes.visible.currentValue === true) {
        this.focusSearch();
      }
    }

    if (changes.resourceEntries) {
      this.listFiltered = changes.resourceEntries.currentValue
    }
  }
}
