<div class="left-nav">
  <form (ngSubmit)="filterMenu(input.value)">
    <fieldset class="srch-field">
      <input type="text" placeholder="{{search}}" name="text" #input (input)="filterMenu(input.value)" autocomplete=off>
      <button type="submit"></button>
    </fieldset>
  </form>
  <p-panelMenu [model]="serviceSettMenu"></p-panelMenu>
</div>
<div class="setting-content">
  <router-outlet></router-outlet>
</div>


<p-dialog [resizable]="false" [(visible)]="serviceVis" [closable]="false" [style]="{'width':'370px'}" [ngClass]="{visible: serviceVis}" class="dialog-window buttons-1" dismissableMask="true" showHeader="false">
  <div class="modal-content">
    <p>{{this.alertValue}}</p>
    <div>
      <button type="button" class="btn-blue-light"(click)="this.navigate()">{{callButtonShowingOK}}</button>
    </div>
  </div>
</p-dialog>
