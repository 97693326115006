<div class="row">
  <p>
    {{description}}
  </p>
</div>
<p-tabView [activeIndex]="this.settingsGeneral.selectQueueTab">
  <p-tabPanel header="{{this.agentColumnText}}">
    <div class="row">
      <div class="box">
        <!-- <p-dataTable [value]="this.settingsGeneral.agentColumnsInTable" selectionMode="single" dataKey="id" [(selection)]="this.settingsGeneral.selectedAgentColumn" (dblclick)="doubleClickAgentTable($event)">
          <p-headerColumnGroup>

          </p-headerColumnGroup>
          <p-column field="label"></p-column>
        </p-dataTable> -->

        <p-table [value]="this.settingsGeneral.agentColumnsInTable" #datatable selectionMode="single" [(selection)]="this.settingsGeneral.selectedAgentColumn" dataKey="id">
            <ng-template pTemplate="header" >
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
               <tr (dblclick)="doubleClickAgentTable(rowData)" [pSelectableRow]="rowData" [pSelectableRowDblClick]="rowData">
                <td>
                  {{rowData.label}}
                </td>
              </tr>
            </ng-template>
          </p-table>


        <div class="controls">
          <div class="right">
            <a  class="ico-downarrow downArrow" (click)="changeRowToDown()"></a>
            <a  class="ico-uparrow downArrow" (click)="changeRowToUP()"></a>
            <a href="javascript:void(0);" (click)="showDeleteAlert('agentStat')">{{delete}}</a>
            <a href="javascript:void(0);" (click)="addAgentColumn()">{{plus}}</a>

          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="{{this.queueColumnText}}">
      <div class="row">
          <div class="box">
            <!-- <p-dataTable [value]="this.contextService.showQueueColumnsList" selectionMode="single" dataKey="id" [(selection)]="this.settingsGeneral.selectedQueueColumn" (dblclick)="doubleClickCallCenterTable($event)">
              <p-headerColumnGroup>
    
              </p-headerColumnGroup>
              <p-column field="label"></p-column>
            </p-dataTable> -->

            <p-table [value]="this.settingsGeneral.queueColumnsInTable" #datatable selectionMode="single" [(selection)]="this.settingsGeneral.selectedQueueColumn" dataKey="id">
              <ng-template pTemplate="header" >
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                 <tr (dblclick)="doubleClickCallCenterTable(rowData)" [pSelectableRowDblClick]="rowData" (click)="rowClick(rowData)" [pSelectableRow]="rowData">
                  <td>
                    {{rowData.label}}
                  </td>
                </tr>
              </ng-template>
            </p-table>

            <div class="controls">
              <div class="right">
                <a  class="ico-downarrow downArrow" (click)="changeQueueRowToDown()"></a>
                <a class="ico-uparrow downArrow" (click)="changeQueueRowToUP()"></a>
                <a href="javascript:void(0);" (click)="showDeleteAlert('queueStat')">{{delete}}</a>
                <a href="javascript:void(0);" (click)="addCallCenterColumn()">{{plus}}</a>
    
              </div>
            </div>
          </div>
        </div>
  </p-tabPanel>

  <p-tabPanel header="{{this.agentActivityText}}" *ngIf="this.contextService.objSettings.STARTUP===3">
    <div class="row">
        <div class="box">
          <!-- <p-dataTable [value]="this.contextService.showQueueColumnsList" selectionMode="single" dataKey="id" [(selection)]="this.settingsGeneral.selectedQueueColumn" (dblclick)="doubleClickCallCenterTable($event)">
            <p-headerColumnGroup>
  
            </p-headerColumnGroup>
            <p-column field="label"></p-column>
          </p-dataTable> -->

          <p-table [value]="this.settingsGeneral.agentActivityColumnsInTable" #datatable selectionMode="single" [(selection)]="this.settingsGeneral.selectedAgentActivityColumn" dataKey="id">
            <ng-template pTemplate="header" >
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
               <tr (dblclick)="doubleClickAgentActivityTable(rowData)" [pSelectableRowDblClick]="rowData" (click)="rowClick(rowData)" [pSelectableRow]="rowData">
                <td>
                  {{rowData.label}}
                </td>
              </tr>
            </ng-template>
          </p-table>

          <div class="controls">
            <div class="right">
              <a  class="ico-downarrow downArrow" (click)="changeAgentActivityRowToDown()"></a>
              <a class="ico-uparrow downArrow" (click)="changeAgentActivityRowToUP()"></a>
              <a href="javascript:void(0);" (click)="showDeleteAlert('agentActivity')">{{delete}}</a>
              <a href="javascript:void(0);" (click)="addAgentActivityColumn()">{{plus}}</a>
  
            </div>
          </div>
        </div>
      </div>
</p-tabPanel>
</p-tabView>

