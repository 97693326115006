<div class="row">
  <p>
    {{flexibleSeatingGuestDescription}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" (click)="flexibleSeatingGuestService.changeHostID()" value="checkbox" label="{{deviceEnable}}" [(ngModel)]="flexibleSeatingGuestService.isActive"
  [disabled]="!flexibleSeatingGuestService.disableActive"   binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label"> {{flexibleSeatingGuestDeviceName}}</label>
  <input type="text" id="txt_deviceName" name="input-text" autocomplete=off [(ngModel)]="flexibleSeatingGuestService.deviceName" [readonly]="true">
</div>
<div class="row">
  <label for="" class="label">{{linePort}}</label>
  <input type="text" id="txt_devicePort" name="input-text" autocomplete=off [(ngModel)]="flexibleSeatingGuestService.deviceLinePort" [readonly]="true">
</div>
<div class="row">
  <p>
    <b>
      {{flexibleSeatingGuestHostAssociation}}</b>
    </p>
</div>
<div class="row">
  <label for="" class="label">{{hotelingGuestHostName}}</label>
  <p-dropdown [options]="this.contextService.flexibleHostListWithName" [(ngModel)]="flexibleSeatingGuestService.hostUserId" (onChange)="onChange($event)" [disabled]="!flexibleSeatingGuestService.isActive" name="dropdown" [style]="{'width':'100%'}" [required]=true></p-dropdown>
</div>
<div class="row" *ngIf="flexibleSeatingGuestService.hostUserId!=='none' && flexibleSeatingGuestService.isActive=== true">
  <p>
    {{association}}
  </p>
  <input id="txt_associationLimitHours" [(ngModel)]="flexibleSeatingGuestService.associationLimitHours"  [maxLength]="2" type="text" id="associationNumber" name="mini-input"  class="input-mini">
  <label style="padding-left: 10px" class="label">{{hours}}</label>
  <!-- <label *ngIf="this.flexibleSeatingGuestService.showUnlimitedAssociationText ==false" style="padding-left: 10px" class="label">{{hours}} ({{flexibleSeatingGuestService.hostAssociationLimitHours}} {{maximum}})</label>
  <label *ngIf="this.flexibleSeatingGuestService.showUnlimitedAssociationText ==true"  style="padding-left: 10px"  class="label">{{this.unlimitedHours}}</label> -->
</div>