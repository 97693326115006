<p-dialog [resizable]="false" [(visible)]="aboutModalVisible" [ngClass]="{visible: aboutModalVisible}"  [closable]="true" [style]="{'width':'700px'}" class="small-dialog about-dialog" dismissableMask="false" showHeader="false">
    <div class="modal-content"> 
        <span class="close-modal small" (click)="closeModal()">X</span> 
        <table>
          <tr>
            <td class="about-licence">
              <div class="unity-logo"> 
                <span></span>
                <strong>{{productName}}</strong>
              </div>
              <div class="version">{{version}}: {{this.contextService.version}}</div>
              <div class="licence">{{licenseType}}: {{licence}}</div>
            </td>
            <td class="about-system">
              <!-- <p>{{this.contextService.getLanguageTagByKey('About.CurrentlyRunningOn')}}: <span>{{runningOn}} ({{IP}})</span></p> -->
              <p *ngIf="this.contextService.isElectron === true">{{workingFolderPath}}: <span (click)="goToFolder()">{{workingFolder}}</span></p>



              <p>{{operatingSystem}}: <span>{{osName}}, {{osBit}}</span></p>
              <p *ngIf="this.contextService.isElectron===false">{{browserdetails}}: <span>{{browserName}}.{{browserVersion}}</span></p>
            </td>
          </tr>
          <tr>
            <td colspan="2" class="about-notice">
              <p><strong>{{importantNotice}}</strong> - {{noticeBody}}</p>
            </td>
          </tr>
        </table>
    </div>
</p-dialog>

