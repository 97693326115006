import { MonitoresUserCallDetails } from "./monitored-user-call-details";
import { loggedUserCallCenterList } from "../agent/loggedUserCallCenterList";
import { Subscription } from 'rxjs';
/**
 * model class for storing monitored users and their statuses
 * 
 * @export
 * @class MonitoredUser
 */
export class MonitoredUser {
    imagePath: String;
    public isOnline: boolean= false;
    callDetails:Array<MonitoresUserCallDetails>=[];
    subscriptionList = [];
    isDnd: boolean=false;
    cfa: string;
    imStatus: string;
    commpilot: string;
    displayStatus: string;
    monitiorUserSubscription:Subscription;

    ACDState:string="";
    callCenters:Array<loggedUserCallCenterList>=[];
    stateTimestamp:string="";
    unavailableCode:string="";
    voicemailAssigned:boolean=false;
    constructor(imagePath: string,  isDnd: boolean, cfa: string, imStatus: string, commpilot: string, displayStatus: string) {
        this.imagePath = imagePath;
        this.isDnd = isDnd;
        this.cfa = cfa;
        this.imStatus = imStatus;
        this.commpilot = commpilot;
        this.displayStatus = displayStatus;
  
    }
    setCfa(number:string){
        this.cfa="";
        for (let index = 0; index < number.length; index++) {
            if (index % 2 == 0) {
                this.cfa += number.substring(index, index+2) + "\u00AD";
            }
        }
    }


   
}


