import { Component, OnInit } from '@angular/core';
import { ContextService } from "../../../../../../../../providers/context.service";
import { SettingsGeneral } from '../../../../../../../../providers/settings/settings-general/settings-general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-center-authentication-details',
  templateUrl: './call-center-authentication-details.component.html',
  styleUrls: ['./call-center-authentication-details.component.scss']
})
export class CallCenterAuthenticationDetailsComponent implements OnInit {
  loggedIn: string = "";
  loggedoff: string = "";
  toolTip: string = "Remove password";
  applyAllQueues: string = "";
  okBtn: string = "";
  noDetailsEntered: string = "";
  loggedMessage: string = "";
  isErrormsg: boolean = false;
  isLogged: boolean = false;
  ConfirmPasswordDelete: string = "";
  applyPasswordToAllQueues: string = "";
  showInPersonalWallBoard: string = "";
  constructor(private router: Router, public contextService: ContextService, public settingsGeneral: SettingsGeneral) { }

  ngOnInit() {
    this.applyPasswordToAllQueues = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.ApplyPasswordToAllQueues');
    this.loggedIn = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.LoginSuccessful');
    this.loggedoff = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.PasswordNotEntered');
    this.loggedMessage = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.LoginFailed');
    this.applyAllQueues = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.ApplyPasswordToAllQueues');
    this.okBtn = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.noDetailsEntered = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.NoDetailsEntered');
    this.ConfirmPasswordDelete = this.contextService.getLanguageTagByKey('Settings.Connection.Authentication.Queue.ConfirmPasswordDelete');
    this.showInPersonalWallBoard = this.contextService.getLanguageTagByKey('CallCenter.ShowInPersonalWallboard');


    if (this.settingsGeneral.callCenterPassword === "" && this.settingsGeneral.defaultPassword ==="" && !this.contextService.agentDetails.callCenters[this.settingsGeneral.callCenterID].isLoggedIn) {
      this.isErrormsg = true;

    }
    else if ((this.settingsGeneral.callCenterPassword !== "" || this.settingsGeneral.callCenterPassword === "") && this.contextService.agentDetails.callCenters[this.settingsGeneral.callCenterID].isLoggedIn) {
      this.isLogged = true;
    }
    else if (this.settingsGeneral.callCenterPassword !== "" && !this.contextService.agentDetails.callCenters[this.settingsGeneral.callCenterID].isLoggedIn) {
      this.isLogged = false;
    }
  }
  ngOnDestroy() {
    this.settingsGeneral.currentCallCenterAuthPage = "0";
  }
  dontAllowSpace(event) {
    return (event.charCode == 32) ? false : true;
  }
  removePasswordAlert() {
    this.contextService.log("callCenter removePasswordAlert() clicked");
    this.settingsGeneral.removePasswordVis = true;

  }
  removePassword() {
    this.contextService.log("callCenter removePassword() clicked");
    this.contextService.agentDetails.callCenters[this.settingsGeneral.callCenterID].password = "";
    let callCenterExist = false;
    this.contextService.loggedInCallCenters.forEach(element => {
      if (element.callCenterID === this.settingsGeneral.callCenterID) {
        element.callCenterPassword = "";
        element.showInWallBoard = false;
        callCenterExist = true;
      }
    });
    if (!callCenterExist) {
      this.contextService.loggedInCallCenters.push({ callCenterID: this.settingsGeneral.callCenterID, callCenterPassword: "", showInWallBoard: false });
    }
    this.settingsGeneral.callCenterPassword = "";
    this.settingsGeneral.removePasswordVis = false;
    this.settingsGeneral.passwordDeleteButtonVisisbility = false;
    //to navigate to callCenter main page
    this.router.navigate(['/settings-general', 'call-centers-authentication']);
    this.settingsGeneral.currentCallCenterAuthPage = "0";
  }


}
