import { Injectable } from '@angular/core';
import { FlexibleSeatingGuest } from '../../../model/bw-services/flexible-seating-guest';
import { ContextService } from '../../../providers/context.service';
import { BwServices } from './bw-services.service';
@Injectable()
export class FlexibleSeatingGuestService {
    isActive: boolean = false;
    enableAssociationLimit: boolean = false;
    associationLimitHours: string = "";
    deviceName: string = "";
    deviceLevel: string = "";
    deviceLinePort: string = "";
    hostUserId: string = "none";
    flexibleSeatingGuestChanged: boolean = false;
    disableActive: boolean = false;
    numberValidation: boolean = false;
    flexibleSeatingGuest: FlexibleSeatingGuest;
    showUnlimitedAssociationText: boolean = false;
    hostAssociationLimitHours: string = "";
    constructor(private bwServices: BwServices, public contextService: ContextService) {
    }

    setData(flexibleSeatingGuest: FlexibleSeatingGuest) { }

    validate(): boolean {
        return true;
    }
    allnumericplusminus(inputtxt) { }
    save() { }
    populateData() { }
    cancel() { }
    changeHostID() { }
}