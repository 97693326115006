import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { CallRecordingService } from '../../../../../providers/settings/settings-services/call-recording.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-call-recording',
  templateUrl: './call-recording.component.html',
  styleUrls: ['./call-recording.component.css']
})
export class CallRecordingComponent extends BaseComponent implements OnInit {
  description: string = "";
  recordingOptions: string = "";
  pauseResumeNotification: string = "";
  callRecordingAnnouncement: string = "";
  voiceMailRecording: string = "";
  repeatCallRecordingTone: string = "";
  seconds: string = "";
  recordingStateForCalls: string = "";
  showRecordVoiceMessaging: boolean = false;
  constructor(public callRecordingService: CallRecordingService, private contextService: ContextService) {
    super();
  }

  ngOnInit() {
    if (this.contextService.assignedServices.includes('VoiceMessaging')) {
      this.showRecordVoiceMessaging = true;
    } else {
      this.showRecordVoiceMessaging = false;
    }
    this.callRecordingService.callRecordingChanges = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.Description');
    this.recordingOptions = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.RecordingOption');
    this.callRecordingAnnouncement = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.PlayStartStopAlert');
    this.voiceMailRecording = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.RecordVoicemail');
    this.pauseResumeNotification = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.NotificationType');
    this.recordingStateForCalls = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.ShowRecordingStateForCalls');
    this.repeatCallRecordingTone = this.contextService.getLanguageTagByKey('Settings.Services.CallRecording.RepeatAlert');
    this.seconds = this.contextService.getLanguageTagByKey('Misc.Seconds');
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
}
