import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HelperService {
  setVisible = new Subject<boolean>();
  manageUsersVisible = new Subject<boolean>();
  repVisible = new Subject<boolean>();
  calendarModalVis = new Subject<boolean>();
  dialVisible = new Subject<boolean>();
  closeModalVis = new Subject<boolean>();
  aboutModalVis = new Subject<boolean>();
  outOfOfficeModalVis = new Subject<boolean>();
  addPersonalEntryModalVis = new Subject<boolean>();
  openedMenuOnDrop = new Subject<any>();
  openedMenuOnDropStats = new Subject<any>();
  openedMenuOnDropCallLogs = new Subject<any>();
  openedMenuOnDropAgentActivity = new Subject<any>();
  recalcContactsHeight = new Subject<boolean>();
  recalcCallLogsHeight = new Subject<boolean>();
  recalcVoicemailHeight = new Subject<boolean>();
  // supervisor
  recalcagentactivityHeight = new Subject<boolean>();
  resized = new Subject<boolean>();
  selectedTableTab: string = 'contacts';
  tableDropped = new Subject<any>();
  tableTabChanged = new Subject<boolean>();
  dragStart = new Subject<boolean>();
  cmenus = [];
  cmX = 0;
  cmY = 0;
  tables: Array<any> = [];
  startConversationVisible = new Subject<boolean>();
  statusModalVis = new Subject<boolean>();

  constructor(private router: Router) { }

  hasClass(el: any, name: string) {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  addClass(el: any, name: string) {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  removeClass(el: any, name: string) {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), ' ');
    }
  }

  closeModal() {
    this.router.navigate(['/']);
  }

  setContextMenuCoordinate(x, y) {
    this.cmX = x;
    this.cmY = y;
  }

  getContextMenuCoordinate() {
    return { x: this.cmX, y: this.cmY };
  }

  saveAllCMenus(cmenu) {
    this.cmenus.push(cmenu);
  }

  hideAllCMenus() {
    for (let i = 0; i < this.cmenus.length; i++) {
      // this.cmenus[i].visible = false;
      this.cmenus[i].hide();

    }
  }

  /**
  * forEach polyfill for IE
  */
  forEach = function (array, callback, scope?) {
    for (let i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  };

  calcCellsWidth(table, th?) {
    let statsCells;
    let res;
    if (table && table.el) {
      if (th) {
        // calc width of header cells
        let statsThRow
        if (table.el.nativeElement.children[0].querySelector('.ui-datatable-thead tr')) {
          statsThRow = table.el.nativeElement.children[0].querySelector('.ui-datatable-thead tr');
        } else {
          statsThRow = table.el.nativeElement.children[0].querySelector('.ui-table-thead tr');
        }
        if (statsThRow) {
          statsCells = statsThRow.querySelectorAll('th');
          res = this.setCellsWidth(statsCells, table);
        }
        return res;
      } else {

        // calc width of tbody cells
        let statsRows
        if (table.el.nativeElement.children[0].querySelector('tr.ui-widget-content')) {
          statsRows = table.el.nativeElement.children[0].querySelector('tr.ui-widget-content');
        } else {
          statsRows = table.el.nativeElement.children[0].querySelector('tr.ui-selectable-row');
        }
        // const statsRows = table.el.nativeElement.children[0].querySelector('tr.ui-widget-content');


        if (statsRows) {
          statsCells = statsRows.querySelectorAll('td');
          res = this.setCellsWidth(statsCells, table);
        }
        return res;
      }
    }
  }

  async setCellsWidth(arr, table) {
    // get TH cells beacsue we have to set width for these programattically below in foreach loop
    let statsThRow;
    if (table.el.nativeElement.children[0].querySelector('.ui-datatable-thead tr')) {
      statsThRow = table.el.nativeElement.children[0].querySelector('.ui-datatable-thead tr');
    } else {
      statsThRow = table.el.nativeElement.children[0].querySelector('.ui-table-thead tr');
    }
    // const statsThRow = table.el.nativeElement.children[0].querySelector('.ui-datatable-thead tr');
    const statsThCells = statsThRow.querySelectorAll('th');
    const widths: any = await this.pushWidthToArr(arr);
    if (statsThCells && widths.length > 1) {
      this.forEach(statsThCells, (i, th) => {
        th.style.width = (widths[i]).toString() + 'px';
        th.style.maxWidth = (widths[i]).toString() + 'px';
      });
    }

    // set max-width for editable note cell so it doesn't overflow..
    if (table.el.nativeElement.id === 'drop-call-table') {
      const activeRows = table.el.nativeElement.querySelectorAll('.act');
      this.forEach(activeRows, (i, tr) => {
        const activeCells = tr.querySelectorAll('td');
        this.forEach(activeCells, (i, td) => {
          if (this.hasClass(td, 'note-cell')) {
            console.log('notee');
            td.style.maxWidth = (widths[i]).toString() + 'px';
          }
        });
      });
    }

    // for TD we set width in HTML using string interpolation
    return widths;
  }

  pushWidthToArr(cells) {
    return new Promise((resolve) => {
      const arrWidth = [];
      this.forEach(cells, (i, td) => {
        arrWidth.push(td.getBoundingClientRect().width);
      });
      resolve(arrWidth);
    });
  }

  disableRows(table) {
    if (this.tables.length) {
      const sameTable = this.tables.every((item) => {
        const tbody = table.el.nativeElement.querySelector('tbody');
        const ibody = item.el.nativeElement.querySelector('tbody');
        if (tbody && ibody) {
          return ibody.id === tbody.id;
        }
      })
      if (sameTable) {
        return;
      } else {
        const prevTable = this.tables.shift();
        const rows = prevTable.el.nativeElement.querySelectorAll('tbody tr');
        this.forEach(rows, (i, row) => {
          if (this.hasClass(row, 'ui-state-highlight')) {
            this.removeClass(row, 'ui-state-highlight');
          }
        });
        this.tables.push(table);
      }
    } else {
      this.tables.push(table);
    }
  }

  /**
  * Set a tick icon on contextMenu
  *
  * @param {MenuItem[]} menu - array of menu items
  * @param {number} id - id of the menu item that we click (order)
  * @param {number} parentID - (optional) id of the parent menu item (if nested items)
  * @param {string} group - (optional) should work like radiobutton (i.e 3 items 'grouped' together and only 1 of them is selected),
                           Use also styleClass that should match a passed group name
  */
  setTickIcon(menu, id, parentId?, group?) {
    if (parentId) {
      if (group) {
        // simulating radiobutton
        this.forEach(menu[parentId].items, (i, item) => {
          if (item.styleClass === group) {
            item.icon = '';
          }
        });
        menu[parentId].items[id].icon = 'tick';
      } else {
        // simulating checkbox
        if (menu[parentId].items[id].icon === 'tick') {
          menu[parentId].items[id].icon = '';
        } else {
          menu[parentId].items[id].icon = 'tick';
        }
      }
    } else {
      if (group) {
        this.forEach(menu, (i, item) => {
          if (item.styleClass === group) {
            item.icon = '';
          }
        });
        menu[id].icon = 'tick';
      } else {
        if (menu[id].icon === 'tick') {
          menu[id].icon = '';
        } else {
          menu[id].icon = 'tick';
        }
      }
    }
  }

  /**
  * Calculates height for tables (contact, call logs, voicemail) when top table is toggled or height of active call area is changed
  *
  * @param {ElementRef} ele - reference for a component
  * @param {Array} arr - data of table
  * @param {number} gap - height in px for elements (rows?) that is not calculated below but makes the result
  * @returns {object} - {table height, contextMenu div height, scrolling(boolean)}
  */
  calcTableHeight(ele, arr: Array<any>, gap: number, layoutType?) {
    let th; // table height
    let mh; // menu height
    let scrolling;
    let arrLen;
    if (layoutType === 'Small icons') {
      arrLen = (arr.length / 5) * 25;
    } else {
      arrLen = arr.length * 10;
    }
    const natEl = ele.nativeElement;
    const winHeight = window.innerHeight;
    const elOffSetTop = natEl.offsetTop;
    const elOffSetParentTop = natEl.offsetParent.offsetTop;
    const tableH = winHeight - (elOffSetTop + elOffSetParentTop);
    let elOffSetHeight = 0;
    if (natEl.querySelector('p-datatable')) {
      elOffSetHeight = natEl.querySelector('p-datatable').clientHeight + natEl.querySelector('.row').clientHeight;
    } else {
      elOffSetHeight = natEl.querySelector('p-table').clientHeight + natEl.querySelector('.row').clientHeight;
    }

    const menuH = tableH - elOffSetHeight;
    const scrollBarW = this.getScrollBarWidth();
    if (arrLen + gap > tableH) {
      th = (tableH - gap).toString() + 'px';
      mh = '0px';
    } else {
      // th = 'auto';
      th = (tableH - gap).toString() + 'px';
      mh = menuH.toString() + 'px';
    }
    if (scrollBarW !== '0px') { //not Safari
      scrolling = true;
    }
    return { th, mh, scrolling }
  }


  getScrollBarWidth() {
    const inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    const w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 === w2) { w2 = outer.clientWidth };

    document.body.removeChild(outer);
    return (w1 - w2).toString() + 'px';
  };

  searchNodes(query: string, nodes: any[], childrenProp) {
    let nodesToRemove = [];
    let children = [];
    let prop;
    this.forEach(nodes, (index, node) => {
      children = [];
      if (childrenProp === 'children') {
        prop = node.children;
      } else if (childrenProp === 'items') {
        prop = node.items;
      }
      if (prop && prop.length) {
        children = this.searchNodes(query, prop, childrenProp);
      }
      if (node.label.search(new RegExp(query, "i")) > -1) {
        // ok
      } else {
        if (!children.length) {
          nodesToRemove.push(index);
        }
      }
    });

    if (nodesToRemove.length) {
      for (let index of nodesToRemove.reverse()) {
        this.removeNode(nodes, index);
      }
    }
    return nodes;
  }

  removeNode(nodes, index) {
    if (index !== -1) {
      nodes.splice(index, 1);
    }
    return nodes;
  }

  clone(originalObject, circular) {
    // First create an empty object with
    // same prototype of our original source
    let propertyIndex, descriptor, keys, current, nextSource, indexOf,
      copies = [{
        source: originalObject,
        target: Array.isArray(originalObject) ? [] : Object.create(Object.getPrototypeOf(originalObject))
      }],
      cloneObject = copies[0].target,
      sourceReferences = [originalObject],
      targetReferences = [cloneObject];

    // First in, first out
    while (current = copies.shift())	// jshint ignore:line
    {
      keys = Object.getOwnPropertyNames(current.source);
      for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
        // Save the source's descriptor
        descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

        if (!descriptor.value || typeof descriptor.value !== 'object') {
          Object.defineProperty(current.target, keys[propertyIndex], descriptor);
          continue;
        }

        nextSource = descriptor.value;
        descriptor.value = Array.isArray(nextSource) ? [] : Object.create(Object.getPrototypeOf(nextSource));

        if (circular) {
          indexOf = sourceReferences.indexOf(nextSource);

          if (indexOf !== -1) {
            // The source is already referenced, just assign reference
            descriptor.value = targetReferences[indexOf];
            Object.defineProperty(current.target, keys[propertyIndex], descriptor);
            continue;
          }

          sourceReferences.push(nextSource);
          targetReferences.push(descriptor.value);
        }

        Object.defineProperty(current.target, keys[propertyIndex], descriptor);

        copies.push({ source: nextSource, target: descriptor.value });
      }
    }
    return cloneObject;
  }

  searchNodesTree(query: string, nodes: any[], childrenProp) {
    let nodesToRemove = [];
    let children = [];
    let prop;
    this.forEach(nodes, (index, node) => {
      children = [];
      if (childrenProp === 'children') {
        prop = node.children;
      } else if (childrenProp === 'items') {
        prop = node.items;
      }
      if (prop && prop.length) {
        children = this.searchNodes(query, prop, childrenProp);
      }
      if (node.label.search(new RegExp(query, "i")) > -1) {
        // ok
      } else {
        if (!children.length) {
          nodesToRemove.push(index);
        }
      }
    });

    if (nodesToRemove.length) {
      for (let index of nodesToRemove.reverse()) {
        this.removeNode(nodes, index);
      }
    }
    /*
    if(nodes.length>0){
        console.log(nodes);
        console.log("------------");
        //console.log(nodes[0].type);
        let index=0;
        index=nodes[0].children.length -1;
        for (index; index >= 0; index--) {
            console.log(nodes[0].children[index].type);
            if(nodes[0].children[index].type ==='department'){
               
                if(nodes[0].children[index].children.length < 0){
                    console.log("yes");
                    nodes[0].children.splice(index,1);
                }
             
            }
            
        }
    }
    */

    return nodes;
  }


}