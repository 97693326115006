import { Component, OnInit, Input, OnChanges, ViewEncapsulation, SimpleChanges, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ContextService } from '../../providers/context.service';
import { MessagesService } from '../../providers/messages.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { HistoryEntry } from '../../model/history-entry.model'

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HistoryComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
  //@ViewChild('.history-entries') scrollPane: HTMLElement;
  @ViewChild('historyEentries', { static: false }) scrollPane: ElementRef;


  @Input() historyEntries: Array<HistoryEntry>;
  @Input() visible: boolean;
  historyEntriesFiltered: Array<HistoryEntry>;
  title: string = "";
  noHistory: string = "";
  searchString: string = "";
  search: string = "";
  scrollHeight: string = 'auto';
  inited: boolean = false;

  constructor(public contextService: ContextService, private messagesService: MessagesService) { }

  ngOnInit() {

    this.title = this.contextService.getLanguageTagByKey('History.Title');
    this.search = this.contextService.getLanguageTagByKey('Misc.Search');
    this.noHistory = this.contextService.getLanguageTagByKey('Misc.NoEntries');



    this.searchString = '';
  }

  ngAfterViewInit() {
    //console.log(this.scrollPane);
    //let el = document.getElementById('history-scroll');
    //this.scrollHeight = el.offsetHeight + 'px';
  }

  onClick(entry) {
    alert('clicked - ' + entry.title)
  }

  filterHistory(s) {
    this.searchString = s;

    if (s.length) {
      var found: Array<HistoryEntry> = [];
      var sl = s.toLowerCase()
      this.historyEntries.forEach(entry => {
        if (entry.title.toLowerCase().indexOf(sl) >= 0) {
          found.push(entry)
        }
      });
      this.historyEntriesFiltered = found;
    } else {
      this.historyEntriesFiltered = this.historyEntries;
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.historyEntries) {
      this.historyEntriesFiltered = changes.historyEntries.currentValue
    }
    if (changes.visible) {
      this.visible = changes.visible.currentValue
      if (!this.inited === true && this.scrollPane) {

        setTimeout(() => {
          this.scrollHeight = this.scrollPane.nativeElement.offsetHeight + 'px';
          this.inited = true;
        }, 200);
      }

    }


    /*
    console.log(changes);
    console.log(this.scrollPane);
    if (!this.inited === true) {
      console.log(this.scrollPane);
      let el = document.getElementById('history-scroll');
      this.scrollHeight = el.offsetHeight + 'px';
    }
    console.log('changeeeeeeeeeeeeeeeeeeeeeeees')
    //this.contextService.log('!!!!!!!!!'); this.contextService.log(changes);
  */
  }
}
