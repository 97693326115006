import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContextService } from 'app/providers/context.service';
import { HelperService } from 'app/providers/helper.service';
import { SettingService } from "app/providers/settings/settings-general/setting.service";
import { MenuItem } from 'primeng/api/menuitem';
@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss']
})
export class SettingsGeneralComponent implements OnInit {
  generalSetMenu: MenuItem[];
  searchString: string = "";
  originalgeneralSetMenu: MenuItem[];
  serviceVis: boolean = false;
  alertValue: string = "";
  search: string = "";
  callButtonShowingOK: string = "";

  constructor(private router: Router, private contextService: ContextService, private settings: SettingService, private helper: HelperService) { }

  ngOnInit() {
    this.search = this.contextService.getLanguageTagByKey('CallButtonShowing.Search');
    this.callButtonShowingOK = this.contextService.getLanguageTagByKey('CallButtonShowing.OK');
    this.originalgeneralSetMenu = this.settings.getGeneralSettMenu();
    this.generalSetMenu = [...this.originalgeneralSetMenu];
    this.searchString = '';

    this.contextService.serviceModalVis.subscribe(val => {
      this.serviceVis = true;
      this.alertValue = val;
    });

  }
  navigate() {
    this.serviceVis = false;
    //Route to corresponding pages when an validation error occured in service and settings pages
    this.contextService.log("Routing page: " + this.contextService.route);
    switch (this.contextService.route) {
      case 'cfa':
        this.router.navigate(['/settings-service', 'call-forwarding-always']);
        break;
      case 'cfb':
        this.router.navigate(['/settings-service', 'call-forwarding-busy']);
        break;
      case 'noAnswer':
        this.router.navigate(['/settings-service', 'call-forward-no-answer']);
        break;
      case 'acdState':
        this.router.navigate(['/settings-service', 'acd-state']);
        break;
      case 'inOffice':
        this.router.navigate(['/settings-service', 'available-in-office']);
        break;
      case 'outOffice':
        this.router.navigate(['/settings-service', 'available-out-of-office']);
        break;
      case 'busy':
        this.router.navigate(['/settings-service', 'busy']);
        break;
      case 'unavailable':
        this.router.navigate(['/settings-service', 'unavailable']);
        break;
      case 'simultaneous':
        this.router.navigate(['/settings-service', 'simultaneous-ring']);
        break;
      case 'callTransfer':
        this.router.navigate(['/settings-service', 'call-transfer']);
        break;
      case 'FlexibleSeatingGuest':
        this.router.navigate(['/settings-service', 'flexible-seating-guest']);
        break;
      case 'hotelingGuest':
        this.router.navigate(['/settings-service', 'hoteling-guest']);
        break;
      case 'remoteOffice':
        this.router.navigate(['/settings-service', 'remote-office']);
        break;
      case 'voicemail':
        this.router.navigate(['/settings-service', 'voice-mail-service']);
        break;
      case 'dialing':
        this.router.navigate(['/settings-general', 'dialing-rule']);
        break;
      case 'network':
        this.router.navigate(['/settings-general', 'network']);
        break;
      case 'noAuthentication':
        this.router.navigate(['/settings-general', 'authentication']);
        break;
      case 'noDomain':
        this.router.navigate(['/settings-general', 'authentication']);
        break;
      case 'statistics':
        this.router.navigate(['/settings-service', 'statistics']);
        break;
      case 'browserIntegration':
        this.router.navigate(['/settings-general', 'browser-integration']);
        break;
      case 'supervisor':
        this.router.navigate(['/settings-general', 'suprevisor']);
        break;
      default: {
        this.contextService.log("Routing page not in switch case: " + this.contextService.route)
      }
    }

  }
  filterMenu(s) {
    let f = false;
    this.searchString = s;
    if (s.length) {

      this.settings.settingsTreeArray.forEach(element => {
        if (element.includes(this.searchString.toLowerCase())) {
          f = true;
        }
      });
      if (f) {
        this.generalSetMenu = this.helper.searchNodes(this.searchString.toLowerCase(), this.helper.clone(this.originalgeneralSetMenu, true), 'items');
      } else {
        let tempname = this.contextService.getLanguageTagByKey('Settings.Cti');
        if (tempname.toLowerCase().includes(this.searchString.toLowerCase())) {
          this.generalSetMenu = this.helper.searchNodes(this.searchString.toLowerCase(), this.helper.clone(this.settings.computerPhoneIntegration, true), 'children');
        }
        else {
          this.generalSetMenu = this.helper.searchNodes(this.searchString.toLowerCase(), this.helper.clone(this.originalgeneralSetMenu, true), 'children');
        }
      }
    } else {
      this.generalSetMenu = this.originalgeneralSetMenu;
    }
  }
}
