import { DispostionCodes } from './dispostionCodes';
import { dnisDetails} from './dnisDetails';
import { Supervisor } from './supervisor';
import { Agent } from '../supervisor/agent';

export class loggedUserCallCenterList {
    public callCenterName: string;
    public acdUserId: string;
    public joined: boolean;
    public extension: string;
    isLogOffAllowed: boolean;
    public dispositionCodeList: Array<DispostionCodes> = [];
    public dnisInfoList: Array<dnisDetails> = [];
    public supervisorList: Array<Supervisor> = [];
    public agentList: Array<Agent> = [];
    public isLoggedIn: boolean=false;
    public isSupervisorCallcenter=false;
    public isAgentCallcenter=false;
    public type:string;
    public activeForcedForwarding:boolean=false;
    public forcedForwardingNumber:string="";
    constructor(callCenterName: string, acdUserId: string, joined: boolean, extension: string, isLogOffAllowed: boolean) {
        this.callCenterName=callCenterName;
        this.acdUserId = acdUserId;
        this.joined = joined;
        this.extension = extension;
        this.isLogOffAllowed = isLogOffAllowed;
    }
}