<nav class="main-menu" id="mainMenuID">
  <ul>
    <li><a href="#" #file  (mouseup)="openCm($event, cmfile)"(click)="openCm($event, cmfile)" >{{fileMenuBar}}</a></li>
    <li [style.display]="hideIM?'none':'inherit'"><a href="#" #messaging  (mouseup)="status()"  (click)="openCm($event, cmmessage)">{{messagingFileMenu}}</a></li>
    <li><a href="#tools" #tools (mouseup)="toolsClick()"  (click)="openCm($event, cmtools)">{{menuTools}}</a></li>
    <li><a href="#" #help (mouseup)="doNotShowRemoteAssistance()"  (click)="openCm($event, cmhelp)">{{menuHelp}}</a></li>
  </ul>
</nav>
<p-contextMenu [model]="messagingMenu" appendTo="body" [target]="messaging" leftClick="true" #cmmessage></p-contextMenu>
<p-contextMenu [model]="toolsMenu" appendTo="body" [target]="tools" leftClick="true" #cmtools></p-contextMenu>
<p-contextMenu [model]="fileMenu" appendTo="body" [target]="file" leftClick="true" #cmfile></p-contextMenu>
<p-contextMenu [model]="helpMenu" appendTo="body" [target]="help" leftClick="true" #cmhelp></p-contextMenu>

<p-dialog [resizable]="false" [(visible)]="closeVis" [closable]="false" [style]="{'width':'370px'}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false" modal="true">
  <div class="modal-content">
    <p>{{restartPrompt}}</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="restart()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="closeVis = false">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>

<p-dialog *ngIf="this.contextService.agentDetails" [resizable]="false" [(visible)]="dnisVis" [closable]="false" [style]="{'width':'370px'}" class="dialog-window buttons-2" dismissableMask="true" showHeader="false" modal="true">
  <div class="modal-content">
    <p>You are currently using {{this.contextService.agentDetails.dnisName}} as your outbound phone number. Would you like to remove this assignment?</p>
    <div>
      <button type="button" class="btn-blue-light" (click)="clearDNIS()">{{miscYes}}</button>
      <button type="button" class="btn-white" (click)="dnisVis = false;this.clearAll();">{{miscNo}}</button>
    </div>
  </div>
</p-dialog>