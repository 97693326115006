import { DirectoryEntry } from './directory-entry';
/**
 * This class is the common model class for BW personal and common phone list.
 * 
 * @export
 * @class BwCommonOrPersonalDirectory
 */
export class PersonalDirectory extends DirectoryEntry {

    constructor(name: string, number: string) {
        super(name, number);
    }
}