import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { ChatComponent } from "app/components/chat/chat.component";
import { ChatConversation } from "app/model/chat-conversation.model";
import { ChatMessage } from "app/model/chat-message.model";
import { ChatService } from "app/providers/chat.service";
import { ContextService } from "app/providers/context.service";
import { DataService } from "app/providers/dataservice.service";
import { HelperService } from 'app/providers/helper.service';
import { ImService } from "app/providers/im.service";
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { BwDirectoryEntry } from '../../../model/directory/bw-directory-entry';
import { DirectoryView } from "../../../model/directory/directory-view";






@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('close', [
      state('ok', style({

      })),
      state('removed', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('ok => removed', animate('100ms ease-in'))
    ])
  ]
})

export class ConversationComponent implements OnInit, OnChanges {

  @Input() conversationObject: object;
  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;
  @ViewChild('afterdropmenu') afterdropmenu;
  afterdropmenuLeft: string;
  afterdropmenuTop: string;
  conversation: ChatConversation;
  messages: Array<any> = [];
  contextMenuItems: Array<any> = [];
  state: string = 'ok';
  message = new ChatMessage('');
  submitted = false;
  typing?: string = null;
  private sTimeout: any;
  private sTimeout2: any;
  private sTimeoutScroll: any;
  classes: Array<any> = ['lines-1', 'lines-2', 'lines-3', 'lines-4'];
  maxLines: number = 4;
  inputHeight: number = 20;
  tempHeadline: string[] = [];
  documentClickListener: any;
  tempId: string = "";

  closeChat: string = "";
  addToChat: string = "";
  offlineNotAdded: string = "";
  sendlbl: string = "";
  writeMessageHere: string = "";

disableTextfield:boolean=false;
  constructor(public imService: ImService, public contextService: ContextService, public element: ElementRef, private chatService: ChatService, private parent: ChatComponent, private helper: HelperService, private dataService: DataService, public renderer: Renderer2) {
    if (this.contextService.isElectron) {
      this.documentClickListener = this.renderer.listen('document', 'click', (event) => {
        let link = "";
        if (event.target.className == "chat_with_http") {
          link = event.target.innerHTML;
        }
        if (event.target.className == "chat_without_http") {
          link = 'https://' + event.target.innerHTML;
        }
        if (event.target.className == "chat_mailid") {
          link = 'mailto:' + event.target.innerHTML;
        }
        if (link !== "") {
          this.contextService.electronService.ipcRenderer.send('load-url', link);
        }
      });
    }
  }

  ngOnInit() {
   this.closeChat = this.contextService.getLanguageTagByKey('CallButtonShowing.CloseChat');
   this.addToChat = this.contextService.getLanguageTagByKey('Conversation.AddToChat');
   this.offlineNotAdded = this.contextService.getLanguageTagByKey('Conversation.OfflineNotAdded');
   this.sendlbl = this.contextService.getLanguageTagByKey('Misc.Send');
   this.writeMessageHere = this.contextService.getLanguageTagByKey('Conversation.WriteMessageHere');



    this.contextService.imConnectionLostModalVis.subscribe(val => {
      // if (val !== "enableMessagingMenu") {
      //   if (this.contextService.isChatOpen) {
      //   this.disableTextfield=true;
      //   } 
      // } else if (val === "enableMessagingMenu") {
      //   this.disableTextfield=false;          
      //   }
      if (val === "") {
          if (this.contextService.isChatOpen && this.contextService.active!="") {
          this.unFocus(this.contextService.active);
          } 
        }
    });
    this.conversation = new ChatConversation(this.conversationObject);
    this.chatService.droppedToConversation.subscribe(person => {
      if (this.element.nativeElement === person.element) {
        if (person.data.rowIdModel.type === 'Broadworks Directory') {


          if (person.data.rowIdModel instanceof DirectoryView) {
            this.contextService.log(person.data.rowIdModel.userId);
            this.contextService.log(person.data.rowIdModel.name);
            this.buildContextMenu();
            if (person.data.rowIdModel.isOnline && this.conversation.offline === false) {
              //This is to drag and drop to message from call status table
              this.contextService.conversations.forEach(element => {
                let temp = false;

                element.people.forEach(elementPeople => {
                  if (elementPeople.id === person.data.rowIdModel.userId) {
                    temp = true
                  }
                });

                if (!temp) {

                  if (element.id === this.conversation.id) {
                    this.conversation.addPerson(person.data.rowIdModel.userId, person.data.rowIdModel.name);
                    let headline = [];
                    element.people.forEach(elementPeople => {
                      headline.push(elementPeople.name)
                    });

                    element.headline = headline.toString();
                    this.contextService.log("person.data.rowIdModel userId" + person.data.rowIdModel.userId + ", name " + person.data.rowIdModel.name);
                    if (this.contextService.active != this.conversation.id.toString()) {
                      this.activeId(this.conversation.id.toString());

                      this.contextService.messageArray.forEach(element => {
                        this.contextService.messages = [];
                        this.contextService.messageArray.forEach(element => {
                          if (element.id == this.conversation.id.toString() && element.text.length > 0) {
                            this.contextService.messages.push(element);
                          }
                        });

                      });

                    }

                  }
                }
              });

            }
            else {
              let id = person.data.rowIdModel.userId + "_" + Math.floor(Date.now() / 1000);
              let isMatch = false;
              this.contextService.conversations.forEach(conversationsElement => {

                if (conversationsElement.people.length === 1) {
                  conversationsElement.people.forEach(element => {
                    let result = id.substring(0, id.lastIndexOf("_"));
                    if (element.id === result) {
                      isMatch = true;
                      this.tempId = conversationsElement.id.toString();
                    }
                  });
                }
              });

              if (!isMatch) {
                let offline = false;
                this.contextService.active = id;
                let people = [];
                let obj = { "id": person.data.rowIdModel.userId, "name": person.data.rowIdModel.name };
                people.push(obj)
                if (person.data.rowIdModel.isOnline) {
                  offline = false;
                }
                else {
                  offline = true;
                }
                let c = new ChatConversation({
                  "id": id, "people": people, "headline": this.contextService.getNameFromUserID(person.data.rowIdModel.userId), "unread": 0, "offline": offline, "message": ""
                });
                this.contextService.conversations.push(c);
                this.contextService.messageArray.push({
                  dateTime: "",
                  headline: "",
                  id: id,
                  itsMe: false,
                  person: [99],
                  text: ""
                });
                this.contextService.messageArray.forEach(element => {
                  if (this.contextService.active == id) {
                    this.contextService.messages = [];
                    this.contextService.messageArray.forEach(element => {


                      if (element.id == id.toString() && element.text.length > 0) {
                        this.contextService.messages.push(element);

                      }
                    });
                  }
                });
                this.activeId(id);
              }
              else if (this.contextService.conversations.length !== 1) {

                this.contextService.messages = [];
                this.contextService.messageArray.forEach(element => {
                  if (element.id == this.tempId && element.text.length > 0) {
                    this.contextService.messages.push(element);
                  }
                });
                this.activeId(this.tempId);

              }

            }
          }
        }
      }
    });

    this.chatService.droppedActiveCallToConversation.subscribe(person => {
      if (this.element.nativeElement === person.element) {
        this.buildContextMenu();
        // dropdown context menu
        let viewportOffset = this.element.nativeElement.getBoundingClientRect();
        this.afterdropmenuLeft = (viewportOffset.left + 10) + "px";
        this.afterdropmenuTop = (viewportOffset.top + 10) + "px";
        this.afterdropmenu.show();
      }

    });

    this.chatService.scrollToBottom.subscribe(val => {
      setTimeout(() => {
        this.updateScroll(val);
      }, 100);

    });
    this.chatService.unFocus.subscribe(val => {
        this.unFocus(val);
    });
    this.contextService.chatFocus.subscribe(val => {
      this.focusChat(val);
    });


    this.contextService.conversationHeaderChange.subscribe(updateObject => {
      this.contextService.log("conversationHeaderChange");
      if (updateObject.conversationId == this.conversation.id) {
        this.conversation.update(updateObject.update);
      }
    });
    this.buildContextMenu();// This is to drag and drop to message from call status table
  }
  transferOnDrop(transferTo: string) {
    if (transferTo === "extension") {
      this.contextService.websocketService.blindTransferAction(this.contextService.droppedcall.callId, this.contextMenuItems[0].id);
    } else if (transferTo === "mobile") {
      this.contextService.websocketService.blindTransferAction(this.contextService.droppedcall.callId, this.contextMenuItems[1].id);
    }
  }

  transferOnDropGroupChat(transferTo: string, num) {

  }
  buildContextMenu() {

    let tempID = "";
    let extnNum = "";
    let mob = "";
    this.contextMenuItems = [];
    let items = [];

    if (this.conversation.people.length == 1) {
      this.conversation.people.forEach(person => {
        tempID = person.id;
      });
      for (let i = 0; i < this.contextService.directoryList.length; i++) {
        if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
          let dirElement = this.contextService.directoryList[i] as BwDirectoryEntry;
          if (tempID === dirElement.getUserId()) {
            if (dirElement.getExtension() !== "") {
              items.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.transferOnDropGroupChat('extension', dirElement.getExtension()) });
            } else if (dirElement.getNumber() != "") {
              items.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.transferOnDropGroupChat('extension', dirElement.getNumber()) });
            }
            if (dirElement.getMobile() !== "") {
              items.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToMobile'), command: (event) => this.transferOnDropGroupChat('mobile', dirElement.getMobile()) });
            }
            if (dirElement.getExtension() !== "") {
              items.push({ separator: true, visible: true });
              items.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.transferOnDropGroupChat('voicemail', dirElement.getExtension()) });
            } else if (dirElement.getNumber() != "") {
              items.push({ separator: true, visible: true });
              items.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.transferOnDropGroupChat('voicemail', dirElement.getNumber()) });
            }
          }
        }
      }

      this.contextMenuItems = items;


    } else {
      this.conversation.people.forEach(person => {
        let groupItems = [];
        for (let i = 0; i < this.contextService.directoryList.length; i++) {
          if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
            let dirElement = this.contextService.directoryList[i] as BwDirectoryEntry;
            if (person.id === dirElement.getUserId()) {
              if (dirElement.getExtension() !== "") {
                groupItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.transferOnDropGroupChat('extension', dirElement.getExtension()) });
              }
              else if (dirElement.getNumber() != "") {
                groupItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToExtension'), command: (event) => this.transferOnDropGroupChat('extension', dirElement.getNumber()) });
              }
              if (dirElement.getMobile() !== "") {
                groupItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToMobile'), command: (event) => this.transferOnDropGroupChat('mobile', dirElement.getMobile()) })
              }
              if (dirElement.getExtension() !== "") {
                groupItems.push({ separator: true, visible: true });
                groupItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.transferOnDropGroupChat('voicemail', dirElement.getExtension()) });
              }
              else if (dirElement.getNumber() != "") {
                groupItems.push({ separator: true, visible: true });
                groupItems.push({ label: this.contextService.getLanguageTagByKey('Menu.Context.TransferToVoicemail'), command: (event) => this.transferOnDropGroupChat('voicemail', dirElement.getNumber()) });
              }
            }
          }
        }

        this.contextMenuItems.push({ label: person.name, items: groupItems });
      });
    }

  }

  activeId(id: string) {
    this.contextService.activateId.next(id);
  }



  send(event, id) {
    // this.submitOnEnter(event, id);
    if (this.imService.message == "\n" && this.imService.message.length < 1 && this.imService.message.replace(/\s/g, '').length < 1) {
      this.imService.message = '';
    }
    this.removeHeightClasses();
    document.forms["imMessageForm" + id]["message"].style.height = 'auto';

  }

  submitOnEnter(event, id) {
    if (this.message.text !== "\n" && this.message.text.length > 0 && this.message.text.replace(/\s/g, '').length > 0) {

      if (event.keyCode == 13) {
        this.onSubmit(id);
        event.preventDefault();

        let el = event.target;
        el.style.height = this.inputHeight + "px";
        this.removeHeightClasses();

      }
    }
    else {
      this.message.text = '';
    }
  }
unFocus(id){
  let idExist = false;
  this.contextService.conversations.forEach(element => {
    if (id == element.id) {
      idExist = true;
    }
  });
  if (idExist) {
    document.getElementById(id).blur();
  }
}
  focusChat(id) {
    let idExist = false;
    this.contextService.conversations.forEach(element => {
      if (id == element.id) {
        idExist = true;
      }
    });
    if (idExist) {
      if(document.getElementById(id)){
        document.getElementById(id).focus();
        this.imService.messageID = id.toString();
      }
    }
  }

  focus(event, id: number) {
    this.imService.extensionArray = [];
    event.target.focus();
    this.imService.messageID = id.toString();

    this.contextService.conversations.forEach(element => {
      element.people.forEach(people => {
        if (element.id == id) {
          this.imService.extensionArray.push(people.id);
        }
      });
    });
    this.updateScrollTextArea(id);
  }
  updateScrollTextArea(id) {
    var element = document.getElementById("chat" + id);
    if (element.scrollHeight != null) {
      element.scrollTop = element.scrollHeight;
    }
  }
  updateScroll(id) {
    var element = document.getElementById("scrollPS" + id);
    if (element) {
      if (element.scrollHeight != null) {
        element.scrollTop = element.scrollHeight;
      }
    }
  }
  adjustSize(event, id) {
    // if (event.keyCode === 8 || event.keyCode === 46) {
    document.forms["imMessageForm" + id]["message"].style.height = 'auto';
    // }
    if (event.keyCode != 13) {
      let el = event.target;
      el.style.overflow = 'hidden';
      // el.style.height = 'auto';
      el.style.height = el.scrollHeight + "px";
      // adjust sizes
      let lineHeight = this.inputHeight;
      let scrollHeight = el.scrollHeight;
      let numLines = Math.floor(scrollHeight / lineHeight) - 1;
      this.removeHeightClasses();

      // for line breaks add class to enlarge form
      if (numLines > 0) {
        numLines++;
        if (numLines > this.maxLines) {
          numLines = this.maxLines;
        }
        this.helper.addClass(this.element.nativeElement, 'lines-' + numLines);
      }
    }
    else if (event.keyCode == 13) {
      if (this.message.text !== "\n" && this.message.text.length > 0 && this.message.text.replace(/\s/g, '').length > 0) {
        this.onSubmit(id);
        event.preventDefault();
        let el = event.target;
        el.style.height = this.inputHeight + "px";
        this.removeHeightClasses();
      }
      else {
        this.message.text = '';
      }
    }
    if (event.keyCode != 38 && event.keyCode != 40) {
      this.updateScrollTextArea(id);
    }
  }

  removeHeightClasses() {
    for (let c of this.classes) {
      this.helper.removeClass(this.element.nativeElement, c);
    }
  }

  onSubmit(id) {
    if (this.message.text !== "\n" && this.message.text.length > 0) {
      this.imService.message = this.message.text;
      this.imService.sendMessage(id);
      this.addMessage(id, true, "", this.message.text);
      this.submitted = true;
    }

    this.message.text = "";
    this.imService.message = "";
  }

  private addMessage(id, itsMe: boolean, headline: string, text: string) {
    this.contextService.messageArray.push(
      {
        dateTime: Date.now(),
        headline: headline,
        id: id,
        itsMe: true,
        person: [99],
        text: this.contextService.replaceURLWithHTMLLinks(text)
      }
    );
    this.contextService.messages = [];
    this.contextService.messageArray.forEach(element => {
      if (element.id == id.toString() && element.text.length > 0) {
        this.contextService.messages.push(element);
      }
    });
    this.typing = null;
    setTimeout(() => {
      this.updateScroll(id);
    }, 100);
  }



  call(event, conversation: ChatConversation, person) {
    if (conversation.people.length == 1) {
      conversation.people.forEach(element => {
        for (let i = 0; i < this.contextService.directoryList.length; i++) {
          if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
            let dirElement = this.contextService.directoryList[i] as BwDirectoryEntry;
            this.contextService.log(element.id + "     " + dirElement.getUserId() + "  " + (element.id === dirElement.getUserId()));

            if (element.id === dirElement.getUserId()) {
              if (dirElement.getExtension() !== "") {
                this.contextService.log('dialAction(dirElement.getExtension()');
                this.contextService.websocketService.dialAction(dirElement.getExtension());
                return;
              }
              else {
                this.contextService.log('dialAction(dirElement.getNumber()');
                this.contextService.websocketService.dialAction(dirElement.getNumber());
                return;
              }

            }
          }
        }
      });
    }

    else {
      conversation.people.forEach(element => {
        if (element.id === person.id) {
          for (let i = 0; i < this.contextService.directoryList.length; i++) {
            if (this.contextService.directoryList[i] instanceof BwDirectoryEntry) {
              let dirElement = this.contextService.directoryList[i] as BwDirectoryEntry;
              this.contextService.log(element.id + "     " + dirElement.getUserId() + "  " + (element.id === dirElement.getUserId()));
              if (element.id === dirElement.getUserId()) {
                if (dirElement.getExtension() !== "") {
                  this.contextService.log('dialAction(dirElement.getExtension()');
                  this.contextService.websocketService.dialAction(dirElement.getExtension());
                  return;
                }
                else {
                  this.contextService.log('dialAction(dirElement.getNumber()');
                  this.contextService.websocketService.dialAction(dirElement.getNumber());
                  return;
                }

              }
            }
          }
        }
      });
    }


    event.stopPropagation();
    if (typeof (person) == 'undefined') {
      person = conversation.people[0];
    }
  }

  callListTrigger(event) {
    event.stopPropagation();
  }

  showMore(event) {
    let element = event.target || event.srcElement || event.currentTarget;
    let bounds = element.getBoundingClientRect();
    if (window.innerHeight - bounds.bottom < 150) {
      this.helper.addClass(element, 'up');
    }
    this.helper.addClass(element, 'hover');
  }

  hideMore(event) {
    let element = event.target || event.srcElement || event.currentTarget;
    this.helper.removeClass(element, 'hover');
    this.helper.removeClass(element, 'up');
  }


  close(event) {
    if (this.conversation.people.length == 1) {
      this.contextService.deletedConversations.push(this.conversation);
    }
    this.state = (this.state === 'ok' ? 'removed' : 'ok');
    if (this.conversation.unread > 0) {
      if (this.contextService.messageCounter > 0) {
        this.contextService.messageCounter--;
      }
    }
    if (this.contextService.active == this.conversation.id.toString()) {
      this.activeId(this.conversation.id.toString());
      this.parent.activeConversation = 0;
    }
    this.parent.removeConversation(this.conversation);
    if (this.contextService.conversations.length == 0 || this.contextService.active == this.conversation.id.toString()) {
      this.contextService.active = "";
    }
    if (this.contextService.conversations.length == 1) {
      this.contextService.conversations.forEach(element => {
        if (element.id.toString() != this.contextService.active) {
          this.contextService.messages = [];
          this.contextService.messageArray.forEach(elementMessage => {
            if (elementMessage.id == element.id.toString() && elementMessage.text.length > 0) {
              this.contextService.messages.push(elementMessage);
            }
          });
          this.activeId(element.id.toString());

          if (element.unread > 0) {
            this.contextService.messageCounter--;
            element.unread = 0;
            const update = { 'conversationId': element.id, update: { 'unread': element.unread, "message": "" } };
            this.contextService.conversationHeaderChange.next(update);
          }
        }
      });
    }
    // var element = document.getElementById("scrollChat");
    // if (element.scrollHeight != null) {
    //   element.scrollTop =element.scrollHeight;
    // }
    setTimeout(() => {
      this.chatService.srcoll.next(true);
    }, 100);
  }

  activate(id: number, event) {
    this.contextService.conversations.forEach(element => {
      if (element.unread > 0) {
        if (element.id == id) {
          this.contextService.messageCounter--;
        }
      }
    });

    this.contextService.active = id.toString();
    ///// unread messages count to zero when a message is opened

    this.contextService.log("Activate called");
    this.imService.messageID = id.toString();
    this.contextService.conversations.forEach(element => {
      element.people.forEach(people => {
        if (element.id == id) {
          element.unread = 0;
          const update = { 'conversationId': id, update: { 'unread': element.unread } };
          this.contextService.conversationHeaderChange.next(update);
          this.contextService.log(people.id + "  name");
          this.contextService.log("HeadLine" + element.headline);
          this.imService.extension = people.id;
        }
      });
    });
    ///// open selected conversation messages form message array
    this.contextService.messages = [];
    this.contextService.messageArray.forEach(element => {
      if (element.id == id.toString() && element.text.length > 0) {
        this.contextService.messages.push(element);
        this.contextService.log(element);
      }
    });

    if (parent != null) {
      this.parent.activate(id);
    }
    setTimeout(() => {
      this.updateScroll(id);
    }, 100);
  }

  animationDone(event) {
    if (event.fromState != "void") {
      event.element.parentNode.parentNode.remove();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.contextService.log(changes);
  }
}
