import { Component, OnInit } from '@angular/core';
import { ContextService } from '../../../../../providers/context.service';
import { BWAnywhereService } from '../../../../../providers/settings/settings-services/bw-anywhere.service';
import { BaseComponent } from '../../../../base-component';



@Component({
  selector: 'app-bw-anywhere-criteria',
  templateUrl: './bw-anywhere-criteria.component.html',
  styleUrls: ['./bw-anywhere.component.css']
})
export class BwAnywhereCriteriaComponent extends BaseComponent implements OnInit {
  description: string = "";
  criteriaDescription: string = "";
  selectedNumber: any = "";
  schedule: string = "";
  confirm: string = "";
  deleteCriteriaWindow: boolean = false;

  delete: string = "";
  plus: string = "";
  miscNo: string = "";
  miscYes: string = "";


  constructor(public bWAnywhereService: BWAnywhereService, private contextService: ContextService) {
    super();
  }
  deleteCriteria() {
    if (!(this.selectedNumber.number === "") && !(this.selectedNumber.number == null)) {
      this.deleteCriteriaWindow = true;
    }
  }
  deleteCriteriaLocation() {
    this.bWAnywhereService.deleteCriteria(this.selectedNumber.number);
    this.selectedNumber.number = "";
    this.deleteCriteriaWindow = false;
  }
  ngOnInit() {
    this.delete = this.contextService.getLanguageTagByKey('Menu.Context.Delete');
    this.plus = this.contextService.getLanguageTagByKey('CallButtonShowing.Plus');
    this.miscNo = this.contextService.getLanguageTagByKey('Misc.No');
    this.miscYes = this.contextService.getLanguageTagByKey('Misc.Yes');

    this.bWAnywhereService.bwAnyWhereChanges = true;
    this.contextService.checkBWAnywhere = true;
    this.description = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Description');
    this.criteriaDescription = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Description');
    this.schedule = this.contextService.getLanguageTagByKey('Settings.Services.BroadworksAnywhere.Number.Criteria.TimeSchedule');
    this.confirm = this.contextService.getLanguageTagByKey("Settings.Services.BroadworksAnywhere.Number.Criteria.Confirm");
  }
  ngOnDestroy() {
    this.contextService.checkBWAnywhere = false;
    this.bWAnywhereService.validateFlag = false;
  }
  doubleClick(event) {
    this.selectedNumber = event;
    if (this.selectedNumber) {
      this.bWAnywhereService.displayCriteria(this.selectedNumber.number);
    }

  }
}