<div class="row">
  <p>
    {{description}}
    <p>
</div>
<div class="row">
  <label for="" class="label-block"></label>
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{enabled}} [(ngModel)]="bWAnywhereService.objCurrentLocation.isActive"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <label for="" class="label">{{phoneNumber}}</label>
  <input type="text" name="input-text" [(ngModel)]="bWAnywhereService.objCurrentLocation.phoneNumber" autocomplete=off>
</div>
<div class="row">
  <label for="" class="label">{{phDescription}}</label>
  <textarea name="textarea" [(ngModel)]="bWAnywhereService.objCurrentLocation.description" rows="3"></textarea>
</div>
<div class="row">
  <label for="" class="label">{{outBound}}</label>
  <input type="text" name="input-text" [(ngModel)]="bWAnywhereService.objCurrentLocation.outboundAlternateNumber" placeholder="">
</div>
<div class="row">

  <p-checkbox name="checkboxGroup1" value="checkbox" label={{divInhibitor}} [(ngModel)]="bWAnywhereService.objCurrentLocation.useDiversionInhibitor"
    binary="true"></p-checkbox>
</div>
<div class="row">

  <p-checkbox name="checkboxGroup1" value="checkbox" label={{answer}} [(ngModel)]="bWAnywhereService.objCurrentLocation.answerConfirmationRequired"
    binary="true"></p-checkbox>
</div>
<div class="row">
  <p-checkbox name="checkboxGroup1" value="checkbox" label={{callControl}} [(ngModel)]="bWAnywhereService.objCurrentLocation.broadworksCallControl"
    binary="true"></p-checkbox>
</div>
<div class="row" [hidden]="bWAnywhereService.location.length == 0">
  <label (click)="showSelectionCriteria()" class="label-block bw-selection-criteria">{{criteria}}</label>
</div>
